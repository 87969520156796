<template>
  <div id="TMEMBERSHIP_NEW_CHECK">
    <div class="event-layer">
      <div class="tmember-popup">
        <div class="tpop-header">
          제휴요금제 신청
        </div>
        <!-- container -->
        <div class="container">
          <div class="bg-grey center">
            T멤버십 등록 당시 휴대폰번호를 인증해주시고<br>
            고객님께서 소유하고 계신 T멤버십 OTB번호를 차례대로 입력해주세요.
          </div>
          <div class="table_type01">
            <div class="table_inner">
              <dl>
                <dt>휴대폰번호</dt>
                <dd>
                  <div class="border-top-n">
                    <div class="phone-box">
                      <div class="sel-box">
                        <label 
                          for="call_select01"
                          aria-hidden="true"
                        >
                          010
                        </label>
                        <select
                          id="call_select01"
                          ref="telNumberFirst"
                          v-model="telNumber.first"
                          name="i_sHpNo1"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                      </div>
                      <span class="txt_hyphen">-</span>
                      <div class="col">
                        <input 
                          ref="telNumberMiddle" 
                          v-model="telNumber.middle" 
                          name="i_sHpNo2" 
                          title="전화번호" 
                          type="text" 
                          maxlength="4" 
                          pattern="[0-9]*"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <span class="txt_hyphen">-</span>
                      <div class="col">
                        <input 
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3" 
                          title="전화번호" 
                          type="text" 
                          maxlength="4" 
                          pattern="[0-9]*" 
                          inputmode="numeric" 
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div> 
                    </div>
                    <div class="btn-parent">
                      <a
                        class="btn btn_sendnum"
                        @click="requestSMSCert"
                      >SMS인증하기</a>
                    </div>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>인증번호 입력</dt>
                <dd>
                  <input
                    id="AsianaMemberNo" 
                    ref="certNumber"
                    v-model="certNumber" 
                    type="text"  
                    name="i_sAsianaMemberNo" 
                    title="인증번호 입력" 
                    class="onlyNumber w100" 
                    maxlength="9" 
                    pattern="[0-9]*"
                    inputmode="numeric"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  >
                  <div class="btn-parent">
                    <a
                      class="btn btn_sendnum"
                      @click="requestCert"
                    >인증번호 확인</a>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>OTB 번호</dt>
                <dd class="inputs_wrap">
                  <input
                    ref="otbNumberOne"
                    v-model="otbNumber.one"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterOtbNo"
                  >
                  <span class="txt_hyphen"><br>-</span>
                  <input
                    ref="otbNumberTwo"
                    v-model="otbNumber.two"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterOtbNo"
                  >
                  <span class="txt_hyphen"><br>-</span>
                  <input
                    ref="otbNumberThree"
                    v-model="otbNumber.three"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterOtbNo"
                  >
                  <span class="txt_hyphen"><br>-</span>
                  <input
                    ref="otbNumberFour"
                    v-model="otbNumber.four"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                  >
                </dd>
              </dl>
              <!-- <dl class="dotted-bot-box">
                <dt>PIN 번호</dt>
                <dd class="inputs_wrap">
                  <input
                    ref="pinNumberOne"
                    v-model="pinNumber.one"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberTwo"
                    v-model="pinNumber.two"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberThree"
                    v-model="pinNumber.three"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberFour"
                    v-model="pinNumber.four"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberFive"
                    v-model="pinNumber.five"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberSix"
                    v-model="pinNumber.six"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                  >
                </dd>
              </dl> -->
            </div>
          </div>

          <!-- 개인정보 동의 -->
          <div class="agreeBox">
            <div class="abT">
              <input
                id="c_a01"
                v-model="checkbox.one"
                type="checkbox"
                class="normal_check"
                @click="privacy1Popup"
              > 
              <label for="c_a01">개인정보 수집 및 이용에 동의합니다.</label>
            </div>
            <div class="abT">
              <input
                id="c_a02"
                v-model="checkbox.two"
                type="checkbox"
                class="normal_check"
                @click="privacy2Popup"
              >
              <label for="c_a02">개인정보 제3자 제공에 동의합니다.</label>
            </div>
          </div>
          <!-- // 개인정보 동의 -->

          <div class="dotted-bot-box center">
            제휴 혜택 신청 및 해지 관련 자세한 문의사항은<br>
            SK국제전화 00700 <strong>고객센터(1599-0070)</strong>를 이용해주시기 바랍니다.
          </div>
          <div class="popup-btn-box">
            <a
              class="btn-small"
              @click="requestFinal"
            >
              <img
                class="next-button"
                style="margin-right: 7px;"
                src="/content/images/tmember_bu_check.jpg"
                alt="확인"
              >
            </a>
            <a
              class="btn-small ml8"
              @click="close"
            >
              <img
                class="cancel-button"
                src="/content/images/tmember_bu_cancel.jpg"
                alt="취소"
              >
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacy1Popup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 수집 및 이용 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 및 이용 동의(내용)
          </h4>

          <div class="cont_term">
            <ul>
              <li>수집하는 개인정보 항목: 휴대폰번호, T멤버십 OTB 번호</li>
              <li>이용목적: 제휴 혜택 제공(T멤버십 플러스 포인트 적립 및 요금 할인)</li>
              <li>보유 및 이용기간: <strong>제휴요금제 해지 시까지</strong></li>
            </ul>
            <p class="t1">
              ※ 미동의 시 T멤버십 제휴요금제 신청이 불가합니다.
            </p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacy1Popup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacy1Popup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>

    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacy2Popup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 제3자 제공동의(내용)</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 제3자 제공 동의 안내
          </h4>

          <div class="cont_term">
            <ul>
              <li>제공받는 자: SK플래닛, SK텔레콤</li>
              <li>제공목적: 제휴 혜택 제공(T멤버십 플러스 포인트 적립 및 요금 할인)</li>
              <li>제공항목: 휴대폰번호, T멤버십 OTB 번호</li>
              <li>보유 및 이용기간: <strong>T멤버십 제휴 요금제 이용 종료 후 5년까지 보관 후 삭제</strong></li>
            </ul>
            <p class="t1">
              ※ 미동의 시 T멤버십 제휴요금제 신청이 불가합니다.
            </p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacy2Popup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacy2Popup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type2="'btn_type02'"
      @click="alertPopup.visible = false"
      @close="alertPopup.visible = false"
    >
      <div class="pop_row">
        중도 해지한 이력이 있습니다.<br>
        <span class="txt_point01">{{ use_end_dt }}</span> 이후에 재신청이 가능합니다.
      </div>
    </Popup>
    
    <Popup
      :is-visible.sync="alertPopup0.visible"
      :pop-title="'요금제 가입 동의'"
      :c-type2="'btn_type02'"
      @click="joinAgree"
      @close="alertPopup0.visible = false"
    >
      <div class="pop_row">
        고객님께서는<br>
        <span class="txt_point02">T멤버십 할인형</span> 요금제 가입 대상입니다.<br><br>
        가입을 원하실 경우 <span class="txt_point03">확인</span> 버튼을 눌러주세요.
      </div>
    </Popup>

    <Popup
      :is-visible.sync="alertPopup1.visible"
      :pop-title="'요금제 가입 동의'"
      :c-type2="'btn_type02'"
      @click="joinAgree"
      @close="alertPopup1.visible = false"
    >
      <div class="pop_row">
        고객님께서는<br>
        <span class="txt_point02">T멤버십 적립형</span> 요금제 가입 대상입니다.<br><br>
        가입을 원하실 경우 <span class="txt_point03">확인</span> 버튼을 눌러주세요.
      </div>
    </Popup>
    
    <Popup
      :is-visible.sync="alertPopup2.visible"
      :pop-title="'요금제 가입 완료'"
      :c-type2="'btn_type02'"
      @click="close"
    >
      <div class="pop_row">
        요금제 가입을 진심으로 감사 드립니다.<br><br>
        이용 안내 문자가 발송되었습니다.
      </div>
    </Popup>
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'

export default {
  name: 'TmembershipNewCheck',
  components: {
    Popup
  },
  props: {},
  emits: [],
  data () {
    return {
      alertPopup: {
        visible: false
      },
      alertPopup0: {
        visible: false
      },
      alertPopup1: {
        visible: false
      },
      alertPopup2: {
        visible: false
      },
      telNumber: {
        first: '010'
      },
      certNumber: '',
      otbNumber: {},
      pinNumber: {},
      isVisiblePrivacy1Popup: false,
      isVisiblePrivacy2Popup: false,
      checkbox: {
        one: '',
        two: ''
      },
      ticket: '',
      use_end_dt: ''
    }
  },
  computed: {
    otb_no() {
      return `${this.otbNumber.one}${this.otbNumber.two}${this.otbNumber.three}${this.otbNumber.four}`  
    },
    // pin_no() {
    //   return `${this.pinNumber.one}${this.pinNumber.two}${this.pinNumber.three}${this.pinNumber.four}${this.pinNumber.five}${this.pinNumber.six}`  
    // },
    tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if (this.callbackAlert.code === 'E027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code === 'E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E032' || this.callbackAlert.code === 'E033' || this.callbackAlert.code === 'E035' || this.callbackAlert.code === 'E036' || this.callbackAlert.code === 'E039' || this.callbackAlert.code === 'E040') { 
          this.resetPage()
        }
        if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
          this.telNumber = {
            first: '010',
            middle: '',
            last: ''
          },
          this.certNumber = '',
          this.ticket = ''
        }
        if (this.callbackAlert.code === 'E001') { // 인증시간 초과
          this.certNumber = ''
          this.ticket = ''
        }
      }
    }  
  },
  created () {},
  mounted () {
  },
  methods: {
    privacy1Popup() {
      if(!this.checkbox.one) {
        this.isVisiblePrivacy1Popup = true
      }
    },
    privacy2Popup() {
      if(!this.checkbox.two) {
        this.isVisiblePrivacy2Popup = true
      }
    },
    close(){
      this.$emit('click')
    },
    enterOtbNo(event) {
      if(event.target.value.length > event.target.maxLength -1) {
        event.target.nextSibling.nextSibling.focus()
      }
    },
    // enterPinNo(event) {
    //   if(event.target.value.length > event.target.maxLength -1) {
    //     event.target.nextSibling.focus()
    //   }
    // },
    checkPhoneNumber() {
      if (!this.telNumber.middle) {
        this.$refs.telNumberMiddle.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.last) {
        this.$refs.telNumberLast.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        alert('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else {
        return true
      }
    },
    checkAuthNumber() {
      if (!this.certNumber) {
        this.$refs.certNumber.focus()
        alert('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkOtbNumber() {
      if (!this.otbNumber.one) {
        this.$refs.otbNumberOne.focus()
        alert('OTB번호를 입력해 주세요.')
        return false
      } else if (!this.otbNumber.two) {
        this.$refs.otbNumberTwo.focus()
        alert('OTB번호를 입력해 주세요.')
        return false
      } else if (!this.otbNumber.three) {
        this.$refs.otbNumberThree.focus()
        alert('OTB번호를 입력해 주세요.')
        return false
      } else if (!this.otbNumber.four) {
        this.$refs.otbNumberFour.focus()
        alert('OTB번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    //   checkPinNumber() {
    //   if (!this.pinNumber.one) {
    //     this.$refs.pinNumberOne.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.two) {
    //     this.$refs.pinNumberTwo.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.three) {
    //     this.$refs.pinNumberThree.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.four) {
    //     this.$refs.pinNumberFour.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.five) {
    //     this.$refs.pinNumberFive.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.six) {
    //     this.$refs.pinNumberSix.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else {
    //     return true
    //   }
    // },
    checkCheckbox() {
      if(!this.checkbox.one) {
        alert('개인정보 수집 이용에 동의해주세요.')
        return false
      } else if(!this.checkbox.two) {
        alert('개인정보 제3자 제공에 동의해주세요.')
        return false
      } else {
        return true
      }
    },
    // SMS인증하기
    requestSMSCert() {
      if (this.checkPhoneNumber()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.tel_no })
          .then(response => {
            if (response.data.sms_ticket) {
              alert('인증번호가 정상적으로 발송되었습니다.')
              this.ticket = response.data.sms_ticket
            }
          })
      }
    },
    // 인증번호 확인
    requestCert() {
      if (this.checkAuthNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.certNumber, sms_ticket: this.ticket })
          .then(response => {
            if (response.data.result_cd === '0001') {
              alert('인증번호가 확인되었습니다. 감사합니다.')
            }
          })
      }
    },
    // 확인 버튼
    requestFinal() {
      if (this.checkPhoneNumber() && this.checkAuthNumber() && this.checkOtbNumber() && this.checkCheckbox()) {
        api.EventPostCheckTmemNewSubs({ 
          sms_ticket: this.ticket,
          otb: this.otb_no,
        })
        .then(response => {
          if (response.data.result_cd === '0001' && response.data.tmembership_status === '01') {
            this.alertPopup0.visible = true
          }
          if (response.data.result_cd === '0001' && response.data.tmembership_status === '02') {
            this.alertPopup1.visible = true
          }
        })
      }
    },
    resetPage(){
      this.telNumber = {
        first: '010',
        middle: '',
        last: ''
      },
      this.certNumber = '',
      this.otbNumber = {
        one: '',
        two: '',
        three: '',
        four: ''
      },
      this.checkbox = {
        one: false,
        two: false
      },
      this.ticket = ''
    },
    joinAgree() {
      if (this.checkPhoneNumber() && this.checkAuthNumber() && this.checkOtbNumber() && this.checkCheckbox()) {
        api.EventPostTmemNewSubs({ 
          sms_ticket: this.ticket,
          otb: this.otb_no,
        })
        .then(response => {
          if (response.data.result_cd === '0007') {
            this.alertPopup2.visible = true
          }
        })
        this.alertPopup0.visible = false;      
        this.alertPopup1.visible = false;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#TMEMBERSHIP_NEW_CHECK {
  position: fixed;
  width: 432px;
  height: 100%;
  overflow-y: auto;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
}
.event-layer .inputs {
  text-align: center;
  margin-left: 1%
}
.event-layer .inputs:first-child {
  margin-left: 0;
}
@media (max-width: 1023px) {
  #TMEMBERSHIP_NEW_CHECK {
    width: 100%;
  }

}
.tmember-popup .table_type01 .inputs_wrap {
  display: flex;
  align-content: space-between;
}
.tmember-popup .inputs_wrap input {
  width: 100%;
  margin-left: 3px;
  padding: 0;
  &:first-child {
    margin-left: 0;
  }
}
.tmember-popup .agreeBox label {
  margin-left: 3px;
}
@media (max-width:470px) {
  .tmember-popup .bg-grey {
    padding-left: 15px;
    padding-right: 15px;
    br {
      display: none;
    }
  }
}
</style>
