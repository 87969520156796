<template>
  <div id="Sk7mobile">
    <Header />
    <!-- 컨텐츠 -->
    <div id="container" class="">
      <BreadCrumb />
      <div id="contents">
        <!-- sk7 2018-05-23 -->
        <div class="sk7">
          <div class="sub_visual">
            <h3>
              <span class="titlePrefix">나를 아끼는 모바일</span>
              <img
                src="/content/images/sub_header_logo.png"
                alt="SK 7mobile"
                style="width: auto"
              />
            </h3>
            <p>
              SK의 품질과 신뢰로<br /><strong>프리미엄 모바일 경험</strong>을
              제공합니다.
            </p>
            <!-- 20211006 - 문구 수정 -->
            <a
              href="https://www.sk7mobile.com"
              class="btn_arrow"
              target="_blank"
              title="SK 7mobile 새창열림"
              ><span>SK 7mobile (알뜰폰) 바로가기</span></a
            >
            <img
              src="/content/images/sub_header_visual_02_m.jpg"
              alt="비쥬얼 이미지"
              class="visual"
            />
          </div>

          <!-- 선택하지 않을 권리 - 20211006 - 문구 수정 -->
          <div class="advice_list white">
            <h4>
              SK 7mobile은 알뜰폰에서 일상의<br /><span
                >프리미엄을 제시하는 브랜드로 <br class="m_br" />다가 가고자
                합니다</span
              >
            </h4>
            <p>
              고객이 원하는 합리적인 가격과 이용 편리성은 기본입니다.<br />
              SK텔레콤의 품질과 신뢰를 통해 빠른 데이터 속도와 통화
              안정성으로<br class="w_br" />
              고품질의 통신 경험을 제공합니다.<br />
              무제한 데이터 서비스를 통한 폭넓은 생활서비스 제공으로<br
                class="w_br"
              />
              고객의 디지털 라이프 스타일을 더욱 풍성하게 하는, 시대가 원하는<br
                class="w_br"
              />
              모바일의 새로운 역할, SK 7mobile 입니다.
            </p>
          </div>
          <!-- // 선택하지 않을 권리 -->

          <!-- 모바일 가치소비 -->
          <div class="advice_list white">
            <h4>
              <!-- 20211006 - 문구 수정 -->
              나를 아끼는 모바일<br class="m_br" />
              <img
                src="/content/images/sub_header_logo.png"
                class="inLo"
                alt="SK 7mobile"
              />
            </h4>
            <p>
              SK텔레콤의 이동통신망을 이용하여<br class="m_br" />
              합리적인 요금과 우수한 통화품질로<br />알뜰폰 이동통신 서비스를
              제공하고 있습니다.
            </p>

            <div class="advice_scroll">
              <div class="advice_scroll">
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img src="/content/images/icon_sk7_b01.png" alt="" />
                    </div>
                    <strong>SK텔레콤 통화품질 그대로</strong>
                    <p>
                      대한민국 1등 이동통신기술을<br />
                      동일하게 제공합니다.
                    </p>
                  </li>
                  <!-- 20211006 - 삭제 <li>
                    <div>
                      <img
                        src="/content/images/icon_sk7_b02.png"
                        alt=""
                      >
                    </div>
                    <strong>이용자보호평가 3년 연속 1위</strong>
                    <p>방송통신위원회 이용자 보호 평가<br> 3년 연속 1위의 명성으로<br> 안심하고 이용하실 수 있습니다.</p>
                  </li> -->
                  <li>
                    <div>
                      <img src="/content/images/icon_sk7_b03.png" alt="" />
                    </div>
                    <strong>합리적인 요금</strong>
                    <p>
                      꼭 필요한 서비스만 담아<br />알뜰하고 실속있게<br
                        class="m_br"
                      />
                      통신비를 낮추었습니다.
                    </p>
                  </li>
                  <li>
                    <!-- 20220919 - 이미지 수정, 텍스트 수정, css 수정 -->
                    <div class="winner">
                      <img
                        src="/content/images/icon_sk7_b05_230728.png"
                        alt=""
                      />
                    </div>
                    <strong>알뜰폰 브랜드 1위 수상</strong>
                    <!-- <p>
                      한국능률협회컨설팅 선정<br>
                      ‘2022년 소비자가 가장 추천하는 브랜드’,<br>
                      한국표준협회 주관 ‘2022 프리미엄브랜드 지수’,<br>
                      국가브랜드경쟁력지수 알뜰폰 부문 1위를 수상한<br>
                      신뢰할 수 있는 브랜드 입니다.
                    </p> -->
                    <p>
                      한국능률협회컨설팅 선정<br />
                      “2023년 국가고객만족도(NCSI)”,<br />
                      “2023년 소비자가 가장 추천하는 브랜드”,<br />
                      “2022년 프리미엄브랜드지수”,<br />
                      “2022년 국가브랜드경쟁력지수(NBCI)”<br />
                      알뜰폰 부문 1위를 수상한<br />
                      신뢰할 수 있는 브랜드 입니다.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- // 모바일 가치소비 -->

          <!-- 나에게 맞는 요금제 - 20211006 - 삭제
          <div class="advice_list">
            <h4>합리적인<br class="m_br"> 모바일 가치소비를 위해<br> <span>나에게 맞는<br class="m_br"> 요금제와 상품을 찾아보세요!</span></h4>
            <p>전화, 데이터 사용량 또는<br class="m_br"> 월 납부금액을 설정하여 상품을 찾아보세요.</p>
            <div class="bsImg">
              <img
                src="/content/images/icon_sk7_b04.png"
                alt=""
              >
            </div>
            <a
              href="https://www.sk7mobile.com/prod/data/callingPlanList.do?refCode=USIM"
              class="btn_arrow"
              target="_blank"
              title="나에게 맞는 요금제 찾기 새창열림"
            ><span>나에게 맞는 요금제 찾기</span></a>
          </div>
          // 나에게 맞는 요금제 -->

          <!-- 유심요금제 - 20211006 - 문구 수정, 삭제, 버튼 추가 -->
          <div class="benefit_list">
            <h4>
              나에게 맞는 요금제를 찾아<br /><span
                >프리미엄 모바일 경험을 누리세요!</span
              ><br />
            </h4>
            <a
              href="https://www.sk7mobile.com/prod/data/callingPlanList.do?refCode=USIM"
              class="btn_arrow benefit"
              target="_blank"
              title="SK 7mobile 요금제 바로가기 새창열림"
              ><span>SK 7mobile 요금제 바로가기</span></a
            >
            <div>
              <div class="benefit_left">
                <a
                  href="https://www.sk7mobile.com/prod/data/callingPlanList.do?refCode=USIM&searchCallPlanType=PROD_USIM_TYPE_INFO&searchOrderby=3&stDate=&endDate=&creator=&mobileNo=&pMail=&pDomain="
                  target="_blank"
                  title="SK 7mobile 유심안내 새창열림"
                >
                  <span class="img_box"
                    ><img src="/content/images/img_benefit02.png" alt=""
                  /></span>
                  <h5>유심 가입 혜택</h5>
                  <p>
                    쓰던 휴대폰, 번호 그대로<br />약정 위약금 걱정 없는 유심
                    요금제
                  </p>
                </a>
              </div>
              <div class="benefit_right">
                <a
                  href="https://www.sk7mobile.com/shop/mobile/usimList.do"
                  target="_blank"
                  title="SK 7mobile 유심 요금제 가입 새창열림"
                >
                  <span class="img_box"
                    ><img src="/content/images/img_benefit01.png" alt=""
                  /></span>
                  <h5>유심 요금제 가입</h5>
                  <p>
                    온라인으로 쉽고 편리하게 가입<br />빠르고 정확한 안심배송!
                  </p>
                </a>
              </div>
            </div>
          </div>
          <!-- // 유심요금제 -->
        </div>
        <!-- // sk7 2018-05-23 -->
      </div>
    </div>
    <!--// 컨텐츠 -->
    <!-- // container -->
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "@/components/Header";
import BreadCrumb from "@/components/BreadCrumb";
import Footer from "@/components/Footer";
import SummitControl from "@/components/SummitControl";

export default {
  name: "Sk7mobile",
  metaInfo: {
    title: "SK7mobile | 사업소개",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
// 20211006 css 추가
.sub_visual {
  margin-top: 58px;
  h3 {
    span.titlePrefix {
      display: inline-block;
      margin-right: 10px;
      padding-top: 23px;
      font-size: 30px;
      color: #222;
      font-weight: 200;
    }
  }
}
.btn_arrow.benefit {
  margin-top: 30px;
  margin-bottom: 45px;
  background: #e5b31e;
}
.sk7 .advice_list ul {
  display: flex;
  li {
    float: none;
    div.winner {
      width: 306px;
    }
  }
}

@media (max-width: 1023px) {
  .sub_visual {
    margin-top: 0;
    h3 {
      span.titlePrefix {
        display: block;
        margin-right: 0;
        margin-bottom: 10px;
        padding-top: 0;
        font-size: 17px;
        font-weight: 100;
      }
    }
  }
  .sk7 h4 > span {
    font-size: 25px;
  }
  .btn_arrow.benefit {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .sk7 .advice_list ul li div.winner {
    width: auto;
  }
  .sk7 .advice_list .advice_scroll div ul li div.winner ~ p {
    font-size: 14px;
  }
}
</style>
