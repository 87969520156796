<template>
  <div id="PRESS">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg06">
          <h3 class="title">
            보도자료
          </h3>
          <p class="txt">
            SK텔링크에서 다양한 소식을 전해 드립니다
          </p>
          <img
            src="/content/images/sub_header_cp06.jpg"
            alt="비쥬얼 이미지"
          > <!-- 2017-06-29 상단이미지변경 -->
        </div>

        <fieldset class="search_box">
          <legend>뉴스센터 검색</legend>
          <div class="search_box_inner">
            <div>
              <div class="select_box">
                <label for="type_select01">전체</label>

                <select
                  id="type_select01"
                  v-model="formData.search_tp"
                  name="i_sSearchKind"
                  title="보도자료 검색 제목"
                >
                  <option value="ALL">
                    전체
                  </option>
                  <option value="TITLE">
                    제목
                  </option>
                  <option value="DESC">
                    내용
                  </option>
                </select>
              </div>
            </div>
            <div>
              <input
                v-model="formData.search_word"
                type="text"
                placeholder="검색어를 입력하세요"
                name="i_sKeyword"
                value=""
                title="검색어를 입력하세요"
                @keyup.enter="apiCall"
              >
              <button
                type="button"
                class="btn_type02"
                @click="apiCall"
              >
                검색
              </button>
              <div class="clear" />
            </div>
          </div>
        </fieldset>

        <!-- company -->
        <div class="company">
          <ul class="news_list">
            <li
              v-for="(item, index) in pressList.bulletin_list"
              :key="index"
            >
              <router-link
                :to="{ name: 'press_contents', params: { id: item.bulletin_no }, query: { page_num: formData.page_num }}"
                class="btn_detail"
              >
                <span class="thumbnail">
                  <img
                    :src="item.thumbnail_small"
                    alt=""
                    width="106px"
                    height="106px"
                  >
                </span>

                <span class="txt_area">
                  <span class="date">{{ $moment(item.start_dt).format('YYYY.MM.DD') }}</span>
                  <strong>{{ item.title }}</strong>
                  <span
                    class="txt"
                    v-html="eliminatedDescription(item)"
                  />
                </span>
              </router-link>
            </li>
            <li
              v-if="pressList.bulletin_total_cnt === 0"
              class="no-data"
            >
              내용이 없습니다.
            </li>
          </ul>

          <!-- paging -->
          <Pagination
            v-if="totalCount > 0"
            :total-count="totalCount"
            :page-per-count="formData.page_per_cnt"
            :current-page="formData.page_num"
            @update="updatePage"
            @loadmo="updatePageMobile"
          />
        </div>
      <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Pagination from "@/components/Pagination"
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'

export default {
  name: 'Press',
  metaInfo: {
    title: '보도자료 목록 | 미디어센터',
  },
  components: {
    Header,
    BreadCrumb,
    Pagination,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      pressList:{},
      pressDetail:{},
      totalCount: 0,
      formData: {
        page_per_cnt: 10,
        page_num: 1,
        search_tp: 'ALL',
        search_word: ''
      },
      currentPage:null
    }
  },
  computed: {

  },
  watch: {},
  created () {
    if(this.$route.query.page_num) {
      this.formData.page_num = parseInt(this.$route.query.page_num, 10)
    }
    api.BulletinGetPressList(this.formData)
    .then(response => {
      this.pressList = response.data
      this.totalCount = response.data.bulletin_total_cnt
    })
  },
  mounted () {
  },
  methods: {
    apiCall(){
      api.BulletinGetPressList(this.formData)
      .then(response => {
        this.pressList = response.data
        this.totalCount = response.data.bulletin_total_cnt
        this.currentPage = null
      })
    },
    moreList(){
      api.BulletinGetPressList(this.formData)
      .then(response => {
        response.data.bulletin_list.forEach(item => {
          this.pressList.bulletin_list.push(item)
        })
      })
    },
    updatePage(num) {
      this.$router.replace({ query: {...this.$route.query, page_num: num }}).catch(()=>{})
      this.formData.page_num = num
      this.apiCall()
    },
    updatePageMobile(num) {
      this.formData.page_num = num
      this.moreList()
    },
    eliminatedDescription(item) {
      let str = item.bulletin_desc
      str = str.replace(/(<([^>]+)>)/gm, '')
      str.replace(/;(?=[^<>]+:|\s*")/gi, '')
      let tmp = document.createElement('DIV')
      tmp.innerHTML = str
      // if(tmp.textContent.includes('figcaption')){
      //   let tagDelText = tmp.textContent.replaceAll( /figcaption{|height: 30px;|font-size: 10pt;|text-indent: 0px;|font-weight: bold;|font-size: 14pt;|line-height:|2;|}/gm,"");
      //   return tagDelText
      // }
      return tmp.textContent || tmp.innerText || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1023px){
  .search_box_inner{
    .select_box{
      border: none;
      label{
        display: none !important;
      }
      select{
        opacity: 1;
        padding: 0 33px 0 15px;
        background-size: 35px auto;
        background-position: right;
        line-height: 1;
        border-radius: 0;
        border: 1px solid #e2e2e2;
      }
    }
  }
}
.company .news_list li.no-data{
    padding: 30px 0;
    text-align: center;
  }
</style>
