<template>
  <div id="SKT_SPECIAL">
    <Header />
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />
      <div id="contents">
        <div class="sub_header bg08">
          <h3 class="title">
            SKT고객 특별할인
          </h3>
          <p class="txt">
            국내전화(망외/부가음성통화)로 국제전화 00700을 이용할 수 있는<br>4종류의 알뜰한 SKT고객 특별할인 부가서비스!
          </p>
          <img
            src="/content/images/sub_header_inter08-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- inter -->
        <div class="inter mb0">
          <div class="advice_list">
            <h4>이런 고객님이 사용하면 좋아요!</h4>
            <p><span class="hashtag"># SKT고객</span><span class="hashtag"># 비즈니스고객</span><span class="hashtag"># 장거리연애고객</span></p>
            <div class="advice_scroll">
              <div>
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter13.png"
                        alt=""
                      >
                    </div>
                    <p>해외에 가족/지인이 있는 SKT 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter14.png"
                        alt=""
                      >
                    </div>
                    <p>해외로 업무를 하는<br class="m_br"> SKT 비즈니스 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter15.png"
                        alt=""
                      >
                    </div>
                    <p>해외에 연인이 있는 롱디족<br>(장거리 연애고객)</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="advice_list white">
            <h4>거부할 수 없는 매력은<br class="m_hide"> 무엇인가요?</h4>
            <div class="advice_scroll">
              <div>
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub18.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>국내통화→국제전화 전환</dt>
                      <dd>SKT 요금제별로 제공되는 망내/부가음성통화를<br> 국제전화로 전환해서 이용해보세요.<br> 무료제공 분수를 초과 사용해도<br> 국내통화 요금인 108원/분으로 통화가 가능합니다.</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub19.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>정액요금으로<br> 마음 놓고 국제전화 이용</dt>
                      <dd>많은 양의 국제전화를 마음 놓고<br> 사용할 수 있도록 구성한 정액요금제입니다.<br> 무료제공분수 안에서는<br> 마음껏 국제전화를 이용해보세요.</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub20.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>최대 26개국을 내 마음대로</dt>
                      <dd>최대 26개국을 국가에 상관없이<br> 자유롭게 통화할 수 있습니다.<br> 국제전화 사용량도 국내전화 사용량과 동일하게<br> 측정되는 놀라운 혜택을 누려보세요.</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="use_table_area">
            <h4>지금 바로 확인해보세요</h4>
            <!-- 웹에서 노출 -->
            <div class="table_type04 table_flag w_hide">
              <table>
                <caption>명칭, 00700프리 5, 00700프리 10, 00700프리 15, 00700프리 20 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:20%;">
                  <col style="width:20%;">
                  <col style="width:20%;">
                  <col style="width:20%;">
                  <col style="width:20%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      명칭
                    </th>
                    <th scope="col">
                      00700프리 5
                    </th>
                    <th scope="col">
                      00700프리 10
                    </th>
                    <th scope="col">
                      00700프리 15
                    </th>
                    <th scope="col">
                      00700프리 20
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>월정액</td>
                    <td>5,500원<br>(부가세 포함)</td>
                    <td>11,000원<br>(부가세 포함)</td>
                    <td>16,500원<br>(부가세 포함)</td>
                    <td>22,000원<br>(부가세 포함)</td>
                  </tr>
                  <tr>
                    <td>대상국가</td>
                    <td
                      colspan="2"
                      class="txt_left"
                    >
                      <ul class="flag_list">
                        <li>
                          <span><img
                            src="/content/images/icon_flag01.png"
                            alt=""
                          ></span>중국
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag02.png"
                            alt=""
                          ></span>미국
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag03.png"
                            alt=""
                          ></span>캐나다
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag04.png"
                            alt=""
                          ></span>홍콩
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag05.png"
                            alt=""
                          ></span>싱가포르
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag06.png"
                            alt=""
                          ></span>태국
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag07.png"
                            alt=""
                          ></span>말레이시아
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag08.png"
                            alt=""
                          ></span>방글라데시
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag09.png"
                            alt=""
                          ></span>몽골
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag10.png"
                            alt=""
                          ></span>인도
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag11.png"
                            alt=""
                          ></span>괌
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag12.png"
                            alt=""
                          ></span>사이판
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag13.png"
                            alt=""
                          ></span>포르투갈
                        </li>
                      </ul>
                    </td>
                    <td
                      colspan="2"
                      class="txt_left"
                    >
                      <ul class="flag_list">
                        <li>
                          <span><img
                            src="/content/images/icon_flag01.png"
                            alt=""
                          ></span>중국
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag02.png"
                            alt=""
                          ></span>미국
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag03.png"
                            alt=""
                          ></span>캐나다
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag04.png"
                            alt=""
                          ></span>홍콩
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag05.png"
                            alt=""
                          ></span>싱가포르
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag06.png"
                            alt=""
                          ></span>태국
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag07.png"
                            alt=""
                          ></span>말레이시아
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag08.png"
                            alt=""
                          ></span>방글라데시
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag09.png"
                            alt=""
                          ></span>몽골
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag10.png"
                            alt=""
                          ></span>인도
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag11.png"
                            alt=""
                          ></span>괌
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag12.png"
                            alt=""
                          ></span>사이판
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag13.png"
                            alt=""
                          ></span>포르투갈
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag14.png"
                            alt=""
                          ></span>우즈베키스탄
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag15.png"
                            alt=""
                          ></span>인도네시아
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag16.png"
                            alt=""
                          ></span>베트남
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag17.png"
                            alt=""
                          ></span>캄보디아
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag18.png"
                            alt=""
                          ></span>파키스탄
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag19.png"
                            alt=""
                          ></span>독일
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag20.png"
                            alt=""
                          ></span>오스트리아
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag21.png"
                            alt=""
                          ></span>호주
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag22.png"
                            alt=""
                          ></span>불가리아
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag23.png"
                            alt=""
                          ></span>이스라엘
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag24.png"
                            alt=""
                          ></span>콜롬비아
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag25.png"
                            alt=""
                          ></span>마카오
                        </li>
                        <li>
                          <span><img
                            src="/content/images/icon_flag26.png"
                            alt=""
                          ></span>라오스
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>가입가능 기본요금제</td>

                    <td class="txt_left">
                      <ul class="hyphen_list">
                        <li>- T끼리35/45/55</li>
                        <li>- T끼리 어르신</li>
                        <li>- band 데이터<br>세이브/1.2G/2.2G/3.5G</li>
                        <li>- band 어르신<br>세이브/1.2G/2.2G</li>
                        <li>- T플랜 스몰/레귤러</li>
                        <li>- T플랜 세이브/안심2.5G</li>
                        <li>- 0플랜 스몰 등</li>
                      </ul>
                    </td>
                    <td class="txt_left">
                      <ul class="hyphen_list">
                        <li>- T끼리 65</li>
                        <li>- 전국민무한69/75/85/100</li>
                        <li>- band 데이터 6.5G/퍼펙트/퍼펙트S</li>
                        <li>- T시그니처Classic/Master/<br>Classic(구)/Master(구)</li>
                        <li>- T플랜 미디엄/라지/<br>패밀리/Data인피니티</li>
                        <li>- T플랜 안심4G/에센스/<br>스페셜/맥스</li>
                        <li>- 0플랜 미디엄/라지/<br>히어로/슈퍼히어로</li>
                        <li>- 슬림</li>
                        <li>- 5GX스탠다드/프라임/<br>플래티넘 등</li>
                      </ul>
                    </td>
                    <td class="txt_left">
                      <ul class="hyphen_list">
                        <li>- T끼리 35/45/55</li>
                        <li>- T끼리 어르신</li>
                        <li>- band 데이터 세이브/1.2G/2.2G/3.5G</li>
                        <li>- band 어르신 세이브/1.2G/2.2G</li>
                        <li>- T플랜 스몰/레귤러</li>
                        <li>- T플랜 세이브/안심2.5G</li>
                        <li>- 0플랜 스몰 등</li>
                      </ul>
                    </td>
                    <td class="txt_left">
                      <ul class="hyphen_list">
                        <li>- T끼리 65</li>
                        <li>- 전국민무한 69/75/85/100</li>
                        <li>- band 데이터 6.5G/퍼펙트/퍼펙트S</li>
                        <li>- T시그니쳐 Classic/Master/<br>Classic(구)/Master(구)</li>
                        <li>- T플랜 미디엄/라지/<br>패밀리/Data인피니티 </li>
                        <li>- T플랜 안심4G/에센스/<br>스페셜/맥스 </li>
                        <li>- 0플랜 미디엄/라지/<br>히어로/슈퍼히어로</li>
                        <li>- 슬림</li>
                        <li>- 5GX스탠다드/프라임/<br>플래티넘 등</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>신청</td>
                    <td>
                      <a
                        href="http://www.tworld.co.kr/normal.do?serviceId=S_PROD2001&viewId=V_PROD2001&prod_id=NA00004683"
                        target="_blank"
                        class="btn_small"
                        title="00700프리 5 새창열림"
                      >상세보기</a>
                    </td>
                    <td>
                      <a
                        href="http://www.tworld.co.kr/normal.do?serviceId=S_PROD2001&viewId=V_PROD2001&prod_id=NA00004684"
                        target="_blank"
                        class="btn_small"
                        title="00700프리 10 새창열림"
                      >상세보기</a>
                    </td>
                    <td>
                      <a
                        href="http://www.tworld.co.kr/normal.do?serviceId=S_PROD2001&viewId=V_PROD2001&prod_id=NA00004685"
                        target="_blank"
                        class="btn_small"
                        title="00700프리 15 새창열림"
                      >상세보기</a>
                    </td>
                    <td>
                      <a
                        href="http://www.tworld.co.kr/normal.do?serviceId=S_PROD2001&viewId=V_PROD2001&prod_id=NA00004686"
                        target="_blank"
                        class="btn_small"
                        title="00700프리 20 새창열림"
                      >상세보기</a>
                    </td>
                  </tr>
                  <tr>
                    <td>비고</td>
                    <td
                      class="txt_left"
                      colspan="4"
                    >
                      <ul>
                        <li>- 가입한 기본요금제에 따라 가입 가능한 00700프리 부가서비스가 지정되어 있습니다.</li>
                        <li>- 기본요금제의 망외/유선/부가통화 제공량 내에서 추가 통화료 없이 대상국가에 국제전화 이용이 가능합니다.</li>
                        <li>- 기본요금제의 망외/유선/부가통화 제공량 모두 소진 후 대상국가 국제전화 이용 시 초당 1.8원이 적용됩니다.</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- //웹에서 노출 -->

            <!-- 모바일에서 노출 -->
            <div class="table_slide">
              <ul>
                <li class="accordionItem">
                  <div class="tit_box">
                    <h5 class="title">
                      00700프리 5
                    </h5>
                    <dl>
                      <dt><span class="txt_point01">5,500원</span></dt>
                      <dd>(부가세 포함)</dd>
                    </dl>
                    <span class="btn"><img
                      src="/content/images/btn_table_slide.png"
                      alt="펼쳐짐"
                    ></span>
                  </div>

                  <div
                    class="txt_open"
                    style="display: block;"
                  >
                    <!-- to개발 / 슬라이드 오픈 시 display: block; -->
                    <ul class="table_list">
                      <li>
                        <dl>
                          <dt>&middot; 월정액</dt>
                          <dd>5,500원 (부가세 포함)</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 대상국가</dt>
                          <dd>
                            <ul class="flag_list">
                              <li>
                                <span><img
                                  src="/content/images/icon_flag01.png"
                                  alt=""
                                ></span>중국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag02.png"
                                  alt=""
                                ></span>미국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag03.png"
                                  alt=""
                                ></span>캐나다
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag04.png"
                                  alt=""
                                ></span>홍콩
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag05.png"
                                  alt=""
                                ></span>싱가포르
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag06.png"
                                  alt=""
                                ></span>태국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag07.png"
                                  alt=""
                                ></span>말레이시아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag08.png"
                                  alt=""
                                ></span>방글라데시
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag09.png"
                                  alt=""
                                ></span>몽골
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag10.png"
                                  alt=""
                                ></span>인도
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag11.png"
                                  alt=""
                                ></span>괌
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag12.png"
                                  alt=""
                                ></span>사이판
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag13.png"
                                  alt=""
                                ></span>포르투갈
                              </li>
                            </ul>
                          </dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 가입가능<br>기본요금제</dt>
                          <dd>
                            <ul class="hyphen_list">
                              <li>• T끼리35/45/55</li>
                              <li>• T끼리 어르신</li>
                              <li>• band 데이터<br>세이브/1.2G/2.2G/3.5G</li>
                              <li>• band 어르신<br>세이브/1.2G/2.2G</li>
                              <li>• T플랜 스몰/레귤러</li>
                              <li>• T플랜 세이브/안심2.5G</li>
                              <li>• 0플랜 스몰 등</li>
                            </ul>
                          </dd>
                        </dl>
                      </li>
                    </ul>
                    <div class="btn single">
                      <a
                        href="https://m.tworld.co.kr/product/callplan?prod_id=NA00004683"
                        class="btn_small"
                        title="00700프리 5"
                        target="_blank"
                      >신청하기</a>
                    </div>
                  </div>
                </li>
                <li class="accordionItem">
                  <div class="tit_box">
                    <h5 class="title">
                      00700프리 10
                    </h5>
                    <dl>
                      <dt><span class="txt_point01">11,000원</span></dt>
                      <dd>(부가세 포함)</dd>
                    </dl>
                    <span class="btn"><img
                      src="/content/images/btn_table_slide.png"
                      alt="펼치기"
                    ></span>
                  </div>

                  <div class="txt_open">
                    <ul class="table_list">
                      <li>
                        <dl>
                          <dt>&middot; 월정액</dt>
                          <dd>11,000원 (부가세 포함)</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 대상국가</dt>
                          <dd>
                            <ul class="flag_list">
                              <li>
                                <span><img
                                  src="/content/images/icon_flag01.png"
                                  alt=""
                                ></span>중국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag02.png"
                                  alt=""
                                ></span>미국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag03.png"
                                  alt=""
                                ></span>캐나다
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag04.png"
                                  alt=""
                                ></span>홍콩
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag05.png"
                                  alt=""
                                ></span>싱가포르
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag06.png"
                                  alt=""
                                ></span>태국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag07.png"
                                  alt=""
                                ></span>말레이시아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag08.png"
                                  alt=""
                                ></span>방글라데시
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag09.png"
                                  alt=""
                                ></span>몽골
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag10.png"
                                  alt=""
                                ></span>인도
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag11.png"
                                  alt=""
                                ></span>괌
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag12.png"
                                  alt=""
                                ></span>사이판
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag13.png"
                                  alt=""
                                ></span>포르투갈
                              </li>
                            </ul>
                          </dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 가입가능<br>기본요금제</dt>
                          <dd>
                            <ul class="hyphen_list">
                              <li>• T끼리 65</li>
                              <li>• 전국민무한69/75/85/100</li>
                              <li>• band 데이터 6.5G/퍼펙트/퍼펙트S</li>
                              <li>• T시그니처<br>Classic/Master/Classic(구)/Master(구)</li>
                              <li>• T플랜 미디엄/라지/패밀리/Data인피니티</li>
                              <li>• T플랜 안심4G/에센스/스페셜/맥스</li>
                              <li>• 0플랜 미디엄/라지/히어로/슈퍼히어로</li>
                              <li>• 슬림</li>
                              <li>• 5GX스탠다드/프라임/플래티넘 등</li>
                            </ul>
                          </dd>
                        </dl>
                      </li>
                    </ul>
                    <div class="btn single">
                      <a
                        href="https://m.tworld.co.kr/product/callplan?prod_id=NA00004684"
                        class="btn_small"
                        title="00700프리 10"
                        target="_blank"
                      >신청하기</a>
                    </div>
                  </div>
                </li>
                <li class="accordionItem">
                  <div class="tit_box">
                    <h5 class="title">
                      00700프리 15
                    </h5>
                    <dl>
                      <dt><span class="txt_point01">16,500원</span></dt>
                      <dd>(부가세 포함)</dd>
                    </dl>
                    <span class="btn"><img
                      src="/content/images/btn_table_slide.png"
                      alt="펼치기"
                    ></span>
                  </div>

                  <div class="txt_open">
                    <ul class="table_list">
                      <li>
                        <dl>
                          <dt>&middot; 월정액</dt>
                          <dd>16,500원 (부가세 포함)</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 대상국가</dt>
                          <dd>
                            <ul class="flag_list">
                              <li>
                                <span><img
                                  src="/content/images/icon_flag01.png"
                                  alt=""
                                ></span>중국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag02.png"
                                  alt=""
                                ></span>미국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag03.png"
                                  alt=""
                                ></span>캐나다
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag04.png"
                                  alt=""
                                ></span>홍콩
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag05.png"
                                  alt=""
                                ></span>싱가포르
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag06.png"
                                  alt=""
                                ></span>태국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag07.png"
                                  alt=""
                                ></span>말레이시아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag08.png"
                                  alt=""
                                ></span>방글라데시
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag09.png"
                                  alt=""
                                ></span>몽골
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag10.png"
                                  alt=""
                                ></span>인도
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag11.png"
                                  alt=""
                                ></span>괌
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag12.png"
                                  alt=""
                                ></span>사이판
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag13.png"
                                  alt=""
                                ></span>포르투갈
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag14.png"
                                  alt=""
                                ></span>우즈베키스탄
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag15.png"
                                  alt=""
                                ></span>인도네시아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag16.png"
                                  alt=""
                                ></span>베트남
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag17.png"
                                  alt=""
                                ></span>캄보디아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag18.png"
                                  alt=""
                                ></span>파키스탄
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag19.png"
                                  alt=""
                                ></span>독일
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag20.png"
                                  alt=""
                                ></span>오스트리아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag21.png"
                                  alt=""
                                ></span>호주
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag22.png"
                                  alt=""
                                ></span>불가리아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag23.png"
                                  alt=""
                                ></span>이스라엘
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag24.png"
                                  alt=""
                                ></span>콜롬비아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag25.png"
                                  alt=""
                                ></span>마카오
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag26.png"
                                  alt=""
                                ></span>라오스
                              </li>
                            </ul>
                          </dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 가입가능<br>기본요금제</dt>
                          <dd>
                            <ul class="hyphen_list">
                              <li>• T끼리 35/45/55</li>
                              <li>• T끼리 어르신</li>
                              <li>• band 데이터 세이브/1.2G/2.2G/3.5G</li>
                              <li>• band 어르신 세이브/1.2G/2.2G</li>
                              <li>• T플랜 스몰/레귤러</li>
                              <li>• T플랜 세이브/안심2.5G</li>
                              <li>• 0플랜 스몰 등</li>
                            </ul>
                          </dd>
                        </dl>
                      </li>
                    </ul>
                    <div class="btn single">
                      <a
                        href="https://m.tworld.co.kr/product/callplan?prod_id=NA00004685"
                        class="btn_small"
                        title="00700프리 15"
                        target="_blank"
                      >신청하기</a>
                    </div>
                  </div>
                </li>
                <li class="accordionItem">
                  <div class="tit_box">
                    <h5 class="title">
                      00700프리 20
                    </h5>
                    <dl>
                      <dt><span class="txt_point01">22,000원</span></dt>
                      <dd>(부가세 포함)</dd>
                    </dl>
                    <span class="btn"><img
                      src="/content/images/btn_table_slide.png"
                      alt="펼치기"
                    ></span>
                  </div>

                  <div class="txt_open">
                    <ul class="table_list">
                      <li>
                        <dl>
                          <dt>&middot; 월정액</dt>
                          <dd>22,000원 (부가세 포함)</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 대상국가</dt>
                          <dd>
                            <ul class="flag_list">
                              <li>
                                <span><img
                                  src="/content/images/icon_flag01.png"
                                  alt=""
                                ></span>중국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag02.png"
                                  alt=""
                                ></span>미국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag03.png"
                                  alt=""
                                ></span>캐나다
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag04.png"
                                  alt=""
                                ></span>홍콩
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag05.png"
                                  alt=""
                                ></span>싱가포르
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag06.png"
                                  alt=""
                                ></span>태국
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag07.png"
                                  alt=""
                                ></span>말레이시아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag08.png"
                                  alt=""
                                ></span>방글라데시
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag09.png"
                                  alt=""
                                ></span>몽골
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag10.png"
                                  alt=""
                                ></span>인도
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag11.png"
                                  alt=""
                                ></span>괌
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag12.png"
                                  alt=""
                                ></span>사이판
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag13.png"
                                  alt=""
                                ></span>포르투갈
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag14.png"
                                  alt=""
                                ></span>우즈베키스탄
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag15.png"
                                  alt=""
                                ></span>인도네시아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag16.png"
                                  alt=""
                                ></span>베트남
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag17.png"
                                  alt=""
                                ></span>캄보디아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag18.png"
                                  alt=""
                                ></span>파키스탄
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag19.png"
                                  alt=""
                                ></span>독일
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag20.png"
                                  alt=""
                                ></span>오스트리아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag21.png"
                                  alt=""
                                ></span>호주
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag22.png"
                                  alt=""
                                ></span>불가리아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag23.png"
                                  alt=""
                                ></span>이스라엘
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag24.png"
                                  alt=""
                                ></span>콜롬비아
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag25.png"
                                  alt=""
                                ></span>마카오
                              </li>
                              <li>
                                <span><img
                                  src="/content/images/icon_flag26.png"
                                  alt=""
                                ></span>라오스
                              </li>
                            </ul>
                          </dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 가입가능<br>기본요금제</dt>
                          <dd>
                            <ul class="hyphen_list">
                              <li>• T끼리 65</li>
                              <li>• 전국민무한 69/75/85/100</li>
                              <li>• band 데이터 6.5G/퍼펙트/퍼펙트S</li>
                              <li>• T시그니쳐 Classic/Master/Classic(구)/Master(구)</li>
                              <li>• T플랜 미디엄/라지/패밀리/Data인피니티 </li>
                              <li>• T플랜 안심4G/에센스/스페셜/맥스 </li>
                              <li>• 0플랜 미디엄/라지/히어로/슈퍼히어로</li>
                              <li>• 슬림</li>
                              <li>• 5GX스탠다드/프라임/플래티넘 등</li>
                            </ul>
                          </dd>
                        </dl>
                      </li>
                    </ul>
                    <div class="btn single">
                      <a
                        href="https://m.tworld.co.kr/product/callplan?prod_id=NA00004686"
                        class="btn_small"
                        title="00700프리 20"
                        target="_blank"
                      >신청하기</a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <ul class="txt_em m_hide">
              <li><span class="icon_star">*</span>가입한 기본요금제에 따라 가입 가능한 00700프리 부가서비스가 지정되어 있습니다.</li>
              <li><span class="icon_star">*</span>기본요금제의 망외/유선/부가통화 제공량 내에서 추가 통화료 없이 대상국가에 국제전화 이용이 가능합니다.</li>
              <li><span class="icon_star">*</span>기본요금제의 망외/유선/부가통화 제공량 모두 소진 후 대상국가 국제전화 이용 시 초당 1.8원이 적용됩니다.</li>
            </ul>
            <!-- //모바일에서 노출 -->
          </div>

          <div class="use_list_area">
            <div class="use_list">
              <h4>쉽게 따라 하는 이용방법</h4>
              <p class="txt">
                (3G/LTE) T끼리/전국민 무한 또는 band 데이터 요금제 가입 고객이, 00700 프리 부가서비스 가입 후 [00700+국가번호+현지번호]로 이용할 수 있습니다.<br>
                <span><span class="icon_star">*</span>위 방법으로 이용 시 요금제의 망외/유선/부가통화 제공량에서 국제전화 이용 가능합니다.<br>
                  (음성통화 모두 소진 시 초당 1.98원(부가세제외1.8원) 국내음성통화료 적용)</span>
              </p>
              <ul>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>‘00700’ 국제전화 식별번호 입력</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter19.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호 입력 예 : 미국 1</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter04.png"
                      alt=""
                    >
                  </div>
                  <p>상대방의 현지번호 입력<br><span><span class="icon_star">*</span>지역번호 및 휴대폰 번호는 앞 0번 제외</span></p>
                </li>
              </ul>
            </div>
          </div>

          <div class="use_table_area">
            <h4>간편한 00700 프리 가입방법</h4>
            <div class="table_type04">
              <table>
                <caption>유형, 가입/해지 채널, 상세경로 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:34%;">
                  <col style="width:33%;">
                  <col style="width:33%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      유형
                    </th>
                    <th scope="col">
                      가입/해지 채널
                    </th>
                    <th scope="col">
                      상세경로
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      rowspan="3"
                      class="border_r"
                    >
                      가입/해지
                    </td>
                    <td>Tworld</td>
                    <td>온라인/모바일 Tworld</td>
                  </tr>
                  <tr>
                    <td>고객센터</td>
                    <td>1599-0011(SKT고객센터)</td>
                  </tr>
                  <tr>
                    <td>지점/대리점</td>
                    <td>전국 지점 및 대리점</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="notice_area gray">
            <h4 class="title">
              <span class="icon_mark"><img
                src="/content/images/icon_mark01.png"
                alt="주의사항"
              ></span>부가서비스 가입 시 꼭 읽어보세요
            </h4>
            <ul>
              <li><span class="icon_star">*</span>SKT고객 특별할인 SK텔레콤 이용고객에 한해서만 가입이 가능합니다.</li>
              <li><span class="icon_star">*</span>본 서비스는 월 단위 과금상품으로 가입 시점에 관계 없이 월 정액이 부과됩니다.</li>
              <li><span class="icon_star">*</span>SKT고객 특별할인에서 지정한 국가 외 이용 시 기존 SK텔링크 00700 과금 기준이 적용됩니다.</li>
              <li><span class="icon_star">*</span>SKT고객 특별할인 부가서비스와 SK국제전화 00700 요금제는 중복 가입이 가능합니다.</li>
              <li><span class="icon_star">*</span>SKT고객 특별할인 부가서비스 가입은 Tword(온라인/모바일), 고객센터, 지점/대리점을 통해서만 가입 및 해지가 가능합니다.</li>
              <li><span class="icon_star">*</span>본 서비스는 SK텔링크 사정에 따라 별도 안내 없이 제공이 중단될 수 있습니다.</li>
              <li><span class="icon_star">*</span>비정상적이거나 불법적인 방법으로 국제전화를 이용하실 경우 사전 안내 없이 국제전화 요금제 취소 및 이용에 제한이 있을 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <!-- //inter -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'


export default {
  name: 'SktSpecial',
  metaInfo: {
    title: 'SKT 고객 특별할인 | 국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
    this.accordion()
  },
  methods: {
    accordion() {
      const accordionItem = document.querySelectorAll('.accordionItem')
      const accordionTxt = document.querySelectorAll('.txt_open')
      const closeAll = function() {
        accordionItem.forEach(item => {
          item.classList.remove('on')
        })
        accordionTxt.forEach(item2 => {
          item2.style.height = null
          item2.classList.remove('on')
        })
      }
      for(let i=0; i<accordionItem.length; i++) {
        accordionItem[i].addEventListener('click', function() {
          let currentTxt = this.children[1]
          if(this.classList.contains('on')) {
            closeAll()
            this.classList.remove('on')
            currentTxt.style.height = null
            currentTxt.classList.remove('on')
          } else {
            closeAll()
            this.classList.add('on')
            currentTxt.style.height = currentTxt.scrollHeight + 'px'
            currentTxt.classList.add('on')
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1023px) {
  .table_slide .txt_open {
    display: block !important;
    padding: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.5s;
    border-top: none;
    &.on {
      border-top: 1px dotted #8d8d8d;
    }
    .table_list {
      margin: 15px;
    }
    .btn {
      margin: 0 15px 15px;
    }
  }
}
</style>
