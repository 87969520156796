<template>
  <div id="CREDIT_RATING">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg05">
          <h3 class="title">
            재무정보
          </h3>
          <p class="txt">
            SK텔링크의 투명한 경영으로 글로벌 통신회사로<br> 도약하는 기업가치를 높입니다
          </p>
          <img
            src="/content/images/sub_header_cp05.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- tab -->
        <div class="tab_type01 tab3">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li>
              <router-link to="/view/investor/financial">
                주요재무제표
              </router-link>
            </li>
            <li>
              <router-link to="/view/investor/stockholders">
                주주현황
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/investor/credit_rating">
                신용등급
              </router-link>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <div class="fs_table">
            <div class="table_title">
              <h4>기업어음 및<br class="m_br"> 전자단기사채 신용등급</h4>
              <h5 class="rating">
                A2+
              </h5>
            </div>
            <div class="table_type04 table_rating">
              <table>
                <caption>구분, 2015, 2016, 2017, 2018, 2019 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:34%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      구분
                    </th>
                    <th scope="col">
                      2015년
                    </th>
                    <th scope="col">
                      2016년
                    </th>
                    <th scope="col">
                      2017년
                    </th>
                    <th scope="col">
                      2018년
                    </th>
                    <th scope="col">
                      2019년
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>NICE신용평가(주)</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>한국기업평가(주)</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>한국신용평가(주)</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="img_box">
              <img
                src="/content/images/img_cp_rating01_2019.png"
                class="w_hide"
                alt="기업어음 및 전자단기사채 신용등급 NICE신용평가(주), 한국기업평가(주), 한국신용평가(주) 2015년부터 2018년까지 A2+ 신용등급입니다."
              >
              <img
                src="/content/images/img_cp_rating01_m_2019.png"
                class="m_hide"
                alt="기업어음 및 전자단기사채 신용등급 NICE신용평가(주), 한국기업평가(주), 한국신용평가(주) 2015년부터 2018년까지 A2+ 신용등급입니다."
              >
              <br><br><br>
              ※ 2017년 정기평가 이후 신용평가사의 신용등급을 받지 않으며, 신용조회사(이크레더블) 신용등급은 A+ 입니다.
            </div>
          </div>

          <div class="fs_table">
            <div class="table_title">
              <h4>회사채 신용등급</h4>
              <h5 class="rating">
                A+
              </h5>
            </div>
            <div class="table_type04 table_rating">
              <table>
                <caption>구분, 2012, 2013, 2014, 2015, 2016 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:34%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      구분
                    </th>
                    <th scope="col">
                      2012년
                    </th>
                    <th scope="col">
                      2013년
                    </th>
                    <th scope="col">
                      2014년
                    </th>
                    <th scope="col">
                      2015년
                    </th>
                    <th scope="col">
                      2016년
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>NICE신용평가(주)</td>
                    <td>A</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>한국기업평가(주)</td>
                    <td>A</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>한국신용평가(주)</td>
                    <td>A</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="img_box">
              <img
                src="/content/images/img_cp_rating02.png"
                class="w_hide"
                alt="회사채 신용등급 NICE신용평가(주), 한국기업평가(주), 한국신용평가(주) 2011년~2012년 A등급 2013년~2015년 A+등급입니다."
              >
              <img
                src="/content/images/img_cp_rating02_m.png"
                class="m_hide"
                alt="회사채 신용등급 NICE신용평가(주), 한국기업평가(주), 한국신용평가(주) 2011년~2012년 A등급 2013년~2015년 A+등급입니다."
              >
              <br><br>
              ※ 2016년 이후 회사채 발행내역이 없어 회사채 신용등급을 받지 않습니다.
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from '../../components/SummitControl.vue';

export default {
  name: 'CreditRating',
  metaInfo: {
    title: '신용등급 | 재무정보 | 투자정보 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
