import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import Meta from "vue-meta";
import VueGtag from "vue-gtag";

// import api from '@/api'
import NotFound from "@/pages/error/NotFound.vue";
import SystemError from "@/pages/error/SystemError.vue";

import Main from "@/pages/Main.vue";
import IntroduceIntro from "@/pages/introduce/Intro.vue";
import IntroduceVision from "@/pages/introduce/Vision.vue";
import IntroduceMilestone from "@/pages/introduce/Milestone.vue";
import IntroduceCibi from "@/pages/introduce/Cibi.vue";
import Esg from "@/pages/introduce/Esg.vue"; //20220509 추가, baro 클럽 진행상황에 따라
import Financial from "@/pages/investor/Financial.vue";
import Stockholders from "@/pages/investor/Stockholders.vue";
import CreditRating from "@/pages/investor/CreditRating.vue";
import ElecNotice from "@/pages/investor/ElecNotice.vue";
import Talent from "@/pages/recruit/Talent.vue";
import HrCulture from "@/pages/recruit/HrCulture.vue";
import JobNotice from "@/pages/recruit/JobNotice.vue";
import JobView from "@/pages/recruit/JobView.vue";
import background from "@/pages/ethics/background.vue"; //20220804 추가
import guideline from "@/pages/ethics/guideline.vue"; //20220804 추가
import consult from "@/pages/ethics/consult.vue"; //20220804 추가
// import ItIntro from '@/pages/business/Intro.vue'// 20221021 주석, 인트로 교체
import Business00700intro from "@/pages/business/00700intro.vue"; //20221021 추가
import BusinessSms from "@/pages/business/Sms.vue";
import InterCall from "@/pages/business/InterCall.vue";
import InterSms from "@/pages/business/InterSms.vue";
import FreeTrial from "@/pages/business/FreeTrial.vue";
import CallingPlanRecommanded from "@/pages/business/CallingPlanRecommanded.vue";
import CallingPlanDomestic from "@/pages/business/CallingPlanDomestic.vue";
import CallingPlanSpecified from "@/pages/business/CallingPlanSpecified.vue";
import CallingPlanFlatRate from "@/pages/business/CallingPlanFlatRate.vue";
import CallingPlan3plus from "@/pages/business/CallingPlan3plus.vue";
import CallingPlanFree3000 from "@/pages/business/CallingPlanFree3000.vue";
import SktSpecial from "@/pages/business/SktSpecial.vue";
import Business00700app from "@/pages/business/00700app.vue";
import Inmarsat from "@/pages/business/Inmarsat.vue";
import Vsat from "@/pages/business/Vsat.vue";
import Sk7mobile from "@/pages/business/Sk7mobile.vue";
import Usage from "@/pages/customer/Usage.vue";
import CallingPlanRequest from "@/pages/customer/CallingPlanRequest.vue";
import Terms from "@/pages/customer/Terms.vue";
import CostomerServiceInfo from "@/pages/customer/CostomerServiceInfo.vue";
import Faq from "@/pages/customer/Faq.vue";
import OnlineConsult from "@/pages/customer/OnlineConsult.vue";
import NoticeList from "@/pages/customer/NoticeList.vue";
import NoticeContents from "@/pages/customer/NoticeContents.vue";
import PrivacyGuide from "@/pages/customer/PrivacyGuide.vue";
import PrivacyGuideIllegalTM from "@/pages/customer/PrivacyGuideIllegalTM.vue";
import PrivacyGuideMsafer from "@/pages/customer/PrivacyGuideMsafer.vue";
import PrivacyGuideSmishing from "@/pages/customer/PrivacyGuideSmishing.vue";
import PrivacyGuidePhishing from "@/pages/customer/PrivacyGuidePhishing.vue";
import PrivacyGuidePharming from "@/pages/customer/PrivacyGuidePharming.vue";
import PrivacyGuideSpamMessage from "@/pages/customer/PrivacyGuideSpamMessage.vue";
import PressList from "@/pages/media/PressList.vue";
import PressContents from "@/pages/media/PressContents.vue";
import AdvertisementVideo from "@/pages/media/AdvertisementVideo.vue";
import AdvertisementAudio from "@/pages/media/AdvertisementAudio.vue";
import AdvertisementPaper from "@/pages/media/AdvertisementPaper.vue";
import Sitemap from "@/pages/Sitemap.vue";
import EngMain from "@/pages/eng/Main.vue";
import EngIntroCEO from "@/pages/eng/IntroCEO.vue";
import EngVision from "@/pages/eng/Vision.vue";
import EngMilestone from "@/pages/eng/Milestone.vue";
import EngStockholders from "@/pages/eng/Stockholders.vue";
import EngCreditRating from "@/pages/eng/CreditRating.vue";
import EngCibi from "@/pages/eng/Cibi.vue";
import EngBusiness from "@/pages/eng/Business.vue";
import EngContactUs from "@/pages/eng/ContactUs.vue";
import OnlineConsultReply from "@/pages/customer/OnlineConsultReply.vue";

import CertResult from "@/pages/CertResult.vue";
// event
import AsianaMileage from "@/pages/partnership/AsianaMileage.vue";
import OcbPoint from "@/pages/partnership/OcbPoint.vue";
import Tmembership from "@/pages/partnership/Tmembership.vue";
import TmembershipCheck from "@/pages/partnership/TmembershipCheck.vue";
import TmembershipCancel from "@/pages/partnership/TmembershipCancel.vue";
import KoreanairSkypass from "@/pages/partnership/KoreanairSkypass.vue";
import Global from "@/pages/partnership/Global.vue";
import GlobalA from "@/pages/partnership/GlobalA.vue";
import GlobalB from "@/pages/partnership/GlobalB.vue";
import AllPass from "@/pages/partnership/AllPass.vue";
import AllPassOption from "@/pages/partnership/AllPassOption.vue";
import Event00780Global from "@/pages/partnership/Event00780Global.vue";
import Lolacard from "@/pages/partnership/Lolacard.vue";
import SktIb from "@/pages/partnership/SktIb.vue";
import SktFree5000 from "@/pages/partnership/SktFree5000.vue";
import SktFree10000 from "@/pages/partnership/SktFree10000.vue";
import SktFree15000 from "@/pages/partnership/SktFree15000.vue";
import AsianaMileageEvent from "@/pages/partnership/AsianaMileageEvent.vue";
//20210419 추가
import NcsiNo1 from "@/pages/partnership/NcsiNo1.vue";
import PrivacyIntro from "@/pages/privacy/PrivacyIntro.vue";
//20210429 추가
import TmembershipNew from "@/pages/partnership/TmembershipNew.vue";
import TmembershipNewCheck from "@/pages/partnership/TmembershipNewCheck.vue";
import TmembershipNewCancel from "@/pages/partnership/TmembershipNewCancel.vue";
//20210624 추가
import KsSqiNo1 from "@/pages/partnership/KsSqiNo1.vue";
//20210708 추가
import AllPassOptionChange from "@/pages/partnership/AllPassOptionChange.vue";
//20210901 추가
import welcomeGift from "@/pages/partnership/welcomeGift.vue";
//20211006 추가
import eventWelcome from "@/pages/partnership/eventWelcome.vue";
//20220315 추가
import TmembershipBaskin from "@/pages/partnership/TmembershipBaskin.vue";
//20220405 추가
import FriendRecommend from "@/pages/partnership/FriendRecommend.vue";
//20220429 추가
import NcsiNo1_22 from "@/pages/partnership/NcsiNo1_22.vue";
//20220510 추가
import BaroClub from "@/pages/partnership/BaroClub.vue";
//20220511 추가
import BaroClubCheck from "@/pages/partnership/BaroClubCheck.vue";
//20220523 추가
import Sk00701 from "@/pages/partnership/Sk00701.vue";
import Sk00701Detail from "@/pages/partnership/Sk00701Detail.vue";
//20220613 추가
import Holic from "@/pages/partnership/Holic.vue";
//20220728 추가
import HolicEvent from "@/pages/partnership/HolicEvent.vue";
//20220907 추가
import SktFree0070150 from "@/pages/partnership/SktFree0070150.vue";
//20220907 추가
import SktFree0070110 from "@/pages/partnership/SktFree0070110.vue";
//20230130 추가
import SktFree0070110FR02 from "@/pages/partnership/SktFree0070110FR02.vue";
//20230220 추가
import Free00701Agent from "@/pages/partnership/Free00701Agent.vue";

// 20240826 추가
import EsgIntro from "@/pages/esg/Intro.vue";
import EsgSafety from "@/pages/esg/Safety.vue";
import EsgHumanRight from "@/pages/esg/HumanRight.vue";
import EsgEthicalManagement from "@/pages/esg/EthicalManagement.vue";
import EsgEnvironment from "@/pages/esg/Environment.vue";
import EngEthics from "@/pages/eng/Ethics.vue";

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "*",
    redirect: "/main",
  },
  // {
  //   path: '*',
  //   name: 'notfound',
  //   component: NotFound
  // },
  {
    path: "/view/error/systemerror",
    name: "systemerror",
    component: SystemError,
  },
  {
    path: "/",
    redirect: "/main",
  },
  {
    path: "/main",
    name: "main",
    component: Main,
  },
  {
    path: "/view/introduce/intro",
    name: "intro",
    component: IntroduceIntro,
  },
  {
    path: "/view/introduce/vision",
    name: "vision",
    component: IntroduceVision,
  },
  {
    path: "/view/introduce/milestone",
    name: "milestone",
    component: IntroduceMilestone,
  },
  {
    path: "/view/introduce/cibi",
    name: "cibi",
    component: IntroduceCibi,
  },
  // {
  //   //20220509 추가
  //   path: "/view/introduce/esg",
  //   name: "esg",
  //   component: Esg,
  // },
  {
    path: "/view/investor/financial",
    name: "financial",
    component: Financial,
  },
  {
    path: "/view/investor/stockholders",
    name: "stockholders",
    component: Stockholders,
  },
  {
    path: "/view/investor/credit_rating",
    name: "credit_rating",
    component: CreditRating,
  },
  {
    path: "/view/investor/elec_notice",
    name: "elec_notice",
    component: ElecNotice,
  },
  {
    path: "/view/recruit/talent",
    name: "talent",
    component: Talent,
  },
  {
    path: "/view/recruit/hr_culture",
    name: "hr_culture",
    component: HrCulture,
  },
  {
    path: "/view/recruit/job_notice",
    name: "job_notice",
    component: JobNotice,
  },
  {
    path: "/view/recruit/job_view/:id",
    name: "job_view",
    component: JobView,
  },
  // {
  //   //20220804 추가
  //   path: "/view/ethics/background",
  //   name: "background",
  //   component: background,
  // },
  // {
  //   //20220804 추가
  //   path: "/view/ethics/guideline",
  //   name: "guideline",
  //   component: guideline,
  // },
  // {
  //   //20220804 추가
  //   path: "/view/ethics/consult",
  //   name: "consult",
  //   component: consult,
  // },
  // { // 20221021 주석, 인트로 교체
  //   path: '/view/business/intro',
  //   name: 'it_intro',
  //   component: ItIntro
  // },
  {
    // 20221021 주석, 인트로 교체
    path: "/view/business/00700intro",
    name: "Business00700intro",
    component: Business00700intro,
  },
  {
    path: "/view/business/sms",
    name: "BusinessSms",
    component: BusinessSms,
  },
  // {
  //   path: '/view/business/inter_call',
  //   name: 'inter_call',
  //   component: InterCall
  // },
  // {
  //   path: '/view/business/inter_sms',
  //   name: 'inter_sms',
  //   component: InterSms
  // },
  // {
  //   path: '/view/business/free_trial',
  //   name: 'free_trial',
  //   component: FreeTrial
  // },
  // {
  //   path: '/view/business/calling_plan_recommanded',
  //   name: 'calling_plan_recommanded',
  //   component: CallingPlanRecommanded
  // },
  // {
  //   path: '/view/business/calling_plan_domestic',
  //   name: 'calling_plan_domestic',
  //   component: CallingPlanDomestic
  // },
  // {
  //   path: '/view/business/calling_plan_specified',
  //   name: 'calling_plan_specified',
  //   component: CallingPlanSpecified
  // },
  // {
  //   path: '/view/business/calling_plan_flat_rate',
  //   name: 'calling_plan_flat_rate',
  //   component: CallingPlanFlatRate
  // },
  // {
  //   path: '/view/business/calling_plan_3plus',
  //   name: 'calling_plan_3plus',
  //   component: CallingPlan3plus
  // },
  // {
  //   path: '/view/business/calling_plan_free3000',
  //   name: 'calling_plan_free3000',
  //   component: CallingPlanFree3000
  // },
  // {
  //   path: '/view/business/skt_special',
  //   name: 'skt_special',
  //   component: SktSpecial
  // },
  // {
  //   path: '/view/business/00700app',
  //   name: '00700app',
  //   component: Business00700app
  // },
  {
    path: "/view/business/inmarsat",
    name: "inmarsat",
    component: Inmarsat,
  },
  {
    path: "/view/business/vsat",
    name: "vsat",
    component: Vsat,
  },
  {
    path: "/view/business/sk7mobile",
    name: "sk7mobile",
    component: Sk7mobile,
  },
  // {
  //   path: '/view/customer/usage',
  //   name: 'usage',
  //   component: Usage
  // },
  // {
  //   path: '/view/customer/calling_plan_request',
  //   name: 'calling_plan_request',
  //   component: CallingPlanRequest
  // },
  // {
  //   path: '/view/customer/terms',
  //   name: 'terms',
  //   component: Terms
  // },
  {
    path: "/view/customer/costomer_service_info",
    name: "costomer_service_info",
    component: CostomerServiceInfo,
  },
  {
    path: "/view/customer/faq",
    name: "faq",
    component: Faq,
  },
  // {
  //   path: '/view/customer/online_consult',
  //   name: 'online_consult',
  //   component: OnlineConsult
  // },
  {
    path: "/view/customer/notice_list",
    name: "notice_list",
    component: NoticeList,
  },
  {
    path: "/view/customer/notice_contents/:id",
    name: "notice_contents",
    component: NoticeContents,
    props: true,
  },
  {
    path: "/view/customer/privacy_guide",
    name: "privacy_guide",
    component: PrivacyGuide,
  },
  {
    path: "/view/customer/privacy_guide_illegal_TM",
    name: "privacy_guide_illegal_TM",
    component: PrivacyGuideIllegalTM,
  },
  {
    path: "/view/customer/privacy_guide_Msafer",
    name: "privacy_guide_Msafer",
    component: PrivacyGuideMsafer,
  },
  {
    path: "/view/customer/privacy_guide_smishing",
    name: "privacy_guide_smishing",
    component: PrivacyGuideSmishing,
  },
  {
    path: "/view/customer/privacy_guide_phishing",
    name: "privacy_guide_phishing",
    component: PrivacyGuidePhishing,
  },
  {
    path: "/view/customer/privacy_guide_pharming",
    name: "privacy_guide_pharming",
    component: PrivacyGuidePharming,
  },
  {
    path: "/view/customer/privacy_guide_spam_message",
    name: "privacy_guide_spam_message",
    component: PrivacyGuideSpamMessage,
  },
  {
    path: "/view/media/press_list",
    name: "press_list",
    component: PressList,
  },
  {
    path: "/view/media/press_contents/:id",
    name: "press_contents",
    component: PressContents,
  },
  {
    path: "/view/media/advertisement_video",
    name: "advertisement_video",
    component: AdvertisementVideo,
  },
  {
    path: "/view/media/advertisement_audio",
    name: "advertisement_audio",
    component: AdvertisementAudio,
  },
  {
    path: "/view/media/advertisement_paper",
    name: "advertisement_paper",
    component: AdvertisementPaper,
  },
  {
    path: "/view/sitemap",
    name: "sitemap",
    component: Sitemap,
  },
  {
    path: "/eng/main",
    name: "eng_main",
    component: EngMain,
  },
  {
    path: "/eng/intro_CEO",
    name: "eng_intro_CEO",
    component: EngIntroCEO,
  },
  {
    path: "/eng/vision",
    name: "eng_vision",
    component: EngVision,
  },
  {
    path: "/eng/milestone",
    name: "eng_milestone",
    component: EngMilestone,
  },
  {
    path: "/eng/stockholders",
    name: "eng_stockholders",
    component: EngStockholders,
  },
  {
    path: "/eng/credit_rating",
    name: "eng_credit_rating",
    component: EngCreditRating,
  },
  {
    path: "/eng/cibi",
    name: "eng_cibi",
    component: EngCibi,
  },
  {
    path: "/eng/business",
    name: "business",
    component: EngBusiness,
  },
  {
    path: "/eng/contact_us",
    name: "contact_us",
    component: EngContactUs,
  },
  {
    path: "/view/customer/online_consult_reply",
    name: "online_consult_reply",
    component: OnlineConsultReply,
  },

  {
    path: "/cert-result",
    name: "certResult",
    component: CertResult,
  },

  // event
  // {
  //   path: '/partnership/asiana_mileage',
  //   name: 'asiana_mileage',
  //   component: AsianaMileage
  // },
  // {
  //   path: '/partnership/ocb_point',
  //   name: 'ocb_point',
  //   component: OcbPoint
  // },
  // {
  //   path: '/partnership/tmembership',
  //   name: 'tmembership',
  //   component: Tmembership
  // },
  // {
  //   path: '/partnership/tmembership_check',
  //   name: 'tmembership_check',
  //   component: TmembershipCheck
  // },
  // {
  //   path: '/partnership/tmembership_cancel',
  //   name: 'tmembership_cancel',
  //   component: TmembershipCancel
  // },
  // {
  //   path: '/partnership/koreanair_skypass',
  //   name: 'koreanair_skypass',
  //   component: KoreanairSkypass
  // },
  // {
  //   path: '/partnership/global',
  //   name: 'global',
  //   component: Global
  // },
  // {
  //   path: '/partnership/global_a',
  //   name: 'global_a',
  //   component: GlobalA
  // },
  // {
  //   path: '/partnership/global_b',
  //   name: 'global_b',
  //   component: GlobalB
  // },
  // {
  //   path: '/partnership/all_pass',
  //   name: 'all_pass',
  //   component: AllPass
  // },
  // {
  //   path: '/partnership/all_pass_option',
  //   name: 'all_pass_option',
  //   component: AllPassOption
  // },
  // {
  //   path: '/partnership/00780_global',
  //   name: '00780_global',
  //   component: Event00780Global
  // },
  // {
  //   path: '/partnership/lolacard',
  //   name: 'lolacard',
  //   component: Lolacard
  // },
  // {
  //   path: '/partnership/skt_ib',
  //   name: 'skt_ib',
  //   component: SktIb
  // },
  // {
  //   path: '/partnership/skt_free_5000',
  //   name: 'skt_free_5000',
  //   component: SktFree5000
  // },
  // {
  //   path: '/partnership/skt_free_10000',
  //   name: 'skt_free_10000',
  //   component: SktFree10000
  // },
  // {
  //   path: '/partnership/skt_free_15000',
  //   name: 'skt_free_15000',
  //   component: SktFree15000
  // },
  // {
  //   path: '/partnership/asiana_mileage_event',
  //   name: 'asiana_mileage_event',
  //   component: AsianaMileageEvent
  // },
  // {//20210419 추가
  //   path: '/partnership/ncsi_no1',
  //   name: 'ncsi_no1',
  //   component: NcsiNo1
  // },
  // {//20210429 추가
  //   path: '/partnership/tmembershipnew',
  //   name: 'tmembershipnew',
  //   component: TmembershipNew
  // },
  // {//20210429 추가
  //   path: '/partnership/tmembershipnew_check',
  //   name: 'tmembershipnew_check',
  //   component: TmembershipNewCheck
  // },
  // {//20210429 추가
  //   path: '/partnership/tmembershipnew_cancel',
  //   name: 'tmembershipnew_cancel',
  //   component: TmembershipNewCancel
  // },
  // {//20210624 추가
  //   path: '/partnership/KsSqiNo1',
  //   name: 'KsSqiNo1',
  //   component: KsSqiNo1
  // },
  // {//20210708 추가
  //   path: '/partnership/all_pass_option_change',
  //   name: 'all_pass_option_change',
  //   component: AllPassOptionChange
  // },
  // {//20210901 추가
  //   path: '/partnership/welcome_gift',
  //   name: 'welcome_gift',
  //   component: welcomeGift
  // },
  // {//20211006 추가
  //   path: '/partnership/event_welcome',
  //   name: 'event_welcome',
  //   component: eventWelcome
  // },
  // {//220315 추가
  //   path: '/partnership/tmembership_baskin',
  //   name: 'tmembership_baskin',
  //   component: TmembershipBaskin
  // },
  // {//20220405 추가
  //   path: '/partnership/friend_recommend',
  //   name: 'friend_recommend',
  //   component: FriendRecommend
  // },
  // {//20220429 추가
  //   path: '/partnership/ncsi_no1_22',
  //   name: 'ncsi_no1_22',
  //   component: NcsiNo1_22
  // },
  // {//20220510 추가
  //   path: '/partnership/baroclub',
  //   name: 'baroclub',
  //   component: BaroClub
  // },
  // {//20220511 추가
  //   path: '/partnership/baroclubcheck',
  //   name: 'baroclubcheck',
  //   component: BaroClubCheck
  // },
  {
    //20220523 추가
    path: "/partnership/sk00701",
    name: "sk00701",
    component: Sk00701,
  },
  {
    //20220523 추가
    path: "/partnership/sk00701detail",
    name: "sk00701detail",
    component: Sk00701Detail,
  },
  // {//20220613 추가
  //   path: '/partnership/holic',
  //   name: 'holic',
  //   component: Holic
  // },
  // {//20220728 추가
  //   path: '/partnership/holic_event',
  //   name: 'holic_event',
  //   component: HolicEvent
  // },
  {
    //20220907 추가
    path: "/partnership/00701_50Free",
    name: "holic_event",
    component: SktFree0070150,
  },
  {
    //20220907 추가
    path: "/partnership/00701_10Free",
    name: "holic_event",
    component: SktFree0070110,
  },
  {
    //20230130 추가
    path: "/partnership/00701_10Free_FR02",
    name: "FREE_EVENT",
    component: SktFree0070110FR02,
  },
  {
    //20230220 추가
    path: "/partnership/00701_agent",
    name: "AgentFreeEvent",
    component: Free00701Agent,
  },
  {
    path: "/privacy/intro",
    name: "privacy_intro",
    component: PrivacyIntro,
  },
  // 20240826 추가
  {
    path: "/view/esg/intro",
    name: "esg_intro",
    component: EsgIntro,
  },
  {
    path: "/view/esg/environment",
    name: "esg_environment",
    component: EsgEnvironment,
  },
  {
    path: "/view/esg/safety",
    name: "esg_safety",
    component: EsgSafety,
  },
  {
    path: "/view/esg/human_right",
    name: "esg_human_right",
    component: EsgHumanRight,
  },
  {
    path: "/view/esg/ethical_management",
    name: "esg_ethical_management",
    component: EsgEthicalManagement,
  },
  {
    path: "/eng/ethics",
    name: "eng_ethics",
    component: EngEthics,
  },
  {
    // /os/cs/av/cs_av_advice_reg_pw.do 으로 접근시 외부 특정 화면으로 Redirect
    path: "/os/cs/av/cs_av_advice_reg_pw.do",
    beforeEnter(to, from, next) {
      window.location.href = `https://www.sktelink.com${to.path}${window.location.search}`;
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

Vue.use(
  VueGtag,
  {
    config: { id: "UA-48670326-1" },
  },
  router
);

const passRouterCert = (path, next) => {
  if (path.indexOf("/partnership/") !== -1) {
    document.getElementById("event-css").href = store.getters.EVENT_CSS;
  } else {
    document.getElementById("event-css").href = "";
  }
  next();
};

router.beforeEach((to, from, next) => {
  if (to.path === "/view/recruit/job_notice") {
    next("/main");
  } else {
    passRouterCert(to.path, next);
  }
});

router.afterEach((to, from) => {
  setTimeout(() => {
    if (document.getElementById("wrap").scrollTop > 0) {
      document.getElementById("wrap").scrollTop = 0;
    }
  }, 100);
});

export default router;
