<template>
  <div id="CALLING_PLAN3PLUS">
    <Header />
    <!-- 컨텐츠 -->
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />
      <div id="contents">
        <div class="sub_header bg07">
          <h3 class="title">
            무료통화제공형
          </h3>
          <p class="txt">
            국제전화 이용 시 사용패턴에 맞는 할인이 필요한 고객님을 위해<br>SK국제전화 00700이 준비한 HOT한 요금제!
          </p>
          <img
            src="/content/images/sub_header_inter07-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <div class="tab_type01">
          <ul>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/business/calling_plan_3plus">
                3플러스 요금제
              </router-link>
            </li>
            <li>
              <router-link to="/view/business/calling_plan_free3000">
                안심3000 요금제
              </router-link>
            </li>
          </ul>
        </div>

        <!-- inter -->
        <div class="inter mb0">
          <!-- 하단 여백없을시 class mb0 추가 -->
          <div class="sub_title sub-title-mobile">
            <h4 class="title">
              요금걱정으로 3분 미만<br class="m_br"> 통화하시는 분을 위한<br>
              <span class="txt_point01">00700  3플러스 요금제</span>
            </h4>
            <p class="txt">
              <span class="txt_point01">매 통화당 3분 통화 시 3분 무료혜택이 제공되는<br class="m_br"> 00700 3플러스 요금제!</span><br>
              한 통화를 길~게 사용하면서<br class="m_br"> 할인까지 제공되는 국제전화!
            </p>
          </div>

          <div class="advice_list">
            <h4> 이런 고객님이 사용하면 좋아요!   </h4>
            <p><span class="hashtag"># 무료통화</span><span class="hashtag"># 긴통화</span><span class="hashtag"># 장거리연애</span></p>
            <div class="advice_scroll">
              <div>
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter04.png"
                        alt=""
                      >
                    </div>
                    <p>무료통화 혜택을 원하는 00700 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter05.png"
                        alt=""
                      >
                    </div>
                    <p>해외로 길~게 안부 전하는 00700 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter06.png"
                        alt=""
                      >
                    </div>
                    <p>해외에 연인이 있는 롱디족 <br> (장거리 연애 고객)</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="advice_list white">
            <h4>거부할 수 없는 매력은<br class="m_hide"> 무엇인가요?</h4>
            <div class="advice_scroll">
              <div>
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub04.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>3+3 혜택</dt>
                      <dd>1+1보다 더욱 커진 국제전화 3+3 혜택!<br> 3분 이상 통화 시 추가 3분의 무료통화가<br> 매 통화마다 제공됩니다.</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub02.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>사용량에 따라<br> 무료통화 변경 가능</dt>
                      <dd>짧은 통화는 이제 그만!<br> 3분 무료통화가 부족한 고객님이시라면<br> 5분 무료통화 혜택으로 변경해보세요.</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub05.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>가입비 100% 무료</dt>
                      <dd>무료통화를 받기 위해 필요한 요금제 가입비 0원!<br> 남녀노소, 국내거주 외국인까지 부담 없이<br> 가입하여 이용할 수 있습니다. </dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="inter_seach">
            <h4><strong>3플러스 요금제 국가별 요금과</strong> 할인금액 을 확인해보세요</h4>
            <div class="type03">
              <div class="select_box">
                <label
                  for="pay_select01"
                  aria-hidden="true"
                >3플러스 요금제</label>
                <select
                  id="pay_select01"
                  v-model="formData.chrg_cd"
                  title="요금제 선택"
                  name="i_BaseMin"
                >
                  <option
                    v-for="(item, index) in price"
                    :key="index"
                    :value="item.chrg_cd"
                    :selected="item.chrg_cd == formData.chrg_cd"
                  >
                    {{ item.chrg_nm }}
                  </option>
                </select>
              </div>
              <div class="section">
                <Autocomplete
                  v-model="formData.natl_cd"
                  :source="country"
                  :results-display="'natl_nm'"
                  :results-value="'natl_cd'"
                  :clear-button-icon="''"
                  :placeholder="'국가명 입력'"
                  @selected="selectCountry"
                  @keyupEnter="inquire"
                />

                <input
                  id="i_ReqMin"
                  v-model.number="formData.use_time"
                  type="text"
                  title="이용시간(분) 입력"
                  placeholder="이용시간(분)"
                  name="i_ReqMin"
                  style="ime-mode: disabled; text-align: right"
                  oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  autocomplete="off"
                  @keyup.enter="inquire"
                >
                <button
                  type="button"
                  class="btn_type01 btn_search"
                  @click="inquire"
                >
                  검색
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="resultAreaOpen"
            class="result_area"
          >
            <div class="table_type04">
              <table>
                <caption>3플러스 요금제, 표준요금, 할인금액, 할인율 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      {{ selectedPrice }}
                    </th>
                    <th scope="col">
                      표준요금
                    </th>
                    <th scope="col">
                      할인금액
                    </th>
                    <th scope="col">
                      할인율
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ result.price | comma }}원</td>
                    <td>{{ result.price_00700 | comma }}원</td>
                    <td><span class="discount">{{ result.discount_amount | comma }}원</span></td>
                    <td>{{ result.discount_rate }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <ul class="txt_em">
              <li><span class="icon_star">*</span>최대 50% 할인 제공</li>
              <li><span class="icon_star">*</span>과금 기준 : 3플러스 요금제(원/분), 표준요금(원/초)</li>
              <li><span class="icon_star">*</span>해당 요금 적용은 요금제 가입일시부터 적용됩니다.</li>
              <li><span class="icon_star">*</span>표기된 요금은 VAT가 포함된 요금이며, 상기 표준요금은 비교를 위해 분으로 환산된 금액으로 표기되었습니다.</li>
            </ul>
          </div>
          <div class="btn_center single line_top">
            <a
              class="btn_type01"
              @click="certKcbToken('3p')"
            >
              3플러스 요금제 신청하기
            </a>
          </div>

          <div class="payment_area">
            <h4 class="title">
              더 큰 폭의 혜택이 필요하다면?
            </h4>
            <div class="img_box auto">
              <span class="w_hide"><img
                src="/content/images/img_inter_pay01.png"
                alt="5분 통화하면 5분 무료!"
                style="width: auto;"
              ></span>
              <span class="m_hide"><img
                src="/content/images/img_inter_pay01-m.png"
                alt="5분 통화하면 5분 무료!"
              ></span>
            </div>
            <h5 class="title02">
              5플러스 요금제
            </h5>
            <p class="txt2">
              <span class="txt_point01">5분 통화 시 5분 무료혜택이 제공되는 00700 5플러스 요금제!</span><br>
              3분 보다 더 긴~ 무료통화 할인으로 더 커진 혜택!
            </p>
            <div class="btn_center single">
              <a
                class="btn_type01"
                @click="certKcbToken('5p')"
              >
                5플러스 요금제 신청하기
              </a>
            </div>
          </div>

          <div class="use_list_area">
            <div class="use_list">
              <h4>쉽게 따라 하는 이용방법</h4>
              <p>한국에서 캐나다 오타와 지역의 <span>휴대폰으로 전화를 걸 경우</span></p>
              <ul>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>00700 국제전화 식별번호</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter05.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호(캐나다 : 1)</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter38.png"
                      alt=""
                    >
                  </div>
                  <p>상대방 휴대폰 번호(613-123-4567)<br><span><span class="icon_star">*</span>휴대폰 번호는 0번 제외</span></p>
                </li>
              </ul>
            </div>

            <div class="use_list">
              <p>한국에서 캐나다 오타와 지역의 <span>일반전화로 전화를 걸 경우</span></p>
              <ul class="four">
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>00700 국제전화 식별번호</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter05.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호(캐나다 : 1)</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter30.png"
                      alt=""
                    >
                  </div>
                  <p>지역번호(오타와  : 613)<br><span><span class="icon_star">*</span>지역번호 번호는<br class="m_hide"> 0번 제외</span></p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter40.png"
                      alt=""
                    >
                  </div>
                  <p>상대방  번호(123-4567)</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="notice_area gray">
            <h4 class="title">
              <span class="icon_mark"><img
                src="/content/images/icon_mark01.png"
                alt="주의사항"
              ></span>요금제 가입 시 꼭 읽어보세요
            </h4>
            <ul>
              <li><span class="icon_star">*</span>국제전화 00700 국내통화요금제는 6개월간  미 사용 시 자동 취소될 수 있습니다.</li>
              <li><span class="icon_star">*</span>번호 변경 시, 요금제 가입을 다시 신청해야만 기존 혜택이 유지됩니다. </li>
              <li><span class="icon_star">*</span>국제전화 요금제 변경 또는 재가입 신청은 홈페이지 내 마이페이지 또는 고객센터 1599-00700(평일 09시~18시)에서 변경/수정 가능합니다.</li>
              <li><span class="icon_star">*</span>비정상적이거나 불법적인 방법으로 국제전화를 이용할 경우 사전 안내 없이 국제전화 요금제 취소 및 이용에 제한이 있을 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <!-- //inter -->
      </div>
    </div>
    <!--// 컨텐츠 -->
    <Footer />
    <ApplyPlan
      :is-visible.sync="popup.applyPlan.isVisible"
      :type="popup.applyPlan.type"
      :code="popup.applyPlan.code"
      :plan-name="popup.applyPlan.planName"
      :usage-plan="popup.applyPlan.usagePlan"
      :cert-info="certResult"
      @click="ApplyPlan"
    />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'
import Autocomplete from '@/components/Autocomplete'
import ApplyPlan from '@/components/popup/ApplyPlan'

export default {
  name: 'CallingPlan3plus',
  metaInfo: {
    title: '3플러스 요금제 | 무료통화 제공형 | 국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
    Autocomplete,
    ApplyPlan
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      formData: {
        chrg_cd: 'COSK3',
        natl_cd: '',
        use_time: ''
      },
      selectedCountry: {},
      country: [],
      price: [],
      resultAreaOpen: false,
      result: {},
      selectedPrice: '',
      popup: {
        applyPlan: {
          isVisible: false,
          type: '',
          planName: '',
          code: ''
        }
      },
      certResult: {},
      planningName: ''
    }
  },
  computed: {
    token() {
      return this.$store.getters.KCB_CERT_KEY
    }
  },
  created () {
    api.ItsvcGetPreFreeDedtPricePlanInfo({ req_tp: 'PLUS' })
      .then(response => {
        this.country = response.data.natl_list
        this.price = response.data.price_plan_list
      })
  },
  methods: {
    selectCountry(item) {
      this.selectedCountry = item.selectedObject
    },
    inquire() {
      if (!this.formData.natl_cd) {
        this.$store.commit('ON_ALERT_POPUP', { message: '국가명을 입력하세요.' })
      } else if(!this.formData.use_time) {
        this.$store.commit('ON_ALERT_POPUP', { message: '이용시간(분)을 입력하세요.' })
      } else {
        this.price.forEach(item => {
          if(item.chrg_cd === this.formData.chrg_cd) {
            this.selectedPrice = item.chrg_nm
          }
        })
        api.ItsvcGetFreeDedtPricePlanInfo(this.formData)
        .then(response => {
          this.result = response.data
          this.resultAreaOpen = true
        })
      }
    },
    ApplyPlan() {
      const chrgCode = this.planningName === '3p' ? 'COSK3' : 'COSK5'
      this.popup.applyPlan.isVisible = false
      api.ItsvcPostSubsPricePlan({ chrg_cd: chrgCode, mdl_tkn: this.token })
    },
    certKcbToken(identifier) {
      this.planningName = identifier
      if (this.token) { // 토큰 있으면
      api.CommonGetReqKcbAuth({ mdl_tkn: this.token })
        .then(response => {
          this.certResult = response.data
          if (response.data.result_cd === '0001') {
            this.getCommonData()
          }
        })
      } else {  // 토큰 없으면 : 인증 레이어 팝업 띄우기
        this.$store.commit('ON_CERT_POPUP')
      }
    },
    // 사용중인 요금제 조회
    getCommonData(identifier) {
      api.ItsvcGetSvcUseInfo({ mdl_tkn: this.token})
        .then(response => {
          if (response.data.result_cd === '0000' || response.data.result_cd === 'E015') {
            if (response.data.result_cd === '0000') {
              this.popup.applyPlan.usagePlan = response.data.svc_info.chrg_cd_nm
            }
            this.popup.applyPlan.planName = this.planningName === '3p' ? '3플러스' : '5플러스'
            this.popup.applyPlan.isVisible = true
          }
        })
    }
  },
}
</script>

<style lang="scss">
.inter_seach {
  overflow: visible;
  div.type03 {
    position: relative;
    overflow: visible;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
  & + .line_top {
    margin: 0 auto 30px;
    border-top: none;
  }
}
.type03 {
  .autocomplete {
    float: left;
    width: 324px;
    height: 57px;
    @media (max-width: 1023px) {
      width: 49%;
      height: auto;
    }
    &__box {
      background: none;
      border: none;
      border-radius: 0;
      padding: 0;
    }
    &__icon {
      display: none;
    }
    &__inputs {
      width: 100%;
      padding: 0;
      input {
        width: 100% !important;
        margin-left: 0 !important;
        @media (max-width: 1023px) {
          line-height: 45px;
        }
      }
    }
    &__results {
      height: 150px;
      border: 1px solid #ddd;
      background: #eee;
      color: #333;
      padding: 2px;
      &__item {
        text-align: left;
        margin: 0;
        padding: 0;
        line-height: 28px;
        font-size: 17px;
        &:hover {
          border: 1px solid #fbd850;
          background: #fff;
          font-weight: bold;
          color: #eb8f00;
        }
      }
    }
    &__selected {
      border: 1px solid #fbd850;
      background: #fff !important;
      font-weight: bold;
      color: #eb8f00;
    }
  }
}

.sub_main .inter .result_area {
  display: block;
}

@media (max-width: 1023px) {
  .inter_seach div.type03 .section input {
    line-height: 45px;
  }
}
</style>
