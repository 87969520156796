<template>
  <div
    id="ENG_VISION"
    class="eng"
  >
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents en"
      >
        <div class="sub_header bg01">
          <h3 class="title">
            Vision
          </h3>
          <p class="txt">
            SK telink endeavors to provide our customers<br> with valuable service and make a better world with new technologies.
          </p>
          <img
            src="/content/images/sub_header_cp01.jpg"
            alt="Visual image"
          >
        </div>

        <!-- company -->
        <div class="company mb0">
          <div class="cp_area">
            <div class="cp_box">
              <div class="img_sk w_hide">
                <img
                  src="/content/images/img_cp_vision01_1_en.png"
                  alt="Link the World, SK telink SK telink’s vision is to make customers’ life happy by connecting all customers and the world, and leading the new future."
                >
              </div>
              <div
                class="img_sk m_hide"
                style="margin-top: 0;"
              >
                <img
                  src="/content/images/img_cp_vision02_1_en.png"
                  alt="Link the World, SK telink SK telink’s vision is to make customers’ life happy by connecting all customers and the world, and leading the new future."
                >
              </div>
            </div>
          </div>

          <div class="cp_area gray">
            <div>
              <h4>Vision</h4>
              <p>
                SK telink endeavors to provide our customers<br>with valuable service and make a better world with new technologies.
              </p>
              <div class="img_box">
                <img
                  src="/content/images/img_cp_vision03.png"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/EngHeader";
import BreadCrumb from "@/components/EngBreadCrumb";
import Footer from "@/components/EngFooter";

export default {
  name: 'Vision',
  metaInfo: {
    title: 'Vision | Company | SK telink',
    titleTemplate: null
  },
  components: {
    Header,
    BreadCrumb,
    Footer
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
