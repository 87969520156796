<template>
  <div id="Sitemap">
    <Header />
    <div id="container">
      <!-- location -->
      <div class="location">
        <ul class="location_inner">
          <li class="location_item">
            <router-link 
              to="/main"
              class="location_link"
            >
              <img
                src="/content/images/location_ico_home.png"
                alt="홈"
              >
            </router-link>
          </li>
          <li class="location_item current">
            <a
              href=""
              class="location_link"
            >사이트 맵</a>
          </li>
        </ul>
      </div>
      <!-- //location -->
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header site_bg">
          <h3 class="title">
            사이트 맵
          </h3>
          <p class="txt">
            전체 메뉴를 한눈에 보며<br>원하시는 메뉴로 빠르게 이동하세요
          </p>
          <img
            src="/content/images/sub_header_site.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- sitemap_wrap -->
        <div class="sitemap_wrap">
          <template
            v-for="(oneDepth, index) in menuItems"
          >
            <div 
              v-if="oneDepth.isVisible === true"
              :key="index"
              class="sitemap_area"
            >
              <h4>{{ oneDepth.label }}</h4>
              <div class="inner">
                <template
                  v-for="(twoDepth, index2) in oneDepth.children"
                >
                  <div 
                    v-if="twoDepth.isVisible === true"
                    :key="index2"
                    class="item"
                  >
                    <h5>
                      <router-link 
                        :to="twoDepth.link"
                      >
                        {{ twoDepth.label }}
                      </router-link>
                    </h5>
                    <ul>
                      <template
                        v-for="(threeDepth, index3) in twoDepth.children"
                      >
                        <li
                          v-if="threeDepth.isVisible === true"
                          :key="index3"
                          :class="{
                            hidden: threeDepth.label === '국제전화 소개',
                          }"
                        >
                          <router-link
                            v-if="threeDepth.link"
                            :to="threeDepth.link"
                          >
                            {{ threeDepth.label }}
                          </router-link>
                          <a
                            v-else-if="threeDepth.outLink"
                            :href="threeDepth.outLink"
                            target="_blank"
                          >
                            {{ threeDepth.label }}</a
                          >
                        </li>
                      </template>
                    </ul>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </div>
        <!-- //sitemap_wrap -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from '../components/Header'
import Footer from '../components/Footer.vue'
import SummitControl from '../components/SummitControl.vue'

export default {
  name: 'Sitemap',
  metaInfo: {
    title: '사이트맵',
  },
  components: {
    Header,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      menuItems: []
    }
  },
  computed: {
  },
  watch: {},
  created () {
    api.Menu()
      .then(response => {
        this.menuItems = response.data
      })
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.sitemap_area ul li.hidden {
  display: none;
}
</style>
