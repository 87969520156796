
const setCookie = (name, value, expiredays) => {
  const expire = new Date();
  expire.setDate(expire.getDate() + expiredays);
  let cookies = name + '=' + escape(value) + '; path=/ ';
  if(typeof expiredays != 'undefined') cookies += ';expires=' + expire.toGMTString() + ';';
  document.cookie = cookies;
}

const getCookie = (name) =>  {
  name = name + '=';
  const cookieData = document.cookie;
  let start = cookieData.indexOf(name);
  let cValue = '';
  if(start != -1){
    start += name.length;
    var end = cookieData.indexOf(';', start);
    if(end == -1)end = cookieData.length;
    cValue = cookieData.substring(start, end);
  }
  return unescape(cValue);
}

export {
  setCookie,
  getCookie
}