<template>
  <div class="swiper-wrap">
    <VueSlickCarousel
      ref="myswiper" 
      v-bind="settings"
      :dots="true" 
      @afterChange="onAfterChange"
    >
      <!-- <div class="swiper-item nth3">  
        <router-link
          to="/partnership/ncsi_no1_22"
          class="swiper-link"
        >
          <picture class="image-media">
            <source
              srcset="/content/images/w_main_banner_20220502.jpg"
              media="(min-width: 769px)"
            >
            <source
              srcset="/content/images/m_main_banner_20220502.jpg"
              media="(max-width: 1023px)"
            >
            <img
              src="/content/images/w_main_banner_20220502.jpg"
              alt=""
            >
          </picture>
        </router-link>
      </div> -->

      <!-- <div class="swiper-item nth1">  
        <router-link
          to="/view/business/intro"
          class="swiper-link"
        >
          <picture class="image-media">
            <source
              srcset="/content/images/w_main_banner_20210726_01.jpg"
              media="(min-width: 769px)"
            >
            <source
              srcset="/content/images/m_main_banner_20210726_01.jpg"
              media="(max-width: 1023px)"
            >
            <img
              src="/content/images/w_main_banner_20210726_01.jpg"
              alt="대한민국을 대표하는 국제전화 브랜드 SK국제전화 00700 대한민국 3대 고객 만족도 조사 국제전화 서비스 최초 6년 연속 1위"
            >
          </picture>
        </router-link>
      </div> -->

      <div class="swiper-item nth2"> 
        <!--pc(자세히보기)-->
        <router-link
          to="/view/business/00700intro"
          class="w_br swiper-link"
        >
          <picture class="image-media">
            <source
              srcset="/content/images/w_main_banner_20221017_02.jpg"
              media="(min-width: 1023px)"
            >
            <img
              src="/content/images/w_main_banner_20221017_02.jpg"
              alt="국제전화는 더 편리하게! 더 다양하게! 국내 1위 국제전화 통화품질! 이젠 00700 APP으로 더욱 간편하고 즐겁게 국제전화를 사용해보세요."
            >
          </picture>
        </router-link>
        <!--//pc-->
        <!-- mobile (앱 다운로드) -->
        <a
          href="https://sk00700.onelink.me/nBYS/be8fb69e"
          target="_blank"
          class="m_br swiper-link"
        >
          <picture class="image-media">
            <source
              srcset="/content/images/m_main_banner_20221017_02.jpg"
              media="(max-width: 1023px)"
            >
            <img
              src="/content/images/m_main_banner_20221017_02.jpg"
              alt="국제전화는 더 편리하게! 더 다양하게! 국내 1위 국제전화 통화품질! 이젠 00700 APP으로 더욱 간편하고 즐겁게 국제전화를 사용해보세요."
            >
          </picture>
          <!-- //mobile -->
        </a>
      </div>
    </VueSlickCarousel>
    <button 
      v-if="control.stopBtn.isVisible" 
      type="button"
      class="slick_controller slick_pause" 
      @click="stopFn"
    >
      정지
    </button>
    <button 
      v-if="control.playBtn.isVisible" 
      type="button" 
      class="slick_controller slick_play" 
      @click="startFn"
    >
      시작
    </button>
  </div>
</template>

<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  export default {
    name: 'Swiper',
    components: { VueSlickCarousel },
    data() {
      return {
        control: {
          stopBtn: {
            isVisible: false
          },
          playBtn: {
            isVisible: false
          },
        },
        settings: {
          infinite : true, //무한 반복
          autoplay: true, //자동 스크롤
          autoplaySpeed: 3000, //자동 스크롤 속도
          fade : false, //슬라이드 효과 (fade)
          arrows: false, //좌우 화살표 표시
          dots: true, //페이지네이션
          dotsClass : "slick-dots custom-dot-class", //페이지네이션 class
          pauseOnFocus: false, //슬라이드 클릭시 멈춤,
        },
      }
    },
    created () {
      // posController()
        let gController = document.querySelector('.key_slide');
        let gController1 = gController.querySelectorAll('.slick_controller');
        console.log(gController1)
    },
    mounted() {
    },
    methods: {
      stopFn() {
        this.$refs.myswiper.pause()
        this.control.playBtn.isVisible = true
        this.control.stopBtn.isVisible = false
      },
      startFn() {
        this.$refs.myswiper.play()
        this.control.playBtn.isVisible = false
        this.control.stopBtn.isVisible = true
      },
      onAfterChange() {
        let currentEl = document.querySelector('.slick-active'),
            currentElChild = currentEl.querySelector('.swiper-item');
            currentEl.tabIndex = 0;
            currentElChild.tabIndex = 0;
      },
      posController() {
        let gController = document.querySelector('.key_slide');
        let gController1 = gController.querySelectorAll('.slick_controller');
        console.log(gController1.length)
      }
    },
  }
</script>


<style lang="scss">
  //pc
  .swiper-wrap {
      position: relative; 
    //pagenation
    .slick-dots {
      position: absolute;
      bottom: 30px;
      & > li {
        &.slick-active {
          > button {
            &:before {
              color: #fff;
              opacity: 1;
            }
          }
        }
        & > button {
          &:before {
            color: #fff;
            font-size: 13px;
            opacity: 0.5;
          }
        }
      }
    }
    .slick_controller {
      position: absolute;
      left: 50%;
      bottom: 31px;
      width: 20px;
      height: 20px;
      padding: 5px;
      display: inline-block;
      margin-left: 65px; //50px
      background-size: 10px 12px;
      background-repeat: no-repeat;
      transform: translate(-50%);
      text-indent: -999em;
      &.slick_pause {
        background-image: url('/content/images/btn_slick_pause.png');
      }
      &.slick_play {
        background-image: url('/content/images/btn_slick_play.png');
      }
    }
    .swiper-link {
      height: 100%;
    }
  }

  //phone
  @media (max-width:1023px) {
    .swiper-wrap {
      .slick-dots {
        bottom: 20px;
        li {
          margin: 0 2px;
        }
      }
      .slick_controller {
        bottom: 21px;
        margin-left: 53px; //38px
      }
    }
  }
  @media (max-width:520px) {
    .swiper-wrap {
     .slick-slide {
       height: 320px;
      }
    }
  }
  @media (max-width:375px) {
    .swiper-wrap {
     .slick-slide {
       height: 293px;
      }
    }
  }
</style>
