
<template>
  <div id="friend_recommend">
    <div class="event_wrap friend">
      <div class="event_view">
        <div>
          <span>
            <img
              src="/content/images/partnership/220405_friendrecommend_1.jpg"
              alt="SK국제전화 00700 지인추천 이벤트, 고객님 지인 전화번호로 All Pass이벤트를 보내주세요!"
              @load="onLoad"  
            ><img
              src="/content/images/partnership/220405_friendrecommend_2.jpg"
              alt="All Pass 요금제란?"
            >
          </span>
          <div class="event_view_field">
            <div class="event_header">
              <img
                src="/content/images/partnership/220405_friendrecommend_t1.png"
                alt="이벤트 참여 방법"
              >
            </div>
            <div class="event_content">
              <div class="box">
                <div class="box_header">
                  <img
                    src="/content/images/partnership/220405_friendrecommend_t1_1.png"
                    alt="추천인 전화번호 입력"
                  >
                </div>
                <div class="simple_type">
                  <dl>
                    <dt>전화번호</dt>
                    <dd>
                      <div class="insert_phone">
                        <select
                          id="call_select01"
                          ref="telNumberFirst"
                          v-model="telNumber.first"
                          name="i_sHpNo1"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                        <span class="txt_hyphen" />
                        <input
                          ref="telNumberMiddle"
                          v-model="telNumber.middle"
                          name="i_sHpNo2"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen" />
                        <input
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                    </dd>
                  </dl>
                  <div class="btn_wrap">
                    <button
                      type="button"
                      class="btn btn_cert"
                      @click="requestSMSCert"
                    >
                      <img
                        src="/content/images/partnership/220405_friendrecommend_btn_sms.png"
                        alt="SMS 인증"
                      >
                    </button>
                  </div>
                  <dl
                    v-if="authNumberInputVisible"
                    id="numberCheck"
                    class="C030 duplPhono"
                  >
                    <dt>인증번호 입력</dt>
                    <dd>
                      <div class="insert_certNum">
                        <input
                          ref="certNumber"
                          v-model="auth_no"
                          name="i_sCertificationNo"
                          title="인증번호"
                          type="text"
                          maxlength="6"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <button
                        class="btn btn_cert"
                        @click="requestCert"
                      >
                        인증번호확인
                      </button>
                    </dd>
                  </dl>
                </div>
                
                <div class="agree_box">
                  <input
                    id="yes"
                    v-model="agreePrivacy"
                    name="yesno"
                    class="myClass YNchk"
                    type="checkbox"
                    value="yes01"
                  ><label for="yes" />
                  <a
                    @click="isVisiblePrivacyPopup = true"
                  >
                    개인정보 제공 동의
                  </a>
                </div>
              </div>

              <div class="box">
                <div class="box_header">
                  <img
                    src="/content/images/partnership/220405_friendrecommend_t1_2.png"
                    alt="지인 전화번호 입력"
                  >
                </div>
                <div class="simple_type">
                  <dl>
                    <dt>전화번호</dt>
                    <dd
                      v-if="friendTelNumberVisible1"
                    >
                      <div class="insert_phone">
                        <select
                          id="call_select02"
                          ref="friendTelNumber1First"
                          v-model="friendTelNumber1.first"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber1Middle"
                          v-model="friendTelNumber1.middle"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber1Last"
                          v-model="friendTelNumber1.last"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                    </dd>
                    <dd
                      v-if="friendTelNumberVisible2"
                    >
                      <div class="insert_phone">
                        <select
                          id="call_select03"
                          ref="friendTelNumber2First"
                          v-model="friendTelNumber2.first"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber21Middle"
                          v-model="friendTelNumber2.middle"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber2Last"
                          v-model="friendTelNumber2.last"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                    </dd>
                    <dd
                      v-if="friendTelNumberVisible3"
                    >
                      <div class="insert_phone">
                        <select
                          id="call_select04"
                          ref="friendTelNumber3First"
                          v-model="friendTelNumber3.first"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber31Middle"
                          v-model="friendTelNumber3.middle"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber3Last"
                          v-model="friendTelNumber3.last"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                    </dd>
                    <dd
                      v-if="friendTelNumberVisible4"
                    >
                      <div class="insert_phone">
                        <select
                          id="call_select05"
                          ref="friendTelNumber4First"
                          v-model="friendTelNumber4.first"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber41Middle"
                          v-model="friendTelNumber4.middle"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber4Last"
                          v-model="friendTelNumber4.last"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                    </dd>
                    <dd
                      v-if="friendTelNumberVisible5"
                    >
                      <div class="insert_phone">
                        <select
                          id="call_select06"
                          ref="friendTelNumber5First"
                          v-model="friendTelNumber5.first"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber5Middle"
                          v-model="friendTelNumber5.middle"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen" />
                        <input
                          ref="friendTelNumber5Last"
                          v-model="friendTelNumber5.last"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                    </dd>
                  </dl>
                  <div class="btn_wrap">
                    <button
                      v-if="friendTelNumberVisibleBtn"
                      type="button"
                      class="btn btn_cert"
                      @click="telNumberAdd"
                    >
                      <img
                        src="/content/images/partnership/220405_friendrecommend_btn_add.png"
                        alt="번호추가"
                      >
                    </button>
                  </div>
                </div>
              </div>
              
              <div class="box">
                <div class="box_header">
                  <img
                    src="/content/images/partnership/220405_friendrecommend_t1_3.png"
                    alt="추천 문자요청"
                  >
                </div>

                <div class="simple_type">
                  <dl>
                    <dt>※ 이벤트문자 발송 예</dt>
                    <dd>
                      <img
                        src="/content/images/partnership/220405_friendrecommend_sample.png"
                        alt="이벤트 참여하기"
                      >
                    </dd>
                  </dl>
                  <div class="btn_wrap">
                    <button
                      id="btnApply"
                      type="button"
                      class="btn btn_cert"
                      onclick="gtag('event','응모완료',{'event_category':'이벤트'});"
                      @click="registrationFinal"
                    >
                      <img
                        src="/content/images/partnership/220405_friendrecommend_btn_join.png"
                        alt="이벤트 참여하기"
                      >
                    </button>
                  </div>
                </div>
              </div>
              
              <div class="box">
                <div class="box_header">
                  <img
                    src="/content/images/partnership/220405_friendrecommend_t1_4.png"
                    alt="지인에게 문자전송"
                  >
                </div>
                <div class="event_text">
                  02번에 입력한 지인 전화번호로<br>
                  <span>수신한 이벤트 문자 전달</span>
                </div>
              </div>
            </div>
          </div>
          <div class="event_guide">
            <img
              src="/content/images/partnership/220405_friendrecommend_t2.png"
              alt="안내사항"
            >
          </div>
          <div class="event_caution">
            <h2>
              <img
                src="/content/images/partnership/220405_friendrecommend_t3.png"
                alt="이벤트 유의 사항"
              >
            </h2>
            <ul>
              <li>이벤트 참여 완료 시 개인정보 수집, 활용 및 취급위탁에 대해 동의하신 것으로 간주됩니다.</li>
              <li>상품 지급은 추천하신 지인 전화번호의 요금제 가입이 완료된 경우에 한하여 지급됩니다</li>
              <li>본 이벤트 경품은 제휴사 사정에 따라 별도 공지 없이 상품이 변경/대체될 수 있습니다. </li>
              <li>비정상적이거나  불법적인 방법으로 이벤트에 참여하신 경우, 이벤트 대상에서 제외될 수 있습니다.</li>
              <li>이벤트 관련 문의 : SK국제전화 00700<br>고객센터 : 1599-0070(월~금, 9시~18시)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 00700 앱 가입(웰컴 기프트 제공) 이벤트 운영을 위해 아래와 같이 고객정보를 수집하며,
              고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보 항목 : 핸드폰번호(무선번호)</li>
              <li>개인정보의 수집 및 이용목적 : 이벤트 운영 및 경품 제공, 이벤트 관련 고객 민원 대응, 00700 이용요금 공제</li>
              <li>개인정보 보유 및 이용기간 : <strong>이벤트 운영 기간 포함 경품 제공 및 고객 민원 대응, 00700 요금 공제를 위해 6개월까지 보유</strong></li>
            </ul>
            <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부시 이벤트 참여가 제한될 수 있습니다.</p>
            <p>SK국제전화 00700이 운영하는 이벤트 참여 및 경품 수령을 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    <!-- //팝업 -->
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  name: 'WellcomeGift',
  components: {
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    isVisiblePrivacyPopup: false,
    agreePrivacy:false,
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    telNumber:{
      first:'010',
      middle: '',
      last:''
    },
    friendTelNumber1:{
      first:'010',
      middle: '',
      last:''
    },
    friendTelNumber2:{
      first:'010',
      middle: '',
      last:''
    },
    friendTelNumber3:{
      first:'010',
      middle: '',
      last:''
    },
    friendTelNumber4:{
      first:'010',
      middle: '',
      last:''
    },
    friendTelNumber5:{
      first:'010',
      middle: '',
      last:''
    },
    authNumberInputVisible: false,
    ticket: '',
    event_no:'EV000000000000000006',
    auth_no: '',
    friendTelNumberVisibleCount: 1,
    friendTelNumberVisible1: true,
    friendTelNumberVisible2: false,
    friendTelNumberVisible3: false,
    friendTelNumberVisible4: false,
    friendTelNumberVisible5: false,
    friendTelNumberVisibleBtn: true,
  }
 },
 computed: {
  svc_tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  friend_tel_no_1() {
    return `${this.friendTelNumber1.first}${this.friendTelNumber1.middle}${this.friendTelNumber1.last}`
  },
  friend_tel_no_2() {
    return `${this.friendTelNumber2.first}${this.friendTelNumber2.middle}${this.friendTelNumber2.last}`
  },
  friend_tel_no_3() {
    return `${this.friendTelNumber3.first}${this.friendTelNumber3.middle}${this.friendTelNumber3.last}`
  },
  friend_tel_no_4() {
    return `${this.friendTelNumber4.first}${this.friendTelNumber4.middle}${this.friendTelNumber4.last}`
  },
  friend_tel_no_5() {
    return `${this.friendTelNumber5.first}${this.friendTelNumber5.middle}${this.friendTelNumber5.last}`
  },
  callbackAlert(){
    return this.$store.getters.CALLBACK_ALERT
  }
 },
 watch: {
  callbackAlert: {
    deep: true,
    handler() {
      if (this.callbackAlert.code === '0002' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E0027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E021' || this.callbackAlert.code === 'E022' || this.callbackAlert.code === 'E037') {
        this.resetPage()
        window.scrollTo(0, 0)
        document.getElementById('wrap').scrollTop = 0
      }
      if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
        this.authNumberInputVisible = false,
        this.telNumber = {
          first:'010',
          middle: '',
          last:''
        },
        this.auth_no = ''
        this.ticket = ''
      }
      if (this.callbackAlert.code === 'E001') { // 인증시간 초과
        this.auth_no = ''
        this.ticket = ''
      }
    }
  }
 },
 created () {},
 mounted () {},
 methods: {
  alertPopupHandler() {
    this.alertPopup.visible = false
    this.alertPopup.message = ''
    if (this.alertPopup.target) {
      this.$refs[this.alertPopup.target].focus()
    }
    this.alertPopup.target = ''
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
      return false
    } else {
      return true
    }
  },
  checkFriendPhoneNumber() {
    if (!this.friendTelNumber1.first) {
      this.alertPopup.target = 'friendTelNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.friendTelNumber1.middle) {
      this.alertPopup.target = 'friendTelNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.friendTelNumber1.last) {
      this.alertPopup.target = 'friendTelNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if(this.friendTelNumber1.middle.length < 3 || this.friendTelNumber1.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
      return false
    } else {
      return true
    }
  },
  checkAuthNumber() {
    if (!this.auth_no) {
      this.alertPopup.target = 'certNumber'
      this.showAlertPopup('인증번호를 입력해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkSMSCert() {
    if(!this.isSmsCert) {
      this.showAlertPopup('SMS인증을 완료해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkselect(){
    if(!this.agreePrivacy){
      this.showAlertPopup('개인정보 수집에 동의하셔야 신청이 가능합니다.')
      return false
    }else {
      return true
    }
  },
  // SMS 인증 요청
  requestSMSCert() {
    if (this.checkPhoneNumber()) {
      api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
      .then(response => {
        if (response.data.sms_ticket) {
          this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
          this.ticket = response.data.sms_ticket
          this.authNumberInputVisible = true
        }
      })
    }
  },
  // 인증 번호로 SMS 인증 확인
  requestCert() {
    if (this.checkAuthNumber()) {
      api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
      .then(response => {
        if (response.data.result_cd === '0001') {
          this.isSmsCert = true
          this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
        }
      })
    }
  },
  //신청완료
  registrationFinal() {
    if (this.checkselect() && this.checkPhoneNumber() && this.checkFriendPhoneNumber() && this.checkSMSCert()) {
      api.EventPostReqEntry({
        sms_ticket: this.ticket,
        event_no: this.event_no,
        svc_tel_no: this.svc_tel_no,
        ref1: this.friend_tel_no_1,
        ref2: this.friend_tel_no_2,
        ref3: this.friend_tel_no_3,
        ref4: this.friend_tel_no_4,
        ref5: this.friend_tel_no_5
      })
    }
  },
  resetPage(){
    this.agreePrivacy = false,
    this.telNumber= {
      first:'010',
      middle: '',
      last:''
    },
    this.friendTelNumber1= {
      first:'010',
      middle: '',
      last:''
    },
    this.friendTelNumber2= {
      first:'010',
      middle: '',
      last:''
    },
    this.friendTelNumber3= {
      first:'010',
      middle: '',
      last:''
    },
    this.friendTelNumber4= {
      first:'010',
      middle: '',
      last:''
    },
    this.friendTelNumber5= {
      first:'010',
      middle: '',
      last:''
    },
    this.friendTelNumberVisibleCount = 1,
    this.friendTelNumberVisible1 = true,
    this.friendTelNumberVisible2 = false,
    this.friendTelNumberVisible3 = false,
    this.friendTelNumberVisible4 = false,
    this.friendTelNumberVisible5 = false,
    this.friendTelNumberVisibleBtn = true,
    this.authNumberInputVisible = false,
    this.auth_no = '',
    this.ticket = '',
    this.isSmsCert = false
  },

  //번호추가
  telNumberAdd() {
    let count = this.friendTelNumberVisibleCount = this.friendTelNumberVisibleCount + 1
    // console.log(count)
    if(count === 2) this.friendTelNumberVisible2 = true
    if(count === 3) this.friendTelNumberVisible3 = true
    if(count === 4) this.friendTelNumberVisible4 = true
    if(count === 5) {
      this.friendTelNumberVisible5 = true
      this.friendTelNumberVisibleBtn = false
    }
  },
  
  onLoad() {
    var popitem = document.getElementById("friend_recommend");

     if (new Date() >= new Date('4/24/2022 23:59:59')) {   //반영 시간 지정
        this.showAlertPopup('이벤트 기간이 종료되었습니다.<br>감사합니다.')
         popitem.classList.add("closeEvent");
    }
  }
 }
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');

.event_wrap.type2.type_05 .select_box select{
  opacity: 1 !important;
  color: #6c6c6c !important;
  font-weight: 400;
}
.table_type01.w100 input { font-size: 16px; }

.friend {
  & > .event_view > div { text-align: left; }
  .event_view_field {
    margin-top: 0;
    padding: 110px 70px 150px;
    background: url('/content/images/partnership/220405_friendrecommend_pattern_1.png');
    .event_header {
      text-align: center;
      img { width: auto; }
    }
    .event_content {
      .box {
        position: relative;
        margin-top: 75px;
        padding: 100px 70px;
        background: url('/content/images/partnership/220405_friendrecommend_pattern_2.png');
        border-radius: 50px;
        .box_header {
          text-align: center;
          img { width: auto; }
        }
        &:after {
          content: '';
          position: absolute;
          left: 0; bottom: -30px;
          width: 100%;
          height: 30px;
          background: url('/content/images/partnership/220405_friendrecommend_shadow.png') no-repeat center top;
        }
        .event_text {
          margin-top: 60px;
          color: #fff;
          font-family: 'NanumSquareRound',sans-serif;
          font-size: 50px;
          font-weight: 700;
          line-height: 1.3;
          text-align: center;
          span {
            font: inherit;
            border-bottom: 1px solid #fff;
          }
        }
      }
      .simple_type {
        dl {
          margin-top: 60px;
          dt {
            margin-bottom: 28px;
            color: #fff;
            font-family: 'NanumSquareRound',sans-serif;
            font-size: 50px;
            font-weight: 700;
            line-height: 1;
          }
          dd {
            &:after {
              content: ''; display: block; clear: both; overflow: hidden;
            }
            & + dd { margin-top: 40px; }
          }
        }
        .insert_phone {
          height: 125px;
          & > * { vertical-align: middle; }
          select {
            width: 30%;
            height: 100%;
            color: #000;
            font-size: 50px;
            font-family: 'NanumSquareRound', Sans-serif;
            font-weight: 700;
            line-height: normal;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: #fff url('/content/images/btn_arrow_selelct.png') no-repeat calc(100% - 12px) 50%/16px 9px;
            border-radius: 20px;
          }
          input[type=number], input[type=text] {
            width: 30%;
            height: 100%;
            padding: 0;
            color: #000;
            font-size: 50px;
            font-family: 'NanumSquareRound', Sans-serif;
            font-weight: 700;
            line-height: 125px;
            text-align: center;
            background-color: #fff;
            border-radius: 20px;
          }
          .txt_hyphen {
            display: inline-block;
            position: relative;
            width: 5%;
            height: 100%;
            text-align: center;
            line-height: 100%;
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: 8px; height: 2px; background-color: #fff;
              margin: -1px 0 0 -4px;
            }
          }
        }
        .insert_certNum {
          float: left;
          width: 22.5%;
          height: 90px;
          margin-right: 3.75%;
          input[type=number], input[type=text] {
            width: 100%;
            height: 100%;
            padding: 0;
            color: #000;
            font-size: 32px;
            font-family: 'arial', Sans-serif;
            line-height: 90px;
            text-align: center;
            background-color: #fff;
            border-radius: 10px;
          }
          & + .btn.btn_cert {
            float: left;
            width: auto;
            height: 90px;
            padding: 0 25px;
          }
        }
        .btn_wrap { margin: 40px 0 0 0; }
        .btn.btn_cert {
          display: block;
          width: 100%;
          height: 126px;
          color: #fff;
          font-size: 32px;
          background: #461cbe;
          border-radius: 18px;
          img { width: auto; }
        }
      }
      .agree_box {
        display: block;
        position: relative;
        margin-top: 40px;
        padding-left: 75px;
        color: #fff;
        font-size: 40px;
        line-height: 40px;
        letter-spacing: -0.05em;
        input[type=checkbox] {
          position: absolute;
          left: 5px;
          top: 3px;
          z-index: -1;
          opacity: 0;
          &:checked+label {
            background: url('/content/images/partnership/icon_checkbox_recommend_checked.png') no-repeat;
          }
        }
        label {
          position: absolute;
          left: 0;
          width: 52px;
          height: 52px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: url('/content/images/partnership/icon_checkbox_recommend.png') no-repeat;
        }
        a {
          color: inherit;
          font-size: inherit;
          line-height: inherit;
        }
        p {
          color: inherit;
          font-size: inherit;
          line-height: inherit;
          text-align: left;
        }
      }
    }
  }
  .event_guide {
    img { width: auto; }
  }
  .event_caution {
    padding: 150px 140px;
    background: #444;
    color: #fff;
    h2 {
      margin-bottom: 65px;
      text-align: center;
      img { width: auto; }
    }
    li {
      position: relative;
      padding-left: 20px;
      color: #cdcdcd;
      font-family: 'NanumSquareRound',sans-serif;
      font-size: 33px;
      font-weight: 400;
      line-height: 1.67;
      letter-spacing: -0.04em;
      &:before {
        position: absolute;
        content: "-";
        top: -1px;
        left: 0;
      }
    }
  }
}
@media (max-width: 1023px) {
  .friend {
    .event_view_field {
      padding: 35px 33px 50px;
      .event_header {
        img { height: 27px; }
      }
      .event_content {
        .box {
          margin-top: 25px;
          padding: 33px 23px;
          border-radius: 16px;
          .box_header {
            img { height: 25px; }
          }
          &:after {
            bottom: -10px;
            height: 10px;
            background-size: auto 10px;
          }
          .event_text {
            margin-top: 20px;
            font-size: 17px;
            text-align: center;
          }
        }
        .simple_type {
          dl {
            margin-top: 20px;
            dt {
              font-size: 16px;
              margin-bottom: 10px;
            }
            dd {
              & + dd { margin-top: 13px; }
            }
          }
          .insert_phone {
            height: 40px;
            select, input[type=number], input[type=text] { font-size: 16px; line-height: 40px; border-radius: 5px; }
            .txt_hyphen:before { width: 4px; height: 1px; margin-left: -2px; }
          }

          .insert_certNum {
            height: 40px;
            input[type=number], input[type=text] { font-size: 16px; line-height: 40px; border-radius: 5px; }
            & + .btn.btn_cert {
              height: 40px;
              padding: 0 10px;
            }
          }

          .btn.btn_cert {
            height: 40px;
            font-size: 13px;
            border-radius: 6px;
              img { height: 15px; }
          }
          .btn_wrap { margin-top: 17px; padding: 0; }
        }
        .agree_box {
          margin-top: 15px;
          padding-left: 30px;
          font-size: 15px;
          line-height: 1.2;
          input[type=checkbox]:checked+label {
            background-size: 100% auto;
          }
          label {
            width: 20px;
            height: 20px;
            background-size: 100% auto;
          }
        }
      }
    }
    .event_caution {
      padding: 50px 47px;
      h2 {
        margin-bottom: 20px;
        img { height: 20px; }
      }
      li {
        font-size: 15px;
        padding: 0 0 0 10px;
        &:before {
          top: 0;
        }
      }
    }
  }
  .pop_wrap02 .medium { padding-bottom: 20px; }
}
</style>