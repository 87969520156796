<template>
  <div class="v-captcha">
    <div class="v-captcha__flex">
      <canvas
        ref="image"
        class="v-captchar__image"
        :width="width"
        :height="height"
      />
      <button
        type="button"
        class="v-captcha__button"
        @click="generate"
      >
        <img
          class="v-captcha__reload-image"
          src="/content/images/btn_refresh.png"
          alt="새로고침"
        >
      </button>
    </div>
    <input
      ref="input"
      type="text"
      class="v-captcha__input"
      :placeholder="placeholder"
      maxlength="6"
      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
      @input="check"
    >
  </div>
</template>

<script>
export default {
  name: 'Captchar',
  props: {
    len: {
      type: Number,
      required: false,
      default: 6
    },
    width: {
      type: Number,
      required: false,
      default: 200
    },
    height: {
      type: Number,
      required: false,
      default: 40
    },
    placeholder:{
      type:String,
      required: false,
      default:'보안문자를 입력하세요'
    }
  },
  data() {
    return  {
      // keyList: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789',
      keyList: '0123456789',
      key: '',
      canvas: null
    }
  },
  mounted() {
    this.canvas = this.$refs.image.getContext('2d')
    this.generate()
  },
  methods: {
    generate(len){
      let temp = ''
      for (let i=0;i < this.len ;i++) {
        temp += this.keyList.charAt(Math.floor(Math.random()*this.keyList.length))
      }
      this.key = temp
      this.draw()
      this.check()
    },
    draw() {
      this.reset()
      this.canvas.font = '24px Arial'
      this.canvas.strokeText(this.key, 10, 30)
    },
    reset() {
      this.canvas.clearRect(0, 0, this.width, this.height)
      this.canvas.beginPath()
    },
    check(event) {
      if (event) {
        this.$emit('result', event.target.value === this.key)
      } else {
        this.$emit('result', this.$refs.input.value === this.key)
      }
    },
    resetInput() {
      this.$refs.input.value = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.v-captcha {
  display: flex;
  @media (max-width: 1023px) {
    display: block;
  }
  &__flex{
    display: flex;
  }
  &__image{
    border: solid 1px black;
  }
  &__button{
    min-width: auto;
    width: 42px;
    height: 42px;
    margin-left: 8px;
    padding: 0;
    border: 1px solid #dadada;
  }
  &__reload-image{
    width: 100%;
    height: 100%;
  }
  &__input {
    @media (max-width: 1023px) {
      width: 100%;
    }
    width: 225px;
  }
}
</style>



