<template>
  <div id="HR_CULTURE">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bgEthics">
          <h3 class="title">
            윤리경영 추진배경
          </h3>
          <p class="txt">
            SK텔링크는 윤리경영을 통해<br>
            투명하고 건전한 기업문화를 만듭니다.
          </p>
          <img
            src="/content/images/sub_header_ethics.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- company -->
        <div class="company mb0">
          <div class="hr_area">
            <h4>윤리경영의 정의 및 추진배경</h4>
            <p>
              윤리경영이란 기업이 <span class="txt_point01">경제적, 법률적 책임을 준수하는 것과 더불어 사회적 통념으로 요구되는 윤리적 기준을 기업의 의사결정과 행동에 반영</span>하는 것입니다.<br class="w_hide">
              또한, 건전한 기업시민의 역할(사회적 책임)이라고 할 수 있는 재량적 책임(Discretional)을 수행하는<br class="w_hide">
              지속가능경영도 광의의 윤리경영이라고 할 수 있습니다.
            </p>
            
            <h5>윤리경영의 추구 가치</h5>
            <div class="img_box w_hide">
              <img
                src="/content/images/img_ethics_pc.png"
                alt="윤리경영 Program 확립 및 적극적 실천, 고객 이윤창출의 출발점, 구성원 기업 경영활동의 주체, 비지니스 파트너 윈윈 파트너쉽 구축의 대상, 사회 잠재고객, 잠재구성원, 잠재주주, 주주 투자의 주체, 환경 지속발전을 위한 근간"
              >
            </div>
            <div class="img_box m_hide">
              <img
                src="/content/images/img_ethics_mob.png"
                alt="윤리경영 Program 확립 및 적극적 실천, 고객 이윤창출의 출발점, 구성원 기업 경영활동의 주체, 비지니스 파트너 윈윈 파트너쉽 구축의 대상, 사회 잠재고객, 잠재구성원, 잠재주주, 주주 투자의 주체, 환경 지속발전을 위한 근간"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl.vue'

export default {
  name: 'HrCulture',
  metaInfo: {
    title: '윤리경영 추천배경 | 윤리경영 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.sub_header.bgEthics {
  background: url('/content/images/sub_header_bg_ethics.jpg') no-repeat 50% 0;
}
.company .hr_area {
  h5 { margin-top: 72px; color: #ff630a; font-size: 24px; }
  .img_box {
    margin-top: 67px;
    padding-bottom: 80px;
    img { width: auto; }
  }
}
@media (max-width: 1023px) {
  .company .hr_area {
    h5 { margin-top: 42px; font-size: 22px; }
    .img_box {
      margin-top: 43px;
      padding-bottom: 30px;
    }
  }
}
</style>
