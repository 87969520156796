<template>
  <div id="GLOBAL_A">
    <div
      id="boardWEB"
      class="event_wrap type type2 type_05"
    >
      <div class="event_view type_02">
        <div>
          <span class="w_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp3plus_pc_01.jpg"
              width="1120"
              usemap="#Map"
              style="height: auto;"
            >

          </span>
          <span class="m_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp3plus_mo_01.jpg"
              width="640"
              usemap="#Map"
              style="height: auto;"
            >

          </span>
          <input
            name="i_sPostscript"
            type="hidden"
            value=""
          >
          <input
            name="cType"
            type="hidden"
            value="10000"
          >
          <div class="notice event_view_field">
            <div class="table_type01">
              <dl>
                <dt>요금제</dt>
                <dd>
                  <div class="select_box seb2">
                    <select
                      id="i_sComment"
                      v-model="selectRatePlan"
                      name="i_sComment"
                      title="요금제 선택하기"
                      @change="categoryChange"
                    >
                      <option value="요금제 선택">
                        요금제 선택
                      </option>
                      <option value="COGP3">
                        00700 Global Plus 300
                      </option>
                      <option value="COGP5">
                        00700 Global Plus 500
                      </option>
                      <option value="COSK3">
                        00700 3Plus 요금제
                      </option>
                    </select>
                  </div>
                </dd>
              </dl>

              <dl>
                <dt>전화번호</dt>
                <dd>
                  <div>
                    <div class="C030 duplPhono">
                      <!-- 무선 전화번호 타입 -->
                      <div
                        class="select_box"
                        style="vertical-align: middle;"
                      >
                        <select
                          id="i_sHpNo1"
                          ref="telNumberFirst"
                          v-model="telNumber.first"
                          name="i_sHpNo1"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="">
                            선택
                          </option>
                          <option
                            value="010"
                            selected
                          >
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                          <option value="02">
                            02
                          </option>
                          <option value="031">
                            031
                          </option>
                          <option value="032">
                            032
                          </option>
                          <option value="033">
                            033
                          </option>
                          <option value="041">
                            041
                          </option>
                          <option value="042">
                            042
                          </option>
                          <option value="043">
                            043
                          </option>
                          <option value="044">
                            044
                          </option>
                          <option value="051">
                            051
                          </option>
                          <option value="052">
                            052
                          </option>
                          <option value="053">
                            053
                          </option>
                          <option value="054">
                            054
                          </option>
                          <option value="055">
                            055
                          </option>
                          <option value="061">
                            061
                          </option>
                          <option value="062">
                            062
                          </option>
                          <option value="063">
                            063
                          </option>
                          <option value="064">
                            064
                          </option>
                          <option value="070">
                            070
                          </option>
                        </select>
                      </div>
                      <span class="txt_hyphen">-</span>
                      <input
                        id="i_sHpNo2"
                        ref="telNumberMiddle"
                        v-model="telNumber.middle"
                        name="i_sHpNo2"
                        title="휴대폰번호"
                        type="text"
                        maxlength="4"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                      <span class="txt_hyphen">-</span>
                      <input
                        id="i_sHpNo3"
                        ref="telNumberLast"
                        v-model="telNumber.last"
                        name="i_sHpNo3"
                        title="휴대폰번호"
                        type="text"
                        maxlength="4"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                  </div>
                </dd>
              </dl>

              <dl>
                <dt>보안문자</dt>
                <dd class="security">
                  <Captchar
                    ref="captchar"
                    :width="135"
                    :height="42"
                    @result="updateCaptcharState"
                  />

                  <!-- <div class="captcha">
                    <img
                      id="formCaptchaImage"
                      src="/content/images/captcha.png"
                    >
                  </div>
                  <a
                    href="javascript:void(0)"
                    onclick="fnPreventRefresh($('#formCaptchaImage'));"
                    class="btn_refresh"
                  ><img
                    src="/content/images/btn_refresh.png"
                    alt="새로고침"
                  ></a>
                  <input
                    id="formCaptchaText"
                    type="text"
                    placeholder="보안문자를 입력하세요."
                    title="보안문자를 입력하세요."
                    class="wid225 captchaText"
                    name="formCaptchaText"
                    maxlength="6"
                  > -->
                </dd>
              </dl>
            </div>
            <div class="align-center">
              <div class="check_box global">
                <input
                  id="yes"
                  v-model="agreePrivacy"
                  name="yesno"
                  class="myClass YNchk"
                  type="checkbox"
                  value="yes01"
                >
                <label for="yes"><span style="font-size: 14px;">SK국제전화 요금제 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</span></label>
              </div>
              <div>
                <a
                  class="btn_view"
                  @click="isVisiblePrivacyPopup = true"
                >개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 신청이 가능합니다.</a>
              </div>
            </div>
          </div>
        </div>
        <div class="event_view_field">
          <div class="btn_center type_02">
            <a
              id="btnApply"
              class="btn_type01 round-type"
              @click="registrationFinal"
            >요금제 신청하기</a>
          </div>

          <span class="w_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp3plus_pc_02.jpg"
            >
          </span>
          <span class="m_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp3plus_mo_02.jpg"
            >
          </span>

          <div
            style="display:none"
            class="event_view"
          >
            <div class="blind">
              <h6>이벤트 참여시 유의사항</h6>
              <ul>
                <li>본 이벤트에 참여하기 위해서는 개인정보 수집, 활용 및 취급위탁에 대한 동의가 필요합니다.</li>
                <li>본 이벤트 경품은 사정에 따라 별도 공지 없이 상품이 변경/대체될 수 있습니다.</li>
                <li>본 이벤트는 기프티콘 발송을 위해 휴대전화(핸드폰)만 참여가 가능합니다.</li>
                <li>이벤트 응모, 경품 기프티콘 발송을 위해 휴대전화번호를 정확히 입력하셔야 합니다.</li>
                <li>
                  본 이벤트는 위성/화상전화, 국제SMS이용 내역과 마이플랜 요금제, 더길게요금제, SKT00700프리
                  부가서비스 등 정액제 요금제 가입자는 참여가 제한됩니다.
                </li>
                <li>비정상적이거나 불법적인 방법으로 이벤트에 참여하신 경우 혜택 대상에서 제외되실 수 있습니다.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium ko">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 국제전화 이용에 대한 혜택제공을 위해 아래와 같이 고객정보를 수집하며, <br>고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보의 항목 : 휴대폰번호(연락처)</li>
              <li>개인정보의 수집 및 이용목적 : SK국제전화 요금제에 대한 고객상담 진행</li>
              <li>개인정보의 보유 및 이용기간 : <strong>SK국제전화 요금제 가입 시점부터 해지일자까지</strong></li>
            </ul>
            <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 요금제 혜택 제공에 제한이 될 수 있습니다.</p>
            <p>SK국제전화 요금제 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a
          @click="isVisiblePrivacyPopup = false"
        ><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'INFO'"
      :c-type="'btn_type02'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>

    <Popup
      :is-visible.sync="registerPopup.visible"
      :pop-title="'INFO'"
      :c-type="'btn_type02'"
      :btn-divide="'double'"
      :button-text="'취소'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
      @confirm="registrationConfirm"
    >
      <p v-html="registerPopup.message" />
    </Popup>
    <!-- //팝업 -->
  </div>
</template>

<script>
import api from '@/api'
import Captchar from '@/components/Captchar'
import Popup from '@/components/popup/Popup'
export default {
  name: 'GlobalA',
  components: {
    Captchar,
    Popup
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      isPassCaptchar: false,
      isVisiblePrivacyPopup:false,
      alertPopup: {
        visible: false,
        message: '',
        target: ''
      },
      registerPopup: {
        visible: false,
        message: '',
        target: ''
      },
      telNumber: {
        first:'010',
        middle:'',
        last:''
      },
      selectRatePlan:'요금제 선택',
      agreePrivacy:false,
      event_no:'ZZ000000000000005701',
      oper_br_cd: 'D15265'
    }
  },
  computed: {
    tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },
    add_info(){
      return this.selectRatePlan
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if (this.callbackAlert.type === 'alert') { // 신청완료
          this.resetPage()
          window.scrollTo(0, 0)
          document.getElementById('wrap').scrollTop = 0
        }
      }
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    alertPopupHandler() {
      if(this.alertPopup){
        this.alertPopup.visible = false
        this.alertPopup.message = ''
        if (this.alertPopup.target) {
          this.$refs[this.alertPopup.target].focus()
        }
        this.alertPopup.target = ''
      }
      if(this.registerPopup){
        this.registerPopup.visible = false
        this.registerPopup.message = ''
      }
    },
    showAlertPopup(message) {
      this.alertPopup.message = message
      this.alertPopup.visible = true
    },
    showRegisterPopup(message){
      this.registerPopup.message = message
      this.registerPopup.visible = true
    },
    checkselect(){
      if(!this.agreePrivacy){
        this.showAlertPopup('개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 신청이 가능합니다.')
      }else if(this.selectRatePlan ==='요금제 선택'){
        this.showAlertPopup('요금제를 선택해주세요.')
        return false
      }else {
        return true
      }
    },
    checkPhoneNumber() {
      if (!this.telNumber.first) {
        this.alertPopup.target = 'telNumberFirst'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.middle) {
        this.alertPopup.target = 'telNumberMiddle'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.last) {
        this.alertPopup.target = 'telNumberLast'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else {
        return true
      }
    },
    checkCaptchar(){
      if(this.isPassCaptchar === false){
        this.showAlertPopup('보안문자를 바르게 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    updateCaptcharState(state) {
      this.isPassCaptchar = state
    },
    categoryChange(){
      this.add_info
    },
    registrationFinal(){
      if (this.checkselect() && this.checkPhoneNumber() && this.checkCaptchar()) {
        this.showRegisterPopup('신청하시겠습니까?')
      }
    },
    registrationConfirm(){
      this.registerPopup.visible = false
      api.EventPostReqEvent({
        tel_no: this.tel_no,
        event_no: this.event_no,
        add_info: this.add_info,
        oper_br_cd: this.oper_br_cd
      })
    },
    resetPage(){
      this.isVisiblePrivacyPopup = false,
      this.agreePrivacy = false,
      this.selectRatePlan = '요금제 선택',
      this.telNumber = {
        first:'010',
        middle:'',
        last:''
      },
      this.isPassCaptchar = false,
      this.$refs.captchar.resetInput(),
      this.$refs.captchar.generate()
    }
  }
}
</script>

<style lang="scss" scoped>
.event_wrap.type2.type_05 .select_box select{
  opacity: 1 !important;
  font-weight: 400;
}
</style>
