
<template>
  <div id="EVENT_00780_GLOBAL">
    <div id="container">
      <div id="contents">
        <div
          id="boardWEB"
          class="event_wrap type type2 type_05"
        >
          <div class="event_view">
            <div>
              <span class="w_hide">
                <img
                  alt="Wow 요금제"
                  src="/content/images/gp00780_pc_01.jpg"
                  width="1120"
                  usemap="#Map"
                  style="height: auto;"
                >
              </span>
              <span class="m_hide">
                <img
                  alt="Wow 요금제"
                  src="/content/images/gp00780_mo_01.jpg"
                  width="640"
                  usemap="#Map"
                  style="height: auto;"
                >
              </span>
              <input
                name="i_sPostscript"
                type="hidden"
                value=""
              >
              <input
                name="cType"
                type="hidden"
                value="10000"
              >
              <div class="notice event_view_field">
                <div class="table_type01">
                  <dl>
                    <dt>Call Plan</dt>
                    <dd>
                      <div class="select_box seb2">
                        <select
                          v-model="selectRatePlan"
                          name="i_sComment"
                          title="요금제 선택하기"
                        >
                          <option value="Choose your Call Plan">
                            Choose your Call Plan
                          </option>
                          <option value="CO78J">
                            00780 Global Plus 300
                          </option>
                          <option value="CO78K">
                            00780 Global Plus 500
                          </option>
                          <option value="CO780">
                            Standard Call Plan
                          </option>
                        </select>
                      </div>
                    </dd>
                  </dl>

                  <dl>
                    <dt>Telephone Number(Mobile/Fixed)</dt>
                    <dd>
                      <div>
                        <div class="C030 duplPhono">
                          <!-- 무선 전화번호 타입 -->
                          <div
                            class="select_box"
                            style="vertical-align: middle;"
                          >
                            <select
                              id="i_sHpNo1"
                              ref="telNumberFirst"
                              v-model="telNumber.first"
                              name="i_sHpNo1"
                              title="무선전화 앞자리 선택"
                            >
                              <option value="">
                                선택
                              </option>
                              <option
                                value="010"
                                selected
                              >
                                010
                              </option>
                              <option value="011">
                                011
                              </option>
                              <option value="016">
                                016
                              </option>
                              <option value="017">
                                017
                              </option>
                              <option value="018">
                                018
                              </option>
                              <option value="019">
                                019
                              </option>
                              <option value="02">
                                02
                              </option>
                              <option value="031">
                                031
                              </option>
                              <option value="032">
                                032
                              </option>
                              <option value="033">
                                033
                              </option>
                              <option value="041">
                                041
                              </option>
                              <option value="042">
                                042
                              </option>
                              <option value="043">
                                043
                              </option>
                              <option value="044">
                                044
                              </option>
                              <option value="051">
                                051
                              </option>
                              <option value="052">
                                052
                              </option>
                              <option value="053">
                                053
                              </option>
                              <option value="054">
                                054
                              </option>
                              <option value="055">
                                055
                              </option>
                              <option value="061">
                                061
                              </option>
                              <option value="062">
                                062
                              </option>
                              <option value="063">
                                063
                              </option>
                              <option value="064">
                                064
                              </option>
                              <option value="070">
                                070
                              </option>
                            </select>
                          </div>
                          <span class="txt_hyphen">-</span>
                          <input
                            id="i_sHpNo2"
                            ref="telNumberMiddle"
                            v-model="telNumber.middle"
                            name="i_sHpNo2"
                            title="휴대폰번호"
                            type="text"
                            maxlength="4"
                            oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                          >
                          <span class="txt_hyphen">-</span>
                          <input
                            id="i_sHpNo3"
                            ref="telNumberLast"
                            v-model="telNumber.last"
                            name="i_sHpNo3"
                            title="휴대폰번호"
                            type="text"
                            maxlength="4"
                            oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                          >
                        </div>
                      </div>
                    </dd>
                  </dl>

                  <dl>
                    <dt>Security Code</dt>
                    <dd class="security">
                      <Captchar
                        ref="captchar"
                        :width="135"
                        :height="42"
                        :placeholder="'Key in Security Code'"
                        @result="updateCaptcharState"
                      />
                      <!-- <div class="captcha">
                        <img
                          id="formCaptchaImage"
                          src="/content/images/captcha.png"
                        >
                      </div>
                      <a
                        href="javascript:void(0)"
                        onclick="fnPreventRefresh($('#formCaptchaImage'));"
                        class="btn_refresh"
                      ><img
                        src="/content/images/btn_refresh.png"
                        alt="새로고침"
                      ></a>

                      <input
                        id="formCaptchaText"
                        type="text"
                        placeholder="Key in Security Code"
                        title="Key in Security Code"
                        class="wid225 captchaText"
                        name="formCaptchaText"
                        maxlength="6"
                      > -->
                    </dd>
                  </dl>
                </div>
                <div class="align-center">
                  <!-- to개발 / 2021-03-12 퍼블수정 | 인라인 스타일 삭제, 클래스 추가 -->
                  <div class="check_box global">
                    <input
                      id="yes"
                      v-model="agreePrivacy"
                      name="yesno"
                      class="myClass YNchk"
                      type="checkbox"
                      value="yes01"
                    >
                    <label for="yes"><span style="font-size: 14px;">Agree personal information provided to third parties/Agree to receive advertising information</span></label>
                  </div>
                  <div>
                    <a
                      class="btn_view"
                      @click="isVisiblePrivacyPopup = true"
                    >View guide for Agree personal information provided to third parties /Agree to receive advertising information</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="event_view_field">
              <div class="btn_center">
                <a
                  id="btnApply"
                  class="btn_type01 round-type"
                  @click="registrationFinal"
                >Apply for a Call Plan</a>
              </div>

              <span class="w_hide">
                <img
                  alt="Wow 요금제"
                  src="/content/images/gp00780_pc_02.jpg"
                >
              </span>
              <span class="m_hide">
                <img
                  alt="Wow 요금제"
                  src="/content/images/gp00780_mo_02.jpg"
                >
              </span>

              <div
                style="display:none"
                class="event_view"
              >
                <div class="blind">
                  <h6>이벤트 참여시 유의사항</h6>
                  <ul>
                    <li>본 이벤트에 참여하기 위해서는 개인정보 수집, 활용 및 취급위탁에 대한 동의가 필요합니다.</li>
                    <li>본 이벤트 경품은 사정에 따라 별도 공지 없이 상품이 변경/대체될 수 있습니다.</li>
                    <li>본 이벤트는 기프티콘 발송을 위해 휴대전화(핸드폰)만 참여가 가능합니다.</li>
                    <li>이벤트 응모, 경품 기프티콘 발송을 위해 휴대전화번호를 정확히 입력하셔야 합니다.</li>
                    <li>
                      본 이벤트는 위성/화상전화, 국제SMS이용 내역과 마이플랜 요금제, 더길게요금제, SKT00700프리
                      부가서비스 등 정액제 요금제 가입자는 참여가 제한됩니다.
                    </li>
                    <li>비정상적이거나 불법적인 방법으로 이벤트에 참여하신 경우 혜택 대상에서 제외되실 수 있습니다.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium ko">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 국제전화 이용에 대한 혜택제공을 위해 아래와 같이 고객정보를 수집하며, <br>고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보의 항목 : 휴대폰번호(연락처)</li>
              <li>개인정보의 수집 및 이용목적 : SK국제전화 요금제에 대한 고객상담 진행</li>
              <li>개인정보의 보유 및 이용기간 : <strong>SK국제전화 요금제 가입 시점부터 해지일자까지</strong></li>
            </ul>
            <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 요금제 혜택 제공에 제한이 될 수 있습니다.</p>
            <p>SK국제전화 요금제 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a
          @click="isVisiblePrivacyPopup = false"
        ><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <!-- //팝업 -->

    <!-- 팝업2 -->
    <div
      id="popMedium02"
      class="pop_wrap02 w757 pop-scroll"
    >
      <!-- to개발 / 클래스 pop-scroll 추가 2021-02-26 -->
      <div class="medium">
        <div
          id="in_l"
          class="company"
        >
          <div class="fs_table">
            <div class="table_title_in_l">
              <h4>Domestic Call Plan</h4>
              <span class="fr txt_point">(Unit : Won/Min., V.A.T Included)</span>
            </div>
            <div class="table_type04">
              <table>
                <caption>Domestic Call Plan</caption>
                <colgroup>
                  <col style="width:33.3%;">
                  <col style="width:33.3%;">
                  <col style="width:33.3%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      Zone
                    </th>
                    <th scope="col">
                      Country
                    </th>
                    <th scope="col">
                      Fee
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr> <td>1</td> <td>JAPAN</td> <td>280.5</td> </tr>
                  <tr> <td>2</td> <td>CHINA</td> <td>118.8</td> </tr>
                  <tr> <td>3</td> <td>VIETNAM</td> <td>607.2</td> </tr>
                  <tr> <td>4</td> <td>MYANMAR</td> <td>805.2</td> </tr>
                  <tr> <td>5</td> <td>HONG KONG</td> <td>118.8</td> </tr>
                  <tr> <td>6</td> <td>SINGAPORE</td> <td>118.8</td> </tr>
                  <tr> <td>7</td> <td>TAIWAN</td> <td>541.2</td> </tr>
                  <tr> <td>8</td> <td>INDONESIA</td> <td>541.2</td> </tr>
                  <tr> <td>9</td> <td>PHILIPPINES</td> <td>448.8</td> </tr>
                  <tr> <td>10</td> <td>MALAYSIA</td> <td>541.2</td> </tr>
                  <tr> <td>11</td> <td>THAILAND</td> <td>118.8</td> </tr>
                  <tr> <td>12</td> <td>MONGOLIAN</td> <td>805.2</td> </tr>
                  <tr> <td>13</td> <td>LAOS etc.</td> <td>825</td> </tr>
                  <tr> <td>14</td> <td>UNITED STATES</td> <td>118.8</td> </tr>
                  <tr> <td>15</td> <td>CANADA</td> <td>118.8</td> </tr>
                  <tr> <td>16</td> <td>ALASKA, HAWAII</td> <td>118.8</td> </tr>
                  <tr> <td>17</td> <td>AMERICAN SAMOA etc.</td> <td>792</td> </tr>
                  <tr> <td>18</td> <td>AUSTRALIA</td> <td>352</td> </tr>
                  <tr> <td>19</td> <td>NEW ZEALAND</td> <td>357.5</td> </tr>
                  <tr> <td>20</td> <td>GUAM, SAIPAN</td> <td>357.5</td> </tr>
                  <tr> <td>21</td> <td>UNITED KINGDOM</td> <td>335.5</td> </tr>
                  <tr> <td>22</td> <td>FRANCE</td> <td>335.5</td> </tr>
                  <tr> <td>23</td> <td>GERMANY</td> <td>335.5</td> </tr>
                  <tr> <td>24</td> <td>ITALY</td> <td>335.5</td> </tr>
                  <tr> <td>25</td> <td>NETHERLANDS etc.</td> <td>528</td> </tr>
                  <tr> <td>26</td> <td>Europe 1</td> <td>653.4</td> </tr>
                  <tr> <td>27</td> <td>Europe 2</td> <td>653.4</td> </tr>
                  <tr> <td>28</td> <td>Europe 3</td> <td>653.4</td> </tr>
                  <tr> <td>29</td> <td>Europe 4</td> <td>653.4</td> </tr>
                  <tr> <td>30</td> <td>Europe 5</td> <td>904.2</td> </tr>
                  <tr> <td>31</td> <td>Europe 6</td> <td>904.2</td> </tr>
                  <tr> <td>32</td> <td>Europe 7</td> <td>904.2</td> </tr>
                  <tr> <td>33</td> <td>Europe 8</td> <td>904.2</td> </tr>
                  <tr> <td>34</td> <td>RUSSIA</td> <td>851.4</td> </tr>
                  <tr> <td>35</td> <td>PAKISTAN</td> <td>877.8</td> </tr>
                  <tr> <td>36</td> <td>BANGLADESH</td> <td>877.8</td> </tr>
                  <tr> <td>37</td> <td>INDIA</td> <td>349.8</td> </tr>
                  <tr> <td>38</td> <td>NEPAL etc.</td> <td>1,188.00</td> </tr>
                  <tr> <td>39</td> <td>IRAN</td> <td>930.6</td> </tr>
                  <tr> <td>40</td> <td>SAUDI ARABIA</td> <td>877.8</td> </tr>
                  <tr> <td>41</td> <td>UNITED ARAB EMIRATES</td> <td>877.8</td> </tr>
                  <tr> <td>42</td> <td>Middle East</td> <td>1,009.80</td> </tr>
                  <tr> <td>43</td> <td>BRAZIL</td> <td>884.4</td> </tr>
                  <tr> <td>44</td> <td>ARGENTINA</td> <td>884.4</td> </tr>
                  <tr> <td>45</td> <td>MEXICO</td> <td>884.4</td> </tr>
                  <tr> <td>46</td> <td>Central South America 1</td> <td>996.6</td> </tr>
                  <tr> <td>47</td> <td>Central South America 2</td> <td>996.6</td> </tr>
                  <tr> <td>48</td> <td>Africa 1</td> <td>1,056.00</td> </tr>
                  <tr> <td>49</td> <td>Africa 2</td> <td>1,056.00</td> </tr>
                  <tr> <td>50</td> <td>Africa 3</td> <td>1,056.00</td> </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="fs_table">
            <div class="table_title_in_l">
              <h4 class="sc">
                Zone table
              </h4>
            </div>
            <div class="table_type04">
              <table>
                <caption>Zone table</caption>
                <colgroup>
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      Zone
                    </th>
                    <th scope="col">
                      Area
                    </th>
                    <th scope="col">
                      국가 및<br class="m_br">지역 수
                    </th>
                    <th scope="col">
                      Country
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td> <td rowspan="13">
                      Asia
                    </td> <td>1</td> <td>JAPAN</td>
                  </tr>
                  <tr> <td>2</td> <td>1</td> <td>CHINA</td> </tr>
                  <tr> <td>3</td> <td>1</td> <td>VIETNAM</td> </tr>
                  <tr> <td>4</td> <td>1</td> <td>MYANMAR</td> </tr>
                  <tr> <td>5</td> <td>1</td> <td>HONG KONG</td> </tr>
                  <tr> <td>6</td> <td>1</td> <td>SINGAPORE</td> </tr>
                  <tr> <td>7</td> <td>1</td> <td>TAIWAN</td> </tr>
                  <tr> <td>8</td> <td>1</td> <td>INDONESIA</td> </tr>
                  <tr> <td>9</td> <td>1</td> <td>PHILIPPINES</td> </tr>
                  <tr> <td>10</td> <td>1</td> <td>MALAYSIA</td> </tr>
                  <tr> <td>11</td> <td>1</td> <td>THAILAND</td> </tr>
                  <tr> <td>12</td> <td>1</td> <td>MONGOLIAN</td> </tr>
                  <tr> <td>13</td> <td>4</td> <td>BRUNEI, CAMBODIA, LAOS, MACAU</td> </tr>
                  <tr>
                    <td>14</td> <td rowspan="3">
                      North America
                    </td> <td>1</td> <td>UNITED STATES</td>
                  </tr>
                  <tr> <td>15</td> <td>1</td> <td>CANADA</td> </tr>
                  <tr> <td>16</td> <td>2</td> <td>ALASKA, HAWAII</td> </tr>
                  <tr>
                    <td>17</td> <td rowspan="4">
                      Oceania
                    </td> <td>23</td> <td>AMERICAN SAMOA, ANTARTICA, CHRISTMAS IS., COOK ISLANDS, EAST TIMOR, FIJI ISLANDS, KIRIBATI, MARSHALL ISLAND, MICRONESIA, NAURU, NEW CALEDONIA, NIUE, NORFOLK IS., PALAU, PAPUA NEW GUINEA, SOLOMON ISLANDS, ST. PIERRE, TAHITI, TOKELAU, TONGA ISLANDS, TUVALU, VANUATU, WALLIS & FORTUNA ISLANDS, WESTERN SAMOA</td>
                  </tr>
                  <tr> <td>18</td> <td>1</td> <td>AUSTRALIA</td> </tr>
                  <tr> <td>19</td> <td>1</td> <td>NEW ZEALAND</td> </tr>
                  <tr> <td>20</td> <td>2</td> <td>GUAM, SAIPAN</td> </tr>
                  <tr>
                    <td>21</td> <td rowspan="14">
                      Europe
                    </td> <td>1</td> <td>UNITED KINGDOM</td>
                  </tr>
                  <tr> <td>22</td> <td>1</td> <td>FRANCE</td> </tr>
                  <tr> <td>23</td> <td>1</td> <td>GERMANY</td> </tr>
                  <tr> <td>24</td> <td>1</td> <td>ITALY</td> </tr>
                  <tr> <td>25</td> <td>4</td> <td>NETHERLANDS, SPAIN, SWEDEN, SWITZERLAND</td> </tr>
                  <tr> <td>26</td> <td>7</td> <td>AUSTRIA, CYPRUS, DENMARK, GREECE, MADEIRA, NORWAY, PORTUGAL</td> </tr>
                  <tr> <td>27</td> <td>5</td> <td>BELGIUM, FAEROE ISLANDS, GREENLAND, ICELAND, IRELAND</td> </tr>
                  <tr> <td>28</td> <td>5</td> <td>FINLAND, LUXEMBOURG, MONACO, SAN MARINO, VATICAN CITY</td> </tr>
                  <tr> <td>29</td> <td>6</td> <td>ANDORRA, BALEARIC, GIBRALTAR, LIECHTENSTEIN, MALTA, TURKEY</td> </tr>
                  <tr> <td>30</td> <td>3</td> <td>BULGARIA, CZECH REPUBLIC, HUNGARY</td> </tr>
                  <tr> <td>31</td> <td>4</td> <td>ESTONIA, KAZAKHSTAN, POLAND, SLOVAKIA</td> </tr>
                  <tr> <td>32</td> <td>10</td> <td>ALBANIA, BOSNIA-HERCEGOVINA, CROATIA, GEORGIA, LATVIA, LITHUANIA, SERBIA, SLOVENIA, UZBEKISTAN</td> </tr>
                  <tr> <td>33</td> <td>10</td> <td>ARMENIA, AZERBAIJAN, BELARUS, KYRGYZSTAN, MACEDONIA, MOLDOVA, ROMANIA, TAJISKISTAN, TURKMENISTAN, UKRAINE</td> </tr>
                  <tr> <td>34</td> <td>1</td> <td>RUSSIA</td> </tr>
                  <tr>
                    <td>35</td> <td rowspan="4">
                      West South Asia
                    </td> <td>1</td> <td>PAKISTAN</td>
                  </tr>
                  <tr> <td>36</td> <td>1</td> <td>BANGLADESH</td> </tr>
                  <tr> <td>37</td> <td>1</td> <td>INDIA</td> </tr>
                  <tr> <td>38</td> <td>4</td> <td>BHUTAN, MALDIVES, NEPAL, SRI LANKA</td> </tr>
                  <tr>
                    <td>39</td> <td rowspan="4">
                      Middle East
                    </td> <td>1</td> <td>IRAN</td>
                  </tr>
                  <tr> <td>40</td> <td>1</td> <td>SAUDI ARABIA</td> </tr>
                  <tr> <td>41</td> <td>1</td> <td>UNITED ARAB EMIRATES</td> </tr>
                  <tr> <td>42</td> <td>11</td> <td>AFGHANISTAN, BAHRAIN, IRAQ, ISRAEL, JORDAN, KUWAIT, LEBANON, OMAN, PALESTINE, QATAR, SYRIA, YEMEN</td> </tr>
                  <tr>
                    <td>43</td> <td rowspan="5">
                      Central South America
                    </td> <td>1</td> <td>BRAZIL</td>
                  </tr>
                  <tr> <td>44</td> <td>1</td> <td>ARGENTINA</td> </tr>
                  <tr> <td>45</td> <td>1</td> <td>MEXICO</td> </tr>
                  <tr> <td>46</td> <td>15</td> <td>AMERICAN VIRGIN IS., ARUBA, BERMUDA, BOLIVIA,BRITISH VIRGIN IS., CAYMAN ISLANDS, CHILE, COLOMBIA, COSTA RICA, EL SALVADOR, GUADELOUPE, HAITI, MARTINIQUE, NETHERLANDS ANTILLES, URUGUAY</td> </tr>
                  <tr> <td>47</td> <td>28</td> <td>ANGUILLA, ANTIGUA, BAHAMAS, BARBADOS, BELIZE, CUBA, DJIBOUTI, DOMINICA, ECUADOR, FALKLAND ISLANDS, FRENCH GUIANA, GRENADA, GUATEMALA, GUYANA, HONDURAS,  JAMAICA, MONTSERRAT, NICARAGUA, PANAMA, PARAGUAY, PERU, ST. KITTS, ST. LUCIA, ST. VINCENT, SURINAME, TRINIDAD & TOBAGO, TURKS & CAICOS ISLANDS, VENEZUELA</td> </tr>
                  <tr>
                    <td>48</td> <td rowspan="3">
                      Africa
                    </td> <td>12</td> <td>ALGERIA, ANGOLA, BOTSWANA, BURUNDI, CENTRAL AFRICAN REPUBLIC, GABON REPUBLIC, GHANA, LIBYAN A P S J, MALAWI, REUNION ISLAND, SOUTH AFRICA, SWAZILAND, ZIMBABWE</td>
                  </tr>
                  <tr> <td>49</td> <td>23</td> <td>BURKINA FASO, CAMEROON, CAPE VERDE ISLANDS, CONGO, GAMBIA, GUINEA, GUINEA BISSAU, IVORY COAST, LESOTHO, LIBERIA, MAURITANIA, MAURITIUS, MOROCCO, NAMIBIA, NIGER REPUBLIC, RWANDA, SENEGAL, SEYCHELLES ISLAND, SOUTH SUDAN, SUDAN, TUNISIA, ZAIRE, ZAMBIA, ZANZIBAR</td> </tr>
                  <tr> <td>50</td> <td>25</td> <td>ASCENSION ISLAND, BENIN, CANARY IS., CHAD, COMOROS, DIEGO GARCIA, DJIBOUTI, EGYPT, EQUATORIAL GUINEA, ERITREA, ETHIOPIA, KENYA, MADAGASCAR, MALI REPUBLIC, MAYOTTE ISLAND, NIGERIA, SAO TOME, SIERRA LEONE, SOMALIA, ST. HELENA, TANZANIA, TOGO, UGANDA, WEST SAHARA</td> </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <a href="javascript:;"><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <!-- //팝업2 -->

    <!-- 팝업3 -->
    <div
      id="popMedium03"
      class="pop_wrap02 w757 pop-scroll"
    >
      <!-- to개발 / 클래스 pop-scroll 추가 2021-02-26 -->
      <div class="medium">
        <div
          id="in_l"
          class="company"
        >
          <div class="fs_table">
            <div class="table_title_in_l">
              <h4> 00780 자동통화서비스</h4>
              <span class="fr txt_point">(KRW/sec. VAT included)</span>
            </div>
            <div class="table_type04">
              <table>
                <caption> 00780 자동통화서비스</caption>
                <colgroup>
                  <col style="width:33.3%;">
                  <col style="width:33.3%;">
                  <col style="width:33.3%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      권역
                    </th>
                    <th scope="col">
                      국가 및 지역명
                    </th>
                    <th scope="col">
                      요금
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr> <td>1</td> <td>USA, Singapore, Canada,Hongkong</td> <td>1.177 </td> </tr>
                  <tr> <td>2</td> <td>China</td> <td>1.177 </td> </tr>
                  <tr> <td>3</td> <td>Thailand, Hawaii</td> <td>1.496 </td> </tr>
                  <tr> <td>4</td> <td>India</td> <td>1.947 </td> </tr>
                  <tr> <td>5</td> <td>Saipan, Peru</td> <td>2.002 </td> </tr>
                  <tr> <td>6</td> <td>Malaysia, Bangladesh</td> <td>2.013 </td> </tr>
                  <tr> <td>7</td> <td>Taiwan</td> <td>2.046 </td> </tr>
                  <tr> <td>8</td> <td>Vietnam</td> <td>2.145 </td> </tr>
                  <tr> <td>9</td> <td>Laos</td> <td>2.200 </td> </tr>
                  <tr> <td>10</td> <td>Cambodia</td> <td>2.200 </td> </tr>
                  <tr> <td>11</td> <td>Macau</td> <td>2.299 </td> </tr>
                  <tr> <td>12</td> <td>Alaska</td> <td>2.365 </td> </tr>
                  <tr> <td>13</td> <td>Mongol</td> <td>2.398 </td> </tr>
                  <tr> <td>14</td> <td>Uzbekistan</td> <td>2.596 </td> </tr>
                  <tr> <td>15</td> <td>Pakistan</td> <td>2.596 </td> </tr>
                  <tr> <td>16</td> <td>Russia</td> <td>2.750 </td> </tr>
                  <tr> <td>17</td> <td>Indonesia</td> <td>3.003 </td> </tr>
                  <tr> <td>18</td> <td>Germany</td> <td>3.300 </td> </tr>
                  <tr> <td>19</td> <td>Kazakhstan</td> <td>3.399 </td> </tr>
                  <tr> <td>20</td> <td>Nigeria, Australia</td> <td>3.597 </td> </tr>
                  <tr> <td>21</td> <td>Sri Lanka</td> <td>3.850 </td> </tr>
                  <tr> <td>22</td> <td>Kyrgyzstan</td> <td>3.905 </td> </tr>
                  <tr> <td>23</td> <td>Guam, Republic of South Africa, and 11 countries</td> <td>4.004 </td> </tr>
                  <tr> <td>24</td> <td>Egypt</td> <td>4.103 </td> </tr>
                  <tr> <td>25</td> <td>Philippines</td> <td>4.345 </td> </tr>
                  <tr> <td>26</td> <td>UK, France</td> <td>4.400 </td> </tr>
                  <tr> <td>27</td> <td>Japan</td> <td>4.697 </td> </tr>
                  <tr> <td>28</td> <td>Nepal, Saudi Arabia, Tajikistan</td> <td>4.697 </td> </tr>
                  <tr> <td>29</td> <td>Syria, Uganda, Ukraine</td> <td>5.005 </td> </tr>
                  <tr> <td>30</td> <td>UAE</td> <td>5.104 </td> </tr>
                  <tr> <td>31</td> <td>Maldives, Bhutan</td> <td>5.137 </td> </tr>
                  <tr> <td>32</td> <td>New Zealand, Cameroon</td> <td>5.500 </td> </tr>
                  <tr> <td>33</td> <td>Greece, Netherlands, and 19 Countries</td> <td>5.896 </td> </tr>
                  <tr> <td>34</td> <td>Sudan, Republic of South Sudan</td> <td>6.303 </td> </tr>
                  <tr> <td>35</td> <td>Ghana</td> <td>6.600 </td> </tr>
                  <tr> <td>36</td> <td>Ethiopia</td> <td>7.502 </td> </tr>
                  <tr> <td>37</td> <td>Italy</td> <td>7.700 </td> </tr>
                  <tr> <td>38</td> <td>Norway, and 15 countries</td> <td>7.799 </td> </tr>
                  <tr> <td>39</td> <td>Swiss</td> <td>7.887 </td> </tr>
                  <tr> <td>40</td> <td>Myanmar</td> <td>8.250 </td> </tr>
                  <tr> <td>41</td> <td>Gabon, Guatemala and 49 countries</td> <td>9.801 </td> </tr>
                  <tr> <td>42</td> <td>Greenland, Principality of Liechtenstein, and 11 countries</td> <td>10.230 </td> </tr>
                  <tr> <td>43</td> <td>Nicaragua, Montenegro, and 11 countries</td> <td>11.803 </td> </tr>
                  <tr> <td>44</td> <td>Republic of Nauru and 21 countries</td> <td>12.650 </td> </tr>
                  <tr> <td>45</td> <td>Guyana, Bosnia And Herzegovina and 6 counties</td> <td>13.596 </td> </tr>
                  <tr> <td>46</td> <td>Latvia, Macedonia and 4 countries</td> <td>14.113 </td> </tr>
                  <tr> <td>47</td> <td>Guadeloupe and 7 countries</td> <td>15.345 </td> </tr>
                  <tr> <td>48</td> <td>Libya and 7 countries</td> <td>15.598</td> </tr>
                  <tr> <td>49</td> <td>Congo</td> <td>15.796</td> </tr>
                  <tr> <td>50</td> <td>Gambia and 27 countries</td> <td>16.313</td> </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <a href="javascript:;"><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <!-- //팝업3 -->
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'INFO'"
      :c-type="'btn_type02'"
      :button-text="'Confirm'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>

    <Popup
      :is-visible.sync="registerPopup.visible"
      :pop-title="'INFO'"
      :c-type="'btn_type02'"
      :btn-divide="'double'"
      :button-text="'Cancel'"
      :button-text-type2="'Confirm'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
      @confirm="registrationConfirm"
    >
      <p v-html="registerPopup.message" />
    </Popup>
  </div>
</template>

<script>
import api from '@/api'
import Captchar from '@/components/Captchar'
import Popup from '@/components/popup/Popup'
export default {
  name: 'Event00780Global',
  components: {
    Captchar,
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    isPassCaptchar: false,
    isVisiblePrivacyPopup:false,
    selectRatePlan:'Choose your Call Plan',
    telNumber:{
      first:'010'
    },
    agreePrivacy:false,
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    registerPopup: {
      visible: false,
      message: '',
      target: ''
    },
    event_no:'ZZ000000000000005902'
  }
 },
 computed: {
  tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  add_info(){
    return this.selectRatePlan
  },
  callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
 },
 watch: {
  callbackAlert: {
      deep: true,
      handler() {
        if (this.callbackAlert.type === 'alert') {
          this.resetPage()
          window.scrollTo(0, 0)
          document.getElementById('wrap').scrollTop = 0
        }
      }
    }
},
 created () {},
 mounted () {
 },
 methods: {
  updateCaptcharState(state) {
    this.isPassCaptchar = state
  },
  alertPopupHandler() {
    if(this.alertPopup){
      this.alertPopup.visible = false
      this.alertPopup.message = ''
      if (this.alertPopup.target) {
        this.$refs[this.alertPopup.target].focus()
      }
      this.alertPopup.target = ''
    }
    if(this.registerPopup){
      this.registerPopup.visible = false
      this.registerPopup.message = ''
    }
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  showRegisterPopup(message){
    this.registerPopup.message = message
    this.registerPopup.visible = true
  },
  checkselect(){
    if(!this.agreePrivacy){
      this.showAlertPopup('You can apply if you check the consignment agreement to use and handle personal information.')
    }else if(this.selectRatePlan ==='Choose your Call Plan'){
      this.showAlertPopup('Choose your Call Plan')
      return false
    }else {
      return true
    }
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('Enter your phone number.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('Enter your phone number.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('Enter your phone number.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('Please enter your cell phone number correctly.')
    } else {
      return true
    }
  },
  checkCaptchar(){
    if(this.isPassCaptchar === false){
      this.showAlertPopup('Please enter the security code correctly.')
      return false
    } else {
      return true
    }
  },
  registrationFinal(){
    if (this.checkselect() && this.checkPhoneNumber() && this.checkCaptchar()) {
      this.showRegisterPopup('Would you like to apply?')
    }
  },
  registrationConfirm(){
    this.registerPopup.visible = false
    api.EventPostReqEvent({
      tel_no: this.tel_no,
      event_no: this.event_no,
      add_info: this.add_info,
    })
  },
  resetPage(){
    this.isVisiblePrivacyPopup = false,
    this.agreePrivacy = false,
    this.selectRatePlan = 'Choose your Call Plan',
    this.telNumber = {
      first:'010',
      middle:'',
      last:''
    },
    this.isPassCaptchar = false,
    this.$refs.captchar.resetInput()
    this.$refs.captchar.generate()
  }
 }
}
</script>

<style lang="scss" scoped>
.event_wrap.type2.type_05 .select_box select{
  opacity: 1 !important;
  font-weight: 400;
}
</style>