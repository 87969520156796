<template>
  <div class="family_site_container">
    <a
      href="https://ethics.sk.co.kr/eng"
      class="family_site_link"
      target="_blank"
      >Ethics Counsel / Report your Concern</a
    >
    <div class="family_site_wrap" :class="{ on: isOpen }">
      <button
        type="button"
        class="family_site_button"
        @click="isOpen = !isOpen"
      >
        {{ anchorText }}
        <span>
          <img src="/content/images/btn_family_site.png" alt="펼치기" />
        </span>
      </button>
      <transition name="slide">
        <ul v-if="isOpen">
          <li v-for="(item, index) in familySiteList" :key="index">
            <a target="_blank" :title="item.title" :href="item.link">{{
              item.text
            }}</a>
          </li>
        </ul>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "EngFamilySite",
  components: {},
  props: {
    anchorText: {
      type: String,
      required: true,
    },
    familySiteList: {
      type: Array,
      required: true,
    },
  },
  emits: [],
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.family_site_wrap ul {
  display: block !important;
  width: 100% !important;
  li:last-child {
    border-bottom: 1px dotted #565656;
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s linear;
}
.slide-enter-to,
.slide-leave {
  max-height: 640px;
  overflow: hidden;
}
.slide-enter,
.slide-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
