
<template>
  <div id="Lolacard">
    <div id="container">
      <div id="contents">
        <div
          id="boardWEB"
          class="event-wrap loca"
        >
          <div class="event">
            <section> <img src="/content/images/loca_top.jpg"> </section>
            <section class="contents">
              <img
                src="/content/images/contents_011.png"
                class="type"
              >
              <img src="/content/images/contents_012.png">
            </section>
            <section class="form-area">
              <h1>
                <img
                  src="/content/images/tit_benefit.png"
                  alt="제휴혜택받기"
                >
              </h1>
              <div class="agree-area">
                <input
                  id="yes"
                  v-model="agreePrivacy"
                  name="yesno"
                  type="checkbox"
                  value=""
                  class="YNchk"
                >
                <label for="yes">롯데카드와  국제전화 00700 제휴혜택을 받기 위해 개인정보 수집하는 것에 동의합니다.</label>
                <a
                  id="myBtn"
                  @click="isVisiblePrivacyPopup = true"
                >자세히 보기</a>
              </div>
              <dl>
                <dt>전화번호(무선번호만 입력)</dt>
                <dd>
                  <div class="phone-box">
                    <input
                      id="i_sHpNo1"
                      ref="telNumberFirst"
                      v-model="telNumber.first"
                      title="전화번호"
                      type="text"
                      name="i_sHpNo1"
                      minlength="3"
                      maxlength="3"
                      class="numbersOnly"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    >
                    <span class="txt_hyphen">-</span>
                    <input
                      id="i_sHpNo2"
                      ref="telNumberMiddle"
                      v-model="telNumber.middle"
                      title="전화번호"
                      type="text"
                      name="i_sHpNo2"
                      minlength="3"
                      maxlength="4"
                      class="numbersOnly"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    >
                    <span class="txt_hyphen">-</span>
                    <input
                      id="i_sHpNo3"
                      ref="telNumberLast"
                      v-model="telNumber.last"
                      title="전화번호"
                      type="text"
                      name="i_sHpNo3"
                      minlength="4"
                      maxlength="4"
                      class="numbersOnly"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    >
                  </div>
                  <a
                    class="btn-s btn_sendnum"
                    @click="requestSMSCert"
                  >SMS인증하기</a>
                </dd>
              </dl>
              <dl
                v-if="authNumberInputVisible"
                id="numberCheck"
              >
                <dt>인증번호 입력</dt>
                <dd>
                  <input
                    ref="auth_no"
                    v-model="auth_no"
                    autocomplete="off"
                    title="인증번호"
                    name="i_sCertificationNo"
                    type="text"
                    maxlength="6"
                    pattern="[0-9]*"
                    class="numbersOnly"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  >
                  <a
                    class="btn-s btn_checknum"
                    @click="requestCert"
                  >인증번호 확인</a>
                </dd>
              </dl>
              <dl>
                <dt>롤라카드번호 등록 (뒷자리 숫자 4개)</dt>
                <dd>
                  <span
                    id="card-num"
                    class="card-num"
                  >**** - **** - **** -
                    <input
                      ref="lolaCardNumberOne"
                      v-model="lolaCardNumber.one"
                      title="카드번호 등록"
                      type="text"
                      name="i_sCardNum"
                      maxlength="1"
                      class="numbersOnly cardNum"
                      pattern="\d*"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      @keyup="enterText"
                    >
                    <input
                      ref="lolaCardNumberTwo"
                      v-model="lolaCardNumber.two"
                      title="카드번호 등록"
                      type="text"
                      name="i_sCardNum"
                      maxlength="1"
                      pattern="\d*"
                      class="numbersOnly cardNum"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      @keyup="enterText"
                    >
                    <input
                      ref="lolaCardNumberThree"
                      v-model="lolaCardNumber.three"
                      title="카드번호 등록"
                      type="text"
                      name="i_sCardNum"
                      maxlength="1"
                      pattern="\d*"
                      class="numbersOnly cardNum"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      @keyup="enterText"
                    >
                    <input
                      ref="lolaCardNumberFour"
                      v-model="lolaCardNumber.four"
                      title="카드번호 등록"
                      type="text"
                      name="i_sCardNum"
                      maxlength="1"
                      pattern="\d*"
                      class="numbersOnly cardNum"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    >
                  </span>
                  <!-- <a
                    class="btn-s btn_checkDupl"
                    @click="registrationLoLaCard"
                  >중복확인하기</a>
                  <a
                    class="btn-s btn_reDupl"
                    href="javascript:;"
                  >카드번호 재입력</a> -->
                </dd>
                <p>※ 혜택 제공을 위해 소지하신 롤라카드 뒤에서부터 4자리 숫자를 정확히 입력해주세요.</p>
              </dl>
              <dl>
                <dt>받으실 혜택 (택 1)</dt>
                <dd>
                  <div class="benefit-item">
                    <input
                      id="benefit_01"
                      v-model="svc_tp"
                      name="i_sbenefit"
                      type="radio"
                      value="7FREE"
                    >
                    <label for="benefit_01"><img src="/content/images/benefit_item_01.png"><span>매월 전세계 어디나 국제전화<br> 00700 7분 무료통화 제공</span></label>
                  </div>
                  <div class="benefit-item">
                    <input
                      id="benefit_02"
                      v-model="svc_tp"
                      name="i_sbenefit"
                      type="radio"
                      value="10SAVE"
                    >
                    <label for="benefit_02"><img src="/content/images/benefit_item_02.png"><span>국제전화 00700 이용금액의 10%<br> 롤라머니로 적립</span></label>
                  </div>
                </dd>
                <p>
                  ※ 롤라머니 적립 선택시, 국제전화 00700 사용 후 익월에 사용금액의 10%를 적립받으실 수 있습니다.<br>
                  ※ 00700 7분 무료통화 선택시, 국가 제한없이 매월 최초 사용분부터 누적 7분까지 자동 무료공제됩니다.
                </p>
              </dl>
              <h2>
                <a
                  id="btnApply"
                  class="btn-confirm"
                  @click="registrationFinal"
                >등록 완료</a>
              </h2>
            </section>
            <section class="contents">
              <img src="/content/images/contents_03.png">
            </section>
            <section class="bottom">
              <img src="/content/images/loca_bottom.jpg">
              <div class="hide">
                <h1>유의사항</h1>
                본 제휴 혜택 제공을 위해선 개인정보 수집에 대한 동의가 반드시 필요하며 거부 시 제휴 혜택 적용이 불가합니다.
                롤라카드 이용 고객님만 제휴 혜택을 받으실 수 있으며, 카드번호 뒤에서 부터 4자리 숫자를 정상적으로 입력하셔야만 등록이 완료됩니다.
                전화번호, 카드번호 혹은 제휴혜택 변경 시 SK국제전화 00700 고객센터로 전화주신 후 등록된 고객정보를 변경하셔야 지속적으로 혜택이 제공됩니다.
                고객정보 등록 시 롤라카드번호 뒷자리 숫자 4개를 부정확하게 입력하신 경우,혜택 제공 대상에서 제외됩니다.
                기존에 SK국제전화 00700 의 정액형 요금제(All pass, 파리바게트, 무비요금제 등)를 이용하시는 경우, 기본료 및 기본제공분수 외 초과 사용료 및 초과 사용분수에 대해 혜택을 적용받으실 수 있으십니다.
                위성전화(인마셋 등), 화상전화에는 해당 00700 7분 무료통화혜택 및 롤라머니 적립이 제공되지 않습니다.
                본 제휴혜택 관련 문의: SK국제전화 00700 고객센터 1599-0070(운영시간 : 평일 9시~18시)
              </div>
            </section>
          </div>
          <!-- to개발 / 2021-03-05 퍼블수정 | .pop_wrap02 삭제-->
          <div
            v-if="isVisiblePrivacyPopup"
            id="myModal"
            class="modal"
            style="display:block;"
          >
            <!-- Modal content -->
            <div class="modal-content type01">
              <span
                class="close-bt close"
                @click="isVisiblePrivacyPopup = false"
              >×</span>
              <h2>개인정보 수집 동의 안내</h2>
              <p>
                SK텔링크 주식회사는 국제전화 이용 및 롯데카드 제휴 혜택제공을 위해 아래와 같이 고객정보를 수집하며, 고객정보 취급에 관한
                상세한 사항은 www.sktelink.com에 게시된 개인정보처리방침을 참조하시기 바랍니다.<br><br>

                - 개인정보를 제공받는 자 : <strong>롯데카드</strong><br>
                - 수집하는 개인정보 항목 : 핸드폰번호(무선번호)/롤라카드번호(뒷자리 숫자 4개)<br>
                - 개인정보의 수집 및 이용목적 : <strong>SK국제전화 00700 이용에 대한 혜택 제공 및 롤라머니 적립 진행, 관련 고객상담 진행</strong><br>
                - 개인정보 보유 및 이용기간 : <strong>혜택을 위한 고객정보 등록 시점부터 표준요금제 전환 이후 5년 경과 후 삭제</strong><br>
                - 개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 혜택 제공이 제한될 수 있습니다.<br><br>

                SK국제전화 00700 이용에 따른 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.
              </p>
              <h3>
                <a
                  class="btn-m close"
                  @click="isVisiblePrivacyPopup = false"
                >확인</a>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  name: 'Lolacard',
  components: {
    Popup
  },
   props: {
   },
   emits: [
   ],
   data () {
    return {
      isVisiblePrivacyPopup: false,
      alertPopup: {
        visible: false,
        message: '',
        target: ''
      },
      agreePrivacy: false,
      telNumber: {},
      lolaCardNumber: {},
      ticket: '',
      auth_no: '',
      svc_tp: '',
      authNumberInputVisible: false,
      isSmsCert: false
    }
   },
   computed: {
    svc_tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },
    card_no() {
      return `${this.lolaCardNumber.one}${this.lolaCardNumber.two}${this.lolaCardNumber.three}${this.lolaCardNumber.four}`
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
   },
   watch: {
    callbackAlert: {
        deep: true,
        handler() {
          if(this.callbackAlert.code === '0002' || this.callbackAlert.code ==='E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code === 'E029'){ // 기신청, 신청완료, 신청 유형 오류, 인증티켓 유효시간초과, 인증티켓 오류, 미인증 티켓
            this.resetPage()
            window.scrollTo(0, 0)
            document.getElementById('wrap').scrollTop = 0
          }
          if (this.callbackAlert.code === 'E002') {  //인증5회 오류
            this.telNumber = {
              first: '',
              middle: '',
              last: ''
            },
            this.authNumberInputVisible = false,
            this.auth_no = '',
            this.ticket = ''
          }
          if (this.callbackAlert.code === 'E001') { //인증시간 초과
            this.auth_no = ''
            this.ticket = ''
          }
        }
      }
    },
   created () {},
   mounted () {
   },
  methods: {
    enterText(event) {
      if(event.target.value.length > 0) {
        event.target.nextSibling.focus()
      }
    },
    alertPopupHandler() {
      this.alertPopup.visible = false
      this.alertPopup.message = ''
      if (this.alertPopup.target) {
        this.$refs[this.alertPopup.target].focus()
      }
      this.alertPopup.target = ''
    },
    showAlertPopup(message) {
      this.alertPopup.message = message
      this.alertPopup.visible = true
    },
    checkPhoneNumber() {
      if (!this.telNumber.first) {
        this.alertPopup.target = 'telNumberFirst'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.middle) {
        this.alertPopup.target = 'telNumberMiddle'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.last) {
        this.alertPopup.target = 'telNumberLast'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.first.length < 3 || this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else if (!this.agreePrivacy) {
        this.showAlertPopup('개인정보 수집에 동의하셔야 신청이 가능합니다.')
      } else {
        return true
      }
    },
    checkLoLaCardNumber() {
      if (!this.lolaCardNumber.one) {
      this.alertPopup.target = 'lolaCardNumberOne'
        this.showAlertPopup('카드번호를 입력해 주세요.')
        return false
      } else if (!this.lolaCardNumber.two) {
      this.alertPopup.target = 'lolaCardNumberTwo'
        this.showAlertPopup('카드번호를 입력해 주세요.')
        return false
      } else if (!this.lolaCardNumber.three) {
      this.alertPopup.target = 'lolaCardNumberThree'
        this.showAlertPopup('카드번호를 입력해 주세요.')
        return false
      } else if (!this.lolaCardNumber.four) {
      this.alertPopup.target = 'lolaCardNumberFour'
        this.showAlertPopup('카드번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkAuthNumber() {
      if (!this.auth_no) {
        this.alertPopup.target = 'auth_no'
        this.showAlertPopup('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkSMSCert() {
      if(!this.isSmsCert) {
        this.showAlertPopup('SMS인증을 완료해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkBenefit() {
      if (!this.svc_tp) {
        this.showAlertPopup('혜택을 선택해 주세요.')
        return false
      } else {
        return true
      }
    },
    // SMS 인증 요청
    requestSMSCert() {
      if (this.checkPhoneNumber()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
          .then(response => {
            if (response.data.sms_ticket) {
              this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
              this.ticket = response.data.sms_ticket
              this.authNumberInputVisible = true
            }
          })
      }
    },
    // 인증 번호로 SMS 인증 확인
    requestCert() {
      if (this.checkAuthNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
          .then(response => {
            if (response.data.result_cd === '0001') {
              this.isSmsCert = true
              this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
            }
          })
      }
    },
    // 등록 완료
    registrationFinal() {
      if (this.checkPhoneNumber() && this.checkSMSCert() && this.checkLoLaCardNumber() && this.checkBenefit()) {
        api.EventPostReqLotteCardEvent({
          sms_ticket: this.ticket,
          card_no: this.card_no,
          svc_tp: this.svc_tp
        })
      }
    },
    resetPage(){
      this.lolaCardNumber = {
        one: '',
        two: '',
        three: '',
        four: ''
      },
      this.agreePrivacy = false,
      this.telNumber = {
        first: '',
        middle: '',
        last: ''
      },
      this.authNumberInputVisible = false,
      this.auth_no = '',
      this.ticket = '',
      this.svc_tp= '',
      this.isSmsCert = false
    }
  }
}
</script>