<template>
  <div id="ESG">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg01">
          <h3 class="title">사회적가치(ESG)</h3>
          <p class="txt">
            SK텔링크는 ESG(환경·사회·지배구조)<br />경영을 선도하는 기업입니다
          </p>
          <img src="/content/images/sub_header_cp01.jpg" alt="비쥬얼 이미지" />
        </div>

        <div class="company esg">
          <div class="cp_area pb0">
            <div class="cp_box">
              <h4>ESG추진방향</h4>
              <p>
                SK텔링크는 ESG(환경·사회·지배구조) 경영을 선도하는
                기업으로서,<br class="pc-only" />
                전사적 차원에서 ESG 경영의 추진 방향을 설정하고 지속가능성을
                강화하기 위해 노력하고 있습니다.
              </p>
            </div>
          </div>

          <div class="esg_box">
            <div class="scroll_area">
              <div class="inner">
                <img
                  src="/content/images/20220512_esg_img.png"
                  alt="친환경 ICT로 진화, ICT의 사화적 가치 창출, 책임경영/투명경영 강화"
                />
              </div>
            </div>
            <div class="ui-btn-wrap">
              <a class="ui-btn" @click="isVisibleFn()">
                ESG 경영개선 공시자료
              </a>
            </div>
          </div>
        </div>

        <!-- company -->
        <div v-if="isVisibleData">
          <div id="tabTarget" class="tab_type01">
            <ul>
              <li :class="tabIndex == 1 ? 'on' : ''">
                <a
                  :title="tabIndex == 1 ? '현재메뉴 선택됨' : ''"
                  @click="tabActive(1)"
                  >사회 데이터</a
                >
              </li>
              <li :class="tabIndex == 2 ? 'on' : ''">
                <a
                  :title="tabIndex == 2 ? '현재메뉴 선택됨' : ''"
                  @click="tabActive(2)"
                  >환경 데이터</a
                >
              </li>
            </ul>
          </div>

          <div class="company esg">
            <div v-if="tabIndex == 1">
              <div class="esg_box">
                <h4>구성원 일반 현황</h4>
                <div class="table_type04">
                  <table>
                    <caption>
                      구성원 일반 현황
                    </caption>
                    <colgroup>
                      <col style="width: 14%" />
                      <col style="width: 14%" />
                      <col style="width: 14%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colspan="3">&nbsp;</th>
                        <th>단위</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="5">
                          인원<br class="mobile-only" />
                          현황
                        </td>
                        <td rowspan="3">
                          전체<br class="mobile-only" />
                          인력
                        </td>
                        <td>계</td>
                        <td>명</td>
                        <td>155</td>
                        <td>155</td>
                        <td>164</td>
                      </tr>
                      <tr>
                        <td>국내</td>
                        <td>명</td>
                        <td>155</td>
                        <td>155</td>
                        <td>164</td>
                      </tr>
                      <tr>
                        <td>해외</td>
                        <td>명</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td colspan="2">정규직 고용</td>
                        <td>명</td>
                        <td>150</td>
                        <td>150</td>
                        <td>159</td>
                      </tr>
                      <tr>
                        <td colspan="2">비정규직 고용</td>
                        <td>명</td>
                        <td>5</td>
                        <td>5</td>
                        <td>5</td>
                      </tr>
                      <tr>
                        <td rowspan="4">성별</td>
                        <td colspan="2">여성 전체</td>
                        <td>명</td>
                        <td>31</td>
                        <td>30</td>
                        <td>36</td>
                      </tr>
                      <tr>
                        <td colspan="2">여성(정규직)</td>
                        <td>명</td>
                        <td>31</td>
                        <td>30</td>
                        <td>36</td>
                      </tr>
                      <tr>
                        <td colspan="2">여성(비정규직)</td>
                        <td>명</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td colspan="2">여성인력 비율</td>
                        <td>%</td>
                        <td>20</td>
                        <td>19</td>
                        <td>22</td>
                      </tr>

                      <tr>
                        <td>
                          장애인<br class="mobile-only" />
                          고용
                        </td>
                        <td colspan="2">장애인 고용 전체</td>
                        <td>명</td>
                        <td>2</td>
                        <td>2</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td rowspan="6">신규<br class="mobile-only" />채용</td>
                        <td colspan="2">
                          전체<br class="mobile-only" />(정규+비정규)
                        </td>
                        <td>명</td>
                        <td>6</td>
                        <td>6</td>
                        <td>14</td>
                      </tr>
                      <tr>
                        <td colspan="2">정규직</td>
                        <td>명</td>
                        <td>6</td>
                        <td>6</td>
                        <td>13</td>
                      </tr>
                      <tr>
                        <td colspan="2">비정규직</td>
                        <td>명</td>
                        <td>-</td>
                        <td>-</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td rowspan="3">총계</td>
                        <td>여성</td>
                        <td>명</td>
                        <td>4</td>
                        <td>1</td>
                        <td>8</td>
                      </tr>
                      <tr>
                        <td>남성</td>
                        <td>명</td>
                        <td>2</td>
                        <td>5</td>
                        <td>6</td>
                      </tr>
                      <tr>
                        <td>여성<br class="mobile-only" />비율</td>
                        <td>%</td>
                        <td>67</td>
                        <td>17</td>
                        <td>57</td>
                      </tr>
                      <tr>
                        <td rowspan="6">퇴직</td>
                        <td rowspan="3">총 퇴직사 수</td>
                        <td>전체</td>
                        <td>명</td>
                        <td>53</td>
                        <td>7</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>정규직</td>
                        <td>명</td>
                        <td>53</td>
                        <td>7</td>
                        <td>4</td>
                      </tr>
                      <tr>
                        <td>비규직</td>
                        <td>명</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td colspan="2">총 퇴직율</td>
                        <td>%</td>
                        <td>34</td>
                        <td>5</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td colspan="2">월 평균 퇴직율</td>
                        <td>%</td>
                        <td>2</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td colspan="2">자발적 퇴직율</td>
                        <td>%</td>
                        <td>1</td>
                        <td>4</td>
                        <td>2</td>
                      </tr>
                      <tr>
                        <td colspan="3">육아휴직 복귀율</td>
                        <td>%</td>
                        <td>100</td>
                        <td>100</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="esg_box">
                <h4>구성원 교육 및 평가 현황</h4>
                <div class="table_type04">
                  <table>
                    <caption>
                      구성원 교육 및 평가 현황
                    </caption>
                    <colgroup>
                      <col style="width: 21%" />
                      <col style="width: 21%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colspan="2">&nbsp;</th>
                        <th>단위</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">
                          교육<br class="mobile-only" />투자비용
                        </td>
                        <td>투자비용</td>
                        <td>백만원</td>
                        <td>490</td>
                        <td>468</td>
                        <td>497</td>
                      </tr>
                      <tr>
                        <td>
                          인당<br class="mobile-only" />
                          교육 비용
                        </td>
                        <td>백만원</td>
                        <td>3</td>
                        <td>3</td>
                        <td>3</td>
                      </tr>
                      <tr>
                        <td rowspan="3">교육 시간</td>
                        <td>
                          온라인<br class="mobile-only" />
                          교육 시간
                        </td>
                        <td>시간</td>
                        <td>22,047</td>
                        <td>15,214</td>
                        <td>10,711</td>
                      </tr>
                      <tr>
                        <td>
                          오프라인<br class="mobile-only" />
                          교육 시간
                        </td>
                        <td>시간</td>
                        <td>-</td>
                        <td>-</td>
                        <td>934</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="esg_box">
                <h4>안전 보건 관리 현황</h4>
                <div class="table_type04">
                  <table>
                    <caption>
                      안전 보건 관리 현황
                    </caption>
                    <colgroup>
                      <col style="width: 42%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>단위</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>재해율</td>
                        <td>%</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="esg_box">
                <h4>기업 데이터 및 고객 정보 보호 현황</h4>
                <div class="table_type04">
                  <table>
                    <caption>
                      기업 데이터 및 고객 정보 보호 현황
                    </caption>
                    <colgroup>
                      <col style="width: 21%" />
                      <col style="width: 21%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colspan="2">&nbsp;</th>
                        <th>단위</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">
                          개인정보,<br class="mobile-only" />
                          사이버보안<br class="mobile-only" />
                          교육현황
                        </td>
                        <td>교육 시간</td>
                        <td>시간</td>
                        <td>2.6</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                      <tr>
                        <td>교육 참석율</td>
                        <td>%</td>
                        <td>100</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        <td rowspan="2">
                          정보보안<br class="mobile-only" />
                          침해
                        </td>
                        <td>
                          사고 발생<br class="mobile-only" />
                          건수
                        </td>
                        <td>원</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>
                          벌금 및<br class="mobile-only" />
                          과태료
                        </td>
                        <td>원</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="esg_box">
                <h4>ESG 활동</h4>
                <div class="table_type04">
                  <table>
                    <caption>
                      ESG 활동
                    </caption>
                    <colgroup>
                      <col style="width: 42%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>단위</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          사회공헌 비용<br class="mobile-only" />
                          및 자원봉사 시간/금액 산출
                        </td>
                        <td>백만원</td>
                        <td>10</td>
                        <td>19</td>
                        <td>9</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="esg_box">
                <h4>협력사 현황</h4>
                <div class="table_type04">
                  <table>
                    <caption>
                      협력사 현황
                    </caption>
                    <colgroup>
                      <col style="width: 21%" />
                      <col style="width: 21%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colspan="2">&nbsp;</th>
                        <th>단위</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="2">협력업체 수</td>
                        <td>총 협력업체 수</td>
                        <td>개</td>
                        <td>44</td>
                        <td>30</td>
                        <td>39</td>
                      </tr>
                      <tr>
                        <td>주요 협력업체</td>
                        <td>개</td>
                        <td>13</td>
                        <td>8</td>
                        <td>11</td>
                      </tr>

                      <tr>
                        <td rowspan="2">구매 금액</td>
                        <td>총 구매 금액</td>
                        <td>백만원</td>
                        <td>14,454</td>
                        <td>6,928</td>
                        <td>16,065</td>
                      </tr>
                      <tr>
                        <td>주요 협력업체 구매 금액</td>
                        <td>백만원</td>
                        <td>10,903</td>
                        <td>5,217</td>
                        <td>12,507</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="esg_box">
                <h4>윤리경영 현황 관리</h4>
                <div class="table_type04">
                  <table>
                    <caption>
                      윤리경영 현황 관리
                    </caption>
                    <colgroup>
                      <col style="width: 42%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>단위</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>윤리경영 교육 이수율</td>
                        <td>%</td>
                        <td>100</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        <td>부패방지 교육 이수율</td>
                        <td>%</td>
                        <td>100</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        <td>성희롱예방 교육 이수율</td>
                        <td>%</td>
                        <td>100</td>
                        <td>100</td>
                        <td>100</td>
                      </tr>
                      <tr>
                        <td>총 윤리 접수 건수</td>
                        <td>건</td>
                        <td>5</td>
                        <td>4</td>
                        <td>6</td>
                      </tr>
                      <tr>
                        <td>윤리 규정 위반 건수</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>윤리 규정 위반 조치 건수</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>근무해태</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>향응·접대수수</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>허위보고·예산낭비</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>회사비용 부당편취</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>품위 손상</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>금전대차</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>겹업·이해상충</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>성희롱 및 직장 예절</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>근무규정위반</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>관리책임</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>공정거래 규정 위반</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                      <tr>
                        <td>기타</td>
                        <td>건</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div v-if="tabIndex == 2">
              <div class="esg_box">
                <h4>온실가스 배출량 현황</h4>
                <div class="table_type04">
                  <table>
                    <caption>
                      온실가스 배출량 현황
                    </caption>
                    <colgroup>
                      <col style="width: 21%" />
                      <col style="width: 21%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th colspan="2">&nbsp;</th>
                        <th>단위</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td rowspan="3">
                          온실가스<br class="mobile-only" />
                          총 배출량
                        </td>
                        <td>총량</td>
                        <td>tonCO<sub>2</sub>e</td>
                        <td>2,428</td>
                        <td>2,465</td>
                        <td>2,335</td>
                      </tr>
                      <tr>
                        <td>
                          직접<br class="mobile-only" />
                          온실가스<br class="mobile-only" />
                          배출량
                        </td>
                        <td>tonCO<sub>2</sub>e</td>
                        <td>135</td>
                        <td>93</td>
                        <td>103</td>
                      </tr>
                      <tr>
                        <td>
                          간접<br class="mobile-only" />
                          온실가스<br class="mobile-only" />
                          배출량
                        </td>
                        <td>tonCO<sub>2</sub>e</td>
                        <td>2,293</td>
                        <td>2,372</td>
                        <td>2,232</td>
                      </tr>
                      <tr>
                        <td rowspan="3">
                          에너지<br class="mobile-only" />
                          소비량
                        </td>
                        <td>총량</td>
                        <td>GJ</td>
                        <td>50,305</td>
                        <td>51,122</td>
                        <td>48,281</td>
                      </tr>
                      <tr>
                        <td>
                          직접<br class="mobile-only" />
                          에너지<br class="mobile-only" />
                          소비량
                        </td>
                        <td>GJ</td>
                        <td>2,383</td>
                        <td>1,555</td>
                        <td>1,678</td>
                      </tr>
                      <tr>
                        <td>
                          간접<br class="mobile-only" />
                          에너지<br class="mobile-only" />
                          소비량
                        </td>
                        <td>GJ</td>

                        <td>47,922</td>
                        <td>49,567</td>
                        <td>46,603</td>
                      </tr>
                      <tr>
                        <td rowspan="3">
                          재생
                          <br class="mobile-only" />
                          에너지
                        </td>
                        <td>
                          재생가능한에너지 <br class="mobile-only" />총 사용량
                        </td>
                        <td>MWh</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="esg_box">
                <h4>용수 및 폐기물 현황</h4>
                <div class="table_type04">
                  <table>
                    <caption>
                      용수 및 폐기물 현황
                    </caption>
                    <colgroup>
                      <col style="width: 42%" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>단위</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>용수 사용량</td>
                        <td>m<sup>2</sup></td>
                        <td>19,410</td>
                        <td>19,285</td>
                        <td>20,537</td>
                      </tr>
                      <tr>
                        <td>폐기물 발생량</td>
                        <td>Ton</td>
                        <td>37</td>
                        <td>37</td>
                        <td>39</td>
                      </tr>
                      <tr>
                        <td>재활용량</td>
                        <td>Ton</td>
                        <td>12</td>
                        <td>12</td>
                        <td>13</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl.vue";

export default {
  name: "Vision",
  metaInfo: {
    title: "사회적가치(ESG) | SK텔링크 소개 | 회사소개",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {
      tabIndex: "1",
      isVisibleData: false,
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    tabActive(num) {
      this.tabIndex = num;
    },
    isVisibleFn() {
      this.isVisibleData = true;
      setTimeout(function () {
        let gPos = document.querySelector("#tabTarget").offsetTop,
          gH1 = document.querySelector("#header").offsetHeight,
          gH2 = document.querySelector(".location").offsetHeight,
          result = gPos - gH1 - gH2;

        if (document.documentElement.scrollTop || document.body.scrollTop) {
          document.documentElement.scrollTop = result;
        } else {
          document.querySelector("#wrap").scrollTop = result;
        }
      }, 300);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab_type01 {
  border-top: 1px solid #dadada;
}
.esg {
  .esg_box {
    width: 1120px;
    margin: 0 auto;
    padding: 60px 0 20px;
    text-align: center;
    p {
      margin: 20px 0 0;
    }
    .table_type04 {
      margin-top: 20px;
      th,
      td {
        vertical-align: middle;
      }
    }
    sub {
      font-size: 0.5em;
      vertical-align: sub;
    }
  }
  .cp_area.pb0 {
    padding-bottom: 0;
  }
  .ui-btn-wrap {
    margin-top: 80px;
    .ui-btn {
      display: inline-block;
      padding: 0 44px;
      color: #fff;
      font-size: 24px;
      line-height: 61px;
      background-color: #e51937;
    }
  }
}

@media (max-width: 1023px) {
  .esg {
    .cp_box {
      padding: 0 20px;
    }
    .esg_box {
      width: 100%;
      margin: 0;
      padding: 30px 15px 10px;
      th,
      td {
        padding: 5px;
        font-size: 13px;
        line-height: 1.3;
      }
      sup {
        line-height: inherit;
      }
    }
    .scroll_area {
      overflow: scroll;
      .inner {
        width: 1120px;
      }
    }
    .ui-btn-wrap {
      margin-top: 40px;
      .ui-btn {
        padding: 0 22px;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}
</style>
