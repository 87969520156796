<template>
  <div id="INTRODUCE_INTRO">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg01">
          <h3 class="title">CEO인사말</h3>
          <p class="txt">
            고객 여러분의 깊은 관심과 사랑에 진심으로<br />감사의 말씀을
            드립니다
          </p>
          <img src="/content/images/sub_header_cp01.jpg" alt="비쥬얼 이미지" />
        </div>
        <!-- company -->
        <div class="company">
          <div class="ceo_area2">
            <h3>
              "SK텔링크의 Next를 책임질 새로운 비전과 문화를 통해<br />
              새롭게 도약하는 성장 Story를 만들어 나가겠습니다."
            </h3>
            <span class="w_hide">
              <img
                class="img_box"
                src="/content/images/img_cp_ceo4.jpg"
                alt="SK텔링크 대표이사 하성호"
              />
              <p>
                안녕하십니까?<br />
                <em>SK텔링크 홈페이지</em>를 방문해 주셔서 감사합니다.<br />
                <br />
                여러분들의 한결 같은 관심과 사랑 덕분에 SK텔링크는 글로벌
                팬데믹과 급격한 시장 변화속에서도 기존 사업 영역에서 견조한
                성과를 일궈왔습니다.<br /><br />
                국제전화 ‘00700’은 '국가고객만족도(NCSI)',
                '한국서비스품질지수(KS-SQI)' 및 '한국산업의 고객만족도(KCSI)' 등
                국내 3대 고객 만족도 조사를 8년 연속으로 석권하며 최고의
                국제전화 서비스로 인정받았고, ‘SK 세븐모바일’은 ‘2022년 소비자가
                가장 추천하는 브랜드’, '2022 프리미엄브랜드지수' 및 ‘2022
                국가브랜드경쟁력지수’ 등 주요 브랜드 지수를 석권하며 최고의
                알뜰폰 브랜드로 자리매김했습니다.<br /><br />
                저희는 이 같은 성과에 안주하지 않고 SK텔링크의 Next를 책임질
                다양한 성장사업을 발굴하고 실행 전략을 수립하는 한편, SK ICT
                패밀리社의 일원으로서 전략적 역할과 Value 증대를 실현해 새롭게
                도약하는 SK텔링크의 성장 Story를 만들어 나가겠습니다.<br /><br />
                더불어 관련 법규 등에서 규정하고 있는 책임과 의무를 성실히
                이행하고 자율준수 프로그램을 강화해 이용자 보호에 앞장서는 한편,
                ESG에 기반한 ‘행복 경영’을 적극 추진해 구성원은 행복하고, 고객은
                신뢰를, 우리가 속한 사회와는 선한 영향력을 주고 받으며 모두가
                자랑스러워 하는 기업으로 성장해 나가고자 합니다.<br /><br />
                SK텔링크의 도전에 변함없는 따뜻한 격려를 부탁드립니다.<br />
                감사합니다.<br /><br />
                SK텔링크 대표이사 하성호
              </p>
            </span>
            <span class="m_hide">
              <img
                class="img_box"
                src="/content/images/img_cp_ceo4.jpg"
                alt="SK텔링크 대표이사 하성호"
              />
              <p>
                안녕하십니까?<br />
                <em>SK텔링크 홈페이지</em>를 방문해 주신 고객 여러분
                반갑습니다.<br /><br />
                고객 여러분들의 한결 같은 사랑 덕분에 SK텔링크는
                '국가고객만족도(NCSI)', '한국서비스품질지수(KS-SQI)' 및
                '한국산업의 고객만족도(KCSI)' 등 국내 3대 고객 만족도 조사를 7년
                연속으로 석권하며 유·무선 통신과 다양한 솔루션을 망라하는 ICT
                서비스기업으로 인정받을 수 있었습니다.<br /><br />
                저희는 여기에 안주하지 않고 빠르게 변화하는 경영환경에 발맞춰
                객관적 인식과 유연한 사고에 기반한 도전 정신으로 비즈니스
                경쟁력을 강화하고 고객만족을 위한 서비스 혁신에 앞장서고자
                합니다.<br /><br />
                또한 자랑스러운 SK 그룹의 일원으로서 재무적 성과와 사회적 가치를
                동시에 추구하는 DBL(Double Bottom Line) 경영을 바탕으로
                SK텔레콤을 위시한 SK ICT 패밀리와 함께 새로운 성장 스토리를
                만들어 나가고자 합니다.<br /><br />
                더불어 사업자로서 관련 법규 등에서 규정하고 있는 책임과 의무를
                성실히 이행하고 자율준수 프로그램을 강화해 이용자 보호에
                앞장서는 한편, 고객이 생각하지 못한 새로운 효용과 가치를
                제공함으로써 궁극적으로 구성원, 고객, 비즈니스 파트너 모두가
                자랑스러워하는 기업으로 성장하겠습니다.<br /><br />
                미래 성장의 해답을 찾기 위한 SK텔링크의 무한질주에 아낌없는
                응원과 격려 부탁 드립니다.<br /><br />
                감사합니다.<br /><br />
                SK텔링크 대표이사 하성호<br />
              </p>
            </span>
          </div>
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl.vue";

export default {
  name: "Intro",
  metaInfo: {
    title: "CEO 인사말 | SK텔링크 소개 | 회사소개",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
