import axios from 'axios'
import store from '@/store'
import util from '@/utilities'
import router from '@/router'

const config = {
  baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_ROOT_API : '/dev/',
  LocalBaseURL: '/data/'
}

const instance = axios.create({
  baseURL: config.baseURL,
});

instance.interceptors.request.use(
  function (config) {
    store.commit('START_LOADER')
    return config;
  },
  function (error) {
    store.commit('FINISH_LOADER')
    // 요청 에러 처리를 작성합니다.
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    store.commit('FINISH_LOADER')
    if (response.data.result_cd) {
      let code, message, type
      util.messageList.some(item => {
        if (response.data.result_cd === item.code) {
          if (router.history.current.fullPath === '/partnership/global_b?lang=eng' || router.history.current.fullPath === '/partnership/00780_global') {
            message = item.messageEng
          } else {
            message = item.message
          }
          code = item.code
          type = item.type
          return response.data.result_cd === item.code
        }
      })
      if (code && message && type) {
        store.commit('ON_ALERT_POPUP', { message: message, code: code, type: type })
      }
    }
    return response;
  },

  function (error) {
    store.commit('SET_ERROR', true)
    store.commit('FINISH_LOADER')
    return Promise.reject(error);
  }
);

export default {

  // LOCAL
  Menu() {
    return axios.get(`${config.LocalBaseURL}menu.json`)
  },
  EngMenu() {
    return axios.get(`${config.LocalBaseURL}engmenu.json`)
  },
  MainProduct() {
    return axios.get(`${config.LocalBaseURL}main/product.json`)
  },

  // UNICON
  CommonPostReqSmsAuth(data) {
    return instance({ url: 'common/req-sms-auth', method: 'post', data: data })
  },
  CommonGetCheckSmsAuth(data) {
    return instance({ url: 'common/check-sms-auth', method: 'post', data: data })
  },
  CommonGetReqKcbAuthTicket(data) {
    return instance({ url: 'common/req-kcb-auth-ticket', method: 'post', data: data })
  },
  CommonGetReqKcbAuth(data) {
    return instance({ url: 'common/req-kcb-auth', method: 'post', data: data })
  },
  ItsvcGet00700PricePlanInfo(data) {
    return instance({ url: 'itsvc/00700-price-plan-info', method: 'post', data: data })
  },
  ItsvcGetPre00700PricePlanInfo(data) {
    return instance({ url: 'itsvc/pre-00700-price-plan-info', method: 'post', data: data })
  },
  ItsvcGetStandardPricePlanInfo(data) {
    return instance({ url: 'itsvc/standard-price-plan-info', method: 'post', data: data })
  },
  ItsvcGetPreStandardPricePlanInfo(data) {
    return instance({ url: 'itsvc/pre-standard-price-plan-info', method: 'post', data: data })
  },
  ItsvcPostSubsPricePlan(data) {
    return instance({ url: 'itsvc/subs-price-plan', method: 'post', data: data })
  },
  ItsvcPostApplyFreeDedt(data) {
    return instance({ url: 'itsvc/apply-free-dedt', method: 'post', data: data })
  },
  ItsvcGetPricePlanInfo(data) {
    return instance({ url: 'itsvc/price-plan-info', method: 'post', data: data })
  },
  ItsvcGetMoreLongPricePlanList(data) {
    return instance({ url: 'itsvc/more-long-price-plan-list', method: 'post', data: data })
  },
  ItsvcGetMoreLongPricePlanCntlList(data) {
    return instance({ url: 'itsvc/more-long-price-plan-cntl-list', method: 'post', data: data })
  },
  ItsvcGetPrePricePlanInfo(data) {
    return instance({ url: 'itsvc/pre-price-plan-info', method: 'post', data: data })
  },
  ItsvcGetFreeDedtPricePlanInfo(data) {
    return instance({ url: 'itsvc/free-dedt-price-plan-info', method: 'post', data: data })
  },
  ItsvcGetPreFreeDedtPricePlanInfo(data) {
    return instance({ url: 'itsvc/pre-free-dedt-price-plan-info', method: 'post', data: data })
  },
  ItsvcGetReqAppLink(data) {
    return instance({ url: 'itsvc/req-app-link', method: 'post', data: data })
  },
  ItsvcGetUsedInfo(data) {
    return instance({ url: 'itsvc/used-info', method: 'post', data: data })
  },
  ItsvcGetUsedCallNum(data) {
    return instance({ url: 'itsvc/used-call-num', method: 'post', data: data })
  },
  ItsvcGetSvcUseInfo(data) {
    return instance({ url: 'itsvc/svc-use-info', method: 'post', data: data })
  },
  ItsvcGetAvailPricePlanList(data) {
    return instance({ url: 'itsvc/avail-price-plan-list', method: 'post', data: data })
  },
  ItsvcPostSaveQuestion(data) {
    return instance({ url: 'itsvc/save-question', method: 'post', data: data })
  },
  ItsvcGetQuestion(data) {
    return instance({ url: 'itsvc/question', method: 'post', data: data })
  },
  BulletinGetBullutinList(data) {
    return instance({ url: 'bulletin/bulletin-list', method: 'post', data: data })
  },
  BulletinGetBullutinDtl(data) {
    return instance({ url: 'bulletin/bulletin-dtl', method: 'post', data: data })
  },
  BulletinGetFreqQuestionList(data) {
    return instance({ url: 'bulletin/freq-question-list', method: 'post', data: data })
  },
  BulletinGetPressList(data) {
    return instance({ url: 'bulletin/press-list', method: 'post', data: data })
  },
  BulletinGetPressDtl(data) {
    return instance({ url: 'bulletin/press-dtl', method: 'post', data: data })
  },
  BulletinGetIrList(data) {
    return instance({ url: 'bulletin/ir-list', method: 'post', data: data })
  },
  BulletinGetCommonCode(data) {
    return instance({ url: 'bulletin/common-code', method: 'post', data: data })
  },
  EventPostReqPartnerEvent(data) {
    return instance({ url: 'event/req-partner-event', method: 'post', data: data })
  },
  EventPostTmemAuth(data) {
    return instance({ url: 'event/tmem-auth', method: 'post', data: data })
  },
  EventPostTmemSubs(data) {
    return instance({ url: 'event/tmem-subs', method: 'post', data: data })
  },
  EventPostTmemTerm(data) {
    return instance({ url: 'event/tmem-term', method: 'post', data: data })
  },
  EventPostReqEvent(data) {
    return instance({ url: 'event/req-event', method: 'post', data: data })
  },
  EventPostReqLotteCardEvent(data) {
    return instance({ url: 'event/req-lotte-card-event', method: 'post', data: data })
  },
  EventPostSktIbEvent(data) {
    return instance({ url: 'event/skt-ib-event', method: 'post', data: data })
  },
  // 20210713 SKT IB 요금제 체크 추가
  EventPostSktIbCheck(data) {
    return instance({ url: 'event/skt-ib-event-check', method: 'post', data: data })
  },
  EventPostFreeDedtEvent(data) {
    return instance({ url: 'event/free-dedt-event', method: 'post', data: data })
  },
  EventPostSavePartnerEvent(data){
    return instance({ url: 'event/save-partner-event-info', method: 'post', data: data })
  },
  EventPostMpSubs(data){
    return instance({ url: 'event/mp-subs', method: 'post', data: data })
  },
  EventPostMpCancel(data){
    return instance({ url: 'event/mp-cancel', method: 'post', data: data })
  },
  // 20210625 KS-SQI 프로모션
  EventPostReqEntry(data){
    return instance({ url: 'event/req-entry', method: 'post', data: data })
  },
  ReqApInfo(data){
    return instance({ url: 'event/req-ap-info', method: 'post', data: data })
  },
  // 20210903 Welcome Gift 프로모션
  EventPostWelGift(data){
    return axios({ url: 'https://appapi.sktelink.com/app/promotion/target-user.do', method: 'post', data: data })
  },
  EventPostCheckTmemNewSubs(data){
    return instance({ url: 'event/check-tmem-subs', method: 'post', data: data })
  },
  EventPostTmemNewSubs(data){
    return instance({ url: 'event/new-tmem-subs', method: 'post', data: data })
  },
  EventPostTmemNewTerm(data){
    return instance({ url: 'event/new-tmem-term', method: 'post', data: data })
  },
  ItsvcPostApplyBaroclubFreeDedt(data) {
    return instance({ url: 'itsvc/apply-baroclub-free-dedt', method: 'post', data: data })
  },
  // 20210525 00701 사용내역조회
  ItsvcPostUsed701Info(data) {
    return instance({ url: 'itsvc/used-701-info', method: 'post', data: data })
  },
}
