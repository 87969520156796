<template>
  <div id="loader">
    <img
      src="~@/assets/images/loader.gif"
      alt=""
    >
  </div>
</template>

<script>
export default {
  name: 'HttpLoader'
}
</script>

<style lang="scss" scoped>
#loader {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: rgb(255, 255, 255, 0.9);
  img{
    width: 100px;
    height: auto;
    position: fixed;
    top: 400px;
    left: 50%;
    top: 50%;
    margin: -50px 0 0 -50px;
  }
}
</style>>
