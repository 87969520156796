var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"eng",attrs:{"id":"CIBI"}},[_c('Header'),_c('div',{attrs:{"id":"container"}},[_c('BreadCrumb'),_c('div',{staticClass:"contents en",attrs:{"id":"contents"}},[_vm._m(0),_c('div',{staticClass:"tab_type01"},[_c('ul',[_c('li',{class:_vm.tabIndex == 1 ? 'on': '',attrs:{"id":"taba","title":"현재메뉴 선택됨"}},[_c('div',{on:{"click":function($event){return _vm.tabActive(1)}}},[_vm._v(" CI ")])]),_c('li',{class:_vm.tabIndex == 2 ? 'on': '',attrs:{"id":"tabb"}},[_c('div',{on:{"click":function($event){return _vm.tabActive(2)}}},[_vm._v(" BI ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 1),expression:"tabIndex == 1"}],staticClass:"company",attrs:{"id":"tab1"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 2),expression:"tabIndex == 2"}],staticClass:"company",attrs:{"id":"tab2"}},[_vm._m(5),_vm._m(6)])])],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_header bg01"},[_c('h3',{staticClass:"title"},[_vm._v(" CI/BI ")]),_c('p',{staticClass:"txt"},[_vm._v(" SK telink’s corporate identity ")]),_c('img',{attrs:{"src":"/content/images/sub_header_cp01.jpg","alt":"Visual image"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ci_list"},[_c('h4',[_vm._v("Logo mark")]),_c('ul',[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo01.png","alt":"SK telink"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci_Sktelink.ai","download":""}},[_vm._v("Download the AI file")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci_Sktelink.jpg","download":""}},[_vm._v("Download the JPG file")])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo02.png","alt":"SK telink"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci-eng_SKtelink.ai","download":""}},[_vm._v("Download the AI file")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci-eng_SKtelink.jpg","download":""}},[_vm._v("Download the JPG file")])])]),_c('div',{staticClass:"txt_box"},[_c('p',[_vm._v(" The logo mark is the official symbol of SK telink. It should not be changed in any case, and it is used in various media,"),_c('br'),_vm._v(" such as printed matters and promotional materials, and plays an important role in delivering the corporate and brand image of SK telink. ")]),_c('p',{staticClass:"txt_point01"},[_c('span',{staticClass:"icon_star"},[_vm._v("*")]),_vm._v("To prevent the deformation of the logo mark, please download the file on the web. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ci_list bg_rule"},[_c('div',[_c('h4',[_vm._v("CI Space Regulation")]),_c('ul',[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo03.png","alt":"Assuming that the height of the logo mark ‘ K ’ is X, the height of the ‘Happy Wings’ is 1.1X. The size of the Korean company name is 0.8X, and the size of the English company name is 0.55X."}})])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo04.png","alt":"The space between the SK logo and the company name is 0.8X in Korean, and 0.55X in English. The logo mark is SK Red, and the company name is SK Orange, and if it is black and white, the logo mark is Black 60%."}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"color_list"},[_c('h4',[_vm._v("Main colors")]),_c('div',{staticClass:"color_list_inner"},[_c('div',{staticClass:"red"},[_c('h5',[_vm._v("SK Red")]),_c('ul',[_c('li',[_vm._v("Pantone : 186C")]),_c('li',[_vm._v("CMYK : 0.100.8.4")]),_c('li',[_vm._v("RGB : 234.0.44")])])]),_c('div',{staticClass:"orange"},[_c('h5',[_vm._v("SK Orange")]),_c('ul',[_c('li',[_vm._v("Pantone : 158C")]),_c('li',[_vm._v("CMYK : 0.66.97.0")]),_c('li',[_vm._v("RGB : 255.122.0")])])])]),_c('div',{staticClass:"txt_box"},[_c('p',[_c('span',{staticClass:"txt_point02"},[_vm._v("The SK Red of the logo mark and the symbol mark is dynamic and vibrant red,")]),_c('br'),_vm._v(" and "),_c('span',{staticClass:"txt_point01"},[_vm._v("SK orange is a color that symbolizes ‘Happiness,’ ‘Friendliness’ and ‘Welcome.’")]),_c('br',{staticClass:"w_hide"}),_vm._v(" In principle, the specified color must be used for the logo mark against a white background."),_c('br'),_vm._v(" In particular, as the continuous use of the same colors plays an important role in building the image of SK telink,"),_c('br'),_vm._v(" you must comply with the color utilization standards. In principle, if you want to use dedicated colors, spot colors must be used. ")]),_c('p',{staticClass:"txt_point01"},[_c('span',{staticClass:"icon_star"},[_vm._v("*")]),_vm._v("If you use colors not described here, you must consider the chroma and brightness of the ground color,"),_c('br'),_vm._v("and use them after consultation with the administrative department. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ci_list color_rule"},[_c('h4',[_vm._v("Color Regulation")]),_c('ul',[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo05.png","alt":"SK telink logo(black)"}})]),_c('dl',[_c('dt',[_vm._v("Black color")]),_c('dd',[_vm._v("When you use the logo for black-and-white printing,"),_c('br'),_vm._v("the Happy Wings must be Black 100% and 60%"),_c('br'),_vm._v("so that the difference in brightness can be distinguished.")])])]),_c('li',{staticClass:"bg_print"},[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo06.png","alt":"SK telink logo(Negative Print)"}})]),_c('dl',[_c('dt',[_vm._v("Negative Print")]),_c('dd',[_vm._v("For negative print,"),_c('br'),_vm._v("if the brightness of the background color is 30%, use white.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bi_area"},[_c('h4',[_vm._v("international call")]),_c('ul',{staticClass:"sk_inter"},[_c('li',{staticClass:"child1"},[_c('div',[_c('img',{attrs:{"src":"/content/images/img_bi01.png","alt":"SK International Call 00700"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci_00700.ai","download":""}},[_vm._v("Download the AI file")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci_00700.jpg","download":""}},[_vm._v("Download the JPG file")])]),_c('li',{staticClass:"child2"},[_c('div',[_c('img',{attrs:{"src":"/content/images/img_bi02.png","alt":"SK International Call 00700"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci-eng_00700.ai","download":""}},[_vm._v("Download the AI file")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci-eng_00700.jpg","download":""}},[_vm._v("Download the JPG file")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bi_area"},[_c('h4',[_vm._v("MVNO SK 7mobile")]),_c('ul',{staticClass:"sk_7m"},[_c('li',{staticClass:"child1 single"},[_c('div',[_c('img',{attrs:{"src":"/content/images/img_bi7m02.png","alt":"General horizontal SK MVNO 7mobile"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/7mobile height.ai","download":""}},[_vm._v("AI file")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/7mobile height.jpg","download":""}},[_vm._v("JPG file")])])])])
}]

export { render, staticRenderFns }