import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import meta from './components/Meta'
import VueMoment from 'vue-moment'
import VueGtag from "vue-gtag"

// import './content/css/event.scss'

Vue.use(VueGtag, {
  config: { id: "UA-48670326-1" }
});

Vue.filter('comma', val =>{
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})
Vue.use(VueMoment)

Vue.component('Meta', meta);
Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
