<template>
  <div id="CALLING_PLAN_FLAT_RATE">
    <Header />
    <!-- 컨텐츠 -->
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />
      <div id="contents">
        <div class="sub_header bg02-02">
          <h3 class="title">
            특정국가할인형
          </h3>
          <p class="txt">
            국제전화 이용 시 국가별 할인 프로그램이 필요한 고객님을 위해<br>SK국제전화 00700이 준비한 SPECIAL 요금제!
          </p>
          <img
            src="/content/images/sub_header_inter02-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <div class="tab_type01 tab3">
          <ul>
            <li>
              <router-link to="/view/business/calling_plan_domestic">
                국내통화 요금제
              </router-link>
            </li>
            <li>
              <router-link to="/view/business/calling_plan_specified">
                지정국가 요금제
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/business/calling_plan_flat_rate">
                더 길게 요금제
              </router-link>
            </li>
          </ul>
        </div>

        <!-- inter -->
        <div class="inter mb0">
          <div class="sub_title sub-title-mobile">
            <h4 class="title">
              최대 21개국을 월 정액요금으로<br class="m_br"> 이용할 수 있는<br>
              <span class="txt_point01">00700 더 길게 요금제</span>
            </h4>
            <p class="txt">
              <span class="txt_point01">여러 국가를 이용하면서, 월 정액으로 편리하게 쓸 수 있는<br class="m_br"> 00700 더 길게 요금제.</span><br>
              4가지 상품으로 각 요금제별 제공되는<br class="m_br"> 무료통화 분수로 편리하고 알뜰하게 사용하세요!
            </p>
          </div>

          <div class="advice_list">
            <h4>이런 고객님이 사용하면 좋아요!</h4>
            <p><span class="hashtag"># 여러국가</span><span class="hashtag"># 월정액제</span><span class="hashtag"># 안심국제전화</span></p>
            <div class="advice_scroll">
              <div>
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter18.png"
                        alt=""
                      >
                    </div>
                    <p>여러 국가로 자주 국제전화를 하는 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter19.png"
                        alt=""
                      >
                    </div>
                    <p>월 정액제로 요금 관리를 원하는<br> 스마트한 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter20.png"
                        alt=""
                      >
                    </div>
                    <p>안심하고 국제전화를 쓰고 싶은 고객</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="advice_list white">
            <h4>거부할 수 없는 매력은<br class="m_hide"> 무엇인가요?</h4>
            <div class="advice_scroll">
              <div>
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub24.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>최대 21개국을 한 요금제로</dt>
                      <dd>주요 국가를 한 요금제로 일괄적으로<br> 관리하고 싶을 때 꼭 필요한 요금제!<br> 10개국부터 최대 21개국까지 한 요금제로<br> 관리할 수 있습니다.</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub25.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>일본/베트남까지<br> 정액상품으로 구성</dt>
                      <dd>일본, 베트남을 이용하는 고객님이라면<br> 항상 아쉽게 생각했던 국제전화 요금제들.<br> 월 정액상품인 더 길게 요금제로<br> 혜택을 누려보세요!(더 길게 60 상품 제외)</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub26.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>월 정액으로<br> 일정한 요금 부과</dt>
                      <dd>할인율이 다른 국가별 요금이 부담스러운<br> 고객님들을 위해 준비한 월 정액상품 요금제!<br> 무려 4가지 종류의 다양한 월 정액금액제로<br> 선택의 폭을 넓혔습니다.</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="inter_seach">
            <h4><strong>더 길게 요금제의</strong> 가입 가능 국가를 확인해보세요</h4>
            <div class="type01">
              <Autocomplete
                v-model="formData.natl_cd"
                :source="country"
                :results-display="'natl_nm'"
                :results-value="'natl_cd'"
                :clear-button-icon="''"
                :placeholder="'국가명을 입력해주세요'"
                @selected="selectCountry"
                @keyupEnter="inquire"
              />
              <button
                type="button"
                class="btn_type01 btn_search"
                @click="inquire"
              >
                검색
              </button>
            </div>
          </div>

          <!-- 검색결과박스 -->
          <div
            v-if="resultAreaOpen"
            class="result_area"
          >
            <div class="table_type04 long_charge">
              <table>
                <caption>조회 국가, 표준요금, 가입 가능 요금제, 이용가능 분수, 월 정액금액 나타내는 표입니다.</caption>
                <colgroup />
                <thead v-if="result.length > 0">
                  <tr>
                    <th scope="col">
                      조회<br class="m_br"> 국가
                    </th>
                    <th scope="col">
                      표준<br class="m_br">요금
                    </th>
                    <th scope="col">
                      가입<br class="m_br"> 가능<br class="m_br"> 요금제
                    </th>
                    <th scope="col">
                      이용<br class="m_br">가능<br class="m_br"> 분수
                    </th>
                    <th scope="col">
                      월<br class="m_br"> 정액<br class="m_br">금액
                    </th>
                  </tr>
                </thead>
                <tbody v-if="result.length > 0">
                  <tr
                    v-for="(item, index) in result"
                    :key="index"
                  >
                    <td>{{ resultCountry }}</td>
                    <td>{{ item.basic_price }}원/분</td>
                    <td>{{ item.chrg_cd_nm }}</td>
                    <td>{{ item.time }}분</td>
                    <td>{{ item.price | comma }}원</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td>내용이 없습니다.</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <ul class="txt_em">
              <li><span class="icon_star">*</span>과금 기준 : 더 길게 요금제(정액요금), 표준요금(원/초)</li>
              <li><span class="icon_star">*</span>해당 요금 적용은 요금제 가입 일시부터 적용됩니다.</li>
              <li><span class="icon_star">*</span>더 길게 요금제는 월 정액 상품으로 무료 이용가능 분수가 제공되며, 국가 합산으로 차감됩니다.</li>
              <li><span class="icon_star">*</span>요금제별 제공되는 무료 이용가능 분수를 초과한 경우 00700 표준요금(초당)이 적용됩니다.</li>
              <li><span class="icon_star">*</span>표기된 요금은 VAT가 포함된 요금이며, 상기 표준요금은 비교를 위해 분으로 환산된 금액으로 표기되었습니다.</li>
            </ul>
          </div>
          <div class="btn_center single line_top">
            <a
              class="btn_type01"
              @click="certKcbToken"
            >
              더 길게 요금제 신청하기
            </a>
          </div>
          <!-- //검색결과박스 -->

          <div class="use_table_area">
            <h4>더 길게 요금제에 대해<br class="m_br"> 자세히 알아보세요</h4>
            <!-- 웹에서 노출 -->
            <div class="table_type04 w_hide">
              <table>
                <caption>요금제 종류, 기본료, 이용가능 분수, 대상 국가 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:20%;">
                  <col style="width:20%;">
                  <col style="width:20%;">
                  <col style="width:40%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      요금제 종류
                    </th>
                    <th scope="col">
                      기본료
                    </th>
                    <th scope="col">
                      이용가능 분수
                    </th>
                    <th scope="col">
                      대상 국가
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>더 길게 60</td>
                    <td>5,390원</td>
                    <td>60분</td>
                    <td class="txt_left">
                      미국, 캐나다, 중국, 일본(유선), 홍콩, 태국, 싱가포르, 말레이시아, 인도, 몽골
                    </td>
                  </tr>
                  <tr>
                    <td>더 길게 80</td>
                    <td>10,890원</td>
                    <td>80분</td>
                    <td class="txt_left">
                      미국, 캐나다, 중국, 일본, 홍콩, 태국, 싱가포르, 말레이시아, 인도, 몽골, 호주, 베트남, 필리핀, 인도네시아, 영국
                    </td>
                  </tr>
                  <tr>
                    <td>더 길게 220</td>
                    <td>31,900원</td>
                    <td>220분</td>
                    <td class="txt_left">
                      미국, 캐나다, 중국, 일본, 홍콩, 태국, 싱가포르, 말레이시아, 인도, 몽골, 호주, 베트남, 필리핀, 인도네시아, 영국, 프랑스, 이탈리아, 뉴질랜드, 러시아, 독일, 대만
                    </td>
                  </tr>
                  <tr>
                    <td>더 길게 380</td>
                    <td>53,900원</td>
                    <td>380분</td>
                    <td class="txt_left">
                      미국, 캐나다, 중국, 일본, 홍콩, 태국, 싱가포르, 말레이시아, 인도, 몽골, 호주, 베트남, 필리핀, 인도네시아, 영국, 프랑스, 이탈리아, 뉴질랜드, 러시아, 독일, 대만
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- //웹에서 노출 -->

            <!-- 모바일에서 노출 -->
            <div class="table_slide">
              <ul>
                <li
                  class="accordionItem"
                >
                  <div class="tit_box">
                    <h5 class="title">
                      더 길게 60
                    </h5>
                    <dl>
                      <dt>기본료</dt>
                      <dd><span class="txt_point01">5,390원</span></dd>
                    </dl>
                    <span class="btn"><img
                      src="/content/images/btn_table_slide.png"
                      alt="펼쳐짐"
                    ></span>
                  </div>
                  <div class="txt_open">
                    <ul class="table_list">
                      <li>
                        <dl>
                          <dt>&middot; 이용가능<br> 분수</dt>
                          <dd>60분</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 대상국가</dt>
                          <dd>미국, 캐나다, 중국, 일본(유선), 홍콩, 태국, 싱가포르, 말레이시아, 인도, 몽고</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="accordionItem"
                >
                  <div class="tit_box">
                    <h5 class="title">
                      더 길게 80
                    </h5>
                    <dl>
                      <dt>기본료</dt>
                      <dd><span class="txt_point01">10,890원</span></dd>
                    </dl>
                    <span class="btn"><img
                      src="/content/images/btn_table_slide.png"
                      alt="펼치기"
                    ></span>
                  </div>
                  <div class="txt_open">
                    <ul class="table_list">
                      <li>
                        <dl>
                          <dt>&middot; 이용가능<br> 분수</dt>
                          <dd>80분</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 대상국가</dt>
                          <dd>미국, 캐나다, 중국, 일본, 홍콩, 태국, 싱가포르, 말레이시아, 인도, 몽고, 호주, 베트남, 필리핀, 인도네시아, 영국</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="accordionItem"
                >
                  <div class="tit_box">
                    <h5 class="title">
                      더 길게 220
                    </h5>
                    <dl>
                      <dt>기본료</dt>
                      <dd><span class="txt_point01">31,900원</span></dd>
                    </dl>
                    <span class="btn"><img
                      src="/content/images/btn_table_slide.png"
                      alt="펼치기"
                    ></span>
                  </div>
                  <div class="txt_open">
                    <ul class="table_list">
                      <li>
                        <dl>
                          <dt>&middot; 이용가능<br> 분수</dt>
                          <dd>220분</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 대상국가</dt>
                          <dd>미국, 캐나다, 중국, 일본, 홍콩, 태국, 싱가포르, 말레이시아, 인도, 몽고, 호주, 베트남, 필리핀, 인도네시아, 영국, 프랑스, 이탈리아, 뉴질랜드, 러시아, 독일, 대만</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </li>
                <li
                  class="accordionItem"
                >
                  <div class="tit_box">
                    <h5 class="title">
                      더 길게 380
                    </h5>
                    <dl>
                      <dt>기본료</dt>
                      <dd><span class="txt_point01">53,900원</span></dd>
                    </dl>
                    <span class="btn"><img
                      src="/content/images/btn_table_slide.png"
                      alt="펼치기"
                    ></span>
                  </div>
                  <div class="txt_open">
                    <ul class="table_list">
                      <li>
                        <dl>
                          <dt>&middot; 이용가능<br> 분수</dt>
                          <dd>380분</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt>&middot; 대상국가</dt>
                          <dd>미국, 캐나다, 중국, 일본, 홍콩, 태국, 싱가포르, 말레이시아, 인도, 몽고, 호주, 베트남, 필리핀, 인도네시아, 영국, 프랑스, 이탈리아, 뉴질랜드, 러시아, 독일, 대만</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <!-- //모바일에서 노출 -->
          </div>

          <div class="use_list_area">
            <div class="use_list">
              <h4>쉽게 따라 하는 이용방법</h4>
              <p>한국에서 호주 시드니 지역의 <span>휴대폰으로 전화를 걸 경우</span></p>
              <ul>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>00700 국제전화 식별번호</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter16.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호(호주 : 61)</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter37.png"
                      alt=""
                    >
                  </div>
                  <p>상대방 휴대폰 번호(411-234-567)<br><span><span class="icon_star">*</span>휴대폰 번호는 0번 제외</span></p>
                </li>
              </ul>
            </div>

            <div class="use_list">
              <p>한국에서 호주 시드니 지역의 <span>일반전화로 전화를 걸 경우</span></p>
              <ul class="four">
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>00700 국제전화 식별번호</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter16.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호(호주 : 61)</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter25.png"
                      alt=""
                    >
                  </div>
                  <p>지역번호(시드니 : 2)<br><span><span class="icon_star">*</span>지역번호 번호는<br class="m_hide"> 0번 제외</span></p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter40.png"
                      alt=""
                    >
                  </div>
                  <p>상대방  번호(123-4567)</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="notice_area gray">
            <h4 class="title">
              <span class="icon_mark"><img
                src="/content/images/icon_mark01.png"
                alt="주의사항"
              ></span>요금제 가입 시 꼭 읽어보세요
            </h4>
            <ul>
              <li><span class="icon_star">*</span>국제전화 00700 더 길게 요금제는 각 요금제 종류별로 제공되는 이용가능 분수가 정해져 있으며, 대상국가의 월 합산 분수로 차감됩니다.</li>
              <li><span class="icon_star">*</span>해당 월 정액금액 적용기간은 무선(전월 21일~당월 20일), 유선(당월 1일~말일)이며 해당 기간에 사용된 것만 적용됩니다.</li>
              <li><span class="icon_star">*</span>해당 요금제의 월 정액금액은 월 단위로 과금되며, 취소 시에도 일할 계산되지 않습니다.</li>
              <li><span class="icon_star">*</span>해당 요금제에 제공되는 이용가능 분수를 초과하여 사용하는 경우, 00700 표준요금이 적용됩니다.</li>
              <li><span class="icon_star">*</span>해당 요금제에서 적용되는 대상 국가 이외의 국가로 국제전화를 하는 경우 00700 표준요금으로 적용됩니다.</li>
              <li><span class="icon_star">*</span>번호 변경 시, 요금제 가입을 다시 신청해야만 기존 혜택이 유지됩니다. </li>
              <li><span class="icon_star">*</span>국제전화 요금제 변경 또는 재가입 신청은 홈페이지 내 마이페이지 또는 고객센터 1599-00700(평일 09시~18시)에서 변경/수정 가능합니다.</li>
              <li><span class="icon_star">*</span>비정상적이거나 불법적인 방법으로 국제전화를 이용할 경우 사전 안내 없이 국제전화 요금제 취소 및 이용에 제한이 있을 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <!-- //inter -->
      </div>
    </div>
    <!--// 컨텐츠 -->
    <Footer />
    <ApplyPlan
      :is-visible.sync="popup.applyPlan.isVisible"
      :type="popup.applyPlan.type"
      :code="popup.applyPlan.code"
      :plan-name="popup.applyPlan.planName"
      :usage-plan="popup.applyPlan.usagePlan"
      :cert-info="certResult"
      @click="ApplyPlan"
    />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl.vue'
import Autocomplete from '@/components/Autocomplete'
import ApplyPlan from '@/components/popup/ApplyPlan'

export default {
  name: 'CallingPlanFlatRate',
  metaInfo: {
    title: '더 길게 요금제 | 특정국가 할인형 | 국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
    Autocomplete,
    ApplyPlan
  },
  data () {
    return {
      formData: {},
      selectedCountry: {},
      country: [],
      resultAreaOpen: false,
      result: {},
      resultCountry: '',
      popup: {
        applyPlan: {
          isVisible: false,
          type: '',
          planName: '',
          code: '',
        }
      },
      certResult: {},
    }
  },
  computed: {
    token() {
      return this.$store.getters.KCB_CERT_KEY
    }
  },
  created () {
    api.ItsvcGetPrePricePlanInfo({ req_tp: 'MORE' })
      .then(response => {
        this.country = response.data
      })
  },
  mounted () {
    this.accordion()
  },
  methods: {
    selectCountry(item) {
      this.selectedCountry = item.selectedObject
    },
    inquire() {
      this.resultCountry = this.selectedCountry.natl_nm
      if (!this.formData.natl_cd) {
        this.$store.commit('ON_ALERT_POPUP', { message: '국가명을 입력하세요.' })
      } else {
        api.ItsvcGetMoreLongPricePlanList(this.formData)
        .then(response => {
          this.result = response.data
          this.resultAreaOpen = true
        })
      }
    },
    ApplyPlan(chrgCd) {
      if (chrgCd) {
        this.popup.applyPlan.isVisible = false
        api.ItsvcPostSubsPricePlan({ chrg_cd: chrgCd, mdl_tkn: this.token })
      } else {
        this.$store.commit('ON_ALERT_POPUP', { message: '요금제를 선택하세요.' })
      }
    },
    certKcbToken() {
      if (this.token) { // 토큰 있으면
      api.CommonGetReqKcbAuth({ mdl_tkn: this.token })
        .then(response => {
          this.certResult = response.data
          if (response.data.result_cd === '0001') {
            this.getCommonData()
          }
        })
      } else {  // 토큰 없으면 : 인증 레이어 팝업 띄우기
        this.$store.commit('ON_CERT_POPUP')
      }
    },
    // 사용중인 요금제 조회
    getCommonData() {
      api.ItsvcGetSvcUseInfo({ mdl_tkn: this.token})
        .then(response => {
          if (response.data.result_cd === '0000' || response.data.result_cd === 'E015') {
            if (response.data.result_cd === '0000') {
              this.popup.applyPlan.usagePlan = response.data.svc_info.chrg_cd_nm
            }
            this.popup.applyPlan.type = 'long'
            this.popup.applyPlan.planName = '더 길게'
            this.popup.applyPlan.isVisible = true
          }
        })
    },
    accordion() {
      const accordionItem = document.querySelectorAll('.accordionItem')
      const accordionTxt = document.querySelectorAll('.txt_open')
      const closeAll = function() {
        accordionItem.forEach(item => {
          item.classList.remove('on')
        })
        accordionTxt.forEach(item2 => {
          item2.style.height = null
          item2.classList.remove('on')
        })
      }
      for(let i=0; i<accordionItem.length; i++) {
        accordionItem[i].addEventListener('click', function() {
          let currentTxt = this.children[1]
          if(this.classList.contains('on')) {
            closeAll()
            this.classList.remove('on')
            currentTxt.style.height = null
            currentTxt.classList.remove('on')
          } else {
            closeAll()
            this.classList.add('on')
            currentTxt.style.height = currentTxt.scrollHeight + 'px'
            currentTxt.classList.add('on')
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1023px) {
  .table_slide .txt_open {
    display: block !important;
    padding: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.4s;
    border-top: none;
    &.on {
      border-top: 1px dotted #8d8d8d;
    }
    .table_list {
      margin: 15px;
    }
  }
}
</style>
