<template>
  <div>
    <div class="paging">
      <span>
        <a @click="first">
          <img
            src="/content/images/btn_paging_first.png"
            alt="처음으로"
          >
        </a>
        <a @click="prev">
          <img
            src="/content/images/btn_paging_prev.png"
            alt="이전"
          >
        </a>
        <template v-for="(item, index) in list">
          <a
            v-if="fromNumber <= (index + 1) && toNumber > (index + 1)"
            :key="index"
            :class="{ active: current === item}"
            @click="select(item)"
          >{{ item }}</a>
        </template>
        <a @click="next">
          <img
            src="/content/images/btn_paging_next.png"
            alt="다음"
          >
        </a>
        <a @click="last">
          <img
            src="/content/images/btn_paging_last.png"
            alt="마지막으로"
          >
        </a>
      </span>
    </div>
    <!-- 모바일용 -->
    <div
      v-if="max > current"
      class="btn_more"
    >
      <button
        type="button"
        @click="more()"
      >
        더보기
        <span title="총 페이지">(<em title="현재 페이지">{{ currentPage }}</em>/ {{ count }})</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    totalCount: {
      type: Number,
      required: true,
    },
    pagePerCount: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
      default: 10
    }
  },
  data() {
    return {
      current: 1,
      fromNumber: 1
    }
  },
  computed: {
    count() {
      return Math.ceil(this.totalCount / this.pagePerCount)
    },
    list() {
      const tmpArray = []
      for (let i = 1; i <= this.count; i++) {
        tmpArray.push(i)
      }
      return tmpArray
    },
    max() {
      return Math.max.apply(null, this.list)
    },
    min() {
      return Math.min.apply(null, this.list)
    },
    toNumber() {
      return this.fromNumber + this.limit
    },
    groupIndex() {
      return Math.ceil(this.current / this.limit) - 1
    }
  },
  watch: {
    currentPage: {
      immediate: true,
      handler() {
        this.current = this.currentPage
      }
    },
    groupIndex: {
      immediate: true,
      handler() {
        this.fromNumber = (this.groupIndex * this.limit) + 1
      }
    }
  },
  created() {

  },
  methods: {
    select(item) {
      this.current = item
      this.$emit('update', this.current)
    },
    first() {
      if (this.current > this.min) {
        this.current = this.min
        this.fromNumber = 1
        this.$emit('update', this.current)
      }
    },
    prev() {
      if (this.min < this.current) {
        this.current--
        this.$emit('update', this.current)
      }
    },
    next() {
      if (this.max > this.current) {
        this.current++
        this.$emit('update', this.current)
      }
    },
    last() {
      if (this.current < this.max) {
        this.current = this.max
        this.fromNumber = this.count - (this.count % this.limit)
        this.$emit('update', this.current)
      }
    },
    more(){
      if (this.max > this.current) {
        this.current++
        this.$emit('loadmo', this.current)
      }
    }
  }
}
</script>
