<template>
  <div id="HR_CULTURE">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bgEthics">
          <h3 class="title">
            윤리강령
          </h3>
          <p class="txt">
            SK텔링크는 임직원의 행동과 가치판단의 기준인<br>
            윤리규범을 제정하여 운영하고 있습니다.
          </p>
          <img
            src="/content/images/sub_header_ethics.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- company -->
        <div class="company mb0">
          <div class="hr_area">
            <h4>윤리강령</h4>
            <p>
              SK텔링크는 <span class="txt_point01">임직원의 행동과 가치 판단의 기준인 윤리규범을 제정하여 운영</span>하고 있으며,<br class="w_hide">
              윤리규범을 올바르게 이해하고 실천할 수 있도록 구체적인 행동 기준을 제공하고 있습니다.
            </p>
            <!-- tab -->
            <div class="tab_type01">
              <ul>
                <li
                  :class="tabIndex == 1 ? 'on': ''"
                >
                  <a
                    :title="tabIndex == 1 ? '현재메뉴 선택됨': ''"
                    @click="tabActive(1)"
                  >윤리규범</a>
                </li>
                <li
                  :class="tabIndex == 2 ? 'on': ''"
                >
                  <a
                    :title="tabIndex == 2 ? '현재메뉴 선택됨': ''"
                    @click="tabActive(2)"
                  >윤리실천지침</a>
                </li>
              </ul>
            </div>
            <!-- //tab -->
            <div
              v-if="tabIndex == 1"
              class="accWrapPd"
            >
              <div class="accWrap">
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      윤리규범 서문
                    </a>
                  </div>
                  <div class="accContent">
                    <p class="fword">
                      SK텔링크(이하 ‘회사’라 함, ‘회사 = 나’)는 SKMS를 기업경영의 근간으로 삼아 고객, 구성원, 주주, Business Partner(이하 ‘BP’라 함), 사회 등 다양한 이해관계자의 가치를 창출함으로써 사회 · 경제 발전에 핵심적인 역할을 수행하고, 나아가 인류의 행복에 공헌하는 SUPEX Company를 지향한다.<br>
                      회사가 추구하는 행복은 장기적으로 지속 가능하고, 회사와 이해관계자 상호간의 관계와 역할이 균형을 이루는 방향으로 실천한다.<br>
                      또한, 회사는 자유시장 경제질서를 존중하며, 제품과 서비스의 질적 향상 등 정당한 방법을 통하여 경쟁우위를 확보하는 등 상도의에 따라 공정하게 경쟁한다.<br>
                      이에 회사는 경영철학인 “자발적 · 의욕적 두뇌활용을 통한 SUPEX 추구를 통해 이해관계자의 행복 구현”을 위한 행동과 가치판단의 기준인 윤리규범을 제정하고 이를 준수한다.
                    </p>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      1. 고객에 대한 책임
                    </a>
                  </div>
                  <div class="accContent">
                    <p>회사는 고객을 지속적으로 만족시켜 고객으로부터 신뢰를 얻고 궁극적으로 고객과 더불어 발전하여야 한다.</p>
                    <dl>
                      <dt>1.1 고객중심 경영</dt>
                      <dd>1.1.1 회사는 고객이 만족할 수 있는 제품과 서비스를 제공하고, 합리적으로 제품과 서비스를 선택할 수 있도록 정확한 정보를 적시에 제공한다</dd>
                      <dd>1.1.2 회사는 고객의 정당한 요구와 합리적인 제안을 적극적으로 수용하고, 고객과의 약속은 반드시 지킨다.</dd>
                      <dt>1.2 고객정보 보호</dt>
                      <dd>회사는 고객정보 보호 관련 법령을 준수하고, 고객정보 보호를 위한 최선의 노력을 다한다</dd>
                      <dt>1.3 고객가치 제고</dt>
                      <dd>회사는 고객에게 제품과 서비스를 제공함에 있어 고객이 기대하는 품질수준 유지, 안심하고 유익하게 사용할 수 있는 이용환경 조성 등 고객가치의 제고를 위해 최선을 다한다.</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      2. 구성원에 대한 책임
                    </a>
                  </div>
                  <div class="accContent">
                    <p>회사는 구성원이 자발적이고 의욕적으로 일할 수 있는 환경을 조성하고, 구성원의 안전과 행복을 위해 최선의 노력을 다하여야 한다</p>
                    <dl>
                      <dt>2.1 인간위주의 경영</dt>
                      <dd>2.1.1 회사는 구성원의 인격을 존중하고 역량과 성과에 따라 공정하고 합리적으로 대우하며, 구성원의 자기개발 활동을 지원하고 업무수행에 필요한 학습기회를 적극 제공한다.</dd>
                      <dd>2.1.2 회사는 구성원이 자발적이고 의욕적으로 두뇌활용을 극대화할 수 있도록 SUPEX 추구환경을 조성하여야 한다.</dd>
                      <dt>2.2 구성원의 안전과 행복</dt>
                      <dd>2.2.1 회사는 구성원의 안전과 건강을 위한 제도를 정비하고 이와 관련된 국제기준, 관련법령, 내부규정 등을 준수한다.</dd>
                      <dd>2.2.2 회사는 구성원이 SK Values를 바탕으로 상호신뢰와 자긍심을 갖고 도전정신과 창의성을 발현하여 동료와 더불어 행복을 추구할 수 있도록 최선의 노력을 다한다.</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      3. 주주에 대한 책임
                    </a>
                  </div>
                  <div class="accContent">
                    <p>회사는 주주의 가치가 창출될 수 있도록 기업의 가치를 높이고, 이를 위해 경영의 투명성을 제고하고 효율적인 경영을 하여야 한다.</p>
                    <dl>
                      <dt>3.1 기업가치 제고</dt>
                      <dd>회사는 끊임없는 혁신을 통한 투명하고 효율적인 경영으로 기업가치를 극대화하며, 그 성과를 주주와 공유한다.</dd>
                      <dt>3.2 주주권익 보호</dt>
                      <dd>3.2.1 회사는 이사회 중심의 투명경영을 실천하며, 주주의 정당한 요구 및 제안을 존중한다.</dd>
                      <dd>3.2.2 회사는 재무제표 등 공시자료를 제반 법규 및 회계기준에 의거하여 작성하고 관련 법령에 따라 적정성 · 정확성 · 완전성 · 적시성 있는 공시를 한다.</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      4. Business Partner와의 관계
                    </a>
                  </div>
                  <div class="accContent">
                    <p>회사는 BP에게 공정한 기회를 제공하고 경쟁력 향상을 지원하며, 상호 신뢰를 기반으로 상생경영을 추구하여야 한다.</p>
                    <dl>
                      <dt>4.1 상생경영</dt>
                      <dd>회사는 협력회사에 대해 공정한 거래 기회를 부여하고 우월적 지위를 이용한 부당 행위를 하지 않으며, 상호 이익과 공동발전을 추구한다.</dd>
                      <dt>4.2 BP정보보호</dt>
                      <dd>회사는 BP의 정보와 영업비밀을 보호하여야 하고, 제공받은 목적 외의 용도로 이용하지 않으며, 위법하게 취득, 사용하지 않는다</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      5. 사회에 대한 책임
                    </a>
                  </div>
                  <div class="accContent">
                    <p>회사는 경제 발전에의 기여와 함께, 사회적 · 문화적 활동을 통하여 사회에 공헌하며, 사회규범과 윤리기준에 적합한 경영을 하여야 한다.</p>
                    <dl>
                      <dt>5.1 건전한 문화 창달</dt>
                      <dd>회사는 사업을 영위하는 지역의 제반 법규를 준수하고, 지역사회의 건전한 문화 형성 및 창달을 위해 노력한다.</dd>
                      <dt>5.2 인간의 존엄성 존중</dt>
                      <dd>회사는 세계인권선언 및 인권관련 국제기준을 준수하고, 고객, 구성원 등 모든 이해관계자에 대해 인종 · 종교 · 성 · 장애 등을 이유로 한 불합리한 차별을 하지 않으며, 미성년자 보호, 고객 프라이버시 보호 등 기본적 인권을 보호한다.</dd>
                      <dt>5.3 환경친화적 경영</dt>
                      <dd>회사는 환경보호 관련 국제기준 및 관련 법령, 내부규정 등을 준수하고, 환경친화적 경영을 실천한다.</dd>
                      <dt>5.4 사회공헌 활동</dt>
                      <dd>회사는 사업지역의 기업시민으로서 자원봉사, 재난구호 등 사회공헌 활동에 적극 참여한다.</dd>
                      <dt>5.5 정치적 중립성 유지</dt>
                      <dd>5.5.1 회사는 개인의 참정권과 정치적 의사표현을 존중하고 회사의 자금, 인력, 시설 등을 정치적 목적으로 사용하는 일체의 정치활동을 인정하지 아니한다.</dd>
                      <dd>5.5.2 회사는 대정부 관련 부적절한 거래를 지양하고 각국의 관련 법규를 준수한다.</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      6. 윤리규범의 적용
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>6.1 적용 대상 및 준수의무</dt>
                      <dd>6.1.1 본 윤리규범은 회사 및 구성원에게 적용된다. 회사 및 구성원은 회사의 이해관계자에게도 본 윤리규범을 이해시키고 실천을 권장한다.</dd>
                      <dd>6.1.2 본 윤리규범을 위반한 경우에는 사규에 따라 조치한다.</dd>
                      <dt>6.2 윤리경영 실천지침 운영</dt>
                      <dd>윤리경영 담당부서는 본 윤리규범의 올바른 해석과 시행을 위하여 ‘윤리경영 실천지침’을 제정 · 운영한다.</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      부칙
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>1. 시행일</dt>
                      <dd>
                        본 윤리규범은 2019년 7월 8일부로 시행한다.
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="tabIndex == 2"
              class="accWrapPd"
            >
              <div class="accWrap">
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      1. 총칙
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>1.1 목적</dt>
                      <dd>본 윤리경영 실천지침(이하 “실천지침”)은 SK텔링크 구성원이 윤리규범을 올바르게 이해하고 실천 할 수 있도록 SK텔링크 구성원으로서 준수하여 할 행동기준을 규정하는 것을 목적으로 한다.</dd>
                      <dt>1.2 용어의 정의</dt>
                      <dd>1.2.1 구성원: SK텔링크에 재직중인 임 · 직원(계약직, 파견직, 기타 비정규직원 포함)</dd>
                      <dd>1.2.2 금품: 금전(현금, 상품권, 이용권, 복권 등), 물품 등 경제적 이익</dd>
                      <dd>1.2.3 향응 · 접대: 술자리, 골프, 공연, 국내외 관광 및 출장, 사행성 오락 등</dd>
                      <dd>1.2.4 편의: 교통, 숙박, 관광안내 및 행사지원 등 금품 또는 향응 이외의 지원</dd>
                      <dd>1.2.5 금전거래: 금전대차, 공동투자, 대출보증, 도박 등 </dd>
                      <dd>1.2.6 친 · 인척: 친가8촌, 처/외가 4촌 </dd>
                      <dd>1.2.7 이해관계자: 구성원의 업무 관련 행위나 의사결정으로 그 권익에 영향을 받는 대리점, 판매점, 납품업체, 협력업체 등 사내 외 모든 자연인과 법인을 포함한 단체</dd>
                      <dd>1.2.8 직장 내 괴롭힘: 구성원이 직장에서의 지위 또는 관계 등의 우위를 이용하여 업무상 적정범위를 넘어 다른 구성원에게 신체적 · 정신적 고통을 주거나 근무환경을 악화시키는 행위</dd>
                      <dt>1.3 적용범위</dt>
                      <dd>본 실천지침은 회사의 재직중인 모든 구성원에게 적용한다.</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      2. 건전한 기업문화 조성
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>2.1 성실한 업무수행</dt>
                      <dd>2.1.1 구성원은 자신의 권한과 책임을 명확히 인식하고 정직과 신뢰의 원칙에 입각하여 업무를 수행하여야 한다.</dd>
                      <dd>2.1.2 구성원은 업무수행에 지장을 주는 겸직, 겸업, 부업 등을 하지 않는다. 단, 회사의 업무 관련성 등을 고려하여 회사의 사전승인이 있는 경우에는 예외로 한다.</dd>
                      <dd>2.2.3 구성원은 빈틈없고 야무지게 업무를 처리해야 하며, 업무 소홀로 인해 회사의 손실이 발생하지 않도록 해야 한다.</dd>
                      <dt>2.2 품위유지</dt>
                      <dd>구성원은 각자의 언행이 회사의 신뢰와 명성에 직결된다는 것을 명심하고 사회 구성원으로서 책임과 의무를 다하도록 노력해야 한다.</dd>
                      <dt>2.3 상호존중</dt>
                      <dd>2.3.1 구성원은 상하간 위화감을 조성하고 부서 내 융화를 저해하는 폭언, 불건전한 음주문화 등의 행동을 하지 않는다.</dd>
                      <dd>2.3.2 구성원간 대가성 금품수수, 청탁, 과도하거나 부적절한 금전거래 등 건전한 관계 형성을 저해하는 일체의 행위를 하지 않는다.</dd>
                      <dd>2.3.3 구성원은 상하 · 동료간에 성희롱, 성폭력, 직장 내 괴롭힘, 부적절한 이성관계, 사적인 부담을 주는 행위를 하여서는 아니 되며 상호 동반자로 인식하고 존중하여야 한다.</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      3. 공정한 업무 수행
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>3.1 금품, 향응 · 접대 및 편의 授受</dt>
                      <dd>3.1.1 구성원은 공과 사를 엄격히 구분하고 공정하고 투명하게 업무를 수행해야 한다.</dd>
                      <dd>
                        3.1.2 구성원은 업무와 관련하여 이해관계자와 금품이나 선물 등을 수수해서는 아니 된다. 다만, 다음의 경우에는 예외적으로 금품수수가 인정된다.
                        <ol class="depth3">
                          <li>가. 홍보 및 행사 기념품:  회사 또는 BP 등의 로고나 명칭이 표시되어 있으며, 그 가격이 사회통념상 인정되는 수준의 물품</li>
                          <li>나. 경조금: 상부상조의 취지에 따른 사회통념상 인정되는 수준의 금품 (경조사를 이해관계자에게 공지하는 행위는 금지)</li>
                        </ol>
                      </dd>
                      <dd>3.1.3. 구성원은 사회통념상 허용되지 않는 술자리, 골프, 해외출장/관광 등 향응 · 접대 및 개인적 편의를 수수해서는 아니 된다.</dd>
                      <dd>3.1.4. 구성원은 업무와 관련하여 BP와 접촉 시 상사에게 보고함을 원칙으로 한다.</dd>

                      <dt>3.2 이해 상충의 해결</dt>
                      <dd>3.2.1 구성원은 회사와 이해상충으로 인해 회사에 대한 신의성실 의무를 위반하지 않도록 하여야 한다.</dd>
                      <dd>
                        3.2.2 다음은 유의하여야 할 이해상충관계의 예시이나, 모든 이해상충관계를 열거한 것이 아니므로 실제 상황에서는 엄격한 판단과 적용이 요구된다.
                        <ol>
                          <li class="depth3">
                            가. 행위가 금지되는 이해상충의 예시
                            <ol>
                              <li class="depth4">
                                1) 구성원이 직접 또는 제3자를 통하여 회사의 자산이나 경영정보를 이용하여 사적 이익을 취하는 행위
                                <p class="depth5">
                                  - 회사가 매수 투자 또는 임차하려고 하는 부동산이나 주식 등에 투자하는 행위
                                </p>
                                <p class="depth5">
                                  - 회사정보를 이용하여 이해관계자로부터 본인 또는 제3자의 고용, 취업 추천 등을 보장받는 행위
                                </p>
                              </li>
                              <li class="depth4">
                                2) 구성원이 직접 또는 제3자를 통하여 BP와 금전대차, 공동투자, 대출보증, 도박 등 금전거래를 하는 행위
                              </li>
                              <li class="depth4">
                                3) 구성원이 직접 또는 제3자를 통하여 BP로부터 과도한 보상을 받는 행위
                              </li>
                            </ol>
                          </li>
                          <li class="depth3">
                            나. 회사의 승인을 득하여야 하는 이해상충의 예시
                            <ol>
                              <li class="depth4">
                                1) 구성원이 직접 또는 제3자를 통하여 회사, BP 및 경쟁업체와 거래하는 행위
                              </li>
                              <li class="depth4">
                                2) 구성원이 직접 또는 제3자를 통하여 이해관계가 있는 사업체의 직위를 겸임하는 행위
                              </li>
                              <li class="depth4">
                                3) 구성원이 직접 또는 제3자를 통하여 영향력을 행사할 수 있는 BP의 주식이나 채권 등을 보유하는 행위
                              </li>
                            </ol>
                          </li>
                          <li class="depth3">
                            다. 기타 불가피하게 구성원이 회사와 이해상충관계를 형성한 경우 윤리경영상담센터에 신고하여야 하며 필요 시 회사의 승인을 받아야 한다. 또한 구성원의 가족 및 친 · 인척, 지인이 회사나 BP와 거래하는 경우에도 윤리경영상담센터에 신고하여야 한다.
                          </li>
                        </ol>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      4. 회사의 자산 보호 및 예산 사용
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>4.1 회사의 자산 및 정보 보호</dt>
                      <dd>
                        4.1.1 구성원은 회사의 유 · 무형자산 및 영업비밀 등의 정보를 보호하고 정당하게 사용하여야 한다.
                        <ol>
                          <li class="depth3">
                            가. 구성원은 회사의 상표권, 특허권, 저작권 등 지적재산권을 적극적으로 보호하여야 한다.
                          </li>
                          <li class="depth3">
                            나. 구성원은 업무상 취득한 회사의 미공개정보를 이용하거나 제3자에게 제공하여 사적 이익을 도모하는 등의 행위를 해서는 아니 된다.
                          </li>
                          <li class="depth3">
                            다. 구성원은 회사 정보와 영업비밀을 사전 승인 없이 내 · 외부에 누설하거나 제공해서는 아니 되며, 회사의 정보보안 정책에 의거 정보보안, IT보안 및 고객정보 보호 등 관련 규정을 준수하고, 회사의 점검 등에 적극 협조하여야 한다.
                          </li>
                        </ol>
                      </dd>
                      <dd>4.1.2 구성원은 회사에 손실을 가져올 상황이 발생하거나 손실발생 개연성이 있을 경우 즉시 회사에 보고하고 손실 방지 및 최소화할 수 있는 조치를 취해야 한다</dd>
                      <dt>4.2 예산사용</dt>
                      <dd>4.2.1 구성원은 회사의 예산을 목적과 기준에 적합하게 효율적으로 사용하여야 한다.</dd>
                      <dd>4.2.2 구성원은 사적인 목적으로 회사비용을 사용하지 않고, 경비 집행 및 정산 시 허위증빙을 사용하지 않아야 한다.</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      5. 법규 및 회사 경영방침 준수
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>5.1 구성원은 경영활동과 관련된 국내 · 외 법규 및 회사 방침과 사규를 명확하게 숙지하고 이에 따라 업무를 수행하여야 한다.</dt>
                      <dt>5.2 구성원은 Compliance 관련 법규 및 사규에 따라 공정거래 의무를 준수하며, 부당한 공동행위, 부당한 내부거래 및 돈세탁 등을 하지 않아야 한다.</dt>
                      <dt>5.3 회사는 공정거래, 안전, 환경 등과 관련된 국내 · 외 법규 및 국제협약을 준수한다.</dt>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      6. 실천지침의 운영 및 절차
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>6.1 윤리경영상담센터 운영</dt>
                      <dd>6.1.1 실천지침의 운영을 위하여 윤리경영 담당 부서는 ‘윤리경영상담센터’를 구축 · 운영한다.</dd>
                      <dd>6.1.2 구성원을 포함한 이해관계자는 윤리경영상담센터를 통해 윤리경영과 관련된 사안을 상담하거나 신고할 수 있다.</dd>
                      <dd>6.1.3 회사는 상담 · 신고로 인하여 회사의 이익에 기여한 상담 · 신고자에게 포상할 수 있다</dd>

                      <dt>6.2 상담 · 신고 접수 시 처리절차</dt>
                      <dd>
                        6.2.1 윤리경영 담당부서장은 구성원과 관련된 윤리경영 관련 상담 · 신고가 있는 경우에는 다음과 같이 조치한다.
                        <ol>
                          <li class="depth3">
                            가. 상담 · 신고된 사안이 추가적인 사실확인 및 조사가 필요하다고 판단되는 경우 직접 조사 하거나 해당업무 담당 부서에 위임할 수 있다.
                          </li>
                          <li class="depth3">
                            나. 조사결과에 대해서는 관련부서와 협의하여 인사규정 등 회사의 제반 사규에 의거하여 처리한다
                          </li>
                        </ol>
                      </dd>
                      <dd>
                        6.2.2 BP 관련 상담 · 신고가 있는 경우 다음과 같이 조치한다.
                        <ol>
                          <li class="depth3">
                            가. 회사는 사안의 중대성, 사회 및 회사 등에의 영향 정도를 감안하여 해당 BP와의 거래물량 제한, 계약해지 등의 필요한 조치를 취할 수 있다.
                          </li>
                          <li class="depth3">
                            나. 회사는 전항에도 불구하고 영향 정도가 경미한 경우 재발방지에 대한 의지 등을 고려하여 해당 선처를 결정할 수 있다.
                          </li>
                        </ol>
                      </dd>
                      <dd>6.2.3 윤리경영 담당부서장은 윤리규범 관련 상담 · 신고 내역 및 처리 결과를 접수일자 순으로 기록하여 저장매체에 보관한다.</dd>
                      <dt>6.3 상담 · 신고자 보호</dt>
                      <dd>6.3.1 회사는 상담 · 신고자의 신분 및 상담 · 신고 내용을 보호하며, 상담 · 신고로 인한 불이익을 주지 않는다.</dd>
                      <dd>6.3.2 상담 · 신고자는 윤리경영 담당 부서장에게 신분보호를 요청할 수 있고, 이 경우 관련 부서간 협의를 통해 부서이동 등 필요한 조치를 취할 수 있다</dd>
                      <dd>6.3.3 구성원은 윤리경영 담당 부서 등에 상담 · 신고자의 신분을 문의하거나 이를 알아내기 위한 탐문 등 신분노출을 가능하게 하는 어떠한 행위도 하여서는 아니 된다</dd>
                      <dd>6.3.4 상담 · 신고자가 부정행위에 가담하였으나, 그 사실을 상담 · 신고한 경우 회사는 부정행위에 대한 처벌이나 징계 시 정상을 참작할 수 있다</dd>
                      <dt>6.4 금품 · 선물 수취 시 처리절차 </dt>
                      <dd>
                        불가피하게 금품/선물을 받은 경우에는 다음과 같이 조치한 후 상사에게 보고하고, 그 경과를 윤리경영상담센터에 신고하여야 한다.
                        <ol>
                          <li class="depth3">
                            가. 반송 가능한 경우에는 즉시 반송하여야 한다.
                          </li>
                          <li class="depth3">
                            나. 반송이 곤란하거나 부패, 변질 등의 사유로 반송이 불가능한 경우
                            <ol>
                              <li class="depth4">
                                - 본사의 경우 회사의 윤리경영 담당부서에 물품을 전달한다.
                              </li>
                              <li class="depth4">
                                - 지방은 해당 물품을 해당 지역의 결연단체 등에 기증하고, 그 처리내역을 윤리경영 담당부서에 통보한다.
                              </li>
                              <li class="depth4">
                                - 승진, 이동 등과 관련된 난 · 화분은 일괄 처분하여 그 수익금을 결연단체 등에 기증한다.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      7. 실천지침의 준수
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>7.1 실천지침 준수 책임</dt>
                      <dd>7.1.1 모든 구성원은 윤리규범과 실천지침을 준수할 책임이 있으며, 의문이 있는 사항에 대해서는 조직의 리더 또는 윤리경영 담당부서에 질의 · 상담하여 그 해석에 따라 행동한다.</dd>
                      <dd>7.1.2 조직의 리더는 소속구성원과 업무상 관련된 이해관계자들이 회사의 윤리규범과 실천지침을 올바르게 이해하고 준수할 수 있도록 적극적으로 지원하고 관리할 책임이 있으며, 솔선하여 준수함으로써 모범을 보여야 한다.</dd>
                      <dd>7.1.3 구성원은 매년 윤리경영 실천을 위해 서약서를 작성하고, 서약내용을 준수하여야 한다.</dd>
                      <dt>7.2 윤리경영 교육 및 실천 Guide 운영</dt>
                      <dd>7.2.1 윤리경영 담당 부서는 구성원들이 윤리경영을 실천할 수 있도록 직장 내 괴롭힘 예방 등을 포함한 윤리경영 교육을 매년 1회 이상 실시해야 한다.</dd>
                      <dd>7.2.2 윤리경영 교육은 주요 윤리경영 위반 사례, 비윤리 행위에 대한 상담 및 처리절차, 피해자 보호 및 가해자에 대한 조치내용 등을 포함한다.</dd>
                      <dd>7.2.3 윤리경영 담당 부서는 실천지침을 준수하는데 있어 구성원들의 판단 및 행동의 기준을 제시하기 위하여 ‘윤리경영 실천 Guide’를 제정 · 운영할 수 있다.</dd>
                      <dd>7.2.4 실∙본부 단위 조직은 해당조직의 특성을 고려하여 필요할 경우 ‘구성원행동준칙’을 제정 · 운영할 수 있다.</dd>
                    </dl>
                  </div>
                </div>
                <div class="accContainer">
                  <div class="accHeader">
                    <a
                      @click.prevent="toggleScroll($event)"
                    >
                      부칙
                    </a>
                  </div>
                  <div class="accContent">
                    <dl>
                      <dt>1. 시행일</dt>
                      <dd>
                        본 윤리경영 실천지침은 2019년 7월 8일부터 시행한다.
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl.vue'

export default {
  name: 'HrCulture',
  metaInfo: {
    title: '윤리걍령 | 윤리경영 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:'1'
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    tabActive(num){
      this.tabIndex = num
      const arr = document.querySelectorAll('.accContainer')
      arr.forEach(function(ele) {
        ele.classList.remove('on')
      });
    },
    toggleScroll(event) {
      event.preventDefault()
      let parent = event.currentTarget.parentNode.parentNode
      parent.classList.toggle('on')

      setTimeout(function() {
        let gPos = parent.offsetTop,
            gH1 = document.querySelector('#header').offsetHeight,
            gH2 = document.querySelector('.location').offsetHeight,
            result = gPos - gH1 - gH2 - 3

        if(document.documentElement.scrollTop || document.body.scrollTop) {
          document.documentElement.scrollTop = result
        } else {
          document.querySelector('#wrap').scrollTop = result
        }
      },300);

    }
  }
}
</script>

<style lang="scss" scoped>
.sub_header.bgEthics {
  background: url('/content/images/sub_header_bg_ethics.jpg') no-repeat 50% 0;
}
.tab_type01 { margin-top: 80px; border-top: 1px solid #dadada; }
.accWrap {
  width: 1120px;
  margin: 100px auto 0;
  text-align: left;
  border-top: 2px solid #e51937;
  border-bottom: 1px solid #dadada;
  .accContainer {
    border-top: 1px solid #dadada;
    &:first-child { border-top: 0; }
    .accHeader {
      a {
        display: block;
        position: relative;
        padding: 37px 90px 37px 19px;
        color: #666;
        font-size: 24px;
        font-weight: 400;
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 30px;
          width: 11px;
          height: 11px;
          margin-top: -10px;
          border-top: 2px solid #222;
          border-right: 2px solid #222;
          transform: rotate(135deg);
        }
      }
    }
    .accContent {
      height: 0;
      padding: 0 30px;
      overflow: hidden;
      background: #f9f9f9;
      border-top: 1px solid #fff;
      transition: all 0.3s;
      & > p {
        margin-bottom: 10px; font-size: 16px; font-weight: 500;
        &.fword {
          margin-bottom: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 30px;
          .txt_point01 {
            display: inline-block;
            margin-top: 24px;
          }
        }
      }
      & > p + dl { margin-top: 25px; }
      & > dl {
        & > dt {
          margin-top: 15px;
          font-size: 16px;
          font-weight: 500;
          &:first-child { margin-top: 0; }
        }
        & > dd {
          margin-top: 5px;
          padding-left: 20px;
          font-size: 16px;
          font-weight: 400;
        }
      }
      .depth3, .depth4, .depth5 {
        margin-top: 5px;
        padding-left: 20px;
        font-size: 16px;
        font-weight: 400;
        & > li {
          font: inherit;
        }
      }
      .depth5 {
        padding-left: 20px;
      }
    }
    &.on {
      .accHeader {
        a:after {
          margin-top: -2px;
          transform: rotate(-45deg);
        }
      }
      .accContent {
        height: auto;
        padding-top: 28px;
        padding-bottom: 28px;
        border-top: 1px solid #dadada;
        border-bottom: 1px solid #dadada;
      }
    }
    &.on + .accContainer {
      border-top: 0;
    }
  }
}
@media (max-width: 1023px) {
.tab_type01 { margin-top: 40px; }
.accWrapPd { padding: 0 15px; }
.accWrap {
  width: 100%;
  margin-top: 50px;
  .accContainer {
    .accHeader {
      a { padding: 20px 30px 20px 20px; font-size: 18px; }
    }
    .accContent {
      padding: 0 20px;
      & > p {
        padding: 0; font-size: 14px; line-height: normal;
        &.fword {
          font-size: 14px;
          line-height: normal;
          .txt_point01 {
            margin-top: 14px;
            line-height: normal;
          }
        }
      }
      & > p + dl { margin-top: 15px; }
      & > dl {
        & > dt { margin-top: 7px; font-size: 14px; line-height: normal; }
        & > dd {
          margin-top: 0; font-size: 14px; line-height: normal;
          .depth3, .depth4, .depth5 {
            margin-top: 0;
            padding: 0 0 0 15px;
            font-size: 14px;
            line-height: normal;
            & > li {
              font: inherit;
            }
          }
        }
      }
    }
    &.on {
      .accContent {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
}
}
</style>
