var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"INTRODUCE_MILESTONE"}},[_c('Header'),_c('div',{attrs:{"id":"container"}},[_c('BreadCrumb'),_c('div',{staticClass:"contents",attrs:{"id":"contents"}},[_vm._m(0),_c('div',{staticClass:"tab_type01 tab4"},[_c('ul',[_c('li',{class:_vm.tabIndex == 1 ? 'on' : '',attrs:{"id":"taba","title":"현재메뉴 선택됨"}},[_c('div',{on:{"click":function($event){return _vm.tabActive(1)}}},[_vm._v("2021~현재")])]),_c('li',{class:_vm.tabIndex == 2 ? 'on' : '',attrs:{"id":"tabb"}},[_c('div',{on:{"click":function($event){return _vm.tabActive(2)}}},[_vm._v("2011~2020")])]),_c('li',{class:_vm.tabIndex == 3 ? 'on' : '',attrs:{"id":"tabc"}},[_c('div',{on:{"click":function($event){return _vm.tabActive(3)}}},[_vm._v("2001~2010")])]),_c('li',{class:_vm.tabIndex == 4 ? 'on' : '',attrs:{"id":"tabd"}},[_c('div',{on:{"click":function($event){return _vm.tabActive(4)}}},[_vm._v("1998~2000")])])])]),_c('div',{staticClass:"company"},[(_vm.tabIndex == 1)?_c('div',{staticClass:"history_area",attrs:{"id":"tab1"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4)]):_vm._e(),(_vm.tabIndex == 2)?_c('div',{staticClass:"history_area",attrs:{"id":"tab2"}},[_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14)]):_vm._e(),(_vm.tabIndex == 3)?_c('div',{staticClass:"history_area img_no",attrs:{"id":"tab3"}},[_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24)]):_vm._e(),(_vm.tabIndex == 4)?_c('div',{staticClass:"history_area img_no",attrs:{"id":"tab4"}},[_vm._m(25),_vm._m(26),_vm._m(27)]):_vm._e()])])],1),_c('Footer'),_c('SummitControl')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_header bg01"},[_c('h3',{staticClass:"title"},[_vm._v("연혁")]),_c('p',{staticClass:"txt"},[_vm._v(" 고객과 함께 성장해온 SK텔링크는"),_c('br'),_vm._v(" 고객과 함께 또 다른 미래를 실현합니다 ")]),_c('img',{attrs:{"src":"/content/images/sub_header_cp01.jpg","alt":"비쥬얼 이미지"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box type"},[_c('h4',[_vm._v("2024")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 알뜰폰 부문 1위(2년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화 부문 1위(10년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 알뜰폰 부문 1위(3년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화 부문 1위(16년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 알뜰폰 부문 1위(4년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 알뜰폰 부문 1위(2년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화 부문 1위(16년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 국가고객만족도(NCSI) 알뜰폰 부문 1위(2년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화 부문 1위(11년 연속) ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box type"},[_c('h4',[_vm._v("2023")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("11월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 알뜰폰 부문 1위 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 알뜰폰 부문 1위 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화 부문 1위(9년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 알뜰폰 부문 1위(2년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화 부문 1위(15년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 알뜰폰 부문 1위(3년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화 부문 1위(15년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 소비자가 가장 추천하는 브랜드(KBRI) 알뜰폰 부문 1위(3년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 국가고객만족도(NCSI) 알뜰폰 부문 1위 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화 부문 1위(10년 연속) ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box type"},[_c('h4',[_vm._v("2022")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화 부문 1위(8년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 알뜰폰 부문 1위(첫 1위) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화 부문 1위(14년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 알뜰폰 부문 1위(2년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위(14년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 소비자가 가장 추천하는 브랜드(KBRI) 알뜰폰 부문 1위(2년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스 부문 1위(9년 연속) ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box type"},[_c('h4',[_vm._v("2021")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위(7년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위(13년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 알뜰폰 부문 1위 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위(13년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 소비자가 가장 추천하는 브랜드(KBRI) 알뜰폰 부문 1위 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(8년 연속) ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box type"},[_c('h4',[_vm._v("2020")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("12월")]),_vm._v(" 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위(5년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위(6년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위(9년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위(12년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위(12년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("05월")]),_vm._v(" SK네트웍스에 중고폰 사업부문 매각 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("05월")]),_vm._v(" GX Air 위성통신 면허(국경간 공급협정 승인) 획득 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(7년 연속) ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box type"},[_c('h4',[_vm._v("2019")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("12월")]),_vm._v(" 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위 수상(4년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(5년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(8년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(11년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(11년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(6년 연속) ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box type"},[_c('h4',[_vm._v("2018")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("12월")]),_vm._v(" 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위 수상(3년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(4년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(10년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(7년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(10년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" SK 7mobile 효도의신 상품 출시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(5년연속) ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2017")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("12월")]),_vm._v(" 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위 수상(2년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(3년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 알뜰폰 서비스 브랜드 'SK 7mobile'로 리뉴얼 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(9년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(6년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(9년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 알뜰폰 공부의신 상품 출시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("05월")]),_vm._v(" 세계 최초 VoLTE 국제전화 서비스 상용화 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(4년연속) ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2017.png","alt":"공부의神 이미지"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2016")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("12월")]),_vm._v(" 방송통신위원회 이용자보호업무평가 알뜰폰 최고등급(3년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("12월")]),_vm._v(" 고객이 가장 추천하는 기업(KNPS) 국제전화서비스 부문 1위 수상 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 보안전문회사 엔에스오케이(NSOK) 인수 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(3년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(8년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(5년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(8년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 미래부 지정 국가 공인 웹접근성(Web Accessibility) 품질 인증마크 획득 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(3년연속) ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2016.png","alt":"SK telink + NSOK 이미지"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2015")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국산업의 고객만족도(KCSI) 국제전화서비스 부문 1위 수상(2년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(7년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 세계 최초 VoLTE 국제전화 테스트 성공 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(4년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(7년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 미래부 지정 국가 공인 웹접근성(Web Accessibility) 품질 인증마크 획득 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상(2년연속) ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2015.png","alt":"VoLTE 이미지"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2014")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 모바일팩스 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(6년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 프리미엄브랜드지수(KS-PBI) 국제전화서비스 부문 1위 수상(3년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(6년 연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 위성통신 서비스 “SK스마트샛” 출시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스 부문 1위 수상 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2014.png","alt":"Mobile FAX 이미지"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2013")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("11월")]),_vm._v(" 1800 전국대표번호 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 알뜰폰(MVNO) 30만 가입자 돌파 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수 (NBCI) 국제전화서비스 부문 1위 수상(5년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 브랜드콜 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 한국서비스품질지수 (KS-SQI) 국제전화서비스 부문 1위 수상(5년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("01월")]),_vm._v(" 알뜰폰(MVNO) 후불 사업 개시 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2013.png","alt":"1800 브랜드콜 이미지"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2012")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국서비스품질지수(KS-SQI) 국제전화서비스 부문 1위 수상(4년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(4년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 위성DMB 서비스 종료 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 알뜰폰(MVNO) 선불 사업 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("05월")]),_vm._v(" 화상회의서비스(VaaS) 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 스마트폰 전용 국제전화 ‘00700’ 앱 출시 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2012.png","alt":"SK 국제전화 00700 이미지"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2011")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국서비스품질지수 (KS-SQI) 국제전화서비스 부문 1위 수상(3년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상(3년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상(6년연속) ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2011.png","alt":"NBCI, KS-SQI, NCSI 이미지"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2010")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("11월")]),_vm._v(" TU Media Corp와 합병 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국서비스품질지수 (KS-SQI) 국제전화서비스 부문 1위 수상(2년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 국가브랜드경쟁력지수 (NBCI) 국제전화서비스 부문 1위 수상(2년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 국가고객만족도 (NCSI) 국제전화서비스 부문 1위 수상(5년연속) ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2010.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2009")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 한국서비스품질지수 (KS-SQI) 국제전화서비스 부문 1위 수상 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 한국산업의 고객만족도( KCSI) 국제전화서비스 부문 1위 수상 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 국가브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상(4년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("02월")]),_vm._v(" 국내 최초 오피스 레터링 (Office Lettering) 서비스 개시 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2009.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2008")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("05월")]),_vm._v(" 국내 최초 기업용 레터링(Lettering) 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상(3년연속) ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2008.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2007")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" SK텔레콤과 함께 FMC(Fixed Mobile Converngence) 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("07월")]),_vm._v(" 국내최초 WCDMA-유선전화 간 영상전화 연동 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상(2년연속) ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("02월")]),_vm._v(" 세계 최초 모바일 T-커머스 서비스 제공 (CJ 홈쇼핑 채널) ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2007.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2006")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 국가 브랜드경쟁력지수(NBCI) 국제전화서비스부문 1위 수상 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" 네이트온폰(웹 포탈 소프트 폰) 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 국가고객만족도(NCSI) 국제전화서비스부문 1위 수상 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2006.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2005")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("12월")]),_vm._v(" 1599전국대표번호 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("12월")]),_vm._v(" 인터넷전화 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("11월")]),_vm._v(" 국가 브랜드경쟁력지수(NBCI) 국제전화서비스 부문 1위 수상 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 인터넷전화/시내부가 기간 사업 권 획득 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("02월")]),_vm._v(" SK시외전화 서비스 개시 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2005.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2004")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 시외전화 기간사업자 면허 취득 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" 국제 SMS 서비스 개시 (국내최초) ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2004.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2003")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("08월")]),_vm._v(" SK Bizlink VPN 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" SK Bizlink SMS 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 국제전화 기간사업자 면허 취득 (식별번호 006) ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2003.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2002")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("11월")]),_vm._v(" SK 전용회선 서비스 제공 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2002.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2001")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("05월")]),_vm._v(" SK Bizlink 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" VoIP Co-Location 서비스 개시 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2001.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("2000")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("06월")]),_vm._v(" 부산지역 SK Express 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("03월")]),_vm._v(" 인마셋 서비스 개시 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history2000.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("1999")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("12월")]),_vm._v(" 신규 법인접속교환기(ETS) 개통 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" 국제 관문국 (IGW) 이설 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history1999.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history_list"},[_c('div',{staticClass:"txt_box"},[_c('h4',[_vm._v("1998")]),_c('ul',[_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("11월")]),_vm._v(" SK 국제전화 선불카드 판매 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("10월")]),_vm._v(" SK Express 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" ‘SK 국제전화’ 상용 서비스 개시 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("09월")]),_vm._v(" 국제전화 식별번호 (00700) 취득 ")]),_c('li',[_c('strong',{staticClass:"txt_point01"},[_vm._v("04월")]),_vm._v(" SK텔링크주식회사 설립 ")])])]),_c('div',{staticClass:"img_box"},[_c('img',{attrs:{"src":"/content/images/img_history1998.png","alt":""}})])])
}]

export { render, staticRenderFns }