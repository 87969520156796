<template>
  <div id="ADVERTISEMENT_AUDIO">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg06">
          <h3 class="title">
            광고
          </h3>
          <p class="txt">
            미디어속 SK텔링크의<br> 광고 이야기를 전해드립니다.
          </p>
          <img
            src="/content/images/sub_header_cp06.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- tab -->
        <div class="tab_type01 tab3">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li>
              <router-link to="/view/media/advertisement_video">
                영상 광고
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/media/advertisement_audio">
                오디오 광고
              </router-link>
            </li>
            <li>
              <router-link to="/view/media/advertisement_paper">
                지면 광고
              </router-link>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <div class="movie_list">
            <ul>
              <li>
                <div class="movie">
                  <iframe
                    title="YouTube 오디오 플레이어"
                    src="https://www.youtube.com/embed/FcWbLslemE4"
                    allowfullscreen=""
                  />
                </div>
                <div class="info">
                  <dl>
                    <dt>[SK텔링크]국제전화 00700 나라별</dt>
                    <dd>중국! 전화하께~ 00700<br>브라질! 전화하ㄹㄹ리~오~ 00700<br>이탈리아! 눌러보리~따~ 00700<br><br>전세계 어디든 국가대표 국제전화 00700</dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl'

export default {
  name: 'AdvertisementAudio',
  metaInfo: {
    title: '오디오 광고 | 광고 | 미디어센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>

