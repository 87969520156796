
const convertStringToDate = str => {
  return new Date(`${str.substr(0,4)}/${str.substr(4,2)}/${str.substr(6,2)} ${str.substr(8,2)}:${str.substr(10,2)}:${str.substr(12,2)}`)
}

const convDateToFullDateFormat = (str, hour) => {
  const week = ['일', '월', '화', '수', '목', '금', '토']
  let date = convertStringToDate(str)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const dayLabel = week[date.getDay()]
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM'
  let hours = date.getHours()
  hours = hours % 12
  hours = hours ? hours : 12
  hours = ('0' + hours).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)
  const seconds = ('0' + date.getSeconds()).slice(-2)
  return `${date.getFullYear()}.${month}.${day}(${dayLabel}) ${ampm} ${hours}:${minutes}:${seconds}`
}

const copyDifferentlyData = data => {
  return JSON.parse(JSON.stringify(data))
}

const convDateToSimpleDateFormat = str => {
  const date = convertStringToDate(str)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const day = ('0' + date.getDate()).slice(-2)
  const hour = ('0' + date.getHours()).slice(-2)
  const minutes = ('0' + date.getMinutes()).slice(-2)
  return `${date.getFullYear()}.${month}.${day} ${hour}:${minutes}`
}

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

import { messageList } from './message.js'
import { getBrowserIEVersion } from './browser.js'
import { setCookie, getCookie } from './cookie.js'

export default {
  convertStringToDate,
  convDateToFullDateFormat,
  copyDifferentlyData,
  convDateToSimpleDateFormat,
  messageList,
  getBrowserIEVersion,
  setCookie,
  getCookie,
  numberWithCommas
}