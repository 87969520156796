<template>
  <div>
    <!-- to개발 / 2020.11.09 ~ 2021.01.19 -->
    <div
      class="body-wrap"
    >
      <div class="terms_contents">
        <div class="terms_body">
          <div
            id="terms_01"
            class="article-box"
          >
            <strong>1. 총칙</strong>
            <p class="mark_item">
              본 「개인정보처리방침」에서 "개인정보"라 함은 살아있는 개인에 관한 정보로서 다음 중 어느 하나에 해당하는 정보를 말합니다.<br>
              1) 이름, 주민등록번호 등에 의하여 개인을 알아볼 수 있는 정보<br>
              2) 해당 정보만으로는 특정 개인을 알아볼 수 없어도 다른 정보와 쉽게 결합하여 알아볼 수 있는 정보<br>
              3) 위 1) 또는 2)의 정보를 가명처리함으로써 원래의 상태로 복원하기 위한 추가 정보의 사용•결합 없이는 특정 개인을 알아볼 수 없는 정보(이하 '가명정보')
            </p>
            <p class="mark_item">
              SK텔링크는 개인정보 처리방침을 홈페이지(privacy.sktelink.com) 첫 화면에 공개함으로써 고객이 언제나 용이하게 볼 수 있도록 조치하고 있습니다.
            </p>
            <p class="mark_item">
              SK텔링크는 관련 법률 및 지침의 변경, 또는 내부 개인정보 운영방침의 변경에 따라 개인정보 처리방침을 개정할 수 있으며, 개정하는 경우 시행일자 등을 부여하여 개정된 내용을 확인할 수 있도록 하고 있습니다.
            </p>
            <p class="mark_item">
              SK텔링크의 개인정보 처리방침이 변경되는 경우에는 홈페이지(privacy.sktelink.com) 등을 통하여 변경 및 시행의 시기, 내용을 고객에게 공지합니다.
            </p>
          </div>
          <div
            id="terms_02"
            class="article-box"
          >
            <strong>2. 개인정보의 수집, 이용 목적, 항목 및 보유기간</strong>
            <p class="mark_item">
              SK텔링크는 본질적인 서비스 제공을 위한 ‘필수동의’와 고객 각각의 기호와 필요에 맞는 서비스를 제공하기 위한 ‘선택동의’로 구분하여 개인정보를 수집∙이용하고 있습니다. 선택동의에 동의하지 않은 경우에도 본질적인 서비스를 이용할 수 있습니다.
            </p>
            <p class="mark_item">
              SK텔링크는 고객의 사생활을 현저하게 침해할 우려가 있는 민감한 개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다. 다만, 서비스 제공을 위해 일부 민감정보를 필요•최소한으로 수집, 처리할 필요가 있는 경우 관련 법령의 제한에 따라 고객의 동의 등 필요한 조치를 거쳐 그 개인정보를 수집, 처리할 수 있습니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('1')"
              >
                <span><i>[개인정보의 수집, 이용목적, 항목 및 보유기간 (필수)]</i></span>
              </button>
            </div>
            <p>※ 가입 당시 정보뿐만 아니라 정보 수정으로 변경된 정보를 포함합니다.</p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('2')"
              >
                <span><i>[개인정보의 수집, 이용목적, 항목 및 보유기간 (선택)]</i></span>
              </button>
            </div>
            <p>※ 가입 당시 정보뿐만 아니라 정보 수정으로 변경된 정보를 포함합니다</p>
            <p class="mark_item">
              SK텔링크는 고객이 앱을 통한 서비스 가입 시 고객의 이동통신단말장치(스마트폰 등)에 저장된 정보 및 설치된 기능(이하 ‘단말기 정보 등’이라 합니다)에 접근하여 개인정보를 수집할 경우, 사전에 ‘단말기 정보 등’에 접근한다는 것을 고지하고, 고객이 개인정보 수집∙이용 동의를 한 범위 내에서 해당 개인정보를 수집, 이용합니다.
            </p>
            <p class="mark_item">
              SK텔링크는 법령에 따라 동의없이 수집할 수 있는 자동생성 정보(로그기록, 과금정보, 결제기록 등 서비스 계약 이행과정에서 자동적으로 생성되는 정보), 요금정산에 필요한 정보(요금내역, 납부∙미납사실, 미납액 등) 등을 필수동의 사항에 기재된 목적범위 내에서 수집하여 이용할 수 있습니다. 또한 고객이 선택동의에 동의할 경우 해당 목적범위 내에서도 이용할 수 있습니다.
            </p>
            <p class="mark_item">
              SK텔링크는 법령에 따라 수집 목적과 합리적으로 관련된 범위에서는 고객의 동의 없이 개인정보를 이용할 수 있으며, 이때 '당초 수집 목적과 관련성이 있는지, 수집한 정황 또는 처리 관행에 비추어 볼 때 예측 가능성이 있는지, 고객의 이익을 부당하게 침해하는지, 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지'를 종합적으로 고려합니다.
            </p>
            <p class="mark_item">
              SK텔링크는 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명 처리하여 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 처리할 수 있습니다. 가명정보 처리 및 위탁, 제3자 제공과 관련한 내용은 아래와 같으며, 이때 가명정보는 재식별되지 않도록 분리하여 별도 저장•관리하고 필요한 기술적•관리적 보호조치를 취합니다.
            </p>
            <div class="btn-in-box">
              <span><i>[가명정보의 처리 및 위탁, 제3자 제공] : 해당사항 없음</i></span>
            </div>
            <p class="mark_item">
              SK텔링크는 개인정보보호법 제 58조의 2에 따라 시간, 비용, 기술 등을 합리적으로 고려할 때 다른 정보를 사용하여도 더 이상 개인을 알아볼 수 없는 정보(통계자료 등)를 고객의 동의 없이 생성하여 이용 또는 제공할 수 있습니다.
            </p>
          </div>
			
          <div
            id="terms_03"
            class="article-box"
          >
            <strong>3. 개인정보 수집 방법 및 동의 방법</strong>
            <p class="mark_item">
              SK텔링크는 고객의 ‘개인정보 수집 및 이용’에 대한 동의 절차를 두고, 고객이 이에 대해 '동의한다' 또는 '동의하지 않는다' 를 체크하거나 버튼을 클릭할 수 있는 등의 절차를 마련하고 있습니다. '동의한다' 항목에 체크를 하거나 버튼을 클릭하면 개인정보 수집 및 이용에 대해 동의한 것으로 봅니다.
            </p>
            <p class="mark_item">
              고객은 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며, 동의를 거부할 경우 받는 별도의 불이익은 없습니다. 단, 필수동의 사항에 동의를 거부할 경우, 서비스 이용이 불가능하거나, 서비스 이용 목적에 따른 서비스 제공에 제한이 따르게 됩니다.
            </p>
            <p class="mark_item">
              SK텔링크는 고객이 [기본적인 서비스 제공을 위해 타 기관으로부터 제공받는 정보] 서비스이용 신청서, 명의변경 계약서 등 서면양식에 서명하거나, SK텔링크에서 운영하는 홈페이지, 앱, 상담게시판, 전화 • 팩스를 통한 본인 • 대리인 확인, 경품행사 응모, 제휴사로부터의 제공, 이동전화 및 유 • 무선 인터넷 서비스 사용 시 생성정보 수집 툴을 통한 방법, 휴대폰인증서비스를 위하여 본인확인기관으로부터 제공받는 방법 등으로 개인정보를 수집합니다.
            </p>
          </div>
          <div
            id="terms_04"
            class="article-box"
          >
            <strong>4. 개인정보 처리 업무의 위탁</strong>
            <p>
              SK텔링크는 업무위탁계약서 등을 통하여 위탁업무 수행목적 외 개인정보의 처리 금지에 관한 사항, 개인정보의 기술적, 관리적 보호조치에 관한 사항, 위탁업무의 목적 및 범위, 재위탁 제한에 관한 사항, 개인정보에 대한 접근 제한 등 안전성 확보 조치에 관한 사항, 위탁업무와 관련하여 보유하고 있는 개인정보의 관리현황 점검 등 감독에 관한 사항, 개인정보 사고시의 책임부담, 처리 종료 후의 개인정보의 반환 또는 파기 의무 등을 규정하고, 이를 준수하도록 관리하고 있습니다.
            </p>
            <p>
              SK텔링크는 보다 나은 서비스 제공, 고객편의 제공 등 원활한 업무 수행을 위하여 다음과 같이 개인정보 처리 업무를 외부 전문업체에 위탁하여 운영하고 있습니다. 수탁업체 리스트는 해당 서비스 변경 및 계약기간에 따라 변경될 수 있으며 변경 시 각 서비스에서 정한 방식을 통해 공지하고 특별히 정하지 않은 경우 공지사항을 통해 공지합니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('3')"
              >
                <span><i>[개인정보 처리 위탁]</i></span>
              </button>
            </div>
          </div>
          <div
            id="terms_05"
            class="article-box"
          >
            <strong>5. 수집한 개인정보의 제3자 제공</strong>
            <p>
              SK텔링크는 고객의 개인정보를 고객으로부터 동의 받거나 법령에 의해 허용된 범위 내에서 사용하며, 동 범위를 넘어 이용하거나 제3자에게 제공하지 않습니다. 단, SK텔링크는 법령에 따라 수집 목적과 합리적으로 관련된 범위에서는 고객의 동의 없이 개인정보를 제공할 수 있으며, 이때 '당초 수집 목적과 관련성이 있는지, 수집한 정황 또는 처리 관행에 비추어 볼 때 예측 가능성이 있는지, 고객의 이익을 부당하게 침해하는지, 가명처리 또는 암호화 등 안전성 확보에 필요한 조치를 하였는지'를 종합적으로 고려합니다.<br>
              특히, 다음의 경우에는 위와 같은 범위 내에서 주의를 기울여 개인정보를 제공할 수 있습니다.
            </p>
            <ol>
              <li>
                가. 제휴관계 <br> 보다 나은 서비스 제공을 위하여 고객의 개인정보를 제휴사에게 제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를 제공하거나 공유할 경우에는 사전에 고객께 제휴사가 누구인지, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게 보호∙관리되는지에 대해 개별적으로 서면 또는 전자우편 등을 통해 고지하여 동의를 구하는 절차를 거치게 되며, 고객께서 동의하지 않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다. 제휴관계에 변화가 있거나 제휴관계가 종결될 때도 같은 절차에 의하여 고지하거나 동의를 구합니다.
              </li>
              <li>
                나. 매각 · 인수합병 등<br> 영업의 전부 또는 일부를 양도하거나, 합병•상속 등으로 서비스제공자의 권리 • 의무를 이전 승계하는 경우 개인정보보호 관련 고객의 권리를 보장하기 위하여 반드시 그 사실을 고객에게 통지합니다.
              </li>
              <li>
                다. 법령에 의해 필요하거나 허용되는 경우<br>
                <p class="mark_item">
                  • 요금정산을 위하여 필요한 경우
                </p>
                <p class="mark_item">
                  • 통신비밀보호법, 국세기본법, 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보 보호법, 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한 법률, 전기통신기본법, 전기통신사업법, 지방세법, 소비자기본법, 한국은행법, 형사소송법 등 다른 법률에 특별한 규정이 있는 경우 <br>
                  ※ 단, '법률에 특별한 규정이 있는 경우'로 행정목적이나 수사목적으로 행정관청 또는 수사기관이 요구해 온 경우라도 무조건 고객의 개인정보를 제공하지 않으며, 영장 또는 기관장의 직인이 날인된 서면에 의한 경우 등 법률에서 규정한 적법한 절차에 따라 제공합니다.
                </p>
              </li>
            </ol>
            <p>SK텔링크는 고객에 대하여 보다 질 높은 서비스 제공 등을 위해 아래와 같이 개인정보를 제공하고 있습니다.</p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('4')"
              >
                <span><i>[수집한 개인정보의 제3자 제공]</i></span>
              </button>
            </div>
          </div>
          <div
            id="terms_06"
            class="article-box"
          >
            <strong>6. 개인정보의 보관기간 및 이용기간</strong>
            <p>
              SK텔링크는 다른 내용으로 동의를 받거나 법령상의 근거가 있지 않는 한 원칙적으로 고객의 서비스 가입일로부터 고객에게 서비스를 제공하는 기간 동안에 한하여 고객의 개인정보를 보유 및 이용하게 됩니다. 고객이 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는 경우, 수집∙이용목적을 달성하거나 보유∙이용기간이 종료한 경우, 사업폐지 등의 파기사유 발생시 당해 개인정보를 지체 없이 파기합니다. 단, 이용요금의 정산, 소송이나 분쟁 등 기타 필요한 경우를 대비하여 보유하여야 할 필요가 있을 경우 개인정보는 해당 목적이 달성될 때까지 일정기간 보유합니다.
            </p>
            <p class="mark_item">
              • 상품 또는 서비스의 요금관련 분쟁 해결을 위해 계약 해지 후 6개월까지 보관 (단, 계약이 해지된 고객의 요금정산이 미완료된 경우는 해결 완료일로부터 6개월까지, 요금 관련 분쟁이 계속될 경우에는 해결 시까지)
            </p>
            <p>
              또한 예를 들어 상법, 국세기본법, 통신비밀보호법, 전자상거래 등에서 소비자보호에 관한 법률, 신용정보의 이용 및 보호에 관한 법률 등 관계법령에 따라 보존할 필요가 있는 경우 SK텔링크는 관계법령에서 정한 기간 동안 회원정보를 보관합니다. 이 경우 SK텔링크는 보관하는 정보를 그 보관의 목적으로만 이용하며 각 법령에 따른 보관기간의 예를 들면 다음과 같습니다.
            </p>
            <ul>
              <li>• 전기통신사업자가 통신사실확인자료 제공 시 필요한 성명, 주민등록번호, 연락처 : 12개월 (통신비밀보호법)</li>
              <li>• 전기통신사업자에게 보관의무가 있는 가입자의 전기통신 일시, 전기통신 개시∙종료시간, 통신번호 등 상대방의 가입자번호, 사용도수, 정보통신망에 접속된 정보통신기기의 위치자료 : 12개월 (통신비밀보호법)</li>
              <li>• 성명, 주민등록번호, (해지)이동전화번호, 청구서 배달 주소, 요금, 계약서 등 거래내역 관련 정보 : 5년(단, 해지후 6개월이 지난 고객의 개인정보는 별도의 분리된 저장공간에 보관) (국세기본법, 법인세법, 부가가치세법)</li>
              <li>• 상업장부와 영업에 관한 중요서류 및 전표 : 중요서류는 10년, 전표(상법)는 5년</li>
              <li>• 통신사실확인자료 제공시 필요한 로그기록자료, IP주소 등 : 3개월(통신비밀보호법)</li>
              <li>• 표시∙광고에 관한 기록 : 6개월(전자상거래 등에서의 소비자보호에 관한 법률)</li>
              <li>• 계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)</li>
              <li>• 대금결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래 등에서의 소비자보호에 관한 법률)</li>
              <li>• 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래 등에서의 소비자보호에 관한 법률)</li>
              <li>• 신용정보의 수집∙처리 및 이용 등에 관한 기록 : 3년(신용정보의 이용 및 보호에 관한 법률)</li>
            </ul>
          </div>
          <div
            id="terms_07"
            class="article-box"
          >
            <strong>7. 개인정보의 파기절차 및 방법</strong>
            <p>
              SK텔링크는 수집한 개인정보의 이용목적이 달성된 후에는 보관기간 및 이용기간에 따라 해당 정보를 지체없이 파기합니다. 파기절차, 방법, 시점은 다음과 같습니다.
            </p>
            <ol>
              <li>
                <strong>가. 파기절차 및 시점</strong> <br>
                <p>
                  고객이 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지 등으로 이용목적이 달성된 후 서비스의 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보관기간 및 이용기간 참조) 허용된 기간 동안 저장된 후 삭제하거나 파기합니다.
                </p>
                <p>
                  웹회원 정보는 서비스 해지 또는 회원 탈퇴 시점에 바로 파기합니다.
                </p>
                <p>
                  고객이 이동통신단말장치(스마트폰 등)에서 앱을 삭제하더라도 개인정보 동의 철회(회원탈퇴) 요청을 하지 않을 경우 해당 개인정보는 여전히 서비스에 남아있을 수 있으므로, 개인정보 파기를 원하시면 반드시 동의 철회(회원탈퇴) 요청을 하여야 합니다.
                </p>
              </li>
              <li>
                <strong>나. 파기방법</strong> <br>
                <p>
                  가입신청서 등 서면양식에 기재하거나, 종이에 출력된 개인정보는 분쇄기로 분쇄 또는 소각하거나 화학약품 처리를 하여 용해하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
                </p>
              </li>
              <li>
                <strong>다. 특정 기간 서비스 미이용자의 개인정보 분리보관 또는 파기</strong> <br>
                <p>
                  SK텔링크는 다른 법령 또는 이용자의 요청에 따라 달리 정한 경우가 아닌 한 1년 간 서비스 접근 로그가 없는 웹회원 정보는 파기합니다.
                </p>
                <p>
                  SK텔링크는 상기 파기일의 30일전까지 서비스에서 수집한 연락처로 개인정보의 파기사실을 통지합니다. 단, 서비스에서 연락처를 수집하지 않는 경우에는 본 개인정보 처리방침 내용의 공개로 통지를 갈음합니다.
                </p>
              </li>
            </ol>
          </div>
          <div
            id="terms_08"
            class="article-box"
          >
            <strong>8. 고객의 권리, 의무와 행사 방법</strong>
            <ol>
              <li>
                <strong>가. 고객의 개인정보 보호 관련 권리 </strong> <br>
                <p>
                  고객은 언제든지 본인에 관해 SK텔링크가 보유하고 있는 고객의 개인정보 현황, SK텔링크가 개인정보를 이용하거나 제3자에게 제공한 현황, 개인정보의 수집∙이용∙제공 등의 동의 현황을 열람하거나 제공받을 수 있습니다. SK텔링크는 고객이 개인정보에 대한 열람∙제공 또는 정정을 요구하는 경우에는 고객의 요구에 10일 이내에 대응하고, 해당 개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는 등 정정∙삭제를 할 필요가 있다고 인정되는 경우에는 지체 없이 정정∙삭제를 합니다.
                </p>
                <p>
                  고객은 개인정보 수집∙이용 또는 제공에 대한 동의철회(가입∙해지)를 요구할 수 있고 SK텔링크는 지체 없이 필요한 조치를 취합니다. 고객은 언제든지 개인정보 수집•이용•제공 등의 동의를 철회할 수 있습니다. 다만, 회원가입 및 본질적 서비스 제공에 필요한 개인정보 등에 대한 동의를 철회하는 경우 회원탈퇴 및 서비스 해지가 불가피하여, 고객에게 제공되는 서비스를 받지 못하게 될 수 있습니다.
                </p>
              </li>
              <li>
                <strong>나. 고객의 권리행사 방법 </strong> <br>
                <p>
                  고객은 대상 서비스의 성격에 따라 다음 중 한 가지 방법으로 위와 같은 권리를 행사할 수 있습니다. 대상 서비스 또는 개인정보의 성격에 따라, 또는 고객정보 보호의 필요성에 따라 일부 방법은 제한될 수 있으며, SK텔링크는 위와 같은 권리를 행사하는 자가 고객 본인 또는 고객의 적법한 대리인인지 여부를 신분증명문서(주민등록증, 외국인등록증, 여권, 운전면허증) 및 권리증명문서(위임장, 본인 및 대리인의 인감증명서 등)를 통해 확인할 수 있습니다.
                </p>
                <ul>
                  <li>- SK텔링크와 위탁업무계약을 맺은 지점, 대리점 또는 고객센터 방문, 전화</li>
                  <li>- 홈페이지 내 회원정보변경 메뉴를 클릭하거나 공지된 연락처로 서면, 전화, 전자우편 발송하여 문의</li>
                </ul>
                <p>
                  SK텔링크는 개인정보의 전부 또는 일부에 대하여 열람∙증명 또는 정정요청을 거절할 정당한 이유가 있는 경우에는 고객에게 이를 통지하고 그 이유를 설명합니다. 고객은 회사의 열람 등의 요청 거절 시 이의제기를 할 수 있으며, 이의제기 방법은 개인정보보호 담당자 메일로 접수하시면 신속∙정확한 답변을 드리겠습니다.
                </p>
              </li>
              <li>
                <strong>다. 고객의 개인정보 보호 관련 의무 </strong> <br>
                <p>
                  고객은 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고 예방을 위해 노력하여야 합니다. 고객이 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 고객에게 있으며 타인 정보를 도용하거나 허위정보를 입력할 경우 회원자격상실을 포함하여 서비스 약관위반에 따른 제재가 따를 수 있습니다.
                </p>
                <p>
                  고객은 접속정보(ID, 패스워드 등)를 포함한 본인의 개인정보가 유출되지 않도록 공용 PC 사용 시 반드시 로그아웃을 하는 등 기본적인 주의를 기울여야 하고 다른 사람이 추측할 수 있는 쉬운 비밀번호는 사용을 피하여야 하며, 정기적으로 비밀번호를 변경하는 것이 바람직합니다.
                </p>
                <p>
                  고객은 게시물을 포함한 타인의 개인정보 및 권리를 침해, 훼손하지 않도록 유의하여야 합니다. 고객이 관계 법령을 위반하여 타인의 개인정보 및 권리를 침해, 훼손할 시에는 법적 책임이 따를 수 있습니다.
                </p>
              </li>
            </ol>
          </div>
          <div
            id="terms_09"
            class="article-box"
          >
            <strong>9. 아동의 개인정보보호 및 법정대리인의 권리</strong>
            <p>
              SK텔링크는 만 14세 미만 아동(이하 ‘아동’)의 개인정보 수집∙이용∙제공 등을 하는 경우 해당 아동의 법정대리인의 동의를 얻도록 하고 있습니다.
            </p>
            <p>
              SK텔링크는 아동의 서비스 이용 신청 시 법정대리인의 동의를 얻기 위하여 법정대리인의 성명, 연락처 등 필요한 최소한의 정보를 요구할 수 있습니다. 이 경우 개인정보의 수집∙이용 또는 제공 목적 및 법정대리인의 동의가 필요하다는 취지를 아동이 쉽게 이해 할 수 있는 평이한 표현으로 아동에게 고지 합니다.
            </p>
            <p>
              SK텔링크는 법정대리인의 동의를 얻기 위하여 수집한 법정대리인의 개인정보를 해당 법정대리인의 동의 여부를 확인하는 목적 외의 용도로 이를 이용하거나 제3 자에게 제공하지 않습니다. <br>아동의 법정대리인은 아동에 관해 SK텔링크가 보유하고 있는 개인정보 수집•이용•제공 동의를 철회할 수 있고, SK텔링크가 개인정보를 이용하거나 제3자에게 제공한 현황, 개인정보의 수집∙이용∙제공 등의 동의 현황을 열람하거나 제공받을 수 있으며, 오류가 있는 경우에는 그 정정을 요구할 수 있습니다.
            </p>
          </div>
          <div
            id="terms_10"
            class="article-box"
          >
            <strong>10. 개인정보의 자동수집장치의 설치 · 운영 및 그 거부에 관한 사항</strong>
            <p>
              SK텔링크는 고객에게 제공하는 서비스를 통하여 고객의 선호사항 등을 기억하거나 서비스를 효율적으로 제공하기 위하여 개인정보를 저장하고 조회하는 쿠키(cookie)(이하 ‘쿠키’)를 주의깊게 설치∙운용할 수 있습니다. SK텔링크는 고객이 서비스에 접속하면 고객의 브라우저에 있는 쿠키의 내용을 읽고, 추가정보를 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.
            </p>
            <p>
              SK텔링크가 쿠키를 통해 수집한 고객의 정보는 다음의 목적 등을 위해 사용될 수 있습니다.
            </p>
            <ul>
              <li>• 개인의 관심 분야에 따라 차별화된 정보를 제공</li>
              <li>• 관심 있게 둘러본 내용들에 대한 자취를 추적하여 다음 번 접속 때 개인 맞춤 서비스를 제공</li>
              <li>• 유료서비스 이용 시 이용기간 안내</li>
              <li>• 회원들의 습관을 분석하여 서비스 개편 등의 척도로 활용</li>
            </ul>
            <p>
              고객은 서비스에서 제공하는 방식에 따라 쿠키 설치에 대해 선택할 수 있습니다. 예시적으로 웹 브라우저 상단의 “도구 &gt; 인터넷옵션 &gt; 개인정보 &gt; 고급”에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될 때 통지를 보내도록 하거나, 또는 모든 쿠키를 거부할 수 있습니다.
            </p>
          </div>
          <div
            id="terms_11"
            class="article-box"
          >
            <strong>11. 개인정보보호를 위한 기술적 · 관리적 대책</strong>
            <ol>
              <li>
                <strong>가. 기술적 대책</strong><br> SK텔링크는 고객의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 아래와 같은 기술적 대책을 강구하고 있습니다.
                <ul>
                  <li>• 고객의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.</li>
                  <li>• 개인정보 처리에 이용하는 정보기기에 백신프로그램 등을 이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.</li>
                  <li>• 암호알고리즘 등을 이용하여 네트워크 상에서 개인정보를 안전하게 전송할 수 있는 보안장치를 채택하고 있습니다.</li>
                  <li>• 해킹 등 외부침입에 대비하여 침입차단시스템 및 침입탐지시스템을 운영하여 사내 네트워크를 보호하고, 각 서버마다 접근제어시스템을 설치하여 보안을 강화하고 있습니다.</li>
                </ul>
              </li>
              <li>
                <strong>나. 관리적 대책</strong><br>
                <ul>
                  <li>• 개인정보의 안전한 보호를 위하여 주요 시스템 및 설비에 대하여 외부 전문기관으로부터 정보보호관리체계 인증 등 객관적인 인증을 받아 운영되고 있습니다.</li>
                  <li>• 고객의 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하여 소속 직원으로 하여금 이를 숙지하고 준수하도록 하고 있습니다.</li>
                  <li>
                    • 고객의 개인정보를 처리할 수 있는 자를 최소한으로 제한하고 있습니다. 고객의 개인정보를 처리할 수 있는 자는 아래와 같습니다.
                    <ul>
                      <li>- 고객을 직∙간접적으로 상대로 하여 마케팅 업무를 수행하는 자</li>
                      <li>- 개인정보보호책임자 및 개인정보보호담당자 등 개인정보관리∙보호업무를 수행하는 자</li>
                      <li>- 기타 업무상 개인정보의 처리가 불가피한 자</li>
                    </ul>
                  </li>
                  <li>• 컴퓨터를 이용하여 고객의 개인정보를 처리하는 경우 개인정보에 대한 접근권한을 가진 담당자를 지정하여 식별부호(ID) 및 비밀번호를 부여하고, 해당 비밀번호를 정기적으로 갱신하고 있습니다.</li>
                  <li>• 개인정보를 처리하는 직원을 대상으로 새로운 보안기술 습득 및 개인정보보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다.</li>
                  <li>• 신규직원 채용 시 정보보호서약서 또는 개인정보보호서약서에 서명하게 함으로써 직원에 의한 정보유출을 사전에 방지하고 개인정보처리방침에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하여 지속적으로 시행하고 있습니다.</li>
                  <li>• 직원 퇴직 시 비밀유지서약서에 서명하게 함으로써 고객의 개인정보를 처리하였던 자가 직무상 알게 된 개인정보를 훼손∙침해 또는 누설하지 않도록 하고 있습니다.</li>
                  <li>• 개인정보 처리자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며, 입사 및 퇴사 후 개인정보 침해사고에 대한 책임을 명확하게 규정하고 있습니다.</li>
                  <li>• 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여 출입을 통제하고 있습니다.</li>
                  <li>• 서비스이용계약체결 또는 서비스제공을 위하여 고객의 신용카드번호, 은행결제계좌 등 대금결제에 관한 정보를 수집하거나 고객에게 제공하는 경우 당해 고객이 본인임을 확인하기 위하여 필요한 조치를 취하고 있습니다.</li>
                  <li>• 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해 개인정보의 상실, 유출, 위조, 변조, 훼손이 유발될 경우 SK텔링크는 즉각 고객에게 사실을 알리고 적절한 대책과 보상을 강구할 것입니다.</li>
                </ul>
              </li>
            </ol>
          </div>
          <div
            id="terms_12"
            class="article-box"
          >
            <strong>12. 광고성 정보 전송</strong>
            <p>SK텔링크는 고객의 사전 동의 없이 영리목적의 광고성 정보를 전송하지 않습니다.</p>
            <p>SK텔링크는 신상품이나 이벤트 정보안내 등 고객 지향적인 마케팅을 수행하기 위하여 영리 목적의 광고성 정보를 전송하는 경우에는 광고성 정보 전송에 대한 고객의 사전 동의를 득하고 각 전송수단별로 아래와 같이 고객이 광고성 정보임을 쉽게 알아 볼 수 있도록 조치합니다.</p>
            <ul>
              <li>• 전자우편 : 제목란에 '(광고)' 또는 '(성인광고)'라는 문구를 제목란의 처음에 빈칸 없이 한글로 표시하고 이어서 전자우편 본문란의 주요 내용을 표시합니다. 단, 제목란 등에 광고임을 표시하기 어려운 전자매체를 통해 영리목적의 광고성 정보를 전송하는 경우에도 전송자의 명칭을 표기하는 등 필요한 조치를 취합니다.</li>
              <li>• 팩스∙휴대폰 문자전송 등 전자우편 이외의 방법 : 전송자의 명칭을 표기하는 등 필요한 조치를 취합니다.</li>
            </ul>
            <p>
              SK텔링크는 고객의 광고성 정보 수신동의 시, 수신거부 시, 수신동의 철회 시 해당 사실을 고객에게 해당 사실 발생일로부터 14일내에 고지합니다. 또한 고객이 광고성 정보 수신동의를 한 날로부터 매 2년 마다 고객의 광고성 정보 수신동의 사실을 고객에게 확인합니다.
            </p>
          </div>
          <div
            id="terms_13"
            class="article-box"
          >
            <strong>13. 의견수렴 및 불만처리</strong>
            <p>SK텔링크는 고객의 의견을 매우 소중하게 생각합니다. 고객께서 문의사항이 있을 경우 고객센터 등에 연락을 하여 문의하시면 신속∙정확한 답변을 드리겠습니다. 고객센터의 연락처는 다음과 같습니다.</p>
            <ul>
              <li>- 전화번호 : (이동전화서비스) 1599-0999 (유선전화,국제전화,모바일팩스) 1599-0070 </li>
              <li>
                - 사이버상담 : <br>
                <ul>
                  <li>- www.sktelink.com에 접속하신 후 고객센터 메뉴의 상담/안내에서 사이버 상담원에게 질문을 하실 수 있습니다.</li>
                  <li>- 구글플레이, 원스토어 등 앱 마켓에 접속하신 후 관련 서비스의 개발자에게 문의하기를 통해 질문을 하실 수 있습니다.</li>
                </ul>
              </li>
            </ul>
            <p>
              기타 개인정보침해로 인한 구제를 받기 위하여 개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에 분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타 개인정보침해의 신고∙상담에 대하여는 아래의 기관에 문의하시기 바랍니다.
            </p>
            <ul>
              <li>
                . 개인정보분쟁조정위원회 (<a
                  href="https://www.kopico.go.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                >https://www.kopico.go.kr</a>) : 1833-6972
              </li>
              <li>
                . 개인정보침해신고센터 (<a
                  href="https://privacy.kisa.or.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                >https://privacy.kisa.or.kr</a>) : 118
              </li>
              <li>
                . 대검찰청 사이버수사과 (<a
                  href="https://www.spo.go.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                >https://www.spo.go.kr</a>) : 1301
              </li>
              <li>
                . 경찰청 사이버안전국 (<a
                  href="https://cyberbureau.police.go.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                >https://cyberbureau.police.go.kr</a>) : 182
              </li>
            </ul>
          </div>
          <div
            id="terms_14"
            class="article-box"
          >
            <strong>14. 고객 개인정보의 국외 이전</strong>
            <p>
              SK텔링크는 필요한 경우 고객의 개인정보를 국외에 보관 및 처리위탁할 수 있습니다. 국외에 처리위탁∙보관하는 개인정보는 아래와 같습니다.
            </p>
            <div class="btn-in-box">
              [고객 개인정보의 국외 이전] : 해당사항 없음
            </div>
          </div>
          <div
            id="terms_15"
            class="article-box"
          >
            <strong>15. 개인정보보호책임자 및 담당자</strong>
            <p>
              SK텔링크는 고객이 안전하게 서비스를 이용할 수 있도록 개인정보 보호에 최선을 다하고 있습니다. SK텔링크는 고객의 개인정보 관련 문의, 불만처리, 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자 및 담당부서를 지정하여 고객의 요청에 신속하고 성실하게 답변해드리고 있습니다.
            </p>
            <p>
              개인정보보호 담당자 e-Mail : <a href="mailto:sktelinkprivacy@sk.com">sktelinkprivacy@sk.com</a>
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('5')"
              >
                <span><i>[개인정보보호 책임자 및 담당자 전체보기]</i></span>
              </button>
            </div>
            <p />
            <ul>
              <li>• 개인정보처리방침 공고일자 : 2020년 11월 9일</li>
              <li>• 개인정보처리방침 시행일자 : 2020년 11월 9일</li>
              <li>• 개인정보처리방침 버전번호 : V 2.7</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isVisible"
      id="baseLayer"
      class="baselayer"
      style="display: block;"
    >
      <!-- to개발 / popup01 개인정보의 이용목적, 수집항목 및 보유기간(필수) -->
      <section
        v-if="currentModal === '1'"
        id="modalLayerPopup"
        key="modal1"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block;"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1
              id="modalLayerPopup-tit"
              holds="true"
              tabindex="0"
            >
              개인정보의 이용목적, 수집항목 및 보유기간(필수)
            </h1>
            <button
              type="button"
              class="btn-close"
              @click="hide"
            >
              <i>닫기</i>
            </button>
          </header>
          <div
            class="ui-modal-cont"
            style="height: auto;"
          >
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width:40%;">
                  <col style="width:40%;">
                  <col style="width:20%;">
                </colgroup>
                <caption>필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적, 필수 수집 항목, 보유기간정보제공) </caption>
                <tbody>
                  <tr>
                    <th>수집 목적</th>
                    <th>수집 항목</th>
                    <th>보유 기간</th>
                  </tr>
                  <tr>
                    <td>
                      • 이동전화서비스, 부가서비스, 통신과금서비스 등 회사가 제공하는 제반서비스 제공<br>
                      • 서비스 제공을 위한 본인 식별∙인증<br>
                      • 서비스 품질 확인∙개선, 상담∙불만∙기기변경 처리<br>
                      • 요금 정산∙청구∙수납∙추심∙자산유동화<br>
                      • 연체 사실 통지
                    </td>
                    <td>이름(법인명), 생년월일, 성별, 이동전화번호, 주소, 전화번호, e-mail 주소, 계좌(카드)정보, 예금주(카드주) 정보, 단말기 정보(모델명, IMEI, USIM번호, MAC Address 등), 서비스 가입일∙해지일∙가입기간, 서비스 과금정보, 단말매매계약내역, 분할상환계약 내역, 통신과금서비스 거래정보, 휴대폰결제 비밀번호, 번호이동정보, 서비스 이용과정에서 생성되는 정보(발∙수신번호(통화상대방번호 포함), 통화시각, 사용도수, 서비스이용기록, 접속로그, 쿠키, 접속 IP 정보, 결제기록, 이용정지기록, 연체금액, 연체등록 예정일, 기타 요금 과금에 필요한 데이터 및 이를 조합하여 생성되는 정보 등), 미성년자 가입 시 법정대리인 정보(이름, 주민등록번호, 가족관계증명서)</td>
                    <td rowspan="3">
                      서비스 계약 전부 해지 후 6개월까지 (단, 아래의 경우는 각 정한 바에 따름)<br>
                      가. 법령에 따로 정한 경우에는 해당 기간까지<br>
                      나. 요금정산이 미완료된 경우, 정산완료일로부터 6개월까지<br>
                      다. 단말/요금보증보험을 가입한 경우, 보증보험에 따른 법률 관계가 종료된 날로부터 6개월까지<br>
                      라. 요금관련 분쟁이 계속될 경우에는 분쟁이 종료되는 때까지<br>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      • 명의도용 방지, 가입의사 확인<br>
                      • 보편적 역무 제공, 요금감면 서비스∙사무
                    </td>
                    <td><span class="highlight">고유식별정보</span>, 이름, 생년월일, 신분증 기재사항, 주소, 주민등록번호 대체수단, 이동통신사 정보</td>
                  </tr>
                  <tr>
                    <td>• 본인확인서비스</td>
                    <td><span class="highlight">고유식별정보</span>, 이름, 생년월일, 성별, 내∙외국인 여부, 신분증 기재사항, 주민등록번호 대체수단, 이동전화번호, 이동통신사 정보</td>
                  </tr>
                  <tr>
                    <td>• 이동전화 서비스∙단말대금 분할상환구매∙신용보험 계약의 가입∙유지∙관리(채권추심∙대위권 행사 등 포함), 금융사고 조사, 자산유동화, 당사 제휴카드 발급 여부 결정 등</td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 개인신용정보(연체정보 등 신용도 판단정보, 신용거래정보, 신용등급, 보증보험 가입정보 및 보험 가입∙유지∙관리 목적으로서의 조회 정보)<br> ※ 신용정보는 나이스평가정보, 한국정보통신진흥협회 또는 서울보증보험 등 신용조회회사, 신용정보집중기관 또는 보증보험회사(보증보험회사의 신용조회회사, 신용정보집중기관, 금융결제원 등을 통한 조회 포함)로부터 제공받습니다
                    </td>
                    <td>
                      정보 조회∙제공 동의의 효력기간은 동의서 제출시점부터 관련 계약∙정산∙대위권이 종료하는 시점까지. <br>단, 가입이나 거래가 거절되는 경우 상담 종료시점까지
                    </td>
                  </tr>
                  <tr>
                    <td>• 이용요금 미납으로 인한 해지 시 알림</td>
                    <td>이름, 직권해지예정일, 연체금액, 납부기한, 미성년자의 경우 법정대리인 이동전화번호 또는 서비스관리번호</td>
                    <td>해당업무 처리 완료시까지</td>
                  </tr>
                </tbody>
              </table>
							
              <h2>sk7mobile.com 홈페이지</h2>
              <table class="table_wid01 three">
                <colgroup>
                  <col style="width:40%;">
                  <col style="width:40%;">
                  <col style="width:20%;">
                </colgroup>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집·이용 항목</th>
                    <th style=" width: 150px; ">
                      보유 및 이용 기간
                    </th>
                  </tr>
                  <tr>
                    <td>회원가입(후불서비스 조회, 변경, 상담)</td>
                    <td>이름, 아이디, 패스워드, 휴대폰번호, e-mail 주소, 연계정보(CI), 만 14세 미만 아동일 경우 (법정대리인이름, 법정대리인연락처)</td>
                    <td>이동전화서비스 가입자가 회원가입 유지 시까지 (회원탈퇴 및 1년간 미로그인시 삭제)</td>
                  </tr>
                  <tr>
                    <td>이동전화서비스 온라인신청</td>
                    <td>이름, 주민등록번호, 신분증 발급일자, 본인인증(신용카드인증-카드번호,카드유효기간,카드비밀번호,범용공인-인증서암호번호), 휴대폰번호, 다른연락처, 이메일, 주소, 수령인이름, 수령인휴대폰번호, 수령인주소, 자동이체(신용카드-카드번호,카드유효기간,카드비밀번호, 계좌이체-예금주,은행명,계좌번호), 인증방법 (은행계좌번호, 신용카드번호, 휴대폰일련번호, KT합산청구번호, 지로번호), 연계정보 (CI) &amp; 중복확인정보 (DI), 미성년자일 경우 (법정대리인이름, 법정대리인주민등록번호, 가족 관계증명서, 주민등록등본, 법정대리인신분증발급일자)</td>
                    <td rownum="2">
                      가입개통 미진행시 신청작성 3개월까지
                    </td>
                  </tr>
                  <tr>
                    <td>이동전화서비스 전화로신청</td>
                    <td>이름, 주민등록번호, 신분증 발급일자, 본인인증 (신용카드인증-카드번호,카드유효기간,카드비밀번호,휴대폰인증-휴대폰번호), 휴대폰번호, 다른연락처, 연계정보(CI)&amp;중복확인정보(DI), 미성년자일 경우(법정대리인이름,법정대리인주민등록번호,가족관계증명서,주민등록등본,법정대리인신분증발급일자)</td>
                  </tr>
                  <tr>
                    <td>휴대폰판매</td>
                    <td>이름, 휴대폰번호, 생년월일, 성별, 결제정보, 주소</td>
                    <td>. 계약 및 대금결제 기록 5년까지<br>. 소비자불만 / 분쟁 기록 3년까지</td>
                  </tr>
                  <tr>
                    <td>7멤버십</td>
                    <td>아이디, 연계정보(CI), 가맹점, 사용일시</td>
                    <td>회원가입 유지시까지</td>
                  </tr>
                  <tr>
                    <td>7멤버십 가까운 가맹점찾기</td>
                    <td>위치정보</td>
                    <td>위치확인후 삭제</td>
                  </tr>
                  <tr>
                    <td>비회원1:1상담</td>
                    <td>휴대폰번호, 비밀번호</td>
                    <td>등록후 2개월까지</td>
                  </tr>
                  <tr>
                    <td>비회원이벤트 (단말판매 등)</td>
                    <td>이름, 휴대폰번호, 주소</td>
                    <td>등록후 1개월까지</td>
                  </tr>
                  <tr>
                    <td>비회원이벤트 (구매후기)</td>
                    <td>이름, 휴대폰번호, 생년월일, 성별</td>
                    <td>등록후 3개월까지</td>
                  </tr>
                  <tr>
                    <td>선불서비스 충전</td>
                    <td>휴대폰번호, 충전방법(신용카드번호, 계좌번호, 선불(PPS)카드번호)</td>
                    <td>5년까지 (전자상거래 등에서의 소비자보호에 관한 법률)</td>
                  </tr>
                  <tr>
                    <td>해지 미환급금 조회 및 환불 신청</td>
                    <td>. 미환급액 조회시 : 이름, 주민등록번호<br>. 미환급액 환급 신청시 : 이름, 주민등록번호, 입금은행명, 계좌번호</td>
                    <td>. 미환급액 조회시 : 서비스 이용 후 즉시 폐기<br>. 미환급액 환급 신청시 : 신청내역 조회 및 민원처리를 위한 기간(30일) 경과 후 즉시 폐기</td>
                  </tr>
                </tbody>
              </table>
							
              <h2>유선전화</h2>
              <table>
                <colgroup>
                  <col style="width:40%;">
                  <col style="width:40%;">
                  <col style="width:20%;">
                </colgroup>
                <caption>필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적, 필수 수집 항목, 보유기간정보제공) </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>
                      • 유선전화서비스∙부가서비스 등 회사가 제공하는 제반서비스 가입/개통/장애/변경/해지 처리<br>
                      • 명의도용방지 및 가입자본인확인, 인증, 심사<br>
                      • 이용문의/민원처리, 물품배송<br>
                      • 서비스 품질개선활동(확인/점검)<br>
                      • 서비스요금 청구, 정산, 수납, 추심<br>
                      • 고지사항 안내<br>
                      • 요금감면서비스 제공, 요금감면에 관한 사무<br>
                      • 미납정보/스팸발송자 공동관리<br>
                      • 번호안내서비스 제공<br>
                      • 단말대금 보증보험 가입<br>
                      • 수사기관 요청, 긴급통신 대응<br>
                      • 통신요금 연체사실 통지<br>
                      • 스팸, 부정가입 방지<br>
                    </td>
                    <td>
                      <span class="highlight">주민등록번호</span>(운전면허번호, 여권번호, 외국인등록번호 포함), 주민번호 대체수단, 신분증 기재사항, 이름, 생년월일, 주소, 전화번호, 이메일, 복지할인증명 등 각종 증명, 서비스 가입일∙해지일∙가입기간, 이용시간, 이용량, 이용금액, 결제기록, 이용정지/직권해지 기록, 발/착신전화번호, 고객상담녹취록, 단말기정보(설치장소 포함), 연체금액, 연체등록예정일 등
                    </td>
                    <td rowspan="3">
                      아래의 경우를 제외하고 서비스 계약 해지 시까지(단, 일부 서비스만 해지한 경우는 제외)<br>
                      가. 법령에 따로 정한 경우에는 해당 기간까지<br>
                      나. 요금관련 분쟁해결을 위해 해지 후 6개월까지(단, 서비스 계약이 해지된 고객이 요금정산이 미완료된 경우는 해결 완료일로부터 6개월 까지, 요금 관련 분쟁이 계속될 경우에는 해결 시까지)<br>
                      다. 스팸발송 사유로 가입제한된 자는 가입제한일로부터 12개월까지<br>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      • 청구요금 자동이체 처리
                      • 연체정보의 제공 및 채권추심을 위한 정보 제공
                    </td>
                    <td>이름, 생년월일, 전화번호, 고객과의 관계, 예금주(카드명의자)명, 금융기관명, 계좌(카드)번호, 카드유효기간</td>
                  </tr>
                  <tr>
                    <td>• 법정대리인에 대한 본인 확인 및 서비스제공 관련 필요한 의무 등 이행</td>
                    <td><span class="highlight">주민등록번호</span>, 이름, 고객과의 관계, 전화번호, 이메일</td>
                  </tr>
                </tbody>
              </table>
							
              <h2>sktelink.com 홈페이지</h2>
              <table>
                <colgroup>
                  <col style="width:40%;">
                  <col style="width:40%;">
                  <col style="width:20%;">
                </colgroup>
                <caption>필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적, 필수 수집 항목, 보유기간정보제공) </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>회원가입(요금조회, 요금제신청, 상담)</td>
                    <td>이름, 생년월일, 성별, 아이디, 비밀번호, 전화번호, e-mail 주소, 요금제신청 시 관련번호(아시아나회원번호, OK캐쉬백번호)</td>
                    <td>회원탈퇴, 동의철회 및 1년간 미로그인 시 즉시 파기</td>
                  </tr>
                  <tr>
                    <td>비회원1:1상담</td>
                    <td>이름, 휴대폰번호, 전화번호, 이메일, 비밀번호</td>
                    <td>등록후 2개월까지</td>
                  </tr>
                </tbody>
              </table>
											
              <h2>국제전화 앱</h2>
              <table>
                <colgroup>
                  <col style="width:40%;">
                  <col style="width:40%;">
                  <col style="width:20%;">
                </colgroup>
                <caption>필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적, 필수 수집 항목, 보유기간정보제공) </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>국제전화서비스 제공(통화량, 설문, 이벤트, 상담)</td>
                    <td>이동전화번호, 성별, 국적(내/외국인 여부), 이용목적, 설치경로</td>
                    <td>서비스 이용 기간<br>※ 회원 탈퇴 시, 고객민원 처리 등의 사유로 최대 3개월까지 보유</td>
                  </tr>
                </tbody>
              </table>
							
              <h2>모바일팩스 앱</h2>
              <table>
                <colgroup>
                  <col style="width:40%;">
                  <col style="width:40%;">
                  <col style="width:20%;">
                </colgroup>
                <caption>필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적, 필수 수집 항목, 보유기간정보제공) </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>팩스서비스 제공</td>
                    <td>이동전화번호, 모바일팩스이용번호, 접속일시</td>
                    <td>• 이용기간 : 신청일~해지일 (단, 일부 서비스만 해지시 제외)<br>• 보유기간 : 요금정산, 과오납 등 분쟁대비 해지 후 30일까지</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->
							
            <div class="btn-wrap">
              <button
                type="button"
                class="btn-line"
                holde="true"
                @click="hide"
              >
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      
      <!-- end // to개발 / popup01 개인정보의 이용목적, 수집항목 및 보유기간(필수) -->
					
      <!-- to개발 / popup02 개인정보의 이용목적, 수집항목 및 보유기간(선택) -->
      <section
        v-if="currentModal === '2'"
        id="modalLayerPopup"
        key="modal2"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block;"
      >	
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1
              id="modalLayerPopup-tit"
              holds="true"
              tabindex="0"
            >
              개인정보의 이용목적, 수집항목 및 보유기간(선택)
            </h1>
            <button
              type="button"
              class="btn-close"
              @click="hide"
            >
              <i>닫기</i>
            </button>
          </header>
          <div
            class="ui-modal-cont"
            style="height: auto;"
          >
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width:40%;">
                  <col style="width:40%;">
                  <col style="width:20%;">
                </colgroup>
                <caption>필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적, 필수 수집 항목, 보유기간정보제공) </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>이동전화서비스와 SK텔링크가 제공하는 다른 상품∙서비스간, 개인정보의 결합∙분석 및 이를 통한 개인맞춤∙연계서비스 혜택제공 및 고객센터 상담</td>
                    <td>이동전화서비스 이용 시 개인정보 수집∙이용에 동의한 모든 항목(서비스 이용기록 포함)</td>
                    <td>서비스 이용 기간 ※ 선택동의를 철회하고자 하는 경우 080-897-0999 을 통하여 가능</td>
                  </tr>
                </tbody>
              </table>
							
              <h2>국제전화 앱</h2>
              <table>
                <colgroup>
                  <col style="width:40%;">
                  <col style="width:40%;">
                  <col style="width:20%;">
                </colgroup>
                <caption>필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적, 필수 수집 항목, 보유기간정보제공) </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>국제전화 혜택/이벤트 안내</td>
                    <td>국제전화 앱 이용 시 개인정보 수집∙이용에 동의한 모든 항목</td>
                    <td>서비스 이용 기간<br>※ 회원 탈퇴 시, 고객민원 처리 등의 사유로 최대 3개월까지 보유</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->
							
            <div class="btn-wrap">
              <button
                type="button"
                class="btn-line"
                holde="true"
                @click="hide"
              >
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      
      <!-- end // to개발 / popup02 개인정보의 이용목적, 수집항목 및 보유기간(선택) -->
					
      <!-- to개발 / popup03 개인정보처리 업무의 위탁 -->
      <section
        v-if="currentModal === '3'"
        id="modalLayerPopup"
        key="modal3"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block;"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1
              id="modalLayerPopup-tit"
              holds="true"
              tabindex="0"
            >
              개인정보처리 업무의 위탁
            </h1>
            <button
              type="button"
              class="btn-close"
              @click="hide"
            >
              <i>닫기</i>
            </button>
          </header>
          <div
            class="ui-modal-cont"
            style="height: auto;"
          >
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width:60%;">
                  <col style="width:40%;">
                </colgroup>
                <caption>이동전화 개인정보처리 - 위탁한 업체, 위탁 업무 정보입니다.</caption>
                <tbody>
                  <tr>
                    <th>위탁한 업체</th>
                    <th>위탁 업무</th>
                  </tr>
                  <tr>
                    <td>SK텔레콤, SK주식회사C&amp;C, 한국비앤에스시스템</td>
                    <td>
                      • 고객관리시스템 운영<br>
                      • 이동통신서비스 제공을 위한 제반업무 수행 (통화품질 관리 및 개선업무, 고객민원 처리, 불법회선 관리, 회사의 요청에 의한 데이터 추출 등)
                    </td>
                  </tr>
                  <tr>
                    <td>아이브랩</td>
                    <td>홈페이지시스템 운영</td>
                  </tr>
                  <tr>
                    <td>SJ링크</td>
                    <td>전자문서시스템 운영</td>
                  </tr>
                  <tr>
                    <td>대리점 32개 (※ 1 : 대리점명 표하단)</td>
                    <td>가입, 변경, 해지, 번호이동, 요금수납, 기타 상담</td>
                  </tr>
                  <tr>
                    <td>F&amp;U신용정보</td>
                    <td>
                      • 이동전화 관련 각종 조회, 변경 등 각종 고객센터 업무<br>
                      • 통화내역 발급, 명의도용 접수, SMS 수신 확인<br>
                      • 통화품질 관련 고객 상담업무 처리<br>
                      • 요금청구서<br>
                      • 미납요금에 대한 채권추심 및 추심업무 관련 고객 상담<br>
                      • 해외 로밍 신청 접수 처리<br>
                      • 가입신청서 심사<br>
                      • 고객혜택 정보 및 광고수신 동의를 한 고객에 대한 SK텔링크의 상품, 서비스 홍보 활동<br>
                    </td>
                  </tr>
                  <tr>
                    <td>그린피플</td>
                    <td>요금조정, 청구서검증, 금액대사, 재고관리</td>
                  </tr>
                  <tr>
                    <td>대정해피서비스</td>
                    <td>가입정보확인</td>
                  </tr>
                  <tr>
                    <td>나이스평가정보</td>
                    <td>
                      • 실명인증서비스<br>
                      • 본인확인서비스 (휴대폰인증, 범용공인인증)<br>
                      • 아이핀서비스
                    </td>
                  </tr>
                  <tr>
                    <td>코리아크레딧뷰로</td>
                    <td>본인확인서비스 (신용카드인증)</td>
                  </tr>
                  <tr>
                    <td>케이에스넷</td>
                    <td>은행계좌 자동이체 신청 인증</td>
                  </tr>
                  <tr>
                    <td>케이에스넷, 갤럭시아컴즈</td>
                    <td>선불 이동전화서비스 충전 결제</td>
                  </tr>
                  <tr>
                    <td>버프리프</td>
                    <td>홈페이지 서비스 운영 (이동전화 가입, 상담, 배송, 이벤트 등)</td>
                  </tr>
                  <tr>
                    <td>우체국</td>
                    <td>청구서 배송, 유심∙단말기 배송</td>
                  </tr>
                </tbody>
              </table>
							
              <h2>유선전화</h2>
              <table>
                <colgroup>
                  <col style="width:60%;">
                  <col style="width:40%;">
                </colgroup>
                <caption>유선전화 개인정보처리 - 위탁 업체, 위탁 업무 정보입니다.</caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                  </tr>
                  <tr>
                    <td>나이스신용정보</td>
                    <td>서비스 고객상담업무(조회,변경,해지 등), 채권추심업무</td>
                  </tr>
                  <tr>
                    <td>대리점 51개 (※ 2 : 대리점명 표하단)</td>
                    <td>서비스의 가입∙변경∙해지 및 고객상담</td>
                  </tr>
                  <tr>
                    <td>F&amp;U신용정보</td>
                    <td>요금 청구서 발송</td>
                  </tr>
                  <tr>
                    <td>SK주식회사C&amp;C</td>
                    <td>영업전산 시스템 운영</td>
                  </tr>
                  <tr>
                    <td>아이브랩</td>
                    <td>홈페이지 시스템 운영</td>
                  </tr>
                  <tr>
                    <td>나이스평가정보</td>
                    <td>. 본인확인서비스 (휴대폰인증, 범용공인인증)<br>. 아이핀서비스</td>
                  </tr>
                  <tr>
                    <td>이케이네트웍스, 태광에스엠</td>
                    <td>인터넷전화 서비스 개통 및 유지보수</td>
                  </tr>
                  <tr>
                    <td>금융결제원, 케이에스넷, 고객자동납부신청은행∙카드사</td>
                    <td>이용요금 자동이체 처리를 위한 계좌∙카드 인증 및 자동이체 처리</td>
                  </tr>
                  <tr>
                    <td>그린피플</td>
                    <td>영업지원, 정산지원 등 각종 사무업무</td>
                  </tr>
                  <tr>
                    <td>대정해피서비스</td>
                    <td>가입정보확인</td>
                  </tr>
                  <tr>
                    <td>엠에프온</td>
                    <td>모바일팩스 (스마트폰 APP) 시스템 운영</td>
                  </tr>
                </tbody>
              </table>
											
              <h2>국제전화</h2>
              <table>
                <colgroup>
                  <col style="width:60%;">
                  <col style="width:40%;">
                </colgroup>
                <caption>국제전화 개인정보처리 - 위탁 업체, 위탁 업무 정보입니다.</caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                  </tr>
                  <tr>
                    <td>SK주식회사C&amp;C</td>
                    <td>영업전산 시스템 운영</td>
                  </tr>
                  <tr>
                    <td>아이브랩</td>
                    <td>앱 시스템 운영</td>
                  </tr>
                  <tr>
                    <td>나이스신용정보</td>
                    <td>서비스 고객상담, 국제전화 서비스 이용요금제 안내</td>
                  </tr>
												
                  <tr>
                    <td>SK엠앤서비스</td>
                    <td>국제전화 서비스 이용요금제 안내</td>
                  </tr>
                </tbody>
              </table>
              <h2>모바일팩스 앱</h2>
              <table>
                <colgroup>
                  <col style="width:60%;">
                  <col style="width:40%;">
                </colgroup>
                <caption>모바일팩스 앱 개인정보처리 - 위탁 업체, 위탁 업무 정보입니다.</caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                  </tr>
                  <tr>
                    <td>엠에프온</td>
                    <td>모바일팩스 (스마트폰 APP) 시스템 운영</td>
                  </tr>
                </tbody>
              </table>
              <p>※이동전화1:DY커뮤니케이션,G.N모바일,가양피씨에스,경원모바일,고고팩토리,대경네트웍스,동부통신,레드,롯데하이마트,마린쉬핑,밋업코리아,버프리프,베스트모바일,본베스트,부성인터내셔널,쎄븐모바일,아시아피플,에스에스텔레콤,에스와이에스리테일,엘디에스,유비로드,인스코리아,제이디엠,천안컴퍼니,태우통신식품,텔레센트로,파워콜,퍼스트상사,프리페이폰,플러스통신,하이프라자,해암</p>
              <p>※유선전화2:국제키폰,글로리시스템,나모,누리정보기술,다드림아이앤에스,다음텔레콤,대신네트웍스,대양통신,런시스템,리리프로젝트,마이이즈,미디어컴,미소정보통신,민들레,민텔링크,바로콜서비스,비트정보,비피에스테크,삼보테크,상록,선광네트,세연정보통신,스피드070,씨앤씨네트웍스,아이엔텔글로벌,아이티에스서비스,아이피정보통신,아인스네트워크,에스에스통신,에스케이티앤비,에이블솔루션,예지시스템,우성네트웍스,유리OA통신,유즈커뮤니케이션,이디지털큐브,이엔에프텔레콤,이지스,이케이네트웍스,정현에프엠씨,제이피에스,주야시스템,중앙씨앤씨,지에이치정보통신,케이넷통신기술,케이라인텔레콤,키텔스,텔레플러스,티에스컴즈,하나정보통신,휴컴</p>
            </div>
            <!-- // 약관내용 -->
							
            <div class="btn-wrap">
              <button
                type="button"
                class="btn-line"
                holde="true"
                @click="hide"
              >
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>	
      </section>
      
      <!-- end // to개발 / popup03 개인정보처리 업무의 위탁 -->
					
      <!-- to개발 / popup04 수집한 개인정보의 제3자 제공 -->
      <section
        v-if="currentModal === '4'"
        id="modalLayerPopup"
        key="modal4"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block;"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1
              id="modalLayerPopup-tit"
              holds="true"
              tabindex="0"
            >
              수집한 개인정보의 제3자 제공
            </h1>
            <button
              type="button"
              class="btn-close"
              @click="hide"
            >
              <i>닫기</i>
            </button>
          </header>
          <div
            class="ui-modal-cont"
            style="height: auto;"
          >
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <caption>제공받는 업체, 제공하는 항목, 제공받는 자의 이용목적, 보유 및 이용기간 에 따른 이동전화 개인정보 제 3자 제공에 관한 정보입니다.</caption>
                <colgroup>
                  <col style="width:20%;">
                  <col style="width:30%;">
                  <col style="width:30%;">
                  <col style="width:20%;">
                </colgroup>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <th>제공항목</th>
                    <th>제공목적</th>
                    <th>보유기간</th>
                  </tr>
                  <tr>
                    <td>서울보증보험 (서울보증보험(주)가 분리보관 정보 이용 및 신용조회회사(코리아크레딧뷰로, 나이스평가정보 등), 신용정보집중기관(한국신용정보원,금융결제원,한국기업데이터)에 조회 및 제공하는 것 포함)</td>
                    <td><span class="highlight">고유식별정보</span>(서울보증보험에 단순 전달), 이름, 이동전화번호, 서비스정보, 분할상환계약정보, 청구정보, 신용거래정보(신용보험가입정보 등), 신용도 판단정보(연체, 대금지급정보 등), 신용등급(평점)</td>
                    <td>서비스 이용요금 및 분할상환 채권보전을 위한 신용보험가입 인수심사, 운영관리, 채권추심, 신용정보의 집중관리ㆍ활용 및 교환</td>
                    <td rowspan="2">
                      보험 채권 소멸시까지<br>(단, 법령에 따로 정한 경우에는 해당기간까지, 신용보험 미가입시에는 제공 및 보유하지 않음)
                    </td>
                  </tr>
                  <tr>
                    <td>자산유동화법인 및 관련 수탁자(자산실사법인, 추심기관 등), 공공기관 (금융감독원 등)</td>
                    <td>이름, 생년월일, 주소, 서비스가입 및 분할 상환계약 정보, 납부정보 신용능력정보, 보험가입정보</td>
                    <td>자산유동화 관련 업무</td>
                  </tr>
                  <tr>
                    <td>한국인터넷진흥원</td>
                    <td>이동전화번호, 스팸발신번호, 스팸메시지 내용, 스팸수신시간</td>
                    <td>스팸신고 및 차단, 스팸발송으로 인한 이용 정지 혹은 해지사실의 타통신사에 의한 확인 등</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>한국통신사업자연합회</td>
                    <td><span class="highlight">주민등록번호</span>, 이름</td>
                    <td>미환급금 안내</td>
                    <td>미환급금 환급 완료 후 6개월까지</td>
                  </tr>
                  <tr>
                    <td>한국정보통신진흥협회, 타 통신사, 과학기술정보통신부</td>
                    <td><span class="highlight">고유식별정보</span>, 이름, 이동전화번호, 신분증 기재사항, 주소, 생년월일, 국적, 미납요금 금액</td>
                    <td>방송통신신용정보공동관리(식별, 체납, 복지, 외국인정보인증), 번호이동, 국제전화사기 공동대응, 명의도용예방, 명의도용방지, SMS, 우편 안내, 분실 단말기 공동관리, 스팸발송자, 서비스 가입제한</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>한국정보통신진흥협회, 이동통신사</td>
                    <td>이름, 연체정보등록예정일, 신용정보사 위임예정일, 통신권 중복가입방지정보(DI), 직권해지예정일, 연체금액, 납부기한, 미성년자의 경우 법정대리인 중복가입방지정보(DI), 이동전화번호 또는 서비스관리번호</td>
                    <td>통신연체사실통지, 이용요금 미납으로 인한 직권해지시 알림</td>
                    <td>해당 업무 처리 완료 시까지</td>
                  </tr>
                  <tr>
                    <td>나이스평가정보</td>
                    <td><span class="highlight">주민등록번호</span>, 이름, 중복가입확인정보(DI), 생년월일, 성별</td>
                    <td>내국인 실명 인증, 신용정보조회</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>신분증 발급기관(행정안전부, 법무부, 외교부, 경찰청)</td>
                    <td><span class="highlight">고유식별정보</span>, 이름, 신분증 기재사항</td>
                    <td>부정가입 방지</td>
                    <td>서비스 가입 처리 완료시까지</td>
                  </tr>
                  <tr>
                    <td>KT, SK브로드밴드, LGU+, 온세텔레콤, SK텔링크(국제전화 및 콜렉트콜 사업자), 삼성SDS, CJ헬로, 한국케이블텔레콤, 드림라인, SB인터렉티브(국제전화 사업자)</td>
                    <td>이름, 이동전화번호, 생년월일, 청구일자, 청구금액, 과금자료(착신번호, 통화일시, 발신번호, 통화시간), 미납금액 등</td>
                    <td>국제전화 및 콜랙트콜 이용에 따른 요금 정산</td>
                    <td>요금 징수 후 5년간</td>
                  </tr>
                  <tr>
                    <td>국세청</td>
                    <td><span class="highlight">주민등록번호</span></td>
                    <td>소득공제 대상 항목에 대한 소득공제 반영 신청</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>즉시납부/자동납부 카드사</td>
                    <td>카드번호</td>
                    <td>소득공제 대상 항목에 대한 소득공제 반영 신청</td>
                    <td>서비스제공기간</td>
                  </tr>
												
                  <tr>
                    <td>다날, KG모빌리언스, 페이레터, 갤럭시아컴즈, NHN한국사이버결제</td>
                    <td>이름, 이동전화번호, 생년월일, 성별, 이동전화 이용금액, 인증실패사유(한도초과 등), 통신과금서비스 이용가능고객 여부, 잔여한도 및 미납여부</td>
                    <td>통신과금서비스(휴대폰결제) 인증 및 이용금액 정산</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>케이에스넷, SK주식회사</td>
                    <td>예금주 생년월일, 계좌번호</td>
                    <td>은행계좌 자동이체 신청 시 계좌 인증</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>금융결제원, SK주식회사</td>
                    <td>이름, 이동전화번호, 예금주 생년월일, 계좌번호</td>
                    <td>은행계좌 자동이체 신청 및 인출 요청</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>금융결제원, 나이스평가정보</td>
                    <td>카드주 생년월일, 계좌∙카드번호</td>
                    <td>카드 자동결제 신청, 카드결제 요청, 은행계좌 송금처리</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>자동인출을 신청하신 은행</td>
                    <td>이동전화번호, 생년월일, 계좌번호</td>
                    <td>은행계좌 자동이체 처리</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>금융결제원, 케이에스넷</td>
                    <td>카드번호</td>
                    <td>카드 자동결제 요청</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>자동납부 신청 카드사</td>
                    <td>카드번호</td>
                    <td>카드 자동결제 처리</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>케이에스넷, 하나은행, 우리은행, 국민은행, 신한은행, 농협</td>
                    <td>이름</td>
                    <td>입금전용계좌 수취인조회</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>하나은행</td>
                    <td>이름, 생년월일, 성별, 이동전화번호</td>
                    <td>제휴요금제를 통한 요금할인 제공</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>한국정보통신산업협회, 나이스평가정보</td>
                    <td><span class="highlight">주민등록번호</span></td>
                    <td>실명인증 및 연계정보(CI)발급</td>
                    <td>실명인증 후 즉시 파기</td>
                  </tr>
                  <tr>
                    <td>번개장터</td>
                    <td>이동전화번호, 요금제, 가입일, 해지일, 정지일, 요금제변경일</td>
                    <td>번개장터 포인트적립, 수수료정산</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>한국통신사업자연합회</td>
                    <td>이름, 생년월일, 주소, 전화번호, 계좌/카드번호(뒷4자리)</td>
                    <td>번호이동업무</td>
                    <td>서비스제공기간</td>
                  </tr>
                </tbody>
              </table>
							
              <h2>유선전화</h2>
              <table>
                <caption>제공받는 업체, 제공하는 항목, 제공받는 자의 이용목적, 보유 및 이용기간 에 따른 이동전화 개인정보 제 3자 제공에 관한 정보입니다.</caption>
                <colgroup>
                  <col style="width:20%;">
                  <col style="width:30%;">
                  <col style="width:30%;">
                  <col style="width:20%;">
                </colgroup>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <th>제공받는 자</th>
                    <th>제공받는 자의 이용목적</th>
                    <th>제공받는 자의 보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>한국정보통신진흥협회, 과학기술정보통신부, 타 통신사</td>
                    <td><span class="highlight">고유식별정보</span>, 이름, 미납월수, 미납금액, 전화번호, 법인(사업자)등록번호, 이용정지/해지일자 및 사유, 주민등록상주소지</td>
                    <td>통신서비스이용자공동관리(미납정보등), 명의도용예방, 스팸발송자 공동관리(가입제한및이용정지/해지 사실확인등), 불법스팸 발송으로 이용정지/직권해지 사실의 타 통신사에 의한 확인</td>
                    <td>서비스 제공 기간</td>
                  </tr>
                  <tr>
                    <td>한국정보통신진흥협회, 이동통신사(SKT,KT,LGU+,MVNO사업자)</td>
                    <td>이름, 연체정보등록예정일, 제공기관, 중복가입확인정보 (공통DI)</td>
                    <td>통신요금 연체통지서비스</td>
                    <td>해당업무 처리완료시까지</td>
                  </tr>
                  <tr>
                    <td>서울보증보험</td>
                    <td><span class="highlight">고유식별정보</span>, 이름, 전화번호, 할부금액, 단말모델명, 가입일, 해지일</td>
                    <td>인터넷전화단말보증보험가입</td>
                    <td>할부보험채권소멸시까지</td>
                  </tr>
                  <tr>
                    <td>케이티스, 한국전화번호부</td>
                    <td>이름, 전화번호, 주소</td>
                    <td>전화번호안내(인터넷/음성/책자)</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>국세청</td>
                    <td><span class="highlight">주민등록번호</span></td>
                    <td>소등공제 대상 항목에 대한 소득공제 반영 신청</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>한국통신사업자연합회</td>
                    <td>이름, 생년월일, 전화번호, 사전선택 시외전화사업자</td>
                    <td>번호이동업무, 시외전화 사전선택제업무</td>
                    <td>서비스제공기간</td>
                  </tr>
                </tbody>
              </table>
              <h2>국제전화</h2>
              <table>
                <caption>제공받는 업체, 제공하는 항목, 제공받는 자의 이용목적, 보유 및 이용기간 에 따른 이동전화 개인정보 제 3자 제공에 관한 정보입니다.</caption>
                <colgroup>
                  <col style="width:20%;">
                  <col style="width:30%;">
                  <col style="width:30%;">
                  <col style="width:20%;">
                </colgroup>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <th>제공받는 자</th>
                    <th>제공받는 자의 이용목적</th>
                    <th>제공받는 자의 보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>SK플래닛</td>
                    <td>OK캐쉬백번호, 전화번호, 적립포인트</td>
                    <td>OK캐쉬백포인트 적립, 이벤트/혜택 기프티콘 발송</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>아시아나항공</td>
                    <td>아시아나항공회원번호, 전화번호, 적립마일리지</td>
                    <td>마일리지 적립</td>
                    <td>서비스제공기간</td>
                  </tr>
                  <tr>
                    <td>롯데카드</td>
                    <td>롯데카드번호, 전화번호, 적립포인트</td>
                    <td>롯데카드 포인트(롤라머니) 적립</td>
                    <td>서비스제공기간</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->
							
            <div class="btn-wrap">
              <button
                type="button"
                class="btn-line"
                holde="true"
                @click="hide"
              >
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      
      <!-- end // to개발 / popup04 수집한 개인정보의 제3자 제공 -->
					
      <!-- to개발 / popup05 의견 수렴 및 불만 처리 -->
      <section
        v-if="currentModal === '5'"
        id="modalLayerPopup"
        key="modal5"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block;"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1
              id="modalLayerPopup-tit"
              holds="true"
              tabindex="0"
            >
              의견 수렴 및 불만 처리
            </h1>
            <button
              type="button"
              class="btn-close"
              @click="hide"
            >
              <i>닫기</i>
            </button>
          </header>
          <div
            class="ui-modal-cont"
            style="height: auto;"
          >
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화, 유선전화, 국제전화앱, 모바일팩스앱</h2>
              <table>
                <colgroup>
                  <col style="width:50%;">
                  <col style="width:50%;">
                </colgroup>
                <caption>필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적, 필수 수집 항목, 보유기간정보제공) </caption>
                <tbody>
                  <tr>
                    <th>개인정보보호 책임자</th>
                    <th>개인정보보호 담당자</th>
                  </tr>
                  <tr>
                    <td>
                      성명, 직책 : 이정열 ICT Infra본부 본부장<br>
                      전화번호 : 이동전화 114, (국번없이) 1599-0999<br>
                      이메일 : sktelinkprivacy@sk.com
                    </td>
                    <td>
                      성명, 직책 : 이원희 ICT 보안팀 부장<br>
                      전화번호 : 이동전화 114, (국번없이) 1599-0999<br>
                      이메일 : sktelinkprivacy@sk.com
                    </td>
                  </tr>
                </tbody>
              </table>				
            </div>
            <!-- // 약관내용 -->
            <div class="btn-wrap">
              <button
                type="button"
                class="btn-line"
                holde="true"
                @click="hide"
              >
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <div
        class="modal-backdrop"
        style="display: block;"
      />
    <!-- end // to개발 / popup05 의견 수렴 및 불만 처리 -->
    </div>
  <!-- to개발 / 팝업 오픈 시 ui-modal 안에 팝업이 생기는 구조였음 -->
  <!-- end // to개발 / 2020.11.09 ~ 2021.01.19 -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentModal: '',
      isVisible: false
    }
  },
  methods: {
    show(id) {
      this.currentModal = id,
      this.isVisible = true
    },
    hide() {
      this.currentModal = '',
      this.isVisible = false
    }
  }
}
</script>

<style>

</style>