<template>
  <div id="header">
    <!-- begin skipNav 2021-02-19 -->
    <div class="skipNavi">
      <a href="#container">본문 바로가기</a>
    </div>
    <!-- end skipNav 2021-02-19 -->
    <div class="inner">
      <h1>
        <router-link to="/">
          <img src="/content/images/h1_logo.png" alt="SK telink" />
        </router-link>
      </h1>
      <div class="gnb-area">
        <div class="utill">
          <div class="web_list">
            <router-link to="/view/sitemap" class="quick">
              <img src="/content/images/header_btn_quick.png" alt="퀵메뉴" />
            </router-link>
            <router-link to="/eng/main" class="point_color"> ENG </router-link>
          </div>
          <div class="mobile_list">
            <div class="mobile-inner">
              <div class="top-info">
                <a href="#a">로그인</a>
              </div>
              <p>
                SK텔링크에 오신 것을 환영합니다<br />로그인 후 다양한 서비스를
                경험해 보세요
              </p>
              <div class="btns">
                <a href="#a">나의가입정보</a>
                <a href="#a">청구/납부내역</a>
                <a href="#a">자주하는 질문</a>
                <a href="#a">나의 1:1상담내역</a>
              </div>
              <a href="#" class="mobile-list-close">
                <img
                  src="/content/images/mobile/gnb_btn_close.png"
                  alt="메뉴닫기"
                />
              </a>
            </div>
          </div>
        </div>
        <div id="gnb" :class="{ activateGnb: activateGnb }">
          <a href="#" class="mobile-gnb-btn" @click="toggleGnb">
            <img src="/content/images/header_btn_nav.png" alt="메뉴" />
          </a>
          <div class="gnb-inner">
            <div class="gnb-inner-wrap">
              <div class="gnb-eng">
                <router-link to="/eng/main" class="point_color">
                  ENG
                </router-link>
              </div>
              <ul class="gnb_list" @mouseleave="gnbClose()">
                <template v-for="(oneDepth, index) in menuItems">
                  <li
                    v-if="oneDepth.isVisible === true"
                    id="comLi"
                    :key="index"
                    class="gnb-list-wrap has-sub"
                  >
                    <a
                      ref="gnbfocus"
                      href="#"
                      class="gnb-focus"
                      @mouseover="gnbOpen(index)"
                    >
                      {{ oneDepth.label }}
                    </a>
                    <span @click="gnbOpen(index)">{{ oneDepth.label }}</span>
                    <div
                      ref="gnbListArea"
                      class="gnb-list-area"
                      :class="{ open: currentOneIndex == index }"
                    >
                      <ul class="gnb-list-inner gnb-list--first">
                        <template
                          v-for="(twoDepth, index2) in oneDepth.children"
                        >
                          <li
                            v-if="twoDepth.isVisible === true"
                            :key="index2"
                            :class="{
                              'has-depth3': twoDepth.children.length > 0,
                              uTwoColumn: twoDepth.label === '국제전화 00700',
                            }"
                          >
                            <router-link :to="twoDepth.link">
                              {{ twoDepth.label }}
                            </router-link>

                            <span
                              v-if="twoDepth.children.length > 0"
                              @click="gnbOpenTwo"
                            >
                              {{ twoDepth.label }}
                            </span>
                            <span v-else>
                              <router-link :to="twoDepth.link">
                                {{ twoDepth.label }}
                              </router-link>
                            </span>

                            <ul
                              ref="gnbListSub"
                              class="gnb-list-sub"
                              :class="{ open: threeDepthOpen }"
                            >
                              <template
                                v-for="(
                                  threeDepth, index3
                                ) in twoDepth.children"
                              >
                                <li
                                  v-if="
                                    threeDepth.isVisible === true &&
                                    !threeDepth.outLink
                                  "
                                  :key="index3"
                                  :class="{
                                    uMobile:
                                      threeDepth.label === '국제전화 소개',
                                  }"
                                >
                                  <router-link :to="threeDepth.link">
                                    {{ threeDepth.label }}
                                  </router-link>
                                </li>
                                <li
                                  v-else-if="
                                    threeDepth.isVisible === true &&
                                    threeDepth.outLink
                                  "
                                  :key="index3"
                                  :class="{
                                    uMobile:
                                      threeDepth.label === '국제전화 소개',
                                  }"
                                >
                                  <a :href="threeDepth.outLink" target="_blank">
                                    {{ threeDepth.label }}
                                  </a>
                                </li>
                              </template>
                            </ul>
                          </li>
                        </template>
                      </ul>
                    </div>
                  </li>
                </template>
              </ul>
              <a href="#" class="mobile-close-btn" @click.prevent="closeGnb">
                <img
                  src="/content/images/gnb_btn_close.png"
                  alt="메뉴닫기"
                  data-pin-nopin="true"
                />
              </a>
            </div>
          </div>
        </div>
        <!-- gnb -->
      </div>
      <!-- 메인만 링크 노출 -->
      <a
        v-if="$route.name == 'main'"
        href="https://www.sk7mobile.com/main.do?refer=sktl"
        target="_blank"
        title="SK 알뜰폰 7mobile 새창열림"
        class="pop_focus"
      >
        <span
          >SK <em>7mobile</em>
          <!--to개발 / 2020-02-24 추가 --><br />바로가기 </span
        ><!-- to:개발 / 메인페이지서만 보야짐 -->
      </a>
    </div>
  </div>
</template>
<script>
import api from "@/api/index.js";

export default {
  name: "Header",
  data() {
    return {
      activateGnb: false,
      threeDepthOpen: false,
      menuItems: [],
      currentOneIndex: null,
    };
  },
  created() {
    window.addEventListener("resize", this.gnbClose);
    window.addEventListener("resize", this.init);
    api.Menu().then((response) => {
      this.menuItems = response.data;
      setTimeout(() => {
        this.init();
        this.activePage();
      }, 10);
    });
  },
  watch: {
    currentOneIndex() {
      if (this.currentOneIndex !== null) {
        this.$refs.gnbListArea[this.currentOneIndex].style.display = "block";
      } else {
        this.$refs.gnbListArea.forEach((item) => {
          item.style.display = "none";
        });
      }
    },
  },
  methods: {
    init() {
      const mobileMatch = window.matchMedia("screen and (max-width: 1023px)");
      if (mobileMatch.matches) {
        this.gnbSubClose();
      } else {
        this.$refs.gnbListSub.forEach((item) => {
          item.style.height = "auto";
        });
      }
    },
    toggleGnb() {
      this.activateGnb = this.activateGnb ? false : true;
    },
    closeGnb() {
      this.activateGnb = false;
    },
    gnbOpen(index) {
      this.currentOneIndex = index;
    },
    gnbClose() {
      this.currentOneIndex = null;
    },
    gnbOpenTwo(event) {
      const nextNode = event.target.nextElementSibling;
      const nextNodeChildLen =
        nextNode.parentNode.childNodes[2].childElementCount;
      if (nextNode.style.height !== "0px") {
        this.gnbSubClose();
      } else {
        this.gnbSubClose();
        setTimeout(() => {
          this.$refs.gnbListSub.forEach((item) => {
            nextNode.style.height = nextNodeChildLen * 42 + "px";
          });
        }, 1);
      }
    },
    gnbSubClose() {
      this.$refs.gnbListSub.forEach((item) => {
        item.style.height = 0 + "px";
      });
    },
    activePage() {
      this.menuItems.forEach((oneDepth, index) => {
        oneDepth.children.forEach((twoDepth, index2) => {
          if (
            twoDepth.link === this.$route.path ||
            twoDepth.name === this.$route.name
          ) {
            this.$refs.gnbfocus[index].classList.add("current");
          }
          twoDepth.children.forEach((threeDepth, index3) => {
            if (
              threeDepth.link === this.$route.path ||
              threeDepth.name === this.$route.name
            ) {
              this.$refs.gnbfocus[index].classList.add("current");
            }
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#header {
  z-index: 11;
}
.activateGnb {
  @media (max-width: 1023px) {
    .gnb-inner {
      display: block !important;
    }
    .gnb-inner-wrap {
      right: 0 !important;
    }
  }
}
#gnb .gnb_list {
  padding-left: 350px;
  @media (max-width: 1023px) {
    padding-left: 0;
  }
  .gnb-list-wrap {
    padding: 0;
    .gnb-focus {
      &.current {
        color: #ff630a;
        border-bottom: 3px solid #ff630a;
      }
    }
  }
}
#gnb .gnb-list-wrap .gnb-list-area {
  transition: all 0.3s linear;
  max-height: 0;
  // height: 0;
  overflow: hidden;
  line-height: 0;
  &.open {
    max-height: 630px;
  }
  .gnb-list-sub li.uMobile {
    display: none;
    @media (max-width: 1023px) {
      display: block;
    }
  }
}

@media (max-width: 1023px) {
  #gnb .gnb_list .gnb-list-wrap .gnb-list-inner li span a {
    display: block;
    margin-bottom: 0;
    height: 42px;
    line-height: 42px;
    color: #fff;
    font-weight: 300;
  }
  #header > div.skipNavi a {
    position: absolute;
    top: -99999px;
    right: auto;
    left: 0;
    width: 100%;
    height: auto;
    background: none;
    text-align: center;
  }
  #gnb .gnb-list-wrap .gnb-list-area {
    transition: all 0.35s linear;
    .gnb-list-inner ul.gnb-list-sub {
      display: block;
      transition: all 0.2s linear;
      height: 0;
      overflow: hidden;
      line-height: 0;
    }
  }
}
</style>
