<template>
  <div id="USAGE">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg1">
          <h3 class="title">
            사용내역 조회
          </h3>
          <p class="txt">
            고객님의 사용내역과 통화내역을<br>조회 할 수 있습니다.
          </p>
          <img
            src="/content/images/sub_header_my-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <div
          v-if="isVisibleResultData"
        >
          <!-- mypage -->
          <div
            v-if="usedCallNumResult.result_cd === '0001' || usedCallNumResult.result_cd === 'E013'"
            class="mypage"
          >
            <!-- 추가인증 -->
            <template v-if="!usedInfoResult.result_cd || usedInfoResult.result_cd === 'E020'">
              <template v-if="usedCallNumResult.result_cd !== 'E013'">
                <div class="sub_title cs">
                  <h4 class="title">
                    <span class="txt_point01">착신번호</span> 추가 인증
                  </h4>
                </div>
                <div class="sub_text">
                  <span class="txt_point01">사용내역 조회 서비스</span>는 개인정보보호를 위해 추가 인증을 진행합니다. <br>
                  고객님이 이용하신 착신번호(상대방 번호) 중 무작위로 제시된 하나의 <span class="txt_point01">착신번호 끝 4자리를 입력</span>해 주세요.
                </div>
                <div class="certification_area">
                  <div class="certification_inner">
                    <span class="certification_column">{{ usedCallNumResult.recv_no.split('****')[0] }}</span>
                    <span class="certification_column">
                      <input
                        ref="num1"
                        type="text"
                        minlength="1"
                        maxlength="1"
                        @input="inputCertCode"
                      >
                      <input
                        ref="num2"
                        type="text"
                        minlength="1"
                        maxlength="1"
                        @input="inputCertCode"
                      >
                      <input
                        ref="num3"
                        type="text"
                        minlength="1"
                        maxlength="1"
                        @input="inputCertCode"
                      >
                      <input
                        ref="num4"
                        type="text"
                        minlength="1"
                        maxlength="1"
                        @input="inputCertCode"
                      >
                    </span>
                  </div>
                  <div class="certification_button">
                    <button
                      class="btn_type01"
                      @click="certificate"
                    >
                      인증하기
                    </button>
                  </div>
                </div>
              </template>
            </template>

            <!-- 추가인증 후 노출 -->
            <!-- 착신번호 인증 성공시 -->
            <div
              v-if="usedInfoResult.result_cd === '0001' || usedInfoResult.result_cd === 'E014'"
              class="result_area"
            >
              <h4
                v-if="usedCallNumResult.cust_nm.length <= 8"
                class="title"
              >
                <span class="txt_point01">{{ usedCallNumResult.cust_nm }}고객님</span> 안녕하세요.
              </h4>
              <!-- 상단 고객명 노출 영역 고객명이 긴경우 2줄로 -->
              <h4
                v-if="usedCallNumResult.cust_nm.length > 8"
                class="title"
              >
                <span class="txt_point01">{{ usedCallNumResult.cust_nm }} 고객님</span><br> 안녕하세요.
              </h4>
              <!-- 입력 -->
              <div class="table_type05">
                <ul>
                  <li class="full">
                    <dl>
                      <dt>전화번호</dt>
                      <dd>
                        <div class="space_box">
                          <!-- 2020-06-29 개인정보 이슈로 인하여 전화번호 마스킹 -->
                          <span
                            id="hpNoSpan"
                            style="padding-left: 20px;"
                          >
                            {{ usedCallNumResult.svc_tel_no }}
                          </span>
                        </div>
                      </dd>
                    </dl>
                  </li>
                  <li class="full">
                    <dl>
                      <dt>통화종류</dt>
                      <dd>
                        <div class="select_box wid212">
                          <label
                            for="callType"
                            aria-hidden="true"
                          >전체</label>
                          <select
                            id="callType"
                            v-model="formData.call_tp"
                            name="callType"
                            title="통화종류 선택"
                          >
                            <option
                              v-for="(callTp, callTpIndex) in usedCallNumResult.call_tp_list"
                              :key="`callTp${callTpIndex}`"
                              :value="callTp.call_tp"
                            >
                              {{ callTp.call_tp_nm }}
                            </option>
                          </select>
                        </div>
                      </dd>
                    </dl>
                  </li>
                  <li class="full">
                    <dl>
                      <dt>기간 선택</dt>
                      <dd>
                        <div class="algin_box">
                          <div class="select_box wid212">
                            <label
                              for="i_sDateInfoFrom"
                              aria-hidden="true"
                            >조회기간</label>
                            <select
                              id="i_sDateInfoFrom"
                              v-model="formData.tgt_month"
                              name="i_sDateInfoFrom"
                              title="첫 조회기간"
                              class="select_type w_100p"
                              @change="calculatePrevAndNextMonth"
                            >
                              <option
                                v-for="(month, monthIndex) in usedCallNumResult.month_list"
                                :key="`month${monthIndex}`"
                                :value="month.month"
                              >
                                {{ month.month }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <span class="txt_em"><span class="icon_star">*</span>전월 기준 최근 6개월까지만 가능합니다.</span>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <!-- //입력 -->
              <ul class="txt_notice">
                <li><span class="icon_star">*</span>사용내역조회는 전월 기준 <span class="txt_point01">최근 6개월까지만 가능</span>합니다.</li>
                <li><span class="icon_star">*</span>사용내역은 조회 월 기준 전월 21일부터 당월 20일까지 조회된 결과입니다.(예: 7월 내역 - 6월 21일 ~ 7월20일)</li>
                <li><span class="icon_star">*</span>자세한 사항은 고객센터(1599-00700)로 연락바랍니다.</li>
                <li><span class="icon_star">*</span>부가세(VAT) 포함된 금액입니다.</li>
                <li><span class="icon_star">*</span>실제 청구되는 금액과 다소 차이가 있을 수 있습니다.</li>
              </ul>
              <div class="btn_center single line_top">
                <a
                  class="btn_type01 btn_search"
                  @click="certificate"
                >조회하기</a>
              </div>
              <!-- 통계 -->
              <div
                v-if="usedInfoResult.result_cd === '0001' || usedInfoResult.result_cd === 'E014'"
                class="use_area"
              >
                <div>
                  <h4 class="title">
                    월별사용내역요약
                  </h4>
                  <!-- 웹 월별 -->
                  <div class="use_list type02">
                    <button
                      v-if="prevMonth"
                      type="button"
                      class="use_prev"
                      @click="movePrevMonth"
                    >
                      이전
                    </button>
                    <div class="use_date">
                      <span
                        id="srch_date_area"
                        class="date"
                      >{{ `${formData.tgt_month.substr(0,4)}-${formData.tgt_month.substr(4,2)}` }}</span>
                    </div>
                    <button
                      v-if="nextMonth"
                      type="button"
                      class="use_next"
                      @click="moveNextMonth"
                    >
                      다음
                    </button>
                    <div class="table_type04">
                      <table class="style01">
                        <caption>통화구분, 통화건수, 통화시간(초), 과금액, 공제액, 청구액의 월별 사용내역 입니다.</caption>
                        <colgroup>
                          <col style="width:20%;">
                          <col style="width:20%;">
                          <col style="width:20%;">
                          <col style="width:20%;">
                        </colgroup>
                        <tbody id="using_info_area">
                          <tr>
                            <th scope="row">
                              통화구분
                            </th>
                            <td class="txt_point01">
                              전체통화
                            </td>
                            <td class="txt_point01">
                              국제통화
                            </td>
                            <td class="txt_point01">
                              국제SMS
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">
                              통화건수
                            </th>
                            <td>{{ usedDashboard.total.count | comma }}</td>
                            <td>{{ usedDashboard.inter.count | comma }}</td>
                            <td>{{ usedDashboard.sms.count | comma }}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              통화시간(초)
                            </th>
                            <td>{{ usedDashboard.total.time | comma }}</td>
                            <td>{{ usedDashboard.inter.time | comma }}</td>
                            <td>{{ usedDashboard.sms.time | comma }}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              과금액(원)
                            </th>
                            <td>{{ usedDashboard.total.rate | comma }}</td>
                            <td>{{ usedDashboard.inter.rate | comma }}</td>
                            <td>{{ usedDashboard.sms.rate | comma }}</td>
                          </tr>
                          <tr>
                            <th scope="row">
                              공제액(원)
                            </th>
                            <td>{{ usedDashboard.total.dedt | comma }}</td>
                            <td>{{ usedDashboard.inter.dedt | comma }}</td>
                            <td>{{ usedDashboard.sms.dedt | comma }}</td>
                          </tr>
                        </tbody>
                        <tfoot id="using_info_area2">
                          <tr>
                            <th scope="row">
                              청구액(원)
                            </th>
                            <td class="txt_point01">
                              <em>{{ usedDashboard.total.chrg | comma }}</em>원
                            </td>
                            <td class="txt_point01">
                              <em>{{ usedDashboard.inter.chrg | comma }}</em>원
                            </td>
                            <td class="txt_point01">
                              <em>{{ usedDashboard.sms.chrg | comma }}</em>원
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      <span class="txt_point01">부가세(VAT) 포함</span>
                    </div>
                  </div>
                  <!-- //웹 월별 -->
                  <!-- 모바일 월별 -->
                  <div class="table_slide type02">
                    <ul>
                      <li
                        :class="{ on: usedDashboard.currentView === 'total' }"
                        @click="usedDashboard.currentView = 'total'"
                      >
                        <div class="tit_box">
                          <h5 class="table_title">
                            전체통화
                          </h5>
                          <dl>
                            <dt>청구액</dt>
                            <dd><span class="txt_point01">{{ usedDashboard.total.chrg | comma }}원</span></dd>
                          </dl>
                          <span class="btn"><img
                            src="/content/images/btn_table_slide.png"
                            alt="펼쳐짐"
                          ></span>
                        </div>

                        <div
                          v-show="usedDashboard.currentView === 'total'"
                          class="txt_open"
                          style="display: block;"
                        >
                          <!-- to개발 / 슬락이드 오픈될때 display: block; -->
                          <ul class="table_list">
                            <li>
                              <dl>
                                <dt>&middot; 통화건수</dt>
                                <dd>{{ usedDashboard.total.count | comma }}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>&middot; 통화시간(초)</dt>
                                <dd>{{ usedDashboard.total.time | comma }}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>&middot; 과금액(원)</dt>
                                <dd>{{ usedDashboard.total.rate | comma }}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>&middot; 공제액(원)</dt>
                                <dd>{{ usedDashboard.total.dedt | comma }}</dd>
                              </dl>
                            </li>
                          </ul>
                          <span class="txt_em type02 align_r"><span class="icon_star">*</span>부가세 포함</span>
                        </div>
                      </li>
                      <li
                        :class="{ on: usedDashboard.currentView === 'inter' }"
                        @click="usedDashboard.currentView = 'inter'"
                      >
                        <div class="tit_box">
                          <h5 class="table_title">
                            국제전화
                          </h5>
                          <dl>
                            <dt>청구액</dt>
                            <dd><span class="txt_point01">{{ usedDashboard.inter.chrg | comma }}원</span></dd>
                          </dl>
                          <span class="btn"><img
                            src="/content/images/btn_table_slide.png"
                            alt="펼치기"
                          ></span>
                        </div>

                        <div
                          v-show="usedDashboard.currentView === 'inter'"
                          class="txt_open"
                          style="display: block;"
                        >
                          <ul class="table_list">
                            <li>
                              <dl>
                                <dt>&middot; 통화건수</dt>
                                <dd>{{ usedDashboard.inter.count | comma }}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>&middot; 통화시간(초)</dt>
                                <dd>{{ usedDashboard.inter.time | comma }}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>&middot; 과금액(원)</dt>
                                <dd>{{ usedDashboard.inter.rate | comma }}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>&middot; 공제액(원)</dt>
                                <dd>{{ usedDashboard.inter.dedt | comma }}</dd>
                              </dl>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li
                        :class="{ on: usedDashboard.currentView === 'sms' }"
                        @click="usedDashboard.currentView = 'sms'"
                      >
                        <div class="tit_box">
                          <h5 class="table_title">
                            국제SMS
                          </h5>
                          <dl>
                            <dt>청구액</dt>
                            <dd><span class="txt_point01">{{ usedDashboard.sms.chrg | comma }}원</span></dd>
                          </dl>
                          <span class="btn"><img
                            src="/content/images/btn_table_slide.png"
                            alt="펼치기"
                          ></span>
                        </div>

                        <div
                          v-show="usedDashboard.currentView === 'sms'"
                          class="txt_open"
                          style="display: block;"
                        >
                          <ul class="table_list">
                            <li>
                              <dl>
                                <dt>&middot; 통화건수</dt>
                                <dd>{{ usedDashboard.sms.count | comma }}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>&middot; 통화시간(초)</dt>
                                <dd>{{ usedDashboard.sms.time | comma }}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>&middot; 과금액(원)</dt>
                                <dd>{{ usedDashboard.sms.rate | comma }}</dd>
                              </dl>
                            </li>
                            <li>
                              <dl>
                                <dt>&middot; 공제액(원)</dt>
                                <dd>{{ usedDashboard.sms.dedt | comma }}</dd>
                              </dl>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- //모바일 월별 -->
                  <ul class="txt_notice">
                    <li><span class="icon_star">*</span>월별 사용내역요약 및 상세내역은 전월 기준 <span class="txt_point01">최근 6개월까지만</span> 가능합니다.</li>
                    <li><span class="icon_star">*</span>사용내역은 조회 월 기준 전월 21일부터 당월 20일까지 조회된 결과입니다.(예: 7월 내역 - 6월 21일 ~ 7월20일)</li>
                  </ul>
                </div>
              </div>
              <div
                class="table_area top_none"
                style="display:block;"
              >
                <!-- top_none 상단여백 + 라인 삭제 -->
                <h4 class="title">
                  사용내역
                </h4>
                <!-- 웹 상세리스트 -->
                <!-- 피씨일때 사용하는 버튼 -->
                <div class="btn_wrap w_hide">
                  <button
                    class="btn_type03 large"
                    @click="printUsage"
                  >
                    사용내역 인쇄하기
                  </button>
                </div>
                <!-- //피씨일때 사용하는 버튼 -->

                <!-- 웹 상세리스트 -->
                <div
                  v-if="usedInfoResult.use_list"
                  v-show="usedInfoResult.use_list.length"
                  class="table_type04 w_hide"
                >
                  <table>
                    <caption>구분, 2017년, 2018, 2019 년 나타내는 표입니다.</caption>
                    <colgroup>
                      <col width="12%">
                      <col>
                      <col width="12%">
                      <col width="12%">
                      <col>
                      <col>
                      <col width="8%">
                      <col width="8%">
                      <col width="8%">
                      <col>
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">
                          통화일자
                        </th>
                        <th scope="col">
                          통화시각
                        </th>
                        <th scope="col">
                          발신번호
                        </th>
                        <th scope="col">
                          착신번호
                        </th>
                        <th scope="col">
                          국가/대역명
                        </th>
                        <th scope="col">
                          통화종류
                        </th>
                        <th scope="col">
                          통화시간<br>(초)
                        </th>
                        <th scope="col">
                          과금액<br>(원)
                        </th>
                        <th scope="col">
                          공제액<br>(원)
                        </th>
                        <th scope="col">
                          청구액<br>(원)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(history, historyIdex) in usedInfoResult.use_list"
                        :key="`history${historyIdex}`"
                      >
                        <td>{{ `${history.call_dt.substr(0,4)}.${history.call_dt.substr(4,2)}.${history.call_dt.substr(6,2)}` }}</td>
                        <td>{{ `${history.call_tm.substr(0,2)}:${history.call_tm.substr(2,2)}:${history.call_tm.substr(4,2)}` }}</td>
                        <td>{{ history.call_no }}</td>
                        <td>{{ history.recv_no }}</td>
                        <td>{{ history.natl_nm }}</td>
                        <td>{{ history.svc_tp }}</td>
                        <td>{{ history.use_tm | comma }}</td>
                        <td>{{ history.rate_amount | comma }}</td>
                        <td>{{ history.dedt_amount | comma }}</td>
                        <td>{{ history.chrg_amount | comma }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- //웹 상세리스트 -->

                <!-- 모바일 상세리스트 -->
                <div
                  v-if="usedInfoResult.use_list"
                  v-show="usedInfoResult.use_list.length"
                  class="table_slide type03"
                >
                  <ul>
                    <li
                      v-for="(history, historyIdex) in usedInfoResult.use_list"
                      :key="`historyMobile${historyIdex}`"
                      :class="{ on: usedListCurrentView === historyIdex }"
                      @click="usedListCurrentView = historyIdex"
                    >
                      <div class="tit_box">
                        <h5 class="table_title">
                          {{ `${history.call_dt.substr(0,4)}.${history.call_dt.substr(4,2)}.${history.call_dt.substr(6,2)}` }}
                        </h5>
                        <h4 class="table_sub">
                          {{ history.natl_nm }}
                        </h4>
                        <dl>
                          <dt>청구액</dt>
                          <dd><span class="txt_point01">{{ history.chrg_amount }}원</span></dd>
                        </dl>
                        <span class="btn"><img
                          src="/content/images/btn_table_slide.png"
                          alt="펼쳐짐"
                        ></span>
                      </div>

                      <div
                        v-show="usedListCurrentView === historyIdex"
                        class="txt_open"
                        style="display: block;"
                      >
                        <ul class="table_list">
                          <li>
                            <dl>
                              <dt>&middot; 통화시각</dt>
                              <dd>{{ `${history.call_tm.substr(0,2)}:${history.call_tm.substr(2,2)}:${history.call_tm.substr(4,2)}` }}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>&middot; 발신번호</dt>
                              <dd>{{ history.call_no }}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>&middot; 착신번호</dt>
                              <dd>{{ history.recv_no }}</dd>
                            </dl>
                          </li>
                          <li>
                            <dl>
                              <dt>&middot; 통화시간(초)</dt>
                              <dd>{{ history.use_tm | comma }}</dd>
                            </dl>
                          </li>
                        </ul>
                        <span class="txt_em type02 align_r"><span class="icon_star">*</span>부가세 포함</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //모바일 상세리스트 -->

                <!-- 통화내역 없을떄 -->
                <div
                  v-if="!usedInfoResult.use_list"
                  class="table_type03"
                >
                  <ul
                    id="webDetail"
                    class="table_wid03"
                  >
                    <p class="txt_none">
                      내용이 없습니다.
                    </p>
                  </ul>
                </div>
                <!-- end 통화내역 없을떄 -->

                <!-- 피씨일때 보여지는 메세지 -->
                <ul class="txt_notice w_hide">
                  <li class="txt_point01">
                    <span class="icon_star">*</span>사용료는 결산과정 및 할인율에 따라 요금안내서 내역과 다를 수 있으니 이점 양해 바랍니다.
                  </li>
                </ul>
                <!-- //웹 사용내역 -->

                <!-- paging -->
                <!-- <div
                  v-if="usedInfoResult.use_list"
                  v-show="usedInfoResult.use_list.length"
                  class="paging type02"
                >
                  <span>
                    <a href=""><img
                      src="/content/images/btn_arrow_prev_03.png"
                      alt="이전"
                    ></a>
                    <a
                      href=""
                      class="active"
                    >1</a>
                    <a href="">2</a>
                    <a href="">3</a>
                    <a href="">4</a>
                    <a href="">5</a>
                    <a href="">6</a>
                    <a href="">7</a>
                    <a href="">8</a>
                    <a href="">9</a>
                    <a href="">10</a>
                    <a href=""><img
                      src="/content/images/btn_arrow_next_03.png"
                      alt="다음"
                    ></a>
                  </span>
                </div>
                <div
                  v-if="usedInfoResult.use_list"
                  v-show="usedInfoResult.use_list.length"
                  class="btn_more"
                >
                  <button type="button">
                    더보기 <span title="총 페이지">(<em title="현재 페이지">1</em>/11)</span>
                  </button>
                </div> -->
                <!-- //paging -->
                <!-- 모바일일때 보여지는 메세지 -->
                <ul class="txt_notice m_hide">
                  <li class="txt_point01">
                    <span class="icon_star">*</span>사용료는 결산과정 및 할인율에 따라 요금안내서 내역과 다를 수 있으니 이점 양해 바랍니다.
                  </li>
                </ul>
                <!-- 모바일일때 사용하는 버튼 -->
                <div class="btn_wrap m_hide">
                  <button
                    class="btn_type03 large"
                    @click="printUsage"
                  >
                    사용내역 인쇄하기
                  </button>
                </div>
              </div>
            </div>

            <!-- 착신번호 인증시 착신번호 대상없는 경우 -->
            <div
              v-if="usedCallNumResult.result_cd === 'E013'"
              class="result_area"
            >
              <h4
                v-if="usedCallNumResult.cust_nm.length <= 8"
                class="title"
              >
                <span class="txt_point01">{{ usedCallNumResult.cust_nm }}고객님</span> 안녕하세요. <br>
                <span class="">전월 기준 최근 6개월 동안 사용내역이 없습니다.</span>
              </h4>
              <h4
                v-if="usedCallNumResult.cust_nm.length > 8"
                class="title"
              >
                <span class="txt_point01">{{ usedCallNumResult.cust_nm }} 고객님</span><br> 안녕하세요.
              </h4>

              <div class="table_type05">
                <ul>
                  <li class="full">
                    <dl>
                      <dt>전화번호</dt>
                      <dd>
                        <div class="space_box">
                          <span
                            id="hpNoSpan"
                            style="padding-left: 20px;"
                          >
                            {{ usedCallNumResult.svc_tel_no }}
                          </span>
                        <!--
                        <div class="select_box wid144">
                            <label for="i_sHpNo1" aria-hidden="true">선택</label>
                            <select name="i_sHpNo1" id="i_sHpNo1" title="전화번호 앞자리 선택" class="select_type w_100p" >
                                <option value="">선택</option>
                                <option value="010" >010</option>
                                <option value="011" >011</option>
                                <option value="016" >016</option>
                                <option value="017" >017</option>
                                <option value="018" >018</option>
                                <option value="019" >019</option>
                            </select>
                        </div> -->
                        <!-- <span class="txt_hyphen">-</span> -->
                        <!-- <span class="txt_hyphen">-</span> -->
                        </div>
                      </dd>
                    </dl>
                  </li>
                </ul>
              </div>
              <ul class="txt_notice">
                <li><span class="icon_star">*</span>사용내역조회는 전월 기준 <span class="txt_point01">최근 6개월까지만 가능</span>합니다.</li>
                <li><span class="icon_star">*</span>사용내역은 조회 월 기준 전월 21일부터 당월 20일까지 조회된 결과입니다.(예: 7월 내역 - 6월 21일 ~ 7월 20일)</li>
                <li><span class="icon_star">*</span>자세한 사항은 고객센터(1599-00700)로 연락바랍니다.</li>
              </ul>
            </div>
            <!-- 추가인증 후 노출 -->
          </div>
          <!-- //mypage -->
        </div>

        <!-- 20211123 - 개인정보수집동의문 추가 -->
        <div
          v-if="isVisiblePrivacyPopup"
          class="pop_wrap02 w757"
          style="display:block"
        >
          <div class="medium">
            <h3>개인정보 수집 및 이용 동의</h3>
            <div class="pop_cont02">
              <h4 class="title">
                <span>개인정보 수집 및 이용 동의</span>
              </h4>

              <div class="cont_term">
                <ul>
                  <li>1. 수집/이용목적 : 사용내역조회</li>
                  <li>2. 수집/이용항목 : 휴대폰번호, 착신번호 끝4자리</li>
                  <li>3. 보유/이용기간 : <strong>조회 후 3년까지 (단, 착신번호 끝4자리 인증 후 즉시 파기)</strong><br>※ 전자상거래 등에서의 소비자보호에 관한 법률</li>
                  <li>4. 개인정보 수집/이용에 동의하지 않으실 수 있으며, 동의하지 않을 경우 해당 서비스를 이용하실 수 없습니다.</li>
                </ul>
              </div>
              <div class="check_box">
                <input
                  id="terms01"
                  v-model="agreeTerms"
                  type="checkbox"
                  name="id_terms01"
                  class="check_single"
                >
                <label for="terms01"><span>위 내용을 숙지하였으며 이에 동의합니다.</span></label>
              </div>
            </div>
            <div class="btn_center">
              <a
                class="btn_type01"
                @click="resultData"
              >확인</a>
            </div>
            <a @click="isVisiblePrivacyPopup = false"><img
              alt="닫기"
              src="/content/images/btn_pop_close.png"
            ></a>
          </div>
        </div>
        <!--// 20211123 - 개인정보수집동의문 추가 -->

        <form
          ref="form"
          action="https://report.sktelink.com/oz70/ozhviewer/report/phoneUseDetail.jsp"
          target="_blank"
          method="post"
        >
          <input
            id="jsondata"
            v-model="this.calldataJson"
            type="hidden"
            name="jsondata"
          >
        </form>
      </div>
    </div>
    <Footer />
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p>인증번호가 일치하지 않습니다.<br>5회 실패 시 휴대폰 본인인증을 다시 진행해야 합니다.</p>
    </Popup>

    <Popup
      :is-visible.sync="alertPopup1.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler1"
    >
      <p v-html="alertPopup1.message" />
    </Popup>

    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from "@/components/Header";
import BreadCrumb from "@/components/BreadCrumb";
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'
import Popup from '@/components/popup/Popup'
export default {
  name: 'Usage',
  metaInfo: {
    title: '사용 내역 조회 | 국제전화 고객지원 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
    Popup
  },
  filters:{
    comma(val){
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  props: {},
  data () {
    return {
      alertPopup: {
        visible: false
      },
      formData: {
        call_tp: '',
        tgt_month: ''
      },
      certificated: false,
      certInputCodeString:'',
      usedCallNumResult: {},  // 착신번호 조회 결과
      usedInfoResult: {},  // 사용내역 조회 결과
      usedDashboard: {
        currentView: 'total',
        total: {
          count: 0,
          time: 0,
          rate: 0,
          dedt: 0,
          chrg: 0
        },
        inter: {
          count: 0,
          time: 0,
          rate: 0,
          dedt: 0,
          chrg: 0
        },
        sms: {
          count: 0,
          time: 0,
          rate: 0,
          dedt: 0,
          chrg: 0
        },
      },
      prevMonth: '',
      nextMonth: '',
      usedListCurrentView: 0,

      alertPopup1: {
        visible: false,
        message: '',
        target: ''
      },
      agreeTerms:'',
      isVisiblePrivacyPopup: false,
      isVisibleResultData: false
    }
  },
  computed: {
    token() {
      return this.$store.getters.KCB_CERT_KEY
    },
    calldataJson(){
      return JSON.stringify(this.usedInfoResult)
    }
  },
  created () {
    this.certAndUsedCallNum()
  },
  methods: {
    printUsage(){
      if (this.usedInfoResult == {} || this.usedInfoResult.use_list == undefined){
        this.$store.commit('ON_ALERT_POPUP', { message: '조회된 사용내역이 없습니다.' })
        return
      }
      this.$refs.form.submit()
    },
    resetUsedDashboard() {
      this.usedDashboard.total.count = 0
      this.usedDashboard.total.time = 0
      this.usedDashboard.total.rate = 0
      this.usedDashboard.total.dedt = 0
      this.usedDashboard.total.chrg = 0
      this.usedDashboard.inter.count = 0
      this.usedDashboard.inter.time = 0
      this.usedDashboard.inter.rate = 0
      this.usedDashboard.inter.dedt = 0
      this.usedDashboard.inter.chrg = 0
      this.usedDashboard.sms.count = 0
      this.usedDashboard.sms.time = 0
      this.usedDashboard.sms.rate = 0
      this.usedDashboard.sms.dedt = 0
      this.usedDashboard.sms.chrg = 0
    },
    alertPopupHandler() {
      if (this.usedInfoResult.result_cd === 'E020') {
        this.$refs.num1.value = ''
        this.$refs.num2.value = ''
        this.$refs.num3.value = ''
        this.$refs.num4.value = ''
      }
      this.alertPopup.visible = false
    },
    movePrevMonth() {
      this.formData.tgt_month = this.prevMonth
      this.certificate()
      this.calculatePrevAndNextMonth()
    },
    moveNextMonth() {
      this.formData.tgt_month = this.nextMonth
      this.certificate()
      this.calculatePrevAndNextMonth()
    },
    calculatePrevAndNextMonth() {
      if (this.usedCallNumResult.month_list) {
        this.usedCallNumResult.month_list.some((item, index) => {
          if (item.month === this.formData.tgt_month) {
            this.prevMonth = this.usedCallNumResult.month_list[index - 1] ? this.usedCallNumResult.month_list[index - 1].month : ''
            this.nextMonth = this.usedCallNumResult.month_list[index + 1] ? this.usedCallNumResult.month_list[index + 1].month : ''
          }
          return item.month === this.formData.tgt_month
        })
      }
    },
    certAndUsedCallNum() {
      if (this.token) {
        //키 인증 및 착신번호조회
        api.ItsvcGetUsedCallNum({ mdl_tkn: this.token })
        .then(response => {
          this.isVisiblePrivacyPopup = true;

          this.usedCallNumResult = response.data
          this.formData.call_tp = this.usedCallNumResult.call_tp_list[0].call_tp
          this.formData.tgt_month = this.usedCallNumResult.month_list[0].month
          this.calculatePrevAndNextMonth()
          if (this.usedCallNumResult.result_cd === '0001' || this.usedCallNumResult.result_cd === 'E013') {
          } else {
            this.$store.commit('ON_CERT_POPUP')
          }

        })
      } else {  // 토큰 없으면 : 인증 레이어 팝업 띄우기
        this.$store.commit('ON_CERT_POPUP')
      }
    },
    certificate(){
      this.resetUsedDashboard()
      if (this.certInputCodeString.length === 4) {
        let data
        if (this.certificated) {
          data = {
            mdl_tkn: this.token,
            recv_no: this.certInputCodeString,
            tgt_month: this.formData.tgt_month,
            call_tp: this.formData.call_tp,
            call_num_tkn: this.usedCallNumResult.call_num_tkn
          }
        } else {
          data = {
            mdl_tkn: this.token,
            recv_no: this.certInputCodeString,
            tgt_month: this.usedCallNumResult.month_list[0].month,
            call_tp: 'ALL',
            call_num_tkn: this.usedCallNumResult.call_num_tkn
          }
          this.certificated = true
        }

        //사용내역조회
        api.ItsvcGetUsedInfo(data)
        .then(response => {
          this.usedInfoResult = response.data
          if (this.usedInfoResult.result_cd === '0001') {
            this.usedInfoResult.use_list.forEach(item => {
              this.usedDashboard.total.count = this.usedDashboard.total.count + 1
              this.usedDashboard.total.time = this.usedDashboard.total.time + item.use_tm
              this.usedDashboard.total.rate = this.usedDashboard.total.rate + item.rate_amount
              this.usedDashboard.total.dedt = this.usedDashboard.total.dedt + item.dedt_amount
              this.usedDashboard.total.chrg = this.usedDashboard.total.chrg + item.chrg_amount
              if (item.call_tp === 'INTL') {  //
                this.usedDashboard.inter.count = this.usedDashboard.inter.count + 1
                this.usedDashboard.inter.time = this.usedDashboard.inter.time + item.use_tm
                this.usedDashboard.inter.rate = this.usedDashboard.inter.rate + item.rate_amount
                this.usedDashboard.inter.dedt = this.usedDashboard.inter.dedt + item.dedt_amount
                this.usedDashboard.inter.chrg = this.usedDashboard.inter.chrg + item.chrg_amount
              } else if (item.call_tp === 'INTS') {
                this.usedDashboard.sms.count = this.usedDashboard.sms.count + 1
                this.usedDashboard.sms.time = this.usedDashboard.sms.time + item.use_tm
                this.usedDashboard.sms.rate = this.usedDashboard.sms.rate + item.rate_amount
                this.usedDashboard.sms.dedt = this.usedDashboard.sms.dedt + item.dedt_amount
                this.usedDashboard.sms.chrg = this.usedDashboard.sms.chrg + item.chrg_amount
              }
              // } else if (item.call_tp === '')
            })

          } else if (this.usedInfoResult.result_cd === 'E020') {
            this.alertPopup.visible = true
          }
        })
      } else {
        this.$store.commit('ON_ALERT_POPUP', { message: '착신번호 끝 4자리를 입력해 주세요.' })
      }
    },
    inputCertCode(event) {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      if (/^[0-9]/g.test(event.target.value) && event.keyCode !== 8 && event.keyCode !== 37 && event.keyCode !== 39 && event.keyCode !== 9) {
        this.certInputCodeString = this.$refs.num1.value + this.$refs.num2.value + this.$refs.num3.value + this.$refs.num4.value
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.focus()
        }
      }
    },

    alertPopupHandler1() {
      this.alertPopup1.visible = false
      this.alertPopup1.message = ''
      if (this.alertPopup1.target) {
        this.$refs[this.alertPopup1.target].focus()
      }
      this.alertPopup.target = ''
      this.isVisiblePrivacyPopup = true;
    },
    showAlertPopup(message) {
      this.alertPopup1.message = message
      this.alertPopup1.visible = true
      this.isVisiblePrivacyPopup = false;
    },
    resultData() {
      if(!this.agreeTerms) {
        this.showAlertPopup('개인정보 수집/이용에 동의하지 않을 시,<br>요금제 조회가 불가합니다.');
      } else {
        this.isVisiblePrivacyPopup = false;
        this.isVisibleResultData = true;
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.result_area{
  .title{
    margin-top: 54px;
    padding-bottom: 30px;
    font-size: 44px;
    font-weight: 300;
    line-height: 50px;
    letter-spacing: -3px;
    text-align: center;
  }
}
.check_box {
  margin-top: 15px;
}
.pop_wrap02 .medium>.btn_center { margin-bottom: 40px; }
@media (max-width: 1023px) {
  .pop_wrap02 .medium { padding-bottom: 20px; }
  .pop_wrap02 .medium>.btn_center { margin-bottom: 0; }
  .pop_cont02 {
    .cont_term {
      height: 155px;
      overflow: auto;
      margin-top: 0;
    }
    .check_box {
      margin: 15px 15px 0;
      display: block;
    }
  }
}
</style>
