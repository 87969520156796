<template>
  <input
    ref="input"
    type="text"
    :title="title"
    :placeholder="placeholder"
    autocomplete="off"
    :value="value"
    @input="updateInput"
    @keydown.down="$emit('key-down')"
    @keydown.up="$emit('key-up')"
    @keyup.enter="$emit('key-enter')"
  >
</template>

<script>
export default {
  name: 'Input',
  components: {
  },
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    title: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    }
  },
  emits: [
    // 'key-press','input'
    'input'
  ],
  data () {
    return {
      // className: 'input'
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    updateInput(event) {
      this.$emit('input', event.target.value)
    },
    // keypress(event) {
    //   this.$emit('key-press', event)
    // },
    // update(e) {
    //   this.$emit('input', e.target.value)
    // },    
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  width: 384px;
  height: 57px;
  padding: 0 30px;
  color: #fff;
  font-size: 24px;
  font-weight: lighter;
  line-height: 57px;
  border: none;
  background-color: #71747a;
  vertical-align: middle;
  &::placeholder {
    color: #fff;
  }
  &--onlynumber {
    border: 1px solid #e2e2e2;
    color: #666;
    line-height: 55px;
    text-align: right;
    background-color: #fff;
    &::placeholder {
      color: #666;
    }
  }
}
</style>
