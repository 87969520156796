<template>
  <div v-if="isVisible">
    <div id="popAlertSim" class="pop_wrap m-size" style="display: block">
      <div class="alert">
        <h3>제 26기 결산공고</h3>
        <div class="popup-inner">
          <p>
            <span style="font-size: 15pt">SK텔링크(주) 제 26기 결산공고</span>
          </p>
          <div class="btn_center">
            <router-link class="btn_type01" to="/view/investor/elec_notice">
              바로가기
            </router-link>
          </div>
        </div>
        <div class="not-week">
          <div class="check_box">
            <input
              id="agree"
              v-model="agreeClose"
              type="checkbox"
              @change="change"
            />
            <label for="agree"><span>다시 이 창을 열지 않음</span></label>
          </div>
        </div>
        <a class="pop-close" @click="$emit('update:is-visible', false)"
          ><img src="/content/images/btn_pop_close.png" alt="닫기"
        /></a>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/utilities";
export default {
  name: "PopupAnnounce",
  props: {
    // serviceName: {
    //   type: String,
    //   required: true
    // },
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      agreeClose: false,
    };
  },

  methods: {
    change() {
      if (this.agreeClose) {
        util.setCookie("announce", "Y", 100);
        this.$emit("update:is-visible", false);
      }
    },
  },
};
</script>

<style></style>
