
<template>
  <div id="KSSQI_NO1">
    <div class="event_wrap type2 type_05">
      <div class="event_view">
        <div>
          <span>
            <!--20210629 top 이미지 변경 -->
            <img
              alt="한국서비스품질지수(KS-SQL) 13년 연속 1위 기념 00700 고객감사제 스타벅스 ICE 아메리카노 선물 이벤트"
              src="/content/images/partnership/210629_kssqino1_top.jpg"
              width="640"
              style="height: auto;"        
              @load="onLoad"              
            >
          </span>
          <div class="notice event_view_field ksqino1">
            <!--20210624 'ksqino1' 클래스 추가-->
            <div class="align-center">
              <div class="table_type01 w100">     
                <dl>
                  <dt>SMS 인증</dt>
                  <dd>
                    <div class="phone-box_wrap">
                      <div class="phone-box">
                        <div
                          class="select_box type"
                          style="vertical-align: middle;"
                        >
                          <select
                            id="call_select01"
                            ref="telNumberFirst"
                            v-model="telNumber.first"
                            name="i_sHpNo1"
                            title="무선전화 앞자리 선택"
                          >
                            <option
                              value="010"
                            >
                              010
                            </option>
                            <option value="011">
                              011
                            </option>
                            <option value="016">
                              016
                            </option>
                            <option value="017">
                              017
                            </option>
                            <option value="018">
                              018
                            </option>
                            <option value="019">
                              019
                            </option>
                          </select>
                        </div>
                        <span class="txt_hyphen">-</span>
                        <input
                          ref="telNumberMiddle"
                          v-model="telNumber.middle"
                          name="i_sHpNo2"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen">-</span>
                        <input
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <a
                        class="btn btn_sendnum"
                        @click="requestSMSCert"
                      >SMS인증하기</a>
                    </div>
                  </dd>
                </dl>
                <dl
                  v-if="authNumberInputVisible"
                  id="numberCheck"
                  class="C030 duplPhono"
                >
                  <dt>인증번호 입력</dt>
                  <dd>
                    <input
                      ref="certNumber"
                      v-model="auth_no"
                      name="i_sCertificationNo"
                      title="인증번호"
                      type="text"
                      maxlength="6"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    ><a
                      class="btn btn_checknum"
                      @click="requestCert"
                    >인증번호확인</a>
                  </dd>
                </dl>
                <!-- 20210624 dl 순서 변경 -->
                <dl>
                  <dt>카테고리 택1</dt>
                  <dd>
                    <div class="select_box seb2">
                      <select
                        id="i_sCategory"
                        v-model="selectCategory"
                        name="i_sCategory"
                        title="카테고리 선택하기"
                        @change="selectedCategory"
                      >
                        <option value="카테고리를 선택해주세요">
                          카테고리를 선택해주세요
                        </option>
                        <option value="통화품질">
                          통화품질
                        </option>
                        <option value="요금&요금제">
                          요금&요금제
                        </option>
                        <option value="이벤트">
                          이벤트
                        </option>
                        <option value="00700앱&홈페이지">
                          00700앱&홈페이지
                        </option>
                        <option value="고객센터">
                          고객센터
                        </option>
                        <option value="기타의견">
                          기타의견
                        </option>
                      </select>
                    </div>
                  </dd>
                </dl>   
                <!-- //20210624 dl 순서 변경 -->    
                <!-- 20210624 textarea 추가 -->
                <dl>
                  <dt>바라는 한마디</dt>
                  <dd>
                    <textarea
                      v-model="wishText"
                      placeholder="00700 이 더 나은 서비스를 제공할 수 있도록 의견을 들려주세요."
                      class="textarea"
                    />
                  </dd>
                </dl>
                <!-- //20210624 textarea 추가 -->
              </div>

              <div class="check_box mt20">
                <input
                  id="yes"
                  v-model="agreePrivacy"
                  name="yesno"
                  class="myClass YNchk"
                  type="checkbox"
                  value="yes01"
                >
                <label for="yes"><span class="txt_label">이벤트 운영을 위한 개인정보 수집에 동의합니다.</span></label> <!--20210624 'txt_label'클래스 추가-->
                <a
                  class="btn_view pop_focus"
                  @click="isVisiblePrivacyPopup = true"
                >자세히 보기</a>
              </div>
              <div class="align-center">
                <div class="btn_center type_02">
                  <a
                    id="btnApply"
                    class="btn_type01 round-type"
                    @click="registrationFinal"
                  >이벤트 참여하기</a>
                </div>
              </div>
            </div>
          </div>
          <div class="event_view_field">
            <span>
              <img
                alt="유의사항"
                src="/content/images/partnership/210624_kssqino1_bottom.jpg"
              >
            </span>
            <div
              style="display:none"
              class="event_view"
            >
              <div class="blind">
                <h6>유의사항</h6>
                <ul>
                  <li>본 이벤트 경품은 제휴사 사정에 따라 별도 공지 없이 상품이 변경/대체될 수 있습니다.</li>
                  <li>본 이벤트의 스타벅스 ICE 아메리카노의 경우, 이벤트 응모당시 기재하신 휴대폰번호로 모바일 쿠폰이 발송될 예정이며, 실물 상품 구매 시 사용 가능하십니다.</li>
                  <li>경품으로 제공되는 스타벅스 ICE 아메리카노 모바일 쿠폰은 유효기간이 정해져 있습니다. 유효기간 종료 후 재발송 및 재발행은 불가합니다.</li>
                  <li>이벤트 응모, 경품 발송을 위해 휴대폰번호를 정확히 입력하시길 바랍니다. </li>
                  <li>00700에 바라는 한마디에 욕설 혹은 단순 비방을 기재하신 경우, 다음 번 00700 이벤트 참여가 제한되실 수 있으십니다.</li>
                  <li>비정상적이거나 불법적인 방법으로 이벤트에 참여하신 경우, 이벤트 대상에서 제외될 수 있습니다.</li>
                  <li>이벤트 관련 문의: SK국제전화 00700</li>
                  <li>고객센터: 1599-0070(월~금, 9시~18시)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 KS-SQI 1위 기념 고객감사제 이벤트 운영을 위해 아래와 같이 고객정보를 수집하며,<br>고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보의 항목 : 휴대폰번호(연락처)</li>
              <li>개인정보의 수집 및 이용목적 : KS-SQI 1위 기념 고객감사제 이벤트 운영 및 경품 제공, 이벤트 관련 고객 민원 대응</li>
              <li>개인정보의 보유 및 이용기간 : <strong>이벤트 운영 기간 포함 경품 제공 및 고객 민원 대응을 위해 6개월까지 보유</strong></li>
            </ul>
            <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 이벤트 참여가 제한이 될 수 있습니다.</p>
            <p>SK국제전화 00700이 운영하는 이벤트 참여 및 경품 수령을 위해 위와 같이 본인의 개인정보를 수집하는 것에 동의합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    <!-- //팝업 -->
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  name: 'KSSQI',
  components: {
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    isVisiblePrivacyPopup: false,
    agreePrivacy:false,
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    telNumber:{
      first:'010',
      middle: '',
      last:''
    },
    authNumberInputVisible: false,
    ticket: '',
    auth_no: '',
    selectCategory:'카테고리를 선택해주세요',
    wishText: '',
  }
 },
 computed: {
  svc_tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  add_ctgr(){
    return this.selectCategory
  },
  add_txt(){
    return this.wishText
  },
  callbackAlert(){
    return this.$store.getters.CALLBACK_ALERT
  }
 },
 watch: {
  callbackAlert: {
    deep: true,
    handler() {
      if (this.callbackAlert.code === '0002' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E0027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E021' || this.callbackAlert.code === 'E022') {
        this.resetPage()
        window.scrollTo(0, 0)
        document.getElementById('wrap').scrollTop = 0
      }
      if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
        this.authNumberInputVisible = false,
        this.telNumber = {
          first:'010',
          middle: '',
          last:''
        },
        this.auth_no = ''
        this.ticket = ''
      }
      if (this.callbackAlert.code === 'E001') { // 인증시간 초과
        this.auth_no = ''
        this.ticket = ''
      }
    }
  }
},
 created () {},
 mounted () {
    
 },
 methods: {
  selectedCategory() {
    this.add_ctgr
  },
  addText() {
    this.add_txt
  },
  alertPopupHandler() {
    this.alertPopup.visible = false
    this.alertPopup.message = ''
    if (this.alertPopup.target) {
      this.$refs[this.alertPopup.target].focus()
    }
    this.alertPopup.target = ''
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
      return false
    } else {
      return true
    }
  },
  checkAuthNumber() {
    if (!this.auth_no) {
      this.alertPopup.target = 'certNumber'
      this.showAlertPopup('인증번호를 입력해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkSMSCert() {
    if(!this.isSmsCert) {
      this.showAlertPopup('SMS인증을 완료해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkselect(){
    if(!this.agreePrivacy){
      this.showAlertPopup('개인정보 수집에 동의하셔야 신청이 가능합니다.')
      return false
    }else if((this.selectCategory ==='카테고리를 선택해주세요')){
      this.showAlertPopup('카테고리를 선택해주세요.')
      return false
    }else if((this.wishText ==='')){
      this.showAlertPopup('의견을 입력해주세요.')
      return false
    }else {
      return true
    }
  },
  onChange() {
    this.selectCategory = '카테고리를 선택해주세요'
  },
  // SMS 인증 요청
  requestSMSCert() {
    if (this.checkPhoneNumber()) {
      api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
      .then(response => {
        if (response.data.sms_ticket) {
          this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
          this.ticket = response.data.sms_ticket
          this.authNumberInputVisible = true
        }
      })
    }
  },
  // 인증 번호로 SMS 인증 확인
  requestCert() {
    if (this.checkAuthNumber()) {
      api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
      .then(response => {
        if (response.data.result_cd === '0001') {
          this.isSmsCert = true
          this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
        }
      })
    }
  },
  //신청완료
  registrationFinal() {
    if (this.checkselect() && this.checkPhoneNumber() && this.checkSMSCert()) {
      api.EventPostReqEntry({
        sms_ticket: this.ticket,
        req_ctgr: this.add_ctgr,
        req_ct: this.add_txt
      })
    }
  },
  resetPage(){
    this.agreePrivacy = false,
    this.telNumber= {
      first:'010',
      middle: '',
      last:''
    },
    this.selectCategory = '카테고리를 선택해주세요',
    this.wishText = '',
    this.authNumberInputVisible = false,
    this.auth_no = ''
    this.ticket = ''
    this.isSmsCert = false
  },
    //20210625 이벤트 종료
    onLoad() {
      var popitem = document.getElementById("KSSQI_NO1");

      if (new Date() >= new Date('07/31/2021 23:59:59')) {   //종료 시간 지정
          this.showAlertPopup('종료된 이벤트입니다.')
          popitem.classList.add("closeEvent");
      }else if (new Date() < new Date('07/02/2021 00:00:00')) {   //시작 시간 지정
          this.showAlertPopup('본 이벤트는 7/2부터 참여 가능합니다.')
          popitem.classList.add("closeEvent");
      }
  },
 }
}
</script>

<!--20210624 css추가-->
<style lang="scss" scoped>
  .ksqino1 { padding: 0 50px 50px; background-color: #3a3128; }
  .ksqino1 > .align-center { margin-top: 0; }
  .ksqino1 input { padding: 0 20px; }
  .ksqino1 input, 
  .ksqino1 select { 
    background-color: #fff; border: 1px solid #e1e1e1; color: #666; font-size: 16px; font-weight: 400; 
  }
  .ksqino1 input:focus,
  .ksqino1 select:focus { border: 1px solid #333; }
  .ksqino1 .textarea:focus { outline: 1px solid #333; }
  .ksqino1 .table_type01 { border: none; border-top: 1px solid #181818;}
  .ksqino1 .table_type01 dl:first-child { border-top: none; }
  .ksqino1 .table_type01 dl, 
  .ksqino1 .table_type01 dl dd { border-style: solid; border-color:#e1e1e1; }
  .ksqino1 .table_type01 dt { padding-left: 0; color: #191919;  font-weight: 500;  text-align: center !important; }
  .ksqino1 .check_box .btn_view  { color: #fff; }
  .ksqino1 .check_box label { padding-left: 34px; border: none; }
  .ksqino1 .table_type01 .textarea { width: 100%; padding: 20px; color: #666; font-family: notosans, Sans-serif, Dotum, 돋음; font-size: 16px; font-weight: 400; border-color: #e1e1e1; background: #fff; box-sizing: border-box; }
  .ksqino1 .table_type01 .textarea::placeholder { color: #666; font-size: 16px; font-weight: 400; letter-spacing: -0.5px; }
  .ksqino1 .btn_center.type_02 {width: auto;}
  .ksqino1 .txt_label { position: relative; top: -1px; font-size: 16px; color: #fff; }
  .ksqino1 .table_type01 dl dd a { height: 42px; background: #231d17; }
  .ksqino1 .select_box {line-height: 40px;}
  .ksqino1 .select_box select{ opacity: 1 !important; color: #666 !important; font-weight: 400; background-color: #fff; line-height: 40px; }
  .ksqino1 .table_type01.w100 .select_box.seb2 {width: 320px;}

  //20210625 이벤트 종료
  .closeEvent {max-height: 100vh; overflow: hidden;}

  @media (max-width: 1023px) {
    .ksqino1 { padding: 0 12px 30px; }
    .ksqino1 > .align-center { margin-top: 10px; }
    .ksqino1 input[type=number], 
    .ksqino1 input[type=password], 
    .ksqino1 input[type=text], 
    .ksqino1 select { 
      font-size: 16px; line-height: 42px;
    }
    .ksqino1 .table_type01 dl, 
    .ksqino1 .table_type01 dl dd { border: none; }
    .ksqino1 .check_box label span { font-size: 13px; }
    .ksqino1 .btn_type01 { font-size: 16px; }
    .ksqino1 .table_type01 dt { text-align: left !important; }
    .ksqino1 .table_type01 .textarea { padding: 15px; }
    .ksqino1 .txt_label { top: 4px; font-size: 14px; }
    .ksqino1 .event_view_field .btn_center { margin-top: 20px; padding-bottom: 10px; }
    .ksqino1 .event_view_field .btn_center .btn_type01 { width: 100%; }
    .event_wrap.type2.type_05 .ksqino1 .select_box.seb2 { width: 100%; }
    .event_wrap.type2.type_05 .ksqino1 .select_box select { border: 1px solid #e1e1e1; }
  }
</style>
<!-- //20210624 css추가-->
