<template>
  <div
    id="ELEC_NOTICE"
  >
    <Header />
    <div id="container">
      <BreadCrumb />
      <div

        id="contents"
        class="contents"
      >
        <div class="sub_header bg03">
          <h3 class="title">
            IR 자료실
          </h3>
          <p class="txt">
            SK텔링크의 투자관련 정보를 <br>IR자료실에서 확인하세요
          </p>
          <img
            src="/content/images/sub_header_cp03.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- tab -->
        <div class="tab_type01">
          <ul>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <a href="">전자공고</a>
            </li>
            <span class="w_hide">
              <li>
                <a
                  href="http://dart.fss.or.kr/html/search/SearchCompanyIR3_M.html?textCrpNM=00257893"
                  target="_blank"
                  title="공시자료 새창열림"
                >공시자료</a>
              </li>
            </span>
            <span class="m_hide">
              <li>
                <a
                  href="http://dart.fss.or.kr/html/search/SearchCompanyIR3_M.html?textCrpNM=00257893"
                  target="_blank"
                  title="공시자료 새창열림"
                >공시자료</a>
              </li>
            </span>
          </ul>
        </div>
        <!-- //tab -->
        <!-- company -->
        <div
          class="company"
        >
          <div class="table_type02">
            <ul class="table_wid02 four_ir">
              <li
                v-for="(item, index) in irList.bulletin_list"
                :key="index"
              >
                <dl class="child1">
                  <dt>No</dt>
                  <dd>{{ item.row_num }}</dd>
                </dl>
                <dl class="child2">
                  <dt>제목</dt>
                  <dd class="txt_left">
                    <span>{{ item.title }}</span>
                  </dd>
                </dl>
                <dl class="child3">
                  <dt>첨부파일</dt>
                  <dd class="btn">
                    <a
                      :href="item.ir_file_url"
                      :download="getFileName(item.title)"
                    >PDF</a>
                  </dd>
                </dl>
                <dl class="child4">
                  <dt>작성일</dt>
                  <dd>
                    <span>{{ $moment(item.start_dt).format('YYYY.MM.DD') }}</span>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
          <!-- paging -->
          <Pagination
            v-if="totalCount > 0"
            :total-count="totalCount"
            :page-per-count="formData.page_per_cnt"
            :current-page="formData.page_num"
            @update="updatePage"
            @loadmo="updatePageMobile"
          />
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Pagination from "@/components/Pagination"
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'

function MS_bindDownload(el) {
    if (el === undefined) {
        throw Error('I need element parameter.');
    }
    if (el.href === '') {
        throw Error('The element has no href value.');
    }
    var filename = el.getAttribute('download');
    if (filename === null){
        throw Error('I need download property.');
    }
    if (filename === '') {
        var tmp = el.href.split('/');
        filename = tmp[tmp.length - 1];
    }
    el.addEventListener('click', function (evt) {
        evt.preventDefault();
        var xhr = new XMLHttpRequest();
        xhr.onloadstart = function () {
            xhr.responseType = 'blob';
        };
        xhr.onload = function () {
            navigator.msSaveOrOpenBlob(xhr.response, filename);
        };
        xhr.open("GET", el.href, true);
        xhr.send();
    })
}

export default {
  name: 'ElecNotice',
  metaInfo: {
    title: 'IR 자료실 | 투자정보 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Pagination,
    Footer,
    SummitControl
  },
  props:{
  },
  emits: [
  ],
  data () {
    return {
      irList:{},
      totalCount: 0,
      formData: {
        page_per_cnt: 10,
        page_num: 1,
      },
      currentPage:null
    }
  },
  computed: {
  },
  watch: {},
  created () {
    api.BulletinGetIrList(this.formData)
    .then(response => {
      this.irList = response.data
      this.totalCount = response.data.bulletin_total_cnt
    })

  },
  mounted () {
    if (util.getBrowserIEVersion() !== -1) {
      setTimeout(() => {
        let atags = document.querySelectorAll('a[download]')
          for (let i = 0; i < atags.length; i++) {
            const element = atags[i];
            MS_bindDownload(element);
          }
      }, 100)
    }
  },
  methods: {
    getFileName(title){
      return title + ".pdf"

    },
    apiCall(){
      api.BulletinGetIrList(this.formData)
      .then(response => {
        this.irList = response.data
        this.totalCount = response.data.bulletin_total_cnt
        this.currentPage = null
      })

    },
    moreList(){
      api.BulletinGetIrList(this.formData)
      .then(response => {
        response.data.bulletin_list.forEach(item => {
          this.irList.bulletin_list.push(item)
        })
      })
    },
    updatePage(num) {
      this.$router.replace({ query: {...this.$route.query, page_num: num }}).catch(()=>{})
      this.formData.page_num = num
      this.apiCall()
    },
    updatePageMobile(num) {
      this.formData.page_num = num
      this.moreList()
    },
  }
}
</script>

<style lang="scss" scoped>
.paging span {
  button{
    display: inline-block;
    width: 42px;
    height: 42px;
    line-height: 42px;
    font-size: 17px;
    background-color: #fff;
    vertical-align: middle;
  }
}

</style>
