<template>
  <div id="INTRODUCE_CIBI">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg01">
          <h3 class="title">
            CI/BI
          </h3>
          <p class="txt">
            SK텔링크의 기업아이덴티티를 소개합니다
          </p>
          <img
            src="/content/images/sub_header_cp01.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- tab -->
        <div class="tab_type01">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li
              id="taba"
              :class="tabIndex == 1 ? 'on': ''"
              title="현재메뉴 선택됨"
            >
              <div
                @click="tabActive(1)"
              >
                CI
              </div>
            </li>
            <li
              id="tabb"
              :class="tabIndex == 2 ? 'on': ''"
            >
              <div
                @click="tabActive(2)"
              >
                BI
              </div>
            </li>
          </ul>
        </div>
        <!-- //tab -->
        <!-- company -->
        <div
          v-show="tabIndex == 1"
          id="tab1"
          class="company mb0"
        >
          <div class="ci_list">
            <h4>
              로고마크
            </h4>
            <ul>
              <li>
                <div>
                  <img
                    src="/content/images/img_logo01.png"
                    alt="SK 텔링크"
                  >
                </div>
                <a
                  href="/content/download/cibi/ci_Sktelink.ai"
                  class="btn_down"
                  download
                >AI 다운로드</a>
                <a
                  href="/content/download/cibi/ci_Sktelink.jpg"
                  class="btn_down"
                  download
                >JPG 다운로드</a>
              </li>
              <li>
                <div>
                  <img
                    src="/content/images/img_logo02.png"
                    alt="SK telink"
                  >
                </div>
                <a
                  href="/content/download/cibi/ci-eng_SKtelink.ai"
                  class="btn_down"
                  download
                >AI 다운로드</a>
                <a
                  href="/content/download/cibi/ci-eng_SKtelink.jpg"
                  class="btn_down"
                  download
                >JPG 다운로드</a>
              </li>
            </ul>
            <div class="txt_box">
              <p>
                로고마크는 SK텔링크의 공식적인 상징으로 어떤 경우라도 형태가 변형되어서는 안되며 인쇄물, 프로모션물 등 <br class="w_hide">
                여러 매체에 사용되어
                <span class="txt_point01">SK텔링크의 기업 및 브랜드 이미지 전달에 중요한 역할을 하는 요소입니다.</span>
              </p>
              <p class="txt_point01 w_hide">
                <span class="icon_star">*</span>로고마크의 사용시 변형을 방지하기 위하여 웹(web)상에 수록된 원고용 파일을 다운 받아 사용해 주시기 바랍니다.
              </p>
            </div>
          </div>
          <div class="ci_list bg_rule">
            <div>
              <h4>
                CI공간규정
              </h4>
              <ul>
                <li>
                  <div>
                    <img
                      src="/content/images/img_logo03.png"
                      alt="로고마크 ‘ K ’의 높이를 X라고 가정할 때, ‘행복날개’의 높이는 1.1X입니다. 한글 사명의 크기는 0.8X이고, SK 로고와 사명의 간격은 한글은 0.8X 컬러로 표현할 때 로고마크는 SK Red, 사명은 SK Orange로 적용하며, 흑백으로 표현할 때 로고마크는 Black 60%로 적용합니다."
                    >
                  </div>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/img_logo04.png"
                      alt="로고마크 ‘ K ’의 높이를 X라고 가정할 때, ‘행복날개’의 높이는 1.1X입니다. 영문사명은 0.55X이고, SK 로고와 사명의 간격은 영문은 0.55X입니다. 컬러로 표현할 때 로고마크는 SK Red, 사명은 SK Orange로 적용하며, 흑백으로 표현할 때 로고마크는 Black 60%로 적용합니다."
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="color_list">
            <h4>
              주요색상
            </h4>
            <div class="color_list_inner">
              <div class="red">
                <h5>
                  SK Red
                </h5>
                <ul>
                  <li>Pantone : 186C</li>
                  <li>CMYK : 0.100.8.4</li>
                  <li>RGB : 234.0.44</li>
                </ul>
              </div>
              <div class="orange">
                <h5>SK Orange</h5>
                <ul>
                  <li>Pantone : 158C</li>
                  <li>CMYK : 0.66.97.0</li>
                  <li>RGB : 255.122.0</li>
                </ul>
              </div>
            </div>
            <div class="txt_box">
              <p>
                로고마크와 심벌마크의 <span class="txt_point02">SK Red는 역동적이고 생명력 넘치는 적색</span>이며
                <span class="txt_point01">SK orange는 ‘행복’, ‘친근감’, ‘환영’</span>을 상징하는 색입니다.<br class="w_hide">
                로고마크는 백색 바탕에 지정색상 사용을 원칙으로 합니다.<br class="w_hide">
                특히, 지속적인 동일 색상의 사용은 SK텔링크의 이미지 구축에 중요한 역할을 함으로 색상 활용 기준을 준수해야 합니다.
              </p>
              <p class="txt">
                <span class="icon_star">*</span>
                전용색상의 사용은 별색 사용을 원칙으로 하여 본 항에 제시되지 않은 색상활용은 바탕색의 채도, 명도 등을 고려해야 하며<br class="m_br"> 반드시 관리부서와 협의 하에 사용해야 합니다.
              </p>
            </div>
          </div>
          <div class="ci_list color_rule">
            <h4>
              색상규정
            </h4>
            <ul>
              <li>
                <div>
                  <img
                    src="/content/images/img_logo05.png"
                    alt="SK텔링크 로고(흑백)"
                  >
                </div>
                <dl>
                  <dt>
                    흑백 색상규정
                  </dt>
                  <dd>
                    흑백 인쇄용 사용시 행복날개는 Black 100%와 60%로<br>명도의 차이를 구분 할 수 있어야 합니다.
                  </dd>
                </dl>
              </li>
              <li class="bg_print">
                <div>
                  <img
                    src="/content/images/img_logo06.png"
                    alt="SK텔링크 로고(음화용)"
                  >
                </div>
                <dl>
                  <dt>
                    음화용 색상규정
                  </dt>
                  <dd>
                    음화표현시 배경색이 명도 30%인 경우<br>백색으로 사용됩니다.
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
          <div class="notice_area gray">
            <h4 class="title">
              <span class="icon_mark">
                <img
                  src="/content/images/icon_mark01.png"
                  alt="주의사항"
                >
              </span>
              로고 사용시 꼭 읽어보세요
            </h4>
            <p>
              로고마크는 SK텔링크의 공식적인 상징으로 어떤 경우라도 형태가 변형되어서는 안되며 인쇄물, 프로모션물 등 <br>여러 매체에 사용되어
              <span class="txt_point01">SK텔링크의 기업 및 브랜드 이미지 전달에 중요한 역할을 하는 요소입니다.</span>
            </p>
            <p class="txt txt_point01">
              <span class="icon_star">*</span>로고마크의 사용시 변형을 방지하기 위하여 웹(web)상에 수록된 원고용 파일을 다운 받아 사용해 주시기 바랍니다.
            </p>
          </div>
        </div>
        <!-- //customer -->
        <!-- company -->
        <div
          v-show="tabIndex == 2"
          id="tab2"
          class="company mb0"
        >
          <div class="bi_area">
            <h4>
              국제전화 00700
            </h4>
            <ul class="sk_inter">
              <li class="child1">
                <div>
                  <img
                    src="/content/images/img_bi01.png"
                    alt="SK국제전화 00700"
                  >
                </div>
                <a
                  href="/content/download/cibi/ci_00700.ai"
                  class="btn_down"
                  download
                >AI 다운로드</a>
                <a
                  href="/content/download/cibi/ci_00700.jpg"
                  class="btn_down"
                  download
                >JPG 다운로드</a>
              </li>
              <li class="child2">
                <div>
                  <img
                    src="/content/images/img_bi02.png"
                    alt="SK International Call 00700"
                  >
                </div>
                <a
                  href="/content/download/cibi/ci-eng_00700.ai"
                  class="btn_down"
                  download
                >AI 다운로드</a>
                <a
                  href="/content/download/cibi/ci-eng_00700.jpg"
                  class="btn_down"
                  download
                >JPG 다운로드</a>
              </li>
            </ul>
          </div>
          <div class="bi_area">
            <h4>SK 7mobile</h4>
            <ul class="sk_7m">
              <li class="child1 single">
                <div>
                  <img
                    src="/content/images/img_bi7m02.png"
                    alt="일반형세로 SK 알뜰폰 7mobile"
                  >
                </div>
                <a
                  href="/content/download/cibi/7mobile height.ai"
                  class="btn_down"
                  download
                >AI 다운로드</a>
                <a
                  href="/content/download/cibi/7mobile height.jpg"
                  class="btn_down"
                  download
                >JPG 다운로드</a>
              </li>
              <!-- <li class="child2">
                <div>
                  <img
                    src="/content/images/img_bi7m01.png"
                    alt="일반형 가로 SK 알뜰폰 7mobile"
                  >
                </div>
                <a
                  href="/content/download/cibi/7mobile width.ai"
                  class="btn_down"
                  download
                >AI 다운로드</a>
                <a
                  href="/content/download/cibi/7mobile width.jpg"
                  class="btn_down"
                  download
                >JPG 다운로드</a>
              </li> -->
            </ul>
          </div>
          <!-- <div class="bi_area">
            <h4>사바나 라이언</h4>
            <div class="character_area">
              <h5 class="character_title">
                베이직
              </h5>
              <ul class="sk_character">
                <li class="col-6">
                  <h6 class="character_list_title">
                    전신
                  </h6>
                  <div class="character_list_image">
                    <img
                      src="/content/images/img_character_front.png"
                      alt="사바나 라이언 베이직 전신"
                    >
                  </div>
                </li>
                <li class="col-6">
                  <h6 class="character_list_title">
                    머리
                  </h6>
                  <div class="character_list_image">
                    <img
                      src="/content/images/img_character_head.png"
                      alt="사바나 라이언 베이직 머리"
                    >
                  </div>
                </li>
              </ul>
            </div>
            <div class="character_area">
              <h5 class="character_title">
                어플리케이션
              </h5>
              <ul class="sk_character">
                <li class="col-4">
                  <h6 class="character_list_title">
                    01
                  </h6>
                  <div class="character_list_image">
                    <img
                      src="/content/images/img_character_application1.png"
                      alt="사바나 라이언 어플리케이션 타입1"
                    >
                  </div>
                </li>
                <li class="col-4">
                  <h6 class="character_list_title">
                    02
                  </h6>
                  <div class="character_list_image">
                    <img
                      src="/content/images/img_character_application2.png"
                      alt="사바나 라이언 어플리케이션 타입2"
                    >
                  </div>
                </li>
                <li class="col-4">
                  <h6 class="character_list_title">
                    03
                  </h6>
                  <div class="character_list_image">
                    <img
                      src="/content/images/img_character_application3.png"
                      alt="사바나 라이언 어플리케이션 타입3"
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div> -->
          <div class="notice_area gray">
            <h4 class="title">
              <span class="icon_mark">
                <img
                  src="/content/images/icon_mark01.png"
                  alt="주의사항"
                >
              </span>
              로고 사용시 꼭 읽어보세요
            </h4>
            <p>
              로고마크는 SK텔링크의 공식적인 상징으로 어떤 경우라도 형태가 변형되어서는 안되며 인쇄물, 프로모션물 등 <br>여러 매체에 사용되어
              <span class="txt_point01">SK텔링크의 기업 및 브랜드 이미지 전달에 중요한 역할을 하는 요소입니다.</span>
            </p>
            <p class="txt txt_point01">
              <span class="icon_star">*</span>
              로고마크의 사용시 변형을 방지하기 위하여 웹(web)상에 수록된 원고용 파일을 다운 받아 사용해 주시기 바랍니다.
            </p>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import util from '@/utilities'
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl'

function MS_bindDownload(el) {
    if (el === undefined) {
        throw Error('I need element parameter.');
    }
    if (el.href === '') {
        throw Error('The element has no href value.');
    }
    var filename = el.getAttribute('download');
    if (filename === null){
        throw Error('I need download property.');
    }
    if (filename === '') {
        var tmp = el.href.split('/');
        filename = tmp[tmp.length - 1];
    }
    el.addEventListener('click', function (evt) {
        evt.preventDefault();
        var xhr = new XMLHttpRequest();
        xhr.onloadstart = function () {
            xhr.responseType = 'blob';
        };
        xhr.onload = function () {
            navigator.msSaveOrOpenBlob(xhr.response, filename);
        };
        xhr.open("GET", el.href, true);
        xhr.send();
    })
}

export default {
  name: 'Cibi',
  metaInfo: {
    title: 'CI/BI | SK텔링크 소개 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:'1'
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
    if (util.getBrowserIEVersion() !== -1) {
      setTimeout(() => {
        let atags = document.querySelectorAll('a[download]')
          for (let i = 0; i < atags.length; i++) {
            const element = atags[i];
            MS_bindDownload(element);
          }
      }, 100)
    }
  },
  methods: {
    tabActive(num){
      this.tabIndex = num
    }
  }
}
</script>

<style lang="scss" scoped>
.single {
  display: inline-block !important;
  float: none !important;
}
</style>
