<template>
  <div id="CALLING_PLAN_SPECIFIED">
    <Header />
    <!-- 컨텐츠 -->
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />
      <div id="contents">
        <div class="sub_header bg02-02">
          <h3 class="title">
            특정국가할인형
          </h3>
          <p class="txt">
            국제전화 이용 시 국가별 할인 프로그램이 필요한 고객님을 위해<br>SK국제전화 00700이 준비한 SPECIAL 요금제!
          </p>
          <img
            src="/content/images/sub_header_inter02-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <div class="tab_type01 tab3">
          <ul>
            <li>
              <router-link to="/view/business/calling_plan_domestic">
                국내통화 요금제
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/business/calling_plan_specified">
                지정국가 요금제
              </router-link>
            </li>
            <li>
              <router-link to="/view/business/calling_plan_flat_rate">
                더 길게 요금제
              </router-link>
            </li>
          </ul>
        </div>

        <!-- inter -->
        <div class="inter mb0">
          <!-- 하단 여백없을시 class mb0 추가 -->
          <div class="sub_title sub-title-mobile">
            <h4 class="title">
              1개 국가만 이용할 땐!<br>
              <span class="txt_point01">00700  지정국가 요금제</span>
            </h4>
            <p class="txt">
              <span class="txt_point01">자주 사용하는 한 국가만 콕 집어서<br class="m_br"> 할인해주는 00700 지정국가 요금제.</span><br>
              국제전화 00700을 가입비 없이<br class="m_br"> 평균 88%의 할인 혜택을 받는 방법!
            </p>
          </div>

          <div class="advice_list">
            <h4>이런 고객님이 사용하면 좋아요!</h4>
            <p><span class="hashtag"># 법인고객</span><span class="hashtag"># 해외가족/지인</span><span class="hashtag"># 해외직구</span></p>
            <div class="advice_scroll">
              <div>
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter01.png"
                        alt=""
                      >
                    </div>
                    <p>해외 유학생 자녀를 둔 부모님</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter16.png"
                        alt=""
                      >
                    </div>
                    <p>해외 한 개 국가에 가족/지인이 있는 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter17.png"
                        alt=""
                      >
                    </div>
                    <p>한 국가의 사업자와 업무하는 비즈니스 고객</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="advice_list white">
            <h4>거부할 수 없는 매력은<br class="m_hide"> 무엇인가요?</h4>
            <div class="advice_scroll">
              <div>
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub21_20211210.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>한 국가를 평균 88% 할인</dt>
                      <dd>한 국가만 이용하는 고객님을 위한<br> 00700 지정국가 요금제는 표준요금 대비<br> 평균 88% 할인된 요금으로 경제적입니다. </dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub22.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>자유롭게 국가 변경</dt>
                      <dd>국가 지정 후 고객님이 원하시면<br> 언제든지 국가 변경이 가능합니다.<br>(단, 국가 변경 시부터 할인요금 적용)</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub23.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>가입비 100% 무료!</dt>
                      <dd>할인받기 위해 필요한 요금제 가입비 0원!<br> 남녀노소, 국내거주 외국인까지<br> 부담 없이 가입하여 이용할 수 있습니다.</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="inter_seach">
            <h4><strong>지정국가 요금제</strong> 국가별 요금을 확인해보세요</h4>
            <div class="type01">
              <Autocomplete
                v-model="formData.natl_cd"
                :source="country"
                :results-display="'natl_nm'"
                :results-value="'natl_cd'"
                :clear-button-icon="''"
                :placeholder="'국가명을 입력해주세요'"
                @selected="selectCountry"
                @keyupEnter="inquire"
              />
              <button
                type="button"
                class="btn_type01 btn_search"
                @click="inquire"
              >
                검색
              </button>
            </div>
          </div>

          <!-- 검색결과박스 -->
          <div
            v-if="resultAreaOpen"
            class="result_area"
          >
            <div class="table_type04">
              <table>
                <caption>지정국가 요금제, 표준요금, 할인율 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:34%;">
                  <col style="width:33%;">
                  <col style="width:33%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      지정국가 요금제
                    </th>
                    <th scope="col">
                      표준요금
                    </th>
                    <th scope="col">
                      할인율
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ result.price }}원/분</td>
                    <td>{{ result.price_00700 }}원/분</td>
                    <td>{{ result.discount_rate }}%</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <ul class="txt_em">
              <li><span class="icon_star">*</span>평균 88%, 최대 93%까지 할인해 드립니다.</li>
              <li><span class="icon_star">*</span>과금 기준 : 지정국가 요금제 (원/분), 표준요금(원/초)</li>
              <li><span class="icon_star">*</span>해당 요금 적용은 요금제 가입 일시부터 적용됩니다.</li>
              <li><span class="icon_star">*</span>표기된 요금은 VAT가 포함된 요금이며, 상기 표준요금은 비교를 위해 분으로 환산된 금액으로 표기되었습니다.</li>
            </ul>
          </div>
          <div class="btn_center single line_top">
            <!-- TODO : 로그인 페이지로 이동 -->
            <a
              class="btn_type01"
              @click="certKcbToken"
            >
              지정국가 요금제 신청하기
            </a>
          </div>
          <!-- //검색결과박스 -->

          <div class="use_list_area">
            <div class="use_list">
              <h4>쉽게 따라 하는 이용방법</h4>
              <p>한국에서 중국 북경 지역의 <span>휴대폰으로 전화를 걸 경우</span></p>

              <ul>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>00700 국제전화 식별번호</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter15.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호(중국 : 86)</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter36.png"
                      alt=""
                    >
                  </div>
                  <p>상대방 휴대폰 번호(137-123-4567)<br><span><span class="icon_star">*</span>휴대폰 번호는 0번 제외</span></p>
                </li>
              </ul>
            </div>

            <div class="use_list">
              <p>한국에서 중국 북경 지역의 <span>일반전화로 전화를 걸 경우</span></p>
              <ul class="four">
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>00700 국제전화 식별번호</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter15.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호(중국 : 86)</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter27.png"
                      alt=""
                    >
                  </div>
                  <p>지역번호(북경 : 10)<br><span><span class="icon_star">*</span>지역번호 번호는<br class="m_hide"> 0번 제외</span></p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter40.png"
                      alt=""
                    >
                  </div>
                  <p>상대방  번호(123-4567)</p>
                </li>
              </ul>
            </div>
          </div>

          <div class="notice_area gray">
            <h4 class="title">
              <span class="icon_mark"><img
                src="/content/images/icon_mark01.png"
                alt="주의사항"
              ></span>요금제 가입 시 꼭 읽어보세요
            </h4>
            <ul>
              <li><span class="icon_star">*</span>국제전화 00700 국내통화요금제는 6개월간 미 사용 시 자동 취소될 수 있습니다.</li>
              <li><span class="icon_star">*</span>번호 변경 시, 요금제 가입을 다시 신청해야만 기존 혜택이 유지됩니다.</li>
              <li><span class="icon_star">*</span>국제전화 요금제 변경 또는 재가입 신청은 홈페이지 내 마이페이지 또는 고객센터 1599-00700(평일 09시~18시)에서 변경/수정 가능합니다.</li>
              <li><span class="icon_star">*</span>비정상적이거나 불법적인 방법으로 국제전화를 이용할 경우 사전 안내 없이 국제전화 요금제 취소 및 이용에 제한이 있을 수 있습니다.</li>
            </ul>
          </div>
        </div>
        <!-- //inter -->
      </div>
    </div>
    <!--// 컨텐츠 -->
    <Footer />
    <ApplyPlan
      v-if="country.length"
      :is-visible.sync="popup.applyPlan.isVisible"
      :type="popup.applyPlan.type"
      :code="popup.applyPlan.code"
      :plan-name="popup.applyPlan.planName"
      :usage-plan="popup.applyPlan.usagePlan"
      :cert-info="certResult"
      :list="country"
      @click="ApplyPlan"
    />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'
import Autocomplete from '@/components/Autocomplete'
import ApplyPlan from '@/components/popup/ApplyPlan'

export default {
  name: 'CallingPlanSpecified',
  metaInfo: {
    title: '지정국가 요금제 | 특정국가 할인형 | 국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
    Autocomplete,
    ApplyPlan
  },
  data () {
    return {
      formData: {},
      selectedCountry: {},
      country: [],
      resultAreaOpen: false,
      result: {},
      popup: {
        applyPlan: {
          isVisible: false,
          type: '',
          planName: '',
          code: '',
        }
      },
      certResult: {},
    }
  },
  computed: {
    token() {
      return this.$store.getters.KCB_CERT_KEY
    }
  },
  created () {
    api.ItsvcGetPrePricePlanInfo({ req_tp: 'NATL' })
      .then(response => {
        this.country = response.data
      })
  },
  methods: {
    selectCountry(item) {
      this.selectedCountry = item.selectedObject
    },
    inquire() {
      if (!this.formData.natl_cd) {
        this.$store.commit('ON_ALERT_POPUP', { message: '국가명을 입력하세요.' })
      } else {
        const data = {
          "req_tp": "NATL",
          "natl_cd": this.formData.natl_cd
        }
        api.ItsvcGetPricePlanInfo(data)
        .then(response => {
          this.result = response.data
          this.resultAreaOpen = true
        })
      }
    },
    ApplyPlan(natCode) {
      if (natCode) {
        this.popup.applyPlan.isVisible = false
        api.ItsvcPostSubsPricePlan({ chrg_cd: 'CON', mdl_tkn: this.token, nat_cd: natCode })
      } else {
        this.$store.commit('ON_ALERT_POPUP', { message: '국가명을 선택하세요.' })
      }
    },
    certKcbToken() {
      if (this.token) { // 토큰 있으면
        api.CommonGetReqKcbAuth({ mdl_tkn: this.token })
        .then(response => {
          this.certResult = response.data
          if (response.data.result_cd === '0001') {
            this.getCommonData()
          }
        })
      } else {  // 토큰 없으면 : 인증 레이어 팝업 띄우기
        this.$store.commit('ON_CERT_POPUP')
      }
    },
    // 사용중인 요금제 조회
    getCommonData() {
      api.ItsvcGetSvcUseInfo({ mdl_tkn: this.token})
        .then(response => {
          if (response.data.result_cd === '0000' || response.data.result_cd === 'E015') {
            if (response.data.result_cd === '0000') {
              this.popup.applyPlan.usagePlan = response.data.svc_info.chrg_cd_nm
            }
            this.popup.applyPlan.type = 'special'
            this.popup.applyPlan.planName = '지정국가'
            this.popup.applyPlan.isVisible = true
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.inter_seach {
  overflow: visible;
  div.type01 {
    position: relative;
    overflow: visible;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}
.natl-list{
  position: absolute;
  left: 0;
  top: 57px;
  z-index: 999;
  width: 384px;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #ddd;
  background: #eee;
  color: #333;
  padding: 2px;
  margin: 0;
  outline: none;
  li {
    line-height: 28px;
    text-align: left;
    &:hover {
      border: 1px solid #fbd850;
      background: #fff;
      font-weight: bold;
      color: #eb8f00;
    }
    a {
      display: block;
      cursor: pointer;
    }
  }
}

.inter .result_area {
  display: block;
}
</style>
