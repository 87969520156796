
<template>
  <div id="SK00701">
    <div class="event_wrap sk00701">
      <div class="event_header">
        <div class="logo">
          <div>
            <img
              src="/content/images/partnership/220523_sk00701_logo1.png"
              alt="SK텔링크"
            >
          </div>
          <!-- <div>
            <img
              src="/content/images/partnership/220523_sk00701_logo2.png"
              alt="00701"
            >
          </div> -->
        </div>
        <h1 class="title">
          <img
            src="/content/images/partnership/220523_sk00701_text1.png"
            alt="국제전화 00701 이용 내역 조회 서비스"
          >
        </h1>
      </div>
      <div class="event_view">
        <div>
          <div class="event_view_field">
            <div class="event_content">
              <div class="simple_type">
                <dl>
                  <dt>고객 번호</dt>
                  <dd>
                    <div class="insert_phone">
                      <select
                        id="call_select01"
                        ref="telNumberFirst"
                        v-model="telNumber.first"
                        name="i_sHpNo1"
                        title="무선전화 앞자리 선택"
                      >
                        <option
                          value="010"
                        >
                          010
                        </option>
                        <option value="011">
                          011
                        </option>
                        <option value="016">
                          016
                        </option>
                        <option value="017">
                          017
                        </option>
                        <option value="018">
                          018
                        </option>
                        <option value="019">
                          019
                        </option>
                      </select>
                      <span class="txt_hyphen" />
                      <input
                        ref="telNumberMiddle"
                        v-model="telNumber.middle"
                        name="i_sHpNo2"
                        title="전화번호"
                        type="text"
                        maxlength="4"
                        pattern="[0-9]*"
                        class="numbersOnly"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                      <span class="txt_hyphen" />
                      <input
                        ref="telNumberLast"
                        v-model="telNumber.last"
                        name="i_sHpNo3"
                        title="전화번호"
                        type="text"
                        maxlength="4"
                        pattern="[0-9]*"
                        class="numbersOnly"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                    <button
                      type="button"
                      class="btn btn_cert"
                      @click="requestSMSCert"
                    >
                      <img
                        src="/content/images/partnership/220523_sk00701_btn1.png"
                        alt="본인인증"
                      >
                    </button>
                  </dd>
                </dl>
                <dl
                  v-if="authNumberInputVisible"
                  id="numberCheck"
                >
                  <dt>인증번호</dt>
                  <dd>
                    <div class="insert_certNum">
                      <input
                        ref="certNumber"
                        v-model="auth_no"
                        name="i_sCertificationNo"
                        title="인증번호"
                        type="text"
                        maxlength="6"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                    <button
                      class="btn btn_cert"
                      @click="requestCert"
                    >
                      <img
                        src="/content/images/partnership/220523_sk00701_btn2.png"
                        alt="인증완료"
                      >
                    </button>
                  </dd>
                </dl>
                <!-- 개인정보 동의 -->
                <div class="ui-agree">
                  <div>
                    <input
                      id="c_a01"
                      v-model="checkbox"
                      type="checkbox"
                      @click="privacy1Popup"
                    > 
                    <label for="c_a01">개인정보 수집 및 이용에 동의합니다.</label>
                  </div>
                </div>
                <!-- // 개인정보 동의 -->
              </div>

              <div class="event_active_btn">
                <a
                  id="btnApply"
                  @click="inquireFinal"
                ><img
                  src="/content/images/partnership/220523_sk00701_btn3.png"
                  alt="이용 내역 조회하기"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 이용 내역 없을 경우, 팝업 화면 -->
    <div
      v-if="alertPopup2.visible"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <p class="ui-msg">
            {{ date.start }} 21 ~ {{ date.end }} 이용내역이 없습니다.<br>
            오늘 00701 통화 내역은 내일부터 조회 가능합니다.
          </p>
          <ul class="ui-list">
            <li>
              고객센터 : 
              <img
                src="/content/images/partnership/220523_sk00701_ico_tel.png"
                alt="확인"
              >
              1599-0070(유료)
            </li>
            <li>
              운영시간 : 평일 9시~18시(점심시간 12시~13시)
            </li>
          </ul>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="alertPopup2.visible = false"
            >
              <img
                src="/content/images/partnership/220523_sk00701_btn4.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </div>

    <!-- 팝업 -->
    <article
      v-if="isVisiblePrivacy1Popup"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-header">
          <h1>개인정보 수집 및 이용 동의(필수)</h1>
        </div>
        <div class="ui-popup-container">
          <ul>
            <li class="ui-dash">
              수집하는 개인정보의 항목: 휴대폰번호
            </li>
            <li class="ui-dash">
              개인정보의 이용목적: 고객의 00701 국제전화 이용 내역 정보 제공
            </li>
            <li class="ui-dash">
              보유 및 이용기간: <strong>국제전화 이용 내역 조회 후 즉시 파기</strong>
            </li>
            <li>※ 미동의 시 조회가 불가합니다.</li>
          </ul>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="isVisiblePrivacy1Popup = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </article>

    <!-- 팝업 -->
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    <!-- //팝업 -->
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
import { format } from 'path'
export default {
  name: 'SK00701',
  components: {
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    alertPopup2: {
      visible: false
    },
    telNumber:{
      first:'010',
      middle: '',
      last:''
    },
    authNumberInputVisible: false,
    isVisiblePrivacy1Popup: false,
    checkbox: '',
    ticket: '',
    auth_no: '',
    date: {
      now: '',
      start: '',
      end: '',
      formDate: ''     
    },
  }
 },
 computed: {
  svc_tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  callbackAlert(){
    return this.$store.getters.CALLBACK_ALERT
  }
 },
 watch: {
  callbackAlert: {
    deep: true,
    handler() {
      if (this.callbackAlert.code === '0002' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E0027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E021' || this.callbackAlert.code === 'E022') {
        this.resetPage()
        window.scrollTo(0, 0)
        document.getElementById('wrap').scrollTop = 0
      }
      if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
        this.authNumberInputVisible = false,
        this.telNumber = {
          first:'010',
          middle: '',
          last:''
        },
        this.auth_no = ''
        this.ticket = ''
      }
      if (this.callbackAlert.code === 'E001') { // 인증시간 초과
        this.auth_no = ''
        this.ticket = ''
      }
    }
  }
},
 created () {
  this.applydate()
},
 mounted () {
    
 },
 methods: {
  alertPopupHandler() {
    this.alertPopup.visible = false
    this.alertPopup.message = ''
    if (this.alertPopup.target) {
      this.$refs[this.alertPopup.target].focus()
    }
    this.alertPopup.target = ''
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
      return false
    } else {
      return true
    }
  },
  checkAuthNumber() {
    if (!this.auth_no) {
      this.alertPopup.target = 'certNumber'
      this.showAlertPopup('인증번호를 입력해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkSMSCert() {
    if(!this.isSmsCert) {
      this.showAlertPopup('SMS인증을 완료해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkCheckbox() {
    if(!this.checkbox) {
      alert('개인정보 수집 이용에 동의해주세요.')
      return false
    } else {
      return true
    }
  },
  // SMS 인증 요청
  requestSMSCert() {
    if (this.checkPhoneNumber()) {
      api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
      .then(response => {
        if (response.data.sms_ticket) {
          this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
          this.ticket = response.data.sms_ticket
          this.authNumberInputVisible = true
        }
      })
    }
  },
  // 인증 번호로 SMS 인증 확인
  requestCert() {
    if (this.checkAuthNumber()) {
      api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
      .then(response => {
        if (response.data.result_cd === '0001') {
          this.isSmsCert = true
          this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
        }
      })
    }
  },
  //신청완료
  inquireFinal() {
    if (this.checkPhoneNumber() && this.checkSMSCert() && this.checkCheckbox()) {
      api.ItsvcPostUsed701Info({sms_ticket: this.ticket, svc_st_dt: '20220521'})
      .then(response => {
        if (response.data.result_cd === '0001') {
            // this.$router.push('/partnership/Sk00701Detail');
            this.$router.push({path: '/partnership/Sk00701Detail', query: { auth_no: this.auth_no, sms_ticket: this.ticket }})
          } else if (response.data.result_cd === 'E014') {
            // 이용 내역 없을 경우, 팝업 화면
            this.applydate()
            this.alertPopup2.visible = true
            // Test
            // this.$router.push('/partnership/Sk00701Detail')
            this.resetPage()
           }
        })
       }
    },
  applydate() {
    this.formatDate()
    if ((this.date.formDate.substr(-2)) <= 20) {
      this.date.start = new Date((this.date.now).setMonth((this.date.now).getMonth() - 1)).toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: 'numeric'
        })
     } else {
       this.date.start = new Date((this.date.now).setMonth((this.date.now).getMonth())).toLocaleDateString('ko-KR', {
        year: 'numeric',
        month: 'numeric'
        })
     }
    this.date.now = new Date()
    this.date.end = new Date((this.date.now).setDate((this.date.now).getDate() - 1)).toLocaleDateString('ko-KR')
  },
  formatDate() {
    this.date.now = new Date()
    this.date.formDate = (this.date.now).toISOString().slice(0,10).replace(/-/g,"")
  },
  resetPage(){
    this.telNumber= {
      first:'010',
      middle: '',
      last:''
    },
    this.authNumberInputVisible = false,
    this.auth_no = ''
    this.ticket = ''
    this.isSmsCert = false
  },
  privacy1Popup() {
    if(!this.checkbox) {
      this.isVisiblePrivacy1Popup = true
    }
  },
 }
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');

.sk00701 {
  width: 1120px;
  margin: 0 auto;
  margin-top: 40px;
  .event_header {
    padding: 35px 40px 0;
    background-color: #fff2ee;
    img { width: auto; }
    .logo {
      display: flex;
      justify-content: space-between;
    }
    .title {
      margin-top: 70px;
      text-align: center;
    }
  }
  .event_view {
    width: 100%;
    margin: 0;
    padding: 80px 70px;
    background-color: #fff2ee;
    & > div {
      margin-top: 0;
      overflow: visible;
      text-align: left;
    }
  }
  .event_view_field {
    margin-top: 0;
    padding: 100px 70px 80px;
    background-color: #fff;
    border-radius: 45px;
    box-shadow: 10px 10px 16px rgba(0,0,0,.1);
    .event_content {
      .simple_type {
        dl {
          margin-top: 80px;
          &:first-child {
            margin-top: 0;
          }
          &#numberCheck {
            dd {
              &:after { display: none; }
              display: flex;
              justify-content: space-between;
              .btn.btn_cert {
                width: 35%;
                margin-top: 0;
                margin-left: 20px;
              }
            }
          }
          dt {
            color: #2a2a2a;
            font-family: "NanumSquareRound";
            font-size: 50px;
            font-weight: 700;
            line-height: 50px;
          }
          dd {
            margin-top: 28px;
            &:after {
              content: ''; display: block; clear: both; overflow: hidden;
            }
          }
        }
      }
      .insert_phone {
        height: 126px;
        & > * { vertical-align: middle; }
        select {
          width: 30%;
          height: 100%;
          padding-left: 30px;
          color: #000;
          font-family: "NanumSquareRound";
          font-size: 50px;
          font-weight: 700;
          line-height: 100%;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: #fff url('/content/images/btn_arrow_selelct.png') no-repeat calc(100% - 12px) 50%/16px 9px;
          border: 2px solid #505050;
          border-radius: 20px;
        }
        input[type=number], input[type=text] {
          width: 30%;
          height: 100%;
          padding: 0;
          color: #000;
          font-family: "NanumSquareRound";
          font-size: 50px;
          font-weight: 700;
          line-height: 126px;
          text-align: center;
          background-color: #fff;
          border: 2px solid #505050;
          border-radius: 20px;
        }
        .txt_hyphen {
          display: inline-block;
          position: relative;
          width: 5%;
          height: 100%;
          text-align: center;
          line-height: 100%;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px; height: 2px; background-color: #2a2a2a;
            margin: -1px 0 0 -4px;
          }
        }
      }
      .insert_certNum {
        height: 126px;
        input[type=number], input[type=text] {
          width: 100%;
          height: 100%;
          padding: 0;
          color: #000;
          font-family: "NanumSquareRound";
          font-size: 50px;
          font-weight: 700;
          line-height: 126px;
          text-align: center;
          background-color: #fff;
          border: 2px solid #505050;
          border-radius: 20px;
        }
        & + .btn.btn_cert {
          height: 126px;
        }
      }
      .btn.btn_cert {
        display: block;
        width: 100%;
        height: 126px;
        margin-top: 40px;
        background: #ff6432;
        border-radius: 20px;
        img { width: auto; }
      }
      .event_active_btn {
        margin-top: 80px;
        text-align:  center;
        a {
          display: block;
          padding: 46px 120px;
          border-radius: 70px;
          background-color: #420d8a;
          img { width: auto; }
        }
      }
    }
  }
}
.ui-popup {
  position: fixed;
  left: 0; top: 0;
  z-index: 9999;
  width: 100%; height: 100vh;
  background-color: rgba(0,0,0,.8);

  .ui-popup-wrap {
    position: fixed;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 24px 25px;
    background-color: #fff;
    border-radius: 15px;
    .ui-popup-header {
      padding: 20px 0 18px;
      color: #222;
      font-family: 'NanumSquareRound',sans-serif;
      font-size: 15px;
      font-weight: 700;
      border-bottom: 1px solid #dbdbdb;
    }
    .ui-popup-container {
      padding-top: 10px;
      li {
        position: relative;
        color: #222;
        font-family: 'NanumSquareRound',sans-serif;
        font-size: 14px;
        font-weight: 400;
        &.ui-dash {
          padding-left: 8px;
          &:before {
            content: '-';
            position: absolute; left: 0;
          }
        }
        strong {
          color: #ff0101;
          font-size: 16px;
          font-family: inherit;
          font-weight: 700;
          text-decoration: underline;
        }
      }
      .ui-msg {
        padding: 35px 0 20px;
        color: #222;
        font-family: 'NanumSquareRound',sans-serif;
        font-size: 16px;
        font-weight: 700;
        text-align: center;
        line-height: 1.5;
      }
      .ui-list {
        li {
          font-family: 'NanumSquareRound',sans-serif;
          font-size: 16px;
          font-weight: 700;
          img { width: 15px; margin-left: 5px; vertical-align: middle; }
          &:before {
            content: '·';
            display: inline-blick;
          }
        }
      }
      .ui-btn-wrap {
        margin-top: 25px;
        .ui-btn {
          display: block;
          padding: 20px 0;
          line-height: 0;
          text-align: center;
          background-color: #420d8a;
          border-radius: 30px;
          img { width: auto; height: 18px; }
        }
      }
    }
  }
}

.ui-agree {
  margin-top: 33px;
  & > div {
    padding: 17px 0;
  }
  input[type=checkbox] {
    width: 52px; height: 52px;
    margin-right: 20px;
    background: #d3d3d3 url('/content/images/partnership/220511_baroclub_check.png') no-repeat;
    background-size: 100% auto;
    border-radius: 10px;
    vertical-align: top;
    &:checked {
      background-color: #ff6432;
    }
  }
  label {
    color: #222;
    font-family: 'NanumSquareRound',sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
  }
}

@media (max-width: 1023px) {
#SK00701 {
  height: calc(100% - 40px);
  background-color: #fff2ee;
}
.sk00701 {
  width: 100%;
  padding-bottom: 0;
  .event_header {
    padding: 12px 15px 0;
    .logo {
      display: flex;
      justify-content: space-between;
      img { height: 19px; }
    }
    .title {
      margin-top: 12px;
      img { width: 209px; }
    }
  }
  .event_view {
    width: 100%;
    margin: 0;
    padding: 27px 24px;
  }
  .event_view_field {
    padding: 33px 24px 27px;
    border-radius: 15px;
    .event_content {
      .simple_type {
        dl {
          margin-top: 27px;
          &#numberCheck {
            dd {
              .btn.btn_cert {
                width: 35%;
                margin-top: 0;
                margin-left: 7px;
              }
            }
          }
          dt {
            font-size: 17px;
            line-height: 17px;
          }
          dd {
            margin-top: 9px;
          }
        }
      }
      .insert_phone {
        height: 40px;
        select { padding-left: 10px; }
        select, input[type=number], input[type=text] { font-size: 16px; line-height: 40px; border-width: 1px; border-radius: 7px; }
        .txt_hyphen:before { width: 4px; height: 1px; margin-left: -2px; }
      }
      .insert_certNum {
        height: 40px;
        input[type=number], input[type=text] { font-size: 16px; line-height: 40px;  border-width: 1px; border-radius: 7px; }
        & + .btn.btn_cert {
          height: 40px;
          padding: 0 10px;
        }
      }
      .btn.btn_cert {
        height: 40px;
        margin-top: 14px;
        border-radius: 7px;
        img { height: 18px; }
      }
      .event_active_btn {
        margin-top: 27px;
        a {
          padding: 12px 40px;
          line-height: 0;
          img { height: 18px; }
        }
      }
    }
  }
}
.ui-agree {
  margin-top: 11px;
  & > div {
    padding: 6px 0;
    line-height: 0;
  }
  input[type=checkbox] {
    width: 14px; height: 14px;
    margin-right: 7px;
    border-radius: 3px;
  }
  label {
    font-size: 13px;
    line-height: 14px;
  }
}
}
</style>
