<template>
  <div id="ONLINE_CONSULT">
    <Header />
    <!-- 컨텐츠 -->
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            1:1 상담센터
          </h3>
          <p class="txt">
            자주하는 질문 모음에서도 궁금한 사항을 찾지 못하셨나요?<br>1:1온라인 상담으로 문의하세요
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- 회원 customer -->
        <div
          v-if="certResult.result_cd === '0001'"
          id="tab1"
        >
          <div class="customer">
            <h4 class="title">
              <span class="txt_point01">{{ certResult.cust_nm }}고객님</span> 안녕하세요.
            </h4>

            <div class="table_title modify">
              <span class="fr txt_point01"><span class="icon_star">*</span> 필수입력항목</span>
            </div>
            <div class="table_type01">
              <dl>
                <dt>전화번호</dt>
                <dd>{{ certResult.svc_tel_no }}</dd>
              </dl>
              <dl>
                <dl>
                  <dt>항목 <span class="txt_point01">*</span></dt>
                  <dd>
                    <div
                      v-for="(category, categoryIndex) in categories.svc_tp_list"
                      :key="`category${categoryIndex}`"
                      class="radio_box"
                    >
                      <input
                        :id="`category${categoryIndex}`"
                        v-model="formData.svc_tp"
                        type="radio"
                        name="sub"
                        :value="category.svc_tp"
                        @change="changeCategory"
                      >
                      <label :for="`category${categoryIndex}`">{{ category.svc_tp_nm }}</label>
                    </div>
                    <a
                      href="https://www.sk7mobile.com/ccsc/board/counselDirectForm.do"
                      class="btn_arrow"
                      target="_blank"
                      title="SK 7mobile 새창열림"
                    ><span>SK 7mobile(알뜰폰) 1:1 고객상담</span></a>
                  </dd>
                </dl>
              </dl>
              <dl>
                <dt>유형선택 <span class="txt_point01">*</span></dt>
                <dd>
                  <div class="select_box wid252">
                    <label
                      id="type_select01"
                      for="type_select01"
                      aria-hidden="true"
                    >구분</label>

                    <select
                      id="type_select01"
                      v-model="formData.bulletin_tp"
                      name="i_sCategoryHighList"
                      title="구분"
                      @change="changeDivision1"
                    >
                      <option
                        v-if="!formData.bulletin_tp"
                        value=""
                      >
                        구분
                      </option>
                      <template v-for="(division1, division1Index) in categories.bulletin_tp_list">
                        <option 
                          v-if="division1.svc_tp === formData.svc_tp"
                          :key="`bulletin_tp${division1Index}`"
                          :value="division1.bulletin_tp"
                        >
                          {{ division1.bulletin_tp_nm }}
                        </option>
                      </template>
                      회원정보
                    </select>
                  </div>

                  <div
                    id="categoryLowList"
                    class="select_box wid252"
                  >
                    <label
                      id="type_select02"
                      for="type_select02"
                      aria-hidden="true"
                    >상세카테고리 선택</label>

                    <select
                      id="type_select02"
                      v-model="formData.bulletin_tp_dtl"
                      name="i_sCategoryLowList"
                      title="상세카테고리 선택"
                    >
                      <option value="">
                        상세카테고리 선택
                      </option>
                      <template v-for="(division2, division2Index) in categories.bulletin_tp_dtl_list">
                        <option
                          v-if="division2.bulletin_tp === formData.bulletin_tp"
                          :key="`bulletin_tp_dtl${division2Index}`"
                          :value="division2.bulletin_tp_dtl"
                        >
                          {{ division2.bulletin_tp_dtl_nm }}
                        </option>
                      </template>
                    </select>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>내용 <span class="txt_point01">*</span></dt>
                <dd>
                  <div class="textarea">
                    <textarea
                      v-model="formData.rcpt_desc"
                      rows="10"
                      cols="50"
                      title="1:1 상담 내용입력"
                      name="i_sQuestionContent"
                    />
                  </div>
                </dd>
              </dl>
            </div>
            <div class="terms_box">
              <div class="terms">
                <h5 class="title">
                  <span class="org_txt font_15">개인정보수집 동의</span>
                </h5>
                <div
                  class="scroll_box"
                  tabindex="0"
                >
                  <p><b>온라인상담 관련 개인정보 수집/이용 동의</b></p>
                  <p>1. 수집항목 : 휴대폰번호</p>
                  <p>2. 이용목적 : SK텔링크 전반적인 상담(SMS, 카카오 알림톡 안내)</p>
                  <p>3. 개인정보 보유기간 및 이용기간 : <span class="txt_point01">상담 등록 후 3년까지</span></p>
                  <p>4. 개인정보 수집/이용에 동의하지 않을 시, 온라인상담 이용 불가</p>
                </div>
                <div class="check_box">
                  <input
                    id="terms01"
                    v-model="agreeTerms"
                    type="checkbox"
                    name="id_terms01"
                    class="check_single"
                  >
                  <label for="terms01"><span>위 내용을 숙지하였으며 이에 동의합니다.</span></label>
                </div>
              </div>
            </div>
            <div class="btn_center">
              <a
                id="btn_save"
                class="btn_type01"
                @click="save"
              >확인</a>
              <a
                id="btn_cancel"
                class="btn_type02"
                @click="reset"
              >초기화</a>
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import util from '@/utilities'
import Header from "@/components/Header";
import BreadCrumb from "@/components/BreadCrumb";
import Footer from "@/components/Footer";
import SummitControl from '@/components/SummitControl'

const DEFAULT_FORMDATA = {
  svc_tp: '',
  bulletin_tp: '',//
  bulletin_tp_dtl: '',
  rcpt_tp: '',	// 추후 정의 
  rcpt_desc: ''	//string	1000 문의내용
}

export default {
  name: 'OnlineConsult',
  metaInfo: {
    title: '1:1 문의신청 | 고객상담 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:1,
      categories: {},
      saveQuestion:{},
      formData: util.copyDifferentlyData(DEFAULT_FORMDATA),
      agreeTerms: false,
      certResult: {}
    }
  },
  computed: {
    token() {
      return this.$store.getters.KCB_CERT_KEY
    }
  },
  created () {
    if (this.token) { // 토큰 있으면
    api.CommonGetReqKcbAuth({ mdl_tkn: this.token })
      .then(response => {
        this.certResult = response.data
        if (response.data.result_cd === '0001') {
          this.formData.mdl_tkn = this.token
          this.getCommonData()
        }
      })
    } else {  // 토큰 없으면 : 인증 레이어 팝업 띄우기
      this.$store.commit('ON_CERT_POPUP')
    }
  },
  mounted () {
  },
  methods: {
    getCommonData() {
      api.BulletinGetCommonCode()
        .then(response => {
          this.categories = response.data
        })
    },
    save(){
      if (!this.formData.svc_tp) {
        this.$store.commit('ON_ALERT_POPUP', { message: '항목을 선택해 주세요.' })
      } else if (!this.formData.bulletin_tp_dtl) {
        this.$store.commit('ON_ALERT_POPUP', { message: '유형을 선택해 주세요.' })
      } else if (!this.formData.rcpt_desc) {
        this.$store.commit('ON_ALERT_POPUP', { message: '내용을 입력해 주세요.' })
      } else if (this.agreeTerms !== true) {
        this.$store.commit('ON_ALERT_POPUP', { message: '개인정보 수집/이용에 동의하지 않을 시, 온라인상담 이용이 불가합니다.' })
      } else {
        this.formData.rcpt_tp = this.formData.bulletin_tp_dtl
        api.ItsvcPostSaveQuestion(this.formData)
          .then(response => {
            if (response.data.result_cd === '0002') {
              this.reset()
            }
          })
      }
    },
    changeCategory() {
      this.categories.bulletin_tp_list.some(item => {
        if (this.formData.svc_tp === item.svc_tp) {
          this.formData.bulletin_tp = item.bulletin_tp
          return this.formData.svc_tp === item.svc_tp
        }
      })
    },
    changeDivision1() {
      this.formData.bulletin_tp_dtl = ''
    },
    reset() {
      this.agreeTerms = false
      this.formData = util.copyDifferentlyData(DEFAULT_FORMDATA)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
