<template>
  <div id="INTRO_00700">
    <Header />
    <div id="container" class="sub_main">
      <BreadCrumb />
      <div id="contents">
        <div class="sms">
          <div class="sub_visual">
            <div class="inner">
              <h3>
                국내 최초,<br />
                국제 SMS 서비스
              </h3>
              <p>전세계 200여국 이상의 커버리지를 확보하고 있습니다.</p>
            </div>
          </div>

          <div class="sms_section sms_message">
            <p class="section_title">
              국내 최초 국제 메시징 사업자, <br />
              SK 텔링크의 <span class="emphasis">기업형 국제 메시지</span>를
              만나보세요.
            </p>
            <ul class="sms_message_list">
              <li>
                <div class="img_wrap">
                  <img
                    class="first"
                    src="/content/images/sms_content01.png"
                    alt="국내 최초"
                  />
                </div>
                <span>국내 최초</span>
              </li>
              <li>
                <div class="img_wrap">
                  <img
                    class="second"
                    src="/content/images/sms_content02.png"
                    alt="가장 신뢰할 수 있는"
                  />
                </div>
                <span>가장 신뢰할 수 있는</span>
              </li>
              <li>
                <div class="img_wrap">
                  <img
                    class="third"
                    src="/content/images/sms_content03.png"
                    alt="전세계 200개국 이상 커버리지"
                  />
                </div>
                <span>전세계 200개국 이상 <br />커버리지</span>
              </li>
            </ul>
          </div>
          <div class="sms_section sms_service">
            <p class="section_title">기업형 국제 메시지 서비스 소개</p>
            <p class="section_sub_title">서비스 개요</p>
            <p class="section_sub_txt">
              당사는 국제 메시지 서비스 국내 최초 상용화 기업으로서 지난 20년간
              Market을 Leading 해왔으며, <br />
              고객사에선 에이전트 연동을 통해 전세계 200개 국가, 990개
              이동통신사로 국제 메시지를 전송하실 수 있습니다.
            </p>
            <div class="sms_service_list_wrap">
              <dl class="sms_service_list">
                <div class="list_inner">
                  <dt>국내 최초 <br class="m_br" />국제 메시징 사업자</dt>
                  <dd>최초</dd>
                </div>
                <div class="list_inner">
                  <dt>국내 Top 수준의 <br class="m_br" />국제 메시징 사업자</dt>
                  <dd>TOP</dd>
                </div>
              </dl>
              <dl class="sms_service_list">
                <div class="list_inner">
                  <dt>200개 국 이상 국가 Coverage 보유</dt>
                  <dd>200개국</dd>
                </div>
                <div class="list_inner">
                  <dt>
                    전세계 990개 이상<br />
                    이동전화 사업자에 <br class="m_br" />전송 가능
                  </dt>
                  <dd>990개社</dd>
                </div>
              </dl>
              <dl class="sms_service_list">
                <div class="list_inner">
                  <dt>국제 메시지 <br class="m_br" />국내 최고 수준 처리</dt>
                  <dd>최고수준</dd>
                </div>
                <div class="list_inner">
                  <dt>24시간 NOC 운용</dt>
                  <dd>24시간</dd>
                </div>
              </dl>
            </div>
            <div class="sms_inquiry_wrap">
              <img
                src="/content/images/sms_content04.png"
                alt="기업형 국제 SMS 문의"
              />
              <p class="section_sub_title">기업형 국제 SMS 문의</p>
              <p class="email_txt">biz_proposal@sk.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import BreadCrumb from "@/components/BreadCrumb";
import Footer from "@/components/Footer";
// import Input from '@/components/Input'

export default {
  name: "Sms",
  metaInfo: {
    title: "기업형 국제 SMS | 국제통신서비스 | 사업소개",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
  },
  emits: [],
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
$point_color: red;
$text_color222: #222;
$text_color333: #333;
$text_color444: #444;
$text_color666: #666;
.point_color {
  color: $point_color;
  font-weight: inherit;
  line-height: inherit;
}
.sms {
  letter-spacing: -0.075em;
  .sub_visual {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 58px;
    height: 373px;
    background: url("/content/images/sms_visual.jpg") no-repeat center;
    background-size: cover;

    .inner {
      width: 1120px;
      margin: auto;
      padding: 40px 0;
      text-align: center;

      h3 {
        color: $text_color222;
        font-size: 50px;
        font-weight: 400;
      }
      img {
        width: auto;
        margin-top: 21px;
      }
      P {
        margin-top: 25px;
        color: $text_color222;
        font-size: 20px;
        line-height: 25px;
      }
    }
  }

  .section_title {
    color: $text_color333;
    font-size: 44px;
    font-weight: 300;
    line-height: 60px;

    + .section_sub_title {
      margin-top: 40px;
    }
  }

  .emphasis {
    color: #ff0000;
  }

  .section_sub_title {
    font-size: 28px;
    line-height: 1;
    color: #ff0000;

    + .section_sub_txt {
      margin-top: 18px;
    }
  }

  .section_sub_txt {
    font-size: 24px;
    line-height: 34px;
    color: #666666;
  }

  .sms_section {
    text-align: center;
    padding: 120px 0;

    &.sms_message {
      background-color: #f9f9f9;

      .sms_message_list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0 auto;
        margin-top: 90px;
        width: 100%;
        max-width: 960px;

        li {
          position: relative;
          padding: 0 63px;

          + li {
            &:before {
              content: "";
              display: block;
              position: absolute;
              left: 0;
              top: 36px;
              width: 1px;
              height: 140px;
              background-color: #dddddd;
            }
          }

          .img_wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 233px;
            height: 233px;
            background-color: #ffffff;
            border-radius: 50%;
            box-shadow: 0px 6.54px 40.17px 0px #4c4c4c17;

            img {
              &.first {
                width: 105px;
                height: 128px;
              }

              &.second {
                width: 160px;
                height: 102px;
              }

              &.third {
                width: 168px;
                height: 168px;
              }
            }
          }

          > span {
            display: block;
            margin-top: 40px;
            font-size: 24px;
            line-height: 30px;
            color: #444444;
            font-weight: 400;
            letter-spacing: -0.04em;
          }
        }
      }
    }

    &.sms_service {
      .sms_service_list_wrap {
        padding: 75px 0 120px;
        margin: 0 auto;
        max-width: 1120px;

        .sms_service_list {
          display: flex;
          flex-direction: row;
          border-top: solid 1px #ededed;

          &:last-of-type {
            border-bottom: solid 1px #ededed;
          }

          .list_inner {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            padding: 43px 90px;
            min-height: 126px;
            text-align: left;

            + .list_inner {
              &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
                height: 45px;
                background: #ededed;
              }
            }

            dt {
              font-size: 18px;
              line-height: 26px;
              font-weight: 400;
              color: #444444;
              width: 250px;
            }

            dd {
              margin-left: 20px;
              font-size: 27px;
              line-height: 1;
              font-weight: 500;
              color: #444444;
            }
          }
        }
      }

      .sms_service_txt_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 28px;

        li {
          position: relative;
          padding-left: 12px;
          width: auto;
          font-size: 24px;
          line-height: 1;
          color: #4c4c4c;

          &:before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 8px;
            width: 4px;
            height: 4px;
            background: #4c4c4c;
            border-radius: 50%;
          }

          + li {
            margin-top: 13px;
          }
        }
      }

      .sms_inquiry_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;

        > img {
          display: block;
          width: 74px;
          height: 74px;

          + .section_sub_title {
            margin-top: 17px;
          }
        }

        .email_txt {
          margin-top: 20px;
          font-size: 20px;
          line-height: 1;
          color: #4c4c4c;
        }
      }
    }
  }
}
@media (max-width: 1023px) {
  .sms {
    .btn_arrow {
      letter-spacing: -0.075em;
    }
    .sub_visual {
      height: 258px;
      margin-top: 0;
      background-image: url("/content/images/sms_visual_m.jpg");
      background-size: cover;

      .inner {
        width: 100%;
        padding-top: 34px;
        h3 {
          font-size: 17px;
        }
        img {
          width: 150px;
          margin-top: 9px;
        }
        P {
          margin-top: 15px;
          font-size: 13px;
          line-height: 15px;
        }
        .btn_arrow {
          margin-top: 30px;
          span {
            background-size: 22px auto;
          }
        }
      }
    }

    .section_title {
      font-size: 25px;
      line-height: 32px;

      + .section_sub_title {
        margin-top: 20px;
      }
    }

    .section_sub_title {
      font-size: 20px;

      + .section_sub_txt {
        margin-top: 10px;
      }
    }

    .section_sub_txt {
      font-size: 16px;
      line-height: 24px;
    }

    .emphasis {
      color: #ff0000;
    }

    .sub_title {
      font-size: 24px;
      line-height: 1;
    }

    .sms_section {
      padding: 40px 15px 40px;

      &.sms_message {
        .sms_message_list {
          flex-direction: column;
          align-items: center;
          margin-top: 30px;

          li {
            padding: 0;

            + li {
              margin-top: 30px;
              &:before {
                display: none;
              }
            }

            .img_wrap {
              width: 150px;
              height: 150px;

              img {
                &.first {
                  width: 70px;
                  height: 85px;
                }

                &.second {
                  width: 107px;
                  height: 68px;
                }

                &.third {
                  width: 112px;
                  height: 112px;
                }
              }
            }

            > span {
              margin-top: 15px;
              font-size: 18px;
              line-height: 26px;
            }
          }
        }
      }

      &.sms_service {
        .sms_service_list_wrap {
          padding: 40px 0;

          .sms_service_list {
            display: flex;
            flex-direction: row;
            border-top: solid 1px #ededed;

            &:last-of-type {
              border-bottom: solid 1px #ededed;
            }

            .list_inner {
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              padding: 20px;
              min-height: initial;
              text-align: center;

              dt {
                flex: 1;
                width: auto;
                font-size: 16px;
              }

              dd {
                margin-left: 0;
                margin-top: 10px;
                font-size: 18px;
              }
            }
          }
        }

        .sms_service_txt_list {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 15px;

          li {
            padding-left: 10px;
            font-size: 16px;

            &:before {
              width: 3px;
              height: 3px;
            }

            + li {
              margin-top: 13px;
            }
          }
        }

        .sms_inquiry_wrap {
          display: flex;
          flex-direction: column;
          align-items: center;

          > img {
            display: block;
            width: 60px;
            height: 60px;

            + .section_sub_title {
              margin-top: 17px;
            }
          }

          .email_txt {
            margin-top: 10px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
