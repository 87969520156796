<template>
  <div id="ENG_MAIN" class="eng">
    <Header />
    <div id="container">
      <div class="key_slide en">
        <div class="swiper-wrapper">
          <div class="swiper-slider">
            <span class="w_hide">
              <a href="">
                <img
                  src="/content/images/w_main_visual_eng_20210520.jpg"
                  alt=""
                />
                <div class="key_slide_cont">
                  <p class="key_slide_text">
                    “<span>SK telink</span> seeks to become a trusted company<br />
                    that ultimately contributes to the happiness of all <br />
                    stakeholders by providing new efficacy and value<br />
                    previously unimagined by customers.”
                  </p>
                </div>
              </a>
            </span>
            <span class="m_hide">
              <a href="">
                <img
                  src="/content/images/m_main_visual_eng_20210520.jpg"
                  alt=""
                />
                <div class="key_slide_cont">
                  <p class="key_slide_text">
                    “<span>SK telink</span> seeks to<br />
                    become a trusted company<br />
                    that ultimately contributes to<br />
                    the happiness of all stakeholders<br />
                    by providing new efficacy<br />
                    and value previously<br />
                    unimagined by customers.”
                  </p>
                </div>
              </a>
            </span>
          </div>
        </div>
        <div class="indicator slick-dots" />
      </div>

      <div class="en_kv">
        <img class="w_hide" src="/content/images/en_kv.jpg" />
        <img class="m_hide" src="/content/images/en_kv_m.jpg" />
      </div>

      <ul class="service_banners en">
        <li class="inter">
          <dl>
            <dd>
              Korea’s representative<br />
              International Direct Dialing brand<br />
              ‘SK 00700’ topped the three major<br />
              customer satisfaction surveys in<br />
              Korea for seven consecutive years.
            </dd>
          </dl>
        </li>
        <li class="sms">
          <dl>
            <dd>
              International SMS service<br />
              As the first company in Korea to commercialize<br />
              international messaging services, we have led <br />
              the market for the past 20 years. We currently <br />
              cover over 200 countries worldwide with <br />
              our extensive coverage.
            </dd>
          </dl>
        </li>
        <li class="comp">
          <dl>
            <dd>
              Satellite communication service<br />
              provides value-added solutions to<br />
              ships and maritime areas worldwide.
            </dd>
          </dl>
        </li>
        <li class="mobile_cell">
          <dl>
            <dd>
              MVNO Leads the ‘mobile value<br />
              consumption’ era with a brand<br />
              of SK 7mobile.
            </dd>
          </dl>
        </li>
      </ul>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "../../components/EngHeader";
import Footer from "../../components/EngFooter";

export default {
  name: "Main",
  metaInfo: {
    title: "Main | SK telink",
    titleTemplate: null,
  },
  components: {
    Header,
    Footer,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
