<template>
  <div id="FINANCIAL">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg05">
          <h3 class="title">재무정보</h3>
          <p class="txt">
            SK텔링크의 투명한 경영으로 글로벌 통신회사로<br />도약하는
            기업가치를 높입니다
          </p>
          <img src="/content/images/sub_header_cp05.jpg" alt="비쥬얼 이미지" />
        </div>

        <!-- tab -->
        <div class="tab_type01 tab3">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li class="on" title="현재메뉴 선택됨">
              <router-link to="/view/investor/financial">
                주요재무제표
              </router-link>
            </li>
            <li>
              <router-link to="/view/investor/stockholders">
                주주현황
              </router-link>
            </li>
            <li>
              <router-link to="/view/investor/credit_rating">
                신용등급
              </router-link>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <div class="fs_table">
            <div class="table_title">
              <h4>요약 재무상태표</h4>
              <span class="fr txt_point01"
                ><span class="icon_star">*</span>단위 : 백만원
              </span>
            </div>
            <div class="table_type04">
              <table>
                <caption>
                  구분, 2021년, 2022년, 2023년 나타내는 표입니다.
                </caption>
                <colgroup>
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">구분</th>
                    <th scope="col">2021년</th>
                    <th scope="col">2022년</th>
                    <th scope="col">2023년</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>유동<br class="m_br" />자산</td>
                    <td class="txt_right">145,044</td>
                    <td class="txt_right">164,952</td>
                    <td class="txt_right">186,119</td>
                  </tr>
                  <tr>
                    <td>비유동<br class="m_br" />자산</td>
                    <td class="txt_right">29,793</td>
                    <td class="txt_right">31,329</td>
                    <td class="txt_right">27,801</td>
                  </tr>
                  <tr class="tf">
                    <td>자산<br class="m_br" />총계</td>
                    <td class="txt_right">174,837</td>
                    <td class="txt_right">196,281</td>
                    <td class="txt_right">213,920</td>
                  </tr>
                  <tr>
                    <td>유동<br class="m_br" />부채</td>
                    <td class="txt_right">48,626</td>
                    <td class="txt_right">54,960</td>
                    <td class="txt_right">62,200</td>
                  </tr>
                  <tr>
                    <td>비유동<br class="m_br" />부채</td>
                    <td class="txt_right">4,195</td>
                    <td class="txt_right">5,967</td>
                    <td class="txt_right">2,848</td>
                  </tr>
                  <tr class="tf">
                    <td>부채<br class="m_br" />총계</td>
                    <td class="txt_right">52,821</td>
                    <td class="txt_right">60,927</td>
                    <td class="txt_right">65,048</td>
                  </tr>
                  <tr>
                    <td>자본금</td>
                    <td class="txt_right">7,163</td>
                    <td class="txt_right">7,163</td>
                    <td class="txt_right">7,163</td>
                  </tr>
                  <tr>
                    <td>자본<br class="m_br" />잉여금</td>
                    <td class="txt_right">46,964</td>
                    <td class="txt_right">46,964</td>
                    <td class="txt_right">46,964</td>
                  </tr>
                  <tr>
                    <td>
                      기타<br class="m_br" />자본/<br class="m_br" />기타<br
                        class="m_br"
                      />포괄<br class="m_br" />손익
                    </td>
                    <td class="txt_right">
                      <font color="red"> △ 15,091 </font>
                    </td>
                    <td class="txt_right">
                      <font color="red"> △ 15,091 </font>
                    </td>
                    <td class="txt_right">
                      <font color="red"> △ 15,063 </font>
                    </td>
                  </tr>
                  <tr>
                    <td>이익<br class="m_br" />잉여금</td>
                    <td class="txt_right">82,980</td>
                    <td class="txt_right">96,318</td>
                    <td class="txt_right">109,808</td>
                  </tr>
                  <tr class="tf">
                    <td>자본<br class="m_br" />총계</td>
                    <td class="txt_right">122,016</td>
                    <td class="txt_right">135,354</td>
                    <td class="txt_right">148,872</td>
                  </tr>
                  <tr class="tf">
                    <td>
                      부채<br class="m_br" />
                      및<br class="m_br" />
                      자본<br class="m_br" />총계
                    </td>
                    <td class="txt_right">174,837</td>
                    <td class="txt_right">196,281</td>
                    <td class="txt_right">213,920</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="fs_table">
            <div class="table_title">
              <h4>요약 손익계산서</h4>
              <span class="fr txt_point01"
                ><span class="icon_star">*</span>단위 : 백만원
              </span>
            </div>
            <div class="table_type04">
              <table>
                <caption>
                  구분, 2021년, 2022년, 2023년 나타내는 표입니다.
                </caption>
                <colgroup>
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">구분</th>
                    <th scope="col">2021년</th>
                    <th scope="col">2022년</th>
                    <th scope="col">2023년</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>영업<br class="m_br" />수익</td>
                    <td class="txt_right">289,749</td>
                    <td class="txt_right">302,595</td>
                    <td class="txt_right">309,091</td>
                  </tr>
                  <tr>
                    <td>영업<br class="m_br" />비용</td>
                    <td class="txt_right">283,044</td>
                    <td class="txt_right">283,863</td>
                    <td class="txt_right">288,821</td>
                  </tr>
                  <tr>
                    <td>영업<br class="m_br" />이익</td>
                    <td class="txt_right">6,705</td>
                    <td class="txt_right">18,732</td>
                    <td class="txt_right">20,270</td>
                  </tr>
                  <tr>
                    <td>
                      법인세<br class="m_br" />비용<br class="m_br" />차감전<br
                        class="m_br"
                      />계속<br class="m_br" />영업<br class="m_br" />이익
                    </td>
                    <td class="txt_right">8,248</td>
                    <td class="txt_right">20,613</td>
                    <td class="txt_right">24,649</td>
                  </tr>
                  <tr>
                    <td>
                      계속<br class="m_br" />사업<br class="m_br" />당기<br
                        class="m_br"
                      />순이익
                    </td>
                    <td class="txt_right">8,697</td>
                    <td class="txt_right">15,008</td>
                    <td class="txt_right">17,761</td>
                  </tr>
                  <tr>
                    <td>중단<br class="m_br" />손익</td>
                    <td class="txt_right">149</td>
                    <td class="txt_right">-</td>
                    <td class="txt_right">-</td>
                  </tr>
                  <tr>
                    <td>당기<br class="m_br" />순이익</td>
                    <td class="txt_right">8,846</td>
                    <td class="txt_right">15,008</td>
                    <td class="txt_right">17,761</td>
                  </tr>
                  <tr>
                    <td>
                      기타<br class="m_br" />포괄<br class="m_br" />이익<br
                        class="m_br"
                      />(손실)
                    </td>

                    <td class="txt_right">9</td>
                    <td class="txt_right">1,339</td>
                    <td class="txt_right">
                      <font color="red"> △ 1,263 </font>
                    </td>
                  </tr>
                  <tr>
                    <td>총포괄<br class="m_br" />이익</td>
                    <td class="txt_right">8,855</td>
                    <td class="txt_right">16,347</td>
                    <td class="txt_right">16,498</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="fs_table">
            <div class="table_title">
              <h4>요약 현금흐름표</h4>
              <span class="fr txt_point01"
                ><span class="icon_star">*</span>단위 : 백만원
              </span>
            </div>
            <div class="table_type04">
              <table>
                <caption>
                  구분, 2021년, 2022년, 2023년 나타내는 표입니다.
                </caption>
                <colgroup>
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                  <col style="width: 25%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">구분</th>
                    <th scope="col">2021년</th>
                    <th scope="col">2022년</th>
                    <th scope="col">2023년</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      영업<br class="m_br" />활동<br class="m_br" />으로<br
                        class="m_br"
                      />
                      인한<br class="m_br" />
                      현금<br class="m_br" />흐름
                    </td>
                    <td class="txt_right">20,036</td>
                    <td class="txt_right">27,337</td>
                    <td class="txt_right">14,400</td>
                  </tr>
                  <tr>
                    <td>
                      투자<br class="m_br" />활동<br class="m_br" />으로<br
                        class="m_br"
                      />
                      인한<br class="m_br" />
                      현금<br class="m_br" />흐름
                    </td>
                    <td class="txt_right">22,617</td>
                    <td class="txt_right">728</td>
                    <td class="txt_right">
                      <font color="red"> △ 3,787 </font>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      재무<br class="m_br" />활동<br class="m_br" />으로<br
                        class="m_br"
                      />
                      인한<br class="m_br" />
                      현금<br class="m_br" />흐름
                    </td>

                    <td class="txt_right">
                      <font color="red"> △ 7,598 </font>
                    </td>
                    <td class="txt_right">
                      <font color="red"> △ 7,506 </font>
                    </td>
                    <td class="txt_right">
                      <font color="red"> △ 4,952 </font>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      현금의<br class="m_br" />
                      증가<br class="m_br" />(감소)
                    </td>

                    <td class="txt_right">35,056</td>
                    <td class="txt_right">20,559</td>
                    <td class="txt_right">5,661</td>
                  </tr>
                  <tr>
                    <td>
                      기초의<br class="m_br" />
                      현금
                    </td>
                    <td class="txt_right">53,506</td>
                    <td class="txt_right">88,562</td>
                    <td class="txt_right">109,121</td>
                  </tr>
                  <tr>
                    <td>
                      기말의<br class="m_br" />
                      현금
                    </td>
                    <td class="txt_right">88,562</td>
                    <td class="txt_right">109,121</td>
                    <td class="txt_right">114,782</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- <div style="padding-left: 730px;">
            <span style="font-size: 12pt;"><br>* 2019년은 연결재무제표임
            </span>
          </div> -->
          <!-- <div class="fs_table">
            <div class="table_title">
              <h4>요약 연결재무상태표</h4>
              <span class="fr txt_point01"><span class="icon_star">*</span>단위 : 백만원 </span>
            </div>
            <div class="table_type04">
              <table>
                <caption>구분, 2018년, 2019년, 2020년 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      구분
                    </th>
                    <th scope="col">
                      2018년
                    </th>
                    <th scope="col">
                      2019년
                    </th>
                    <th scope="col">
                      2020년
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>유동<br class="m_br">자산</td>
                    <td class="txt_right">
                      426,632
                    </td>
                    <td class="txt_right">
                      202,435
                    </td>
                    <td class="txt_right">
                      119,055
                    </td>
                  </tr>
                  <tr>
                    <td>비유동<br class="m_br">자산</td>
                    <td class="txt_right">
                      67,340
                    </td>
                    <td class="txt_right">
                      63,290
                    </td>
                    <td class="txt_right">
                      57,817
                    </td>
                  </tr>
                  <tr class="tf">
                    <td>자산<br class="m_br">총계</td>
                    <td class="txt_right">
                      493,972
                    </td>
                    <td class="txt_right">
                      265,725
                    </td>
                    <td class="txt_right">
                      176,872
                    </td>
                  </tr>
                  <tr>
                    <td>유동<br class="m_br">부채</td>
                    <td class="txt_right">
                      102,055
                    </td>
                    <td class="txt_right">
                      65,095
                    </td>
                    <td class="txt_right">
                      50,794
                    </td>
                  </tr>
                  <tr>
                    <td>비유동<br class="m_br">부채</td>
                    <td class="txt_right">
                      5,510
                    </td>
                    <td class="txt_right">
                      12,283
                    </td>
                    <td class="txt_right">
                      9,908
                    </td>
                  </tr>
                  <tr class="tf">
                    <td>부채<br class="m_br">총계</td>
                    <td class="txt_right">
                      107,565
                    </td>
                    <td class="txt_right">
                      77,378
                    </td>
                    <td class="txt_right">
                      60,702
                    </td>
                  </tr>
                  <tr>
                    <td>자본금</td>
                    <td class="txt_right">
                      7,163
                    </td>
                    <td class="txt_right">
                      7,163
                    </td>
                    <td class="txt_right">
                      7,163
                    </td>
                  </tr>
                  <tr>
                    <td>자본<br class="m_br">잉여금</td>
                    <td class="txt_right">
                      46,964
                    </td>
                    <td class="txt_right">
                      46,964
                    </td>
                    <td class="txt_right">
                      46,934
                    </td>
                  </tr>
                  <tr>
                    <td>기타<br class="m_br">자본/<br class="m_br">기타<br class="m_br">포괄<br class="m_br">손익</td>
                    <td class="txt_right">
                      <font color="red">
                        △ 15,028
                      </font>
                    </td>
                    <td class="txt_right">
                      <font color="red">
                        △ 15,028
                      </font>
                    </td>
                    <td class="txt_right">
                      <font color="red">
                        △ 15,091
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td>이익<br class="m_br">잉여금</td>
                    <td class="txt_right">
                      347,378
                    </td>
                    <td class="txt_right">
                      149,248
                    </td>
                    <td class="txt_right">
                      77,134
                    </td>
                  </tr>
                  <tr class="tf">
                    <td>자본<br class="m_br">총계</td>
                    <td class="txt_right">
                      386,407
                    </td>
                    <td class="txt_right">
                      188,347
                    </td>
                    <td class="txt_right">
                      116,170
                    </td>
                  </tr>
                  <tr class="tf">
                    <td>부채<br class="m_br"> 및<br class="m_br"> 자본<br class="m_br">총계</td>
                    <td class="txt_right">
                      493,972
                    </td>
                    <td class="txt_right">
                      265,725
                    </td>
                    <td class="txt_right">
                      176,872
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="fs_table">
            <div class="table_title">
              <h4>요약 연결손익계산서</h4>
              <span class="fr txt_point01"><span class="icon_star">*</span>단위 : 백만원 </span>
            </div>
            <div class="table_type04">
              <table>
                <caption>구분, 2018년, 2019년, 2020년 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      구분
                    </th>
                    <th scope="col">
                      2018년
                    </th>
                    <th scope="col">
                      2019년
                    </th>
                    <th scope="col">
                      2020년
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>영업<br class="m_br">수익</td>
                    <td class="txt_right">
                      373,019
                    </td>
                    <td class="txt_right">
                      363,627
                    </td>
                    <td class="txt_right">
                      334,148
                    </td>
                  </tr>
                  <tr>
                    <td>영업<br class="m_br">비용</td>
                    <td class="txt_right">
                      325,362
                    </td>
                    <td class="txt_right">
                      344,178
                    </td>
                    <td class="txt_right">
                      311,434
                    </td>
                  </tr>
                  <tr>
                    <td>영업<br class="m_br">이익</td>
                    <td class="txt_right">
                      47,657
                    </td>
                    <td class="txt_right">
                      19,449
                    </td>
                    <td class="txt_right">
                      22,714
                    </td>
                  </tr>
                  <tr>
                    <td>법인세<br class="m_br">비용<br class="m_br">차감전<br class="m_br">계속<br class="m_br">영업<br class="m_br">이익</td>
                    <td class="txt_right">
                      53,863
                    </td>
                    <td class="txt_right">
                      24,072
                    </td>
                    <td class="txt_right">
                      22,867
                    </td>
                  </tr>
                  <tr>
                    <td>계속<br class="m_br">사업<br class="m_br">당기<br class="m_br">연결<br class="m_br">순이익</td>
                    <td class="txt_right">
                      39,962
                    </td>
                    <td class="txt_right">
                      3,010
                    </td>
                    <td class="txt_right">
                      16,871
                    </td>
                  </tr>
                  <tr>
                    <td>중단<br class="m_br">손익</td>
                    <td class="txt_right">
                      3,337
                    </td>
                    <td class="txt_right">
                      -
                    </td>
                    <td class="txt_right">
                      1,138
                    </td>
                  </tr>

                  <tr>
                    <td>당기<br class="m_br">연결<br class="m_br">순이익</td>
                    <td class="txt_right">
                      43,299
                    </td>
                    <td class="txt_right">
                      3,010
                    </td>
                    <td class="txt_right">
                      18,010
                    </td>
                  </tr>
                  <tr>
                    <td>연결<br class="m_br">기타<br class="m_br">포괄<br class="m_br">이익<br class="m_br">(손실)</td>
                    <td class="txt_right">
                      <font color="red">
                        △ 773
                      </font>
                    </td>
                    <td class="txt_right">
                      <font color="red">
                        △ 1,074
                      </font>
                    </td>
                    <td class="txt_right">
                      <font color="red">
                        △ 219
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td>연결<br class="m_br">총포괄<br class="m_br">이익</td>
                    <td class="txt_right">
                      42,526
                    </td>
                    <td class="txt_right">
                      1,936
                    </td>
                    <td class="txt_right">
                      17,791
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="fs_table">
            <div class="table_title">
              <h4>연결현금흐름표</h4>
              <span class="fr txt_point01"><span class="icon_star">*</span>단위 : 백만원 </span>
            </div>
            <div class="table_type04">
              <table>
                <caption>구분, 2018년, 2019년, 2020년 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      구분
                    </th>
                    <th scope="col">
                      2018년
                    </th>
                    <th scope="col">
                      2019년
                    </th>
                    <th scope="col">
                      2020년
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>영업<br class="m_br">활동<br class="m_br">으로<br class="m_br"> 인한<br class="m_br"> 현금<br class="m_br">흐름</td>
                    <td class="txt_right">
                      49,252
                    </td>
                    <td class="txt_right">
                      49,425
                    </td>
                    <td class="txt_right">
                      37,336
                    </td>
                  </tr>
                  <tr>
                    <td>투자<br class="m_br">활동<br class="m_br">으로<br class="m_br"> 인한<br class="m_br"> 현금<br class="m_br">흐름</td>
                    <td class="txt_right">
                      <font color="red">
                        △ 141,242
                      </font>
                    </td>
                    <td class="txt_right">
                      167,208
                    </td>
                    <td class="txt_right">
                      25,699
                    </td>
                  </tr>
                  <tr>
                    <td>재무<br class="m_br">활동<br class="m_br">으로<br class="m_br"> 인한<br class="m_br"> 현금<br class="m_br">흐름</td>
                    <td class="txt_right">
                      23,118
                    </td>
                    <td class="txt_right">
                      <font color="red">
                        △203,143
                      </font>
                    </td>
                    <td class="txt_right">
                      <font color="red">
                        △ 95,169
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td>현금의<br class="m_br"> 증가<br class="m_br">(감소)</td>
                    <td class="txt_right">
                      <font color="red">
                        △ 68,872
                      </font>
                    </td>
                    <td class="txt_right">
                      13,490
                    </td>
                    <td class="txt_right">
                      <font color="red">
                        △ 32,134
                      </font>
                    </td>
                  </tr>
                  <tr>
                    <td>기초의<br class="m_br"> 현금</td>
                    <td class="txt_right">
                      140,970
                    </td>
                    <td class="txt_right">
                      71,332
                    </td>
                    <td class="txt_right">
                      85,651
                    </td>
                  </tr>
                  <tr>
                    <td>기말의<br class="m_br"> 현금</td>
                    <td class="txt_right">
                      72,090
                    </td>
                    <td class="txt_right">
                      85,651
                    </td>
                    <td class="txt_right">
                      53,506
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl.vue";

export default {
  name: "Financial",
  metaInfo: {
    title: "주요재무제표 | 재무정보 | 투자정보 | 회사소개",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
