<template>
  <div class="swiper-wrapper">
    <button
      type="button"
      data-role="none"
      class="slick-prev slick-arrow"
      aria-label="Previous"
      role="button"
      @click="previous"
    >
      Previous
    </button>
    <div
      ref="noticeList"
      class="notice__list"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
    >
      <div
        v-for="(item, index) in noticeItems"
        :key="index"
        ref="noticeItem"
        class="slick-slide"
      >
        <strong>{{ $moment(item.start_dt).format('MM.DD') }}</strong>
        <span class="w_hide">
          <dl>
            <dt>{{ item.title }}</dt>
            <dd
              class="ellipsis2"
              v-html="eliminatedDescription(item)"
            />
            <dd>
              <router-link
                :to="{ name: 'notice_contents', params: { id: item.bulletin_no }}"
                :title="item.title"
                class="btn_detail"
              >
                자세히 보기
              </router-link>
            </dd>
          </dl>
        </span>
        <span class="m_hide">
          <router-link
            :to="{ name: 'notice_contents', params: { id: item.bulletin_no }}"
            :title="item.title"
            tag="dl"
          >
            <dt>{{ item.title }}</dt>
          </router-link>
        </span>
      </div>
    </div>
    <button
      type="button"
      data-role="none"
      class="slick-next slick-arrow"
      aria-label="Next"
      role="button"
      @click="next"
    >
      Next
    </button>
  </div>
</template>

<script>
import api from '@/api/index.js'

export default {
  name: 'Notice',
  props: {

  },
  data(){
    return{
      noticeItems:[],
      noticeList:{},
      slideSpeed:300,
      slideHeight:210,
      slideHeightMo:60,
      clickAble: true,
      formData: {
        bulletin_tp: "NOTICE",
        page_per_cnt: 5,
        page_num: 1,
      },
      yDown:null
    }
  },
  computed: {
    totalHeight() {
      return this.slideHeight * (this.noticeItems.length)
    },
    totalHeightMo() {
      return this.slideHeightMo * (this.noticeItems.length)
    }
  },
  created () {
    api.BulletinGetBullutinList(this.formData)
      .then(response => {
        this.noticeList = response.data;
        this.noticeItems = this.noticeList.bulletin_list

        setTimeout(() => {
          this.init();
        },100)
        // window.addEventListener('resize', this.mobileInit);
      })
  },
  methods: {
    init(){
      const pcMatch = window.matchMedia("screen and (min-width: 769px)");
      this.noticeItems.forEach((item, index) => {
        if(index === 0) {
          this.noticeItems.push(item);
        }
        if(index === this.noticeItems.length-2){
          this.noticeItems.unshift(item);
        }
      })
      if(pcMatch.matches){
        this.$refs.noticeList.style.transform = "translate3d(0, -" + (this.slideHeight) + "px, 0px)";
        this.$refs.noticeList.style.height = `${this.totalHeight}px`;
      }else{
        this.$refs.noticeList.style.transform = "translate3d(0, -" + (this.slideHeightMo) + "px, 0px)";
        this.$refs.noticeList.style.height = `${this.totalHeightMo}px`;
      }
    },
    next(){
      const pcMatch = window.matchMedia("screen and (min-width: 769px)");
      if(this.clickAble){
        this.clickAble = false
        let slideList = this.$refs.noticeList;
        this.noticeItems.forEach((item, index) => {
          if (index === 2 ) {
            this.noticeItems.push(item)
          }
        })
        if(pcMatch.matches){
          slideList.style.transition = this.slideSpeed + "ms";
          slideList.style.transform = "translate3d(0px, -" + (this.slideHeight * 2) + "px, 0px)";
        }else{
          slideList.style.transition = this.slideSpeed + "ms";
          slideList.style.transform = "translate3d(0px, -" + (this.slideHeightMo * 2) + "px, 0px)";
        }
        setTimeout(() => {
          this.noticeItems.splice(0, 1)
          if(pcMatch.matches){
            slideList.style.transition = 0 + "ms";
            slideList.style.transform = "translate3d(0px, -" + (this.slideHeight * 1) + "px, 0px)";
          }else{
            slideList.style.transition = 0 + "ms";
            slideList.style.transform = "translate3d(0px, -" + (this.slideHeightMo * 1) + "px, 0px)";
          }
          this.clickAble = true
        }, this.slideSpeed)
      }
    },
    previous(){
      const pcMatch = window.matchMedia("screen and (min-width: 769px)");
      if(this.clickAble) {
        this.clickAble = false
        let slideList = this.$refs.noticeList;
        let newArray = [];
        this.noticeItems.forEach((item, index) => {
          if (index === slideList.childNodes.length-3) {
            newArray.push(item)
          }
        })
        slideList.style.transition = this.slideSpeed + "ms";
        slideList.style.transform = "translate3d(0px, 0px, 0px)";
        setTimeout(() => {
          newArray.forEach(item => {
            this.noticeItems.unshift(item)
          })
          this.noticeItems.splice(slideList.childNodes.length-1 ,1);
          if(pcMatch.matches){
            slideList.style.transition = 0 + "ms";
            slideList.style.transform = "translate3d(0px, -" + (this.slideHeight * 1) + "px, 0px)";
          }else{
            slideList.style.transition = 0 + "ms";
            slideList.style.transform = "translate3d(0px, -" + (this.slideHeightMo * 1) + "px, 0px)";
          }
          this.clickAble = true;
        }, this.slideSpeed)
      }
    },
    eliminatedDescription(item) {
      let str = item.bulletin_desc
      str = str.replace(/(<([^>]+)>)/gm, '')
      str.replace(/;(?=[^<>]+:|\s*")/gi, '')
      let tmp = document.createElement('DIV')
      tmp.innerHTML = str
      return tmp.textContent || tmp.innerText || ''
    },
    mobileInit(){
      const mobileMatch = window.matchMedia("screen and (max-width: 1023px)");
      if (mobileMatch.matches) {
        this.$refs.noticeList.style.height = `${this.totalHeightMo}px`;
        this.$refs.noticeList.style.transform = "translate3d(0,-60px,0)"
      }else{
        this.$refs.noticeList.style.transform = "translate3d(0, -" + (this.slideHeight) + "px, 0px)";
        this.$refs.noticeList.style.height = `${this.totalHeight}px`;
      }
    },
    getTouches(event){
      return event.touches || event.originalEvent.touches;
    },
    handleTouchStart(event){
      const firstTouch = this.getTouches(event)[0];
      this.yDown = firstTouch.clientY;
    },
    handleTouchMove(event){
      if ( ! this.yDown ) {
        return;
      }
      event.preventDefault();
      let yUp = event.touches[0].clientY;

      var yDiff = this.yDown - yUp;

      if ( yDiff > 0 ) {
        this.previous();
      } else {
        this.next();
      }
    }
  }
}
</script>

<style lang="scss" scope>
.slick-slide{
  .ellipsis2{
    -webkit-box-orient:vertical;
  }
}
</style>