<template>
  <!-- to개발 / 2024.03.07 ~ 현재까지 적용 (231114 문구수정) -->
  <div>
    <div class="body-wrap">
      <div class="terms_contents">
        <div class="terms_body">
          <div id="terms_01" class="article-box">
            <strong>1. 총칙</strong>
            <p class="mark_item">
              본 「개인정보처리방침」에서 "개인정보"라 함은 살아있는 개인에 관한
              정보로서 다음 중 어느 하나에 해당하는 정보를 말합니다.<br />
              1) 이름, 주민등록번호 등에 의하여 개인을 알아볼 수 있는 정보<br />
              2) 해당 정보만으로는 특정 개인을 알아볼 수 없어도 다른 정보와 쉽게
              결합하여 알아볼 수 있는 정보<br />
              3) 위 1) 또는 2)의 정보를 가명처리함으로써 원래의 상태로 복원하기
              위한 추가 정보의 사용•결합 없이는 특정 개인을 알아볼 수 없는
              정보(이하 '가명정보')
            </p>
            <p class="mark_item">
              SK텔링크는 개인정보 처리방침을
              홈페이지(https://www.sktelink.com/privacy/intro) 첫 화면에
              공개함으로써 고객이 언제나 용이하게 볼 수 있도록 조치하고
              있습니다.
            </p>
            <p class="mark_item">
              SK텔링크는 관련 법률 및 지침의 변경, 또는 내부 개인정보 운영방침의
              변경에 따라 개인정보 처리방침을 개정할 수 있으며, 개정하는 경우
              시행일자 등을 부여하여 개정된 내용을 확인할 수 있도록 하고
              있습니다.
            </p>
            <p class="mark_item">
              SK텔링크의 개인정보 처리방침이 변경되는 경우에는
              홈페이지(https://www.sktelink.com/privacy/intro) 등을 통하여 변경
              및 시행의 시기, 내용을 고객에게 공지합니다.
            </p>
          </div>
          <div id="terms_02" class="article-box">
            <strong>2. 개인정보의 수집, 이용 목적, 항목 및 보유기간</strong>
            <p class="mark_item">
              SK텔링크는 본질적인 서비스 제공을 위한 ‘필수동의’와 고객 각각의
              기호와 필요에 맞는 서비스를 제공하기 위한 ‘선택동의’로 구분하여
              개인정보를 수집∙이용하고 있습니다. 선택동의에 동의하지 않은
              경우에도 본질적인 서비스를 이용할 수 있습니다.
            </p>
            <p class="mark_item">
              SK텔링크는 고객의 사생활을 현저하게 침해할 우려가 있는 민감한
              개인정보(인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향
              및 범죄기록, 건강상태 및 성생활 등)는 수집하지 않습니다. 다만,
              서비스 제공을 위해 일부 민감정보를 필요•최소한으로 수집, 처리할
              필요가 있는 경우 관련 법령의 제한에 따라 고객의 동의 등 필요한
              조치를 거쳐 그 개인정보를 수집, 처리할 수 있습니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('1')"
              >
                <span
                  ><i
                    >[개인정보의 수집, 이용목적, 항목 및 보유기간 (필수)]</i
                  ></span
                >
              </button>
            </div>
            <p>
              ※ 가입 당시 정보뿐만 아니라 정보 수정으로 변경된 정보를
              포함합니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('2')"
              >
                <span
                  ><i
                    >[개인정보의 수집, 이용목적, 항목 및 보유기간 (선택)]</i
                  ></span
                >
              </button>
            </div>
            <p>
              ※ 가입 당시 정보뿐만 아니라 정보 수정으로 변경된 정보를 포함합니다
            </p>
            <p class="mark_item">
              SK텔링크는 고객이 앱을 통한 서비스 가입 시 고객의
              이동통신단말장치(스마트폰 등)에 저장된 정보 및 설치된 기능(이하
              ‘단말기 정보 등’이라 합니다)에 접근하여 개인정보를 수집할 경우,
              사전에 ‘단말기 정보 등’에 접근한다는 것을 고지하고, 고객이
              개인정보 수집∙이용 동의를 한 범위 내에서 해당 개인정보를 수집,
              이용합니다.
            </p>
            <p class="mark_item">
              SK텔링크는 법령에 따라 동의없이 수집할 수 있는 자동생성
              정보(로그기록, 과금정보, 결제기록 등 서비스 계약 이행과정에서
              자동적으로 생성되는 정보), 요금정산에 필요한 정보(요금내역,
              납부∙미납사실, 미납액 등) 등을 필수동의 사항에 기재된 목적범위
              내에서 수집하여 이용할 수 있습니다. 또한 고객이 선택동의에 동의할
              경우 해당 목적범위 내에서도 이용할 수 있습니다.
            </p>
            <p class="mark_item">
              SK텔링크는 법령에 따라 수집 목적과 합리적으로 관련된 범위에서는
              고객의 동의 없이 개인정보를 이용할 수 있으며, 이때 '당초 수집
              목적과 관련성이 있는지, 수집한 정황 또는 처리 관행에 비추어 볼 때
              예측 가능성이 있는지, 고객의 이익을 부당하게 침해하는지, 가명처리
              또는 암호화 등 안전성 확보에 필요한 조치를 하였는지'를 종합적으로
              고려합니다.
            </p>
            <p class="mark_item">
              SK텔링크는 수집한 개인정보를 특정 개인을 알아볼 수 없도록 가명
              처리하여 통계작성, 과학적 연구, 공익적 기록보존 등을 위하여 처리할
              수 있습니다. 가명정보 처리 및 위탁, 제3자 제공과 관련한 내용은
              아래와 같으며, 이때 가명정보는 재식별되지 않도록 분리하여 별도
              저장•관리하고 필요한 기술적•관리적 보호조치를 취합니다.
            </p>
            <div class="btn-in-box">
              <span
                ><i
                  >[가명정보의 처리 및 위탁, 제3자 제공] : 해당사항 없음</i
                ></span
              >
            </div>
            <p class="mark_item">
              SK텔링크는 개인정보보호법 제 58조의 2에 따라 시간, 비용, 기술 등을
              합리적으로 고려할 때 다른 정보를 사용하여도 더 이상 개인을 알아볼
              수 없는 정보(통계자료 등)를 고객의 동의 없이 생성하여 이용 또는
              제공할 수 있습니다.
            </p>
            <p class="mark_item">
              SK텔링크는 기본적인 서비스 제공을 위해 타 기관으로부터 다음의
              정보를 제공받아, 수집 및 이용하고 있습니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('3')"
              >
                <span
                  ><i
                    >[기본적인 서비스 제공을 위해 타 기관으로부터 제공받는
                    정보]</i
                  ></span
                >
              </button>
            </div>
          </div>
          <div id="terms_03" class="article-box">
            <strong>3. 개인정보 수집 방법 및 동의 방법</strong>
            <p class="mark_item">
              SK텔링크는 고객의 ‘개인정보 수집 및 이용’에 대한 동의 절차를 두고,
              고객이 이에 대해 '동의한다' 또는 '동의하지 않는다' 를 체크하거나
              버튼을 클릭할 수 있는 등의 절차를 마련하고 있습니다. '동의한다'
              항목에 체크를 하거나 버튼을 클릭하면 개인정보 수집 및 이용에 대해
              동의한 것으로 봅니다.
            </p>
            <p class="mark_item">
              고객은 개인정보 수집 및 이용에 대한 동의를 거부할 권리가 있으며,
              동의를 거부할 경우 받는 별도의 불이익은 없습니다. 단, 필수동의
              사항에 동의를 거부할 경우, 서비스 이용이 불가능하거나, 서비스 이용
              목적에 따른 서비스 제공에 제한이 따르게 됩니다.
            </p>
            <p class="mark_item">
              SK텔링크는 고객이 서비스이용 신청서, 명의변경 계약서 등 서면양식에
              서명하거나, SK텔링크에서 운영하는 홈페이지, 앱, 상담게시판, 전화 •
              팩스를 통한 본인 • 대리인 확인, 경품행사 응모, 제휴사로부터의
              제공, 이동전화 및 유 • 무선 인터넷 서비스 사용 시 생성정보 수집
              툴을 통한 방법, 휴대폰인증서비스를 위하여 본인확인기관으로부터
              제공받는 방법 등으로 개인정보를 수집합니다.
            </p>
          </div>
          <div id="terms_04" class="article-box">
            <strong>4. 개인정보 처리 업무의 위탁</strong>
            <p>
              SK텔링크는 업무위탁계약서 등을 통하여 위탁업무 수행목적 외
              개인정보의 처리 금지에 관한 사항, 개인정보의 기술적, 관리적
              보호조치에 관한 사항, 위탁업무의 목적 및 범위, 재위탁 제한에 관한
              사항, 개인정보에 대한 접근 제한 등 안전성 확보 조치에 관한 사항,
              위탁업무와 관련하여 보유하고 있는 개인정보의 관리현황 점검 등
              감독에 관한 사항, 개인정보 사고시의 책임부담, 처리 종료 후의
              개인정보의 반환 또는 파기 의무 등을 규정하고, 이를 준수하도록
              관리하고 있습니다.
            </p>
            <p>
              SK텔링크는 보다 나은 서비스 제공, 고객편의 제공 등 원활한 업무
              수행을 위하여 다음과 같이 개인정보 처리 업무를 외부 전문업체에
              위탁하여 운영하고 있습니다. 수탁업체 리스트는 해당 서비스 변경 및
              계약기간에 따라 변경될 수 있으며 변경 시 각 서비스에서 정한 방식을
              통해 공지하고 특별히 정하지 않은 경우 공지사항을 통해 공지합니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('4')"
              >
                <span><i>[개인정보 처리 위탁]</i></span>
              </button>
            </div>
          </div>
          <div id="terms_05" class="article-box">
            <strong>5. 수집한 개인정보의 제3자 제공</strong>
            <p>
              SK텔링크는 고객의 개인정보를 고객으로부터 동의 받거나 법령에 의해
              허용된 범위 내에서 사용하며, 동 범위를 넘어 이용하거나 제3자에게
              제공하지 않습니다. 단, SK텔링크는 법령에 따라 수집 목적과
              합리적으로 관련된 범위에서는 고객의 동의 없이 개인정보를 제공할 수
              있으며, 이때 '당초 수집 목적과 관련성이 있는지, 수집한 정황 또는
              처리 관행에 비추어 볼 때 예측 가능성이 있는지, 고객의 이익을
              부당하게 침해하는지, 가명처리 또는 암호화 등 안전성 확보에 필요한
              조치를 하였는지'를 종합적으로 고려합니다.<br />
              특히, 다음의 경우에는 위와 같은 범위 내에서 주의를 기울여
              개인정보를 제공할 수 있습니다.
            </p>
            <ol>
              <li>
                가. 제휴관계 <br />
                보다 나은 서비스 제공을 위하여 고객의 개인정보를 제휴사에게
                제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를
                제공하거나 공유할 경우에는 사전에 고객께 제휴사가 누구인지, 제공
                또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가
                제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게
                보호∙관리되는지에 대해 개별적으로 서면 또는 전자우편 등을 통해
                고지하여 동의를 구하는 절차를 거치게 되며, 고객께서 동의하지
                않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다.
                제휴관계에 변화가 있거나 제휴관계가 종결될 때도 같은 절차에
                의하여 고지하거나 동의를 구합니다.
              </li>
              <li>
                나. 매각 · 인수합병 등<br />
                영업의 전부 또는 일부를 양도하거나, 합병·상속 등으로
                서비스제공자의 권리 · 의무를 이전 승계하는 경우 개인정보보호
                관련 고객의 권리를 보장하기 위하여 반드시 그 사실을 고객에게
                통지합니다.
              </li>
              <li>
                다. 법령에 의해 필요하거나 허용되는 경우<br />
                <p class="mark_item">• 요금정산을 위하여 필요한 경우</p>
                <p class="mark_item">
                  • 통신비밀보호법, 국세기본법, 정보통신망 이용촉진 및 정보보호
                  등에 관한 법률, 개인정보 보호법, 금융실명거래 및 비밀보장에
                  관한 법률, 신용정보의 이용 및 보호에 관한 법률,
                  전기통신기본법, 전기통신사업법, 지방세법, 소비자기본법,
                  한국은행법, 형사소송법 등 다른 법률에 특별한 규정이 있는 경우
                  <br />
                  ※ 단, '법률에 특별한 규정이 있는 경우'로 행정목적이나
                  수사목적으로 행정관청 또는 수사기관이 요구해 온 경우라도
                  무조건 고객의 개인정보를 제공하지 않으며, 영장 또는 기관장의
                  직인이 날인된 서면에 의한 경우 등 법률에서 규정한 적법한
                  절차에 따라 제공합니다.
                </p>
              </li>
            </ol>
            <p>
              SK텔링크는 고객에 대하여 보다 질 높은 서비스 제공 등을 위해 아래와
              같이 개인정보를 제공하고 있습니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('5')"
              >
                <span><i>[수집한 개인정보의 제3자 제공]</i></span>
              </button>
            </div>
          </div>
          <div id="terms_06" class="article-box">
            <strong>6. 개인정보의 보관기간 및 이용기간</strong>
            <p>
              SK텔링크는 다른 내용으로 동의를 받거나 법령상의 근거가 있지 않는
              한 원칙적으로 고객의 서비스 가입일로부터 고객에게 서비스를
              제공하는 기간 동안에 한하여 고객의 개인정보를 보유 및 이용하게
              됩니다. 고객이 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에
              대한 동의를 철회하는 경우, 수집∙이용목적을 달성하거나
              보유∙이용기간이 종료한 경우, 사업폐지 등의 파기사유 발생시 당해
              개인정보를 지체 없이 파기합니다. 단, 이용요금의 정산, 소송이나
              분쟁 등 기타 필요한 경우를 대비하여 보유하여야 할 필요가 있을 경우
              개인정보는 해당 목적이 달성될 때까지 일정기간 보유합니다.
            </p>
            <p class="mark_item">
              • 상품 또는 서비스의 요금관련 분쟁 해결을 위해 계약 해지 후
              6개월까지 보관 (단, 계약이 해지된 고객의 요금정산이 미완료된
              경우는 해결 완료일로부터 6개월까지, 요금 관련 분쟁이 계속될
              경우에는 해결 시까지)
            </p>
            <p>
              또한 예를 들어 상법, 국세기본법, 통신비밀보호법, 전자상거래 등에서
              소비자보호에 관한 법률, 신용정보의 이용 및 보호에 관한 법률 등
              관계법령에 따라 보존할 필요가 있는 경우 SK텔링크는 관계법령에서
              정한 기간 동안 회원정보를 보관합니다. 이 경우 SK텔링크는 보관하는
              정보를 그 보관의 목적으로만 이용하며 각 법령에 따른 보관기간의
              예를 들면 다음과 같습니다.
            </p>
            <ul>
              <li>
                • 전기통신사업자가 통신사실확인자료 제공 시 필요한 성명,
                주민등록번호, 연락처 : 12개월 (통신비밀보호법)
              </li>
              <li>
                • 전기통신사업자에게 보관의무가 있는 가입자의 전기통신 일시,
                전기통신 개시∙종료시간, 통신번호 등 상대방의 가입자번호,
                사용도수, 정보통신망에 접속된 정보통신기기의 위치자료 : 12개월
                (통신비밀보호법)
              </li>
              <li>
                • 성명, 주민등록번호, (해지)이동전화번호, 청구서 배달 주소,
                요금, 계약서 등 거래내역 관련 정보 : 5년(단, 해지후 6개월이 지난
                고객의 개인정보는 별도의 분리된 저장공간에 보관) (국세기본법,
                법인세법, 부가가치세법)
              </li>
              <li>
                • 상업장부와 영업에 관한 중요서류 및 전표 : 중요서류는 10년,
                전표(상법)는 5년
              </li>
              <li>
                • 통신사실확인자료 제공시 필요한 로그기록자료, IP주소 등 :
                3개월(통신비밀보호법)
              </li>
              <li>
                • 표시∙광고에 관한 기록 : 6개월(전자상거래 등에서의 소비자보호에
                관한 법률)
              </li>
              <li>
                • 계약 또는 청약철회 등에 관한 기록 : 5년(전자상거래 등에서의
                소비자보호에 관한 법률)
              </li>
              <li>
                • 대금결제 및 재화 등의 공급에 관한 기록 : 5년(전자상거래
                등에서의 소비자보호에 관한 법률)
              </li>
              <li>
                • 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년(전자상거래
                등에서의 소비자보호에 관한 법률)
              </li>
              <li>
                • 신용정보의 수집∙처리 및 이용 등에 관한 기록 : 3년(신용정보의
                이용 및 보호에 관한 법률)
              </li>
            </ul>
          </div>
          <div id="terms_07" class="article-box">
            <strong>7. 개인정보의 파기절차 및 방법</strong>
            <p>
              SK텔링크는 수집한 개인정보의 이용목적이 달성된 후에는 보관기간 및
              이용기간에 따라 해당 정보를 지체없이 파기합니다. 파기절차, 방법,
              시점은 다음과 같습니다.
            </p>
            <ol>
              <li>
                <strong>가. 파기절차 및 시점</strong> <br />
                <p>
                  고객이 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지
                  등으로 이용목적이 달성된 후 서비스의 방침 및 기타 관련 법령에
                  의한 정보보호 사유에 따라(보관기간 및 이용기간 참조) 허용된
                  기간 동안 저장된 후 삭제하거나 파기합니다.
                </p>
                <p>
                  고객이 이동통신단말장치(스마트폰 등)에서 앱을 삭제하더라도
                  개인정보 동의 철회(회원탈퇴) 요청을 하지 않을 경우 해당
                  개인정보는 여전히 서비스에 남아있을 수 있으므로, 개인정보
                  파기를 원하시면 반드시 동의 철회(회원탈퇴) 요청을 하여야
                  합니다.
                </p>
              </li>
              <li>
                <strong>나. 파기방법</strong> <br />
                <p>
                  가입신청서 등 서면양식에 기재하거나, 종이에 출력된 개인정보는
                  분쇄기로 분쇄 또는 소각하거나 화학약품 처리를 하여 용해하여
                  파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
                  없는 기술적 방법을 사용하여 삭제합니다.
                </p>
              </li>
            </ol>
          </div>
          <div id="terms_08" class="article-box">
            <strong>8. 고객의 권리, 의무와 행사 방법</strong>
            <ol>
              <li>
                <strong>가. 고객의 개인정보 보호 관련 권리 </strong> <br />
                <p>
                  고객은 언제든지 본인에 관해 SK텔링크가 보유하고 있는 고객의
                  개인정보 현황, SK텔링크가 개인정보를 이용하거나 제3자에게
                  제공한 현황, 개인정보의 수집∙이용∙제공 등의 동의 현황을
                  열람하거나 제공받을 수 있습니다. SK텔링크는 고객이 개인정보에
                  대한 열람∙제공 또는 정정을 요구하는 경우에는 고객의 요구에
                  10일 이내에 대응하고, 해당 개인정보에 오류가 있거나 보존기간을
                  경과한 것이 판명되는 등 정정∙삭제를 할 필요가 있다고 인정되는
                  경우에는 지체 없이 정정∙삭제를 합니다. . 고객은 개인정보
                  수집∙이용 또는 제공에 대한 동의철회(가입∙해지)를 요구할 수
                  있고 SK텔링크는 지체 없이 필요한 조치를 취합니다. 고객은
                  언제든지 개인정보 수집·이용·제공 등의 동의를 철회할 수
                  있습니다. 다만, 회원가입 및 본질적 서비스 제공에 필요한
                  개인정보 등에 대한 동의를 철회하는 경우 회원탈퇴 및 서비스
                  해지가 불가피하여, 고객에게 제공되는 서비스를 받지 못하게 될
                  수 있습니다.
                </p>
                <p>
                  고객은 개인정보 수집∙이용 또는 제공에 대한
                  동의철회(가입∙해지)를 요구할 수 있고 SK텔링크는 지체 없이
                  필요한 조치를 취합니다. 고객은 언제든지 개인정보
                  수집•이용•제공 등의 동의를 철회할 수 있습니다. 다만, 회원가입
                  및 본질적 서비스 제공에 필요한 개인정보 등에 대한 동의를
                  철회하는 경우 회원탈퇴 및 서비스 해지가 불가피하여, 고객에게
                  제공되는 서비스를 받지 못하게 될 수 있습니다.
                </p>
              </li>
              <li>
                <strong>나. 고객의 권리행사 방법 </strong> <br />
                <p>
                  고객은 대상 서비스의 성격에 따라 다음 중 한 가지 방법으로 위와
                  같은 권리를 행사할 수 있습니다. 대상 서비스 또는 개인정보의
                  성격에 따라, 또는 고객정보 보호의 필요성에 따라 일부 방법은
                  제한될 수 있으며, SK텔링크는 위와 같은 권리를 행사하는 자가
                  고객 본인 또는 고객의 적법한 대리인인지 여부를
                  신분증명문서(주민등록증, 외국인등록증, 여권, 운전면허증) 및
                  권리증명문서(위임장, 본인 및 대리인의 인감증명서 등)를 통해
                  확인할 수 있습니다.
                </p>
                <ul>
                  <li>
                    - SK텔링크와 위탁업무계약을 맺은 지점, 대리점 또는 고객센터
                    방문, 전화
                  </li>
                  <li>
                    - 홈페이지 내 회원정보변경 메뉴를 클릭하거나 공지된 연락처로
                    서면, 전화, 전자우편 발송하여 문의
                  </li>
                </ul>
                <p>
                  SK텔링크는 개인정보의 전부 또는 일부에 대하여 열람∙증명 또는
                  정정요청을 거절할 정당한 이유가 있는 경우에는 고객에게 이를
                  통지하고 그 이유를 설명합니다. 고객은 회사의 열람 등의 요청
                  거절 시 이의제기를 할 수 있으며, 이의제기 방법은 개인정보보호
                  담당자 메일로 접수하시면 신속∙정확한 답변을 드리겠습니다.
                </p>
              </li>
              <li>
                <strong>다. 고객의 개인정보 보호 관련 의무 </strong> <br />
                <p>
                  고객은 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고
                  예방을 위해 노력하여야 합니다. 고객이 입력한 부정확한 정보로
                  인해 발생하는 사고의 책임은 고객에게 있으며 타인 정보를
                  도용하거나 허위정보를 입력할 경우 회원자격상실을 포함하여
                  서비스 약관위반에 따른 제재가 따를 수 있습니다.
                </p>
                <p>
                  고객은 접속정보(ID, 패스워드 등)를 포함한 본인의 개인정보가
                  유출되지 않도록 공용 PC 사용 시 반드시 로그아웃을 하는 등
                  기본적인 주의를 기울여야 하고 다른 사람이 추측할 수 있는 쉬운
                  비밀번호는 사용을 피하여야 하며, 정기적으로 비밀번호를
                  변경하는 것이 바람직합니다.
                </p>
                <p>
                  고객은 게시물을 포함한 타인의 개인정보 및 권리를 침해,
                  훼손하지 않도록 유의하여야 합니다. 고객이 관계 법령을 위반하여
                  타인의 개인정보 및 권리를 침해, 훼손할 시에는 법적 책임이 따를
                  수 있습니다.
                </p>
                <p>
                  개인정보 열람∙증명의 접수∙처리 부서 및 담당자 : Infra 보안팀
                  이원희 (sktelinkprivacy@sk.com)
                </p>
              </li>
            </ol>
          </div>
          <div id="terms_09" class="article-box">
            <strong>9. 아동의 개인정보보호 및 법정대리인의 권리</strong>
            <p>
              SK텔링크는 만 14세 미만 아동(이하 ‘아동’)의 개인정보
              수집∙이용∙제공 등을 하는 경우 해당 아동의 법정대리인의 동의를
              얻도록 하고 있습니다.
            </p>
            <p>
              SK텔링크는 아동의 서비스 이용 신청 시 법정대리인의 동의를 얻기
              위하여 법정대리인의 성명, 연락처 등 필요한 최소한의 정보를 요구할
              수 있습니다. 이 경우 개인정보의 수집∙이용 또는 제공 목적 및
              법정대리인의 동의가 필요하다는 취지를 아동이 쉽게 이해 할 수 있는
              평이한 표현으로 아동에게 고지 합니다.
            </p>
            <p>
              SK텔링크는 법정대리인의 동의를 얻기 위하여 수집한 법정대리인의
              개인정보를 해당 법정대리인의 동의 여부를 확인하는 목적 외의 용도로
              이를 이용하거나 제3 자에게 제공하지 않습니다. <br />아아동의
              법정대리인은 아동에 관해 SK텔링크가 보유하고 있는 개인정보
              수집·이용·제공 동의를 철회할 수 있고, SK텔링크가 개인정보를
              이용하거나 제3자에게 제공한 현황, 개인정보의 수집∙이용∙제공 등의
              동의 현황을 열람하거나 제공받을 수 있으며, 오류가 있는 경우에는 그
              정정을 요구할 수 있습니다.
            </p>
          </div>
          <div id="terms_10" class="article-box">
            <strong
              >10. 개인정보의 자동수집장치의 설치 · 운영 및 그 거부에 관한
              사항</strong
            >
            <p>
              SK텔링크는 고객에게 제공하는 서비스를 통하여 고객의 선호사항 등을
              기억하거나 서비스를 효율적으로 제공하기 위하여 개인정보를 저장하고
              조회하는 쿠키(cookie)(이하 ‘쿠키’)를 주의깊게 설치∙운용할 수
              있습니다. SK텔링크는 고객이 서비스에 접속하면 고객의 브라우저에
              있는 쿠키의 내용을 읽고, 추가정보를 찾아 접속에 따른 성명 등의
              추가 입력 없이 서비스를 제공할 수 있습니다.
            </p>
            <p>
              SK텔링크가 쿠키를 통해 수집한 고객의 정보는 다음의 목적 등을 위해
              사용될 수 있습니다.
            </p>
            <ul>
              <li>• 개인의 관심 분야에 따라 차별화된 정보를 제공</li>
              <li>
                • 관심 있게 둘러본 내용들에 대한 자취를 추적하여 다음 번 접속 때
                개인 맞춤 서비스를 제공
              </li>
              <li>• 유료서비스 이용 시 이용기간 안내</li>
              <li>• 회원들의 습관을 분석하여 서비스 개편 등의 척도로 활용</li>
            </ul>
            <p>
              고객은 서비스에서 제공하는 방식에 따라 쿠키 설치에 대해 선택할 수
              있습니다. 예시적으로 웹 브라우저 상단의 “도구 &gt; 인터넷옵션 &gt;
              개인정보 &gt; 고급”에서 모든 쿠키를 다 받아들이거나, 쿠키가 설치될
              때 통지를 보내도록 하거나, 또는 모든 쿠키를 거부할 수 있습니다.
            </p>
          </div>
          <div id="terms_11" class="article-box">
            <strong>11. 개인정보보호를 위한 기술적 · 관리적 대책</strong>
            <ol>
              <li>
                <strong>가. 기술적 대책</strong><br />
                SK텔링크는 고객의 개인정보를 처리함에 있어 개인정보가 분실,
                도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                아래와 같은 기술적 대책을 강구하고 있습니다.
                <ul>
                  <li>
                    • 고객의 개인정보는 비밀번호에 의해 보호되며 파일 및
                    전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여
                    중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.
                  </li>
                  <li>
                    • 개인정보 처리에 이용하는 정보기기에 백신프로그램 등을
                    이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를
                    취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며
                    갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를
                    제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                  </li>
                  <li>
                    • 암호알고리즘 등을 이용하여 네트워크 상에서 개인정보를
                    안전하게 전송할 수 있는 보안장치를 채택하고 있습니다.
                  </li>
                  <li>
                    • 해킹 등 외부침입에 대비하여 침입차단시스템 및
                    침입탐지시스템을 운영하여 사내 네트워크를 보호하고, 각
                    서버마다 접근제어시스템을 설치하여 보안을 강화하고 있습니다.
                  </li>
                </ul>
              </li>
              <li>
                <strong>나. 관리적 대책</strong><br />
                <ul>
                  <li>
                    • 개인정보의 안전한 보호를 위하여 주요 시스템 및 설비에
                    대하여 외부 전문기관으로부터 정보보호관리체계 인증 등
                    객관적인 인증을 받아 운영되고 있습니다.
                  </li>
                  <li>
                    • 고객의 개인정보에 대한 접근 및 관리에 필요한 절차 등을
                    마련하여 소속 직원으로 하여금 이를 숙지하고 준수하도록 하고
                    있습니다.
                  </li>
                  <li>
                    • 고객의 개인정보를 처리할 수 있는 자를 최소한으로 제한하고
                    있습니다. 고객의 개인정보를 처리할 수 있는 자는 아래와
                    같습니다.
                    <ul>
                      <li>
                        - 고객을 직∙간접적으로 상대로 하여 마케팅 업무를
                        수행하는 자
                      </li>
                      <li>
                        - 개인정보보호책임자 및 개인정보보호담당자 등
                        개인정보관리∙보호업무를 수행하는 자
                      </li>
                      <li>- 기타 업무상 개인정보의 처리가 불가피한 자</li>
                    </ul>
                  </li>
                  <li>
                    • 컴퓨터를 이용하여 고객의 개인정보를 처리하는 경우
                    개인정보에 대한 접근권한을 가진 담당자를 지정하여
                    식별부호(ID) 및 비밀번호를 부여하고, 해당 비밀번호를
                    정기적으로 갱신하고 있습니다.
                  </li>
                  <li>
                    • 개인정보를 처리하는 직원을 대상으로 새로운 보안기술 습득
                    및 개인정보보호 의무 등에 관해 정기적인 사내 교육 및 외부
                    위탁교육을 실시하고 있습니다.
                  </li>
                  <li>
                    • 신규직원 채용 시 정보보호서약서 또는 개인정보보호서약서에
                    서명하게 함으로써 직원에 의한 정보유출을 사전에 방지하고
                    개인정보처리방침에 대한 이행사항 및 직원의 준수여부를
                    감사하기 위한 내부절차를 마련하여 지속적으로 시행하고
                    있습니다.
                  </li>
                  <li>
                    • 직원 퇴직 시 비밀유지서약서에 서명하게 함으로써 고객의
                    개인정보를 처리하였던 자가 직무상 알게 된 개인정보를
                    훼손∙침해 또는 누설하지 않도록 하고 있습니다.
                  </li>
                  <li>
                    • 개인정보 처리자의 업무 인수인계는 보안이 유지된 상태에서
                    철저하게 이뤄지고 있으며, 입사 및 퇴사 후 개인정보
                    침해사고에 대한 책임을 명확하게 규정하고 있습니다.
                  </li>
                  <li>
                    • 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여
                    출입을 통제하고 있습니다.
                  </li>
                  <li>
                    • 서비스이용계약체결 또는 서비스제공을 위하여 고객의
                    신용카드번호, 은행결제계좌 등 대금결제에 관한 정보를
                    수집하거나 고객에게 제공하는 경우 당해 고객이 본인임을
                    확인하기 위하여 필요한 조치를 취하고 있습니다.
                  </li>
                  <li>
                    • 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해
                    개인정보의 상실, 유출, 위조, 변조, 훼손이 유발될 경우
                    SK텔링크는 즉각 고객에게 사실을 알리고 적절한 대책과 보상을
                    강구할 것입니다.
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div id="terms_12" class="article-box">
            <strong>12. 광고성 정보 전송</strong>
            <p>
              SK텔링크는 고객의 사전 동의 없이 영리목적의 광고성 정보를 전송하지
              않습니다.
            </p>
            <p>
              SK텔링크는 신상품이나 이벤트 정보안내 등 고객 지향적인 마케팅을
              수행하기 위하여 영리 목적의 광고성 정보를 전송하는 경우에는 광고성
              정보 전송에 대한 고객의 사전 동의를 득하고 각 전송수단별로 아래와
              같이 고객이 광고성 정보임을 쉽게 알아 볼 수 있도록 조치합니다.
            </p>
            <ul>
              <li>
                • 전자우편 : 제목란에 '(광고)' 또는 '(성인광고)'라는 문구를
                제목란의 처음에 빈칸 없이 한글로 표시하고 이어서 전자우편
                본문란의 주요 내용을 표시합니다. 단, 제목란 등에 광고임을
                표시하기 어려운 전자매체를 통해 영리목적의 광고성 정보를
                전송하는 경우에도 전송자의 명칭을 표기하는 등 필요한 조치를
                취합니다.
              </li>
              <li>
                • 팩스∙휴대폰 문자전송 등 전자우편 이외의 방법 : 전송자의 명칭을
                표기하는 등 필요한 조치를 취합니다.
              </li>
            </ul>
            <p>
              SK텔링크는 고객의 광고성 정보 수신동의 시, 수신거부 시, 수신동의
              철회 시 해당 사실을 고객에게 해당 사실 발생일로부터 14일내에
              고지합니다. 또한 고객이 광고성 정보 수신동의를 한 날로부터 매 2년
              마다 고객의 광고성 정보 수신동의 사실을 고객에게 확인합니다.
            </p>
          </div>
          <div id="terms_13" class="article-box">
            <strong>13. 의견수렴 및 불만처리</strong>
            <p>
              SK텔링크는 고객의 의견을 매우 소중하게 생각합니다. 고객께서
              문의사항이 있을 경우 고객센터 등에 연락을 하여 문의하시면
              신속∙정확한 답변을 드리겠습니다. 고객센터의 연락처는 다음과
              같습니다.
            </p>
            <ul>
              <li>
                - 전화번호 : (이동전화서비스) 1599-0999 (국제전화) 1599-0070
              </li>
              <li>
                - 사이버상담 : <br />
                <ul>
                  <li>
                    - www.sktelink.com에 접속하신 후 고객센터 메뉴의
                    상담/안내에서 사이버 상담원에게 질문을 하실 수 있습니다.
                  </li>
                  <li>
                    - 구글플레이, 원스토어 등 앱 마켓에 접속하신 후 관련
                    서비스의 개발자에게 문의하기를 통해 질문을 하실 수 있습니다.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              기타 개인정보침해로 인한 구제를 받기 위하여
              개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
              분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
              개인정보침해의 신고∙상담에 대하여는 아래의 기관에 문의하시기
              바랍니다.
            </p>
            <ul>
              <li>
                . 개인정보분쟁조정위원회 (<a
                  href="https://www.kopico.go.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                  >https://www.kopico.go.kr</a
                >) : 1833-6972
              </li>
              <li>
                . 개인정보침해신고센터 (<a
                  href="https://privacy.kisa.or.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                  >https://privacy.kisa.or.kr</a
                >) : 118
              </li>
              <li>
                . 대검찰청 (<a
                  href="https://www.spo.go.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                  >https://www.spo.go.kr</a
                >) : 1301
              </li>
              <li>
                . 경찰청 (<a
                  href="https://ecrm.cyber.go.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                  >https://ecrm.cyber.go.kr</a
                >) : 182
              </li>
            </ul>
          </div>
          <div id="terms_14" class="article-box">
            <strong>14. 고객 개인정보의 국외 이전</strong>
            <p>
              SK텔링크는 필요한 경우 고객의 개인정보를 국외에 보관 및 처리위탁할
              수 있습니다. 국외에 처리위탁 보관하는 개인정보는 아래와 같습니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('6')"
              >
                <span><i>[고객 개인정보의 국외 이전]</i></span>
              </button>
            </div>
            <p style="margin-top: 25px">
              이전거부시 eSIM서비스를 사용할 수 없습니다. 이에 이전을 원치
              않으실 경우 eSIM서비스를 탈퇴하고 USIM으로 전환하여 서비스를
              이용해 주시기 바랍니다. 해당 업무는 고객센터를 통해 요청하실 수
              있습니다.
            </p>
          </div>
          <div id="terms_15" class="article-box">
            <strong>15. 개인정보보호책임자 및 담당자</strong>
            <p>
              SK텔링크는 고객이 안전하게 서비스를 이용할 수 있도록 개인정보
              보호에 최선을 다하고 있습니다. SK텔링크는 고객의 개인정보 관련
              문의, 불만처리, 피해구제 등을 위하여 아래와 같이 개인정보
              보호책임자 및 담당부서를 지정하여 고객의 요청에 신속하고 성실하게
              답변해드리고 있습니다.
            </p>
            <p>
              개인정보보호 담당자 e-Mail :
              <a href="mailto:sktelinkprivacy@sk.com">sktelinkprivacy@sk.com</a>
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('7')"
              >
                <span><i>[개인정보보호 책임자 및 담당자 전체보기]</i></span>
              </button>
            </div>
            <p />
            <ul>
              <li>• 개인정보처리방침 공고일자 : 2024년 3월 21일</li>
              <li>• 개인정보처리방침 시행일자 : 2024년 3월 21일</li>
              <li>• 개인정보처리방침 버전번호 : V 3.10</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isVisible"
      id="baseLayer"
      class="baselayer"
      style="display: block"
    >
      <!-- to개발 / popup01 개인정보의 이용목적, 수집항목 및 보유기간(필수) -->
      <section
        v-if="currentModal === '1'"
        id="modalLayerPopup"
        key="20210120-01"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              개인정보의 이용목적, 수집항목 및 보유기간(필수)
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집 목적</th>
                    <th>수집 항목</th>
                    <th>보유 기간</th>
                  </tr>
                  <tr>
                    <td>
                      • 이동전화서비스, 부가서비스, 통신과금서비스 등 회사가
                      제공하는 제반서비스 제공<br />
                      • 서비스 제공을 위한 본인 식별∙인증<br />
                      • 서비스 품질 확인∙개선, 상담∙불만∙기기변경 처리<br />
                      • 요금 정산∙청구∙수납∙추심∙자산유동화<br />
                      • 연체 사실 통지
                    </td>
                    <td>
                      이름(법인명), 생년월일, 성별, 이동전화번호, 주소,
                      전화번호, e-mail 주소, 계좌(카드)정보, 예금주(카드주)
                      정보, 단말기 정보(모델명, IMEI, USIM번호, MAC Address 등),
                      서비스 가입일∙해지일∙가입기간, 서비스 과금정보,
                      단말매매계약내역, 분할상환계약 내역, 통신과금서비스
                      거래정보, 휴대폰결제 비밀번호, 번호이동정보, 서비스
                      이용과정에서 생성되는 정보(발∙수신번호(통화상대방번호
                      포함), 통화시각, 사용도수, 서비스이용기록, 접속로그, 쿠키,
                      접속 IP 정보, 결제기록, 이용정지기록, 연체금액, 연체등록
                      예정일, 기타 요금 과금에 필요한 데이터 및 이를 조합하여
                      생성되는 정보 등), 기지국정보, 미성년자 가입 시 법정대리인
                      정보(이름, <span class="highlight">주민등록번호</span>)
                    </td>
                    <td rowspan="3">
                      <span class="highlight"
                        >서비스 계약 전부 해지 후 6개월까지</span
                      >
                      (단, 아래의 경우는 각 정한 바에 따름)<br />
                      가. 법령에 따로 정한 경우에는 해당 기간까지<br />
                      나. 요금정산이 미완료된 경우, 정산완료일로부터
                      6개월까지<br />
                      다. 단말/요금보증보험을 가입한 경우, 보증보험에 따른 법률
                      관계가 종료된 날로부터 6개월까지<br />
                      라. 요금관련 분쟁이 계속될 경우에는 분쟁이 종료되는
                      때까지<br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      • 명의도용 방지, 가입의사 확인<br />
                      • 보편적 역무 제공, 요금감면 서비스∙사무
                    </td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름,
                      생년월일, 신분증 기재사항, 주소,
                      <span class="highlight">주민등록번호</span> 대체수단,
                      이동통신사 정보
                    </td>
                  </tr>
                  <tr>
                    <td>• 본인확인서비스</td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름,
                      생년월일, 성별, 내∙외국인 여부, 신분증 기재사항,
                      <span class="highlight">주민등록번호</span> 대체수단,
                      이동전화번호, 이동통신사 정보
                    </td>
                  </tr>
                  <tr>
                    <td>
                      • 이동전화 서비스∙단말대금 분할상환구매∙신용보험 계약의
                      가입∙유지∙관리(채권추심∙대위권 행사 등 포함), 금융사고
                      조사, 자산유동화, 당사 제휴카드 발급 여부 결정 등
                    </td>
                    <td>
                      <span class="highlight">고유식별정보</span>,
                      개인신용정보(연체정보 등 신용도 판단정보, 신용거래정보,
                      신용등급, 보증보험 가입정보 및 보험 가입∙유지∙관리
                      목적으로서의 조회 정보)<br />※ 신용정보는 나이스평가정보,
                      한국정보통신진흥협회 또는 서울보증보험 등 신용조회회사,
                      신용정보집중기관 또는 보증보험회사(보증보험회사의
                      신용조회회사, 신용정보집중기관, 금융결제원 등을 통한 조회
                      포함)로부터 제공받습니다
                    </td>
                    <td>
                      <span class="highlight"
                        >정보 조회∙제공 동의의 효력기간은 동의서 제출시점부터
                        관련 계약∙정산∙대위권이 종료하는 시점까지. <br />단,
                        가입이나 거래가 거절되는 경우 상담 종료시점까지</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>• 이용요금 미납으로 인한 해지 시 알림</td>
                    <td>
                      이름, 직권해지예정일, 중복확인정보(DI), 연체금액,
                      납부기한, 미성년자의 경우 법정대리인 중복확인정보(DI),
                      법정대리인 이동전화번호 또는 서비스관리번호
                    </td>
                    <td>
                      <span class="highlight">해당업무 처리 완료시까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>• 주민등록전산정보를 통한 실 사용자 확인</td>
                    <td><span class="highlight">주민등록번호</span>, 이름</td>
                    <td><span class="highlight">서비스 해지시까지</span></td>
                  </tr>
                  <tr>
                    <td>• 외국인 출국여부 확인</td>
                    <td>
                      <span class="highlight">여권번호</span>, 이름, 국적,
                      등록번호, 생년월일, 이동전화번호
                    </td>
                    <td><span class="highlight">체류기간까지</span></td>
                  </tr>
                  <tr>
                    <td>• 이동전화 단기간 가입 회선 관리</td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름(법인명),
                      생년월일, 이동전화번호, 가입정보(통신사, 가입일, 해지일),
                      국적(외국인)
                    </td>
                    <td>
                      <span class="highlight">서비스 계약해지 시점까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>• 단말 내 이동전화 회선의 명의 일치 여부 확인</td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름,
                      생년월일, 이동전화번호, 주민등록번호 대체수단, 이동통신사
                      정보, 단말기 정보(모델명, IMEI, USIM 및 eSIM번호 등)
                    </td>
                    <td>
                      <span class="highlight">서비스 계약해지 시점까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>• 전기통신역무 가입</td>
                    <td>
                      <span class="highlight">고유식별정보</span>,
                      신용도판단정보(번호변작, 불법스팸, 보이스피싱,
                      불법대부광고 등으로 인한 서비스 정지 등)
                    </td>
                    <td>
                      <span class="highlight"
                        >서비스 제공기간 (단, 가입거절시 상담
                        종료시점까지)</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      • 이용자가 웹사이트 또는 Application에 입력한
                      본인확인정보의 정확성여부확인 (본인확인 서비스 제공) •
                      해당 웹사이트 또는 Application 에 연계정보(CI) /
                      중복가입확인정보(DI) 전송 • 서비스 관련 상담 및 불만처리
                      등
                    </td>
                    <td>
                      이용자의 성명, 이동전화번호, 가입한 이동전화 회사,
                      생년월일, 성별, 연계정보(CI), 중복가입확인정보(DI),
                      이용자가 이용하는 웹사이트 또는 Application 정보,
                      이용일시, 내외국인 여부, 배송을 위한 정보(수령인, 휴대
                      폰번호, 다른 연락처, 배송주소)
                    </td>
                    <td>
                      <span class="highlight"
                        >이용자가 서비스를 이용하는 기간에 한하여 보유 및 이용.
                        다만 관련 법령에서 정하는 경우에는 해당 기간까지
                        보유</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>sk7mobile.com 홈페이지</h2>
              <table class="table_wid01 three">
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집·이용 항목</th>
                    <th style="width: 150px">보유 및 이용 기간</th>
                  </tr>
                  <tr>
                    <td>본인인증 (휴대폰인증, 아이핀인증)</td>
                    <td>
                      이름, 이동전화번호 (PASS 인증시)<br />
                      이름, 이동전화번호, 생년월일, 성별 (SMS 인증시)<br />
                      아이핀ID, 비밀번호 (아이핀 인증시)
                    </td>
                    <td>
                      <span class="highlight">3개월까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>회원가입(후불서비스 조회, 변경, 상담)</td>
                    <td>
                      이름, 생년월일, 성별, 아이디, 패스워드, 휴대폰번호,
                      통신사, e-mail 주소, 연계정보(CI), 만 14세 미만 아동일
                      경우 (법정대리인 휴대폰인증 또는 아이핀인증 결과 정보)
                    </td>
                    <td>
                      <span class="highlight"
                        >이동전화서비스 가입자가 회원가입 유지 시까지</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>유심 구매</td>
                    <td>
                      이름, 휴대폰번호, 주소, 생년월일, 성별, 결제정보, 카드사,
                      신용 카드번호
                    </td>
                    <td>
                      <span class="highlight">5년까지</span> (전자상거래
                      등에서의 소 비자보호에 관한 법률)
                    </td>
                  </tr>
                  <tr>
                    <td>가입신청서 작성, 휴대폰 구매</td>
                    <td>
                      이동전화서비스 제공시 수집.이용하는 모든 항목(표 상단 9개
                      항목), 배송을 위한 정보(수령인, 휴대폰번호, 다른 연락처,
                      배송주소)
                    </td>
                    <td>
                      <span class="highlight"
                        >가입신청 및 구매정보 작성 3개월 까지</span
                      >
                      (가입완료시 이동전화서비스 보유기간까지)
                    </td>
                  </tr>
                  <tr>
                    <td>셀프 개통</td>
                    <td>이름, 휴대폰번호, 인증번호, USIM번호, 번호이동인증</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>공기계 구매</td>
                    <td>이름, 휴대폰번호, 생년월일, 성별, 결제정보, 주소</td>
                    <td>
                      <span class="highlight"
                        >계약 및 대금결제 기록 5년까지</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>비회원이벤트 (구매후기)</td>
                    <td>이름, 휴대폰번호, 생년월일, 성별</td>
                    <td>
                      <span class="highlight">이벤트 종료후 3개월까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>신청내역조회</td>
                    <td>이름, 휴대폰번호, 접수번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>휴대폰등록</td>
                    <td>이름, 휴대폰번호, 신용카드번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>선불서비스 충전</td>
                    <td>
                      휴대폰번호, 충전방법(신용카드번호, 계좌번호,
                      선불(PPS)카드번호)
                    </td>
                    <td>
                      <span class="highlight">5년까지</span> (전자상거래
                      등에서의 소비자보호에 관한 법률)
                    </td>
                  </tr>
                  <tr>
                    <td>개인정보 이용내역조회</td>
                    <td>이름, 휴대폰번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>통신자료제공사실 열람</td>
                    <td>이름, 휴대폰번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>미환급금 조회</td>
                    <td>
                      • 미환급액 조회 시 : 이름,
                      <span class="highlight">주민등록번호</span><br />•
                      미환급액 환급 신청시 : 이름,
                      <span class="highlight">주민등록번호</span>, 입금은행명,
                      계좌번호
                    </td>
                    <td>
                      <span class="highlight">서비스 이용 후 즉시 폐기</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>SK국제전화 00700 앱</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>
                      회원가입(휴대폰인증, 국제전화서비스 조회, 변경, 이벤트,
                      상담)
                    </td>
                    <td>이름, 휴대폰번호, 생년월일, DI, 회원등급</td>
                    <td>
                      <span class="highlight">서비스 이용 기간</span><br />※
                      회원 탈퇴 시, 고객민원 처리 등의 사유로 최대 3개월까지
                      보유(법령에 따로 정한 경우에는 해당 기간까지)
                    </td>
                  </tr>
                  <tr>
                    <td>제휴포인트</td>
                    <td>휴대폰번호, OK캐쉬백 번호, 아시아나항공 번호</td>
                    <td>
                      <span class="highlight">요금제 종료후 5년까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>앱 다운로드 URL 전송, 고객 불만상담</td>
                    <td>휴대폰번호</td>
                    <td>
                      <span class="highlight">3년까지</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>국제전화 00700.com 홈페이지</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>본인인증 (휴대폰인증)</td>
                    <td>
                      이름, 이동전화번호 (PASS 인증시)<br />
                      이름, 이동전화번호, 생년월일, 성별 (SMS 인증시)
                    </td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>
                      로그인(요금제 조회 및 신청, 이용내역 조회, 제휴포인트 등록
                      및 적립내역 조회, 이벤트 신청 및 결과 조회)
                    </td>
                    <td>휴대폰번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>요금제 신청</td>
                    <td>휴대폰번호</td>
                    <td>
                      <span class="highlight">요금제 종료 후 5년까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>이용내역</td>
                    <td>
                      휴대폰번호, 통화내역 조회 시 상대방 착신번호 끝 4자리
                    </td>
                    <td>
                      <span class="highlight">3년까지</span>(전자상거래 등에서의
                      소비자보호에 관한 법률)
                    </td>
                  </tr>
                  <tr>
                    <td>제휴포인트</td>
                    <td>휴대폰번호, OK캐쉬백 번호, 아시아나항공 번호</td>
                    <td>
                      <span class="highlight">요금제 종료 후 5년까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>이벤트</td>
                    <td>휴대폰번호</td>
                    <td>
                      <span class="highlight">이벤트 종료 후 3개월까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>무료통화 신청</td>
                    <td>휴대폰번호</td>
                    <td><span class="highlight">6개월까지</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- end // to개발 / popup01 개인정보의 이용목적, 수집항목 및 보유기간(필수) -->

      <!-- to개발 / popup02 개인정보의 이용목적, 수집항목 및 보유기간(선택) -->
      <section
        v-if="currentModal === '2'"
        id="modalLayerPopup"
        key="20210120-02"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              개인정보의 이용목적, 수집항목 및 보유기간(선택)
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>
                      이동전화서비스와 SK텔링크가 제공하는 다른 상품∙서비스간,
                      개인정보의 결합∙분석 및 이를 통한 개인맞춤∙연계서비스
                      혜택제공 및 고객센터 상담
                    </td>
                    <td>
                      이동전화서비스 이용 시 개인정보 수집∙이용에 동의한 모든
                      항목(서비스 이용기록 포함)
                    </td>
                    <td>
                      <span class="highlight">서비스 이용 기간</span> ※
                      선택동의를 철회하고자 하는 경우 080-897-0999 을 통하여
                      가능
                    </td>
                  </tr>
                  <tr>
                    <td>사은품 제공</td>
                    <td>이름, 이동전화번호, 주소</td>
                    <td>
                      <span class="highlight">신청서 작성 완료일부터 90일</span>
                    </td>
                  </tr>
                  <tr>
                    <td>명의도용 조사 및 수사기관을 통한 수사의뢰</td>
                    <td>
                      이름, 생년월일, 성별, 이동전화번호, 주소, 전화번호,
                      가입신청서, 서비스이용내역, 상담내역, 미납 등
                      요금납부정보, 단말계약내역, 분할상환내역
                    </td>
                    <td>
                      <span class="highlight"
                        >명의도용 조사 완료일로부터 5년</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>명의자 가입대리점, 실사용자에 의한 법적분쟁 대응</td>
                    <td>
                      명의도용 접수신청서, 명의도용 조사내역, 판정결과, 변상내역
                    </td>
                    <td>
                      <span class="highlight"
                        >명의도용 조사 완료일로부터 5년</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>SK국제전화 00700 앱</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>국제전화 혜택/이벤트 안내</td>
                    <td>
                      이동전화번호, 이용목적, 설치경로, 선호요금제, 선호혜택
                    </td>
                    <td>
                      <span class="highlight">서비스 이용 기간</span><br />※
                      회원 탈퇴 시, 고객민원 처리 등의 사유로 최대 3개월까지
                      보유
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- end // to개발 / popup02 개인정보의 이용목적, 수집항목 및 보유기간(선택) -->

      <!-- 기본적인 서비스 제공을 위해 타 기관으로부터 제공받는 정보  -->
      <section
        v-if="currentModal === '3'"
        id="modalLayerPopup"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              기본적인 서비스 제공을 위해 타 기관으로부터 제공받는 정보
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <table>
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>서비스명</th>
                    <th>제공자</th>
                    <th>제공받는 항목</th>
                    <th>수집 및 이용목적</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>국제전화 00700.com 홈페이지</td>
                    <td>코리아크레딧뷰로</td>
                    <td>연계정보(CI) &amp; 중복확인정보(DI)</td>
                    <td>서비스 이용에 따른 본인식별∙인증절차에 이용</td>
                    <td>
                      <span class="highlight">본인식별∙인증완료시까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>SK국제전화 00700 앱</td>
                    <td>코리아크레딧뷰로</td>
                    <td>연계정보(CI) &amp; 중복확인정보(DI)</td>
                    <td>서비스 이용에 따른 본인식별∙인증절차에 이용</td>
                    <td>
                      <span class="highlight">본인식별∙인증완료시까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>sk7mobile.com 홈페이지</td>
                    <td>나이스평가정보, 코리아크레딧뷰로</td>
                    <td>연계정보(CI) &amp; 중복확인정보(DI)</td>
                    <td>서비스 이용에 따른 본인식별∙인증절차에 이용</td>
                    <td>
                      <span class="highlight">본인식별∙인증완료시까지</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!--end 기본적인 서비스 제공을 위해 타 기관으로부터 제공받는 정보  -->

      <!-- to개발 / popup03 개인정보처리 업무의 위탁 -->
      <section
        v-if="currentModal === '4'"
        id="modalLayerPopup"
        key="20210120-03"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              개인정보처리 업무의 위탁
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width: 30%" />
                  <col style="width: 30%" />
                  <col style="width: 40%" />
                </colgroup>
                <caption>
                  이동전화 개인정보처리 - 위탁한 업체, 위탁 업무 정보입니다.
                </caption>
                <tbody>
                  <tr>
                    <th>위탁한 업체</th>
                    <th>다시 위탁한 업체</th>
                    <th>위탁 및 다시 위탁한 업무</th>
                  </tr>
                  <tr>
                    <td>SK텔레콤</td>
                    <td>SK주식회사C&C</td>
                    <td>고객관리시스템 운영<br /></td>
                  </tr>
                  <tr>
                    <td>코니퍼</td>
                    <td></td>
                    <td>고객관리시스템 운영<br /></td>
                  </tr>
                  <tr>
                    <td>아이브랩</td>
                    <td></td>
                    <td>온라인시스템 운영</td>
                  </tr>
                  <tr>
                    <td>자몽컴퍼니</td>
                    <td></td>
                    <td>전자문서시스템 운영</td>
                  </tr>
                  <tr>
                    <td rowspan="3">SK텔레콤</td>
                    <td>에프앤유신용정보</td>
                    <td>
                      • 이동전화 관련 각종 조회, 변경 등 각종 고객센터 업무<br />
                      • 통화내역 발급, 명의도용 접수, SMS 수신 확인<br />
                      • 통화품질 관련 고객 상담업무 처리<br />
                      • 요금청구서<br />
                      • 미납요금에 대한 채권추심 및 추심업무 관련 고객 상담<br />
                      • 해외 로밍 신청 접수 처리<br />
                      • 가입신청서 심사<br />
                      • 고객혜택 정보 및 광고수신 동의를 한 고객에 대한
                      SK텔링크의 상품, 서비스 홍보 활동
                    </td>
                  </tr>
                  <tr>
                    <td style="border-left: 1px solid #e2e2e4">SK 플래닛</td>
                    <td>AI 고객상담 업무</td>
                  </tr>
                  <tr>
                    <td style="border-left: 1px solid #e2e2e4">베스핀글로벌</td>
                    <td>AWS 클라우드 인프라 관리, 운영 (서울 리전)</td>
                  </tr>
                  <tr>
                    <td>그린피플</td>
                    <td></td>
                    <td>요금조정, 청구서검증, 금액대사, 재고관리</td>
                  </tr>
                  <tr>
                    <td>서비스에이스</td>
                    <td></td>
                    <td>가입정보확인</td>
                  </tr>
                  <tr>
                    <td>나이스평가정보, 코리아크레딧뷰로</td>
                    <td></td>
                    <td>
                      • 실명인증서비스<br />
                      • 본인확인서비스 (휴대폰인증, 범용공인인증,
                      신용카드인증)<br />
                      • 아이핀서비스
                    </td>
                  </tr>
                  <tr>
                    <td>네이버</td>
                    <td></td>
                    <td>본인확인서비스 (네이버인증)</td>
                  </tr>
                  <tr>
                    <td>케이에스넷</td>
                    <td></td>
                    <td>은행계좌 자동이체 유효성인증</td>
                  </tr>
                  <tr>
                    <td>케이에스넷, 페이레터</td>
                    <td></td>
                    <td>신용카드 결제</td>
                  </tr>
                  <tr>
                    <td>버프리프</td>
                    <td></td>
                    <td>이동전화 가입, 상담, 배송, 이벤트</td>
                  </tr>
                  <tr>
                    <td>대리점 8개 (※대리점명 표하단)</td>
                    <td>판매점 3,629개 (※판매점명 표하단)</td>
                    <td>가입, 변경, 해지, 번호이동, 요금수납, 기타 상담</td>
                  </tr>
                  <tr>
                    <td>체인로지스, 만나코퍼레이션</td>
                    <td></td>
                    <td>유심∙단말기 배송</td>
                  </tr>
                  <tr>
                    <td>GS엠비즈, SK엠앤서비스</td>
                    <td></td>
                    <td>사은품제공</td>
                  </tr>
                  <tr>
                    <td>한국정보통신진흥협회</td>
                    <td></td>
                    <td>
                      • 개인신용정보 전송 요구에 따른 (거점)중계기관 이용<br />
                      • 알뜰폰허브 가입 접수
                    </td>
                  </tr>
                  <tr>
                    <td>인포뱅크</td>
                    <td></td>
                    <td>카카오톡 알림</td>
                  </tr>
                </tbody>
              </table>

              <h2>국제전화(00700.com 홈페이지, 앱)</h2>
              <table>
                <colgroup>
                  <col style="width: 60%" />
                  <col style="width: 40%" />
                </colgroup>
                <caption>
                  국제전화 개인정보처리 - 위탁 업체, 위탁 업무 정보입니다.
                </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                  </tr>
                  <tr>
                    <td>코니퍼</td>
                    <td>고객관리시스템 운영</td>
                  </tr>
                  <tr>
                    <td>나이스신용정보</td>
                    <td>
                      서비스 고객상담, 국제전화 서비스 이용요금제 안내,
                      전자문서시스템 운영
                    </td>
                  </tr>
                  <tr>
                    <td>그린피플</td>
                    <td>요금조정, 청구서검증, 금액대사, 재고관리</td>
                  </tr>
                  <tr>
                    <td>서비스에이스</td>
                    <td>가입정보확인</td>
                  </tr>
                  <tr>
                    <td>SK엠앤서비스</td>
                    <td>국제전화 서비스 이용요금제 안내</td>
                  </tr>
                  <tr>
                    <td>GS엠비즈</td>
                    <td>상품권 발송</td>
                  </tr>
                  <tr>
                    <td>코리아크레딧뷰로</td>
                    <td>본인확인서비스 (휴대폰인증)</td>
                  </tr>
                </tbody>
              </table>
              <div style="font-size: 12px; font-weight: 400">
                ※ 대리점 : 태우통신, 엠씨씨네트월드, 비전모바일,
                에스제이네트웍스, 에셀트리, 기준정보통신, 글로벌네트웍스,
                인스코리아
                <br />
                ※ 판매점 : (가연)탑텔레콤(미래정보), (서면)유에스모바일,
                010모바일_부산, 0통신, 1004모바일, 119모바일,
                1994휴대폰5호_송탄, 1번가통신(계열), 21세기통신(서울용산),
                21세기폰통령, 21정보통신(목포), 25시텔레콤, 276모바일,
                2호광장아이정보통신, 300텔레콤(폰방앗간), 365통신물류(대전),
                3사정보통신(목포), 777휴대폰안성점,
                815텔레콤미아동(폰방앗간배석기), 88모바일, 88통신(대림),
                998통신(시흥), 99텔레콤(용인), A1모바일, ABC폰마트, AM모바일,
                ark통신(서울구로), ASUS김해, BD통신, BF연합, BF연합통신, BK통신,
                BS정보통신(노원구-중계동), C&K모바일, C&T프라자S.K, CJ텔레콤,
                CK통신, CP통신(범물), CS모바일, CS통신, CS통신(도봉구-쌍문동),
                D&G통신, DC텔레콤_부산, DH통신(광주/금호월드), DK모바일,
                DMB정보통신, DM프리넷, DS정보통신(시흥), EU정보통신(수원),
                FClabs강변테크노(폰방앗간), FM통신(경산), GANBATTAMIR(TEDY),
                GK_사당, GK_삼양, GK_정릉, GK_피에스, Good통신_부산,
                GSM코리아(용산), G텔레콤, HELLO통신_중앙대, HHC통신, HM텔레콤,
                HS텔레콤, HS통신(구미), H모바일, IDM정보통신, IMC모바일,
                IMT코리아십정(부성), INS대곶, JH정보통신, JH텔레콤, JH통신,
                JK모바일(부산/기장), JM텔레콤_서산, JM통신, JS정보통신,
                Js텔레콤, JS텔레콤(부천), JS통신(영등포), JW모바일(부천),
                J텔레콤, K-1정보통신, K2텔레콤(인천), kb통신(대림),
                KG컴퍼니(남양주시-다산동), KR모바일1호점, KT마트앤T월드(청주),
                LOL모바일_서대문, LTE국민텔레콤(인천영종도), LTE통신(시흥),
                Main, MAMA통신, MC모바일, MK정보(서울), MO모바일(부산연제구),
                M-PLAY, MSTelecom의정부(콜앤러브), MS통신, ms통신(창동),
                m스타(인천), M통신, NEW삼성공릉(노원구-공릉동),
                NS소사직영샵(부천), NS텔레콤(노원구-하계동), N모바일,
                N샵운서(영종도), n텔레콤, N-통신, OHOTelcom, OK모바일(안산),
                OK모바일(천안), OK텔레콤안양지하상가(폰방앗간), OK통신,
                P&V텔레콤(강변테크노), PMJ캄보디아통신(안산), PS모바일,
                ROO정보통신, S.H정보통신(상호)(시흥), SA모바일, SA통신, SCL통신,
                SCOMPANY, Seven텔레콤, SG정보통신, SH모바일, SIB대성유통,
                SJ모바일(고양시-원흥동), SKS통신논산(폰방앗간), SK미라클,
                SK숭인, SK유림, SK정보통신, SK청양대리점, SK텔레콤건대점,
                sm무역(서울), SM스토어, SM통신, SN네트웍스, SSK텔레콤천안(부성),
                SS모바일, SS텔레콤, STX칸, ST통신, sun정보통신, S모바일,
                T.S(태성)모바일, TB모바일, THE조아통신, TI모바일, TnKmobile,
                TOP정보통신(파주), TOP통신, TR모바일(JB), TU통신,
                T정보통신(용산), T텔레콤, U2모바일(부산),
                UP네트웍스의정부지하(부성), US모바일(경기광주),
                WC컴퍼니(송파구-마천동), WIN(윈)모바일(김해), W텔레콤_양산,
                YG이동통신, YG정보통신(남원), YJ컴퍼니(남양주시-진접읍),
                YL통신(일산), YN텔레콤, Yoon텔레콤(벌교), YT통신, Y텔레콤(목포),
                가가정봉통신_안산, 가나안텔레콤, 가나통신, 가나통신(통영),
                가나휴대폰, 가람(양산), 가람모바일, 가람통신,
                가로수텔레콤(성북구A), 가리봉선불폰(구로), 가린텔레콤,
                가미중국식품, 가바인터내셔널(서울중구), 가야텔레콤, 가야통신,
                가연모바일, 가온모바일, 가온센텀, 가온정보통신(만수),
                가온컴퍼니, 가온컴퍼니(수서동A), 가온컴퍼니가양점(폰방앗간),
                가온통신, 가은이, 가은텔레콤(의정부지하상가),
                가이드9호점(동대문), 가인정보통신, 가호정보통신,
                갈렙종합상사_사천, 갈마소리통신, 갈마정보통신, 감동텔레콤,
                감만정보통신, 감성컴퍼니, 감자글로벌, 감전텔레콤, 강남모바일,
                강남엔젤폰, 강남통신(강북구-수유동), 강릉폰(폰방앗간), 강명통신,
                강변통신, 강산통신, 강일텔레콤, 강준통신(시흥),
                강화스피드텔레콤, 개떡카페(cafe)&SH모바일(논현), 개미통신, 개화,
                갤럭시에스엠(사천), 거북이폰, 거상텔레콤, 거성정보통신,
                거성통신, 거제맘통신, 거제액정나라, 거창통신, 건강한휴대폰,
                건우모바일, 건우모바일인천부개동(폰방앗간), 겟폰(수원A),
                경남통신김해, 경남통신사천점, 경남통신진주점, 경동텔레콤,
                경산중고폰, 경산중고폰(경산), 경산중고폰(월드폰),
                경산중고폰거래소, 경원통신, 경주월드폰, 경품텔레쿰,
                계원통신(의왕시-내손동), 고고통신, 고니정보통신_인천서구,
                고래통신(경산), 고려(포항), 고려이동통신, 고려정보통신,
                고려통신, 고릴라폰, 고성대리점, 고양폰, 고텔레콤, 곤지암통신,
                곤지암통신(경기광주), 골드모바일(대림), 골드문(김해),
                골드문통신동두천(미래), 골드텔레콤,
                골드텔레콤수유점(강북구-수유동), 골든드래곤, 골목통신,
                곰돌이통신(강변테크노), 곰통신, 공간모바일, 공감모바일,
                공감모바일(대전)KL, 공룡통신, 공설통신, 공존모바일(임학),
                공짜텔레콤, 공짜파티, 과천모두네트웍스, 관교우리정보, 관훈,
                광복정보통신(목포), 광수텔레콤화곡동(부성), 광주글로벌매월,
                광주폰카페, 광천핸드폰가게, 광천핸드폰가게(홍성), 광통신,
                광희통신고속터미널점, 구구텔레콤, 구구폰, 구름통신2(신도림),
                구리스카이정보통신, 구지아시아, 구찌통신, 구해줘폰앤컴(광명),
                국가대표통신, 국대폰(위드컴퍼니), 국대폰3(평택), 국민폰(작전),
                국보통신(용인), 국보통신_용인, 국성텔레콤, 국제정보통신,
                국제통신, 국제통신(대림매장), 국제통신(쌍문), 국화통신,
                군베스트통신, 군포텔레콤, 군포텔레콤(군포), 군플러스모바일,
                굿뉴스모바일_수원, 굿대디모바일, 굿데이텔레콤, 굿모닝(외대점),
                굿모닝모바일2, 굿모닝목4점, 굿모닝정보통신, 굿모닝텔레콤,
                굿모닝통신(충주), 규리나라통신강동(부성), 규봉모바일(구로),
                규통신, 그랜드캐피탈(인천), 그레이슬, 그린정보통신, 그린통신,
                그린핸드폰(의정부지하), 글로벌(안양시-성결대점), 글로벌네트웍스,
                글로벌네트윅스, 글로벌제이제이_부평, 글로벌통신,
                글로벌통신(대림), 글로벌통신(부산), 금강정보통신, 금릉폰,
                금복통신, 금빛통신, 금산통신, 금샘, 금성사, 금영미래통신,
                금오텔레콤_예산, 금정모바일, 금천모바일_서울, 금촌폰,
                기가샵(대구), 기가텔레콤, 기가팩토리, 기담텔레콤, 기분좋은,
                기성정보통신, 기원, 기원텔레콤(광주), 기장판다모바일, 기준,
                기준정보통신, 길벗정보통신_마산_, 길상모바일, 길상휴대폰,
                길정보통신(성남지하), 김가네폰가게, 김앤김,
                김앤방모바일(동대문구-답십리동), 김천영통신, 김포아저씨,
                김해두니여(김해), 까래통신, 까미통신, 까츄모바일, 깨모바일,
                꽁돈버는머니폰, 꽃남통신, 꿀꿀스마트통신(구리시-갈매동),
                꿀단지텔레콤(평택), 꿀통신_창녕, 뀨텔레콤(노원구-공릉동),
                나경통신(구)제이컴퍼니), 나나힐통신, 나노(부평), 나눔통신,
                나눔통신(영천), 나눔통신,아이폰수리, 나라통신, 나라통신(안산),
                나라통신_여수, 나래이동통신(본점), 나래텔레콤, 나로통신, 나르샤,
                나린텔레콤(일산), 나마스떼모바일, 나무모바일(의정부시-민락동),
                나무텔레콤, 나바인터내셔널(중구-태평로2가),
                나바인터네셔널종각(폰방앗간), 나비모바일(인천/연수), 나비텔레콤,
                나야나야경희대점(폰방앗간), 나우모바일, 나우텔레콤(양산),
                나우통신, 나이스드림텔레콤(도봉구-방학동), 나이스텔레콤,
                나이스통신(마포), 나인텔레콤_상록수, 낙원통신, 남광모바일(계산),
                남광통신, 남동통신, 남매통신, 남부정보통신, 남산통신,
                남서울정보통신, 남양주아이폰수리이서통신, 남원통신(제주서귀포),
                남포대학, 남포동중고폰, 내담통신, 내폰을부탁해(수원A), 넘버원,
                넘버원모바일, 넘버원텔레콤, 넘버원텔레콤(안성), 네오정보,
                네오통신, 네잎크로버통신, 네트웍스미엘(은평), 네트웍툴샵,
                네팔모바일, 네팔모바일_수원, 넥스테라, 넥스트제네레이션_서울,
                노다지텔레콤, 노란마켓, 노란마켓(구리), 노란마켓(마포),
                노란마켓(부산서면), 노란마켓(의정부), 노란마켓_대전시청역점,
                노란마켓_부산사상점, 노란마켓_부산해운대점, 노란마켓_성신여대점,
                노란마켓대전시청역점, 노란마켓부산대점,
                노란마켓청주점중고폰매입판매$아이폰수리센터,
                노랑모바일남촌점(인천), 노마상회, 노마진모바일(산곡), 노원탑,
                녹색통신, 놀숲천호로데오점(천호동), 누나텔레콤, 누리,
                누리정보통신, 누리컴퍼니(동대문구-이문동), 누리통신(세종),
                누리통신(안산), 누림모바일, 뉴(new)야탑통신, 뉴국제정보통신,
                뉴디지털정보통신, 뉴베스트(의정부), 뉴스카이(암사),
                뉴스타(화원), 뉴스타2호, 뉴아마존, 뉴월드모바일(부천),
                뉴코아텔레콤_이천, 뉴텔레콤, 뉴텔레콤_창원, 뉴텔레콤밀레, 뉴폰,
                뉴폰(NEWPHONE), 느루, 느루모바일, 늑대와여우컴퓨터, 늘봄,
                늘솜모바일(제천), 늘푸른통신, 니나노텔레콤, 니즈플러스,
                닌자통신, 다감통신, 다나와통신, 다다통신, 다다폰(경기광주),
                다둥이통신, 다마꼬데스, 다모다플러스(다모아(안산)),
                다모아정보통신, 다모아통신, 다모아통신(김해), 다모아플러스,
                다미통신(수원시-정자동), 다비텔레콤중림동(폰방앗간),
                다빈텔레콤(답십리), 다솔모바일, 다솔통신(산격), 다솜텔레콤,
                다연컴퍼니, 다예통신, 다온_대림, 다온Mobile(중랑구-상봉동),
                다온라인, 다온모바일, 다온모바일서창동(부성), 다온정보,
                다온텔레콤, 다온통신, 다온통신(하남시-신장동), 다올_시흥,
                다올사무기(동인천), 다올성남(폰방앗간), 다올통신, 다와정보통신,
                다우통신, 다원TC장안, 다원텔레콤, 다원텔레콤2, 다원통신,
                다원통신(평택), 다윗통신(광진구A),
                다은텔레콤(구-동산텔레콤시흥), 다음모바일, 다이아, 다이아모바일,
                다인텔레콤, 다인통신, 다진하이킥, 다판다모바일,
                다판다새폰헌폰_양산, 다힘(대구북구), 단무지(선한텔레콤),
                단무지폰_노원, 단유통신, 단하통신, 달리컴퍼니, 달미정보통신,
                달인텔레콤3호점, 당감플러스, 당근모바일(인천),
                당신을위한휴대폰비서心, 당정모바일, 당진휴대폰_당진,
                당하한빛이동통신, 대경텔레콤, 대구수성구아이폰수리애플웍스,
                대구아이폰수리이지픽스, 대구전자담배(해바라기통신),
                대구칠곡중고폰에코폰, 대덕통신, 대도모바일(시흥),
                대림통신(대림), 대명정보(미아), 대명정보통신(강북구-미아동),
                대명텔레콤, 대명텔레콤(강북구-번동), 대명텔레콤(거제),
                대명통신(대구), 대명폰(오산A), 대명폰3호점(오산), 대박MK(강동),
                대박네트웍스(전주), 대박모바일(수원지하상가), 대박모바일(숭의),
                대박착한폰2호점, 대박텔레컴, 대박텔레콤, 대박텔레콤(광주),
                대박텔레콤(장안동), 대박텔레콤강릉송정점, 대박텔레콤유천점,
                대박통신, 대박통신(대전), 대박통신(발안), 대박통신(안산),
                대박통신(의정부), 대박통신도촌점, 대박통신성포점, 대박폰,
                대박폰요기요, 대박플러스(시민공원지하), 대산휴대폰프라자,
                대상정보, 대성F&C신천점(시흥), 대성모바일(플레너),
                대성모바일_밀양, 대성모바일_시흥, 대성통신(일산),
                대신정보(목포), 대연1호점(부산), 대연성민, 대영정보통신,
                대영정보통신_인천, 대영텔레콤2호점, 대우아이테크_안산,
                대원모바일, 대원텔레콤(성남지하상가), 대원통신, 대인배모바일,
                대일통신, 대전별통신, 대전상회(대전), 대전선불폰(대전),
                대정씨앤씨서울지점, 대진17호, 대창텔레콤, 대한모바일(부평),
                대한애플, 대한정보통신, 대한텔레콤(의정부지하상가),
                대형텔레콤(부산/연지), 더굿컴퍼니, 더글로우, 더더(동대문),
                더더컴퍼니, 더라온, 더베스트폰_오산, 더본모바일,
                더블S(구리시-수택동), 더블엠모바일, 더블와이컴퍼니(중곡동),
                더블유에스컴퍼니, 더블유통신, 더블제로모바일, 더블조이(연수),
                더블케이대박통신, 더원(동두천시-생연동), 더원네트웍스, 더원통신,
                더조은모바일(구월), 더주누(TheJUNU), 더케이모바일,
                더킹컴퍼니(대구), 더폰샵, 더폰코리아, 더폰통신(전주),
                더플레이(강북구-번동), 덕구통신, 덕산텔레콤, 덕이폰, 덤플러스,
                데이타통신_사천, 데일리통신(청라), 도경글로벌(DKGlobal),
                도도통신노학점, 도로시컴퍼니, 도마혁, 도매통신남동탄, 도매폰,
                도매폰_고양, 도매폰2010(평택안중), 도매폰센터, 도매폰센터(평택),
                도매폰의정부, 도연, 도연통신, 도연통신_신가(광주), 독도텔레콤,
                돌고래통신, 동남텔레콤(진주), 동대구유림,
                동두천모바일(동두천시-지행동), 동방텔레콤, 동방통신,
                동부나이스드림, 동부통신_부산, 동북국제(대림), 동빈, 동산텔레콤,
                동산텔레콤금천구(부성), 동신정보통신(부산), 동아모바일8호점,
                동아모바일만수점(만수), 동아모바일학익점(학익), 동아이동통신,
                동아전자, 동아통신공사, 동인텔레콤, 동일, 동촌모바일,
                동탄2일등폰, 동탄호수공원, 동하모바일, 돼지네(구리시-수택동),
                돼지텔레콤(당진), 두두모바일, 두리서용문점, 두리텔레콤,
                두리텔레콤동암(부성), 두무두무컴퍼니, 두청년, 두청년2, 두폰,
                둥이종합조경, 둥이통신_통영, 듀폰, 드루와통신(노량진),
                드림네트웍스, 드림네트웍스(용산아이파크3층), 드림모바일,
                드림모바일_광주, 드림온정보통신, 드림정보통신, 드림텔레콤,
                드림텔레콤(서울오류), 드림텔레콤(안산), 드림텔레콤_부산,
                드림통신, 드림통신(구로), 드림통신_안산, 디디모바일, 디딤모바일,
                디비케이(DBK)텔레콤, 디앤알컴퍼니, 디에스텔레콤(동대문미래),
                디에이드림, 디에이치(남양주시-화도읍), 디에이치모바일,
                디에이치모바일중고폰, 디엘무역, 디엠모바일, 디오젠커머스_군포,
                디와이텔레콤(노원구-하계동), 디와이통신, 디제이컴퍼니,
                디지싸본점, 디지싸본점(DGSSA), 디지털텔레콤, 디컴퍼니, 디텍그룹,
                디텍스, 디토우모바일진주점, 딸기텔레콤_나주, 딸기폰(부산),
                땅이지, 땅파서폰파는집, 땅파서폰파는집(반여점),
                땅파서폰파는집덕천점, 땅폰광안점, 땅폰서면점, 땡글이텔레콤,
                또바기, 또바기모바일, 똘똘텔레콤, 뚜뚜통신, 뚜비모바일(충주),
                뚝딱성지2호점(서울), 라라네트웍스(의정부), 라라통신(여수),
                라베니체폰, 라비커뮤니케이션, 라온(계산), 라온닷컴, 라온웍스,
                라온정밀, 라온컴퍼니(LKT)(부천), 라온텔레콤, 라이언통신, 라이온,
                라이온모바일, 라이프텔레콤, 라인네트웍스(흥덕점), 라인모바일,
                라인텔레콤(을지로), 라함텔레콤(동인천), 람경통신,
                람경통신8호점(고대), 람보통신(송현동), 랍스마트폰,
                랜퉁텔레콤_서울구로, 러화국제, 럭키모바일, 럭키모바일(김해),
                럭키정보통신, 럭키텔레콤, 럭키통신, 럭키통신_동작, 레드모바일,
                레드신암(대구), 레드정보통신(본오점), 레드텔레콤_서울구로,
                레드통신, 레드통신(중리), 레스트, 레오모바일2호(양주시-옥정동),
                레오통신, 레오통신(시흥), 레이디two, 레인보우텔레콤(연무점),
                레전드, 레전드모바일2(인천/제물포), 렌트닷컴,
                로그인유학원(김해), 로덱스화장품&정통신, 로드_청주, 로또모바일,
                로또모바일(청라), 로또정보통신(마산), 로또텔레콤,
                로또텔레콤(창원), 로라모바일, 로미오통신(익산),
                로아정보통신_인천, 로얄모바일, 로얄엔터프라이즈,
                로얄텔레콤(영종도), 로얄통신쌍문동(부성), 로이통신,
                로자통신(안산역), 로즈텔레콤(양산), 로하스, 루시텔레콤,
                루아모바일, 루이통신, 루키통신_김해, 룸비니(K2NEPAL),
                뤼둥버(부산), 륀뽀, 류컴퍼니, 르소(울산), 리더스모바일,
                리더스오브이노베이션(남양), 리더스텔레콤교대점, 리더정보통신,
                리드텔레콤_아산, 리사모바일(경기광주), 리아,
                리아(k모바일)(화성), 리안(신도림테크노), 리안텔레콤, 리얼와이즈,
                리온, 리즌정보통신, 리첼이노베이션, 리치앤코마부하이,
                리치컴퍼니, 리치텔강변테크노(KL), 리치텔레콤, 리치통신,
                리카모바일, 리트로바, 리한, 리호모바일, 린헤어구월동(부성),
                링링, 링치그룹, 마루, 마루(안양시-호계동), 마루모바일,
                마루텔레콤(서울대림), 마루통신, 마린텔레콤, 마마통신_부산,
                마스트통신, 마이모바일(관악), 마이텔레콤, 마이통신, 마이폰,
                마징가텔레콤(중앙점), 마징가텔레콤2호, 마카롱통신,
                마켓제이앤제이(폰방앗간심현재), 막둥이텔레콤(전주), 만기통신,
                만나폰_고양일산, 만복통신, 만복통신(장기), 만선(대구),
                만선큰고개지점, 만선통신, 만세텔레콤(화성발안),
                만세통신(양산덕계점), 만세통신_부산W점, 만세통신_사직점,
                만세통신_울산웅촌점, 만세폰, 만월, 만평통신, 망고스,
                망고통신(강화), 망내, 매니아, 매니아M&C, 매직텔레콤,
                맥스모바일남목점, 맨2통신, 맨앤텔(강변테크노), 멀티스,
                멀티정보통신, 멀티존텔레콤, 메시아컴퍼니, 메이트모바일(인천),
                메이트텔레콤(광주), 메이플라이통신, 메인텔레콤,
                메인텔레콤(군포시-부곡동), 메인통신, 메카정보, 메트로통신,
                멕시멈, 멤버스, 명당(중랑구-신내동), 명륜연텔, 명문통신,
                명산모바일(대구), 명선통신, 명성대박, 명성정보, 명성정보(인천),
                명성정보통신, 명성텔레콤(은평구A), 명신텔레콤(공주), 명음텔레콤,
                명촌여행사_대림, 명텔레콤, 명폰, 명품모바일(만촌),
                명품텔레콤(일산A), 모닝텔레콤(화성), 모두의모바일(부산),
                모두컴퍼니(부산), 모든스마트폰, 모든텔레콤, 모든통신,
                모든폰_세종_, 모라텔레콤, 모마, 모바일89, 모바일SC,
                모바일SC(서울은평), 모바일데이, 모바일랜드(용산역), 모바일박스,
                모바일뱅크, 모바일부동산, 모바일불리스, 모바일샵(보건대점),
                모바일샵(제천), 모바일스토리(성북구-삼선동1가),
                모바일스토어(천안/목천), 모바일스토어(천안/백석),
                모바일스토어(평택), 모바일스토어성정점, 모바일엔, 모바일온,
                모바일원, 모바일월드, 모바일컴퍼니(장기점), 모바일트리,
                모바일파크(김해), 모바일패밀리(의정부), 모바일팩토리,
                모바일팩토리(강북구-수유동), 모바일팩토리(호평),
                모바일팩토리_홍성, 모바일폰컴퍼니, 모바일프렌즈(호산점),
                모바일플래닛, 모바일플랜, 모바일플러스, 모바일하우스,
                모비맨(연수), 모빌랜드, 모빌랜드_안산, 모빌샵_시흥, 모션텔레콤,
                모아telecom, 모아텔레콤, 모이자_서울구로, 모전텔레콤,
                모토로라정보통신, 모퉁이(인천/부개), 모현통신,
                목행동휴대폰판매(충주), 몬스터폰(폰방앗간), 몬텔, 몽드통신,
                몽텔레콤, 무등상사(광주), 무안고추통신, 무안수영, 무적통신,
                무지개컴퍼니_파주, 무지개통신(부산A), 문모바일,
                문산텔레콤(일산), 문산통신, 문텔레콤(행신), 미나리즘,
                미나정보통신(광명), 미니텔레콤, 미니통신, 미도텔레콤,
                미드모바일(강경), 미디어정보통신(여수), 미라클, 미라클통신,
                미래로컴퍼니, 미래무역, 미래무역_시흥, 미래정보통신,
                미래정보통신(강진), 미래정보통신(모바일동서남북(천안)),
                미래텔레콤, 미래통신, 미래통신(독산), 미래통신_서울대림,
                미르통신(동해), 미소_파주, 미소모바일, 미소모바일(남동),
                미소정보통신, 미소정보통신(평택), 미소텔레콤, 미소통신,
                미소통신_대전, 미스터모바일, 미스터모바일(구리시-수택동),
                미얀마식당(안산), 미원정보통신, 미카엘통신, 미키포토,
                민스모바일, 민아모바일, 민우텔레콤, 민우통신, 민이동통신,
                민이통신, 민재통신, 민정보통신, 민정보통신(광명),
                민정커뮤니케이션, 민정통신, 민주모바일, 민주모바일_전주,
                민주통신(김해), 민캉_부천, 민텔, 민텔레콤,
                민트정보통신(중랑구-면목동), 민트통신, 민후통신(안산),
                믿음모바일, 믿음텔레콤, 밀레니엄(양주), 밀레니엄이동통신,
                밀레니엄컴퓨터(광주), 밀레니엄컴퓨터(포천),
                밀레니엄컴퓨터(화성발안), 밀양홀릭통신_밀양,
                바나나모바일)_진접점(경기남양주시),
                바나나모바일독립수리제공업체남양주센터, 바나나폰(공도),
                바다모바일, 바로배터리(논현), 바로정보통신, 바로정보통신_서울,
                바르다모바일, 바른(자양), 바른(전주), 바른길통신, 바른드림,
                바른모바일, 바른모바일_완주, 바른오피스, 바른오피스(대구),
                바른월드, 바른컴퍼니, 바른텔레콤, 바른텔레콤(의정부), 바른통신,
                바른통신_마포, 바른통신_부평역, 바른통신_창원, 바른휴대폰,
                바른휴대폰_김해, 바를정보통신(고양),
                바름정보통신(김포시-운양동), 바바모바일, 바양CG(동대문),
                바울이동통신익산(부성), 바울통신, 바이폰,
                바인텔레콤(노원구-월계동), 박강컴퍼니, 박군통신_청도,
                박상욱모바일, 박카수통신, 반도모바일부산, 반석정보통신,
                반흐헝(부산), 밤가시텔레콤, 밤통신, 방교폰(화성(구)버스폰오산),
                배민폰(양산), 배산JK, 배재통신, 배짱통신(청주), 백곰모바일,
                백두산통신, 백두통신변동점, 백마폰, 백억텔레콤(평택), 백제통신,
                백호모바일(용산), 백호정보통신, 백호텔레콤, 버디텔레콤,
                버미텔레콤, 버블버스터안동점(안동), 벅수통신, 번개통신2호점,
                벌저(BOLZOO), 벌집, 범박통신, 범이통신, 범준통신_통영,
                범텔레콤(성남), 베라컴퍼니_수원, 베레쉬트모바일, 베리통신,
                베베폰_안양, 베스트(BEST), 베스트_구로, 베스트모바일,
                베스트모바일(안산), 베스트바이(강변테크노), 베스트원(제물포),
                베스트인코리아, 베스트코리안모바일(평택), 베스트텔레콤,
                베스트텔레콤2호점, 베스트폰서초(부성), 베테랑컴퍼니,
                베토벤통신(부산/진구), 베트남모바일(부산), 벳폰(안산),
                별꼴모바일(안양지하상가), 별모바일(서산), 별별통신, 별빛,
                별빛통신_수원, 별텔레콤, 별텔레콤(울산), 별텔레콤(청주,
                병아리통신, 보담모바일구월아시아드점, 보담정보통신,
                보라정보통신(김포통진), 보라텔레콤(원주), 보람모바일,
                보람텔레콤(평택), 보물섬통신, 보배드림, 보배정보통신_진도,
                보성정보통신(성남), 보성정보통신_송파, 보스, 보스그룹,
                보아스솔루션(서초구A), 복산텔레콤(울산), 복현아이폰수리점,
                본앤코리아, 봄봄, 봄스팩토리, 봉동해피통신, 봉봉텔레콤,
                봉봉통신, 부건모바일(양주시-옥정동), 부경텔레콤, 부곡정보통신,
                부산베트남, 부산사상모바일, 부산원텔레콤, 부산정보연산점,
                부산폰천국, 부원통신, 부자텔레콤, 북경전화국,
                분당중고폰멀티폰샵분당아이폰수리서현아이폰수, 불티나통신(안동),
                뷔케이, 뷔통신, 뷔통신_대전, 브더욱글로리_인천부평, 브라더,
                브라더컴퍼니, 브라더텔레콤(광진구), 브라더통신,
                브랜드모바일_천안, 브로컴퍼니, 브이아이피모바일,
                브이아이피텔레콤(광진구-중곡동), 블랙문(의정부), 블랙퀸,
                블레스3호점, 블루1호점, 블루갤럭시, 블루드래곤, 블루명가,
                블루모바일, 블루오션텔레콤, 블루웨이, 블루텔레콤,
                블루텔레콤(금천구-시흥동), 블리스, 블리스(성북구),
                비디오카페수원탑동점, 비디정보통신, 비래영우통신,
                비래폰마트대전(대전/무한통신), 비비9, 비비모바일(부산중앙),
                비상통신, 비스타엠, 비에스, 비에프모바일, 비엔모바일,
                비엔모바일(부평), 비엔통신, 비엠넷, 비엠모바일, 비엠티정보통신,
                비와이텔레콤(수원지하), 비일공사울산(폰방앗간), 비전모바일,
                비제이모바일(동인천), 비제트컴퍼니_인천, 비젼텔레콤(중계),
                비젼텔레콤(중곡), 비젼플러스, 비쥬통신, 비케이모바일_노원,
                비케이정보통신, 비케이통신, 비타민(북부), 비투비풀러스,
                비트통신, 비티모바일(화성), 비피엠통신, 빅모바일,
                빅뱅정보통신(도화점), 빅뱅정보통신(법원점),
                빅뱅정보통신(삼산동), 빅뱅정보통신(용현),
                빅뱅정보통신(인천/학익동), 빅뱅통신, 빅홈국제여행사(군포),
                빈스(대구), 빈텔레콤, 빈텔레콤_울산언양, 빌리브, 빌리언모바일,
                빙그레모바일(가장점), 빛고을정보통신, 빛으로텔레콤(용인남사),
                빨간모자(부산), 빵떡폰샵, 빵텔레콤, 뽀너스, 뽐뿌폰, 삐삐통신,
                삐삐통신_김해, 삐삐폰대구경산점, 사과텔레콤(문현), 사구통신,
                사랑모바일, 사랑방멀티(광탄), 사만아시아, 사자핸드폰,
                사직플러스, 사천통신, 산내미래통신, 산다라폰(부천), 산성깡S통신,
                산성모바일, 삼경통신, 삼낭(SAMNANG)모바일, 삼량진대일통신,
                삼만정보통신(노원구-공릉동), 삼미폰케이스,
                삼박금정(군포시-금정동), 삼박금정텔레콤(군포A), 삼백통신,
                삼백통신먹골(중랑구-묵동), 삼봉통신_거제,
                삼사텔레콤(맑은샘)성남, 삼성SB통신, 삼성이동통신, 삼성텔레콤,
                삼성텔레콤(영주), 삼성통신, 삼성통신(서동로), 삼성통신(언양),
                삼순모바일, 삼양통신, 삼양프라자(강북구-미아동), 삼영텔레콤,
                삼영통신, 삼육오모바일, 삼일텔레콤, 삼일통신, 삼일통신(파주),
                삼청통신, 삼형제텔레콤, 상대원정보통신(성남), 상록이동통신,
                상민텔레콤, 상상모바일(인천), 상상텔레콤, 상상트레이드,
                상승모바일, 상승텔레콤, 상아정보통신, 상아텔레콤(부천한울),
                상해중국슈퍼, 상해중국식품_김해, 새내모바일, 새누리통신,
                새드텔레콤(대구), 새로(강북구-수유동), 새로나(안양), 새록통신,
                새롬테크노, 새롬텔레콤, 새롬통신(의정부), 새마을,
                새마을정보통신_대구, 새마을텔레콤전의점, 새마을통신대신점,
                새봄통신, 새솔11, 새폰줄게헌폰다오, 새하텔레콤, 새한정보통신,
                새한통신, 샛별통신, 생생통신, 샤넬텔레콤, 샤넬텔레콤(예산),
                샤샤마트, 샤인(SHINE), 샤인모바일, 샤인모바일(안양),
                샤인모바일_부천, 샤인컴퍼니_수원, 샤크텔레콤, 샤크통신(대전),
                샤플라(SHAPLA), 샬롬텔레콤, 샴푸폰나라, 샴푸폰나라_동대문,
                샴푸폰나라동대문, 서대구통신, 서린글로벌, 서문통신,
                서산진텔레콤(티월드), 서산핸드폰, 서영텔레콤,
                서우정보통신(경기부천시), 서울메큐리통신, 서울역전자담배(용산),
                서울이동통신, 서울재팬코머스(서울),
                서울중고폰폰당폰영등포점(영등포), 서원컴퍼니,
                서윤정보명일동(폰방앗간), 서윤텔레콤(일산), 서이, 서통신,
                서하네, 석광통신(대전), 석교센스_폰샵, 석군모바일, 석사정보통신,
                선경텔, 선모바일, 선모바일_수원, 선암텔레콤, 선우통신,
                선정보통신, 선중텔레콤, 선진통상, 선화영희와통신,
                설렘모바일(와동점), 섬비서모바일, 섬비서모바일(안산), 섭통신,
                성공모바일, 성공통신(강변테크노), 성남IMT, 성남이동통신,
                성동정보통신, 성림텔레콤, 성미통신, 성민통신, 성산하나로통신,
                성성텔레콤(천안), 성신모바일(성북구A), 성신유통, 성신유통(오산),
                성신텔레콤, 성원정보통신, 성원텔레콤, 성재텔레콤, 성주월드폰,
                성지, 성지모바일(양주), 성지모바일도림점, 성지텔레콤,
                성지텔레콤인천(부성), 성지폰(양천구-신월동), 성창텔레콤,
                성호이동통신, 성환텔레콤, 성훈대박(송파), 세경통신(안산),
                세계로텔레콤(부천시-옥길동), 세계이동통신, 세계통신,
                세교폰(경기오산시), 세린텔레콤_창원, 세림이동통신,
                세림텔레콤(왜관), 세방모바일, 세복모바일_서울강서, 세븐모바일,
                세븐텔레콤(면목), 세븐통신서대문(폰방앗간엄지환), 세상모든폰,
                세상모든폰_서울암사_, 세성올댓폰, 세연통신(남양주),
                세영통신_대구, 세웅통신, 세원통신, 세은통신, 세이브통신,
                세인텔레콤_을지로지하상가, 세일텔레콤(의정부), 세종, 세종통신,
                세진, 세진2호점, 세진텔레콤, 세화통신, 센스정보통신,
                센스텔레콤(인천), 센터모바일, 센텀모바일, 센텀휴스,
                센트럴통신(강남고속터미널), 셀타운_굿모닝쇼핑몰, 셀폰모바일,
                셀프메이드(명동), 소녀폰(구리), 소녀폰(신사역점),
                소녀폰(오목교점), 소녀폰(인천논현), 소녀폰_건대점,
                소녀폰_공덕역, 소녀폰_노원역점, 소녀폰_부천역점,
                소녀폰_분당서현점, 소녀폰_상봉역점, 소녀폰_세종,
                소녀폰_종각역점, 소녀폰동대구점, 소녀폰산격점, 소녀폰상인점,
                소녀폰제주점, 소녀폰창원상남점, 소녀폰칠곡, 소녀폰포항점,
                소리모바일, 소리새통신, 소리샘모바일, 소리샘텔레콤(수원),
                소리이동통신, 소리정보통신(군포시-금정동), 소리통신,
                소리통신대전, 소망모바일, 소망텔레콤(작전), 소명모바일, 소울,
                소원텔레콤, 솔티텔레콤(안산), 솔티휴대폰할인마트, 송이통신,
                송혁이네, 송현텔레콤, 수(水)글로벌, 수담_J텔레콤, 수담_K플러스,
                수담_LH모바일, 수담_갤럭시, 수담_경성모바일,
                수담_김병후(관리용), 수담_다이아모바일, 수담_대우정보통신,
                수담_모바일뱅크, 수담_백프로_거제, 수담_보성텔레콤,
                수담_소중한텔레콤, 수담_수통신, 수담_스마트스토어,
                수담_스타텔레콤, 수담_신동텔레콤, 수담_신세계폰마트,
                수담_애드앤샵(부산), 수담_언니네아이폰수리점, 수담_에스아이,
                수담_에이치모바일, 수담_엔비컴퍼니, 수담_예스메이저,
                수담_올폰컴퍼니, 수담_왕창텔레콤, 수담_우진통신, 수담_임자통신,
                수담_제이제이텔레콤, 수담_제이폰, 수담_진영성지폰,
                수담_창원휴대폰연합, 수담_큰사랑, 수담_태준모바일,
                수담_피케이모바일, 수담_행복정보통신_창원, 수담_형제텔레콤,
                수담_호앙안코리아, 수담_휴대FUN, 수담_휴대폰천국,
                수리정보통신(군포시산본동), 수모바일_인천, 수민텔레콤,
                수빈텔레콤, 수수모바일_파주, 수승통신, 수아통신,
                수엔사라스마트폰, 수엠비, 수연정보통신, 수연텔레콤,
                수원8949(수원), 수원권선미소, 수원진명전자, 수유리치,
                수정텔레콤, 수청대우폰, 수호정보통신, 수호정보통신_인천,
                수호텔레콤, 숙지정보통신, 순돌이모바일(보정점),
                순돌이모바일(작전), 순돌이순천, 순수텔레콤, 순창정보통신,
                숨은휴대폰맛집, 슈비즈(서울금천구), 슈텔레콤, 슈퍼대박,
                슈퍼맨동래점, 슈퍼맨텔레콤, 슈퍼맨텔레콤(양산),
                슈퍼맨텔레콤2호점_양산, 스레이럿모바일, 스레이럿모바일(안산),
                스마일(안산시-건건동), 스마일(역촌), 스마일이동통신(연천),
                스마일텔레콤, 스마일텔레콤(남양주), 스마일텔레콤_울산성남,
                스마일통신, 스마일통신(의왕시-오전동), 스마일티,
                스마일피자연수동(부성), 스마트419, 스마트K(노원구-공릉동),
                스마트면목(중랑구면목동), 스마트모바일, 스마트모바일(경기광주),
                스마트모바일_대구, 스마트삼육오(폰방앗간), 스마트월드2,
                스마트정보, 스마트정석, 스마트중고폰(부산), 스마트컴퍼니_고양,
                스마트텔레콤, 스마트텔레콤(동대문), 스마트통신,
                스마트통신(갈매), 스마트통신(안산), 스마트폰1번가, 스마트플래닛,
                스마트플러스(동대문구휘경동), 스마트한(영종도),
                스마트한2호점(영종), 스마트한폰세상(강동구A), 스마트해솔,
                스위치모바일_수원, 스위치모바일에이엔티, 스위트베이마트(울산),
                스카이, 스카이010(성북구석관동), 스카이K1, 스카이모바일,
                스카이모바일(안산), 스카이성결(안양시안양동), 스카이연수구,
                스카이정보통신_광명, 스카이텔레콤(은평구),
                스카이텔레콤2호점(포항), 스카이통신(용현점), 스타, 스타라이트,
                스타모바일, 스타모바일(울산), 스타모바일_안산, 스타텔(창녕),
                스타통신, 스타필휴대폰(평택A), 스토리모바일대전, 스페이스,
                스피드(김해), 스피드(인천부평), 스피드_영통, 스피드모바일(상모),
                스피드원(영등포), 스피드정보통신, 스피드텔레콤,
                스피드텔레콤오목천점, 스피드통신, 승승장구텔레콤,
                승승장구텔레콤26호점, 승승장구텔레콤2호점, 승승장구텔레콤4호점,
                승승컴퍼니, 승신텔레콤, 승원통신, 승진통신, 시나브로,
                시내모바일(잠실), 시대정보통신, 시아통신, 시온모바일,
                시원한정보통신, 시은모바일, 시티정보통신, 시티텔레콤(가산점),
                시티텔레콤(도봉구-창동), 시현통신(강북구-미아동), 시흥IM,
                신구대리점, 신길정보통신(시흥), 신나라통신읍내점,
                신동백프라자(용인), 신동아모바일(인천/학익동), 신동텔레콤,
                신명통신(수원), 신부산모바일, 신부텔레콤, 신사모바일(인천),
                신삽교대리점, 신성난곡점, 신성남정보통신_성남,
                신성텔레콤난곡점_관악, 신세계모바일, 신세계모바일(도봉구쌍문동),
                신세계전화, 신세계전화_신길, 신세계텔레콤, 신세계토탈시스템,
                신세계통신, 신세계통신_전주, 신세계통신부천(부성), 신세기통신,
                신세기통신(경기시흥), 신안텔레콤(창원), 신애통신, 신영정보통신,
                신영통신포항(부성), 신우정보, 신우정보통신(거제),
                신원텔레콤(도봉구-쌍문동), 신정보통신, 신탄진수정이동통신,
                신텔레콤, 신홍명통신, 신화(제주), 신화그룹개운점,
                신화그룹행구1호점, 신화정보통신(구로), 신화텔레콤,
                신화텔레콤(도봉구-창동), 신화텔레콤_제주, 신화텔레콤관설점,
                신화통신, 신흥텔레콤, 실로통신, 심SIMKOREA, 심천모바일,
                심컴퍼니, 심코리아, 심플시스템, 싸다구모바일(동작구-상도동),
                싸다요폰, 싸다통신(동대문구-장안동), 싸다폰_노원,
                싸대기중고폰_부산, 싸이렉스, 싸이렉스(대방), 싸이렉스(영등포),
                싸이버통신, 싼타모바일, 싼통신, 쌍둥이텔레콤, 쌍문텔레콤,
                써니텔레콤(인천서구), 써니통신, 썬모바일(공릉썬-노원구-공릉동),
                썬모바일(노원구-당고개), 썬모바일(비젼공릉-노원구-공릉동),
                썬엔텔레콤(도봉구방학동), 썬텔레콤, 썬텔레콤_대림,
                썬통신(성북구장위동), 썬통신(의정부), 썬통신면목점, 썬플러스,
                썬플중계(노원구-중계동), 썸2호점, 썸통신(전주), 쎄븐모바일_인천,
                쎄븐텔레콤본점, 쏘울(SOUL), 쏠텔레콤, 씨네텔레콤,
                씨씨클라쓰안양, 씨씨텔레콤(범일점), 씨앤씨모바일,
                씨앤케이네트웍스, 씨앤피, 씨에스모바일(부평/비젼),
                씨에이치모바일, 씨에이치모바일2, 씨엔씨통신, 씨엔케이네트웍스,
                씨엘모바일(김포), 씨엘비, 씨엠알모바일(수원), 씨케이모바일,
                씨케이모바일_경산, 씨피모바일(인천간석동), 씨피통신, 씽씽텔레콤,
                아담모바일, 아딸아모바일, 아라라트, 아라라트(오류동),
                아란모바일_대림, 아랏나사당(KL), 아로파정보, 아론테크, 아리랑,
                아린텔레콤(수원), 아빠통신, 아시아마트(김포양촌),
                아시아마트(밀양), 아시아마트언양, 아시아모바일,
                아시아모바일(김해), 아시아모바일_대전, 아시아텔레콤(안산),
                아시아폰마트, 아시아폰마트(경산), 아시아폰마트/와룡아시아,
                아시아폰마트_대구, 아시안마트(안산), 아우라모바일, 아이디오,
                아이랜드_서울구로, 아이모바일, 아이박스24, 아이안텔레콤(동인천),
                아이에스컴퍼니, 아이엠씨모바일완주점, 아이엠피(대림),
                아이원통신, 아이유케이, 아이제이텔레콤(중랑구-묵동),
                아이케이소프트, 아이텔레콤, 아이폰나라_광주, 아이폰닥터(수원),
                아이폰브라더스(양주), 아이폰수리센터바나나모바일진접,
                아이폰수리와중고폰_부산, 아이폰수리점, 아이폰을부탁해,
                아이폰조이(계양), 아이프랜드디지털, 아이플러스(가산),
                아이플러스폰(울산), 아이픽스굿(동해), 아인모바일,
                아인통신(성남), 아재통신, 아정통신, 아카푸르코,
                아태교육문화연맹, 아토모바일, 아트통신, 아프리디,
                아프리디트레이딩, 아프리카중고폰(안산중앙),
                아프리카중고폰(안양범계점), 아호모바일, 안나폰마트, 안녕휴대폰,
                안드로통신, 안득휴대폰, 안면종합전자대리점, 안산민스, 안산하늘,
                안중통신(평택/안중), 안테나아이티_전주,
                알(R)통신별내점(남양주시-별내면), 알뜰모바일, 알뜰통신,
                알뜰폰가게, 알뜰폰다이렉트, 알뜰폰마트(부산점), 알라딘,
                알로통신, 알로통신(선학), 알앤알텔레콤(R&RTelecom)(구구2호매장),
                알에스통신, 알케이유통, 알케이유통(안산), 알티통신(RT통신),
                알파통신, 알파통신(부평), 앗싸(DN1), 앙코르모바일, 애니콜통신,
                애니콜통신(홍성)KL, 애니콜프라자, 애니텔레콤, 애니통신&앤언니,
                애드스쿨아이앤씨, 애플(김해), 애플박사(시흥),
                애플정보(북부정류장), 애플통신, 애플통신모바일, 애플플러스,
                액세스모바일&하랄푸드, 액세스모바일숍(남양주), 액정이랑폰이랑,
                앤모바일부전역지하, 앤스마트(천안), 앤텔레콤(PAS),
                앤텔레콤플러스(의정부지하), 앤통신, 앤트피디,
                양가모바일3호점(만수), 양가모바일4호점(부개), 양가스토어,
                양곡이동통신, 양반텔레콤(평택), 양산서창텔레콤, 양산아저씨,
                양산아저씨양산, 양심폰마트(안양시-석수동), 어나더레벨_파주,
                어라운드비_의정부_, 어반모바일(부산), 어서와중고폰(광명), 어썸,
                어울림텔레콤(중랑구-망우동), 억성무역,
                억성무역_3호점(부산금정구), 언니텔레콤, 언양가폰, 업타운(전주),
                에덴(설탕), 에덴통신, 에라이모바일, 에린통신용인(웰모비),
                에밀리무역, 에셀트리, 에스디텍(SD텍)(용산), 에스디텔레콤.알뜰폰,
                에스모바일, 에스비정보통신(부천), 에스비텔레콤, 에스비통신,
                에스씨엘통신(김해), 에스씨컴퍼니(의정부), 에스앤비,
                에스앤비모바일(울산울주군), 에스앤에이치(서울/노원),
                에스에스디(SSD)헤렌텔레콤, 에스에스모바일, 에스에스통신_시흥,
                에스에이치(발안), 에스에이치모바일, 에스에이치상사(SH상사),
                에스에이컴퍼니, 에스엔(SN)(영주), 에스엔모바일,
                에스엔모바일_수원, 에스엠(SM), 에스엠(SM)통신(부산영도구),
                에스엠디컴퍼니, 에스엠모바일(예천), 에스엠씨(금호월드),
                에스엠통신, 에스엠통신(서울대림), 에스엠트리(서창), 에스엠티,
                에스와이정보, 에스와이텔레콤, 에스와이통신, 에스제이,
                에스제이네트웍스, 에스제이모바일, 에스제이씨(파주),
                에스제이텔레콤, 에스케이광릉, 에스케이엠비(리더스),
                에스케이엠비(화성), 에스케이와이텔레콤, 에스텔레콤(송파),
                에스통신, 에스티제이, 에스티통신,
                에스피모바일(핸드폰아울렛본오5호점), 에스피에프무역/울산셀폰,
                에스피커뮤니티, 에어모바일, 에울, 에원준텔레콤, 에이비,
                에이스네트웍(청주), 에이스네트웍스(부산), 에이스마트(김해),
                에이스모바일김해점, 에이스정보, 에이스텔레콤,
                에이스텔레콤(목포), 에이스통신, 에이스통신_대구,
                에이스프리미엄모바일(안양시-안양동), 에이아이시스템,
                에이앤에이네트웍스(연천), 에이에스(A/S)통신,
                에이엠모바일(AMMOBILE), 에이와이모바일, 에이와이모바일(간석),
                에이치(H)무역, 에이치디커머스, 에이치모바일,
                에이치비컴퍼니(강북구-번동), 에이치씨텍,
                에이치에스모바일(애니-노원구-중계동), 에이치엘마켓_고양,
                에이치엘비내츄럴_안산, 에이치엠(NnD)communication(음성),
                에이치엠오, 에이치와이, 에이치와이이노베이션,
                에이치케이(HK)모바일, 에이치케이티샵, 에이케이모바일,
                에이케이오토(발안), 에이티원텔레콤, 에이플러스,
                에이플러스(2호점), 에이플러스2호점(보령), 에이플러스원(안산),
                에이피, 에치엘그룹, 에코모바일대구점, 에코페이,
                에코폰(영등포점), 에코폰_거제, 에코폰동성로점,
                에코폰신세계동대구점, 에코폰일산화정점, 에프원(고척),
                에프티브로, 엑스포통신, 엔네트, 엔모바일, 엔분의일(1/N),
                엔비에스인터내셔널, 엔비텔레콤, 엔스피드, 엔씨정보통신(화성),
                엔씨정보통신NC, 엔씨통신마북점, 엔알네트웍스,
                엔에스텔레콤(NS텔레콤), 엔에스통신, 엔제이통신,
                엔케이_e편한통신, 엔케이모바일, 엔케이모바일_대구,
                엔피엔피(천호), 엘군텔레콤(노원구상계동), 엘디에스(LDS)(부산),
                엘리텔레콤(예산), 엘림, 엘림정보통신_인덕원, 엘샤)구평점,
                엘샤)구포점, 엘샤)남산점, 엘샤)망미행？점, 엘샤)몰운대점,
                엘샤)아호모바일, 엘샤다이다대점, 엘샤다이두실점, 엘샤다이본점,
                엘샤다이신만덕점, 엘샤다이주례점, 엘에스티, 엘엠글로벌,
                엘제이모바일, 엘제이모바일(평택), 엘제이모바일_경주,
                엘제이모바일_부산, 엘지디지탈통신, 엘지휴대폰매장_구로, 엘케이,
                엘티마트(칠곡), 엘티이통신, 엠(M)통신, 엠디통신,
                엠바이무역(동대문지하), 엠비에스포스원, 엠스타쉬,
                엠씨씨네트월드, 엠앤에이모바일, 엠에스(수내), 엠에스모바일,
                엠에스케이_인천, 엠에스티(평창), 엠에스플러스, 엠인엠,
                엠제이(하남시-풍산동), 엠제이스토어, 엠제이타포린, 엠존, 엠케이,
                엠케이(MK)텔레콤(마전), 엠케이_성남지하, 엠케이텔레콤,
                엠통신(안산), 엠트랜스퍼, 엣지(중랑구-중화동), 여사,
                역말이동통신, 연서텔레콤(노원구상계동), 연세폰카페(서울구로),
                연우통신(시흥), 연우통신정왕(안산), 연일텔레콤, 연지IMT(부산),
                연통신, 연합통신(대구), 연합통신(이천), 연화정보통신,
                열정컴퍼니, 열정통신(폰방앗간), 영광이동통신, 영광정보통신,
                영동이수통신, 영미전자, 영보통신_성주, 영보통신부평점,
                영사운드(용산), 영산미래정보통신, 영석정보통신, 영신텔레콤,
                영심텔레콤(관악/신림), 영암우리, 영원OA통신, 영원이동통신,
                영원텔레콤, 영자텔레콤, 영진정보, 영텔레콤, 영텔레콤(부천/고강),
                영텔레콤(전주), 영토통신, 영통신, 영희와통신, 옆커폰사천점,
                옆커폰안동점(안동), 옆커폰화곡, 예린통신, 예린통신(안산),
                예림통신_대구, 예쁜중고폰(강릉), 예쁜폰아트, 예산준닷컴,
                예스모바일, 예스컴퍼니, 예스컴퍼니(김해), 예안텔레콤(성남),
                예은텔레콤(중랑구-면목동), 예인모바일, 예일정보통신(광주),
                예지정보통신(평택/안중), 예진텔레콤, 옐로우폰마켓, 오거리텔레콤,
                오대양통신, 오동통신, 오드니엘, 오디텔레콤, 오라컴퍼니_안산,
                오랜知, 오랜지(화천), 오렌지(도봉구신창동),
                오렌지이동통신동암(KL), 오렌지컴퓨터, 오르나,
                오르나핸드폰(몽골타운), 오마주, 오메가정보통신,
                오빠네휴대폰가게, 오빠통신, 오산버스폰궐동점, 오산중고폰,
                오션텔레콤(서울강남), 오알지, 오에스텔레콤, 오정에이스,
                오정통신, 오조호조, 오케이모바일, 오케이모바일(안산역),
                오케이에스케이맨2, 오케이정보, 오케이중고폰원당점,
                오케이텔레콤(안양지하상가), 오케이통신,
                오케이통신(남양주시-화도읍), 오투모바일, 오픈모바일(광주),
                오학통신, 오호통신(광주), 오히무역(화성발안), 옥구통신_부산,
                옥스네트워크_군포, 옥자정보통신, 온누리텔레콤, 온누리통신,
                온누리통신_대구, 온세계통신, 온세계통신_대전,
                온온모바일노고산동(폰방앗간), 온천텔레콤,
                올드앤뉴텔레콤(노원구공릉동), 올림픽텔레콤(고양일산),
                올모바일(인천), 올빛통신(성남미래정보), 올탑, 와돔블루오션,
                와요샵, 와이드통신(안성), 와이디텔레콤_서울금천, 와이모바일,
                와이모바일(금산), 와이알컴퍼니, 와이에스티에스, 와이엔엠모바일,
                와이엠통신, 와이쥐모바일(YGmobile논공점),
                와이쥐모바일(YGmobile창녕점), 와이즈, 와이즈(WISE), 와이즈욜로,
                와이컴퍼니_거제, 완도통신, 완주한백, 왕대박(수원), 왕대박(파주),
                왕텔레콤(양주시-덕계동), 외국인마트(이천), 요셉텔레콤,
                요촌김제통신, 요촌해피, 용봉환전소(공항점), 용산공일공텔레콤,
                용산텔레콤20호점, 용텔레콤, 용호플러스, 우광통신_광명,
                우기통신(안양), 우리3통신, 우리동네, 우리동네(구리시-수택동),
                우리동네도매폰(은평구A), 우리동네전화국, 우리동네텔레콤,
                우리동네폰가게(인천/용현), 우리동네폰마트남양주(부성),
                우리동네휴대폰_태안, 우리동네휴대폰만수,
                우리동네휴대폰만수점(만수), 우리모바일, 우리모바일(병방),
                우리모바일(수원), 우리모바일_의정부, 우리모바일병밤점,
                우리정보(남원), 우리정보통신, 우리텔레콤,
                우리텔레콤파주(폰방앗간), 우리통신, 우리통신(충주),
                우리폰(시흥), 우리형스마트폰(미아), 우림텔레콤(대곶), 우림통신,
                우성컴퍼니, 우송이동통신, 우수통신(수원/지동), 우신텔레콤,
                우아미컴퍼니, 우야통신, 우윈스, 우유통신, 우주이동통신,
                우주정보통신(강서), 우주텔레콤, 우주통신, 우주통신(대전), 우진,
                우진스마트통신, 우진정보통신, 우진통신_창원, 우텔레콤,
                운산칼라.휴대폰세상, 운성상사, 운정곰집, 운정아저씨,
                울산메가폰(강남초점), 울산월드마트SHALIMAR, 웃딩통신마트_부산,
                웅모바일(인천계양구), 웅비통신, 웅비통신(대구), 웅이텔레콤,
                웅이통신, 웅쭈노리퍼블릭, 워터웨이브, 원(One)텔레콤,
                원네트웍(용산), 원다리텔레콤(안양지하), 원모바일, 원스에임,
                원음정보통신가리봉(부성), 원정보통신, 원카무역, 원큐(동대문),
                원큐통신, 원탑(금곡), 원탑모바일, 원탑프로_안양, 원텔레콤,
                원텔레콤(노원구수락산), 원텔레콤(부전점), 원텔정보통신, 원통신,
                원통신_영천신녕, 원플러스원(평택), 월드, 월드MOBILEMART,
                월드넷(대전), 월드마켓(경주), 월드모바일, 월드모바일(김포),
                월드모바일(대연), 월드모바일_울산, 월드와이드모바일네트웍스,
                월드이동통신, 월드전자랜드(강남), 월드정보통신, 월드텔레콤,
                월드텔레콤(성북구장위동), 월드텔레콤(아산신창), 월드텔레콤_안산,
                월드통신, 월드통신(둔포), 월드통신(청량리), 월드통신(포항),
                월드폰, 월드폰3, 월드폰3_성주, 월드폰마트(고잔동), 웨스,
                웨일모바일, 웰빙통신, 웰컴_대전, 웰컴리치컴퍼니(웰컴텔레콤수원),
                웰컴리치컴퍼니_수원역지하상가, 위너스(대전), 위너텔레콤,
                위너텔레콤(대전), 위너텔레콤(수원지하상가), 위너통신, 위너피플,
                위더스, 위원모바일, 위즈모바일, 위즈엠통신, 위코리아, 윈스토어,
                윈정보통신, 윈텔(부평), 유가통신, 유광통신, 유나통신,
                유니온통신(웰모비폰방앗간), 유니콘통신, 유니크, 유락통신(대전),
                유리정보_영광_, 유림정보통신(수원), 유명전자, 유모바일,
                유민이네텔레콤, 유성정보통신(창원, 유성텔레콤(중랑구망우동),
                유성텔레콤_대구, 유성텔레콤_서울도봉, 유성통신,
                유시티부동산공인중개사무소, 유시티부동산공인중개사사무소,
                유안텔레콤, 유안통신, 유앤유컴퍼니_양산, 유어컴(용산),
                유에스모바일, 유엔소프트, 유이통신(부천), 유일모바일(청라),
                유일이동통신, 유정텔레콤(인천), 유주모바일(김포),
                유즈글로벌(부평), 유진정보통신, 유진텔레콤, 유진텔레콤(안산),
                유진통신, 유진통신(안산), 유케이씨컴퍼니, 유토피아정보통신,
                유토피아정보통신(시흥-구)도솔천청천), 유투폰(해운대),
                유투폰_부산대, 유투폰서면점, 유플러스스토어,
                유플러스스토어(화성), 유피텔레콤, 유한회사플러스통신(목포),
                유행통신(인천서구), 유현통신(송탄), 윤이나텔레콤, 윤텔레콤,
                율리정보통신, 율리정보통신(파주), 율모바일, 율텔레콤,
                으뜸정보통신(광주), 으뜸정보통신(인천), 으뜸통신, 은성정보통신,
                은성통신, 은성통신(김해), 은아정보통신, 은진텔레콤(답십리),
                은텔레콤, 은하텔레콤, 은하텔레콤(인천/문학),
                은하통신(의왕시-오전동), 은현텔레콤, 은혜정보통신, 음봉텔레콤,
                음파통신(휴대폰싸게파는집), 응답하라,
                의정부레오(하이시스템-노원구-상계동), 의형제, 이군모바일,
                이글스AURORA, 이김텔레콤, 이나인샵(김해), 이노모바일,
                이노텔레콤(평택), 이니몰, 이니코, 이다글로벌, 이동정보본오점,
                이동통신프라자, 이동통신할인마트, 이레통상, 이레통상_안산역,
                이레행복통신, 이루다, 이루다(오산점), 이루다모바일, 이루리3호,
                이룸(은평), 이룸(청주), 이룸N(김해), 이룸정보통신_신대방,
                이비모바일, 이쁜핸드폰가게_진천, 이삭명지,
                이삭텔레콤(신도림테크노마트), 이수네트웍스,
                이슬텔레콤부평점(부평), 이슬텔레콤작전동(부성), 이슬통신_대구,
                이시스헬프, 이안통신, 이앤박, 이약국텔레콤(용인), 이에스,
                이에스모바일2호점, 이에스엠(부평다인), 이에스텔레콤7_부천,
                이엔통신, 이엘코리아(군포), 이오모바일, 이오통신,
                이원모바일(간석), 이음모바일, 이음새(부산), 이조통신,
                이즈람엔터프라이즈(답동), 이지통신, 이천스마일통신,
                이천시통신_이천, 이천우리모바일, 이천원탑, 이천폰폰폰, 이천허브,
                이코노미텔레콤(구월), 이테하드인터네셔날, 이투통신,
                이펑휴대폰가게, 이펑휴대폰가게(평택), 이편한텔레콤,
                이혜리핸드폰, 이화정보통신, 이환텔레콤, 인기텔레콤, 인사랑,
                인사랑시흥점, 인아정보통신, 인재통신, 인천비승십정,
                인천작은마을, 인천중고폰매입판매폰사요몰구월점, 인피니티텔레콤,
                인하텔레콤, 일등모바일, 일등모바일_천안, 일등컴퍼니(파주),
                일루텔레콤, 일모바일_안양, 일번텔레콤, 일석, 일우, 일우(신림),
                일잘하는분, 일촌통신, 일통(대전지하상가), 일풍국제,
                일프로_오름모바일, 일프로모바일_부산, 임란모바일, 임실이동통신,
                자몰마켓(JAMOLMARKET, 자몰마켓(김해), 자유모바일(수원),
                자이폰마트, 자평텔레콤, 잠수부닷텀, 잠실텔레콤, 잡스폰마트,
                장성통신, 장승정보, 장안월드, 장유텔레콤_김해장유,
                장은영_양산덕계, 장흥통신, 재인통신, 재재모바일(광주),
                재홍텔레콤, 전국텔레콤, 전국텔레콤_김해, 전기통신LED,
                전주한백동산본점, 전주한성, 점보텔레콤, 정명모바일(경주),
                정모바일, 정민모바일, 정보통신마을(관악구),
                정보통신피앤에스(대림), 정연텔레콤, 정우통신주안지하(KL),
                정원이동통신, 정원텔레콤, 정인네트웍스_광주금호월드,
                정있는모바일_서산, 정있는모바일2호점_서산,
                정직한스토리(강북구-미아동), 정직한통신, 정직한폰가게,
                정직한휴대폰, 정텔레콤, 정통신, 정품통신, 정해통신,
                정후디지털(수원), 제니스, 제니스모바일(대구), 제로모바일,
                제로모바일_춘천, 제로통신, 제은통신, 제이(J)라스트모바일,
                제이공릉(노원구-공릉동), 제이네트웍스, 제이대리점, 제이더블유,
                제이드, 제이디모바일, 제이디모바일(김해),
                제이디원모바일대전(KL), 제이모바일, 제이모바일(양주시-옥정동),
                제이비모바일, 제이소프트(파주), 제이씨(영통점),
                제이아띠(간석동), 제이알컴퍼니, 제이앤제이지지(GG),
                제이에스(JS)통신백화점, 제이에스모바일,
                제이에스모바일(JS모바일서대문), 제이에스코퍼레이션, 제이에이치,
                제이에이치(JH)컴퍼니, 제이엘정보통신, 제이엠(JOYMOBILE),
                제이엠_부산, 제이엠넷, 제이엠모바일, 제이엠모바일(부산/기장),
                제이엠모바일(신도림), 제이엠컴퍼니(통영),
                제이와이텔레콤(도봉구창동), 제이와이통신,
                제이와이푸드(광주시-치평동), 제이윈통신, 제이제이,
                제이제이글로벌홀딩스, 제이제이네트웍스, 제이제이모바일(삼산),
                제이제이스모바일, 제이제이컴퍼니, 제이제이텔레콤, 제이컴퍼니,
                제이케이모바일, 제이케이버스_인천부평, 제이케이컴퍼니,
                제이케이통신, 제이텔레콤, 제이텔레콤(대전),
                제이텔레콤(수원/영통), 제이텔레콤(중랑구신내동),
                제이텔레콤남문로데오점(수원/팔달), 제이텔레콤대림(국제통신),
                제이텔레콤안산(부성), 제이텔정보(JTEL), 제이통신,
                제이통신(형곡), 제이투케이, 제이티(경기남양주), 제이티모바일,
                제이티엠컴퍼니, 제이플러스, 제이플러스(망월사), 제이피모바일,
                제이피모바일(용인), 제일정보통신, 제일텔레콤, 제일통신,
                제일통신(김제), 제트컴퍼니십정(부성), 제트텔레콤심곡동(서동관),
                제트통신(서울강동), 젠틀맨통신, 조아모바일, 조아텔레콤,
                조아텔레콤_김포, 조양문, 조은전자통신, 조은정보통신, 조은통신,
                조은통신_대전(지하상가), 조이너스, 조이모바일, 조이모바일_용인,
                조이텔레콤, 종로프라자, 종암이동통신, 종합경영(폰방앗간),
                좋은생각수원지점(수원), 좋은생각텔레콤특판본점, 좋은예감,
                좋은정보, 주)한나정보건설, 주니통신, 주니통신(군산), 주덕텔레콤,
                주명정보통신_구로, 주모바일, 주민센터구포시장점, 주민텔레콤,
                주씨모바일, 주얼리텔레콤, 주연모바일(만수), 주연정보(논현),
                주연정보(인천/만수), 주연정보통신, 주연통신, 주왕모바일,
                주울모바일, 주원이동통신(전주), 주은텔레콤, 주은텔레콤(부산),
                주인텔레콤, 주주컴퍼니, 주피터통신(종로), 주현모바일,
                주호텔레콤(인천남구), 준대성천모바일(인천), 준모바일(성남),
                준모바일(전주), 준모바일(중랑구-망우동), 준선코리아,
                준앤준(구의), 준이동통신, 준정보통신, 준케이통신, 준코리아,
                준텔레컴퍼니(원주), 준텔레콤, 준텔레콤(노원구-상계동),
                준텔레콤4호점(진주), 준통신, 준통신(대전), 준통신(도봉구-창동),
                준통신(수성구), 중계월드(노원구중계동),
                중계텔레콤(노원구중계동), 중계텔레콤(중계), 중고폰1번가본점,
                중고폰1번가삼례점, 중고폰거래,
                중고폰구입판매수리케이스필름부평점(인천/부평), 중고폰나라,
                중고폰나라부천(부성), 중고폰마켓JJ, 중고폰몬스터,
                중고폰뱅크_오산점(경기오산시), 중고폰뱅크수원점,
                중고폰사고파는집(울산), 중고폰사고팔고(진주), 중고폰알뜰샵,
                중고폰에코폰연신내점, 중고폰판매센터슈가폰(서울), 중국식품,
                중국식품마트, 중부비즈네트웍스(청주), 중앙이동통신(거제),
                중앙텔레콤, 중앙통신, 중원텔레콤, 중파텔레콤, 쥐에프모바일,
                쥐에프모바일(부평), 쥬네브프라자(동백), 즐겨찾기, 즐펀텔레콤,
                즐펀텔레콤(노원구-공릉동), 지노네트웍스, 지니모바일,
                지니텔레콤(용인), 지디엠_부산, 지림, 지모바일, 지비모바일,
                지성정보통신, 지성통신, 지아모바일, 지안모바일_울산, 지알텔레콤,
                지앤비, 지에스엠(중구/을지로), 지에이티글로벌,
                지에프모바일(인천/관교), 지엠엔씨(창동), 지오모바일, 지오텔레콤,
                지오텔레콤(동두천시-지행동), 지우모바일, 지은통신,
                지은통신(안산), 지음모바일_수원, 지인텔레콤, 지인통신(신림),
                지중해텔레콤, 지지티네트윅스, 지케에스, 지티,
                지혜정보2(성동구-행당동), 지호텔레콤, 지환모바일(인천미추홀구),
                지효텔레콤(일산), 지훈텔레콤(서울강남), 진명전자(수원),
                진모바일, 진모바일(중랑구-중화동), 진성이동통신, 진성정보(석남),
                진성통신, 진솔텔레콤, 진실텔레콤, 진양텔레콤(발안),
                진영정보(가좌), 진우텔레콤, 진우통신,
                진접루아모바일(남양주시-진접읍), 진정보통신, 진주1등텔레콤,
                진주아이폰수리중고폰, 진천화랑휴대폰, 진텔레콤, 진통신,
                진폰명폰, 짱구네잡화점(강변테크노), 짱구텔레콤, 짱구통신(대전),
                짱구통신(중구-장충동2가), 짱정보통신(폰방앗간), 쭌텔레콤, 차차,
                착한가게, 착한가게_송파, 착한가게연산, 착한총각네폰가게(안양),
                착한통신(관악구A), 착한폰가게, 착한폰마트(함안), 착한핸드폰가게,
                착한휴대폰, 착한휴대폰(달천로), 착한휴대폰샵, 찬비텔레콤(부천),
                참좋은유통, 참텔레콤, 참통신1호점, 창고형핸드폰수원아주대점,
                창녕아시아마트, 창대통신, 창신텔레콤, 창조랜드, 창진정보통신,
                창천항로옥동점, 찾폰샵, 채움통신(시흥), 채하모바일,
                챌린지모바일(강북구수유동), 천둥모바일(양주시-삼숭동),
                천사텔레콤(부산), 천안구공휴대폰, 천안모바일, 천지종합이동통신,
                천지통신, 천하텔레콤, 천하통신, 첨단이동통신(광주),
                청구TOP(서대문구-홍은동), 청군통신하성점, 청년모바일,
                청년텔레콤, 청심네트웍, 청아텔레콤, 청음정보(김포), 청주폰,
                청춘개금, 청춘대길응암지점, 청춘모바일, 청춘모바일(일산),
                청춘모바일_서울양천, 청춘통신, 청포도텔레콤, 청하정보통신_광명,
                체이스모바일, 체인지유심, 체인지텔레콤_송탄, 체화모바일,
                첼시통신, 초록정보통신, 초심(논산), 초이모바일, 초지모바일,
                초코모바일, 촌놈텔레콤, 총각네모바일(천안), 총각네텔레콤,
                총각네텔레콤(평택), 총각네통신(목포), 총각네폰, 총각네폰가게,
                총각네휴대폰가게(백마점), 총각텔레콤_양산, 최고도화점,
                최군통신(구리), 최신정보통신, 최우수텔레콤, 쵸파통신,
                춘향이휴대폰, 충남전자, 충북공일공, 충주휴대폰성지_충주,
                충청종합유통, 츄츄다모바일, 치즈모바일,
                친절텔레콤(의정부시-가능동), 친절한텔레콤본점,
                친절한텔레콤악세사리, 칠곡아이폰수리, 칠삼통신, 칠삼통신_대구,
                칠팔유통, 카이(KAI)모바일, 카이텔레콤, 카인드, 칸무역, 칼라통신,
                캄보디아모바일_김해, 캄보디아종합서비스,
                캄보디아종합서비스(안산역), 캄보디아통신, 캄코텍, 캡틴모바일,
                컴사요, 컴앤폰, 컴앤폰(인천연수), 컴앤휴부천, 컴포니통신,
                케빈모바일, 케이(K)모바일, 케이디모바일(부산), 케이모바일,
                케이모바일(광진구), 케이모바일(내당동), 케이모바일(부천),
                케이모바일_대전, 케이모바일_대전지하상가, 케이브이엔,
                케이비(중랑구-망우동), 케이비모바일(KBMobile),
                케이비모바일(군자), 케이비에이코리아, 케이스타(옥포점),
                케이스타(용산점), 케이씨통신_구로, 케이알(KR)모바일2호점,
                케이알(KR)모바일4호점, 케이알모바일3호점, 케이알모바일5호점,
                케이알모바일6호점, 케이알모바일7호점, 케이앤리치(강북구-우이동),
                케이에스(K.S)텔레콤(석남), 케이에스모바일, 케이에스모바일_수원,
                케이에스모바일_오산, 케이에스스토어, 케이에스텔레콤,
                케이에이치(KH)통신, 케이에이치(부평), 케이엔씨(K&C)(오산),
                케이엠모바일, 케이엠모바일(성남분당), 케이엠컴퍼니,
                케이엠텔레콤, 케이와이모바일(양산), 케이원텔, 케이원텔(서울),
                케이정보, 케이정보통신, 케이제이, 케이제이텔레콤(강남구-역삼동),
                케이제이통신, 케이지컴퍼니, 케이지컴퍼니(수원),
                케이지컴퍼니수원(KL), 케이케이엠유한회사, 케이텔레콤,
                케이투(K2)통신, 케이투모바일성남(부성), 코딱지텔레콤(부천),
                코레아모바일숍, 코레아모바일숲(수원),
                코리아글로벌네트워크(부천), 코리아모바일, 코리아셀폰,
                코리아코어링크(서울), 코리아텔레콤, 코리아텔레콤(군포),
                코리아통신, 코리아통신(논공), 코스모바일울산(폰방앗간),
                코코로모바일(창원), 코털텔레콤(동두천시-생연동), 콜(call)텔레콤,
                쾌남정보통신(부개점), 쿠모바일(도봉구-쌍문동), 쿠팡모바일,
                쿨정보통신, 퀸모바일, 큐큐아시아, 큐큐트레이드(구로), 크레센도,
                크레이지모바일(인천), 크로바이동통신(울산),
                크리에이티브네트웍스, 큰길정보통신(도봉구-창동),
                큰길통신(노원구-중계동), 큰별통신_대전, 큰사랑, 큰소리통신,
                큰소리통신구암점, 큰통신, 클래식모바일, 클래식모바일(평택),
                클릭모바일(부천), 클릭이동통신, 키다리삼촌폰, 키스,
                키움모바일(석남동), 키움텔레콤, 키움통신, 킴스모바일(부천),
                킴스키친, 킹모바일(용산), 킹스모바일, 킹텔레콤(수원),
                킹텔레콤(수원역지하상가), 킹텔레콤서산, 킹통신(제주), 타이거,
                타이마트(인천), 타이푸드(가좌동), 타임모바일, 타임전주,
                타임텔레콤, 탄탄대로폰마켓1, 탄현폰, 탄후엔, 탑스텔레콤,
                탑정보통신(진주), 탑컴퍼니, 탑통신(논산), 탑통신_대구,
                탕정모바일(아산), 태경텔레콤, 태극이동통신, 태마, 태산정보,
                태산통신, 태상모바일, 태승(김해), 태승모바일_본점,
                태양모바일_대구, 태양정보통신, 태양통신, 태양홀딩스(수원/장안),
                태연모바일(인천/심곡동), 태영정보통신, 태왕통신, 태우통신,
                태원텔레콤, 태원텔레콤_인천부평, 태준모바일,
                태평올빛(성남미래정보), 태현정보통신, 태형정보, 태화텔레콤,
                태흥모바일, 태희통신_안산_, 터미널폰(인천/논현), 테라월드(논산),
                테르게르, 테마모바일, 테마신흥점(인천/도원동),
                테마이동통신(인천/가좌동), 테마정보통신(인천/논현동), 테마학익,
                테크노정보통신, 텍사스통신부산, 텔레붐연수동(부성),
                텔레토크_안산, 텔리타운용산전자랜드(부성), 텔코, 토르폰,
                토리통신, 톡톡휴대폰스토어, 통신나라합정(부성), 통신본가,
                통신일번지, 통신쟁이, 통일텔레콤, 투게더통신, 투노텔레콤, 투민,
                투박(경기남양주시), 투엘, 투투, 투헝통신, 투헝통신(시흥),
                튀블리, 튀블리_수원, 트라이모바일, 트리오케이(양산), 트윈통신,
                특가폰(수원), 티디씨환전(동두천), 티비모바일, 티스타,
                티아이모바일, 티에센터_군포, 티에스(TS)통신백화점(전주),
                티에이치텔레콤(평택), 티엔에스텔레콤(영등포),
                티엘에스텔레콤(제천), 티유모바일(김포/장기동), 티정보,
                티제이모바일, 티제이모바일(용인), 티제이텔레콤,
                티케이씨모바일(TKCMOBILE), 티케이엘파트너스(인천), 티통신,
                티통신_창원, 파란(PARAN)(인천), 파란MOBILE, 파란정보,
                파란텔레콤(춘천), 파랑텔레콤(부천), 파워스테이션(삼척), 파워콜,
                파워해미, 파주제이비모바일, 판다텔레콤, 판다통신,
                판다통신고려대점, 판매왕, 판매왕(하남시-신장동), 팔평통신(효목),
                팡팡텔레콤, 패밀리모바일, 패밀리모바일(가정점),
                패밀리모바일(성남), 패밀리모바일_가정점,
                패밀리모바일_연희점/간석점, 패밀리서창, 패밀리텔레콤,
                퍼스트네트웍스(인천부개), 퍼스트모바일, 퍼스트모바일(파주),
                퍼스트용호점2, 퍼스트텔레콤2호점, 퍼스트텔레콤양산, 퍼스트통신,
                펀패밀리, 페리도트&휴대폰면세점(안성), 펠릭스(포천),
                펭수텔레콤_통영, 평택모바일, 평택성지폰, 평택아저씨,
                평택아저씨(평택), 평화모바일, 평화커뮤니케이션, 평화통신,
                포시즌통신, 포유(전주), 포유모바일(용인), 포인트샵,
                포인트정보통신, 폰119, 폰153, 폰82온수점(서울구로), 폰Seoko,
                폰shop모바일, 폰가게&팔로우렌탈(대전), 폰가게(부천), 폰값껌값,
                폰고래(부산), 폰공짜세상, 폰나라, 폰나라(배방점),
                폰나라(수원역지하상가), 폰나라이동통신(의왕시-오전동), 폰다방,
                폰닥(대구), 폰닥터, 폰닥터(대전), 폰당포, 폰라인, 폰마켓,
                폰마켓(주안), 폰마켓_파주, 폰마트, 폰마트(울산), 폰마트(하남),
                폰마트넘버원, 폰마트도화점(부성), 폰마트통신, 폰맛집,
                폰맥스양산, 폰모아, 폰무원, 폰바꿔(폰방앗간엄지환), 폰박텔레콤,
                폰방앗간, 폰뱅크, 폰뱅크_송탄, 폰사119스마트, 폰사닷컴본점,
                폰사닷컴진주점, 폰사랑, 폰사랑(김포통진), 폰사랑(태백),
                폰사랑2(태백), 폰사랑김도령(평택), 폰사랑평릉점, 폰사요몰(광주),
                폰사요몰(대전), 폰사요몰(수원인계), 폰사요몰(수원점),
                폰사요몰(울산), 폰사요몰_부산서면점, 폰사요몰_창원, 폰사자,
                폰상점(안산), 폰생폰사, 폰샵, 폰샵성남지하(폰방앗간),
                폰샵주안지하(부성), 폰세사리1, 폰세일스토어_고양, 폰스타,
                폰스타(안양), 폰스타2호점, 폰스타본점, 폰스타평촌점, 폰스토리,
                폰스토리(여주), 폰스토어, 폰시티(테크노마트),
                폰아울렛(강북구-우이동), 폰앤서비스은평대조동점(폰방앗간),
                폰앤수리(은평)KL, 폰앤커피, 폰앤톡(고양), 폰앤폰, 폰에스(천호),
                폰엔케이스, 폰요폰요_서울송파, 폰월드(도봉구신창동),
                폰이로다(평택), 폰지유(안성), 폰천국수유동(폰방앗간심현재),
                폰친구, 폰친구(노원), 폰케어샵_고양일산, 폰케이스_경산, 폰토리,
                폰통령(충주), 폰튜브, 폰파는총각, 폰판다, 폰팜, 폰폰폰,
                폰프렌즈, 폰프리카, 폰프린스1호점(성북구-길음동), 폰플러스,
                폰플러스(김제), 폰플러스(해남), 폰하나, 폰하우스(진주),
                폰하우스통신, 폰할인마트, 폰할인마트2(병점), 폴스타, 폼텔레콤,
                푸른정보, 푸른정보통신, 품크메르모바일, 풍납텔레콤_강변테크노,
                풍무통신, 프라임(부평), 프라임모바일, 프라임통신,
                프라임통신(대구), 프렌즈, 프렌즈중고폰, 프렌즈통신,
                프로통신(만수), 프로통신군자(광진구-군자동),
                프로통신전농(동대문구-전농동), 프롬레브(fro.Reve), 프리마텔레콤,
                프리통신, 프리패어드, 프리페이폰, 프리페이폰_동대문,
                프린스텔레콤(가좌), 플러스모바일, 플러스모바일(양천),
                플러스텔레콤, 플러스텔레콤(일산), 플러스통신(목포),
                플레이모바일, 플렉스모바일, 피씨가이, 피앤피모바일_일산,
                피앤피몽골타운/바양CG, 피에스모바일(부평), 피에스지,
                피엔씨정보통신, 피엘모바일은평(폰방앗간), 피엠씨_수원역지하상가,
                피오엔정보통신, 피치모바일, 피카소, 피케이2(시흥),
                피크통신-도마동대전, 피텔레콤(광주), 피피통신(경기남양주시),
                픽스굿애플에서정품부품제공받는독립수리제공업체, 픽스모바일,
                필이동통신, 필정보통신(미래정보), 핏불텔레콤(중랑구중화동),
                하나글로벌, 하나글로벌_인천부평, 하나네트웍스태화점,
                하나로네이트, 하나로무선통신남양주(미래), 하나로정보통신(구리),
                하나로텔레콤, 하나로통신, 하나이동통신, 하나이동통신(대곶),
                하나정보, 하나텔레콤, 하나통신(여수), 하나통신(해남),
                하나폰_강동, 하냥모바일_한양대, 하누리통신, 하늘광운,
                하늘모바일(김포통진), 하늘정보(서울관악), 하늘정보(통진),
                하늘정보통신, 하늘텔레콤(미래정보), 하늘통신, 하늘통신_대전,
                하동본가(여주), 하랑에이스모바일, 하랑플러스,
                하루모바일(강북구-미아동), 하모니, 하빈통신,
                하얀세상(강변테크노), 하양미래플러스, 하원모바일,
                하은모바일(평택), 하음통신, 하이모바일, 하이엔드스토어,
                하이정보통신, 하이컴씨에이치, 하이텔, 하이텔레콤,
                하이텔레콤(남양주), 하이텔레콤미아, 하이통신, 하이투,
                하이휴대폰이야, 하통통신, 하트코리아, 하트코리아(서울성동구),
                하트텔레콤_서울암사, 하하모바일, 하하모바일(인천), 하하호호,
                한mobile, 한가족통신2호점문막, 한강통신(수원), 한결모바일(부산),
                한결컴퍼니, 한결텔레콤, 한국스마트폰거래소, 한길텔레콤,
                한길통신, 한누리통신, 한라통신(제주), 한림국제_용인,
                한마음아이앤씨, 한마음정보통신(구로),
                한마음정보통신검암동(부성), 한마음컴퍼니(김해), 한미통신,
                한배모바일, 한백이동통신, 한백이동통신보령점, 한별통신,
                한별통신(광주), 한빛(일산), 한빛모바일_광명, 한빛정보통신,
                한빛통신, 한사랑텔레콤, 한솔, 한양모바일(구로), 한얼정보통신,
                한영모바일, 한영텔레콤_신용산, 한우리통신, 한우물통신, 한울,
                한울(부천), 한울텔레콤(용원점), 한울텔레콤다대점, 한음정보통신,
                한이정보통신(부산A), 한종합2호점, 한종합8호점,
                한진텔레콤(강변테크노), 한창(신도림테크노마트), 한창텔레콤,
                한컴정보, 한호텔레콤, 함덕SK통신, 함양공짜나라, 합덕통신,
                합덕휴대폰프라자, 핫라인마포(폰방앗간), 항성텔레콤(가리봉),
                해남미래정보통신(해남), 해남예스, 해랑모바일대전,
                해리포터모바일, 해리포터모바일(인천), 해맞이통신, 해미핸드폰,
                해미핸드폰_서산, 해밀정보통신, 해성, 해주통신성남,
                해태통신_대전, 해피넷, 해피월드Ⅱ, 해피콜통신, 해피텔레콤,
                해피텔레콤(노원구-상계동), 해피통신_신대방, 해피폰카페(김해),
                핸드폰나라, 핸드폰바꾸는날(시온정보통신)(거제), 핸드폰성지,
                핸드폰성지와이에스모바일(간석), 핸드폰스토어,
                핸드폰싸다구3호점(행신동), 핸드폰아울렛동아모바일8호점(작전),
                핸드폰전문매장, 핸드폰할인마트, 핸프모바일(중구-신당동),
                핸프모바일중랑점(중랑구-상봉동), 행복드림(계산),
                행복모바일_부천, 행복정보통신, 행복텔레콤, 행복텔레콤_인천,
                행복통신, 행복통신(금천구시흥동), 행복통신(상도),
                행복통신(정읍), 행복통신(중랑구면목동), 행복통신_서울암사점,
                행복통신2(성남지하), 행복통신관양점(안양-관양동),
                행복통신신포지하(부성), 행복통신포일점(의왕시-포일동),
                행복폰동인천, 행복한마을(강동구상일동), 행복한텔레콤,
                행운(화곡동), 행운모바일(화성), 행운텔레콤, 행운통신,
                향남버스폰_화성, 허브정보통신(시흥), 허브텔레콤, 헌주통신(안산),
                헤라2호점, 헬로모바일신월점, 헬로벨계양지사, 헬로우GSM,
                헬로중고폰, 헬로폰, 혁신텔레콤(대구동구), 혁신토탈샵,
                혁신토탈샵(진천), 혁이네통신(대전), 현대IMT, 현대모바일,
                현대모바일(부산대), 현대정보(반고개),
                현대정보통신(안양시-호계동), 현대통신(고성), 현대통신성남,
                현동모바일, 현미모바일, 현빈텔레콤, 현성, 현우정보통신(시흥),
                현이네폰가게, 현이통신(구로), 현자모바일(구리), 현정보통신,
                현정보통신(음성), 현주텔레콤(도봉구-쌍문동), 현텔레콤,
                현텔레콤(의정부시-용현동), 현호중국슈퍼(인천), 형제모바일,
                형제텔레콤, 형제통신, 혜성모바일, 혜성인터네셔널, 혜통신,
                호경플러스(불로), 호야네폰가게, 호우(인천), 호운래중국슈퍼,
                호키포키(전주), 호현텔레콤_시흥, 홀인원서창점, 홈모바일플러스,
                홍_선봉모바일2호점(인천남동구), 홍모바일, 홍천효진,
                홍텔레콤마트_대전신지하, 화니모바일, 화담, 화랑텔레콤,
                화성텔#(양산서창), 화이트컴퍼니, 화이트텔레콤대림동(부성),
                화창통신(안양시석수동), 환통신(북부폰샵), 환희이동통신(부안),
                황금돼지통신, 황린모바일, 황린모바일(안산), 황모바일(안산),
                황민글로벌, 황민글로벌(수원), 황산텔레콤, 황소폰, 황제모바일,
                황제통신(일산), 효성텔레콤(광명), 효원텔레콤, 효자통신(면목점),
                효정정보통신, 후니모바일, 후니모바일(강동구), 후후모바일(울산),
                후후인터내셔널(서울), 훈남모바일, 훈모바일, 훈스마트텔레콤,
                훈이통신, 훈훈통신(서울은평), 훌랄라텔레콤(안중), 휘텔레콤,
                휘황(부산), 휘황부산, 휴고남아이폰수리(달서구), 휴대FUN,
                휴대폰11번가, 휴대폰1번가, 휴대폰1번지,
                휴대폰365(남양주시-진접읍), 휴대폰7번가, 휴대폰DC(이천),
                휴대폰DC마트, 휴대폰DC이천점, 휴대폰가게,
                휴대폰가게(인천연수구), 휴대폰가게당진(부성),
                휴대폰가격이알고싶다울산점, 휴대폰갤럭시아울렛_여수,
                휴대폰거래소, 휴대폰김선생(수원), 휴대폰꽁짜마트(경북/영주),
                휴대폰나라(충주), 휴대폰나라공도점, 휴대폰놀이터,
                휴대폰놀이터(우암), 휴대폰다모아(태백), 휴대폰달인(새마을통신),
                휴대폰대박싼집(북문로짱), 휴대폰대통령, 휴대폰도매？(퇴촌),
                휴대폰도매할인, 휴대폰도매할인파주, 휴대폰랜드_밀양,
                휴대폰랜드_상주, 휴대폰마을, 휴대폰마트, 휴대폰맛집,
                휴대폰멀티샵, 휴대폰멀티샵(김천), 휴대폰멀티샵(창원),
                휴대폰멀티샵(평택서부역점), 휴대폰멀티샵(평택서부역점)3004,
                휴대폰면세점(서면), 휴대폰면세점_나주, 휴대폰면세점_수원,
                휴대폰명가(시흥), 휴대폰바로고_충주, 휴대폰박사, 휴대폰백화점,
                휴대폰뱅크, 휴대폰빅마트_정읍, 휴대폰빵집_청주,
                휴대폰성지(의정부), 휴대폰세상, 휴대폰세상(거창), 휴대폰쇼핑몰,
                휴대폰쇼핑몰(왜관), 휴대폰쇼핑몰YesTelecom, 휴대폰싸게파는집,
                휴대폰싸다구, 휴대폰싸다구(평택), 휴대폰싼집, 휴대폰아울렛,
                휴대폰아울렛(강북구우이동), 휴대폰아울렛(안산),
                휴대폰아울렛(천안), 휴대폰아울렛(해남),
                휴대폰아울렛_괴정점(부산사하구), 휴대폰아울렛_서산,
                휴대폰일번가, 휴대폰전화국_청주, 휴대폰종합마트(정읍),
                휴대폰주민센터괴정점, 휴대폰주민센터우동점,
                휴대폰주민센터주례점, 휴대폰창고, 휴대폰천국,
                휴대폰천국(인천/십정동), 휴대폰총판, 휴대폰카페,
                휴대폰클라쓰_나우이공이, 휴대폰클라쓰청북점, 휴대폰특판,
                휴대폰팜(평택), 휴대폰프라자, 휴대폰프라자지웰씨티점,
                휴대폰플러스주성점, 휴대폰할인마트, 휴대폰할인마트(울산),
                휴대폰할인마트(청릉동), 휴대폰할인마트갈산점(이천),
                휴대폰할인마트외동, 휴대폰할인매장, 휴대폰할인매장(김천),
                휴대폰할인매장(맹동점), 휴먼(휴먼모바일(안양역)),
                휴먼모바일(김해)KL, 휴모바일, 휴백_진해, 휴케이스, 희망정보통신,
                희망텔레콤, 희망통신, 희수통신, 희야텔레콤, 히어로폰(Herophone),
                히치통신(수원역지하상가), 히트모바일, 히트통신,
                히트통신월곡(성북구-하월곡동), 힐링(강변테크노)
              </div>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- end // to개발 / popup03 개인정보처리 업무의 위탁 -->

      <!-- to개발 / popup04 수집한 개인정보의 제3자 제공 -->
      <section
        v-if="currentModal === '5'"
        id="modalLayerPopup"
        key="20210120-04"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              수집한 개인정보의 제3자 제공
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <caption>
                  제공받는 업체, 제공하는 항목, 제공받는 자의 이용목적, 보유 및
                  이용기간 에 따른 이동전화 개인정보 제 3자 제공에 관한
                  정보입니다.
                </caption>
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 30%" />
                  <col style="width: 30%" />
                  <col style="width: 20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <th>제공항목</th>
                    <th>제공목적</th>
                    <th>보유기간</th>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">서울보증보험</span>
                      (서울보증보험이 분리보관 정보 이용 및
                      신용정보회사(나이스평가정보, 코리아크레딧뷰로,
                      한국평가데이터), 신용정보집중기관(한국신용정보원),
                      금융결제원에 제공ㆍ조회하는 것 포함)
                    </td>
                    <td>
                      <span class="highlight">고유식별정보</span>(서울보증보험에
                      단순 전달), 이름, 이동전화번호, 서비스정보,
                      분할상환계약정보, 청구정보, 신용거래정보(신용보험가입정보
                      등), 신용도 판단정보(연체, 대금지급정보 등),
                      신용등급(평점)
                    </td>
                    <td>
                      <span class="highlight"
                        >서비스 이용요금 및 분할상환 채권보전을 위한
                        신용보험가입 인수심사, 운영관리, 채권추심, 신용정보의
                        집중관리ㆍ활용 및 교환</span
                      >
                    </td>
                    <td>
                      <span class="highlight">보험 채권 소멸시까지</span
                      ><br />(단, 법령에 따로 정한 경우에는 해당기간까지,
                      신용보험 미가입시에는 제공 및 보유하지 않음)
                    </td>
                  </tr>
                  <tr>
                    <td><span class="highlight">한국인터넷진흥원</span></td>
                    <td>
                      이동전화번호, 스팸발신번호, 스팸메시지 내용, 스팸수신시간
                    </td>
                    <td>
                      <span class="highlight"
                        >스팸신고 및 차단, 스팸발송으로 인한 이용 정지 혹은
                        해지사실의 타통신사에 의한 확인 등</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">한국통신사업자연합회</span></td>
                    <td><span class="highlight">주민등록번호</span>, 이름</td>
                    <td><span class="highlight">미환급금 안내</span></td>
                    <td>
                      <span class="highlight"
                        >미환급금 환급 완료 후 6개월까지</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >한국정보통신진흥협회, 이동통신사, 과학기술정보통신부,
                        한국통신사업자연합회</span
                      >
                    </td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름(법인명),
                      이동전화번호, 신분증 기재사항, 주소, 생년월일, 국적,
                      미납요금 금액, 가입정보(통신사, 가입일, 해지일)
                    </td>
                    <td>
                      <span class="highlight"
                        >방송통신신용정보공동관리(식별, 체납, 복지, 외국인정보인
                        증), 번호이동, 명의도용방지, 외국인 출국 정보, 우편안내,
                        분실단말기 공동관리, 스팸발송자, 서비스 가입제한, 이동
                        전화 단기간 가입 회선 관리</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >한국정보통신진흥협회, 이동통신사</span
                      >
                    </td>
                    <td>
                      이름, 연체정보등록예정일, 신용정보사 위임예정일, 통신권
                      중복가입방지정보(DI), 직권해지예정일, 연체금액, 납부기한,
                      미성년자의 경우 법정대리인 중복가입방지정보(DI),
                      이동전화번호 또는 서비스관리번호
                    </td>
                    <td>
                      <span class="highlight"
                        >통신연체사실통지, 이용요금 미납으로 인한 해지시
                        알림</span
                      >
                    </td>
                    <td>
                      <span class="highlight">해당 업무 처리 완료 시까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="highlight">한국정보통신진흥협회</span></td>
                    <td>단말기 정보(IMEI)</td>
                    <td><span class="highlight">IMEI 사전등록 서비스</span></td>
                    <td>
                      <span class="highlight">서비스 계약 해지 시까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="highlight">한국정보통신진흥협회</span></td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름,
                      생년월일, 이 동전화번호, 주소, 국적, 단말기 정 보(모델명,
                      IMEI)
                    </td>
                    <td><span class="highlight">동일명의 확인 서비스</span></td>
                    <td>
                      <span class="highlight"
                        >서비스 계약 전 부 해지 후 6개 월까지</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td><span class="highlight">한국정보통신진흥협회</span></td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름,
                      이용정지 사유
                    </td>
                    <td>
                      <span class="highlight"
                        >전기통신역무를 이용한 번호변작, 불법스팸발송,
                        보이스피싱, 불법대부광고 등으로 인한 서비스이용정지자
                        서비스 가입제한</span
                      >
                    </td>
                    <td><span class="highlight">서비스 제공 기간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">나이스평가정보</span></td>
                    <td>
                      <span class="highlight">주민등록번호</span>, 이름,
                      중복가입확인정보(DI), 생년월일, 성별
                    </td>
                    <td>
                      <span class="highlight"
                        >내국인 실명 인증, 신용정보조회</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >신분증 발급기관(행정안전부, 법무부, 외교부,
                        경찰청)</span
                      >
                    </td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름, 신분증
                      기재사항
                    </td>
                    <td><span class="highlight">부정가입 방지</span></td>
                    <td>
                      <span class="highlight"
                        >서비스 가입 처리 완료 시까지</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >행정안전부, 과학기술정보통신부,
                        한국정보통신진흥협회</span
                      >
                    </td>
                    <td><span class="highlight">주민등록번호</span>, 이름</td>
                    <td>
                      <span class="highlight">주민등록전산 정보 확인</span>
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >법무부, 한국정보통신진흥협회</span
                      >
                    </td>
                    <td>
                      <span class="highlight">여권번호</span>, 이름, 국적,
                      등록번호, 생년월일, 이동전화번호
                    </td>
                    <td>
                      <span class="highlight">외국인 출국사실 정보 확인</span>
                    </td>
                    <td><span class="highlight">체류기간까지</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >KT, SK브로드밴드, LGU+, 온세텔레콤, SK텔링크(국제전화
                        및 콜렉트콜 사업자), 삼성SDS, LG헬로, 한국케이블텔레콤,
                        드림라인, SB인터렉티브(국제전화 사업자)</span
                      >
                    </td>
                    <td>
                      이름, 이동전화번호, 생년월일, 청구일자, 청구금액,
                      과금자료(착신번호, 통화일시, 발신번호, 통화시간), 미납금액
                      등
                    </td>
                    <td>
                      <span class="highlight"
                        >국제전화 및 콜랙트콜 이용에 따른 요금 정산</span
                      >
                    </td>
                    <td><span class="highlight">요금 징수 후 5년 간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">국세청</span></td>
                    <td><span class="highlight">주민등록번호</span></td>
                    <td>
                      <span class="highlight"
                        >소득공제 대상 항목에 대한 소득공제 반영 신청</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">즉시납부/자동납부 카드사</span>
                    </td>
                    <td>카드번호</td>
                    <td>
                      <span class="highlight"
                        >소득공제 대상 항목에 대한 소득공제 반영 신청</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>

                  <tr>
                    <td>
                      <span class="highlight"
                        >다날, KG모빌리언스, 페이레터, 갤럭시아머니트리,
                        NHN한국사이버결제</span
                      >
                    </td>
                    <td>
                      이름, 이동전화번호, DI, 이동전화 이용금액,
                      인증실패사유(한도초과 등), 통신과금서비스 이용가능고객
                      여부, 잔여한도 및 미납여부
                    </td>
                    <td>
                      <span class="highlight"
                        >통신과금서비스(휴대폰결제) 인증 및 이용금액 정산</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">케이에스넷, SK주식회사</span>
                    </td>
                    <td>예금주 생년월일, 계좌번호</td>
                    <td>
                      <span class="highlight"
                        >은행계좌 자동이체 신청 시 계좌 인증</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">금융결제원, SK주식회사</span>
                    </td>
                    <td>이름, 이동전화번호, 예금주 생년월일, 계좌번호</td>
                    <td>
                      <span class="highlight"
                        >은행계좌 자동이체 신청 및 인출 요청</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">금융결제원, 나이스평가정보</span>
                    </td>
                    <td>카드주 생년월일, 계좌∙카드번호</td>
                    <td>
                      <span class="highlight"
                        >카드 자동결제 신청, 카드결제 요청, 은행계좌
                        송금처리</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">자동인출을 신청하신 은행</span>
                    </td>
                    <td>이동전화번호, 생년월일, 계좌번호</td>
                    <td>
                      <span class="highlight">은행계좌 자동이체 처리</span>
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">금융결제원, 케이에스넷</span>
                    </td>
                    <td>카드번호</td>
                    <td><span class="highlight">카드 자동결제 요청</span></td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">자동납부 신청 카드사</span></td>
                    <td>카드번호</td>
                    <td><span class="highlight">카드 자동결제 처리</span></td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >케이에스넷, 하나은행, 우리은행, 국민은행, 신한은행,
                        농협</span
                      >
                    </td>
                    <td>이름</td>
                    <td>
                      <span class="highlight">입금전용계좌 수취인조회</span>
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">하나은행</span></td>
                    <td>
                      이름, <span class="highlight">주민등록번호</span>,
                      이동전화번호
                    </td>
                    <td>
                      <span class="highlight"
                        >제휴요금제를 통한 요금할인 제공</span
                      >/td>
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >한국정보통신산업협회, 나이스평가정보</span
                      >
                    </td>
                    <td><span class="highlight">주민등록번호</span></td>
                    <td>
                      <span class="highlight"
                        >실명인증 및 연계정보(CI)발급</span
                      >
                    </td>
                    <td>
                      <span class="highlight">실명인증 후 즉시 파기</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="highlight">번개장터</span></td>
                    <td>
                      이동전화번호, 요금제, 가입일, 해지일, 정지일, 요금제변경일
                    </td>
                    <td>
                      <span class="highlight"
                        >번개장터 포인트적립, 수수료정산</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">중고나라</span></td>
                    <td>이름, 이동전화번호, 요금제명, 가입일, 해지일</td>
                    <td>
                      <span class="highlight">제휴요금제를 통한 쿠폰 제공</span>
                    </td>
                    <td>
                      <span class="highlight">쿠폰발송 후 6개월까지</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>국제전화(00700.com 홈페이지, 앱)</h2>
              <table>
                <caption>
                  제공받는 업체, 제공하는 항목, 제공받는 자의 이용목적, 보유 및
                  이용기간 에 따른 이동전화 개인정보 제 3자 제공에 관한
                  정보입니다.
                </caption>
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 30%" />
                  <col style="width: 30%" />
                  <col style="width: 20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <th>제공항목</th>
                    <th>제공목적</th>
                    <th>보유기간</th>
                  </tr>
                  <tr>
                    <td><span class="highlight">SK플래닛</span></td>
                    <td>OK캐쉬백번호, 전화번호, 적립포인트</td>
                    <td>
                      <span class="highlight"
                        >OK캐쉬백포인트 적립, 이벤트/혜택 기프티콘 발송</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">아시아나항공</span></td>
                    <td>아시아나항공회원번호, 전화번호, 적립마일리지</td>
                    <td><span class="highlight">마일리지 적립</span></td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- end // to개발 / popup04 수집한 개인정보의 제3자 제공 -->

      <!-- to개발 / popup05 고객 개인정보의 국외 이전-->
      <section
        v-if="currentModal === '6'"
        id="modalLayerPopup"
        key="20210120-05"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              고객 개인정보의 국외 이전
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>서비스명</th>
                    <th>관련 서비스</th>
                    <th>이전되는 개인정보 항목</th>
                    <th>이전되는 국가 및 이전 일시, 방법</th>
                    <th>개인정보를 이전받는 자</th>
                    <th>개인정보를 이전받는 자의 이용목적 및 보유기간</th>
                  </tr>
                  <tr>
                    <td>이동전화</td>
                    <td>eSIM 개통 서비스</td>
                    <td>IMSI, MSISDN, EID, ICCID</td>
                    <td>
                      <span class="highlight">이전국가 : 프랑스</span><br />
                      일시 : eSIM 개통 요청 발생시<br />
                      방법 : VPN을 이용한 원격지 전송
                    </td>
                    <td>
                      <span class="highlight">IDEMIA (RSP 운영사)</span><br />
                      <span class="highlight">02-3446-6277</span>
                    </td>
                    <td>
                      <span class="highlight">목적 : eSIM Profile Download</span
                      ><br />
                      <span class="highlight">보유기간 : 위탁계약 종료까지</span
                      ><br />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->
            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <!-- to개발 / popup06 의견 수렴 및 불만 처리 -->
      <section
        v-if="currentModal === '7'"
        id="modalLayerPopup"
        key="20210120-06"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              의견 수렴 및 불만 처리
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화, 국제전화(00700.com 홈페이지, 앱)</h2>
              <table>
                <colgroup>
                  <col style="width: 50%" />
                  <col style="width: 50%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>개인정보보호 책임자</th>
                    <th>개인정보보호 담당자</th>
                  </tr>
                  <tr>
                    <td>
                      성명, 직책 : 이정열 Infra Tech 담당임원<br />
                      전화번호 : 이동전화 114, (국번없이) 1599-0999<br />
                      이메일 : sktelinkprivacy@sk.com
                    </td>
                    <td>
                      성명, 직책 : 이원희 Infra 보안팀 부장<br />
                      전화번호 : 이동전화 114, (국번없이) 1599-0999<br />
                      이메일 : sktelinkprivacy@sk.com
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->
            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <div class="modal-backdrop" style="display: block" />

      <!-- to개발 / 팝업 오픈 시 ui-modal 안에 팝업이 생기는 구조였음 -->
      <!-- end // to개발 / 2021.01.20 ~ 현재까지 적용 -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentModal: "",
      isVisible: false,
    };
  },
  methods: {
    show(id) {
      (this.currentModal = id), (this.isVisible = true);
    },
    hide() {
      (this.currentModal = ""), (this.isVisible = false);
    },
  },
};
</script>

<style></style>
