
<template>
  <div id="SKT_FREE_15000">
    <div class="event-wrap">
      <div class="event">
        <section class="top pink">
          <h1><img src="/content/images/keycopy.png"></h1>
          <h1>
            <div class="coupon">
              <div class="txt-box">
                <span class="price"><strong>15,000</strong>원</span>
                <p>휴대폰번호 등록시 이벤트기간내 (수신문자 참조)<br><em>00700 사용금액에서 자동으로 15,000원</em>이 공제됩니다.</p>
              </div>
            </div>
          </h1>
        </section>
        <section class="contents">
          <h1>
            <img
              src="/content/images/tit_01.png"
              alt="제휴혜택받기"
            >
          </h1>
          <p><img src="/content/images/contents_01.png"></p>
        </section>
        <section class="form-area">
          <h1>
            <img
              src="/content/images/tit_02.png"
              alt="제휴혜택받기"
            >
          </h1>
          <div class="form-box">
            <div class="agree-area">
              <input
                id="yes"
                v-model="agreePrivacy"
                type="checkbox"
                value=""
              >
              <label for="yes">개인정보 수집하는 것에 동의합니다.</label>
              <a
                id="myBtn"
                @click="privacyPopup = true"
              >자세히 보기</a>
            </div>
            <dl>
              <dt>전화번호(무선번호만 입력)</dt>
              <dd>
                <div class="phone-box">
                  <input
                    ref="telNumberFirst"
                    v-model="telNumber.first"
                    type="text"
                    title="전화번호"
                    name="i_sHpNo1"
                    maxlength="3"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="numbersOnly phoneNum"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    @keyup="enterText"
                  >
                  <span class="txt_hyphen">-</span>
                  <input
                    ref="telNumberMiddle"
                    v-model="telNumber.middle"
                    type="text"
                    title="전화번호"
                    name="i_sHpNo2"
                    maxlength="4"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="numbersOnly phoneNum"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    @keyup="enterText"
                  >
                  <span class="txt_hyphen">-</span>
                  <input
                    ref="telNumberLast"
                    v-model="telNumber.last"
                    type="text"
                    title="전화번호"
                    name="i_sHpNo3"
                    maxlength="4"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    class="numbersOnly phoneNum"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  >
                </div>
                <a
                  class="btn-s btn_sendnum"
                  @click="requestSMSCert"
                >SMS인증하기</a>
              </dd>
            </dl>
            <dl>
              <dt>인증번호 입력</dt>
              <dd>
                <input
                  ref="certNumber"
                  v-model="auth_no"
                  type="text"
                  name="i_sCertificationNo"
                  title="인증번호"
                  class="wid452"
                  maxlength="6"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                >
                <a
                  class="btn-s btn_checknum"
                  @click="requestCert"
                >인증번호 확인</a>
              </dd>
            </dl>
            <h2>
              <a
                class="btn-confirm btnApply"
                @click="requestFinal"
              >등록 완료</a>
            </h2>
          </div>
        </section>
        <section class="app-down">
          <h1>
            <img
              src="/content/images/ico_00700.png"
              alt="00700다운로드"
            >
          </h1>
          <span>
            <em>00700 앱을 설치하시면<br></em>더 편하게 00700을 사용하실 수 있어요!
            <router-link
              to="/view/business/00700app"
              target="_blank"
            >
              <img src="/content/images/btn_00700app.png">
            </router-link>
          </span>
        </section>
        <section class="contents">
          <h1>
            <img
              src="/content/images/tit_03.png"
              alt="제휴혜택받기"
            >
          </h1>
          <p class="type">
            <img src="/content/images/contents_02.png">
          </p>
        </section>
        <section class="bottom">
          <h1>
            <img
              src="/content/images/tit_04.png"
              alt="유의사항"
            >
          </h1>
          <ul>
            <li>본 혜택 제공을 위해선 개인정보 수집에 대한 동의가 반드시 필요합니다.</li>
            <li>국제전화 무료통화 체험은 SKT 가입고객에 한해서만 SK텔링크가 제공하는 혜택이며, 광고 문자 수신고객에 한해서만 무료통화권이 지급됩니다.</li>
            <li>수신받은 광고문자에 기재된 이벤트 기간에 한해서만 국제전화 무료통화 혜택이 적용됩니다.</li>
            <li>무료통화권 지급을 위한 휴대폰번호 등록시 등록일 관계없이 이벤트 기간내 00700 사용금액에 대해 소급적용하여 15,000원이 공제됩니다.</li>
            <li>이벤트 기간 종료 후에는 해당 웹페이지 접속이 불가합니다. </li>
            <li>휴대폰번호를 정확하게 입력하지 않아 발생하는 오류에 대해선 SK국제전화 00700은 책임을 지지 않습니다.</li>
            <li>위성전화(인마셋 등), 화상전화에는 해당 무료통화 체험 혜택이 제공되지 않습니다.</li>
            <li>기존에 SK국제전화 00700 의 정액형 요금제(All pass, 파리바게트, 무비요금제 등)를 이용하시는 경우, 기본료 및 기본제공분수 외 초과 사용료 및 초과 사용분수에 대해 혜택을 적용받으실 수 있으십니다.</li>
            <li>본 제휴혜택 관련 문의: SK국제전화 00700 고객센터 1599-0070 <br>(운영시간 : 평일 9시~18시)</li>
          </ul>
        </section>
      </div>
    </div>

    <!-- 개인정보 수집동의 팝업 -->
    <div
      v-if="privacyPopup"
      id="myModal"
      class="modal"
      style="display: block;"
    >
      <div class="modal-content type01">
        <span
          class="close-bt close"
          @click="privacyPopup = false"
        >&times;</span>
        <h2>개인정보 수집 동의 안내</h2>
        <p>
          SK텔링크 주식회사는 SK국제전화 00700 무료통화 지급을 위해 아래와 같이 고객정보를 수집하며, 고객정보 취급에 관한 상세한 사항은 www.sktelink.com에 게시된 개인정보처리방침을 참조하시기 바랍니다.
          <br><br>
          - 수집하는 개인정보 항목 : 핸드폰번호(무선번호)<br>
          - 개인정보의 수집 및 이용목적 : SK국제전화 00700 이용에 대한 무료통화 혜택 제공 및 이용 안내, 고객 민원 대응<br>
          - 개인정보 보유 및 이용기간 : <strong>>무료통화혜택 제공 기간 포함 혜택 관련 고객민원 대응을 위해 6개월까지 보유</strong>
          <br><br>
          개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부시 혜택 제공이 제한될 수 있습니다.
          <br><br>
          SK국제전화 00700 이용에 따른 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.
        </p>
        <h3>
          <a
            class="btn-m close"
            @click="privacyPopup = false"
          >확인</a>
        </h3>
      </div>
    </div>

    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'

export default {
  name: 'SktFree15000',
  components: {
    Popup
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      privacyPopup: false,
      alertPopup: {
        visible: false,
        message: '',
        target: ''
      },
      ticket: '',
      auth_no: '',
      isSmsCert: false,
      agreePrivacy: false,
      telNumber: {},
    }
  },
  computed: {
    tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if (this.callbackAlert.code === '0002' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code === 'E029') {
          this.resetPage()
          window.scrollTo(0, 0)
          document.getElementById('wrap').scrollTop = 0
        }
        if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
          this.telNumber = {
            first: '',
            middle: '',
            last: ''
          },
          this.auth_no = '',
          this.ticket = ''
        }
        if (this.callbackAlert.code === 'E001') { // 인증시간 초과
          this.auth_no = ''
          this.ticket = ''
        }
      }
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    enterText(event) {
      if(event.target.value.length > event.target.maxLength -1) {
        event.target.nextSibling.nextSibling.focus()
      }
    },
    alertPopupHandler() {
      this.alertPopup.visible = false
      this.alertPopup.message = ''
      if (this.alertPopup.target) {
        this.$refs[this.alertPopup.target].focus()
      }
      this.alertPopup.target = ''
    },
    showAlertPopup(message) {
      this.alertPopup.message = message
      this.alertPopup.visible = true
    },
    checkPrivacy() {
      if(!this.agreePrivacy) {
        this.showAlertPopup('개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 이벤트 참여가 가능합니다.')
        return false
      } else {
        return true
      }
    },
    checkPhoneNumber() {
      if (!this.telNumber.first) {
        this.alertPopup.target = 'telNumberFirst'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.middle) {
        this.alertPopup.target = 'telNumberMiddle'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.last) {
        this.alertPopup.target = 'telNumberLast'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.first.length < 3 || this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else if (!this.agreePrivacy) {
        this.showAlertPopup('개인정보뢀용 수집 및 취급 위탁 동의에 체크하셔야 응모 가능합니다.')
      } else {
        return true
      }
    },
    checkSMSCert() {
      if(!this.isSmsCert) {
        this.showAlertPopup('휴대폰 인증을 해주세요.')
        return false
      } else {
        return true
      }
    },
    checkAuthNumber() {
      if (!this.auth_no) {
        this.alertPopup.target = 'certNumber'
        this.showAlertPopup('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    // SMS인증하기
    requestSMSCert() {
      if (this.checkPhoneNumber()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.tel_no })
          .then(response => {
            if (response.data.sms_ticket) {
              this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
              this.ticket = response.data.sms_ticket
            }
          })
      }
    },
    // 인증번호 확인
    requestCert() {
      if (this.checkAuthNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
          .then(response => {
            if (response.data.result_cd === '0001') {
              this.isSmsCert = true
              this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
            }
          })
      }
    },
    // 등록 완료
    requestFinal() {
      if (this.checkPrivacy() && this.checkPhoneNumber() && this.checkSMSCert() && this.checkAuthNumber()) {
        if(confirm('등록 하시겠습니까?')) {
          api.EventPostFreeDedtEvent({
            sms_ticket: this.ticket,
            event_no: 'ZZ000000000000005974'
          })
        }
      }
    },
    resetPage(){
      this.agreePrivacy = false
      this.telNumber = {
        first: '',
        middle: '',
        last: ''
      },
      this.auth_no = '',
      this.ticket = '',
      this.isSmsCert = false
    }
  }
}
</script>

<style lang="scss" scoped>
</style>