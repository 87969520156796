<template>
  <div id="ALL_PASS_OPTION_CHANGE">
    <div class="event-wrap">
      <div class="event">
        <!-- 휴대폰 인증이 되었는지 여부에 따라 아래 내용 활성화/비활성화 -->
        <!-- 또는 파라메타 없이 "/partnership/all_pass_option_change" URL 접속하면 아래 내용 비활성화 -->
        <!-- 휴대폰 인증 후 파라메타 붙여서 접속 "/partnership/all_pass_option_change?plan=0061" -->
        <section
          v-if="$route.query.plan === undefined"
          class="top navy before_auth"
        >
          <h1>
            <img
              src="/content/images/bg_event_allpass_text.png"
              alt="All Pass 요금제 혜택 종류"
            >
          </h1>
          <div class="form-area type02">
            <div class="form-box">
              <dl>
                <dt>휴대폰정보</dt>
                <dd>
                  <div class="phone-box">
                    <select
                      ref="telNumberFirst"
                      v-model="telNumber.first"
                    >
                      <option value="010">
                        010
                      </option>
                      <option value="011">
                        011
                      </option>
                    </select>
                    <span class="txt_hyphen">-</span>
                    <input
                      ref="telNumberMiddle"
                      v-model="telNumber.middle"
                      type="text"
                      title="전화번호"
                      maxlength="4"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    >
                    <span class="txt_hyphen">-</span>
                    <input
                      ref="telNumberLast"
                      v-model="telNumber.last"
                      type="text"
                      title="전화번호"
                      maxlength="4"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    >
                  </div>
                  <a 
                    class="btn-s btn_sendnum"
                    @click="requestSMSCert"
                  >SMS 인증하기</a>
                </dd>
              </dl>
              <dl>
                <dt>인증번호 입력</dt>
                <dd>	                         
                  <input
                    ref="certNumber"
                    v-model="certNumber"
                    type="text"
                    title="인증번호"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  > 
                  <a
                    class="btn-s btn_checknum"
                    @click="requestCert"
                  >인증번호 확인</a>
                </dd>
              </dl>
            </div>
          </div>
        </section>

        <section
          v-if="$route.query.plan"
          class="top navy"
        >
          <h1>
            <span class="user_phone_number">{{ telMask }}</span><span class="text">님의<br>현재 혜택</span>
          </h1>

          <div class="current_selected">
            <img
              :alt="`${ optionProd }`"
              :src="optionBtn"
            >
          </div>
          <div class="bill">
            <table>
              <tbody>
                <tr>
                  <th>
                    <span>요금제</span>
                  </th>
                  <td>
                    <span>
                      {{ planData.name }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>기본료</span>
                  </th>
                  <td>
                    <span>
                      {{ planData.basicPrice }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>기본혜택</span>
                  </th>
                  <td>
                    <span>
                      {{ planData.basicBenefit }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>
                    <span>옵션 혜택<br class="m_br">(택1)</span>
                  </th>
                  <td>
                    <span v-html="planData.optionBenefit" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="button-list">
            <h2>
              <img
                src="/content/images/img_allpass_cnange_copy.png"
                alt="요금제 옵션 혜택 상품을 선택하여 All Pass 요금제만의 혜택을 만나보세요 (원하시는 상품 이미지를 선택해 주세요.)"
              >
            </h2>
            <ul>
              <li
                v-for="(item, index) in benefitList"
                :key="index"
              >
                <button 
                  class="brand_button"
                  @click="optionPopup"
                >
                  <img
                    :src="item.btnImage"
                    :alt="item.product"
                    :data-status="item.status"
                    :data-product="item.product"
                  >
                </button>
              </li>
            </ul>
          </div>
        </section>

        <section
          v-if="$route.query.plan"
          class="bottom gray"
        >
          <h1>
            <img
              src="/content/images/tit_05.png"
              alt="유의사항"
            >
          </h1>
          <ul>
            <li>본 요금제 가입을 위해선 개인정보 수집 및 활용, 취급 위탁에 대한 동의가 필요합니다.</li>
            <li>SK국제전화에서 판매 중인 타 국제전화 요금제와 중복가입이 불가합니다.</li>
            <li>불법스팸, 상업적 용도 등 불법적인 국제전화 이용 시 즉시 서비스 제공이 중단됩니다.</li>
            <li>월 제공분수 초과 이용시, 초과분에 대해서는 00700 표준요금이 적용됩니다. </li>
            <li>요금월(무선주기 : 전월 21일~당월20일) 중도에 해당 요금제를 가입 또는  해지하는 경우 기본료는 월할 계산으로 적용됩니다.</li>
            <li>화상/위성전화등 특수대역대 이용시 무료통화는 적용되지 않으며, 선불폰 이용은 불가합니다.</li>
            <li>번호 또는 명의 변경 시, 혜택은 소멸되며 고객센터로 사전 연락 부탁 드립니다.</li>
            <li><i class="bold-type">요금제 관련 자세한 문의는 SK국제전화 고객센터로 연락 부탁드립니다.</i></li>
            <li class="purple">
              <i class="bold-type">고객센터 전화번호 :</i> 1599-0070
            </li>
            <li class="purple">
              <i class="bold-type">운영시간 :</i> 월~금(오전9시~오후6시)
            </li>
          </ul>
        </section>
      </div>
    </div>

    <!-- 혜택 변경 가능 팝업 -->
    <div
      v-if="popup.benefitChange.isVisible"
      class="pop_wrap10"
      style="display: block;"
    >
      <div class="pop_top">
        <h1>
          <img
            src="/content/images/keycopy04.png"
            alt="올패스"
          >
        </h1>
        <h2>{{ planData.name }}<br>옵션 혜택</h2>
        <!-- <h2>10,000 요금제 옵션 혜택</h2> -->
        <div class="bill-cont">
          <div class="bill-cont_top">
            <img
              :alt="`${selectedBenefit.product}로고`"
              :src="selectedBenefit.popupImage"
            >
            <div class="bill-cont_name">
              {{ selectedBenefit.product }}
            </div>
            <div class="bill-cont_value">
              {{ selectedBenefit.benefit }}
            </div>
          </div>
          <div class="bill-cont_bottom">
            <div class="bill-cont_text">
              <span v-html="selectedBenefit.unusedBenefit" />
            </div>
            <!-- <div class="bill-cont_text">
              전월21부터 당월20일까지 국제전화<br>00700 미사용시
              <span class="bold-type">1만원 상품권 제공</span>
            </div> -->
          </div>
        </div>
        <ul class="pop_list">
          <li>국제전화 월 청구주기 (전월21일~당월20일)에 따라 매월 20일 23시59분까지 최종 혜택변경이 가능하며 최종 선택된 혜택으로 익월 15일 이내 모바일상품권 문자 발송됩니다.</li>
          <li>고객님께서 이미 받으신 상품의 혜택상품도 변경을 원하실 경우 고객센터로 별도 연락을 부탁 드립니다.</li>
          <li>고객센터 1599-0070</li>
        </ul>
        <div class="pop_check">
          <input
            id="chk1"
            v-model="agreePrivacy"
            type="checkbox"
          >
          <label for="chk1">SK국제전화 요금제 혜택을 받기 위해 개인정보 수집하는 것에 동의합니다</label>
        </div>
        <button 
          class="btn_text"
          @click="popup.privacy.isVisible = true"
        >
          개인정보 수집 동의 안내 자세히 보기
        </button>
      </div>

      <div class="pop_button">
        <button
          class="btn-cancel"
          @click="popup.benefitChange.isVisible = false"
        >
          취소
        </button>
        <button
          class="btn-confirm"
          @click="requestFinal"
        >
          혜택변경
        </button>
      </div>
      <button 
        class="pop_close"
        @click="popup.benefitChange.isVisible = false"
      >
        닫기
      </button>
    </div>
    <!-- //혜택 변경 가능 팝업 -->

    <!-- 혜택 변경 불가 팝업 -->
    <div
      v-if="popup.unableChange.isVisible"
      class="pop_wrap10"
      style="display: block;"
    >
      <div class="pop_top">
        <h1>
          <img
            src="/content/images/keycopy04.png"
            alt="올패스"
          >
        </h1>
        <h2>
          <span>{{ providePlanInfo.name }}</span>
          부터 선택이 가능한 옵션입니다.
        </h2>
        <div class="bill-cont">
          <div class="bill-cont_top">
            <img
              :alt="`${selectedBenefit.product}로고`"
              :src="selectedBenefit.popupImage"
            >
            <div class="bill-cont_name">
              {{ selectedBenefit.product }}
            </div>
          </div>
          <div class="bill-cont_bottom">
            <div class="bill-cont_text">
              필요하실 경우 고객센터에 문의 부탁 드립니다.
              <span class="bold-type">(1599-0700)</span>
            </div>
          </div>
        </div>
        <ul class="pop_list table-type">
          <li>
            <span class="title">요금제</span>
            <span class="data">{{ providePlanInfo.name }}</span>
          </li>
          <li>
            <span class="title">기본료</span>
            <span class="data">{{ providePlanInfo.basicPrice }}</span>
          </li>
          <li>
            <span class="title">기본혜택</span>
            <span class="data">{{ providePlanInfo.basicBenefit }}</span>
          </li>
          <li>
            <span class="title">옵션 혜택(택1)</span>
            <span
              class="data"
              v-html="providePlanInfo.optionBenefit"
            />
          </li>
        </ul>
      </div>
      <div class="pop_button">
        <button
          class="btn-close"
          @click="popup.unableChange.isVisible = false"
        >
          닫기
        </button>
      </div>
      <button
        class="pop_close"
        @click="popup.unableChange.isVisible = false"
      >
        닫기
      </button>
    </div>
    <!-- //혜택 변경 불가 팝업 -->

    <!-- 혜택 변경 완료 팝업 -->
    <div
      v-if="popup.changeComplete.isVisible"
      class="pop_wrap10"
      style="display: block;"
    >
      <div class="pop_top pop_complete">
        <h1>
          <img
            src="/content/images/keycopy04.png"
            alt="올패스"
          >
        </h1>
        <h2>요금제 혜택 변경 완료</h2>
        <div class="bill-cont">
          <div class="bill-cont_top">
            <img
              :alt="`${selectedBenefit.product}로고`"
              :src="selectedBenefit.popupImage"
            >
            <div class="bill-cont_name">
              {{ selectedBenefit.product }}
            </div>
          </div>
          <div class="bill-cont_bottom">
            <div class="bill-cont_text">
              필요하실 경우 고객센터에 문의 부탁 드립니다.
              <span class="bold-type">(1599-0700)</span>
            </div>
          </div>
        </div>
        <ul class="pop_list table-type">
          <li>
            <span class="title">요금제</span>
            <span class="data">{{ planData.name }}</span>
          </li>
          <li>
            <span class="title">기본료</span>
            <span class="data">{{ planData.basicPrice }}</span>
          </li>
          <li>
            <span class="title">기본혜택</span>
            <span class="data">{{ planData.basicBenefit }}</span>
          </li>
          <li>
            <span class="title">옵션 혜택(택1)</span>
            <span 
              class="data" 
              v-html="planData.optionBenefit"
            />
          </li>
        </ul>
      </div>
      <button 
        class="pop_close"
        @click="[popup.changeComplete.isVisible = false, popup.benefitChange.isVisible = false, initPage()]"
      >
        닫기
      </button>
    </div>
    <!-- //혜택 변경 완료 팝업 -->
        	
    <!-- 개인정보 수집 동의안내 팝업 -->
    <div
      v-if="popup.privacy.isVisible"
      id="popMedium01"
      class="pop_wrap02 w757 type02"
      style="display: block;"
    >
      <div class="medium">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 국제전화 이용에 대한 혜택제공을 위해 아래와 같이 고객정보를 수집하며, 고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보 항목: 휴대폰번호(연락처)</li>
              <li>개인정보 수집 및 이용목적: SK국제전화요금제에 대한 고객상담 진행</li>
              <li>개인정보 보유 및 이용기간: <strong>SK국제전화요금제 가입 시점부터 해지일자까지</strong></li>
            </ul>
            <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 요금제 혜택 제공에 제한이 될 수 있습니다.</p>
            <p>SK국제전화 요금제 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="popup.privacy.isVisible = false"
          >확인</a>
        </div>
        <a @click="popup.privacy.isVisible = false">
          <img
            alt="닫기"
            src="/content/images/btn_pop_close.png"
          ></a>
      </div>
    </div>
    <!-- //개인정보 수집 동의안내 팝업 -->
    <div
      id="msgDimd"
      class="dimd"
      style="display: none;"
    />
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'

export default {
  name: 'AllPassOption',
  components: {
    Popup
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      alertPopup: {
        visible: false,
        message: '',
        target: ''
      },
      callingPlan: [
        {
          plan:	"0071",	
          type: "00700",
          name:	"All Pass 10,000요금제",
          basicPrice:	"11,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700 300분 제공",
          optionBenefit:	"모바일 상품권 5천원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700 미사용시<br>1만원 상당 상품권 제공)"
        },
        {
          plan:	"0072",	
          type: "00700",
          name:	"All Pass 20,000요금제",
          basicPrice:	"22,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700 350분 제공",
          optionBenefit:	"모바일 상품권 1만원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700 미사용시<br>2만원 상당 상품권 제공)"
        },
        {
          plan:	"0073",	
          type: "00700",
          name:	"All Pass 30,000요금제",
          basicPrice:	"33,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700 400분 제공",
          optionBenefit:	"모바일 상품권 2만원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700 미사용시<br>3만원 상당 상품권 제공)"
        },
        {
          plan:	"0075",	
          type: "00700",
          name:	"All Pass 50,000요금제",
          basicPrice:	"55,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700 무제한 제공",
          optionBenefit:	"모바일 상품권 3만원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700 미사용시<br>5만원 상당 상품권 제공)"
        },
        {
          plan:	"0061",	
          type: "006",
          name:	"All Pass 10,000요금제",
          basicPrice:	"11,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700,006 합산 300분 제공",
          optionBenefit:	"모바일 상품권 5천원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700, 006 미사용시<br>1만원 상당 상품권 제공)"
        },
        {
          plan:	"0062",	
          type: "006",
          name:	"All Pass 20,000요금제",
          basicPrice:	"22,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700,006 합산 350분 제공",
          optionBenefit:	"모바일 상품권 1만원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700, 006 미사용시<br>2만원 상당 상품권 제공)"
        },
        {
          plan:	"0063",	
          type: "006",
          name:	"All Pass 30,000요금제",
          basicPrice:	"33,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700,006 합산 400분 제공",
          optionBenefit:	"모바일 상품권 2만원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700, 006 미사용시<br>3만원 상당 상품권 제공"
        },
        {
          plan:	"0065",	
          type: "006",
          name:	"All Pass 50,000요금제",
          basicPrice:	"55,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700,006 합산 무제한 제공",
          optionBenefit:	"모바일 상품권 3만원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700, 006 미사용시<br>5만원 상당 상품권 제공"
        },
        {
          plan:	"0071S",	
          type: "SKTOB",
          name:	"All Pass 10,000요금제",
          basicPrice:	"11,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700 300분 제공",
          optionBenefit:	"모바일 상품권 5천원 상당<br>(전월21일부터 당월20일까지<br>국제전화 0700 미사용시<br>1만원 상당 상품권 제공)"
        },
        {
          plan:	"0072S",	
          type: "SKTOB",
          name:	"All Pass 20,000요금제",
          basicPrice:	"22,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700 350분 제공",
          optionBenefit:	"모바일 상품권 1만원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700 미사용시<br>2만원 상당 상품권 제공)"
        },
        {
          plan:	"0073S",	
          type: "SKTOB",
          name:	"All Pass 30,000요금제",
          basicPrice:	"33,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700 400분 제공",
          optionBenefit:	"모바일 상품권 2만원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700 미사용시<br>3만원 상당 상품권 제공)"
        },
        {
          plan:	"0075S",	
          type: "SKTOB",
          name:	"All Pass 50,000요금제",
          basicPrice:	"55,000원/월 (VAT포함)",
          basicBenefit:	"국제전화 00700 무제한 제공",
          optionBenefit:	"모바일 상품권 3만원 상당<br>(전월21일부터 당월20일까지<br>국제전화 00700 미사용시<br>5만원 상당 상품권 제공)"
        },
      ],
      callingBenefit: [
        {
          plan:	"0071",
          type:	"00700",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액EM10요금제",	
          optionCode:	"COZ14",
          planInfo:	""
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액GS10요금제",	
          optionCode:	"COZ05",
          planInfo:	""
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액LM10요금제",	
          optionCode:	"COZ18",
          planInfo:	""
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액HC10요금제",	
          optionCode:	"COZ22",
          planInfo:	""
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액SK10요금제",	
          optionCode:	"COZ10",
          planInfo:	""
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"카페라떼 Tall 1잔 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“카페라떼 커플 세트” 제공",
          appliedPlan: "AP-정액SB10요금제",	
          optionCode:	"COZ46",
          planInfo:	""
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0072"
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0072"
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0073"
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액HC10요금제",	
          optionCode:	"COZ22",
          planInfo:	""
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 5천원 제공",
          unusedBenefit: "전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액HC10요금제",	
          optionCode:	"COZ22",
          planInfo:	""
        },
        {
          plan:	"0071",
          type:	"00700",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0073"
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액EM20요금제",	
          optionCode:	"COZ15",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액GS20요금제",	
          optionCode:	"COZ06",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액LM20요금제",	
          optionCode:	"COZ19",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액HC20요금제",	
          optionCode:	"COZ23",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액SK20요금제",	
          optionCode:	"COZ11",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"“카페라떼 커플세트” 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“우리 흑미 카스텔라 & 라떼 세트” 제공",
          appliedPlan: "AP-정액SB20요금제",	
          optionCode:	"COZ47",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"Y",
          benefit:	"메가박스 관람권 1매 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2매제공",
          appliedPlan: "AP-정액MB20요금제",	
          optionCode:	"COZ57",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"Y",
          benefit:	"올리브영 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액OY20요금제",	
          optionCode:	"COZ54",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0073"
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액HC20요금제",	
          optionCode:	"COZ23",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액HC20요금제",	
          optionCode:	"COZ23",
          planInfo:	""
        },
        {
          plan:	"0072",
          type:	"00700",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0073"
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액EM30요금제",	
          optionCode:	"COZ16",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액GS30요금제",	
          optionCode:	"COZ07",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액LM30요금제",	
          optionCode:	"COZ20",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액HC30요금제",	
          optionCode:	"COZ24",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액SK30요금제",	
          optionCode:	"COZ12",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"“너만큼 완벽한 디저트 세트” 상품제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“For Your Lucky 7 Set” , “너만큼 완벽한 디저트 세트” 모두 제공",
          appliedPlan: "AP-정액SB30요금제",	
          optionCode:	"COZ48",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"Y",
          benefit:	"메가박스 관람권 2매 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3매제공",
          appliedPlan: "AP-정액MB30요금제",	
          optionCode:	"COZ58",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"Y",
          benefit:	"올리브영 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액OY30요금제",	
          optionCode:	"COZ55",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"Y",
          benefit:	"“페퍼로니 오리지널(M)+콜라1.25L”상품 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“포테이토 오리지널(L)+콜라1.25L” 상품 제공",
          appliedPlan: "AP-정액PZ30요금제",	
          optionCode:	"COZ52",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액HC30요금제",	
          optionCode:	"COZ24",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액HC30요금제",	
          optionCode:	"COZ24",
          planInfo:	""
        },
        {
          plan:	"0073",
          type:	"00700",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"Y",
          benefit:	"깐부치킨 2만원 디지털 상품권 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액CH30요금제",	
          optionCode:	"COZ50",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액EM50요금제",	
          optionCode:	"COZ17",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액GS50요금제",	
          optionCode:	"COZ08",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액LM50요금제",	
          optionCode:	"COZ21",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액HC50요금제",	
          optionCode:	"COZ25",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액SK50요금제",	
          optionCode:	"COZ13",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"“For Your Lucky 7 Set”, “너만큼 완벽한 디저트 세트” 상품제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“Happy Birthday 세트” , “기분 좋은 디저트 세트”, “너만큼 완벽한 디저트 세트” 모두 제공",
          appliedPlan: "AP-정액SB50요금제",	
          optionCode:	"COZ49",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"Y",
          benefit:	"메가박스 관람권 3매 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5매제공",
          appliedPlan: "AP-정액MB50요금제",	
          optionCode:	"COZ59",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"Y",
          benefit:	"올리브영 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액OY50요금제",	
          optionCode:	"COZ56",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"Y",
          benefit:	"“포테이토 오리지널(L)+콜라1.25L”, 상품 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“포테이토 오리지널(L)+콜라1.25L”, “페퍼로니 오리지널(M)+콜라1.25L” 상품 모두 제공",
          appliedPlan: "AP-정액PZ50요금제",	
          optionCode:	"COZ53",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액HC50요금제",	
          optionCode:	"COZ25",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액HC50요금제",	
          optionCode:	"COZ25",
          planInfo:	""
        },
        {
          plan:	"0075",
          type:	"00700",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"Y",
          benefit:	"깐부치킨 3만원 디지털 상품권 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액CH50요금제",	
          optionCode:	"COZ51",
          planInfo:	""
        },
        {
          plan:	"0061",
          type:	"006",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700, 006<br>미사용시 1만원 상품권 제공",
          appliedPlan: "AP-006정액EM10요금제",	
          optionCode:	"COZ34",
          planInfo:	""
        },
        {
          plan:	"0061",
          type:	"006",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700, 006<br>미사용시 1만원 상품권 제공",
          appliedPlan: "AP-006정액GS10요금제",	
          optionCode:	"COZ26",
          planInfo:	""
        },
        {
          plan:	"0061",
          type:	"006",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700, 006<br>미사용시 1만원 상품권 제공",
          appliedPlan: "AP-006정액LM10요금제",	
          optionCode:	"COZ38",
          planInfo:	""
        },
        {
          plan:	"0061",
          type:	"006",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700, 006<br>미사용시 1만원 상품권 제공",
          appliedPlan: "AP-006정액HC10요금제",	
          optionCode:	"COZ42",
          planInfo:	""
        },
        {
          plan:	"0061",
          type:	"006",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700, 006<br>미사용시 1만원 상품권 제공",
          appliedPlan: "AP-006정액SK10요금제",	
          optionCode:	"COZ30",
          planInfo:	""
        },
        {
          plan:	"0061",
          type:	"006",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"카페라떼 Tall 1잔 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700, 006<br>미사용시 “카페라떼 커플 세트” 제공",
          appliedPlan: "AP-006정액SB10요금제",	
          optionCode:	"COZ60",
          planInfo:	""
        },
        {
          plan:	"0061",
          type:	"006",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0062"
        },
        {
          plan:	"0061",
          type:	"006",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0062"
        },
        {
          plan:	"0061",
          type:	"006",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0063"
        },
        {
          plan:	"0061",
          type:	"006",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700, 006<br>미사용시 1만원 상품권 제공",
          appliedPlan: "AP-006정액HC10요금제",	
          optionCode:	"COZ42",
          planInfo:	""
        },
        {
          plan:	"0061",
          type:	"006",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700, 006<br>미사용시 1만원 상품권 제공",
          appliedPlan: "AP-006정액HC10요금제",	
          optionCode:	"COZ42",
          planInfo:	""
        },
        {
          plan:	"0061",
          type:	"006",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0063"
        },
        {
          plan:	"0062",
          type:	"006",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 2만원 상품권 제공",
          appliedPlan: "AP-006정액EM20요금제",	
          optionCode:	"COZ35",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 2만원 상품권 제공",
          appliedPlan: "AP-006정액GS20요금제",	
          optionCode:	"COZ27",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 2만원 상품권 제공",
          appliedPlan: "AP-006정액LM20요금제",	
          optionCode:	"COZ39",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 2만원 상품권 제공",
          appliedPlan: "AP-006정액HC20요금제",	
          optionCode:	"COZ43",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 2만원 상품권 제공",
          appliedPlan: "AP-006정액SK20요금제",	
          optionCode:	"COZ31",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"“카페라떼 커플세트” 제공",
          unusedBenefit: "전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 “우리 흑미 카스텔라 & 라떼 세트” 제공",
          appliedPlan: "AP-006정액SB20요금제",	
          optionCode:	"COZ61",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"Y",
          benefit:	"메가박스 관람권 1매 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 2매제공",
          appliedPlan: "AP-006정액MB20요금제",	
          optionCode:	"COZ71",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"Y",
          benefit:	"올리브영 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 2만원 상품권 제공",
          appliedPlan: "AP-006정액OY20요금제",	
          optionCode:	"COZ68",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0063"
        },
        {
          plan:	"0062",
          type:	"006",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 2만원 상품권 제공",
          appliedPlan: "AP-006정액HC20요금제",	
          optionCode:	"COZ43",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 2만원 상품권 제공",
          appliedPlan: "AP-006정액HC20요금제",	
          optionCode:	"COZ43",
          planInfo:	""
        },
        {
          plan:	"0062",
          type:	"006",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0063"
        },
        {
          plan:	"0063",
          type:	"006",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3만원 상품권 제공",
          appliedPlan: "AP-006정액EM30요금제",	
          optionCode:	"COZ36",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3만원 상품권 제공",
          appliedPlan: "AP-006정액GS30요금제",	
          optionCode:	"COZ28",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3만원 상품권 제공",
          appliedPlan: "AP-006정액LM30요금제",	
          optionCode:	"COZ40",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3만원 상품권 제공",
          appliedPlan: "AP-006정액HC30요금제",	
          optionCode:	"COZ44",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3만원 상품권 제공",
          appliedPlan: "AP-006정액SK30요금제",	
          optionCode:	"COZ32",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"“너만큼 완벽한 디저트 세트” 상품제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 “For Your Lucky 7 Set” , “너만큼 완벽한 디저트 세트” 모두 제공",
          appliedPlan: "AP-006정액SB30요금제",	
          optionCode:	"COZ62",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"Y",
          benefit:	"메가박스 관람권 2매 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3매제공",
          appliedPlan: "AP-006정액MB30요금제",	
          optionCode:	"COZ72",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"Y",
          benefit:	"올리브영 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3만원 상품권 제공",
          appliedPlan: "AP-006정액OY30요금제",	
          optionCode:	"COZ69",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"Y",
          benefit:	"“페퍼로니 오리지널(M)+콜라1.25L”상품 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 “포테이토 오리지널(L)+콜라1.25L” 상품 제공",
          appliedPlan: "AP-006정액PZ30요금제",	
          optionCode:	"COZ66",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3만원 상품권 제공",
          appliedPlan: "AP-006정액HC30요금제",	
          optionCode:	"COZ44",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3만원 상품권 제공",
          appliedPlan: "AP-006정액HC30요금제",	
          optionCode:	"COZ44",
          planInfo:	""
        },
        {
          plan:	"0063",
          type:	"006",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"Y",
          benefit:	"깐부치킨 2만원 디지털 상품권 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 3만원 상품권 제공",
          appliedPlan: "AP-006정액CH30요금제",	
          optionCode:	"COZ64",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5만원 상품권 제공",
          appliedPlan: "AP-006정액EM50요금제",	
          optionCode:	"COZ37",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5만원 상품권 제공",
          appliedPlan: "AP-006정액GS50요금제",	
          optionCode:	"COZ29",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5만원 상품권 제공",
          appliedPlan: "AP-006정액LM50요금제",	
          optionCode:	"COZ41",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5만원 상품권 제공",
          appliedPlan: "AP-006정액HC50요금제",	
          optionCode:	"COZ45",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5만원 상품권 제공",
          appliedPlan: "AP-006정액SK50요금제",	
          optionCode:	"COZ33",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"“For Your Lucky 7 Set”, “너만큼 완벽한 디저트 세트” 상품제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 “Happy Birthday 세트” , “기분 좋은 디저트 세트”, “너만큼 완벽한 디저트 세트” 모두 제공",
          appliedPlan: "AP-006정액SB50요금제",	
          optionCode:	"COZ63",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"Y",
          benefit:	"메가박스 관람권 3매 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5매제공",
          appliedPlan: "AP-006정액MB50요금제",	
          optionCode:	"COZ73",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"Y",
          benefit:	"올리브영 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5만원 상품권 제공",
          appliedPlan: "AP-006정액OY50요금제",	
          optionCode:	"COZ70",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"Y",
          benefit:	"“포테이토 오리지널(L)+콜라1.25L”, 상품 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 “포테이토 오리지널(L)+콜라1.25L”, “페퍼로니 오리지널(M)+콜라1.25L” 상품 모두 제공",
          appliedPlan: "AP-006정액PZ50요금제",	
          optionCode:	"COZ67",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5만원 상품권 제공",
          appliedPlan: "AP-006정액HC50요금제",	
          optionCode:	"COZ45",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5만원 상품권 제공",
          appliedPlan: "AP-006정액HC50요금제",	
          optionCode:	"COZ45",
          planInfo:	""
        },
        {
          plan:	"0065",
          type:	"006",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"Y",
          benefit:	"깐부치킨 3만원 디지털 상품권 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700,006<br>미사용시 5만원 상품권 제공",
          appliedPlan: "AP-006정액CH50요금제",	
          optionCode:	"COZ65",
          planInfo:	""
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액EM10요금제(SKT O/B)",	
          optionCode:	"COZ83",
          planInfo:	""
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액GS10요금제(SKT O/B)",	
          optionCode:	"COZ75",
          planInfo:	""
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액LM10요금제(SKT O/B)",	
          optionCode:	"COZ87",
          planInfo:	""
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액HC10요금제(SKT O/B)",	
          optionCode:	"COZ91",
          planInfo:	""
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액SK10요금제(SKT O/B)",	
          optionCode:	"COZ79",
          planInfo:	""
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"카페라떼 Tall 1잔 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“카페라떼 커플 세트” 제공",
          appliedPlan: "AP-정액SB10요금제(SKT O/B)",	
          optionCode:	"COZ95",
          planInfo:	""
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"",
          planInfo:	"0072S"
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0072S"
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0073S"
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 5천원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액HC10요금제(SKT O/B)",	
          optionCode:	"COZ91",
          planInfo:	""
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 5천원 제공",
          unusedBenefit: "전월21일부터 당월20일까지 국제전화 00700 미사용시<br>1만원 상품권 제공",
          appliedPlan: "AP-정액HC10요금제(SKT O/B)",	
          optionCode:	"COZ91",
          planInfo:	""
        },
        {
          plan:	"0071S",
          type:	"SKTOB",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0073S"
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액EM20요금제(SKT O/B)",	
          optionCode:	"COZ84",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액GS20요금제(SKT O/B)",	
          optionCode:	"COZ76",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액LM20요금제(SKT O/B)",	
          optionCode:	"COZ88",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액HC20요금제(SKT O/B)",	
          optionCode:	"COZ92",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액SK20요금제(SKT O/B)",	
          optionCode:	"COZ80",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"“카페라떼 커플세트” 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“우리 흑미 카스텔라 & 라떼 세트” 제공",
          appliedPlan: "AP-정액SB20요금제(SKT O/B)",	
          optionCode:	"COZ96",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"Y",
          benefit:	"메가박스 관람권 1매 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2매제공",
          appliedPlan: "AP-정액MB20요금제(SKT O/B)",	
          optionCode:	"COZA7",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"Y",
          benefit:	"올리브영 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액OY20요금제(SKT O/B)",	
          optionCode:	"COZA4",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0073S"
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액HC20요금제(SKT O/B)",	
          optionCode:	"COZ92",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 1만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>2만원 상품권 제공",
          appliedPlan: "AP-정액HC20요금제(SKT O/B)",	
          optionCode:	"COZ92",
          planInfo:	""
        },
        {
          plan:	"0072S",
          type:	"SKTOB",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"N",
          benefit:	"",
          unusedBenefit:	"",
          appliedPlan: "",	
          optionCode:	"N",
          planInfo:	"0073S"
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액EM30요금제(SKT O/B)",	
          optionCode:	"COZ85",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액GS30요금제(SKT O/B)",	
          optionCode:	"COZ77",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액LM30요금제(SKT O/B)",	
          optionCode:	"COZ89",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액HC30요금제(SKT O/B)",	
          optionCode:	"COZ93",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액SK30요금제(SKT O/B)",	
          optionCode:	"COZ81",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"“너만큼 완벽한 디저트 세트” 상품제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“For Your Lucky 7 Set” , “너만큼 완벽한 디저트 세트” 모두 제공",
          appliedPlan: "AP-정액SB30요금제(SKT O/B)",	
          optionCode:	"COZ97",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"Y",
          benefit:	"메가박스 관람권 2매 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3매 제공",
          appliedPlan: "AP-정액MB30요금제(SKT O/B)",	
          optionCode:	"COZA8",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"Y",
          benefit:	"올리브영 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액OY30요금제(SKT O/B)",	
          optionCode:	"COZA5",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"Y",
          benefit:	"“페퍼로니 오리지널(M)+콜라1.25L”상품 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“포테이토 오리지널(L)+콜라1.25L” 상품 제공",
          appliedPlan: "AP-정액PZ30요금제(SKT O/B)",	
          optionCode:	"COZA2",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액HC30요금제(SKT O/B)",	
          optionCode:	"COZ93",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 2만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액HC30요금제(SKT O/B)",	
          optionCode:	"COZ93",
          planInfo:	""
        },
        {
          plan:	"0073S",
          type:	"SKTOB",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"Y",
          benefit:	"깐부치킨 2만원 디지털 상품권 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>3만원 상품권 제공",
          appliedPlan: "AP-정액CH30요금제(SKT O/B)",	
          optionCode:	"COZ99",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "신세계백화점",
          btnImage: "/content/images/btn_logo_brand01.png",
          popupImage: '/content/images/img_brand01.png',
          status:	"Y",
          benefit:	"신세계백화점 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액EM50요금제(SKT O/B)",	
          optionCode:	"COZ86",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "GS편의점",
          btnImage: "/content/images/btn_logo_brand02.png",
          popupImage: '/content/images/img_brand02.png',
          status:	"Y",
          benefit:	"GS편의점 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액GS50요금제(SKT O/B)",	
          optionCode:	"COZ78",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "롯데마트",
          btnImage: "/content/images/btn_logo_brand03.png",
          popupImage: '/content/images/img_brand03.png',
          status:	"Y",
          benefit:	"롯데마트 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액LM50요금제(SKT O/B)",	
          optionCode:	"COZ90",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "던킨도너츠",
          btnImage: "/content/images/btn_logo_brand04.png",
          popupImage: '/content/images/img_brand04.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액HC50요금제(SKT O/B)",	
          optionCode:	"COZ94",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "SK주유권",
          btnImage: "/content/images/btn_logo_brand05.png",
          popupImage: '/content/images/img_brand05.png',
          status:	"Y",
          benefit:	"SK주유 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액SK50요금제(SKT O/B)",	
          optionCode:	"COZ82",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "스타벅스",
          btnImage: "/content/images/btn_logo_brand06.png",
          popupImage: '/content/images/img_brand06.png',
          status:	"Y",
          benefit:	"“For Your Lucky 7 Set”, “너만큼 완벽한 디저트 세트” 상품제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“Happy Birthday 세트” , “기분 좋은 디저트 세트”, “너만큼 완벽한 디저트 세트” 모두 제공",
          appliedPlan: "AP-정액SB50요금제(SKT O/B)",	
          optionCode:	"COZ98",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "메가박스",
          btnImage: "/content/images/btn_logo_brand07.png",
          popupImage: '/content/images/img_brand07.png',
          status:	"Y",
          benefit:	"메가박스 관람권 3매 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5매제공",
          appliedPlan: "AP-정액MB50요금제(SKT O/B)",	
          optionCode:	"COZA9",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "올리브영",
          btnImage: "/content/images/btn_logo_brand08.png",
          popupImage: '/content/images/img_brand08.png',
          status:	"Y",
          benefit:	"올리브영 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액OY50요금제(SKT O/B)",	
          optionCode:	"COZA6",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "도미노피자",
          btnImage: "/content/images/btn_logo_brand09.png",
          popupImage: '/content/images/img_brand09.png',
          status:	"Y",
          benefit:	"“포테이토 오리지널(L)+콜라1.25L”, 상품 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>“포테이토 오리지널(L)+콜라1.25L”, “페퍼로니 오리지널(M)+콜라1.25L” 상품 모두 제공",
          appliedPlan: "AP-정액PZ50요금제(SKT O/B)",	
          optionCode:	"COZA3",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "파리바게트",
          btnImage: "/content/images/btn_logo_brand10.png",
          popupImage: '/content/images/img_brand10.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액HC50요금제(SKT O/B)",	
          optionCode:	"COZ94",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "베스킨라빈스",
          btnImage: "/content/images/btn_logo_brand11.png",
          popupImage: '/content/images/img_brand11.png',
          status:	"Y",
          benefit:	"해피콘 모바일 상품권 3만원 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액HC50요금제(SKT O/B)",	
          optionCode:	"COZ94",
          planInfo:	""
        },
        {
          plan:	"0075S",
          type:	"SKTOB",
          product: "깐부치킨",
          btnImage: "/content/images/btn_logo_brand12.png",
          popupImage: '/content/images/img_brand12.png',
          status:	"Y",
          benefit:	"깐부치킨 3만원 디지털 상품권 제공",
          unusedBenefit:	"전월21일부터 당월20일까지 국제전화 00700 미사용시<br>5만원 상품권 제공",
          appliedPlan: "AP-정액CH50요금제(SKT O/B)",	
          optionCode:	"COZA1",
          planInfo:	""
        }
      ],
      planData: {},
      benefitList: [],
      popup: {
        benefitChange: {
          isVisible: false
        },
        unableChange: {
          isVisible: false
        },
        privacy: {
          isVisible: false
        },
        changeComplete: {
          isVisible: false
        }
      },
      selectedBenefit: {},
      providePlanInfo: {},
      agreePrivacy: false,
      telNumber: {
        first: '010'
      },
      certNumber: '',
      ticket: '',
      isSmsCert: false,
      plan: '',
      telMask: '',
      chrg_cd: '',
      optionBtn: '',
      optionProd: '',    
    }
  },
  computed: {
    tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    },
    telMaskNo(){
      return this.$store.getters.TELMASK
    },
    ticketNo(){
      return this.$store.getters.TICKET
    },
    btnImage(){
      return this.$store.getters.BTNIMAGE
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if(this.callbackAlert.code === '0002' || this.callbackAlert.code ==='E027' || this.callbackAlert.code ==='E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code ==='E011' || this.callbackAlert.code ==='E016' || this.callbackAlert.code ==='E017' || this.callbackAlert.code ==='E018' || this.callbackAlert.code ==='E021' || this.callbackAlert.code ==='E022'){
          this.resetPage()
        }
        if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
          this.telNumber = {
            first: '010',
            middle: '',
            last: ''
          },
          this.certNumber = '',
          this.ticket = ''
          this.ticket = ''
        }
        if (this.callbackAlert.code === 'E001') { // 인증시간 초과
          this.certNumber = ''
          this.ticket = ''
        }
      }
    }
},
  created () {
    this.callingPlan.forEach(item => {
      if(this.$route.query.plan === item.plan) {
        this.planData = item
      }
    })
    this.callingBenefit.forEach(item => {
      if(this.$route.query.plan === item.plan) {
        this.benefitList.push(item)
      }
    })
    this.telMask = this.telMaskNo
    this.ticket = this.ticketNo
    this.optionBtn = this.btnImage
  },
  mounted () {
  },
  methods: {
    alertPopupHandler() {
      this.alertPopup.visible = false
      this.alertPopup.message = ''
      if (this.alertPopup.target) {
        this.$refs[this.alertPopup.target].focus()
      }
      this.alertPopup.target = ''
    },
    showAlertPopup(message) {
      this.alertPopup.message = message
      this.alertPopup.visible = true
    },
    
    optionPopup(event) {
      this.benefitList.forEach(item => {
        if(item.product === event.target.dataset.product) {
          this.selectedBenefit = item
        }
      })

      if(event.target.dataset.status === 'Y') {
        this.popup.benefitChange.isVisible = true
      } else {
        this.popup.unableChange.isVisible = true
        this.callingPlan.forEach(item => {
          if(item.plan === this.selectedBenefit.planInfo) {
            this.providePlanInfo = item
          }
        })
      }
    },
    // 옵션 혜택 변경 
    checkPrivacy() {
      if(!this.agreePrivacy) {
        this.$store.commit('ON_ALERT_POPUP', { message: '개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 신청이 가능합니다.'})
        return false
      } else {
        return true
      }
    },
    checkPhoneNumber() {
      if (!this.telNumber.middle) {
        this.alertPopup.target = 'telNumberMiddle'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.last) {
        this.alertPopup.target = 'telNumberLast'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else {
        return true
      }
    },
    checkSMSCert() {
      if(!this.isSmsCert) {
        this.showAlertPopup('휴대폰 인증을 해주세요.')
        return false
      } else {
        return true
      }
    },
    checkAuthNumber() {
      if (!this.certNumber) {
        this.alertPopup.target = 'certNumber'
        this.showAlertPopup('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    // SMS인증하기
    requestSMSCert() {
      if (this.checkPhoneNumber()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.tel_no })
          .then(response => {
            if (response.data.sms_ticket) {
              this.$store.commit('ON_ALERT_POPUP', { message: '인증번호가 정상적으로 발송되었습니다.'})
              this.ticket = response.data.sms_ticket
            }
          })
      }
    },
    // 인증번호 확인 & 옵션 변경 화면 생성
    requestCert() {
      if (this.checkAuthNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.certNumber, sms_ticket: this.ticket })
          .then(response => {
            if (response.data.result_cd === '0001') {
              this.isSmsCert = true
              this.$store.commit('ON_ALERT_POPUP', { message: '인증번호가 확인되었습니다. 감사합니다.'})
              api.ReqApInfo({ sms_ticket: this.ticket })
                .then(response => {
                  if (response.data.result_cd === '0007') {
                    this.chrg_cd = response.data.chrg_cd
                    this.plan = response.data.chrg_sys_cd
                    this.telMask = response.data.svc_tel_no
                    this.$store.commit('SET_TEL_MASK', this.telMask)
                    this.$store.commit('SET_TICKET_NO', this.ticket)
                    this.$router.push({ path: 'all_pass_option_change', query: { plan: `${ this.plan }` }})
                    this.callingPlan.forEach(item => {
                      if(this.$route.query.plan === item.plan) {
                        this.planData = item
                      }
                    })
                    this.benefitList = []
                    this.callingBenefit.forEach(item => {
                      if(this.$route.query.plan === item.plan) {
                        this.benefitList.push(item)
                      }
                    })
                    this.callingBenefit.forEach(item => {
                      if(this.chrg_cd === item.optionCode) {
                        this.optionBtn = item.btnImage
                        this.optionProd = item.benefit
                      }
                    })
                    this.$store.commit('SET_BTN_IMAGE', this.optionBtn)
                  }
                })
            }
          })
      }
    },
    // 혜택변경
    requestFinal() {
      if (this.checkPrivacy()) {
        api.EventPostReqEvent({ 
          sms_ticket: this.ticket,
          event_no: 'ZZ000000000000006090',
          add_info: this.selectedBenefit.optionCode
        })
          .then(response => {
            if(response.data.result_cd === '0002') {
             this.popup.changeComplete.isVisible = true
             this.$store.commit('SET_BTN_IMAGE', this.btnImage)
            }
          })
         
      }
    },
    resetPage(){
      this.agreePrivacy = false,
      this.telNumber = {
        first: '010',
        middle: '',
        last: ''
      }
      // this.certNumber = '',
      // this.ticket = '',
      // this.isSmsCert = false
    },
    initPage(){
      this.$router.push({ path: 'all_pass_option_change', query: {}})
    }                    
  }
}
</script>

<style lang="scss" scoped>
.pop_wrap10 {
  position: fixed;
  overflow-y: auto;
  height: 100%;
}
.pop_complete {
  height: 100%;
}
.top.navy {
  padding-top: 325px;
  background-image: url('/content/images/bg_event_allpass_change.jpg');
  background-size: 100% auto;
  &.before_auth {
    padding: 330px 0 0;
    text-align: left;
    h1 {
      margin-bottom: 200px;
    }
    .form-area.type02 {
      margin: 0 25px;
    }
  }
  .user_phone_number {
    color: #fde62f;
    font-size: 100px;
    font-weight: 700;
  }
  .text {
    color: #fcfcfc;
    font-size: 95px;
    font-weight: 700;
    line-height: 1.2;
  }
  .current_selected {
    position: relative;
    z-index: 1;
    text-align: left;
    margin-top: 55px;
    margin-bottom: -250px;
    &:after {
      content: "";
      position: absolute;
      width: 180px;
      height: 180px;
      border: 2px solid #e5e4ea;
      border-radius: 50%;
      left: 12px;
      top: 13px;
    }
    img {
      width: 210px;
    }
  }
  .bill {
    position: relative;
    min-height: 632px;
    margin-top: 0;
    padding-top: 180px;
    background-image: url('/content/images/bg_bill_change.png');
    background-size: 100% auto;
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 50px;
      background: url('/content/images/bg_bill_change_bottom.png') no-repeat 0 bottom / 100% auto;
    }
    table {
      position: relative;
      z-index: 1;
      width: 80%;
      margin: 0 auto;
      th {
        width: 30%;
        padding: 20px 0 20px 25px;
        & span:before {
          top: 11px;
        }
        span { font-weight: 700; }
      }
      td {
        width: 70%;
        padding: 20px 0 20px 36px;
        span { color: #5936ad; line-height: 1.2; font-weight: 700; }
      }
    }
  }
  .button-list {
    ul { margin-top: 70px; }
  }
}

@media (max-width: 1023px) {
    .pop_wrap10 {
    left: 0;
    transform: translateX(0);
  }
  .top.navy {
    padding: 30.5% 10px 40px 8px;
    &.before_auth {
      padding: 29.5% 0 0;
      h1 {
        margin-bottom: 40px;
        img {
          margin-top: 0;
        }
      }
      .form-area.type02 {
        margin: 0;
      }
    }
    .user_phone_number {
      font-size: 9vw;
    }
    .text {
      color: #fcfcfc;
      font-size: 8vw;
    }
    .current_selected {
      margin-top: 7%;
      margin-bottom: -24%;
      &:after {
        width: 16.07%;
        height: 86%;
        left: 1.2%;
        top: 6.5%;
      }
      img {
        width: 18.75%;
      }
    }
    .bill {
      min-height: auto;
      padding-top: 21%;
      &:after {
        bottom: -5px;
      }
      table {
        width: 100%;
        th {
          width: 30%;
          padding: 5px 0 5px 10px;
          & span:before {
            top: 11px;
          }
        }
        td {
          width: 80%;
          padding: 5px 10px;
        }
      }
    }
    .button-list {
      ul {
        margin-top: 30px;
      }
    }
  }
}
@media (max-width: 500px) {
  .top.navy {
    .bill {
      &:after {
        height: 140px;
      }
    }
  }
}
</style>