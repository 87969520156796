<template>
  <div id="TMEMBERSHIP_NEW_CANCEL">
    <div class="event-layer">
      <div
        v-if="cancel.isVisible"
        class="tmember-popup"
      >
        <div class="tpop-header">
          제휴 혜택 해지
        </div>
        <!-- container -->
        <div class="container">
          <div class="bg-grey center">
            제휴 혜택을 해지할 경우 해당 연도 재신청은 불가하며,<br>
            해지 시 00700 요금제가 미가입된 상태인 표준요금제로 변경됩니다.
          </div>
          <div class="dotted-bot-box center">
            제휴 혜택 신청 및 해지 관련 자세한 문의사항은<br>
            <strong>SK국제전화 00700 고객센터(1599-0070)</strong>를 <br>이용해주시길 바랍니다.
          </div>
          <div class="agree-msg">
            동의 하시겠습니까?
          </div>
          <div class="popup-btn-box">
            <a
              class="btn-small"
              @click="nextStep"
            >
              <img
                class="next-button"
                style="margin-right: 7px;"
                src="/content/images/tmember_bu_check.jpg"
                alt="확인"
              >
            </a>
            <a
              class="btn-small ml8"
              @click="close"
            >
              <img
                class="cancel-button"
                src="/content/images/tmember_bu_cancel.jpg"
                alt="취소"
              >
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="check.isVisible"
        class="tmember-popup"
      >
        <div class="tpop-header">
          제휴 혜택 해지
        </div>
        <!-- container -->
        <div class="container">
          <div class="bg-grey center">
            고객님의 T멤버십 OTB 번호를 입력해주세요.<br>
            중도 해지 시, 최초 신청일 기준 1년 이내 재신청이 불가합니다.
          </div>
          <div class="table_type01">
            <div class="table_inner">
              <dl>
                <dt>휴대폰번호</dt>
                <dd>
                  <div class="border-top-n">
                    <div class="phone-box">
                      <div class="sel-box">
                        <label 
                          for="call_select01"
                          aria-hidden="true"
                        >
                          010
                        </label>
                        <select
                          id="call_select01"
                          ref="telNumberFirst"
                          v-model="telNumber.first"
                          name="i_sHpNo1"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                      </div>
                      <span class="txt_hyphen">-</span>
                      <div class="col">
                        <input 
                          ref="telNumberMiddle" 
                          v-model="telNumber.middle" 
                          name="i_sHpNo2" 
                          title="전화번호" 
                          type="text" 
                          maxlength="4" 
                          pattern="[0-9]*"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <span class="txt_hyphen">-</span>
                      <div class="col">
                        <input 
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3" 
                          title="전화번호" 
                          type="text" 
                          maxlength="4" 
                          pattern="[0-9]*" 
                          inputmode="numeric" 
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div> 
                    </div>
                    <div class="btn-parent">
                      <a
                        class="btn btn_sendnum"
                        @click="requestSMSCert"
                      >SMS인증하기</a>
                    </div>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>인증번호 입력</dt>
                <dd>
                  <input
                    id="AsianaMemberNo" 
                    ref="certNumber"
                    v-model="certNumber" 
                    type="text"  
                    name="i_sAsianaMemberNo" 
                    title="인증번호 입력" 
                    class="onlyNumber w100" 
                    maxlength="9" 
                    pattern="[0-9]*"
                    inputmode="numeric"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  >
                  <div class="btn-parent">
                    <a
                      class="btn btn_sendnum"
                      @click="requestCert"
                    >인증번호 확인</a>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>OTB 번호</dt>
                <dd class="inputs_wrap">
                  <input
                    ref="otbNumberOne"
                    v-model="otbNumber.one"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterOtbNo"
                  >
                  <span class="txt_hyphen"><br>-</span>
                  <input
                    ref="otbNumberTwo"
                    v-model="otbNumber.two"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterOtbNo"
                  >
                  <span class="txt_hyphen"><br>-</span>
                  <input
                    ref="otbNumberThree"
                    v-model="otbNumber.three"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterOtbNo"
                  >
                  <span class="txt_hyphen"><br>-</span>
                  <input
                    ref="otbNumberFour"
                    v-model="otbNumber.four"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                  >
                </dd>
              </dl>
              <!-- <dl class="dotted-bot-box">
                <dt>PIN 번호</dt>
                <dd class="inputs_wrap">
                  <input
                    ref="pinNumberOne"
                    v-model="pinNumber.one"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberTwo"
                    v-model="pinNumber.two"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberThree"
                    v-model="pinNumber.three"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberFour"
                    v-model="pinNumber.four"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberFive"
                    v-model="pinNumber.five"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterPinNo"
                  >
                  <input
                    ref="pinNumberSix"
                    v-model="pinNumber.six"
                    type="password"
                    maxlength="1"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                  >
                </dd>
              </dl> -->
            </div>
          </div>
          
          <div class="dotted-bot-box center">
            제휴 혜택 신청 및 해지 관련 자세한 문의사항은<br>
            SK국제전화 00700 <strong>고객센터(1599-0070)</strong>를 이용해주시기 바랍니다
          </div>
          <div class="popup-btn-box">
            <a
              class="btn-small"
              @click="requestFinal"
            >
              <img
                class="next-button"
                style="margin-right: 7px;"
                src="/content/images/tmember_bu_check.jpg"
                alt="확인"
              >
            </a>
            <a
              class="btn-small ml8"
              @click="close"
            >
              <img
                class="cancel-button"
                src="/content/images/tmember_bu_cancel.jpg"
                alt="취소"
              >
            </a>
          </div>
        </div>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type2="'btn_type02'"
      @click="alertPopup.visible = false"
      @close="alertPopup.visible = false"
    >
      <div class="pop_row">
        중도 해지한 이력이 있습니다.<br>
        <span class="txt_point01">{{ use_end_dt }}</span> 이후에 재신청이 가능합니다.
      </div>
    </Popup>
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'

export default {
  name: 'TmembershipNewCancel',
  components: {
    Popup
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      alertPopup: {
        visible: false
      },
      telNumber: {
        first: '010'
      },
      certNumber: '',
      otbNumber: {},
      pinNumber: {},
      cancel: {
        isVisible: true
      },
      check: {
        isVisible: false
      },
      ticket: '',
      use_end_dt: ''
    }
  },
  computed: {
    otb_no() {
      return `${this.otbNumber.one}${this.otbNumber.two}${this.otbNumber.three}${this.otbNumber.four}`  
    },
    // pin_no() {
    //   return `${this.pinNumber.one}${this.pinNumber.two}${this.pinNumber.three}${this.pinNumber.four}${this.pinNumber.five}${this.pinNumber.six}`  
    // },
    tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },    
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if (this.callbackAlert.code === 'E027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code === 'E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E032' || this.callbackAlert.code === 'E033' || this.callbackAlert.code === 'E035' || this.callbackAlert.code === 'E036' || this.callbackAlert.code === 'E039' || this.callbackAlert.code === 'E040') { 
          this.resetPage()
        }
        if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
          this.telNumber = {
            first: '010',
            middle: '',
            last: ''
          },
          this.certNumber = '',
          this.ticket = ''
        }
        if (this.callbackAlert.code === 'E001') { // 인증시간 초과
          this.certNumber = ''
          this.ticket = ''
        }
      }
    }  
  },
  created () {},
  mounted () {
  },
  methods: {
    close(){
      this.$emit('click')
    },
    nextStep() {
      this.cancel.isVisible = false
      this.check.isVisible = true
    },
    enterOtbNo(event) {
      if(event.target.value.length > event.target.maxLength -1) {
        event.target.nextSibling.nextSibling.focus()
      }
    },
    // enterPinNo(event) {
    //   if(event.target.value.length > event.target.maxLength -1) {
    //     event.target.nextSibling.focus()
    //   }
    // },
    checkPhoneNumber() {
      if (!this.telNumber.middle) {
        this.$refs.telNumberMiddle.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.last) {
        this.$refs.telNumberLast.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        alert('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else {
        return true
      }
    },
    checkAuthNumber() {
      if (!this.certNumber) {
        this.$refs.certNumber.focus()
        alert('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkOtbNumber() {
      if (!this.otbNumber.one) {
        this.$refs.otbNumberOne.focus()
        alert('OTB번호를 입력해 주세요.')
        return false
      } else if (!this.otbNumber.two) {
        this.$refs.otbNumberTwo.focus()
        alert('OTB번호를 입력해 주세요.')
        return false
      } else if (!this.otbNumber.three) {
        this.$refs.otbNumberThree.focus()
        alert('OTB번호를 입력해 주세요.')
        return false
      } else if (!this.otbNumber.four) {
        this.$refs.otbNumberFour.focus()
        alert('OTB번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    //   checkPinNumber() {
    //   if (!this.pinNumber.one) {
    //     this.$refs.pinNumberOne.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.two) {
    //     this.$refs.pinNumberTwo.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.three) {
    //     this.$refs.pinNumberThree.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.four) {
    //     this.$refs.pinNumberFour.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.five) {
    //     this.$refs.pinNumberFive.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else if (!this.pinNumber.six) {
    //     this.$refs.pinNumberSix.focus()
    //     alert('PIN번호를 입력해 주세요.')
    //     return false
    //   } else {
    //     return true
    //   }
    // },
    // SMS인증하기
    requestSMSCert() {
      if (this.checkPhoneNumber()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.tel_no })
          .then(response => {
            if (response.data.sms_ticket) {
              alert('인증번호가 정상적으로 발송되었습니다.')
              this.ticket = response.data.sms_ticket
            }
          })
      }
    },
    // 인증번호 확인
    requestCert() {
      if (this.checkAuthNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.certNumber, sms_ticket: this.ticket })
          .then(response => {
            if (response.data.result_cd === '0001') {
              alert('인증번호가 확인되었습니다. 감사합니다.')
            }
          })
      }
    },
    // 확인
    requestFinal() {
      if (this.checkPhoneNumber() && this.checkAuthNumber() && this.checkOtbNumber()) {
        api.EventPostTmemNewTerm({ 
          sms_ticket: this.ticket,
          otb: this.otb_no,
        })
        .then(response => {
          if (response.data.result_cd === '0005') {
            this.close()
          }
        })
      }
    },
    resetPage(){
      this.telNumber = {
        first: '010',
        middle: '',
        last: ''
      },
      this.certNumber = '',
      this.otbNumber = {
        one: '',
        two: '',
        three: '',
        four: ''
      },
      // this.pinNumber = {
      //   one: '',
      //   two: '',
      //   three: '',
      //   four: '',
      //   five: '',
      //   six: ''
      // },
      this.ticket = ''
    }
  }
}
</script>

<style lang="scss" scoped>
#TMEMBERSHIP_NEW_CANCEL {
  position: fixed;
  width: 432px;
  height: 100%;
  overflow-y: auto;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
}
.event-layer .inputs {
  text-align: center;
  margin-left: 1%
}
.event-layer .inputs:first-child {
  margin-left: 0;
}
.agree-msg {
  padding: 30px 0 0;
  color: #000;
  font-size: 19px;
  font-weight: 600;
  text-align: center;
}
@media (max-width: 1023px) {
  #TMEMBERSHIP_NEW_CANCEL { 
    width: 100%;
  }
}
.tmember-popup .table_type01 .inputs_wrap {
  display: flex;
  align-content: space-between;
}
.tmember-popup .inputs_wrap input {
  width: 100%;
  margin-left: 3px;
  padding: 0;
  &:first-child {
    margin-left: 0;
  }
}
.tmember-popup .agreeBox label {
  margin-left: 3px;
}
</style>
