<template>
  <div id="INTER_SMS">
    <Header />
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />

      <div id="contents">
        <div class="sub_header bg01-01">
          <h3 class="title">
            국제SMS 00700
          </h3>
          <p class="txt">
            국제전화 00700으로 국제 문자메시지(SMS)도 간편하게!<br>해외에 계신 분들과 더 자주 사랑과 응원메시지를 주고 받으세요.
          </p>
          <img
            src="/content/images/sub_header_inter01-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- to개발 / 2021-03-05 tab_type01 추가 -->
        <div class="tab_type01">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li>
              <router-link to="/view/business/inter_call">
                국제전화 00700
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/business/inter_sms">
                국제SMS 00700
              </router-link>
            </li>
          </ul>
        </div>
        <!-- end / to개발 / 2021-03-05 tab_type01 추가 -->

        <!-- inter -->
        <div class="inter mb0">
          <div class="advice_list white">
            <h4>이런 고객님이 사용하면 좋아요!</h4>
            <p class="advice_list_text">
              <span class="hashtag"># 국제 업무 기업</span><span class="hashtag"># 해외가족/친구</span><span class="hashtag"># 해외여행</span>
            </p>
            <div class="advice_scroll">
              <div class="advice_scroll_inner">
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter01.png"
                        alt=""
                      >
                    </div>
                    <p>해외 가족/지인과<br> 간단한 안부인사 전하는 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter07.png"
                        alt=""
                      >
                    </div>
                    <p>해외 비즈니스를 하는 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter23.png"
                        alt=""
                      >
                    </div>
                    <p>호텔/투어 등 간단한 확인이 필요한 여행객</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="advice_list">
            <h4>거부할 수 없는 매력은<br class="m_hide"> 무엇인가요?</h4>
            <div class="advice_scroll">
              <div class="advice_scroll_inner">
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub32-1.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>국제전화와 동일한<br> 고품질 통화 제공</dt>
                      <dd>국제전화 00700과 동일한 최고의 국제전화<br> 품질 제공으로 세계 200여 개국에 마음 놓고 연락하세요.<br> (국제 SMS는 최소 70Byte 제공,<br> 이용요금 165원/건, VAT 포함)</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub30.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>별도의 가입절차 없이<br> 즉시 이용가능</dt>
                      <dd>국제 SMS 00700은 국내 이동통신사업자에<br> 가입되어 있는 휴대폰이라면 별도의 가입절차 없이<br> 즉시 국제 SMS 전송이 가능합니다.<br> (한글, 영문, 숫자, 특수기호 등 사용가능. 일부 국가 제외)</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub31.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>불편함을 덜어 주는<br> 합산 요금안내</dt>
                      <dd>국제 SMS 사용요금을 별도로 납부할 필요 없이<br> 휴대폰 이용요금안내서에 함께 안내되어<br> 더욱 편리하게 이용할 수 있습니다.</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="use_list_area">
            <div class="use_list">
              <h4>00700 로 쉽게 문자 보내는 방법</h4>
              <p>한국에서 미국 LA 지역의 <span>휴대폰으로 문자를 보내는 경우</span></p>
              <ul class="four">
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter18.png"
                      alt=""
                    >
                  </div>
                  <p>SMS 작성</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>00700 국제전화 식별번호</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter19.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호(미국 : 1)</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter32.png"
                      alt=""
                    >
                  </div>
                  <p>상대방 휴대폰 번호<br class="m_hide">(41-123-4567)<br><span><span class="icon_star">*</span>휴대폰 번호는 0번 제외</span></p>
                </li>
              </ul>
            </div>
          </div>

          <div class="payment_area">
            <h4 class="title">
              이용금액
            </h4>
            <p class="txt1">
              이용금액 : <strong>165원/건(VAT 포함)</strong>
            </p>
            <p class="txt_em">
              <span class="icon_star">*</span>국제 SMS 사용금액은 이동통신사업자의 요금안내서에 합산되어 안내됩니다.
            </p>
          </div>

          <div class="notice_area">
            <h4 class="title">
              <span class="icon_mark"><img
                src="/content/images/icon_mark02.png"
                alt="주의사항"
              ></span>국제SMS 00700 사용 전 <br class="m_br">꼭 읽어보세요
            </h4>
            <ul>
              <li><span class="icon_star">*</span>이용하는 한국 이동통신사 환경에 따라 국제 SMS  발신  가능한 Byte가 다르게 설정되어 있습니다. </li>
              <li><span class="icon_star">*</span>국제 SMS 한 건당 최소 70Byte~최대 140Byte의 SMS를 전송 가능하며, 이동통신사에서 설정한 Byte 수를 초과한 컨텐츠는 삭제될 수 있습니다.</li>
              <li><span class="icon_star">*</span>국제 SMS 이용 시 발신 가능한 자세한 Byte  수 문의는 가입하신 이동통신사 고객센터를 통해 문의 바랍니다.</li>
            </ul>
          </div>
        </div>
        <!-- //inter -->
      </div>
      <!--// 컨텐츠 -->
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl'

export default {
  name: 'InterSms',
  metaInfo: {
    title: '국제SMS 00700 | 국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
