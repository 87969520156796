<template>
  <div id="TMEMBERSHIP_CANCEL">
    <div class="event-layer">
      <div
        v-if="cancel.isVisible"
        class="tmember-popup"
      >
        <div class="tpop-header">
          00700 제휴할인 해지
        </div>
        <div class="container">
          <div class="bg-grey center">
            T 멤버십 00700 가입 시 차감된 할인 한도가<br>
            재생성 되지는 않습니다.
          </div>
          <div class="dotted-bot-box center">
            00700 제휴할인에 대한 자세한 문의사항은<br>
            <strong>고객센터(1599-0070)</strong>를 이용해주시기 바랍니다
          </div>
          <div class="popup-btn-box">
            <a
              @click="nextStep"
            >
              <img
                class="next-button"
                style="margin-right: 7px;width: auto"
                src="/content/images/tmember_bu_check.jpg"
                alt="확인"
              >
            </a>
          </div>
        </div>
      </div>
      <div
        v-if="check.isVisible"
        class="tmember-popup"
      >
        <div class="tpop-header">
          T membership 확인
        </div>
        <div class="container">
          <div class="bg-grey center">
            본인의 T membership 카드의 번호를 입력해주세요.<br>
            중도 해지 시, 최초 가입일 기준 1년 이내 재가입이 불가합니다.
          </div>

          <div class="table_type01">
            <div class="table_inner">
              <dl>
                <dt>휴대폰번호</dt>
                <dd>
                  <div class="border-top-n">
                    <div class="phone-box">
                      <div class="sel-box">
                        <label 
                          for="call_select01"
                          aria-hidden="true"
                        >
                          010
                        </label>
                        <select
                          id="call_select01"
                          ref="telNumberFirst"
                          v-model="telNumber.first"
                          name="i_sHpNo1"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                      </div>
                      <span class="txt_hyphen">-</span>
                      <div class="col">
                        <input 
                          ref="telNumberMiddle" 
                          v-model="telNumber.middle" 
                          name="i_sHpNo2" 
                          title="전화번호" 
                          type="text" 
                          maxlength="4" 
                          pattern="[0-9]*" 
                          inputmode="numeric" 
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <span class="txt_hyphen">-</span>
                      <div class="col">
                        <input 
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3" 
                          title="전화번호" 
                          type="text" 
                          maxlength="4" 
                          pattern="[0-9]*" 
                          inputmode="numeric" 
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div> 
                    </div>
                    <div class="btn-parent">
                      <a
                        class="btn btn_sendnum"
                        @click="requestSMSCert"
                      >SMS인증하기</a>
                    </div>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>인증번호 입력</dt>
                <dd>
                  <input
                    id="AsianaMemberNo" 
                    ref="certNumber"
                    v-model="certNumber" 
                    type="text"
                    name="i_sAsianaMemberNo" 
                    title="인증번호 입력"  
                    class="onlyNumber w100" 
                    maxlength="9" 
                    pattern="[0-9]*" 
                    inputmode="numeric" 
                  >
                  <div class="btn-parent">
                    <a
                      class="btn btn_sendnum"
                      @click="requestCert"
                    >인증번호 확인</a>
                  </div>
                </dd>
              </dl>
              <dl class="dotted-bot-box">
                <dt>T맴버십 카드번호</dt>
                <dd class="inputs_wrap">
                  <input
                    ref="cardNumberOne"
                    v-model="cardNumber.one"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterCardNo"
                  >
                  <span class="txt_hyphen">-</span>
                  <input
                    ref="cardNumberTwo"
                    v-model="cardNumber.two"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterCardNo"
                  >
                  <span class="txt_hyphen">-</span>
                  <input
                    ref="cardNumberThree"
                    v-model="cardNumber.three"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                    @keyup="enterCardNo"
                  >
                  <span class="txt_hyphen">-</span>
                  <input
                    ref="cardNumberFour"
                    v-model="cardNumber.four"
                    type="text"
                    maxlength="4"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    pattern="\d*"
                  >
                </dd>
              </dl>
            </div>
          </div>
          

          <div class="dotted-bot-box center">
            00700 제휴할인에 대한 자세한 문의사항은<br>
            <strong>고객센터(1599-0070)</strong>를 이용해주시기 바랍니다
          </div>
          <div class="popup-btn-box">
            <a
              class="btn-small"
              @click="requestFinal"
            >
              <img
                class="next-button"
                style="margin-right: 7px;"
                src="/content/images/tmember_bu_check.jpg"
                alt="확인"
              >
            </a>
            <a
              class="btn-small ml8"
              @click="close"
            >
              <img
                class="cancel-button"
                src="/content/images/tmember_bu_cancel.jpg"
                alt="취소"
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'TmembershipCancel',
  components: {
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      cardNumber: {},
      cancel: {
        isVisible: true
      },
      check: {
        isVisible: false
      },
      telNumber: {
        first: '010'
      },
      certNumber: '',
      ticket: ''
    }
  },
  computed: {
    card_no() {
      return `${this.cardNumber.one}${this.cardNumber.two}${this.cardNumber.three}${this.cardNumber.four}`  
    },
    tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if (this.callbackAlert.code === 'E027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code === 'E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E018') { 
          this.resetPage()
        }
        if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
          this.telNumber = {
            first: '010',
            middle: '',
            last: ''
          },
          this.certNumber = '',
          this.ticket = ''
        }
        if (this.callbackAlert.code === 'E001') { // 인증시간 초과
          this.certNumber = ''
          this.ticket = ''
        }
      }
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    close(){
      this.$emit('click')
    },
    nextStep() {
      this.cancel.isVisible = false
      this.check.isVisible = true
    },
    enterCardNo(event) {
      if(event.target.value.length > event.target.maxLength -1) {
        event.target.nextSibling.nextSibling.focus()
      }
    },
    checkPhoneNumber() {
      if (!this.telNumber.middle) {
        this.$refs.telNumberMiddle.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.last) {
        this.$refs.telNumberLast.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        alert('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else {
        return true
      }
    },
    checkAuthNumber() {
      if (!this.certNumber) {
        this.$refs.certNumber.focus()
        alert('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkCardNumber() {
      if (!this.cardNumber.one) {
        this.$refs.cardNumberOne.focus()
        alert('카드번호를 입력해 주세요.')
        return false
      } else if (!this.cardNumber.two) {
        this.$refs.cardNumberTwo.focus()
        alert('카드번호를 입력해 주세요.')
        return false
      } else if (!this.cardNumber.three) {
        this.$refs.cardNumberThree.focus()
        alert('카드번호를 입력해 주세요.')
        return false
      } else if (!this.cardNumber.four) {
        this.$refs.cardNumberFour.focus()
        alert('카드번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    // SMS인증하기
    requestSMSCert() {
      if (this.checkPhoneNumber()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.tel_no })
          .then(response => {
            if (response.data.sms_ticket) {
              alert('인증번호가 정상적으로 발송되었습니다.')
              this.ticket = response.data.sms_ticket
            }
          })
      }
    },
    // 인증번호 확인
    requestCert() {
      if (this.checkAuthNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.certNumber, sms_ticket: this.ticket })
          .then(response => {
            if (response.data.result_cd === '0001') {
              alert('인증번호가 확인되었습니다. 감사합니다.')
            }
          })
      }
    },
    // 확인
    requestFinal() {
      if (this.checkPhoneNumber() && this.checkAuthNumber() && this.checkCardNumber()) {
        api.EventPostTmemTerm({ 
          sms_ticket: this.ticket,
          member_no: this.card_no 
        })
        .then(response => {
          if (response.data.result_cd === '0002') {
            this.close()
          }
        })
      }
    },
    resetPage(){
      this.telNumber = {
        first: '010',
        middle: '',
        last: ''
      },
      this.certNumber = '',
      this.cardNumber = {
        one: '',
        two: '',
        three: '',
        four: ''
      }
    }
  }
}
</script>

<style>
#TMEMBERSHIP_CANCEL {
  position: fixed;
  width: 432px;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: #fff;
}

@media (max-width: 1023px) {
  #TMEMBERSHIP_CANCEL { 
    width: 100%;
  }
}
</style>

