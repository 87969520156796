<template>
  <div id="JOB_VIEW">
    <Header />
    <div id="container">
      <BreadCrumb :parms="80" />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg04">
          <h3 class="title">
            채용공고
          </h3>
          <p class="txt">
            패기와 열정이 넘치는 당신!<br>SK텔링크에서 당신을 기다립니다.
          </p>
          <img
            src="/content/images/sub_header_cp04.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- company -->
        <div class="company">
          <div class="notice_view career_view">
            <div class="title">
              <h4>{{ jobDetail.title }}</h4>
              <div class="type">
                <span v-if="jobDetail.bulletin_tp === 'CAREER'">경력</span>
                <span v-if="jobDetail.bulletin_tp === 'NEW'">신입</span>
                <span>{{ endDate }}</span>
                <span v-if="diffTime<=0">접수가능</span>
                <span v-if="diffTime>0">접수마감</span>
                <span><a
                  :href="jobDetail.landing_url"
                  class="btn_small arrow"
                  target="_blank"
                >지원하기</a></span>
              </div>
            </div>
            <div v-html="jobDetail.bulletin_desc" />
            <div class="btn_center">
              <a
                class="btn_type01"
                @click="back"
              >
                목록
              </a>
            </div>
          </div>
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'

export default {
  name: 'JobView',
  metaInfo: {
    title: '채용공고 상세 | 채용안내 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      jobDetail:{},
      formData: {
        bulletin_no: this.$route.params.id,
      },
      endDate: null,
    }
  },
  computed: {
    diffTime(){
      return this.$moment().diff(this.endDate)
    }
  },
  watch: {},
  created () {
    api.BulletinGetBullutinDtl(this.formData)
    .then(response => {
      this.jobDetail = response.data
      this.endDate = this.$moment(this.jobDetail.end_dt).format('YYYY.MM.DD')
    })
  },
  mounted () {
  },
  methods: {
    back() {
      this.$router.push({ name: 'job_notice', query: { page_num: this.$route.query.page_num }})
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
