<template>
  <div id="STOCKHOLDERS">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg05">
          <h3 class="title">
            재무정보
          </h3>
          <p class="txt">
            SK텔링크의 투명한 경영으로 글로벌 통신회사로<br> 도약하는 기업가치를 높입니다
          </p>
          <img
            src="/content/images/sub_header_cp05.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- tab -->
        <div class="tab_type01 tab3">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li>
              <router-link to="/view/investor/financial">
                주요재무제표
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/investor/stockholders">
                주주현황
              </router-link>
            </li>
            <li>
              <router-link to="/view/investor/credit_rating">
                신용등급
              </router-link>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <div class="fs_table">
            <h4 class="blind">
              주주현황
            </h4>
            <div class="img_box">
              <img
                src="/content/images/img_cp_equity_2018.png"
                alt="SK텔레콤(주)지분율 100%"
                class="w_hide"
              >
              <img
                src="/content/images/img_cp_equity_m_2018.png"
                alt="SK텔레콤(주)지분율 100%"
                class="m_hide"
              >
            </div>

            <div class="table_title">
              <span class="fr txt_point01"><span class="icon_star">*</span>(단위 : 주,%)</span>
            </div>
            <div class="table_type04">
              <table>
                <caption>구분, 주식수㈜, 지분율(%) 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:33%;">
                  <col style="width:34%;">
                  <col style="width:33%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      구분
                    </th>
                    <th scope="col">
                      주식수
                    </th>
                    <th scope="col">
                      지분율
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>SK텔레콤㈜</td>
                    <td class="txt_right">
                      1,432,627
                    </td>
                    <td class="txt_right">
                      100.00%
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th scope="row">
                      합계
                    </th>
                    <td class="txt_right">
                      1,432,627
                    </td>
                    <td class="txt_right">
                      100.00%
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "@/components/Header";
import BreadCrumb from "@/components/BreadCrumb";
import Footer from "@/components/Footer";
import SummitControl from '@/components/SummitControl.vue';

export default {
  name: 'Stockholders',
  metaInfo: {
    title: '주주현황 | 재무정보 | 투자정보 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
