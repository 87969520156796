<template>
  <div v-if="isVisible">
    <div
      id=""
      class="pop_wrap"
      style="display: block;"
    >
      <div class="alert">
        <h3>00700 무료통화 신청</h3>
        <div class="popup-inner">
          <h4
            id="currentInfo2"
            class="title"
          >
            <span
              id="newFeeName"
              class="txt_point01"
            >00700 무료통화 5분</span>을 신청하실 <br>
            번호를 확인해주세요.
          </h4>

          <div class="table_type04">
            <table class="table_tal">
              <caption>선택, 휴대폰번호 나타내는 표입니다.</caption>
              <tbody id="phoneList">
                <tr>
                  <td>
                    <div
                      class="radio_box"
                    >
                      <input
                        id="id_phone0"
                        v-model="numberType"
                        type="radio"
                        name="phone"
                        value="cordless"
                      >
                      <label for="id_phone0">무선번호</label>
                    </div>
                    <span
                      id="phone_num0"
                      class="ml-15"
                    >{{ usedPhoneNumber }}</span>
                  </td>
                </tr>
                <!-- <tr>
                  <td>
                    <div class="radio_box">
                      <input
                        id="id_phone1"
                        v-model="numberType"
                        type="radio"
                        name="phone"
                        value="corded"
                      >
                      <label for="id_phone1">유선번호</label>
                    </div>
                  </td>
                </tr> -->

                <!-- to개발 / 유선번호 check 됐을때만 display: block-->
                <!-- <tr v-if="numberType === 'corded'">
                  <td>
                    <div class="select_box w_3">
                      <label
                        id="tel1Lb"
                        for="call_select01"
                        aria-hidden="true"
                      >선택</label>
                      <select
                        id="call_select01"
                        v-model="corded.number1"
                        name="i_sTel1"
                        title="일반전화 지역번호 선택"
                      >
                        <option value="">
                          선택
                        </option>
                        <option value="02">
                          02
                        </option>
                        <option value="031">
                          031
                        </option>
                        <option value="032">
                          032
                        </option>
                        <option value="033">
                          033
                        </option>
                        <option value="041">
                          041
                        </option>
                        <option value="042">
                          042
                        </option>
                        <option value="043">
                          043
                        </option>
                        <option value="050">
                          050
                        </option>
                        <option value="051">
                          051
                        </option>
                        <option value="052">
                          052
                        </option>
                        <option value="053">
                          053
                        </option>
                        <option value="054">
                          054
                        </option>
                        <option value="055">
                          055
                        </option>
                        <option value="061">
                          061
                        </option>
                        <option value="062">
                          062
                        </option>
                        <option value="063">
                          063
                        </option>
                        <option value="064">
                          064
                        </option>
                        <option value="070">
                          070
                        </option>
                        <option value="010">
                          010
                        </option>
                        <option value="011">
                          011
                        </option>
                        <option value="016">
                          016
                        </option>
                        <option value="017">
                          017
                        </option>
                        <option value="018">
                          018
                        </option>
                        <option value="019">
                          019
                        </option>
                        <option value="0505">
                          0505
                        </option>
                      </select>
                    </div>
                    <span class="txt_hyphen">-</span>
                    <input
                      v-model="corded.number2"
                      type="text"
                      name="i_sTel2"
                      title="일반전화 가운데자리 입력"
                      alt="일반전화2"
                      maxlength="4"
                      class="onlyNumber w_3"
                      oninput="javascript:
                      this.value= this.value.replace(/[^0-9]/g, '');"
                    >
                    <span class="txt_hyphen">-</span>
                    <input
                      v-model="corded.number3"
                      type="text"
                      name="i_sTel3"
                      title="일반전화 마지막자리 입력"
                      alt="일반전화3"
                      maxlength="4"
                      class="onlyNumber w_3"
                      oninput="javascript:
                      this.value= this.value.replace(/[^0-9]/g, '');"
                    >
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>

          <div class="check_box mt20">
            <input
              id="terms01"
              v-model="agreeTerms"
              type="checkbox"
              name="id_terms01"
              class="check_single"
              @click="privacyPopup"
            >
            <label for="terms01"><span>개인정보 수집 및 이용에 동의합니다.</span></label>
          </div>

          <div class="btn_center single">
            <a
              class="btn_type01"
              @click="applyFree"
            >신청하기</a>
          </div>
        </div>
        <a
          class="pop-close"
          @click="$emit('update:is-visible', false)"
        ><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>

    <div
      id="msgDimd"
      class="dimd"
      style="display: block;"
    />
    <Popup
      :is-visible.sync="popup.isVisible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @close="popup.isVisible = false"
      @click="popup.isVisible = false"
    >
      <p v-html="popup.message" />
    </Popup>

    <!-- 20220421 - 개인정보수집동의문 추가 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 수집 및 이용 동의</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 및 이용 동의
          </h4>

          <div class="cont_term">
            <ul>
              <li>1. 수집/이용목적 : 무료통화 신청</li>
              <li>2. 수집/이용항목 : 휴대폰번호</li>
              <li>3. 보유/이용기간 : <span style="font-weight: bold; font-size: 1.2em;>">신청일로부터 6개월까지</span></li>
              <li>4. 개인정보 수집/이용에 동의하지 않으실 수 있으며, 동의하지 않을 경우 해당 서비스를 이용하실 수 없습니다.</li>
            </ul>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <!--// 20220421 - 개인정보수집동의문 추가 -->    
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  components: {
    Popup
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    usedPhoneNumber: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      numberType: 'cordless',
      corded: {
        number1: '',
        number2: '',
        number3: ''
      },
      popup: {
        isVisible: false,
        message: ''
      },      
      agreeTerms:'',
      isVisiblePrivacyPopup: false
    }
  },
  computed: {
    token() {
      return this.$store.getters.KCB_CERT_KEY
    },
    cordedNumber() {
      return `${this.corded.number1}${this.corded.number2}${this.corded.number3}`
    }
  },
  methods: {
    checkNumber() {
      if (!this.corded.number1) {
          this.popup.message = '지역번호를 입력해 주세요'
          this.popup.isVisible = true
          return false
        } else if (!this.corded.number2) {
          this.popup.message = '전화번호 중간자리를 입력해 주세요'
          this.popup.isVisible = true
          return false
        } else if (!this.corded.number3) {
          this.popup.message = '전화번호 끝자리를 입력해 주세요'
          this.popup.isVisible = true
          return false
        } else {
          return true
        }
    },
    applyFree() {
      if(!this.agreeTerms) {
        this.popup.message = '개인정보 수집/이용에 동의하지 않을 시,<br>무료통화 신청이 불가합니다.'
        this.popup.isVisible = true
      } else {
        if (this.numberType === 'corded') {
          if (this.checkNumber()) {
            api.ItsvcPostApplyFreeDedt({ mdl_tkn: this.token, tel_no: this.cordedNumber })
              .then(response => {
                if (response.data.result_cd === 'E025') {
                  this.$emit('already', response.data)
                } else if (response.data.result_cd === '0004') {
                  this.$emit('success', response.data)
                }
                this.$emit('update:is-visible', false)
              })
          }
        } else {
          api.ItsvcPostApplyFreeDedt({ mdl_tkn: this.token })
            .then(response => {
              if (response.data.result_cd === 'E025') {
                this.$emit('already', response.data)
              } else if (response.data.result_cd === '0004') {
                this.$emit('success', response.data)
              }
              this.$emit('update:is-visible', false)
            })
        }
      }
    },
    privacyPopup() {
      if(!this.agreeTerms) {
        this.isVisiblePrivacyPopup = true
      }
    }
  }
}
</script>

<style>

</style>