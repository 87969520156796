<template>
  <div
    id="CALLING_PLAN_REQUEST"
  >
    <Header />
    <!-- 컨텐츠 -->
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg1">
          <h3 class="title">
            국제전화 요금제 조회
          </h3><p class="txt">
            현재 사용 중인 국제전화 요금제 정보를 조회할 수 있습니다.
          </p>
          <img
            src="/content/images/sub_header_my-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- 20211123 - 개인정보수집동의문 추가 -->
        <div
          v-if="isVisiblePrivacyPopup"
          class="pop_wrap02 w757"
          style="display:block"
        >
          <div class="medium">
            <h3>요금제 조회</h3>
            <div class="pop_cont02">
              <h4 class="title">
                <span>개인정보 수집 및 이용 동의</span>
              </h4>

              <div class="cont_term">
                <ul>
                  <li>1. 수집/이용목적 : 요금제 조회</li>
                  <li>2. 수집/이용항목 : 휴대폰번호</li>
                  <li>3. 보유/이용기간 : <strong>조회 후 즉시 파기</strong></li>
                  <li>4. 개인정보 수집/이용에 동의하지 않으실 수 있으며, 동의하지 않을 경우 해당 서비스를 이용하실 수 없습니다.</li>
                </ul>
              </div>
              <div class="check_box">
                <input
                  id="terms01"
                  v-model="agreeTerms"
                  type="checkbox"
                  name="id_terms01"
                  class="check_single"
                >
                <label for="terms01"><span>위 내용을 숙지하였으며 이에 동의합니다.</span></label>
              </div>
            </div>
            <div class="btn_center">
              <a
                class="btn_type01"
                @click="resultData"
              >조회하기</a>
            </div>
            <a @click="isVisiblePrivacyPopup = false"><img
              alt="닫기"
              src="/content/images/btn_pop_close.png"
            ></a>
          </div>
        </div>
        <!--// 20211123 - 개인정보수집동의문 추가 -->


        <!-- mypage -->
        <div
          v-if="isVisibleResultData"
        >
          <div
            v-if="certResult.result_cd === '0001'"
            class="mypage pt0"
          >
            <h4 class="title">
              <span class="txt_point01">{{ certResult.cust_nm }}고객님</span> 안녕하세요.
            </h4>

            <div class="table_type05">
              <ul>
                <li class="full">
                  <dl>
                    <dt>전화번호</dt>
                    <dd>
                      <div class="space_box">
                        <span
                          id="hpNoSpan"
                        >
                          {{ certResult.svc_tel_no }}
                        </span>
                      </div>
                    </dd>
                  </dl>
                </li>
                <li
                  class="full"
                >
                  <dl>
                    <dt>사용중인 요금제</dt>
                    <dd v-if="useInfo.result_cd === '0000'">
                      {{ useInfo.svc_info.chrg_cd_nm }}
                    </dd>
                    <dd v-else>
                      미사용
                    </dd>
                  </dl>
                </li>
                <li
                  v-if="useInfo.svc_info.chrg_cd_nm"
                  class="full"
                >
                  <dl>
                    <dt>신청일자</dt>
                    <dd>
                      {{ formatedDate }}
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- //mypage -->
      </div>
    </div>
    <!--// 컨텐츠 -->

    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>

    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from "@/components/Header";
import BreadCrumb from "@/components/BreadCrumb";
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'
import Popup from '@/components/popup/Popup'

export default {
  name: 'CallingPlanRequest',
  metaInfo: {
    title: '요금제 조회 | 국제전화 고객지원 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
    Popup
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      isVisiblePrivacyPopup: false,
      isVisibleResultData: false,
      agreeTerms:'',
      selected:'',
      identity:{},
      useInfo: {},
      certResult: {},
      alertPopup: {
        visible: false,
        message: '',
        target: ''
      },
    }
  },
  computed: {
    token() {
      return this.$store.getters.KCB_CERT_KEY
    },
    formatedDate() {
      return `${this.useInfo.svc_info.sub_dt.substr(0,4)}-${this.useInfo.svc_info.sub_dt.substr(4,2)}-${this.useInfo.svc_info.sub_dt.substr(6,2)}`
    }
  },
  created () {
    if (this.token) { // 토큰 있으면
    api.CommonGetReqKcbAuth({ mdl_tkn: this.token })
      .then(response => {
        this.certResult = response.data
        if (response.data.result_cd === '0001') {
          this.fetchUsedData()
        }
      })
    } else {  // 토큰 없으면 : 인증 레이어 팝업 띄우기
      this.$store.commit('ON_CERT_POPUP')
    }
    //서비스이용정보조회


  },
  mounted () {
  },
  methods: {
    alertPopupHandler() {
      this.alertPopup.visible = false
      this.alertPopup.message = ''
      if (this.alertPopup.target) {
        this.$refs[this.alertPopup.target].focus()
      }
      this.alertPopup.target = ''
      this.isVisiblePrivacyPopup = true;
    },
    showAlertPopup(message) {
      this.alertPopup.message = message
      this.alertPopup.visible = true
      this.isVisiblePrivacyPopup = false;
    },
    resultData() {
      if(!this.agreeTerms) {
        this.showAlertPopup('개인정보 수집/이용에 동의하지 않을 시,<br>요금제 조회가 불가합니다.');
      } else {
        this.isVisiblePrivacyPopup = false;
        this.isVisibleResultData = true;
      }
    },
    fetchUsedData() {
      api.ItsvcGetSvcUseInfo({ mdl_tkn: this.token })
        .then(response => {
          this.useInfo = response.data
          this.isVisiblePrivacyPopup = true;
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.check_box {
  margin-top: 15px;
}
.pop_wrap02 .medium>.btn_center { margin-bottom: 40px; }
@media (max-width: 1023px) {
  .pop_wrap02 .medium { padding-bottom: 20px; }
  .pop_wrap02 .medium>.btn_center { margin-bottom: 0; }
  .pop_cont02 {
    .cont_term {
      height: 155px;
      overflow: auto;
      margin-top: 0;
    }
    .check_box {
      margin: 15px 15px 0;
      display: block;
    }
  }
}
</style>
