<template>
  <div id="TMEMBERSHIP">
    <!-- 컨텐츠 -->
    <div class="tmemberWrap">
      <div class="tmHeader">
        <div class="tmHeaderB">
          <img
            src="/content/images/tm_header_01.png"
            alt="T멤버십 포인트로 매월 천원 할인! T데이터 쿠폰까지"
          >
          <div class="tmPopLink">
            <a
              class="new-button"
              @click="popup.tmCheck.isVisible = true"
            >신규가입</a>
            <a
              class="terminate-button"
              @click="popup.tmCancel.isVisible = true"
            >해 지</a>
          </div>
        </div>
      </div>

      <div class="tmContent">
        <div class="tmTab">
          <ul>
            <li
              :class="tabIndex == 1 ? 'on': ''"
              @click="tabActive(1)"
            >
              00700<br>제휴할인 이란?
            </li>
            <li
              :class="tabIndex == 2 ? 'on': ''"
              @click="tabActive(2)"
            >
              가입안내
            </li>
            <li
              :class="tabIndex == 3 ? 'on': ''"
              @click="tabActive(3)"
            >
              이용안내
            </li>
          </ul>
        </div>

        <!-- 00700 제휴할인 이란? -->
        <div
          v-if="tabIndex == 1"
          class="tmContBox"
        >
          <!-- <h2><img src="/content/images/tm_tit_00700.png" alt="SK국제전화 00700" />제휴할인 이란?</h2> -->
          <div class="tmContTxt">
            <ul>
              <li>T 멤버십 고객이 신청 후, 가입시점으로부터 1년간 이용하는 국제전화 요금할인 상품으로,월 1천원씩 제공된 00700 무료통화권은 요금안내서에 요금 할인으로 적용됩니다.<br>가입 후 00700으로 국제전화 첫 이용 시(3분 이상) 데이터쿠폰 500MB가 제공됩니다.</li>
              <li>신청 후 세이브요금제가 적용되며 기존 가입 요금제는 자동 해지됩니다.</li>
              <li>2021년 4월 1일부터 신규로 가입하신 경우, T멤버십>마이페이지>사용내역에서 00700 할인혜택이 조회되지 않습니다.<br>혜택 조회는 SK국제전화 고객센터(1599-0070)로 문의 부탁 드리겠습니다.</li>
            </ul>

            <p class="t1">
              ※ 세이브 요금제<br>(미국: 141.9원/분, 중국: 363원/분, 일본: 297원/분, 부가세 포함)
            </p>
            <p class="t1">
              ※ 기타 국가 요금의 경우 ‘이용안내’ 참고
            </p>
            <img
              src="/content/images/tm_cont_01.png"
              alt="할인혜택 : 매월 1,000원 할인 연 12,000원, 쿠폰 : 첫 이용 시 500mb"
            >
          </div>
        </div>
        <!-- // 00700 제휴할인 이란? -->

        <!-- 가입안내 -->
        <div
          v-if="tabIndex == 2"
          class="tmContBox"
        >
          <!-- <h2>가입안내</h2> -->
          <div class="tmContTxt">
            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_ct_21.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3>SK텔레콤 T멤버십 고객</h3>
                <ul>
                  <li>T멤버십에서 00700 검색 후 가입 가능</li>
                  <li>상품 해지 시 해당 년도 내 재가입 불가</li>
                  <li>SK텔레콤 서비스정지나 T 멤버십 정지 상태에서는 가입 불가</li>
                  <li>만 18세 미만 가입 불가</li>
                </ul>
              </div>
            </div>

            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_ct_22.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3>가입방법</h3>
                <ul>
                  <li>T 멤버십 웹사이트에서 국제전화 00700 '신규가입' 메뉴를 통해 가입 가능합니다.</li>
                  <li>국제전화 00700 제휴할인은 가입 당일에 한해서만 가입 취소가 가능합니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- // 가입안내 -->


        <!-- 이용안내 -->
        <div
          v-if="tabIndex == 3"
          class="tmContBox"
        >
          <!-- <h2>이용안내</h2> -->
          <div class="tmContTxt">
            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_ct_31.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3>T멤버십에서 서비스 가입을 통한 연간 국제전화 00700 할인</h3>
                <ul>
                  <li>가입 후 1년간 월 1,000원 00700 요금할인 (초과금액은 정상 과금)</li>
                  <li>
                    국제전화 요금제: 세이브 요금제<br>(미국: 141.9원/분,<br>중국: 363원/분,<br>일본: 297원/분, 부가세 포함)
                    <a
                      id="opTmNation"
                      @click="popup.price.isVisible = true"
                    >국가별 요금</a>
                  </li>
                  <li>00700 제휴할인 가입 후<br>국제전화 00700 첫 이용 확인 시<br>(사용 후 1개월 이내) SKT 데이터 쿠폰 500MB 지급</li>
                </ul>
              </div>
            </div>

            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_ct_32.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3>유의사항</h3>
                <ul>
                  <li>SKT 휴대폰 번호가 변경된 고객은 1599-0070으로 알려주시면 변경된 번호로 잔여 할인을 받으실 수 있습니다.</li>
                  <li>가입 후 1년간 월 1,000원 00700 요금할인(초과 금액은 정상 과금)</li>
                </ul>
                <p>※ 1년 혜택 종료 후 재가입 필요(가입절차 동일)</p>
              </div>
            </div>

            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_ct_33.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3>문의사항</h3>
                <ul>
                  <li>가입 (신규/재가입) 관련 문의 : <strong>1599-0070</strong></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- // 이용안내 -->
      </div>
    </div>

    <!-- 국가별 요금 안내 팝업-->
    <div
      v-if="popup.price.isVisible" 
      id="tmNationBg"
    />
    <div
      v-if="popup.price.isVisible"
      id="tmNation"
    >
      <!-- ro개발 / 국가별 요금 팝업 style="display:block" -->
      <h1>국가별 요금 안내</h1>
      <div class="tmNCont">
        <h2>· 주요국가<span>(부가세 포함)</span></h2>
        <div class="tmTblW">
          <div class="tmTbl">
            <table>
              <caption />
              <colgroup>
                <col style="width:64%">
                <col style="width:36%">
              </colgroup>
              <thead>
                <tr><th>국가</th><th>요금</th></tr>
              </thead>
              <tbody>
                <tr><td>미국</td><td>141.90/분</td></tr>
                <tr><td>중국</td><td>363.00/분</td></tr>
                <tr><td>호주</td><td>429.00/분</td></tr>
              </tbody>
            </table>
          </div>
          <div class="tmTbl">
            <table>
              <caption />
              <colgroup>
                <col style="width:64%">
                <col style="width:36%">
              </colgroup>
              <thead>
                <tr><th>국가</th><th>요금</th></tr>
              </thead>
              <tbody>
                <tr><td>일본</td><td>297.00/분</td></tr>
                <tr><td>캐나다</td><td>323.40/분</td></tr>
              </tbody>
            </table>
          </div>
        </div>

        <h2>· 기타국가<span>(부가세 포함)</span></h2>
        <div class="tmTblW">
          <div class="tmTbl">
            <table>
              <caption />
              <colgroup>
                <col style="width:64%">
                <col style="width:36%">
              </colgroup>
              <thead>
                <tr>
                  <th>국가</th>
                  <th>요금</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>가나</td><td>979.00/분</td></tr>
                <tr><td>가봉</td><td>979.00/분</td></tr>
                <tr><td>가이아나</td><td>920.70/분</td></tr>
                <tr><td>감비아</td><td>979.00/분</td></tr>
                <tr><td>과델로프</td><td>920.70/분</td></tr>
                <tr><td>괌</td><td>429.00/분</td></tr>
                <tr><td>구아테말라</td><td>920.70/분</td></tr>
                <tr><td>그레나다</td><td>920.70/분</td></tr>
                <tr><td>그루지아</td><td>847.00/분</td></tr>
                <tr><td>그리스</td><td>613.80/분</td></tr>
                <tr><td>그린란드</td><td>613.80/분</td></tr>
                <tr><td>기니아</td><td>979.00/분</td></tr>
                <tr><td>기니아 비소우</td><td>979.00/분</td></tr>
                <tr><td>나미비아</td><td>979.00/분</td></tr>
                <tr><td>나우르</td><td>759.00/분</td></tr>
                <tr><td>나이지리아</td><td>979.00/분</td></tr>
                <tr><td>남 수단</td><td>979.00/분</td></tr>
                <tr><td>남아프리카공화국</td><td>979.00/분</td></tr>
                <tr><td>네덜란드</td><td>473.00/분</td></tr>
                <tr><td>네덜란드령 안틸레스</td><td>920.70/분</td></tr>
                <tr><td>네팔</td><td>1,188.00/분</td></tr>
                <tr><td>노르웨이</td><td>613.80/분</td></tr>
                <tr><td>노르폭섬</td><td>759.00/분</td></tr>
                <tr><td>뉴질랜드</td><td>429.00/분</td></tr>
                <tr><td>뉴칼래도니아</td><td>759.00/분</td></tr>
                <tr><td>니우</td><td>759.00/분</td></tr>
                <tr><td>니저</td><td>979.00/분</td></tr>
                <tr><td>니카라구아</td><td>920.70/분</td></tr>
                <tr><td>대만</td><td>572.00/분</td></tr>
                <tr><td>덴마크</td><td>613.80/분</td></tr>
                <tr><td>도미니카</td><td>920.70/분</td></tr>
                <tr><td>도미니칸 공화국</td><td>920.70/분</td></tr>
                <tr><td>독일</td><td>365.20/분</td></tr>
                <tr><td>동티모르</td><td>759.00/분</td></tr>
                <tr><td>디에고가르시아</td><td>979.00/분</td></tr>
                <tr><td>디지부티</td><td>979.00/분</td></tr>
                <tr><td>라오스</td><td>825.00/분</td></tr>
                <tr><td>라이베리아</td><td>979.00/분</td></tr>
                <tr><td>라트비아</td><td>847.00/분</td></tr>
                <tr><td>러시아</td><td>935.00/분</td></tr>
                <tr><td>레바논</td><td>957.00/분</td></tr>
                <tr><td>레소토</td><td>979.00/분</td></tr>
                <tr><td>레위니옹섬</td><td>979.00/분</td></tr>
                <tr><td>루마니아</td><td>847.00/분</td></tr>
                <tr><td>룩셈부르크</td><td>613.80/분</td></tr>
                <tr><td>르완다</td><td>979.00/분</td></tr>
                <tr><td>리비아</td><td>979.00/분</td></tr>
                <tr><td>리투아니아</td><td>847.00/분</td></tr>
                <tr><td>리히텐슈타인</td><td>613.80/분</td></tr>
                <tr><td>마다카스카르</td><td>979.00/분</td></tr>
                <tr><td>마셀아일랜드</td><td>759.00/분</td></tr>
                <tr><td>마이크로네시아</td><td>759.00/분</td></tr>
                <tr><td>마카우</td><td>825.00/분</td></tr>
                <tr><td>마케도니아</td><td>847.00/분</td></tr>
                <tr><td>마트니크</td><td>920.70/분</td></tr>
                <tr><td>말라위</td><td>979.00/분</td></tr>
                <tr><td>말레이시아</td><td>612.70/분</td></tr>
                <tr><td>말리</td><td>979.00/분</td></tr>
                <tr><td>말타</td><td>613.80/분</td></tr>
                <tr><td>메이요트 섬</td><td>979.00/분</td></tr>
                <tr><td>멕시코</td><td>920.70/분</td></tr>
                <tr><td>모나코</td><td>613.80/분</td></tr>
                <tr><td>모로코</td><td>979.00/분</td></tr>
                <tr><td>모리타니아</td><td>979.00/분</td></tr>
                <tr><td>모리티어스</td><td>979.00/분</td></tr>
                <tr><td>모잠비크</td><td>979.00/분</td></tr>
                <tr><td>몬세라트</td><td>920.70/분</td></tr>
                <tr><td>몬테네그로</td><td>847.00/분</td></tr>
                <tr><td>몰도바</td><td>847.00/분</td></tr>
                <tr><td>몰디브</td><td>1,188.00/분</td></tr>
                <tr><td>몽고</td><td>825.00/분</td></tr>
                <tr><td>미안마</td><td>930.60/분</td></tr>
                <tr><td>바누아투</td><td>759.00/분</td></tr>
                <tr><td>바레인</td><td>957.00/분</td></tr>
                <tr><td>바베이도스</td><td>920.70/분</td></tr>
                <tr><td>바티칸시티</td><td>613.80/분</td></tr>
                <tr><td>바하마</td><td>920.70/분</td></tr>
                <tr><td>발레아릭</td><td>613.80/분</td></tr>
                <tr><td>방글라데시</td><td>1,188.00/분</td></tr>
                <tr><td>버뮤다</td><td>920.70/분</td></tr>
                <tr><td>버진섬(미국)</td><td>920.70/분</td></tr>
                <tr><td>버진섬(영국)</td><td>920.70/분</td></tr>
                <tr><td>베네주엘라</td><td>920.70/분</td></tr>
                <tr><td>베넹</td><td>979.00/분</td></tr>
                <tr><td>베트남</td><td>930.60/분</td></tr>
                <tr><td>벨라루스</td><td>847.00/분</td></tr>
                <tr><td>벨리제</td><td>920.70/분</td></tr>
                <tr><td>벨지움</td><td>613.80/분</td></tr>
                <tr><td>보스니아</td><td>847.00/분</td></tr>
                <tr><td>보츠와나</td><td>979.00/분</td></tr>
                <tr><td>볼리비아</td><td>920.70/분</td></tr>
                <tr><td>부룬디</td><td>979.00/분</td></tr>
                <tr><td>부르키나 파소</td><td>979.00/분</td></tr>
                <tr><td>부탄</td><td>1,188.00/분</td></tr>
                <tr><td>북 수단</td><td>979.00/분</td></tr>
                <tr><td>불가리아</td><td>847.00/분</td></tr>
                <tr><td>브라질</td><td>920.70/분</td></tr>
                <tr><td>브르나이</td><td>825.00/분</td></tr>
                <tr><td>사모아(미국령)</td><td>759.00/분</td></tr>
                <tr><td>사오 톰</td><td>979.00/분</td></tr>
                <tr><td>사우디아라비아</td><td>957.00/분</td></tr>
                <tr><td>사이판</td><td>429.00/분</td></tr>
                <tr><td>산마리노</td><td>613.80/분</td></tr>
                <tr><td>서사모아</td><td>759.00/분</td></tr>
                <tr><td>세네갈</td><td>979.00/분</td></tr>
                <tr><td>세르비아</td><td>847.00/분</td></tr>
                <tr><td>세우타</td><td>847.00/분</td></tr>
                <tr><td>세이칠레스 섬</td><td>979.00/분</td></tr>
                <tr><td>세이트 헬레나</td><td>979.00/분</td></tr>
                <tr><td>세인트 루시아</td><td>920.70/분</td></tr>
                <tr><td>세인트 킷츠</td><td>920.70/분</td></tr>
                <tr><td>세인트 피에르</td><td>759.00/분</td></tr>
                <tr><td>세인트빈센트</td><td>920.70/분</td></tr>
                <tr><td>소말리아</td><td>979.00/분</td></tr>
                <tr><td>솔로몬제도</td><td>759.00/분</td></tr>
                <tr><td>수리남</td><td>920.70/분</td></tr>
              </tbody>
            </table>
          </div>
          <div class="tmTbl">
            <table>
              <caption />
              <colgroup>
                <col style="width:64%">
                <col style="width:36%">
              </colgroup>
              <thead>
                <tr>
                  <th>국가</th>
                  <th>요금</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>스리랑카</td><td>1,188.00/분</td></tr>
                <tr><td>스와질랜드</td><td>979.00/분</td></tr>
                <tr><td>스웨덴</td><td>473.00/분</td></tr>
                <tr><td>스위스</td><td>473.00/분</td></tr>
                <tr><td>스페인</td><td>473.00/분</td></tr>
                <tr><td>슬로바키아</td><td>847.00/분</td></tr>
                <tr><td>슬로베니아</td><td>847.00/분</td></tr>
                <tr><td>시리아</td><td>957.00/분</td></tr>
                <tr><td>시에라레온</td><td>979.00/분</td></tr>
                <tr><td>싱가포르</td><td>382.80/분</td></tr>
                <tr><td>싸이프러스</td><td>613.80/분</td></tr>
                <tr><td>아랍에미리트</td><td>957.00/분</td></tr>
                <tr><td>아루바</td><td>920.70/분</td></tr>
                <tr><td>아르메니아</td><td>847.00/분</td></tr>
                <tr><td>아르헨티나</td><td>920.70/분</td></tr>
                <tr><td>아리보리코스트</td><td>979.00/분</td></tr>
                <tr><td>아이슬랜드</td><td>613.80/분</td></tr>
                <tr><td>아일랜드</td><td>613.80/분</td></tr>
                <tr><td>아제르바이젠</td><td>847.00/분</td></tr>
                <tr><td>아프가니스탄</td><td>957.00/분</td></tr>
                <tr><td>안귈라</td><td>920.70/분</td></tr>
                <tr><td>안도라</td><td>613.80/분</td></tr>
                <tr><td>안타티카</td><td>759.00/분</td></tr>
                <tr><td>안티구아</td><td>920.70/분</td></tr>
                <tr><td>알레스카</td><td>141.90/분</td></tr>
                <tr><td>알바니아</td><td>847.00/분</td></tr>
                <tr><td>알제리아</td><td>979.00/분</td></tr>
                <tr><td>앙골라</td><td>979.00/분</td></tr>
                <tr><td>에리트리아</td><td>979.00/분</td></tr>
                <tr><td>에센션 9섬</td><td>97.00/분</td></tr>
                <tr><td>에스토니아</td><td>847.00/분</td></tr>
                <tr><td>에집트</td><td>979.00/분</td></tr>
                <tr><td>에쿠아도르</td><td>920.70/분</td></tr>
                <tr><td>에티오피아</td><td>979.00/분</td></tr>
                <tr><td>엘살바도르</td><td>920.70/분</td></tr>
                <tr><td>영국</td><td>407.00/분</td></tr>
                <tr><td>예멘</td><td>957.00/분</td></tr>
                <tr><td>오만</td><td>957.00/분</td></tr>
                <tr><td>오스트리아</td><td>613.80/분</td></tr>
                <tr><td>온두라스</td><td>920.70/분</td></tr>
                <tr><td>왈리스엔 포튜나 섬</td><td>759.00/분</td></tr>
                <tr><td>요르단</td><td>957.00/분</td></tr>
                <tr><td>우간다</td><td>979.00/분</td></tr>
                <tr><td>우루구아이</td><td>920.70/분</td></tr>
                <tr><td>우즈베키스탄</td><td>847.00/분</td></tr>
                <tr><td>우크라이나</td><td>847.00/분</td></tr>
                <tr><td>이라크</td><td>957.00/분</td></tr>
                <tr><td>이란</td><td>957.00/분</td></tr>
                <tr><td>이스라엘</td><td>957.00/분</td></tr>
                <tr><td>이탈리아</td><td>365.20/분</td></tr>
                <tr><td>인도</td><td>1,188.00/분</td></tr>
                <tr><td>인도네시아</td><td>572.00/분</td></tr>
                <tr><td>자마이카</td><td>920.70/분</td></tr>
                <tr><td>자이레</td><td>979.00/분</td></tr>
                <tr><td>잔지바</td><td>979.00/분</td></tr>
                <tr><td>잠비아</td><td>979.00/분</td></tr>
                <tr><td>적도 기9니아</td><td>79.00/분</td></tr>
                <tr><td>중앙아프리카</td><td>979.00/분</td></tr>
                <tr><td>지부롤타</td><td>613.80/분</td></tr>
                <tr><td>짐바브웨</td><td>979.00/분</td></tr>
                <tr><td>차드공화국</td><td>979.00/분</td></tr>
                <tr><td>체코</td><td>847.00/분</td></tr>
                <tr><td>칠레</td><td>920.70/분</td></tr>
                <tr><td>카나리제도</td><td>979.00/분</td></tr>
                <tr><td>카메룬</td><td>979.00/분</td></tr>
                <tr><td>카자흐스탄</td><td>847.00/분</td></tr>
                <tr><td>카타르</td><td>957.00/분</td></tr>
                <tr><td>카프버데 섬</td><td>979.00/분</td></tr>
                <tr><td>캄보디아</td><td>825.00/분</td></tr>
                <tr><td>케냐</td><td>979.00/분</td></tr>
                <tr><td>케이만 0섬</td><td>92.70/분</td></tr>
                <tr><td>코모로스</td><td>979.00/분</td></tr>
                <tr><td>코스타리카</td><td>920.70/분</td></tr>
                <tr><td>콜롬비아</td><td>920.70/분</td></tr>
                <tr><td>콩고</td><td>979.00/분</td></tr>
                <tr><td>쿠바</td><td>920.70/분</td></tr>
                <tr><td>쿠웨이트</td><td>957.00/분</td></tr>
                <tr><td>쿡아일랜드</td><td>759.00/분</td></tr>
                <tr><td>크로아티아</td><td>847.00/분</td></tr>
                <tr><td>크리스마스 섬</td><td>759.00/분</td></tr>
                <tr><td>키리기즈스탄</td><td>847.00/분</td></tr>
                <tr><td>키리바티</td><td>759.00/분</td></tr>
                <tr><td>타이티</td><td>759.00/분</td></tr>
                <tr><td>타지스키스탄</td><td>847.00/분</td></tr>
                <tr><td>탄자니아</td><td>979.00/분</td></tr>
                <tr><td>태국</td><td>612.70/분</td></tr>
                <tr><td>터크메니스탄</td><td>847.00/분</td></tr>
                <tr><td>터크스 엔 카이코스 섬</td><td>920.70/분</td></tr>
                <tr><td>터키</td><td>613.80/분</td></tr>
                <tr><td>토고</td><td>979.00/분</td></tr>
                <tr><td>토켈라우</td><td>759.00/분</td></tr>
                <tr><td>통가</td><td>759.00/분</td></tr>
                <tr><td>투바루</td><td>759.00/분</td></tr>
                <tr><td>튜니지아</td><td>979.00/분</td></tr>
                <tr><td>트리나다드 토바코</td><td>920.70/분</td></tr>
                <tr><td>파나마</td><td>920.70/분</td></tr>
                <tr><td>파라구아이</td><td>920.70/분</td></tr>
                <tr><td>파키스탄</td><td>1,188.00/분</td></tr>
                <tr><td>파퓨아 뉴기니아</td><td>759.00/분</td></tr>
                <tr><td>팔라우</td><td>759.00/분</td></tr>
                <tr><td>팔레스타인</td><td>957.00/분</td></tr>
                <tr><td>팔크랜드 섬</td><td>920.70/분</td></tr>
                <tr><td>페로이 6제도</td><td>13.80/분</td></tr>
                <tr><td>페루</td><td>920.70/분</td></tr>
                <tr><td>포루투칼</td><td>613.80/분</td></tr>
                <tr><td>폴란드</td><td>847.00/분</td></tr>
                <tr><td>푸에르토리코</td><td>920.70/분</td></tr>
                <tr><td>프랑스</td><td>365.20/분</td></tr>
                <tr><td>프랑스령 가이아나</td><td>920.70/분</td></tr>
                <tr><td>피지</td><td>759.00/분</td></tr>
                <tr><td>핀란드</td><td>613.80/분</td></tr>
                <tr><td>필리핀</td><td>612.70/분</td></tr>
                <tr><td>하와이</td><td>141.90/분</td></tr>
                <tr><td>하이티</td><td>920.70/분</td></tr>
                <tr><td>헝가리</td><td>847.00/분</td></tr>
                <tr><td>홍콩</td><td>382.80/분</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <a
        class="tmNClose"
        href="#none"
        @click="popup.price.isVisible = false"
      >
        <img
          src="/content/images/btn_tm_n_close.png"
          alt="닫기"
        >
      </a>
    </div>

    <!-- 신규가입 -->
    <tmCheck 
      v-if="popup.tmCheck.isVisible"
      @click="popup.tmCheck.isVisible = false"
    />
    <!-- 해지 -->
    <tmCancel
      v-if="popup.tmCancel.isVisible"
      @click="popup.tmCancel.isVisible = false"
    />
  </div>
</template>

<script>
import tmCheck from '../partnership/TmembershipCheck'
import tmCancel from '../partnership/TmembershipCancel'

export default {
  name: 'Tmembership',
  components: {
    tmCheck,
    tmCancel
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:'1',
      popup: {
        price: {
          isVisible: false
        },
        tmCheck: {
          isVisible: false
        },
        tmCancel: {
          isVisible: false
        }
      }
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    tabActive(num){
      this.tabIndex = num
    }
  }
}
</script>

<style lang="scss" scoped>
.tmemberWrap .tmContent .tmContBox{
  display: block;
}
#tmNation, #tmNationBg {
  display: block;
}
</style>
