<template>
  <div v-if="isVisible">
    <div
      class="pop_wrap02"
      style="display:block;"
      :class="fullPopup"
    >
      <div class="medium">
        <h3>{{ popHeading }}</h3>
        <div class="pop_cont02">
          <slot />
        </div>
        <div
          v-if="btnShow"
          class="btn_center"
          @click="buttonHandler"
        >
          <a
            class="btn_type01"
          >{{ btnText }}</a>
        </div>
        <a
          class="pop-close"
          @click="popupClose"
        ><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <div
      id="msgDimd"
      class="dimd"
      style="display: block;"
    />
  </div>
</template>

<script>
export default {
  name: 'LargePopup',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    popHeading: {
      type: String,
      required: true
    },
    btnShow: {
      type: Boolean,
      required: false,
      default: false
    },
    btnText: {
      type: String,
      required: false,
      default: '확인'
    },
    fullPopup: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods:{
    popupClose(){
      this.$emit('close')
    },
    buttonHandler() {
      this.$emit('click')
    },
  }
}
</script>

<style>
</style>