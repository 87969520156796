<template>
  <div id="ESG" class="eng">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg_esg01">
          <h3 class="title">Ethics</h3>
          <img
            src="/content/images/sub_header_bg_esg01_mo.jpg"
            alt="비쥬얼 이미지"
          />
        </div>

        <div class="company esg">
          <div class="cp_area pb0">
            <div class="cp_box">
              <h4>Definition and Background of Ethics Management</h4>
              <p>
                Ethics & Compliance refers to the ethical and responsible
                corporate management where the company not only fulfills its
                economic <br class="pc-only" />
                and legal responsibilities but also applies the ethical
                standards required by the society to its decision-making process
                and actions. The concept of <br class="pc-only" />
                Ethics & Compliance also includes corporate sustainability
                management where the company carries out its discretional
                <br class="pc-only" />
                responsibility(social responsibility) as a sound corporate
                citizen.
              </p>
            </div>
          </div>

          <div class="esg_box">
            <div class="inner">
              <img
                src="/content/images/img_en_esg_ethics01.png"
                alt="Value of Ethics Management"
              />
            </div>
            <div class="esg_content">
              <h5 class="esg_content_title">
                <span>Code of Ethics</span>
                SKTL’s Code of Conduct Framework
              </h5>
              <p class="esg_content_sub">
                SK Telink’s Code of Conduct, which is centered on our Code of
                Ethics, sets forth the overall ethics management principles of
                the company, including<br class="pc-only" />
                ethical attitude of employees, the company’s responsibility to
                the employees, business partners, customers, shareholders and
                the community, as well as <br class="pc-only" />
                the criteria for applying the Code of Ethics. The Code of
                Conduct also stipulates our obligations and responsibilities for
                ethics & compliance, <br class="pc-only" />
                in accordance with the applicable laws.
              </p>
              <ul class="esg_pdf_list">
                <li>
                  <span class="pdf_title">Code of Ethics</span>
                  <span class="pdf_txt"
                    >Establishment and compliance with the Code a Ethics,
                    <br class="pc-only" />
                    the criteria for ethical behaviors and judgment</span
                  >
                  <a
                    class="pdf_btn"
                    href="/content/download/Code_of_Ethics_SKTL_240903.pdf"
                    target="_blank"
                    >PDF File View</a
                  >
                </li>
                <li>
                  <span class="pdf_title"
                    >Code of Ethics <br class="pc-only" />
                    Implementation Guide</span
                  >
                  <span class="pdf_txt"
                    >Criteria for ethical behavior and judgment to help
                    employees correctly <br class="pc-only" />
                    understand and implement Code of Ethics</span
                  >
                  <a
                    class="pdf_btn"
                    href="/content/download/COE_Implementation_Guide_SKTL_240304.pdf"
                    target="_blank"
                    >PDF File View</a
                  >
                </li>
                <li>
                  <span class="pdf_title">Biz. Partner’s Ethics Pledge</span>
                  <span class="pdf_txt"></span>
                  <a
                    class="pdf_btn"
                    href="/content/download/Biz.Partner's Ethics_Pledge_SKTL_240108.pdf"
                    target="_blank"
                    >PDF File View</a
                  >
                </li>
                <li>
                  <span class="pdf_title">Anti-Bribery Policy</span>
                  <span class="pdf_txt"></span>
                  <a
                    class="pdf_btn"
                    href="/content/download/Anti-Bribery_Policy_SKTL_240830.pdf"
                    target="_blank"
                    >PDF File View</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/EngHeader";
import BreadCrumb from "@/components/EngBreadCrumb";
import Footer from "@/components/EngFooter";

export default {
  name: "Ethics",
  metaInfo: {
    title: "Ethics | Company | SK telink",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.tab_type01 {
  border-top: 1px solid #dadada;
}

.sub_header {
  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    letter-spacing: -0.03em;
  }
}

.esg {
  margin: 80px 0 100px;

  .esg_box {
    width: 1120px;
    margin: 0 auto;
    padding: 107px 0 20px;
    text-align: center;

    .table_type04 {
      margin-top: 20px;
      th,
      td {
        vertical-align: middle;
      }
    }
    sub {
      font-size: 0.5em;
      vertical-align: sub;
    }

    .inner {
      width: 100%;

      img {
        max-width: 785px;
      }
    }
  }

  .esg_pdf_list {
    margin-top: 52px;

    .pd_title {
      line-height: 22px;
      letter-spacing: -0.01em;
    }

    .pdf_txt {
      font-size: 15px;
      line-height: 22px;
      letter-spacing: -0.01em;
      color: #666666;
    }

    .pdf_btn {
      letter-spacing: -0.03em;
    }
  }

  .esg_content {
    margin-top: 147px;

    .esg_content_title {
      font-weight: 500;
      color: #141414;
      letter-spacing: -0.02em;

      > span {
        display: block;
        margin-bottom: 12px;
        font-weight: 500;
        letter-spacing: -0.02em;
        color: #e51937;
      }
    }
  }

  .cp_area {
    .cp_box {
      h4 {
        line-height: 44px;
        font-weight: 400;
        color: #e51937;
        letter-spacing: -0.02em;
      }

      p {
        margin-top: 34px;
        font-size: 15px;
        line-height: 28px;
        color: #666666;
        letter-spacing: -0.01em;
      }
    }
  }

  .cp_area.pb0 {
    padding-bottom: 0;
  }
  .ui-btn-wrap {
    margin-top: 80px;

    .ui-btn {
      display: inline-block;
      padding: 0 44px;
      color: #fff;
      font-size: 24px;
      line-height: 61px;
      background-color: #e51937;
    }
  }

  .esg_report {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 117px;
    padding: 90px 20px;
    text-align: center;
    background-color: #f9f9f9;

    * {
      letter-spacing: -0.03em;
    }

    .report-title-wrap {
      text-align: center;

      img {
        width: 74px;
        height: 74px;
      }

      .report-title {
        margin-top: 30px;
        font-size: 44px;
        line-height: 44px;
        color: #444444;
      }
    }

    .report-txt {
      margin-top: 25px;
      font-size: 15px;
      line-height: 26px;

      > p {
        margin-top: 12px;
        font-size: 15px;
        line-height: 26px;
        color: #ff8522;
      }
    }

    .emphasis-txt {
      margin-top: 12px;
      font-size: 15px;
      line-height: 26px;
      color: #ff8522;
    }

    .report-bottom-wrap {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 85px;
      text-align: center;

      .bottom-title {
        font-size: 22px;
        line-height: 22px;
        font-weight: 4100;
        color: #444444;
      }

      .btn_type04 {
        margin-top: 20px;
      }

      .helper-txt {
        margin-top: 35px;
        font-size: 14px;
        line-height: 25px;
        font-weight: 400;
        color: #9c9c9c;
      }
    }
  }
}

@media (max-width: 1023px) {
  .esg {
    margin: 0 0 50px;
    padding-bottom: 0;

    .cp_box {
      padding: 0 20px;
    }
    .esg_box {
      width: 100%;
      margin: 0;
      padding: 30px 15px 10px;
      th,
      td {
        padding: 5px;
        font-size: 13px;
        line-height: 1.3;
      }
      sup {
        line-height: inherit;
      }
    }

    .ui-btn-wrap {
      margin-top: 40px;
      .ui-btn {
        padding: 0 22px;
        font-size: 16px;
        line-height: 40px;
      }
    }

    .esg_content {
      margin-top: 60px;
    }

    .esg_report {
      padding: 50px 20px;
      margin-top: 60px;

      .report-title-wrap {
        .report-title {
          margin-top: 20px;
          font-size: 32px;
          line-height: 32px;
        }
      }

      .report-bottom-wrap {
        margin-top: 40px;

        .helper-txt {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
