<template>
  <div id="NOTICE_CONTENTS">
    <Header />
    <div
      id="container"
      class="sdsdsd"
    >
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            공지사항
          </h3>
          <p class="txt">
            SK 텔링크의 새로운 소식을 알려드립니다
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- customer -->
        <div class="customer">
          <div class="notice_view">
            <div class="title">
              <h4>{{ noticeDetail.title }}</h4>
              <span>{{ $moment(noticeDetail.start_dt).format('YYYY.MM.DD') }}</span>
            </div>
            <div
              class="text-wrap"
              v-html="noticeDetail.bulletin_desc"
            />
            <!-- 이전글 & 다음글 -->
            <!-- <div class="next_table">
              <dl>
                <dt>이전글</dt>
                <dd>
                  <router-link
                    :to="{ name: 'notice_contents', params: { id: prevItem.bulletin_no }}"
                    :title="prevItem.title"
                    class="btn_detail"
                  >
                    {{ prevItem.title }}
                  </router-link>
                </dd>
              </dl>
              <dl>
                <dt>다음글</dt>
                <dd>
                  <router-link
                    :to="{ name: 'notice_contents', params: { id: nextItem.bulletin_no }}"
                    :title="nextItem.title"
                    class="btn_detail"
                  >
                    {{ nextItem.title }}
                  </router-link>
                </dd>
              </dl>
            </div> -->
            <div class="btn_center">
              <a
                class="btn_type01"
                @click="back"
              >
                목록
              </a>
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from "@/components/Header";
import BreadCrumb from "@/components/BreadCrumb";
import Footer from "@/components/Footer";
import SummitControl from "@/components/SummitControl";

export default {
  name: "NoticeContents",
  metaInfo: {
    title: '공지사항 상세 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  
  props: {
    id: {
      type: [Number, String],
      required: true
    }
  },
  emits: [],
  data() {
    return {
      noticeDetail:{},
      prevNoticeDetail:{},
      nextNoticeDetail:{},
      detailItem: [],
      prevItem: {},
      nextItem: {},
      prevPath: ''
    };
  },
  computed: {
    detailId() {
      return parseInt(this.id, 10)
    },
    noticeItems() {
      return this.$store.getters.NOTICE_ITEMS
    }
  },
  watch: {
    detailId: {
      immediate: true,
      handler() {
        this.fetData()
      }
    }
  },
  created() {
  },
  mounted() {},
  methods: {
    fetData() {
      this.noticeItems.some(item => {
      if (item.bulletin_no === this.detailId) {
          this.detailItem = item
        }
        return item.bulletin_no === this.detailId
      })

    this.noticeItems.some((li, index) => {
      if (li.row_num === this.detailItem.row_num) {
        this.prevItem = this.noticeItems[index - 1]
        this.nextItem = this.noticeItems[index + 1]
      }
      return li.row_num === this.detailItem.row_num
    })

    api.BulletinGetBullutinDtl({ bulletin_no: this.detailId })
    .then(response => {
      this.noticeDetail = response.data
    })
    },
    back() {
      this.$router.push({ name: 'notice_list', query: { page_num: this.$route.query.page_num }})
    },
  },
};
</script>

<style lang="scss" scoped>
  .customer .notice_view .text-wrap{
    border-bottom: 2px solid #e51937;
  }
</style>
