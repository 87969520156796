<template>
  <div class="error">
    <div class="qna__logo">
      <img
        src="/content/images/logo_qna.png"
        alt="에스케이 텔링크 로고"
      >
    </div>

    <div class="text_align__center">
      <p class="error-title">
        시스템 에러
      </p>
      <p class="error-text">
        보다 안정적인 서비스 이용을 위해 홈페이지를 점검 중입니다. <br>서비스 이용에 불편을 드려 죄송합니다.
      </p>
      <p class="text_bold">
        점검 시간 : YYYY년 MM월 DD일(요일) HH시 ~ HH시 (약 H시간 소요)
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SystemError',
  components: {
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
