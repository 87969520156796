<template>
  <div
    v-if="menuItems.length"
    class="location"
  >
    <ul class="location_inner">
      <li class="location_item">
        <router-link
          to="/"
          class="location_link"
        >
          <img
            src="/content/images/location_ico_home.png"
            alt="홈"
          >
        </router-link>
      </li>

      <li
        class="location_item"
        :class="{ current: oneDepthItem.children.length === 0}"
      >
        <a
          href="#"
          class="location_link"
        >
          {{ oneDepthItem.label }}
        </a>
        <button
          v-if="oneDepthItem.children.length"
          type="button"
          @mouseenter="mouseOver"
        >
          <img
            src="/content/images/location_btn_more.png"
            alt="더보기"
          >
        </button>
        <div
          class="sub_location"
          @mouseleave="mouseLeave"
        >
          <ul>
            <template v-for="(two, twoIndex) in menuItems[oneDepthIndex].children">
              <li
                v-if="two.isVisible === true"
                :key="`two_${twoIndex}`"
              >
                <router-link :to="two.link">
                  {{ two.label }}
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </li>

      <!-- active menu -->
      <li
        v-if="oneDepthItem.children.length"
        class="location_item"
        :class="{'current' : 'isActive'}"
      >
        <router-link
          class="location_link"
          :to="twoDepthItem.link"
        >
          {{ twoDepthItem.label }}
        </router-link>
      </li>
      <!-- //active menu -->
    </ul>
  </div>
</template>

<script>
import api from '@/api/index.js'

export default {
  name: 'BreadCrumb',
  data(){
    return {
      menuItems:[],
      oneDepthItem: {},
      twoDepthItem: {},
      oneDepthIndex:null,
      twoDepthIndex:null,
    }
  },
  created () {
    api.EngMenu()
      .then(response => {
        this.menuItems = response.data
        this.updateArray()
      })
      
  },
  methods:{
    updateArray(){
      this.menuItems.forEach((oneDepth, index) => {
        if(oneDepth.link === this.$route.path){
          if (oneDepth.children.length === 0) {
            this.oneDepthItem = oneDepth;
            this.oneDepthIndex = index;
          }
        }
        oneDepth.children.forEach((twoDepth, index2) =>{
          if (twoDepth.link === this.$route.path) {
            this.oneDepthItem = oneDepth;
            this.twoDepthItem = twoDepth;
            this.oneDepthIndex = index;
            this.twoDepthIndex = index2;
          }
        })
      })
    },
    mouseOver(event){
      let subList = event.currentTarget.nextElementSibling
      let subItems = document.querySelectorAll('.sub_location');
      for(let i=0; i<subItems.length; i++){
        subItems[i].classList.remove('on');
        subItems[i].style.height = '0px'
        subItems[i].style.overflow = 'hidden'
      }
      subList.classList.add('on');
      subList.style.height= subList.childNodes[0].clientHeight + 'px'
      setTimeout(() => {
        subList.style.overflow = 'visible'
      }, 200);
    },
    mouseLeave(event){
      let subList = event.target;
      subList.classList.remove('on');
      subList.style.height= '0px'
      subList.style.overflow = 'hidden'
    }
  },
}
</script>

<style lang="scss">
.location ul li .sub_location{
  display: block;
  overflow: hidden;
  height: 0;
  transition: height 0.2s;
  padding: 0;
}
.location ul li .sub_location ul {
  position: relative;
  padding: 11px 30px 20px;
}
.location ul li .sub_location.on{
  transition: height 0.2s;
  color: #666;
}
</style>
