<template>
  <div id="LIST_CONTENTS">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg06">
          <h3 class="title">
            보도자료
          </h3>
          <p class="txt">
            SK텔링크에서 다양한 소식을 전해 드립니다
          </p>
          <img
            src="/content/images/sub_header_cp06.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- company -->
        <div class="company">
          <div class="notice_view">
            <div class="title">
              <h4>{{ pressDetail.title }}</h4>
              <span>{{ $moment(pressDetail.start_dt).format('YYYY.MM.DD') }}</span>
            </div>
            <div
              class="text-wrap"
              v-html="pressDetail.bulletin_desc"
            />

            <!-- 이전글 & 다음글 추후작업-->
            <!-- <div class="next_table">
              <dl>
                <dt>이전글</dt>
                <dd>
                  <a
                    class="nextBoard"
                    href="javascript:;"
                  >
                    [SK 텔링크] ‘SK세븐모바일’ 무약정 유심, 전국 세븐일레븐 매장에서 판매개시!!
                    <input
                      type="hidden"
                      name="nextSeqno"
                      value="1684"
                    >
                  </a>
                </dd>
              </dl>
              <dl>
                <dt>다음글</dt>
                <dd>
                  <span class="nodata">다음글이 없습니다.</span>
                </dd>
              </dl>
            </div> -->
            <div class="btn_center">
              <a
                class="btn_type01 finish"
                @click="back"
              >
                목록
              </a>
            </div>
          </div>
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import api from '@/api'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'

export default {
  name: 'ListContents',
  metaInfo: {
    title: '보도자료 상세 | 미디어센터',
  },
  components: {
    Header,
    Footer,
    BreadCrumb
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      pressDetail:{},
      formData: {
        bulletin_no: this.$route.params.id,
      },
    }
  },
  computed: {
  },
  watch: {},
  created () {
    api.BulletinGetPressDtl(this.formData)
    .then(response => {
      this.pressDetail = response.data
    })
  },
  mounted () {
  },
  methods: {
    back() {
      this.$router.push({ name: 'press_list', query: { page_num: this.$route.query.page_num }})
    },
  }
}
</script>

<style lang="scss" scoped>
  .company .notice_view .text-wrap{
    border-bottom: 2px solid #e51937;
  }
</style>
