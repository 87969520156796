<template>
  <div id="INTER_CALL">
    <Header />
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />
      <div id="contents">
        <div class="sub_header bg01-01">
          <h3 class="title">
            국제전화 00700
          </h3>
          <p class="txt">
            고객이 인정한 최고의 서비스와 품질로<br>전 세계 어느 곳이라도 통화할 수 있는 국가대표 국제전화 00700
          </p>
          <img
            src="/content/images/sub_header_inter01-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <div class="tab_type01">
          <ul>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/business/inter_call">
                국제전화 00700
              </router-link>
            </li>
            <li>
              <router-link to="/view/business/inter_sms">
                국제SMS 00700
              </router-link>
            </li>
          </ul>
        </div>

        <!-- inter -->
        <div class="inter">
          <div class="advice_list">
            <h4>이런 고객님이 사용하면 좋아요!</h4>
            <p class="advice_list_text">
              <span class="hashtag"># 국제 업무 기업</span><span class="hashtag"># 해외가족/친구</span><span class="hashtag"># 해외여행</span>
            </p>
            <div class="advice_scroll">
              <div class="advice_scroll_inner">
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter01.png"
                        alt=""
                      >
                    </div>
                    <p>해외에 있는 가족/지인과<br> 꾸준히 연락하는 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter07.png"
                        alt=""
                      >
                    </div>
                    <p>해외 사업자와 사업하는<br> 비즈니스 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter22.png"
                        alt=""
                      >
                    </div>
                    <p>해외 여행과 쇼핑 직구를 즐기는<br> YOLO족</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="advice_list white">
            <h4>거부할 수 없는 매력은<br class="m_hide"> 무엇인가요?</h4>
            <div class="advice_scroll">
              <div class="advice_scroll_inner">
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub32-1.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>해외 직통회선으로<br> 고품질 통화 제공</dt>
                      <dd>국제전화 통화량의 95%를 차지하는<br> 상위 30개국과의 직통회선 연결로<br> 더욱 생생하고 깨끗한 통화품질을 제공해,<br> 고객의 마음까지 전달합니다.</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub14.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>고객이 원하는<br> 다양한 요금혜택</dt>
                      <dd>요금할인, 무료통화, 무료요금 등 <br>다양한 할인/혜택으로 넓은 선택의 폭을 제공합니다</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub33-1.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>최고의 고객만족 서비스</dt>
                      <dd>국가고객만족도 1위 기업답게!<br> 00700을 많이 사용하거나<br> 오랜 기간 이용해주신 고객님께<br> 우수고객 사은행사, 정기 문화행사, 무료통화권 등<br> 다양한 혜택을 제공합니다.</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="inter_seach">
            <h4><strong>국제전화 00700 표준요금</strong>과 현지정보를 확인해보세요</h4>
            <div class="type01">
              <Autocomplete
                v-model="formData.natl_cd"
                :source="country"
                :results-display="'natl_nm'"
                :results-value="'natl_cd'"
                :clear-button-icon="''"
                :placeholder="'국가명을 입력해주세요'"
                @selected="selectCountry"
                @keyupEnter="inquire"
              />
              <button
                type="button"
                class="btn_type01 btn_search"
                @click="inquire"
              >
                검색
              </button>
            </div>
          </div>
          <!-- 검색결과박스 -->
          <div
            v-if="resultAreaOpen"
            class="result_area"
          >
            <div class="table_type04">
              <table id="charge_table">
                <caption>00700 표준요금, 001 표준요금, 002 표준요금, 수도, 국가번호, 시차 및 현지시간 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:34%;">
                  <col style="width:33%;">
                  <col style="width:33%;">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">
                      00700<br class="m_br"> 표준요금
                    </th>
                    <th scope="col">
                      001<br class="m_br"> 표준요금
                    </th>
                    <th scope="col">
                      002<br class="m_br"> 표준요금
                    </th>
                  </tr>
                  <tr>
                    <td>{{ result.price_info.price_00700 }}원/분</td>
                    <td>{{ result.price_info.price_001 }}원/분</td>
                    <td>{{ result.price_info.price_002 }}원/분</td>
                  </tr>
                  <tr>
                    <th scope="col">
                      수도
                    </th>
                    <th scope="col">
                      국가번호
                    </th>
                    <th scope="col">
                      시차 및<br class="m_br"> 현지시간
                    </th>
                  </tr>
                  <tr>
                    <td>
                      {{ result.natl_info.natl_nm }}/{{ result.natl_info.capital }}
                    </td>
                    <td>{{ result.natl_info.natl_no }}</td>
                    <td class="txt_point01">
                      <span class="icon_img"><img
                        src="/content/images/icon_time.png"
                        alt="시차 및 현지시간"
                      ></span>
                      {{ result.natl_info.time_gap }}시간({{ result.natl_info.fomatedDate }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ul class="txt_em">
              <li><span class="icon_star">*</span>해당 요금은 VAT가 포함된 금액입니다.</li>
              <li><span class="icon_star">*</span>해당 요금은 표준요금 기준으로 작성되었습니다.</li>
              <li><span class="icon_star">*</span>실제 과금 기준은 1초당 과금됩니다.</li>
            </ul>
          </div>
          <div class="btn_center single line_top">
            <router-link
              to="/view/business/calling_plan_domestic"
              class="btn_type01"
            >
              할인요금 알아보러 가기
            </router-link>
          </div>
          <!-- //검색결과박스 -->
          <div class="use_list_area">
            <div class="use_list">
              <h4>00700 통화 쉽게 이용하는 방법</h4>
              <p>한국에서 미국 LA 지역의 <span>휴대폰으로 전화를 걸 경우</span></p>
              <ul>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>00700 국제전화 식별번호</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter19.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호(미국 : 1)</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter32.png"
                      alt=""
                    >
                  </div>
                  <p>상대방 휴대폰 번호<br class="m_hide">(41-123-4567)<br><span><span class="icon_star">*</span>휴대폰 번호는 0번 제외</span></p>
                </li>
              </ul>
            </div>
            <div class="use_list">
              <p>한국에서 미국 LA 지역의 <span>일반전화로 전화를 걸 경우</span></p>
              <ul class="four">
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter01.png"
                      alt=""
                    >
                  </div>
                  <p>00700 국제전화 식별번호</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter19.png"
                      alt=""
                    >
                  </div>
                  <p>국가번호(미국 : 1)</p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter29.png"
                      alt=""
                    >
                  </div>
                  <p>지역번호(LA : 213)<br><span><span class="icon_star">*</span>지역번호 번호는<br class="m_hide"> 0번 제외</span></p>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/icon_inter40.png"
                      alt=""
                    >
                  </div>
                  <p>상대방  번호(123-4567)</p>
                </li>
              </ul>
            </div>
          </div>
          <div class="btn_center single">
            <!-- <router-link to="/os/it/it/it_it_international_tel_info.do" class="btn_type01">국제전화 요금제 보기</router-link> -->
            <router-link
              to="/view/business/calling_plan_recommanded"
              class="btn_type01"
            >
              국제전화 요금제 보기
            </router-link>
          </div>
        </div>
        <!-- //inter -->
      </div>
      <!--// 컨텐츠 -->
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import util from '@/utilities'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'
import Autocomplete from '@/components/Autocomplete'

export default {
  name: 'InterCall',
  metaInfo: {
    title: '국제전화 00700 | 국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
    Autocomplete
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      formData: {},
      selectedCountry: {},
      country: [],
      resultAreaOpen: false,
      result: {
        natl_info: {},
        price_info: {}
      }
    }
  },
  computed: {
  },
  watch: {},
  created () {
    api.ItsvcGetPreStandardPricePlanInfo()
      .then(response => {
        this.country = response.data
      })

  },
  mounted () {
  },
  methods: {
    selectCountry(item) {
      this.selectedCountry = item.selectedObject
    },
    inquire() {
      if (!this.formData.natl_cd) {
        this.$store.commit('ON_ALERT_POPUP', { message: '국가명을 입력하세요.' })
      } else {
        api.ItsvcGetStandardPricePlanInfo(this.formData)
        .then(response => {
          this.result = response.data
          if (this.result.natl_info) {
            this.resultAreaOpen = true
            this.result.natl_info.fomatedDate = util.convDateToSimpleDateFormat(this.result.natl_info.local_time)
          }
        })
      }
    },
  }
}
</script>

<style lang="scss">
.inter_seach {
  overflow: visible;
  div.type01 {
    position: relative;
    overflow: visible;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}
.natl-list{
  position: absolute;
  left: 0;
  top: 57px;
  z-index: 999;
  width: 384px;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #ddd;
  background: #eee;
  color: #333;
  padding: 2px;
  margin: 0;
  outline: none;
  li {
    line-height: 28px;
    text-align: left;
    &:hover {
      border: 1px solid #fbd850;
      background: #fff;
      font-weight: bold;
      color: #eb8f00;
    }
    a {
      display: block;
      cursor: pointer;
    }
  }
}

.inter .result_area {
  display: block;
}

.type01 {
  .autocomplete {
    float: left;
    width: 384px;
    height: 57px;
    @media (max-width: 1023px) {
      width: 100%;
      height: auto;
    }
    &__box {
      background: none;
      border: none;
      border-radius: 0;
      padding: 0;
    }
    &__icon {
      display: none;
    }
    &__inputs {
      width: 100%;
      padding: 0;
      input {
        width: 100%;
      }
    }
    &__results {
      height: 150px;
      border: 1px solid #ddd;
      background: #eee;
      color: #333;
      padding: 2px;
      &__item {
        text-align: left;
        margin: 0;
        padding: 0;
        line-height: 28px;
        font-size: 17px;
        &:hover {
          border: 1px solid #fbd850;
          background: #fff;
          font-weight: bold;
          color: #eb8f00;
        }
      }
    }
    &__selected {
      border: 1px solid #fbd850;
      background: #fff !important;
      font-weight: bold;
      color: #eb8f00;
    }
  }
}
</style>
