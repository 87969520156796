<template>
  <div id="TERMS">
    <Header />
    <!-- 컨텐츠 -->
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            약관/서식보기
          </h3>
          <p class="txt">
            서비스 신청, 변경, 가입에 관련된 각종서류를<br>다운로드 받으세요
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <div class="customer">
          <div class="table_type02">
            <ul class="table_wid02">
              <li>
                <dl class="child1">
                  <dt>No</dt>
                  <dd>1</dd>
                </dl>
                <dl class="child2">
                  <dt>구분</dt>
                  <dd class="tit-rgl">
                    국제전화00700
                  </dd>
                </dl>
                <dl class="child3">
                  <dt>상세카테고리</dt>
                  <dd />
                </dl>
                <dl class="child4">
                  <dt>제목</dt>
                  <dd class="txt_left">
                    <span class="tit-rgl">SK텔링크 국제전화 이용약관</span>
                  </dd>
                </dl>
                <dl class="child5">
                  <dt>파일</dt>

                  <dd class="btn">
                    <a
                      href="/content/download/SK텔링크_국제전화이용약관_20220521.pdf"
                      download
                    >PDF</a>
                  </dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!--// 컨텐츠 -->
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "@/components/Header";
import BreadCrumb from "@/components/BreadCrumb";
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'

export default {
  name: 'Terms',
  metaInfo: {
    title: '약관/서식보기 | 국제전화 고객지원 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
