<template>
  <div id="HR_CULTURE">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bgEthics">
          <h3 class="title">
            윤리경영 상담/제보
          </h3>
          <p class="txt">
            SK텔링크의 상담/제보 시스템은<br>
            제보자의 익명성을 철저히 보호합니다.
          </p>
          <img
            src="/content/images/sub_header_ethics.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- company -->
        <div class="company mb0 ethics">
          <div class="cp_area">
            <div class="cp_box">
              <h4>윤리경영 상담/제보</h4>
              <p>
                SK텔링크는 <span class="txt_point01">임직원의 비윤리적인 업무 수행, 부정청탁 및 금품 등 수수의 금지에 관한 법률 위반 행위, 기타 부정한 비리 행위에 대하여<br class="w_hide">
                  상담 및 신고를 할 수 있는 채널을 운영하고 있습니다.</span><br class="m_hide">
                어떠한 경우에도 상담/신고자의 신분 및 상담/신고 내용은 보호되도록 운영하고 있으니<br class="w_hide">
                주저하지 마시고 아래 채널을 통해 접수해 주시기 바랍니다.<br>
                <span class="gap">(단, 사실에 근거하지 않은 비방 또는 개인 사생활에 관한 사항 등은 처리되지 않을 수 있으니 참고 바립니다.)</span>
              </p>
              
              <h5>상담/제보 접수</h5>
              <div class="ui-btn-wrap">
                <a
                  href="https://ethics.sk.co.kr"
                  class="ui-btn"
                  target="_blank"
                >
                  바로 가기
                </a>
              </div>

              <p>
                [참고] SK텔링크의 상품/서비스 관련 불만 및 이용불편 사항은 고객센터로 전화 주시기 바랍니다.<br>
                (국제전화 1599-00700, 알뜰폰 1599-0999). 감사합니다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl.vue'

export default {
  name: 'HrCulture',
  metaInfo: {
    title: '윤리경영 상담/제보 | 윤리경영 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.sub_header.bgEthics {
  background: url('/content/images/sub_header_bg_ethics.jpg') no-repeat 50% 0;
}
.ethics {
  .cp_box {
    p {
      & > span.gap {
        display: block;
        margin-top: 19px;
      }
    }
    h5 { margin-top: 72px; color: #444; font-size: 24px; }
  }
  .ui-btn-wrap {
    margin-top: 28px; margin-bottom: 57px;
    .ui-btn {
      display: inline-block;
      width: 296px;
      color: #fff;
      font-size: 24px;
      line-height: 61px;
      background-color: #e51937;
    }
  }
}

@media (max-width:1023px) {
  .ethics {
    .cp_box {
      padding: 0 20px;
      h5 { margin-top: 42px; font-size: 22px; }
    }
    .ui-btn-wrap {
      margin-top: 14px; margin-bottom: 42px;
      .ui-btn {
        width: 100%;
        font-size: 16px;
        line-height: 40px;
      }
    }
  }
}
</style>
