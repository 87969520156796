
<template>
  <div id="WELCOME_GIFT">
    <div class="event_wrap welcome">
      <div class="event_view">
        <div>
          <span>
            <img
              src="/content/images/partnership/210901_welcomegift_1.png"
              alt="SK국제전화 웰컴기프트 이벤트"
              @load="onLoad"  
            ><img
              src="/content/images/partnership/210901_welcomegift_2.png"
              alt="이벤트에서만 만나볼 수 있는 00700 앱 웰컴기프트"
            ><img
              src="/content/images/partnership/210901_welcomegift_3.png"
              alt="SK국제전화 웰컴기프트 받으려면 이렇게 하세요"
            >
          </span>
          <div class="event_view_field">
            <div class="event_header">
              <img
                src="/content/images/partnership/210901_welcomegift_t1.png"
                alt="이벤트 응모하기"
              >
            </div>
            <div class="event_content">
              <div class="simple_type">
                <dl>
                  <dt>휴대폰번호</dt>
                  <dd>
                    <div class="insert_phone">
                      <select
                        id="call_select01"
                        ref="telNumberFirst"
                        v-model="telNumber.first"
                        name="i_sHpNo1"
                        title="무선전화 앞자리 선택"
                      >
                        <option value="010">
                          010
                        </option>
                        <option value="011">
                          011
                        </option>
                        <option value="016">
                          016
                        </option>
                        <option value="017">
                          017
                        </option>
                        <option value="018">
                          018
                        </option>
                        <option value="019">
                          019
                        </option>
                      </select>
                      <span class="txt_hyphen" />
                      <input
                        ref="telNumberMiddle"
                        v-model="telNumber.middle"
                        name="i_sHpNo2"
                        title="전화번호"
                        type="text"
                        maxlength="4"
                        pattern="[0-9]*"
                        class="numbersOnly"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                      <span class="txt_hyphen" />
                      <input
                        ref="telNumberLast"
                        v-model="telNumber.last"
                        name="i_sHpNo3"
                        title="전화번호"
                        type="text"
                        maxlength="4"
                        pattern="[0-9]*"
                        class="numbersOnly"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                    <button
                      type="button"
                      class="btn btn_cert"
                      @click="requestSMSCert"
                    >
                      SMS 인증
                    </button>
                  </dd>
                </dl>
                <dl
                  v-if="authNumberInputVisible"
                  id="numberCheck"
                  class="C030 duplPhono"
                >
                  <dt>인증번호 입력</dt>
                  <dd>
                    <div class="insert_certNum">
                      <input
                        ref="certNumber"
                        v-model="auth_no"
                        name="i_sCertificationNo"
                        title="인증번호"
                        type="text"
                        maxlength="6"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                    <button
                      class="btn btn_cert"
                      @click="requestCert"
                    >
                      인증번호확인
                    </button>
                  </dd>
                </dl>
              </div>

              <div
                class="agree_box"
              >
                <input
                  id="yes"
                  v-model="agreePrivacy"
                  name="yesno"
                  class="myClass YNchk"
                  type="checkbox"
                  value="yes01"
                ><label for="yes" />
                <a
                  @click="isVisiblePrivacyPopup = true"
                >
                  이벤트 참여 혜택을 받기 위해 개인정보 수집하는 것에 동의합니다.
                </a>
                <p>
                  ※ 이벤트 응모 완료 시, 앱 설치 안내 문자가 발송됩니다.
                </p>
              </div>

              <div class="event_active_btn">
                <a
                  id="btnApply"
                  @click="registrationFinal"
                ><img
                  src="/content/images/partnership/210901_welcomegift_t2.png"
                  alt="이벤트 응모완료"
                ></a>
              </div>
            </div>
          </div>
          <div class="event_caution">
            <h1>
              <img
                src="/content/images/partnership/210901_welcomegift_t3.png"
                alt="이벤트 유의 사항"
              >
            </h1>
            <ul>
              <li>SK국제전화 웰컴기프트는 00700 앱 리뉴얼 이후(2018년 9월) 가입 이력이 없는 고객만 받으실 수 있으십니다.</li>
              <li>
                본 이벤트 응모 후 00700 앱 신규 고객 전원에게 스타벅스 카페라떼 Tall 1잔이 제공되며, 참여 완료 고객 중 2021년 00700 첫 이용 고객에 한해 00700 앱 가입일부터 익월 20일까지 100원에 전 국가 00700 무제한 사용이 가능하십니다.<br>
                (예시: 2021년 10월 1일 앱 가입 시 11월 20일까지 00700 전 국가 100원에 무제한 사용)
              </li>
              <li>화상/위성 등 특수 대역으로 통화 시엔 전 국가 무제한 100원 혜택이 적용되지 않습니다.</li>
              <li>상기 이벤트는 운영 상황에 따라 조기 종료되거나 이벤트가 대체될 수 있습니다.</li><!-- 20210916 추가 -->
              <li>All pass(올패스)요금제 등 구독형 요금제 이용 고객께서는 요금 할인 혜택을 받고 계시므로 00700 전 국가 100원 무제한 혜택은 받으실 수 없으십니다.</li>
              <li>00700 프리, T글로벌 외국인 전용 요금제 등 SKT 국제전화 부가서비스 이용고객께도 00700 전 국가 100원 무제한 혜택은 적용되지 않습니다.</li>
              <li>스타벅스 카페라떼 Tall 1잔은 모바일 쿠폰으로 발송될 예정이며, 이벤트 응모 후 월요일에 일괄 발송됩니다.</li>
              <li>비정상적이거나 불법적인 방법으로 이벤트에 참여하신 경우, 이벤트 대상에서 제외될 수 있습니다.</li>
              <li>이벤트 관련 문의 : SK국제전화 00700 고객센터: 1599-0070(월~금, 9시~18시)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 00700 앱 가입(웰컴 기프트 제공) 이벤트 운영을 위해 아래와 같이 고객정보를 수집하며,
              고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보 항목 : 핸드폰번호(무선번호)</li>
              <li>개인정보의 수집 및 이용목적 : 이벤트 운영 및 경품 제공, 이벤트 관련 고객 민원 대응, 00700 이용요금 공제</li>
              <li>개인정보 보유 및 이용기간 : <strong>이벤트 운영 기간 포함 경품 제공 및 고객 민원 대응, 00700 요금 공제를 위해 6개월까지 보유</strong></li>
            </ul>
            <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부시 이벤트 참여가 제한될 수 있습니다.</p>
            <p>SK국제전화 00700이 운영하는 이벤트 참여 및 경품 수령을 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    <!-- //팝업 -->
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  name: 'WellcomeGift',
  components: {
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    isVisiblePrivacyPopup: false,
    agreePrivacy:false,
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    telNumber:{
      first:'010',
      middle: '',
      last:''
    },
    authNumberInputVisible: false,
    ticket: '',
    event_no:'EV000000000000000003',
    auth_no: '',
  }
 },
 computed: {
  svc_tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  callbackAlert(){
    return this.$store.getters.CALLBACK_ALERT
  }
 },
 watch: {
  callbackAlert: {
    deep: true,
    handler() {
      if (this.callbackAlert.code === '0002' || this.callbackAlert.code === '0006' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E0027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E021' || this.callbackAlert.code === 'E022' || this.callbackAlert.code === 'E037') {
        this.resetPage()
        window.scrollTo(0, 0)
        document.getElementById('wrap').scrollTop = 0
      }
      if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
        this.authNumberInputVisible = false,
        this.telNumber = {
          first:'010',
          middle: '',
          last:''
        },
        this.auth_no = ''
        this.ticket = ''
      }
      if (this.callbackAlert.code === 'E001') { // 인증시간 초과
        this.auth_no = ''
        this.ticket = ''
      }
    }
  }
 },
 created () {},
 mounted () {},
 methods: {
  alertPopupHandler() {
    this.alertPopup.visible = false
    this.alertPopup.message = ''
    if (this.alertPopup.target) {
      this.$refs[this.alertPopup.target].focus()
    }
    this.alertPopup.target = ''
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
      return false
    } else {
      return true
    }
  },
  checkAuthNumber() {
    if (!this.auth_no) {
      this.alertPopup.target = 'certNumber'
      this.showAlertPopup('인증번호를 입력해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkSMSCert() {
    if(!this.isSmsCert) {
      this.showAlertPopup('SMS인증을 완료해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkselect(){
    if(!this.agreePrivacy){
      this.showAlertPopup('개인정보 수집에 동의하셔야 신청이 가능합니다.')
      return false
    }else {
      return true
    }
  },
  // SMS 인증 요청
  requestSMSCert() {
    if (this.checkPhoneNumber()) {
      api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
      .then(response => {
        if (response.data.sms_ticket) {
          this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
          this.ticket = response.data.sms_ticket
          this.authNumberInputVisible = true
        }
      })
    }
  },
  // 인증 번호로 SMS 인증 확인
  requestCert() {
    if (this.checkAuthNumber()) {
      api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
      .then(response => {
        if (response.data.result_cd === '0001') {
          this.isSmsCert = true
          this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
        }
      })
    }
  },
  //신청완료
  registrationFinal() {
    if (this.checkselect() && this.checkPhoneNumber() && this.checkSMSCert()) {
      api.EventPostReqEntry({
        sms_ticket: this.ticket,
        event_no: this.event_no,
        svc_tel_no: this.svc_tel_no
      })
      // api.EventPostWelGift({ svc_tel_no: this.svc_tel_no })
      // .then(response => {
      //   if (response.data.result_cd === 'E037') {
      //     this.$store.commit('ON_ALERT_POPUP', { message: '죄송합니다. 고객님은 이벤트 참여 대상이 아니십니다.', type: 'alert', code: 'E037'})
      //   }
      //   else if(response.data.result_cd === '0002') {
      //     api.EventPostReqEntry({
      //         sms_ticket: this.ticket,
      //         event_no: this.event_no,
      //         svc_tel_no: this.svc_tel_no
      //       })
      //   }
      // })
    }
  },
  resetPage(){
    this.agreePrivacy = false,
    this.telNumber= {
      first:'010',
      middle: '',
      last:''
    },
    this.authNumberInputVisible = false,
    this.auth_no = ''
    this.ticket = ''
    this.isSmsCert = false
  },
  //20210917 이벤트 종료
  onLoad() {
    var popitem = document.getElementById("WELCOME_GIFT");

     if (new Date() >= new Date('09/17/2021 11:59:59')) {   //반영 시간 지정
        this.showAlertPopup('고객님들의 성원에 힘입어 조기 종료되었습니다.<br>감사합니다.')
         popitem.classList.add("closeEvent");
    }
  }
 }
}
</script>

<style lang="scss" scoped>
.event_wrap.type2.type_05 .select_box select{
  opacity: 1 !important;
  color: #6c6c6c !important;
  font-weight: 400;
}
.table_type01.w100 input { font-size: 16px; }

.welcome {
  & > .event_view > div { text-align: left; }
  .event_view_field {
    margin-top: 0;
    padding: 120px 110px;
    background-color: #9d68ea;
    .event_header {
      text-align: center;
      img { width: auto; }
    }
    .event_content {
      .simple_type {
        padding-bottom: 55px;
        border-bottom: 1px solid #ae81ee;
        dl {
          margin-top: 95px;
          dt {
            margin-bottom: 28px;
            color: #fff;
            font-size: 36px;
            line-height: 1;
          }
          dd {
            &:after {
              content: ''; display: block; clear: both; overflow: hidden;
            }
          }
        }
        .insert_phone {
          float: left;
          width: 75%;
          height: 90px;
          & > * { vertical-align: middle; }
          select {
            width: 30%;
            height: 100%;
            color: #000;
            font-size: 32px;
            font-family: 'arial', Sans-serif;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            background: #fff url('/content/images/btn_arrow_selelct.png') no-repeat calc(100% - 12px) 50%/16px 9px;
            border-radius: 10px;
          }
          input[type=number], input[type=text] {
            width: 30%;
            height: 100%;
            padding: 0;
            color: #000;
            font-size: 32px;
            font-family: 'arial', Sans-serif;
            line-height: 90px;
            text-align: center;
            background-color: #fff;
            border-radius: 10px;
          }
          .txt_hyphen {
            display: inline-block;
            position: relative;
            width: 5%;
            height: 100%;
            text-align: center;
            line-height: 100%;
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: 8px; height: 2px; background-color: #fff;
              margin: -1px 0 0 -4px;
            }
          }
        }
        .insert_certNum {
          float: left;
          width: 22.5%;
          height: 90px;
          margin-right: 3.75%;
          input[type=number], input[type=text] {
            width: 100%;
            height: 100%;
            padding: 0;
            color: #000;
            font-size: 32px;
            font-family: 'arial', Sans-serif;
            line-height: 90px;
            text-align: center;
            background-color: #fff;
            border-radius: 10px;
          }
          & + .btn.btn_cert {
            float: left;
            width: auto;
            height: 90px;
            padding: 0 25px;
          }
        }
        .btn.btn_cert {
          display: block;
          float: right;
          width: 22%;
          height: 90px;
          color: #fff;
          font-size: 32px;
          background: #5a25a7;
          border-radius: 10px;
        }
      }
      .agree_box {
        display: block;
        position: relative;
        margin-top: 40px;
        padding-left: 60px;
        color: #fff;
        font-size: 32px;
        line-height: normal;
        letter-spacing: -0.05em;
        input[type=checkbox] {
          position: absolute;
          left: 5px;
          top: 3px;
          z-index: -1;
          opacity: 0;
          &:checked+label {
            background: url('/content/images/partnership/icon_checkbox_welcome_checked.png') no-repeat;
          }
        }
        label {
          position: absolute;
          left: 0;
          width: 49px;
          height: 49px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: url('/content/images/partnership/icon_checkbox_welcome.png') no-repeat;
        }
        a {
          color: inherit;
          font-size: inherit;
          line-height: inherit;
        }
        p {
          color: inherit;
          font-size: inherit;
          line-height: inherit;
          text-align: left;
        }
      }
      .event_active_btn {
        margin-top: 90px;
        text-align:  center;
        a {
          display: inline-block;
          padding: 30px 120px;
          border-radius: 55px;
          background-color: #5a25a7;
          img { width: auto; }
        }
      }
    }
  }
  .event_caution {
    background: #7237c6;
    padding: 140px 70px;
    color: #fff;
    h1 {
      margin-bottom: 65px;
      text-align: center;
      img { width: auto; }
    }
    li {
      position: relative;
      padding-left: 20px;
      font-size: 33px;
      font-weight: 300;
      line-height: 1.6;
      &:before {
        position: absolute;
        content: "-";
        top: -1px;
        left: 0;
      }
    }
  }
}
@media (max-width: 1023px) {
  .welcome {
    .event_view_field {
      padding: 40px 25px;
      .event_header {
        img { height: 20px; }
      }
      .event_content {
        .simple_type {
          padding-bottom: 20px;
          dl {
            margin-top: 30px;
            dt {
              font-size: 15px;
              margin-bottom: 10px;
            }
          }
          .insert_phone {
            height: 40px;
            select, input[type=number], input[type=text] { font-size: 16px; line-height: 40px; border-radius: 5px; }
            .txt_hyphen:before { width: 4px; height: 1px; margin-left: -2px; }
          }

          .insert_certNum {
            height: 40px;
            input[type=number], input[type=text] { font-size: 16px; line-height: 40px; border-radius: 5px; }
            & + .btn.btn_cert {
              height: 40px;
              padding: 0 10px;
            }
          }

          .btn.btn_cert {
            height: 40px;
            font-size: 13px;
            border-radius: 5px;
          }
        }
        .agree_box {
          margin-top: 15px;
          padding-left: 30px;
          font-size: 15px;
          input[type=checkbox]:checked+label {
            background-size: 100% auto;
          }
          label {
            width: 25px;
            height: 25px;
            background-size: 100% auto;
          }
        }
        .event_active_btn {
          margin-top: 20px;
          a {
            padding: 10px 40px;
            line-height: 0;
            img { height: 18px; }
          }
        }
      }
    }
    .event_caution {
      padding: 45px 25px;
      h1 {
        margin-bottom: 20px;
        img { height: 20px; }
      }
      li {
        font-size: 15px;
        padding: 0 0 0 10px;
        &:before {
          top: 0;
        }
      }
    }
  }
  .pop_wrap02 .medium { padding-bottom: 20px; }
}
</style>
