<template>
  <div
    id="CREDIT_RATING"
    class="eng"
  >
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents en"
      >
        <div class="sub_header bg05">
          <h3 class="title">
            Finance information
          </h3>
          <p class="txt">
            We promote our corporate values, leaping to the global carrier<br>with our transparent management
          </p>
          <img src="/content/images/sub_header_cp05.jpg">
        </div>

        <!-- tab -->
        <div class="tab_type01 tab2">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li>
              <router-link to="/eng/stockholders">
                Stockholders’ status
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/eng/credit_rating">
                Credit rating
              </router-link>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <div class="fs_table">
            <div class="table_title">
              <h4>Credit rating of corporate bill and asset backed short-term bond</h4>
              <h5 class="rating">
                A2+
              </h5>
            </div>
            <div class="table_type04 table_rating">
              <table>
                <caption>구분, 2015, 2016, 2017, 2018, 2019 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:34%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      Classification
                    </th>
                    <th scope="col">
                      2015
                    </th>
                    <th scope="col">
                      2016
                    </th>
                    <th scope="col">
                      2017
                    </th>
                    <th scope="col">
                      2018
                    </th>
                    <th scope="col">
                      2019
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>NICE Investors Service, Inc.</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Korea Ratings, Inc.</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Korea Investors Service, Inc.</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>A2+</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="img_box">
              <img
                src="/content/images/img_cp_rating01_2019.png"
                class="w_hide"
                alt="기업어음 및 전자단기사채 신용등급 NICE신용평가(주), 한국기업평가(주), 한국신용평가(주) 2014년부터 2018년까지 A2+ 신용등급입니다."
              >
              <img
                src="/content/images/img_cp_rating01_m_2019.png"
                class="m_hide"
                alt="기업어음 및 전자단기사채 신용등급 NICE신용평가(주), 한국기업평가(주), 한국신용평가(주) 2014년부터 2018년까지 A2+ 신용등급입니다."
              >
              <br><br><br>
              ※ We have stopped taking a credit rating for short-term bonds from credit rating agencies since 2017.<br>
              Instead, We have a credit rating from e-credible, which is widely used for common commercial business activities in Korea and our credit rating is A+.
            </div>
          </div>

          <div class="fs_table">
            <div class="table_title">
              <h4>Credit rating of corporate bond</h4>
              <h5 class="rating">
                A+
              </h5>
            </div>
            <div class="table_type04 table_rating">
              <table>
                <caption>구분, 2012, 2013, 2014, 2015, 2016 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:34%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                  <col style="width:11%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      Classification
                    </th>
                    <th scope="col">
                      2012
                    </th>
                    <th scope="col">
                      2013
                    </th>
                    <th scope="col">
                      2014
                    </th>
                    <th scope="col">
                      2015
                    </th>
                    <th scope="col">
                      2016
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>NICE Investors Service, Inc.</td>
                    <td>A</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Korea Ratings, Inc.</td>
                    <td>A</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Korea Investors Service, Inc.</td>
                    <td>A</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>A+</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="img_box">
              <img
                src="/content/images/img_cp_rating02.png"
                class="w_hide"
                alt="회사채 신용등급 NICE신용평가(주), 한국기업평가(주), 한국신용평가(주) 2011년~2012년 A등급 2013년~2015년 A+등급입니다."
              >
              <img
                src="/content/images/img_cp_rating02.png"
                class="m_hide"
                alt="회사채 신용등급 NICE신용평가(주), 한국기업평가(주), 한국신용평가(주) 2011년~2012년 A등급 2013년~2015년 A+등급입니다."
              >
              <br><br><br>
              ※ We are not evaluated by credit rating of corporate bond as it has not been issued since 2016.
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/EngHeader";
import BreadCrumb from "@/components/EngBreadCrumb";
import Footer from "@/components/EngFooter";

export default {
  name: 'CreditRating',
  metaInfo: {
    title: 'Credit rating | Finance information | Company | SK telink',
    titleTemplate: null
  },
  components: {
    Header,
    BreadCrumb,
    Footer
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
