<template>
  <div v-if="isVisible">
    <div
      class="pop_wrap m-size captchar"
      style="display:block"
    >
      <div class="alert">
        <h3> 보안코드입력</h3>
        <div class="popup-inner">
          <div class="popup-inner__captchar">
            <Captchar
              ref="captchar"
              :width="135"
              :height="42"
              @result="updateCaptcharState"
            />
          </div>
          <div
            class="btn_center"
          >
            <a
              :class="cType"
              @click="buttonHandler"
            >인증하기</a>
          </div>
        </div>
        <a
          href="javascript:;"
          class="pop-close"
          @click="popupClose"
        ><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <div
      id="msgDimd"
      class="dimd"
      style="display: block;"
    />
  </div>
</template>

<script>
import Captchar from '@/components/Captchar'
export default {
  name: 'DefaultPopup',
  components: {
    Captchar,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    cType: {
      type: String,
      required: false,
      default: 'btn_type01'
    },
  },
  data(){
    return{
      isPassCaptchar: false,
    }
  },
  methods:{
    popupClose(){
      this.$emit('close')
    },
    buttonHandler() {
      this.$emit('click')
    },
    updateCaptcharState(state) {
      this.isPassCaptchar = state
    },
  }
}
</script>

<style lang="scss">
.popup-inner{
  &__captchar{
    .v-captcha {
      display: block !important;
      .v-captcha__flex{
        .v-captcha__button{
          margin-left: auto !important;
        }
      }
      .v-captcha__input{
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}
.pop_wrap.captchar{
  z-index: 9998;
}
</style>