!<template>
  <div v-if="isVisible">
    <div
      id="popAlert01"
      class="inter_pop_wrap"
      style="display: block;"
    >
      <!-- 팝업에 id를 만들어 연동 -->
      <div class="alert">
        <h3>요금제 신청</h3>
        <div>
          <h4
            id=""
            class="title"
          >
            <span
              id=""
              class="txt_point01"
            >{{ certInfo.cust_nm }}</span>고객님 <br>
            <span class="txt_point01">{{ planName }}요금제</span>를 <br>
            신청하시겠습니까?
          </h4>

          <div class="table_type04">
            <table>
              <caption>선택, 휴대폰번호 나타내는 표입니다.</caption>
              <colgroup>
                <col style="width:35%;">
                <col style="width:65%;">
              </colgroup>
              <tbody id="phoneList">
                <tr>
                  <th>전화번호</th>
                  <td id="phone_num0">
                    {{ certInfo.svc_tel_no }}
                  </td>
                </tr>
                <tr>
                  <th>사용중인 요금제</th>
                  <td>{{ usagePlan }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <template v-if="type === 'special'">
            <div
              class="select_box w_100 mt20"
            >
              <label
                for="i_sDateInfoFrom"
                aria-hidden="true"
              >국가 선택</label>

              <select
                id="i_sDateInfoFrom"
                v-model="selectedCountry"
                name="i_sDateInfoFrom"
                class="select_type w_100p"
              >
                <option value="">
                  국가 선택
                </option>
                <option
                  v-for="(item, index) in list"
                  :key="index"
                  :value="item.natl_cd"
                >
                  {{ item.natl_nm }}
                </option>
              </select>
            </div>
            <div class="check_box mt20">
              <input
                id="terms01"
                v-model="agreeTerms"
                type="checkbox"
                name="id_terms01"
                class="check_single"
                @click="privacyPopup"
              >
              <label for="terms01"><span>개인정보 수집 및 이용에 동의합니다.</span></label>
            </div>
            <div class="btn_center single">
              <a
                class="btn_type01"
                @click="buttonHandlerSpecial"
              >신청하기</a>
            </div>
          </template>
          <template v-else-if="type === 'long'">
            <div
              class="select_box w_100 mt20"
            >
              <label
                for="i_sDateInfoFrom"
                aria-hidden="true"
              >요금제 선택</label>

              <select
                id="i_sDateInfoFrom"
                v-model="pricePlan"
                name="i_sDateInfoFrom"
                title="첫 조회기간"
                class="select_type w_100p"
              >
                <option value="">
                  요금제 선택
                </option>
                <option
                  v-for="(item, index) in pricePlanList"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </option>
              </select>
            </div>
            <div class="check_box mt20">
              <input
                id="terms01"
                v-model="agreeTerms"
                type="checkbox"
                name="id_terms01"
                class="check_single"
                @click="privacyPopup"
              >
              <label for="terms01"><span>개인정보 수집 및 이용에 동의합니다.</span></label>
            </div>
            <div class="btn_center single">
              <a
                class="btn_type01"
                @click="buttonHandlerLong"
              >신청하기</a>
            </div>
          </template>
          <template v-else>
            <div class="check_box mt20">
              <input
                id="terms01"
                v-model="agreeTerms"
                type="checkbox"
                name="id_terms01"
                class="check_single"
                @click="privacyPopup"
              >
              <label for="terms01"><span>개인정보 수집 및 이용에 동의합니다.</span></label>
            </div>
            <div class="btn_center single">
              <a
                class="btn_type01"
                @click="buttonHandler"
              >신청하기</a>
            </div>
          </template>
        </div>
        <a
          class="pop-close"
          @click="popupClose"
        ><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <div
      id="msgDimd"
      class="dimd"
      style="display: block;"
    />

    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    
    <!-- 20211123 - 개인정보수집동의문 추가 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 수집 및 이용 동의</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 및 이용 동의
          </h4>

          <div class="cont_term">
            <ul>
              <li>1. 수집/이용목적 : 요금제 신청</li>
              <li>2. 수집/이용항목 : 휴대폰번호</li>
              <li>3. 보유/이용기간 : <strong>요금제 종료 후 5년까지</strong><br>※ 전자상거래 등에서의 소비자보호에 관한 법률</li>
              <li>4. 개인정보 수집/이용에 동의하지 않으실 수 있으며, 동의하지 않을 경우 해당 서비스를 이용하실 수 없습니다.</li>
            </ul>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <!--// 20211123 - 개인정보수집동의문 추가 -->
  </div>
</template>

<script>
import Popup from '@/components/popup/Popup'

export default {
  name: 'ApplyPlan',
  components: {
    Popup
  },
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
    certInfo: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'etc'
    },
    planName: {
      type: String,
      required: true,
    },
    usagePlan: {
      type: String,
      required: false,
      default: '미사용'
    },
    list: {
      type: Array,
      required: false,
      default: () => { return [] }
    },

  },
  data() {
    return {
      selectedCountry: '',
      pricePlan: '',
      pricePlanList: [
        { label: '더 길게 요금제 60', value: 'COL06' },
        { label: '더 길게 요금제 80', value: 'COL08' },
        { label: '더 길게 요금제 220', value: 'COL22' },
        { label: '더 길게 요금제 380', value: 'COL38' }
      ],
      agreeTerms:'',
      isVisiblePrivacyPopup: false,
      alertPopup: {
        visible: false,
        message: '',
        target: ''
      },
    }
  },
  methods: {
    alertPopupHandler() {
      this.alertPopup.visible = false
      this.alertPopup.message = ''
      if (this.alertPopup.target) {
        this.$refs[this.alertPopup.target].focus()
      }
      this.alertPopup.target = ''
    },
    showAlertPopup(message) {
      this.alertPopup.message = message
      this.alertPopup.visible = true
    },

    privacyPopup() {
      if(!this.agreeTerms) {
        this.isVisiblePrivacyPopup = true
      }
    },
    popupClose() {
      this.$emit('update:is-visible', false)
    },
    buttonHandler() {
      if(!this.agreeTerms) {
        this.showAlertPopup('개인정보 수집/이용에 동의하지 않을 시,<br>요금제 신청이 불가합니다.');
        // alert('개인정보 수집/이용에 동의하지 않을 시,\n요금제 신청이 불가합니다.');
      } else {
        this.$emit('click')
      }
    },
    buttonHandlerSpecial() {
      if(!this.agreeTerms) {
        this.showAlertPopup('개인정보 수집/이용에 동의하지 않을 시,<br>요금제 신청이 불가합니다.');
        // alert('개인정보 수집/이용에 동의하지 않을 시,\n요금제 신청이 불가합니다.');
      } else {
        this.$emit('click', this.selectedCountry)
      }
    },
    buttonHandlerLong() {
      if(!this.agreeTerms) {
        this.showAlertPopup('개인정보 수집/이용에 동의하지 않을 시,<br>요금제 신청이 불가합니다.');
        // alert('개인정보 수집/이용에 동의하지 않을 시,\n요금제 신청이 불가합니다.');
      } else {
        this.$emit('click', this.pricePlan)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.pop_wrap02 {
  .medium {
    padding-bottom: 30px;
    .pop_cont02 {
      overflow: hidden;
      margin-bottom: 0;
      text-align: center;
      .title {
        margin-bottom: 20px;
        color: #444;
        font-size: 24px;
      }
      .cont_term {
        overflow: hidden;
        margin-top: 30px;
        padding: 15px 20px;
        line-height: 20px;
        border: 1px solid #dadada;
        text-align: left;
        ul {
          margin-top: 3px;
        }
      }
    }
  }
}
.check_box { display: inline-block; }

@media (max-width: 1023px) {
  .check_box { display: block; }
  .pop_wrap02 {
    .medium {
      padding-bottom: 20px;
      .pop_cont02 {
        margin: 0;
        padding: 24px 0 0;
        .title {
          margin: 0 15px 10px;
          font-size: 18px;
        }
        .cont_term {
          height: 155px;
          overflow: auto;
          margin: 0 15px 0;
          padding: 15px 10px;
          font-size: 14px;
        }
        ul {
          li {
            font-size: 14px;
            font-weight: 400;
          }
        }
        .check_box {
          margin: 15px 0 0;
          display: block;
        }
      }
    }
  }
}
</style>