
<template>
  <div id="ALL_PASS">
    <div
      id="boardWEB"
      class="event_wrap type2 type_05"
    >
      <div class="event_view">
        <div>
          <span class="w_hide">
            <img
              alt="국제전화 구독형 상품 Allpass 요금제 출시!"
              src="/content/images/partnership/210817_allpass_top1.jpg"
              width="1120"
              style="height: auto;"
            ><img
              alt="Allpass 요금제 안내"
              src="/content/images/partnership/210817_allpass_top2.png"
              width="1120"
              style="height: auto;"
            ><img
              alt="지금 요금제에 가입하시면 추가 혜택을 드려요!"
              src="/content/images/partnership/210817_allpass_top3.png"
              width="1120"
              style="height: auto;"
            >
          </span>
          <span class="m_hide">
            <img
              alt="국제전화 구독형 상품 Allpass 요금제 출시!"
              src="/content/images/partnership/210817_allpass_top1.jpg"
              width="640"
              style="height: auto;"
            ><img
              alt="Allpass 요금제 안내"
              src="/content/images/partnership/210817_allpass_top2.png"
              width="640"
              style="height: auto;"
            ><img
              alt="지금 요금제에 가입하시면 추가 혜택을 드려요!"
              src="/content/images/partnership/210817_allpass_top3.png"
              width="640"
              style="height: auto;"
            >
          </span>
          <div class="notice event_view_field allpass">
            <div class="align-center">
              <!-- to개발 / 2021-02-24 퍼블수정 | 인라인 스타일 삭제, 클래스 추가 -->
              <div
                class="check_box"
                style="padding-bottom: 20px; "
              >
                <input
                  id="yes"
                  v-model="agreePrivacy"
                  name="yesno"
                  class="myClass YNchk"
                  type="checkbox"
                  value="yes01"
                >
                <label for="yes"> <span style="font-size: 14px;">SK국제전화 요금제 혜택을 받기 위해 개인정보 수집하는 것에 동의합니다.</span></label>
                <a
                  class="btn_view pop_focus"
                  @click="isVisiblePrivacyPopup = true"
                >자세히 보기</a>
              </div>
              <div class="table_type01 w100">
                <dl>
                  <dt>요금제</dt>
                  <dd>
                    <div class="select_box seb2">
                      <!-- <label
                        aria-hidden="true"
                        for="i_sUserNm"
                      >요금제 선택</label> -->
                      <select
                        id="i_sUserNm"
                        ref="select1"
                        v-model="selectRatePlan"
                        name="i_sUserNm"
                        title="요금제 선택하기"
                        @change="onChange"
                      >
                        <option value="요금제 선택">
                          요금제 선택
                        </option>
                        <option :value="['COZ75','COZ83','COZ87','COZ79','COZ91']">
                          All pass 10,000
                        </option>
                        <option :value="['COZ76','COZ84','COZ88','COZ80','COZ92']">
                          All pass 20,000
                        </option>
                        <option :value="['COZ77','COZ85','COZ89','COZ81','COZ93']">
                          All pass 30,000
                        </option>
                        <option :value="['COZ78','COZ86','COZ90','COZ82','COZ94']">
                          All pass 50,000
                        </option>
                      </select>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>혜택</dt>
                  <dd>
                    <div class="select_box seb2">
                      <select
                        v-if="selectRatePlan"
                        id="i_sBenefit"
                        ref="select2"
                        v-model="selectBenefit"
                        name="i_sBenefit"
                        title="혜택 선택하기"
                        @change="selectedBenegfit"
                      >
                        <option
                          value="혜택을 선택해주세요"
                        >
                          혜택을 선택해주세요
                        </option>
                        <option
                          v-if="selectRatePlan =='요금제 선택'"
                          value="요금제를 먼저 선택해주세요"
                        >
                          요금제를 먼저 선택해주세요
                        </option>
                        <option
                          v-if="selectRatePlan !=='요금제 선택'"
                          value="0"
                        >
                          GS25편의점상품권
                        </option>
                        <option
                          v-if="selectRatePlan !=='요금제 선택'"
                          value="1"
                        >
                          신세계상품권
                        </option>
                        <option
                          v-if="selectRatePlan !=='요금제 선택'"
                          value="2"
                        >
                          롯데마트상품권
                        </option>
                        <option
                          v-if="selectRatePlan !=='요금제 선택'"
                          value="3"
                        >
                          SK주유권
                        </option>
                        <option
                          v-if="selectRatePlan !=='요금제 선택'"
                          value="4"
                        >
                          해피콘
                        </option>
                      </select>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>SMS 인증</dt>
                  <dd>
                    <div class="phone-box_wrap">
                      <div class="phone-box">
                        <div
                          class="select_box type"
                          style="vertical-align: middle;"
                        >
                          <select
                            id="call_select01"
                            ref="telNumberFirst"
                            v-model="telNumber.first"
                            name="i_sHpNo1"
                            title="무선전화 앞자리 선택"
                          >
                            <option
                              value="010"
                            >
                              010
                            </option>
                            <option value="011">
                              011
                            </option>
                            <option value="016">
                              016
                            </option>
                            <option value="017">
                              017
                            </option>
                            <option value="018">
                              018
                            </option>
                            <option value="019">
                              019
                            </option>
                          </select>
                        </div>
                        <span class="txt_hyphen">-</span>
                        <input
                          ref="telNumberMiddle"
                          v-model="telNumber.middle"
                          name="i_sHpNo2"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen">-</span>
                        <input
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <a
                        class="btn btn_sendnum"
                        @click="requestSMSCert"
                      >SMS인증하기</a>
                    </div>
                  </dd>
                </dl>
                <dl
                  v-if="authNumberInputVisible"
                  id="numberCheck"
                  class="C030 duplPhono"
                >
                  <dt>인증번호 입력</dt>
                  <dd>
                    <input
                      ref="certNumber"
                      v-model="auth_no"
                      name="i_sCertificationNo"
                      title="인증번호"
                      type="text"
                      maxlength="6"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    ><a
                      class="btn btn_checknum"
                      @click="requestCert"
                    >인증번호확인</a>
                  </dd>
                </dl>
              </div>
              <!-- to개발 / 2021-02-24 퍼블수정 | 인라인 스타일 삭제, 클래스 추가-->
              <div class="align-center">
                <div class="btn_center type_02">
                  <a
                    id="btnApply"
                    class="btn_type01 round-type"
                    @click="registrationFinal"
                  >요금제 신청하기</a>
                </div>
              </div>
            </div>
          </div>
          <div class="event_view_field">
            <div class="event_caution">
              <h1>유의사항</h1>
              <ul>
                <li>본 요금제 가입을 위해선 개인정보 수집 및 활용, 취급위탁에 대한 동의가 필요합니다.</li>
                <li>본 요금제에 대한 가입 혜택은 국제전화 00700 에 대해서만 적용됩니다.</li>
                <li>SK국제전화에서 판매 중인 타 국제전화요금제와 중복가입이 불가합니다.</li>
                <li>불법스팸, 상업적 용도 등 불법적인 국제전화 이용 시 즉시 서비스 제공이 중단됩니다.</li>
                <li>월 제공분수 초과 이용시, 초과분에 대해서는 00700 표준요금이 적용됩니다.</li>
                <li>요금월(무선주기 : 전월21일~당월20일) 중도에 해당 요금제를 가입 또는 해지하는 경우 기본료는 월할 계산으로 적용됩니다.</li>
                <li>화상/위성전화 등 특수대역대 이용시 무료통화는 적용되지 않으며, 선불폰 이용은 불가합니다. </li>
              </ul>
              <dl>
                <dt>* 요금제 관련 자세한 문의는 SK국제전화 고객센터로 연락 부탁 드립니다.</dt>
                <dd>고객센터 전화번호 : 1599-0070</dd>
                <dd>운영시간 : 월~금(오전9시~오후6시)</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 국제전화 이용에 대한 혜택제공을 위해 아래와 같이 고객정보를 수집하며,<br>고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보의 항목 : 휴대폰번호(연락처)</li>
              <li>개인정보의 수집 및 이용목적 : SK국제전화 요금제에 대한 고객상담 진행</li>
              <li>개인정보의 보유 및 이용기간 : <strong>SK국제전화 요금제 가입 시점부터 해지일자까지</strong></li>
            </ul>
            <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 요금제 혜택 제공에 제한이 될 수 있습니다.</p>
            <p>SK국제전화 요금제 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    <!-- //팝업 -->
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  name: 'AllPass',
  components: {
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    isVisiblePrivacyPopup: false,
    agreePrivacy:false,
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    telNumber:{
      first:'010',
      middle: '',
      last:''
    },
    authNumberInputVisible: false,
    ticket: '',
    event_no:'ZZ000000000000005884',
    auth_no: '',
    selectRatePlan:'요금제 선택',
    selectBenefit:'혜택을 선택해주세요',
  }
 },
 computed: {
  svc_tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  add_info(){
    return this.selectRatePlan[this.selectBenefit]
  },
  callbackAlert(){
    return this.$store.getters.CALLBACK_ALERT
  }
 },
 watch: {
  callbackAlert: {
    deep: true,
    handler() {
      if (this.callbackAlert.code === '0002' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E0027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E021' || this.callbackAlert.code === 'E022') {
        this.resetPage()
        window.scrollTo(0, 0)
        document.getElementById('wrap').scrollTop = 0
      }
      if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
        this.authNumberInputVisible = false,
        this.telNumber = {
          first:'010',
          middle: '',
          last:''
        },
        this.auth_no = ''
        this.ticket = ''
      }
      if (this.callbackAlert.code === 'E001') { // 인증시간 초과
        this.auth_no = ''
        this.ticket = ''
      }
    }
  }
},
 created () {},
 mounted () {
 },
 methods: {
  selectedBenegfit() {
    this.add_info
  },
  alertPopupHandler() {
    this.alertPopup.visible = false
    this.alertPopup.message = ''
    if (this.alertPopup.target) {
      this.$refs[this.alertPopup.target].focus()
    }
    this.alertPopup.target = ''
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
      return false
    } else {
      return true
    }
  },
  checkAuthNumber() {
    if (!this.auth_no) {
      this.alertPopup.target = 'certNumber'
      this.showAlertPopup('인증번호를 입력해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkSMSCert() {
    if(!this.isSmsCert) {
      this.showAlertPopup('SMS인증을 완료해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkselect(){
    if(!this.agreePrivacy){
      this.showAlertPopup('개인정보 수집에 동의하셔야 신청이 가능합니다.')
      return false
    }else if(this.selectRatePlan ==='요금제 선택'){
      this.showAlertPopup('요금제를 선택해주세요.')
      return false
    }else if((this.selectBenefit ==='혜택을 선택해주세요')){
      this.showAlertPopup('혜택을 선택해주세요.')
      return false
    }else {
      return true
    }
  },
  onChange() {
    this.selectBenefit = '혜택을 선택해주세요'
  },
  // SMS 인증 요청
  requestSMSCert() {
    if (this.checkPhoneNumber()) {
      api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
      .then(response => {
        if (response.data.sms_ticket) {
          this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
          this.ticket = response.data.sms_ticket
          this.authNumberInputVisible = true
        }
      })
    }
  },
  // 인증 번호로 SMS 인증 확인
  requestCert() {
    if (this.checkAuthNumber()) {
      api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
      .then(response => {
        if (response.data.result_cd === '0001') {
          this.isSmsCert = true
          this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
        }
      })
    }
  },
  //신청완료
  registrationFinal() {
    if (this.checkselect() && this.checkPhoneNumber() && this.checkSMSCert()) {
      api.EventPostReqEvent({
        sms_ticket: this.ticket,
        event_no: this.event_no,
        add_info: this.add_info,
      })
    }
  },
  resetPage(){
    this.agreePrivacy = false,
    this.telNumber= {
      first:'010',
      middle: '',
      last:''
    },
    this.selectBenefit = '혜택을 선택해주세요',
    this.authNumberInputVisible = false,
    this.selectRatePlan = '요금제 선택',
    this.auth_no = ''
    this.ticket = ''
    this.isSmsCert = false
  }
 }
}
</script>

<style lang="scss" scoped>
.event_wrap.type2.type_05 .select_box select{
  opacity: 1 !important;
  color: #6c6c6c !important;
  font-weight: 400;
}
.table_type01.w100 input { font-size: 16px; }

.event_caution {
  background: #2b2b2b;
  padding: 60px 60px 70px;
  color: #fff;
  h1 {
      margin-bottom: 20px;
      font-size: 38px;
  }
  li, dd {
    position: relative;
    color: #adadad;
    font-size: 24px;
    font-weight: 300;
    padding: 10px 0 10px 17px;
    margin-bottom: -7px;
    letter-spacing: -2px;
    line-height: 1.5;
    &:before {
      position: absolute;
      content: "-";
      top: 7px;
      left: 0;
    }
  }
  dl {
    margin-top: 40px;
    dt {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.5;
    }
  }
}
@media (max-width: 1023px) {
  .event_caution {
    padding: 30px 25px;
    h1 {
        margin-bottom: 10px;
        font-size: 19px;
    }
    li, dd {
      font-size: 15px;
      padding: 7px 0 7px 10px;
      letter-spacing: -1px;
      &:before {
        top: 5px;
      }
    }
    dl {
      margin-top: 20px;
      dt {
        margin-bottom: 5px;
        font-size: 15px;
      }
    }
  }
}
</style>