<template>
  <div id="PRIVACY_GUIDE">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            이용자 피해 예방 가이드
          </h3>
          <p class="txt">
            고객님의 개인정보보호를 위해<br>SK 텔링크에서 제공하는 피해 예방 정보를 확인하세요.
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- to.개발 on 되어있는 li가 가운데로 와야함. -->
        <div class="tab_scroll01 tab7">
          <!-- tab갯수에따른 중복클래스 : 4개일때: 클래스 없음, 5개일때: tab5, 6개일때: tab6, 7개일때: tab7 -->
          <ul>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/customer/privacy_guide">
                동영상&amp; 웹툰<br>피해예방교육
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_illegal_TM">
                불법 TM신고
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_Msafer">
                명의도용방지
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_smishing">
                스미싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_phishing">
                피싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_pharming">
                파밍
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_spam_message">
                스팸메시지
              </router-link>
            </li>
          </ul>
        </div>

        <!-- guide_wrap -->
        <div class="guide_wrap">
          <div class="guide_box01">
            <div class="sub_title">
              <h4 class="title">
                동영상으로 배우는<br> 이용자 피해 예방법
              </h4>
              <p class="txt txt_point01">
                <span class="icon_star">*</span>
                자료 제공 : 방송통신이용자 정보포털(
                <a
                  href="http://www.wiseuser.go.kr"
                  target="blank"
                  title="방송통신이용자 정보포털 새창으로 열림"
                >http://www.wiseuser.go.kr</a>)
              </p>
            </div>

            <div class="table_type02">
              <ul class="table_wid04">
                <li>
                  <dl class="child1">
                    <dt>No</dt>
                    <dd>6</dd>
                  </dl>
                  <dl class="child2">
                    <dt>바로 학습하기</dt>
                    <dd class="btn">
                      <a
                        href="http://wiseuser.go.kr/handi_edu/data/2019/02_E_learning/html/preventing2_damage1.html"
                        target="_blank"
                        title="방송통신서비스 개요 및 확인사항 새창열림"
                      >VOD</a>
                    </dd>
                  </dl>
                  <dl class="child3">
                    <dt>제목</dt>
                    <dd class="txt_left">
                      <span>방송통신서비스 개요 및 확인사항 </span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl class="child1">
                    <dt>No</dt>
                    <dd>5</dd>
                  </dl>
                  <dl class="child2">
                    <dt>바로 학습하기</dt>
                    <dd class="btn">
                      <a
                        href="http://wiseuser.go.kr/handi_edu/data/2019/02_E_learning/html/preventing2_damage7.html"
                        target="_blank"
                        title="보이스피싱 / 스미싱 / 파밍 / 메신저 피싱 피해예방 새창열림"
                      >VOD</a>
                    </dd>
                  </dl>
                  <dl class="child3">
                    <dt>제목</dt>
                    <dd class="txt_left">
                      <span>보이스피싱 / 스미싱 / 파밍 / 메신저 피싱 피해예방</span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl class="child1">
                    <dt>No</dt>
                    <dd>4</dd>
                  </dl>
                  <dl class="child2">
                    <dt>바로 학습하기</dt>
                    <dd class="btn">
                      <a
                        href="http://wiseuser.go.kr/handi_edu/data/2019/02_E_learning/html/preventing2_damage8.html"
                        target="_blank"
                        title="개인정보보호 / 명의도용 / 해외 데이터로밍 피해예방 새창열림"
                      >VOD</a>
                    </dd>
                  </dl>
                  <dl class="child3">
                    <dt>제목</dt>
                    <dd class="txt_left">
                      <span>개인정보보호 / 명의도용 / 해외 데이터로밍 피해예방</span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl class="child1">
                    <dt>No</dt>
                    <dd>3</dd>
                  </dl>
                  <dl class="child2">
                    <dt>바로 학습하기</dt>
                    <dd class="btn">
                      <a
                        href="http://wiseuser.go.kr/handi_edu/data/2019/02_E_learning/html/preventing2_damage9.html"
                        target="_blank"
                        title="미환급액 / 이동전화 분실·습득·침수 시 대처법 새창열림"
                      >VOD</a>
                    </dd>
                  </dl>
                  <dl class="child3">
                    <dt>제목</dt>
                    <dd class="txt_left">
                      <span>미환급액 / 이동전화 분실·습득·침수 시 대처법</span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl class="child1">
                    <dt>No</dt>
                    <dd>2</dd>
                  </dl>
                  <dl class="child2">
                    <dt>바로 학습하기</dt>
                    <dd class="btn">
                      <a
                        href="http://wiseuser.go.kr/handi_edu/data/2019/02_E_learning/html/preventing_damage3.html"
                        target="_blank"
                        title="시각장애인을 위한 서비스 안내  새창열림"
                      >VOD</a>
                    </dd>
                  </dl>
                  <dl class="child3">
                    <dt>제목</dt>
                    <dd class="txt_left">
                      <span>시각장애인을 위한 서비스 안내 </span>
                    </dd>
                  </dl>
                </li>
                <li>
                  <dl class="child1">
                    <dt>No</dt>
                    <dd>1</dd>
                  </dl>
                  <dl class="child2">
                    <dt>바로 학습하기</dt>
                    <dd class="btn">
                      <a
                        href="http://wiseuser.go.kr/handi_edu/data/2019/02_E_learning/html/preventing_damage4.html"
                        target="_blank"
                        title="청각장애인을 위한 서비스 안내 새창열림"
                      >VOD</a>
                    </dd>
                  </dl>
                  <dl class="child3">
                    <dt>제목</dt>
                    <dd class="txt_left">
                      <span>청각장애인을 위한 서비스 안내</span>
                    </dd>
                  </dl>
                </li>
              </ul>
            </div>
          </div>

          <div class="guide_box02 gray">
            <div class="sub_title">
              <h4 class="title">
                웹툰으로<br> 보는 이용자 피해 예방법
              </h4>
              <p class="txt txt_point01">
                <span class="icon_star">*</span>자료제공 : 방송통신이용자 정보포털 (<a
                  href="http://www.wiseuser.go.kr"
                  target="_blank"
                  title="방송통신이용자 정보포털 새창으로 열림"
                >http://www.wiseuser.go.kr</a>)
              </p>
            </div>

            <div class="guide_list03">
              <ul>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=124"
                    target="_blank"
                    title="파밍 예방법 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide01.png"
                    alt="파밍 예방법"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=123"
                    target="_blank"
                    title="보이스피싱 예방법 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide02.png"
                    alt="보이스피싱 예방법"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=121"
                    target="_blank"
                    title="휴대폰페이백 주의보 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide03.png"
                    alt="휴대폰페이백 주의보"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=119"
                    target="_blank"
                    title="통신서비스재정제도 안내 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide04.png"
                    alt="통신서비스재정제도 안내"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=127"
                    target="_blank"
                    title="큐싱 예방편 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide05.png"
                    alt="큐싱 예방편"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=129"
                    target="_blank"
                    title="통신 서비스재정제도 안내 2 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide06.png"
                    alt="통신 서비스재정제도 안내 2"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=115"
                    target="_blank"
                    title="이동전화 가입시 주의사항 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide07.png"
                    alt="이동전화 가입시 주의사항"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=112"
                    target="_blank"
                    title="스마트폰중독 피해·예방법 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide08.png"
                    alt="스마트폰중독 피해·예방법"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=102"
                    target="_blank"
                    title="불법메시지를 이용한 스미싱 1 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide09.png"
                    alt="불법메시지를 이용한 스미싱 1"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=103"
                    target="_blank"
                    title="불법메시지를 이용한 스미싱 2 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide10.png"
                    alt="불법메시지를 이용한 스미싱 2"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=104"
                    target="_blank"
                    title="불법메시지를 이용한 스미싱 3 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide11.png"
                    alt="불법메시지를 이용한 스미싱 3"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=105"
                    target="_blank"
                    title="불법메시지를 이용한 스미싱 4 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide12.png"
                    alt="불법메시지를 이용한 스미싱 4"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=97"
                    target="_blank"
                    title="앱 결제 피해사례 및 예방 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide13.png"
                    alt="앱 결제 피해사례 및 예방"
                  ></a>
                </li>
                <li>
                  <a
                    href="http://wiseuser.go.kr/square_view.do?boardtypecode=5420&boardno=128"
                    target="_blank"
                    title="스마트폰 이용자 건강관리 새창으로 열림"
                  ><img
                    src="/content/images/banner_guide14.png"
                    alt="스마트폰 이용자 건강관리"
                  ></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- //guide_wrap -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl";

export default {
  name: 'PrivacyGuide',
  metaInfo: {
    title: '동영상 & 웹툰 피해예방교육 | 이용자 피해 예방가이드 | 고객센터 | SK텔링크',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
