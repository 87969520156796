var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"INTRODUCE_CIBI"}},[_c('Header'),_c('div',{attrs:{"id":"container"}},[_c('BreadCrumb'),_c('div',{staticClass:"contents",attrs:{"id":"contents"}},[_vm._m(0),_c('div',{staticClass:"tab_type01"},[_c('ul',[_c('li',{class:_vm.tabIndex == 1 ? 'on': '',attrs:{"id":"taba","title":"현재메뉴 선택됨"}},[_c('div',{on:{"click":function($event){return _vm.tabActive(1)}}},[_vm._v(" CI ")])]),_c('li',{class:_vm.tabIndex == 2 ? 'on': '',attrs:{"id":"tabb"}},[_c('div',{on:{"click":function($event){return _vm.tabActive(2)}}},[_vm._v(" BI ")])])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 1),expression:"tabIndex == 1"}],staticClass:"company mb0",attrs:{"id":"tab1"}},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 2),expression:"tabIndex == 2"}],staticClass:"company mb0",attrs:{"id":"tab2"}},[_vm._m(6),_vm._m(7),_vm._m(8)])])],1),_c('Footer'),_c('SummitControl')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_header bg01"},[_c('h3',{staticClass:"title"},[_vm._v(" CI/BI ")]),_c('p',{staticClass:"txt"},[_vm._v(" SK텔링크의 기업아이덴티티를 소개합니다 ")]),_c('img',{attrs:{"src":"/content/images/sub_header_cp01.jpg","alt":"비쥬얼 이미지"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ci_list"},[_c('h4',[_vm._v(" 로고마크 ")]),_c('ul',[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo01.png","alt":"SK 텔링크"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci_Sktelink.ai","download":""}},[_vm._v("AI 다운로드")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci_Sktelink.jpg","download":""}},[_vm._v("JPG 다운로드")])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo02.png","alt":"SK telink"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci-eng_SKtelink.ai","download":""}},[_vm._v("AI 다운로드")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci-eng_SKtelink.jpg","download":""}},[_vm._v("JPG 다운로드")])])]),_c('div',{staticClass:"txt_box"},[_c('p',[_vm._v(" 로고마크는 SK텔링크의 공식적인 상징으로 어떤 경우라도 형태가 변형되어서는 안되며 인쇄물, 프로모션물 등 "),_c('br',{staticClass:"w_hide"}),_vm._v(" 여러 매체에 사용되어 "),_c('span',{staticClass:"txt_point01"},[_vm._v("SK텔링크의 기업 및 브랜드 이미지 전달에 중요한 역할을 하는 요소입니다.")])]),_c('p',{staticClass:"txt_point01 w_hide"},[_c('span',{staticClass:"icon_star"},[_vm._v("*")]),_vm._v("로고마크의 사용시 변형을 방지하기 위하여 웹(web)상에 수록된 원고용 파일을 다운 받아 사용해 주시기 바랍니다. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ci_list bg_rule"},[_c('div',[_c('h4',[_vm._v(" CI공간규정 ")]),_c('ul',[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo03.png","alt":"로고마크 ‘ K ’의 높이를 X라고 가정할 때, ‘행복날개’의 높이는 1.1X입니다. 한글 사명의 크기는 0.8X이고, SK 로고와 사명의 간격은 한글은 0.8X 컬러로 표현할 때 로고마크는 SK Red, 사명은 SK Orange로 적용하며, 흑백으로 표현할 때 로고마크는 Black 60%로 적용합니다."}})])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo04.png","alt":"로고마크 ‘ K ’의 높이를 X라고 가정할 때, ‘행복날개’의 높이는 1.1X입니다. 영문사명은 0.55X이고, SK 로고와 사명의 간격은 영문은 0.55X입니다. 컬러로 표현할 때 로고마크는 SK Red, 사명은 SK Orange로 적용하며, 흑백으로 표현할 때 로고마크는 Black 60%로 적용합니다."}})])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"color_list"},[_c('h4',[_vm._v(" 주요색상 ")]),_c('div',{staticClass:"color_list_inner"},[_c('div',{staticClass:"red"},[_c('h5',[_vm._v(" SK Red ")]),_c('ul',[_c('li',[_vm._v("Pantone : 186C")]),_c('li',[_vm._v("CMYK : 0.100.8.4")]),_c('li',[_vm._v("RGB : 234.0.44")])])]),_c('div',{staticClass:"orange"},[_c('h5',[_vm._v("SK Orange")]),_c('ul',[_c('li',[_vm._v("Pantone : 158C")]),_c('li',[_vm._v("CMYK : 0.66.97.0")]),_c('li',[_vm._v("RGB : 255.122.0")])])])]),_c('div',{staticClass:"txt_box"},[_c('p',[_vm._v(" 로고마크와 심벌마크의 "),_c('span',{staticClass:"txt_point02"},[_vm._v("SK Red는 역동적이고 생명력 넘치는 적색")]),_vm._v("이며 "),_c('span',{staticClass:"txt_point01"},[_vm._v("SK orange는 ‘행복’, ‘친근감’, ‘환영’")]),_vm._v("을 상징하는 색입니다."),_c('br',{staticClass:"w_hide"}),_vm._v(" 로고마크는 백색 바탕에 지정색상 사용을 원칙으로 합니다."),_c('br',{staticClass:"w_hide"}),_vm._v(" 특히, 지속적인 동일 색상의 사용은 SK텔링크의 이미지 구축에 중요한 역할을 함으로 색상 활용 기준을 준수해야 합니다. ")]),_c('p',{staticClass:"txt"},[_c('span',{staticClass:"icon_star"},[_vm._v("*")]),_vm._v(" 전용색상의 사용은 별색 사용을 원칙으로 하여 본 항에 제시되지 않은 색상활용은 바탕색의 채도, 명도 등을 고려해야 하며"),_c('br',{staticClass:"m_br"}),_vm._v(" 반드시 관리부서와 협의 하에 사용해야 합니다. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ci_list color_rule"},[_c('h4',[_vm._v(" 색상규정 ")]),_c('ul',[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo05.png","alt":"SK텔링크 로고(흑백)"}})]),_c('dl',[_c('dt',[_vm._v(" 흑백 색상규정 ")]),_c('dd',[_vm._v(" 흑백 인쇄용 사용시 행복날개는 Black 100%와 60%로"),_c('br'),_vm._v("명도의 차이를 구분 할 수 있어야 합니다. ")])])]),_c('li',{staticClass:"bg_print"},[_c('div',[_c('img',{attrs:{"src":"/content/images/img_logo06.png","alt":"SK텔링크 로고(음화용)"}})]),_c('dl',[_c('dt',[_vm._v(" 음화용 색상규정 ")]),_c('dd',[_vm._v(" 음화표현시 배경색이 명도 30%인 경우"),_c('br'),_vm._v("백색으로 사용됩니다. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice_area gray"},[_c('h4',{staticClass:"title"},[_c('span',{staticClass:"icon_mark"},[_c('img',{attrs:{"src":"/content/images/icon_mark01.png","alt":"주의사항"}})]),_vm._v(" 로고 사용시 꼭 읽어보세요 ")]),_c('p',[_vm._v(" 로고마크는 SK텔링크의 공식적인 상징으로 어떤 경우라도 형태가 변형되어서는 안되며 인쇄물, 프로모션물 등 "),_c('br'),_vm._v("여러 매체에 사용되어 "),_c('span',{staticClass:"txt_point01"},[_vm._v("SK텔링크의 기업 및 브랜드 이미지 전달에 중요한 역할을 하는 요소입니다.")])]),_c('p',{staticClass:"txt txt_point01"},[_c('span',{staticClass:"icon_star"},[_vm._v("*")]),_vm._v("로고마크의 사용시 변형을 방지하기 위하여 웹(web)상에 수록된 원고용 파일을 다운 받아 사용해 주시기 바랍니다. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bi_area"},[_c('h4',[_vm._v(" 국제전화 00700 ")]),_c('ul',{staticClass:"sk_inter"},[_c('li',{staticClass:"child1"},[_c('div',[_c('img',{attrs:{"src":"/content/images/img_bi01.png","alt":"SK국제전화 00700"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci_00700.ai","download":""}},[_vm._v("AI 다운로드")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci_00700.jpg","download":""}},[_vm._v("JPG 다운로드")])]),_c('li',{staticClass:"child2"},[_c('div',[_c('img',{attrs:{"src":"/content/images/img_bi02.png","alt":"SK International Call 00700"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci-eng_00700.ai","download":""}},[_vm._v("AI 다운로드")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/ci-eng_00700.jpg","download":""}},[_vm._v("JPG 다운로드")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bi_area"},[_c('h4',[_vm._v("SK 7mobile")]),_c('ul',{staticClass:"sk_7m"},[_c('li',{staticClass:"child1 single"},[_c('div',[_c('img',{attrs:{"src":"/content/images/img_bi7m02.png","alt":"일반형세로 SK 알뜰폰 7mobile"}})]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/7mobile height.ai","download":""}},[_vm._v("AI 다운로드")]),_c('a',{staticClass:"btn_down",attrs:{"href":"/content/download/cibi/7mobile height.jpg","download":""}},[_vm._v("JPG 다운로드")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"notice_area gray"},[_c('h4',{staticClass:"title"},[_c('span',{staticClass:"icon_mark"},[_c('img',{attrs:{"src":"/content/images/icon_mark01.png","alt":"주의사항"}})]),_vm._v(" 로고 사용시 꼭 읽어보세요 ")]),_c('p',[_vm._v(" 로고마크는 SK텔링크의 공식적인 상징으로 어떤 경우라도 형태가 변형되어서는 안되며 인쇄물, 프로모션물 등 "),_c('br'),_vm._v("여러 매체에 사용되어 "),_c('span',{staticClass:"txt_point01"},[_vm._v("SK텔링크의 기업 및 브랜드 이미지 전달에 중요한 역할을 하는 요소입니다.")])]),_c('p',{staticClass:"txt txt_point01"},[_c('span',{staticClass:"icon_star"},[_vm._v("*")]),_vm._v(" 로고마크의 사용시 변형을 방지하기 위하여 웹(web)상에 수록된 원고용 파일을 다운 받아 사용해 주시기 바랍니다. ")])])
}]

export { render, staticRenderFns }