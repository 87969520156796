<template>
  <div />
</template>
<script>
import api from '@/api'
export default {
  mounted() {
    /**
     * 인증 정상 완료
     */
    if (this.$route.query.mdl_tkn) {
      const kcbToken = this.$route.query.mdl_tkn
      api.CommonGetReqKcbAuth({ mdl_tkn: kcbToken})
        .then(response => {
          if (response.data.result_cd === '0001') {
            this.$store.commit('KCB_CERT_KEY', this.$route.query.mdl_tkn)
            window.opener.location.href = window.opener.location.href
            setTimeout(() => {
              window.open('','_self').close();
            }, 1)
          } else if(response.data.result_cd === 'E006') {
            console.log('KCB 연동오류', response.data)
          } else {
            console.log('알수없는 오류', response.data)
          }
        })
    }
  }
}
</script>

<style>

</style>