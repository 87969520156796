import { render, staticRenderFns } from "./PopupNotice.vue?vue&type=template&id=71a355ed&scoped=true"
import script from "./PopupNotice.vue?vue&type=script&lang=js"
export * from "./PopupNotice.vue?vue&type=script&lang=js"
import style0 from "./PopupNotice.vue?vue&type=style&index=0&id=71a355ed&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71a355ed",
  null
  
)

export default component.exports