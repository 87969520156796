<template>
  <!-- to개발 / 2024.03.07 ~ 현재까지 적용 (231114 문구수정) -->
  <div>
    <div class="body-wrap">
      <div class="terms_contents">
        <div class="terms_body">
          <div id="terms_01" class="article-box">
            <strong>1. 총칙</strong>
            <p class="mark_item">
              본 「개인정보처리방침」에서 "개인정보"라 함은 살아있는 개인에 관한
              정보로서 다음 중 어느 하나에 해당하는 정보를 말합니다.<br />
              1) 이름, 주민등록번호 등에 의하여 개인을 알아볼 수 있는 정보<br />
              2) 해당 정보만으로는 특정 개인을 알아볼 수 없어도 다른 정보와 쉽게
              결합하여 알아볼 수 있는 정보<br />
              3) 위 1) 또는 2)의 정보를 가명처리함으로써 원래의 상태로 복원하기
              위한 추가 정보의 사용•결합 없이는 특정 개인을 알아볼 수 없는
              정보(이하 '가명정보')
            </p>
            <p>
              SK텔링크는 개인정보 처리방침을
              홈페이지(https://www.sktelink.com/privacy/intro) 첫 화면에
              공개함으로써 고객이 언제나 용이하게 볼 수 있도록 조치하고
              있습니다.
            </p>
            <p>
              SK텔링크는 관련 법률 및 지침의 변경, 또는 내부 개인정보 운영방침의
              변경에 따라 개인정보 처리방침을 개정할 수 있으며, 개정하는 경우
              시행일자 등을 부여하여 개정된 내용을 확인할 수 있도록 하고
              있습니다.
            </p>
            <p>
              SK텔링크의 개인정보 처리방침이 변경되는 경우에는
              홈페이지(https://www.sktelink.com/privacy/intro) 등을 통하여 변경
              및 시행의 시기, 내용을 고객에게 공지합니다.
            </p>
          </div>
          <div id="terms_02" class="article-box">
            <strong>2. 개인정보의 수집, 이용 목적, 항목 및 보유기간</strong>
            <p>
              SK 텔링크는 본질적인 서비스 제공을 위한 '필수 항목'과 고객의
              기호와 필요에 맞는 서비스를 제공하기 위한 '선택 항목'으로 구분하여
              개인정보를 수집·이용하고 있습니다. 선택 항목 수집·이용에 동의하지
              않아도 본질적인 서비스를 이용할 수 있습니다.
            </p>
            <p>
              SK 텔링크는 아래 어느 하나에 해당하는 경우에 고객의 개인정보를
              수집할 수 있으며, 그 수집 목적의 범위에서 이용할 수 있습니다.
            </p>
            <ol>
              <li>가. 고객의 동의를 받은 경우</li>
              <li>
                나. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여
                불가피한 경우
              </li>
              <li>
                다. 고객과 체결한 계약을 이행하거나 계약을 체결하는 과정에서
                고객 요청에 따른 조치를 이행하기 위하여 필요한 경우
              </li>
              <li>
                라. 명백히 고객 또는 제3 자의 급박한 생명, 신체, 재산의 이익을
                위하여 필요하다고 인정되는 경우
              </li>
              <li>
                마. SK 텔링크의 정당한 이익을 달성하기 위하여 필요한 경우로서
                명백하게 고객 권리보다 우선하는 경우
              </li>
              <li>
                바. 공중위생 등 공공의 안전과 안녕을 위하여 긴급히 필요한 경우
              </li>
            </ol>
            <p>
              또한, 법령에 따라 수집 목적과 합리적으로 관련된 범위에서는 고객의
              동의 없이 개인정보를 이용할 수 있습니다. 이때 '당초 수집 목적과
              관련이 있는지, 수집한 정황이나 처리 관행에 비추어 볼 때 예측
              가능성이 있는지, 고객의 이익을 부당하게 침해하지 않는지, 가명처리
              또는 암호화 등 안전성 확보에 필요한 조치를 하였는지'를 종합적으로
              고려합니다.
            </p>
            <p>
              SK 텔링크는 아래와 같은 목적을 위해 개인정보를 수집∙이용하고
              있습니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('1')"
              >
                <span
                  ><i
                    >[개인정보의 수집, 이용목적, 항목 및 보유기간 (필수)]</i
                  ></span
                >
              </button>
            </div>
            <p>
              ※ 가입 당시 정보뿐만 아니라 정보 수정으로 변경된 정보를
              포함합니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('2')"
              >
                <span
                  ><i
                    >[개인정보의 수집, 이용목적, 항목 및 보유기간 (선택)]</i
                  ></span
                >
              </button>
            </div>
            <p>
              ※ 가입 당시 정보뿐만 아니라 정보 수정으로 변경된 정보를
              포함합니다.
            </p>
            <p>
              SK 텔링크는 기본적인 서비스 제공을 위해 타 기관으로부터 다음의
              정보를 제공받아, 수집 및 이용하고 있습니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('3')"
              >
                <span
                  ><i
                    >[기본적인 서비스 제공을 위해 타 기관으로부터 제공받는
                    정보]</i
                  ></span
                >
              </button>
            </div>
            <br />
            <p>
              SK 텔링크는 자동생성 정보(로그 기록, 과금정보, 결제기록 등 서비스
              계약 이행 과정에서 자동으로 생성되는 정보) 등을 필수 항목에 기재된
              목적 범위 안에서 수집하여 이용할 수 있습니다. 또한 고객이 선택
              항목에 동의할 경우, 해당 목적 범위 안에서도 이용할 수 있습니다.
            </p>
            <p>
              SK텔링크는 고객이 앱을 통한 서비스 가입 시 고객의
              이동통신단말장치(스마트폰 등)에 저장된 정보 및 설치된 기능(이하
              ‘단말기 정보 등’이라 합니다)에 접근하여 개인정보를 수집할 경우,
              사전에 ‘단말기 정보 등’에 접근한다는 것을 고지하고, 고객이
              개인정보 수집∙이용 동의를 한 범위 내에서 해당 개인정보를 수집,
              이용합니다.
            </p>
            <p>
              SK 텔링크는 고객의 사생활을 현저하게 침해할 우려가 있는 민감한
              개인정보(인종 및 민족, 사상 및 신조, 정치적 성향 및 범죄기록, 건강
              상태 및 성생활 등)는 수집하지 않습니다. 다만, 서비스 제공을 위해
              일부 민감한 개인정보를 최소한으로 수집, 처리할 필요가 있는 경우
              관련 법령의 제한에 따라 고객의 동의 등 필요한 조치를 거쳐 그
              개인정보를 수집, 처리할 수 있습니다. 또한, 서비스를 제공하는
              과정에서 고객의 민감한 개인정보가 공개되는 정보에 포함됨으로써
              사생활 침해의 위험성이 있다고 판단하는 때에는 서비스의 제공 전에
              민감한 개인정보의 공개 가능성 및 비공개를 선택하는 방법을 알아보기
              쉽게 알리겠습니다.
            </p>

            <div class="btn-in-box">
              <span
                ><i
                  >[가명정보의 처리 및 위탁, 제3자 제공] : 해당사항 없음</i
                ></span
              >
            </div>
            <p>
              SK텔링크는 개인정보보호법 제 58조의 2에 따라 시간, 비용, 기술 등을
              합리적으로 고려할 때 다른 정보를 사용하여도 더 이상 개인을 알아볼
              수 없는 정보(통계자료 등)를 고객의 동의 없이 생성하여 이용 또는
              제공할 수 있습니다.
            </p>
          </div>
          <div id="terms_03" class="article-box">
            <strong>3. 개인정보 수집 방법 및 동의 방법</strong>
            <p>
              SK 텔링크는 고객이 서비스 이용 신청서, 명의 변경 계약서 등 서면
              양식에 서명하거나, SK 텔링크에서 운영하는 홈페이지, 앱 , 상담
              게시판, 전화 또는 팩스를 통한 본인·대리인 확인, 경품 행사 응모,
              제휴사로부터 제공, 휴대폰 서비스 이용 시 생성정보 수집 툴을 통한
              방법, 휴대폰 인증 서비스를 위해 본인 확인 기관으로부터 제공받는
              방법 등으로 개인정보를 수집합니다.
            </p>
            <p>
              SK 텔링크는 고객의 동의를 받아 개인정보를 수집 이용하는 경우∙ ,
              고객이 '개인정보 수집 이용∙ '에 대해 '동의한다' 또는 ' 동의하지
              않는다'를 체크하거나 버튼을 누르는 등의 절차를 마련하고 있습니다.
              '동의한다' 항목에 체크하거나 버튼을 누르면 개인정보 수집 및 이용에
              대해 동의한 것으로 봅니다.
            </p>
            <p>
              고객은 개인정보 수집∙이용에 대한 동의를 거부할 권리가 있으며,
              동의를 거부해도 불이익을 받지 않습니다. 단, 경우에 따라 서비스를
              이용할 수 없거나, 서비스 이용 목적에 따른 서비스 제공이 제한될 수
              있습니다.
            </p>
          </div>
          <div id="terms_04" class="article-box">
            <strong>4. 개인정보 처리 업무의 위탁</strong>
            <p>
              SK텔링크는 업무위탁계약서 등을 통하여 위탁업무 수행목적 외
              개인정보의 처리 금지에 관한 사항, 개인정보의 기술적, 관리적
              보호조치에 관한 사항, 위탁업무의 목적 및 범위, 재위탁 제한에 관한
              사항, 개인정보에 대한 접근 제한 등 안전성 확보 조치에 관한 사항,
              위탁업무와 관련하여 보유하고 있는 개인정보의 관리현황 점검 등
              감독에 관한 사항, 개인정보 유출 사고 발생 시 책임부담, 처리 종료
              후의 개인정보의 반환 또는 파기 의무 등을 규정하고, 이를 준수하도록
              관리하고 있습니다.
            </p>
            <p>
              SK텔링크는 보다 나은 서비스 제공, 고객편의 제공 등 원활한 업무
              수행을 위하여 다음과 같이 개인정보 처리 업무를 외부 전문업체에
              위탁하여 운영하고 있습니다. 수탁업체 리스트는 해당 서비스 변경 및
              계약기간에 따라 변경될 수 있으며 변경 시 각 서비스에서 정한 방식을
              통해 공지하고 특별히 정하지 않은 경우 공지사항을 통해 공지합니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('4')"
              >
                <span><i>[개인정보 처리 위탁]</i></span>
              </button>
            </div>
          </div>
          <div id="terms_05" class="article-box">
            <strong>5. 수집한 개인정보의 제3자 제공</strong>
            <p>
              SK텔링크는 고객의 개인정보를 고객으로부터 동의 받거나 법령에 의해
              허용된 범위 내에서 사용하며, 동 범위를 넘어 이용하거나 제3자에게
              제공하지 않습니다. 단, SK텔링크는 법령에 따라 수집 목적과
              합리적으로 관련된 범위에서는 고객의 동의 없이 개인정보를 제공할 수
              있으며, 이때 '당초 수집 목적과 관련성이 있는지, 수집한 정황 또는
              처리 관행에 비추어 볼 때 예측 가능성이 있는지, 고객의 이익을
              부당하게 침해하는지, 가명처리 또는 암호화 등 안전성 확보에 필요한
              조치를 하였는지'를 종합적으로 고려합니다.<br />
              특히, 다음의 경우에는 위와 같은 범위 내에서 주의를 기울여
              개인정보를 제공할 수 있습니다.
            </p>
            <ol>
              <li>
                가. 제휴관계 <br />
                보다 나은 서비스 제공을 위하여 고객의 개인정보를 제휴사에게
                제공하거나 또는 제휴사와 공유할 수 있습니다. 개인정보를
                제공하거나 공유할 경우에는 사전에 고객께 제휴사가 누구인지, 제공
                또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가
                제공되거나 공유되어야 하는지, 그리고 언제까지 어떻게
                보호∙관리되는지에 대해 개별적으로 서면 또는 전자우편 등을 통해
                고지하여 동의를 구하는 절차를 거치게 되며, 고객께서 동의하지
                않는 경우에는 제휴사에게 제공하거나 제휴사와 공유하지 않습니다.
              </li>
              <li>
                나. 매각 · 인수합병 등<br />
                영업의 전부 또는 일부를 양도하거나, 합병·상속 등으로
                서비스제공자의 권리 · 의무를 이전 승계하는 경우 개인정보보호
                관련 고객의 권리를 보장하기 위해 그 사실을 고객에게 알립니다.
              </li>
              <li>
                다. 법령에 의해 필요하거나 허용되는 경우<br />
                . 통신비밀보호법, 국세기본법, 형사소송법 등 다른 법률에 특별한
                규정이 있는 경우
                <p class="mark_item">
                  ※ 단, '법률에 특별한 규정이 있는 경우'로 행정목적이나
                  수사목적으로 행정관청 또는 수사기관이 요구해 온 경우라도
                  무조건 고객의 개인정보를 제공하지 않으며, 영장 또는 기관장의
                  직인이 날인된 서면에 의한 경우 등 법률에서 규정한 적법한
                  절차에 따라 제공합니다.
                </p>
              </li>
              <li>
                라. 재난, 감염병 등 긴급상황이 발생하는 경우<br />
                <p class="mark_item">
                  . 재난, 감염병, 급박한 생명·신체 위험을 초래하는 사건·사고,
                  급박한 재산 손실 등의 긴급상황이 발생하는 경우
                </p>
                <p>
                  ※ 단, 근거법령에 의거하여 필요한 최소한의 개인정보만을
                  제공하며, 목적과 다르게 제공하지 않습니다.
                </p>
                <div class="terms-table-box">
                  <table>
                    <colgroup>
                      <col style="width: 20%" />
                      <col style="width: 20%" />
                      <col style="width: 20%" />
                      <col style="width: 20%" />
                    </colgroup>
                    <caption>
                      필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용
                      목적, 필수 수집 항목, 보유기간정보제공)
                    </caption>
                    <thead>
                      <tr>
                        <th>분류</th>
                        <th>근거 법령</th>
                        <th>제공받는 기관</th>
                        <th>제공되는 개인정보</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>재난 대응</td>
                        <td>재난 및 안전관리 기본법</td>
                        <td>중앙대책본부 또는 지역대책 본부</td>
                        <td>
                          이름, 주민등록번호, 주소 및 전화번호, 개인위치정보
                        </td>
                      </tr>

                      <tr>
                        <td>감염병의 예방 및 관리</td>
                        <td>감염병의 예방 및 관리에 관한 법률</td>
                        <td>질병관리청 또는 전국 시· 도</td>
                        <td>
                          이름, 주민등록번호, 주소 및 전화번호, 개인위치정보
                        </td>
                      </tr>
                      <tr>
                        <td>실종아동 발견</td>
                        <td>실종아동등의 보호 및 지원에 관한 법률</td>
                        <td>경찰관서</td>
                        <td>인터넷주소, 통신사실확인자료</td>
                      </tr>
                      <tr>
                        <td>납치, 감금등 범죄와 관련된 자의 개인정보 처리</td>
                        <td>전기통신사업법</td>
                        <td>수사관서</td>
                        <td>
                          성명, 주민등록번호, 주소, 전화번호, 가입일, 해지일
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </li>
            </ol>
            <p>
              SK텔링크는 고객에 대하여 보다 질 높은 서비스 제공 등을 위해 아래와
              같이 개인정보를 제공하고 있습니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('5')"
              >
                <span><i>[수집한 개인정보의 제3자 제공]</i></span>
              </button>
            </div>
          </div>
          <div id="terms_06" class="article-box">
            <strong>6. 개인정보 보유 및 이용 기간</strong>
            <p>
              SK텔링크는 다른 내용으로 동의를 받거나 법령상의 근거가 없다면
              원칙적으로 고객이 서비스에 가입한 날을 시작으로 고객에게 서비스를
              제공하는 기간에 고객의 개인정보를 보유 및 이용합니다. <br />
              고객이 회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한
              동의를 철회하는 경우, 수집∙이용목적을 달성하거나 보유∙이용기간이
              종료한 경우, 사업폐지 등의 파기사유 발생시 당해 개인정보를 지체
              없이 파기합니다. 단, 이용요금의 정산, 소송이나 분쟁 등 기타 필요한
              경우를 대비하여 보유하여야 할 필요가 있을 경우 개인정보는 해당
              목적이 달성될 때까지 일정기간 보유합니다.
            </p>
            <p>
              보유 기간 내용은 "2. 개인정보의 수집∙이용 목적, 항목 및 보유
              기간"에서 자세히 확인하실 수 있습니다.
            </p>
            <p>
              또한 관계 법령에 따라 개인정보를 보존할 필요가 있는 경우,
              SK텔링크는 관계 법령에서 정한 기간 내에서 개인정보를 보관합니다.
              <br />
              이 경우 SK텔링크는 보관하는 정보를 그 보관 목적으로만 이용합니다.
              <br />각 법령에 따른 보관 근거, 보관 항목, 보관 기간의 예를 들면
              아래와 같습니다.
            </p>
            <div class="terms-table-box">
              <table>
                <colgroup>
                  <col style="width: 33.333333%" />
                  <col style="width: 33.333333%" />
                  <col style="width: 33.333333%" />
                </colgroup>

                <thead>
                  <tr>
                    <th>근거 법령</th>
                    <th>보관 항목</th>
                    <th>보관 기간</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>통신비밀보호법</td>
                    <td>
                      통신사실확인자료 제공 시 필요한 로그 기록 자료, IP 주소 등
                    </td>
                    <td>3개월</td>
                  </tr>

                  <tr>
                    <td>통신비밀보호법</td>
                    <td>
                      전기통신사업자가 통신사실확인자료 제공 시 필요한 성명,
                      주민등록번호, 휴대폰 번호
                    </td>
                    <td>12개월</td>
                  </tr>
                  <tr>
                    <td>통신비밀보호법</td>
                    <td>
                      전기통신사업자에게 보관 의무가 있는 가입자의 전기통신
                      일시, 전기통신 개시∙종료시간, 통신번호 등 상대방의 가입자
                      번호, 사용 횟수, 정보통신망에 접속된 정보통신기기의
                      위치자료
                    </td>
                    <td>12개월</td>
                  </tr>
                  <tr>
                    <td>국세기본법, 법인세법, 부가가치세법</td>
                    <td>
                      성명, 주민등록번호, (해지)휴대폰 번호, 청구서 배달 주소,
                      요금, 계약서 등 거래내역 관련 정보
                    </td>
                    <td>
                      5년(단, 해지 후 6개월이 지난 고객의 개인정보는 별도의
                      분리된 저장공간에 보관)
                    </td>
                  </tr>
                  <tr>
                    <td>상법</td>
                    <td>상업 장부와 영업에 관한 중요 서류 및 전표</td>
                    <td>
                      중요 서류: 10년 <br />
                      전표: 5년
                    </td>
                  </tr>
                  <tr>
                    <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                    <td>표시∙광고에 관한 기록</td>
                    <td>6개월</td>
                  </tr>
                  <tr>
                    <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                    <td>계약 또는 청약 철회 등에 관한 기록</td>
                    <td>5년</td>
                  </tr>
                  <tr>
                    <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                    <td>대금 결제 및 재화 등의 공급에 관한 기록</td>
                    <td>5년</td>
                  </tr>
                  <tr>
                    <td>전자상거래 등에서의 소비자보호에 관한 법률</td>
                    <td>소비자의 불만 또는 분쟁 처리에 관한 기록</td>
                    <td>3년</td>
                  </tr>
                  <tr>
                    <td>신용정보의 이용 및 보호에 관한 법률</td>
                    <td>신용정보의 수집∙처리 및 이용 등에 관한 기록</td>
                    <td>3년</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div id="terms_07" class="article-box">
            <strong>7. 개인정보의 파기절차 및 방법</strong>
            <p>
              SK텔링크는 수집한 개인정보의 이용목적이 달성된 후에는 보관기간 및
              이용기간에 따라 해당 정보를 지체없이 파기합니다. 파기절차, 방법,
              시점은 다음과 같습니다.
            </p>
            <ol>
              <li>
                <strong>가. 파기절차 및 시점</strong> <br />
                <p>
                  고객이 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지
                  등으로 이용목적이 달성된 후 서비스의 방침 및 기타 관련 법령에
                  의한 정보보호 사유에 따라(보관기간 및 이용기간 참조) 허용된
                  기간 동안 저장된 후 삭제하거나 파기합니다.
                </p>
                <p>
                  고객이 이동통신단말장치(스마트폰 등)에서 앱을 삭제하더라도
                  개인정보 동의 철회(회원탈퇴) 요청을 하지 않을 경우 해당
                  개인정보는 여전히 서비스에 남아있을 수 있으므로, 개인정보
                  파기를 원하시면 반드시 동의 철회(회원탈퇴) 요청을 하여야
                  합니다.
                </p>
              </li>
              <li>
                <strong>나. 파기방법</strong> <br />
                <p>
                  가입 신청서 등 종이에 기록하거나 출력된 개인정보는 분쇄, 소각
                  또는 화학약품 처리를 하여 용해하는 방법으로 파기합니다. <br />
                  전자 파일 형태로 저장된 개인정보는 기록을 복구 또는 재생할 수
                  없도록 기술적 방법으로 안전하게 삭제합니다.
                </p>
                <p>
                  단, 기술적 특성으로 위에 기재된 방법으로 파기하는 것이 현저히
                  곤란한 경우에는 시간ㆍ비용ㆍ기술 등을 합리적으로 고려할 때
                  다른 정보를 사용하여도 더 이상 개인을 알아볼 수 없는 익명
                  정보로 처리하여 복원이 불가능하도록 조치합니다.
                </p>
              </li>
            </ol>
          </div>
          <div id="terms_08" class="article-box">
            <strong>8. 고객의 권리, 의무와 행사 방법</strong>
            <ol>
              <li>
                <strong>가. 고객의 개인정보 보호 관련 권리 </strong> <br />
                <p>
                  고객은 언제든지 본인에 관해 SK텔링크가 보유하고 있는 고객의
                  개인정보 현황, SK텔링크가 개인정보를 이용하거나 제3자에게
                  제공한 현황, 개인정보의 수집∙이용∙제공 등의 동의 현황을
                  열람하거나 제공받을 수 있습니다. SK텔링크는 고객이 개인정보에
                  대한 열람∙제공 또는 정정을 요구하는 경우에는 고객의 요구에
                  10일 이내에 대응하고, 해당 개인정보에 오류가 있거나 보존기간을
                  경과한 것이 판명되는 등 정정∙삭제를 할 필요가 있다고 인정되는
                  경우에는 지체 없이 정정∙삭제를 합니다. . 고객은 개인정보
                  수집∙이용 또는 제공에 대한 동의철회(가입∙해지)를 요구할 수
                  있고 SK텔링크는 지체 없이 필요한 조치를 취합니다. 고객은
                  언제든지 개인정보 수집·이용·제공 등의 동의를 철회할 수
                  있습니다. 다만, 회원가입 및 본질적 서비스 제공에 필요한
                  개인정보 등에 대한 동의를 철회하는 경우 회원탈퇴 및 서비스
                  해지가 불가피하여, 고객에게 제공되는 서비스를 받지 못하게 될
                  수 있습니다.
                </p>
                <p>
                  고객은 개인정보 수집∙이용 또는 제공에 대한
                  동의철회(가입∙해지)를 요구할 수 있고 SK텔링크는 지체 없이
                  필요한 조치를 취합니다. 고객은 언제든지 개인정보
                  수집•이용•제공 등의 동의를 철회할 수 있습니다. 다만, 회원가입
                  및 본질적 서비스 제공에 필요한 개인정보 등에 대한 동의를
                  철회하는 경우 회원탈퇴 및 서비스 해지가 불가피하여, 고객에게
                  제공되는 서비스를 받지 못하게 될 수 있습니다.
                </p>
              </li>
              <li>
                <strong>나. 고객의 권리행사 방법 </strong> <br />
                <p>
                  고객은 대상 서비스의 성격에 따라 다음 중 한 가지 방법으로 위와
                  같은 권리를 행사할 수 있습니다. 대상 서비스 또는 개인정보의
                  성격에 따라, 또는 고객정보 보호의 필요성에 따라 일부 방법은
                  제한될 수 있으며, SK텔링크는 위와 같은 권리를 행사하는 자가
                  고객 본인 또는 고객의 적법한 대리인인지 여부를
                  신분증명문서(주민등록증, 외국인등록증, 여권, 운전면허증) 및
                  권리증명문서(위임장, 본인 및 대리인의 인감증명서 등)를 통해
                  확인할 수 있습니다.
                </p>
                <ul>
                  <li>
                    - SK텔링크와 위탁업무계약을 맺은 지점, 대리점 또는 고객센터
                    방문, 전화
                  </li>
                  <li>
                    - 홈페이지 내 회원정보변경 메뉴를 클릭하거나 공지된 연락처로
                    서면, 전화, 전자우편 발송하여 문의
                  </li>
                </ul>
                <p>
                  SK텔링크는 개인정보의 전부 또는 일부에 대하여 열람∙증명 또는
                  정정요청을 거절할 정당한 이유가 있는 경우에는 고객에게 이를
                  통지하고 그 이유를 설명합니다. 고객은 회사의 열람 등의 요청
                  거절 시 이의제기를 할 수 있으며, 이의제기 방법은 개인정보보호
                  담당자 메일로 접수하시면 신속∙정확한 답변을 드리겠습니다.
                </p>
              </li>
              <li>
                <strong>다. 고객의 개인정보 보호 관련 의무 </strong> <br />
                <p>
                  고객은 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고
                  예방을 위해 노력하여야 합니다. 고객이 입력한 부정확한 정보로
                  인해 발생하는 사고의 책임은 고객에게 있으며 타인 정보를
                  도용하거나 허위정보를 입력할 경우 회원자격상실을 포함하여
                  서비스 약관위반에 따른 제재가 따를 수 있습니다.
                </p>
                <p>
                  고객은 접속정보(ID, 패스워드 등)를 포함한 본인의 개인정보가
                  유출되지 않도록 공용 PC 사용 시 반드시 로그아웃을 하는 등
                  기본적인 주의를 기울여야 하고 다른 사람이 추측할 수 있는 쉬운
                  비밀번호는 사용을 피하여야 하며, 정기적으로 비밀번호를
                  변경하는 것이 바람직합니다.
                </p>
                <p>
                  고객은 게시물을 포함한 타인의 개인정보 및 권리를 침해,
                  훼손하지 않도록 유의하여야 합니다. 고객이 관계 법령을 위반하여
                  타인의 개인정보 및 권리를 침해, 훼손할 시에는 법적 책임이 따를
                  수 있습니다.
                </p>
                <p>
                  개인정보 열람∙증명의 접수∙처리 부서 및 담당자 : Infra 보안팀
                  이원희 (sktelinkprivacy@sk.com)
                </p>
              </li>
            </ol>
          </div>
          <div id="terms_09" class="article-box">
            <strong>9. 아동의 개인정보보호 및 법정대리인의 권리</strong>
            <p>
              SK텔링크는 만 14세 미만 아동(이하 ‘아동’)의 개인정보
              수집∙이용∙제공 등을 하는 경우 법정대리인의 동의를 얻도록 하고
              있습니다.
            </p>
            <p>
              SK텔링크는 아동의 서비스 이용 신청 시 법정대리인의 동의를 얻기
              위하여 법정대리인의 성명, 연락처 등 필요한 최소한의 정보를 요구할
              수 있으며, 이 경우 아동에게 개인정보의 수집∙이용 또는 제공 목적 및
              법정대리인의 동의가 필요하다는 내용을 잘 이해할 수 있도록 쉬운
              표현으로 알립니다.
            </p>
            <p>
              또한, 다음 중 하나의 방법으로 적법한 법정대리인이 동의하였는지를
              확인할 수 있습니다.
            </p>
            <ol>
              <li>
                . 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를
                표시하도록 하고, 법정대리인의 휴대전화 본인인증 등을 통해 본인
                여부를 확인하는 방법
              </li>
              <li>
                . 동의 내용이 적힌 서면을 법정대리인에게 직접 발급하거나, 우편
                또는 팩스를 통하여 전달하고, 법정대리인이 동의 내용에 대하여
                서명날인 후 제출하도록 하는 방법
              </li>
              <li>
                . 동의 내용이 적힌 전자우편을 발송하여 법정대리인으로부터 동의의
                의사표시가 적힌 전자우편을 전송받는 방법
              </li>
              <li>
                . 전화를 통하여 동의 내용을 법정대리인에게 알리고 동의를 얻거나
                인터넷주소 등 동의 내용을 확인할 수 있는 방법을 안내하고 재차
                전화 통화를 통하여 동의를 얻는 방법
              </li>
              <li>
                . 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를
                표시하도록 하고, 그 동의 표시를 확인했음을 법정대리인의 휴대전화
                문자메시지로 알리는 방법
              </li>
              <li>
                . 동의 내용을 게재한 인터넷 사이트에 법정대리인이 동의 여부를
                표시하도록 하고, 법정대리인의 신용카드·직불카드 등의 카드정보를
                제공받는 방법
              </li>
              <li>
                . 그 밖에 위와 준하는 방법으로 법정대리인에게 동의 내용을 알리고
                동의의 의사표시를 확인하는 방법
              </li>
            </ol>
            <p>
              SK텔링크는 법정대리인의 동의를 얻기 위하여 수집한 법정대리인의
              개인정보를 해당 법정대리인의 동의 여부를 확인하는 목적 외의 용도로
              이를 이용하거나 제3 자에게 제공하지 않습니다. 아동의 법정대리인은
              아동에 관해 SK텔링크가 보유하고 있는 개인정보 수집·이용·제공
              동의를 철회할 수 있고, SK텔링크가 개인정보를 이용하거나 제3자에게
              제공한 현황, 개인정보의 수집∙이용∙제공 등의 동의 현황을 열람하거나
              제공받을 수 있으며, 오류가 있는 경우에는 그 정정을 요구할 수
              있습니다.
            </p>
          </div>
          <div id="terms_10" class="article-box">
            <strong
              >10. 개인정보의 자동수집장치의 설치 · 운영 및 그 거부에 관한
              사항</strong
            >
            <p>
              SK텔링크는 고객에게 제공하는 서비스를 통하여 고객의 선호사항 등을
              기억하거나 서비스를 효율적으로 제공하기 위하여 개인정보를 저장하고
              조회하는 쿠키(cookie)(이하 ‘쿠키’)를 주의깊게 설치∙운용할 수
              있습니다. SK텔링크는 고객이 서비스에 접속하면 고객의 브라우저에
              있는 쿠키의 내용을 읽고, 추가정보를 찾아 접속에 따른 성명 등의
              추가 입력 없이 서비스를 제공할 수 있습니다.
            </p>
            <p>
              SK텔링크가 쿠키를 통해 수집한 고객의 정보는 다음의 목적 등을 위해
              사용될 수 있습니다.
            </p>
            <ul>
              <li>• 개인의 관심 분야에 따라 차별화된 정보를 제공</li>
              <li>
                • 관심 있게 둘러본 내용들에 대한 자취를 추적하여 다음 번 접속 때
                개인 맞춤 서비스를 제공
              </li>
              <li>• 유료서비스 이용 시 이용기간 안내</li>
              <li>• 회원들의 습관을 분석하여 서비스 개편 등의 척도로 활용</li>
            </ul>
            <p>
              고객은 서비스에서 제공하는 방식에 따라 쿠키 설치에 대해 선택할 수
              있습니다. 예를 들어 웹 브라우저 상단의 '도구 &gt; 인터넷 옵션 &gt;
              개인정보 &gt; 고급'에서 모든 쿠키 허용, 쿠키가 저장될 때마다 알림
              받기 또는 모든 쿠키의 저장을 거부할 수 있습니다.
            </p>
          </div>
          <div id="terms_11" class="article-box">
            <strong>11. 개인정보보호를 위한 기술적 · 관리적 대책</strong>
            <ol>
              <li>
                <strong>가. 기술적 대책</strong><br />
                SK텔링크는 고객의 개인정보를 처리함에 있어 개인정보가 분실,
                도난, 유출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여
                아래와 같은 기술적 대책을 강구하고 있습니다.
                <ul>
                  <li>
                    • 고객의 개인정보는 비밀번호에 의해 보호되며 파일 및
                    전송데이터를 암호화하거나 파일 잠금(Lock) 등의 보안기능을
                    통해 따로 보호되고 있습니다.
                  </li>
                  <li>
                    • 개인정보 처리에 이용하는 정보기기에 백신프로그램 등을
                    이용하여 컴퓨터 바이러스에 의한 피해를 방지하기 위한 조치를
                    취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며
                    갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를
                    제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.
                  </li>
                  <li>
                    • 암호알고리즘 등을 이용하여 네트워크 상에서 개인정보를
                    안전하게 전송할 수 있는 보안장치를 채택하고 있습니다.
                  </li>
                  <li>
                    • 해킹 등 외부침입에 대비하여 침입차단시스템 및
                    침입탐지시스템을 운영하여 사내 네트워크를 보호하고, 각
                    서버마다 접근제어시스템을 설치하여 보안을 강화하고 있습니다.
                  </li>
                </ul>
              </li>
              <li>
                <strong>나. 관리적 대책</strong><br />
                <ul>
                  <li>
                    • 개인정보의 안전한 보호를 위하여 주요 시스템 및 설비에
                    대하여 외부 전문기관으로부터 정보보호관리체계 인증 등
                    객관적인 인증을 받아 운영되고 있습니다.
                  </li>
                  <li>
                    • 고객의 개인정보에 대한 접근 및 관리에 필요한 절차 등을
                    마련하여 소속 직원으로 하여금 이를 숙지하고 준수하도록 하고
                    있습니다.
                  </li>
                  <li>
                    • 고객의 개인정보를 처리할 수 있는 자를 최소한으로 제한하고
                    있습니다. 고객의 개인정보를 처리할 수 있는 자는 아래와
                    같습니다.
                    <ul>
                      <li>
                        - 고객을 직∙간접적으로 상대로 하여 마케팅 업무를
                        수행하는 자
                      </li>
                      <li>
                        - 개인정보보호책임자 및 개인정보보호담당자 등
                        개인정보관리∙보호업무를 수행하는 자
                      </li>
                      <li>- 기타 업무상 개인정보의 처리가 불가피한 자</li>
                    </ul>
                  </li>
                  <li>
                    • 컴퓨터를 이용하여 고객의 개인정보를 처리하는 경우
                    개인정보에 대한 접근권한을 가진 담당자를 지정하여
                    식별부호(ID) 및 비밀번호를 부여하고, 해당 비밀번호를
                    정기적으로 갱신하고 있습니다.
                  </li>
                  <li>
                    • 개인정보를 처리하는 직원을 대상으로 새로운 보안기술 습득
                    및 개인정보보호 의무 등에 관해 정기적인 사내 교육 및 외부
                    위탁교육을 실시하고 있습니다.
                  </li>
                  <li>
                    • 신규직원 채용 시 정보보호서약서 또는 개인정보보호서약서에
                    서명하게 함으로써 직원에 의한 정보유출을 사전에 방지하고
                    개인정보처리방침에 대한 이행사항 및 직원의 준수여부를
                    감사하기 위한 내부절차를 마련하여 지속적으로 시행하고
                    있습니다.
                  </li>
                  <li>
                    • 직원 퇴직 시 비밀유지서약서에 서명하게 함으로써 고객의
                    개인정보를 처리하였던 자가 직무상 알게 된 개인정보를
                    훼손∙침해 또는 누설하지 않도록 하고 있습니다.
                  </li>
                  <li>
                    • 개인정보 처리자의 업무 인수인계는 보안이 유지된 상태에서
                    철저하게 이뤄지고 있으며, 입사 및 퇴사 후 개인정보
                    침해사고에 대한 책임을 명확하게 규정하고 있습니다.
                  </li>
                  <li>
                    • 전산실 및 자료 보관실 등을 특별 보호구역으로 설정하여
                    출입을 통제하고 있습니다.
                  </li>
                  <li>
                    • 서비스이용계약체결 또는 서비스제공을 위하여 고객의
                    신용카드번호, 은행결제계좌 등 대금결제에 관한 정보를
                    수집하거나 고객에게 제공하는 경우 당해 고객이 본인임을
                    확인하기 위하여 필요한 조치를 취하고 있습니다.
                  </li>
                  <li>
                    • 그 외 내부 관리자의 실수나 기술관리 상의 사고로 인해
                    개인정보의 상실, 유출, 위조, 변조, 훼손이 유발될 경우
                    SK텔링크는 즉각 고객에게 사실을 알리고 적절한 대책과 보상을
                    강구할 것입니다.
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div id="terms_12" class="article-box">
            <strong>12. 광고성 정보 전송</strong>
            <p>
              SK텔링크는 고객의 사전 동의 없이 영리목적의 광고성 정보를 전송하지
              않습니다.
            </p>
            <p>
              SK텔링크는 신상품이나 이벤트 정보안내 등 고객 지향적인 마케팅을
              수행하기 위하여 영리 목적의 광고성 정보를 전송하는 경우에는 광고성
              정보 전송에 대한 고객의 사전 동의를 득하고 각 전송수단별로 아래와
              같이 고객이 광고성 정보임을 쉽게 알아 볼 수 있도록 조치합니다.
            </p>
            <ul>
              <li>
                • 전자우편 : 제목란에 '(광고)' 또는 '(성인광고)'라는 문구를
                제목란의 처음에 빈칸 없이 한글로 표시하고 이어서 전자우편
                본문란의 주요 내용을 표시합니다. 단, 제목란 등에 광고임을
                표시하기 어려운 전자매체를 통해 영리목적의 광고성 정보를
                전송하는 경우에도 전송자의 명칭을 표기하는 등 필요한 조치를
                취합니다.
              </li>
              <li>
                • 팩스∙휴대폰 문자전송 등 전자우편 이외의 방법 : 전송자의 명칭을
                표기하는 등 필요한 조치를 취합니다.
              </li>
            </ul>
            <p>
              SK텔링크는 고객의 광고성 정보 수신동의 시, 수신거부 시, 수신동의
              철회 시 해당 사실을 고객에게 해당 사실 발생일로부터 14일내에
              고지합니다. 또한 고객이 광고성 정보 수신동의를 한 날로부터 매 2년
              마다 고객의 광고성 정보 수신동의 사실을 고객에게 확인합니다.
            </p>
          </div>
          <div id="terms_13" class="article-box">
            <strong>13. 의견수렴 및 불만처리</strong>
            <p>
              SK텔링크는 고객의 의견을 매우 소중하게 생각합니다. 고객께서
              문의사항이 있을 경우 고객센터 등에 연락을 하여 문의하시면
              신속∙정확한 답변을 드리겠습니다. 고객센터의 연락처는 다음과
              같습니다.
            </p>
            <ul>
              <li>
                - 전화번호 : (이동전화서비스) 1599-0999 (국제전화) 1599-0070
              </li>
              <li>
                - 사이버상담 : <br />
                <ul>
                  <li>
                    - www.sktelink.com에 접속하신 후 고객센터 메뉴의
                    상담/안내에서 사이버 상담원에게 질문을 하실 수 있습니다.
                  </li>
                  <li>
                    - 구글플레이, 원스토어 등 앱 마켓에 접속하신 후 관련
                    서비스의 개발자에게 문의하기를 통해 질문을 하실 수 있습니다.
                  </li>
                </ul>
              </li>
            </ul>
            <p>
              기타 개인정보침해로 인한 구제를 받기 위하여
              개인정보분쟁조정위원회, 한국인터넷진흥원 개인정보침해신고센터 등에
              분쟁해결이나 상담 등을 신청할 수 있습니다. 이 밖에 기타
              개인정보침해의 신고∙상담에 대하여는 아래의 기관에 문의하시기
              바랍니다.
            </p>
            <ul>
              <li>
                . 개인정보분쟁조정위원회 (<a
                  href="https://www.kopico.go.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                  >https://www.kopico.go.kr</a
                >) : 1833-6972
              </li>
              <li>
                . 개인정보침해신고센터 (<a
                  href="https://privacy.kisa.or.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                  >https://privacy.kisa.or.kr</a
                >) : 118
              </li>
              <li>
                . 대검찰청 (<a
                  href="https://www.spo.go.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                  >https://www.spo.go.kr</a
                >) : 1301
              </li>
              <li>
                . 경찰청 (<a
                  href="https://ecrm.cyber.go.kr"
                  target="_blank"
                  alt="새창열림"
                  title="새창열림"
                  >https://ecrm.cyber.go.kr</a
                >) : 182
              </li>
            </ul>
          </div>
          <div id="terms_14" class="article-box">
            <strong>14. 고객 개인정보의 국외 이전</strong>
            <p>
              SK텔링크는 필요한 경우 고객의 개인정보를 국외에 보관 및 처리위탁할
              수 있습니다. 국외에 처리위탁 보관하는 개인정보는 아래와 같습니다.
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('6')"
              >
                <span><i>[고객 개인정보의 국외 이전]</i></span>
              </button>
            </div>
            <p style="margin-top: 25px">
              이전거부시 eSIM서비스를 사용할 수 없습니다. 이에 이전을 원치
              않으실 경우 eSIM서비스를 탈퇴하고 USIM으로 전환하여 서비스를
              이용해 주시기 바랍니다. 해당 업무는 고객센터를 통해 요청하실 수
              있습니다.
            </p>
          </div>
          <div id="terms_15" class="article-box">
            <strong>15. 개인정보보호책임자 및 담당자</strong>
            <p>
              SK텔링크는 고객이 안전하게 서비스를 이용할 수 있도록 개인정보
              보호에 최선을 다하고 있습니다. SK텔링크는 고객의 개인정보 관련
              문의, 불만처리, 피해구제 등을 위하여 아래와 같이 개인정보
              보호책임자 및 담당부서를 지정하여 고객의 요청에 신속하고 성실하게
              답변해드리고 있습니다.
            </p>
            <p>
              개인정보보호 담당자 이메일 :
              <a href="mailto:sktelinkprivacy@sk.com">sktelinkprivacy@sk.com</a>
            </p>
            <div class="btn-in-box">
              <button
                type="button"
                class="btn_popup"
                aria-haspopup="true"
                @click="show('7')"
              >
                <span><i>[개인정보보호 책임자 및 담당자 전체보기]</i></span>
              </button>
            </div>
            <div id="terms_16" class="article-box">
              <strong>16. 개인정보 처리방침 변경에 관한 사항</strong>
            </div>

            <p />
            <ul>
              <li>• 개인정보처리방침 공고일자 : 2024년 7월 18일</li>
              <li>• 개인정보처리방침 시행일자 : 2024년 7월 18일</li>
              <li>• 개인정보처리방침 버전번호 : V 4.0</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isVisible"
      id="baseLayer"
      class="baselayer"
      style="display: block"
    >
      <!-- to개발 / popup01 개인정보의 이용목적, 수집항목 및 보유기간(필수) -->
      <section
        v-if="currentModal === '1'"
        id="modalLayerPopup"
        key="20210120-01"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              개인정보의 이용목적, 수집항목 및 보유기간(필수)
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>
                      · 이동전화서비스, 부가서비스, 통신 청구/수납서비스와 같이
                      회사가 제공하는 관련 서비스(이하 '서비스') 제공<br />
                      · 서비스 제공을 위한 본인 식별∙인증<br />
                      · 서비스 품질 확인∙개선, 상담∙불만∙기기변경 처리<br />
                      · 요금 정산∙청구∙수납∙추심∙자산유동화<br />
                      · 연체 사실 통지
                    </td>
                    <td>
                      이름(법인명), 생년월일, 성별, 이동전화번호, 주소,
                      전화번호, e-mail 주소, 계좌(카드)정보, 예금주(카드주)
                      정보, 단말기 정보(모델명, IMEI, USIM번호, MAC Address 등),
                      서비스 가입일∙해지일∙가입기간, 서비스 과금정보,
                      단말매매계약내역, 분할상환계약 내역, 통신과금서비스
                      거래정보, 휴대폰결제 비밀번호, 번호이동정보, 서비스
                      이용과정에서 생성되는 정보(발∙수신번호(통화상대방번호
                      포함), 통화시각, 사용도수, 서비스이용기록, 접속로그, 쿠키,
                      접속 IP 정보, 결제기록, 이용정지기록, 연체금액, 연체등록
                      예정일, 기타 요금 과금에 필요한 데이터 및 이를 조합하여
                      생성되는 정보), 기지국정보, 미성년자 가입 시 법정대리인
                      정보(이름, <span class="highlight">주민등록번호</span>)
                    </td>
                    <td rowspan="3">
                      <span class="highlight"
                        >서비스 계약 전부 해지 후 6개월까지</span
                      >
                      (단, 아래의 경우는 각 정한 바에 따름)<br />
                      가. 법령에 따로 정한 경우에는 해당 기간까지<br />
                      나. 요금정산이 미완료된 경우, 정산완료일로부터
                      6개월까지<br />
                      다. 단말/요금보증보험을 가입한 경우, 보증보험에 따른 법률
                      관계가 종료된 날로부터 6개월까지<br />
                      라. 요금관련 분쟁이 계속될 경우에는 분쟁이 종료되는
                      때까지<br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      • 명의도용 방지, 가입의사 확인<br />
                      • 보편적 역무 제공, 요금감면 서비스∙사무
                    </td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름,
                      생년월일, 신분증 기재사항, 주소,
                      <span class="highlight">주민등록번호</span> 대체수단,
                      이동통신사
                    </td>
                  </tr>
                  <tr>
                    <td>• 본인확인서비스</td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름,
                      생년월일, 성별, 내∙외국인 여부, 신분증 기재사항,
                      <span class="highlight">주민등록번호</span> 대체수단,
                      이동통신사
                    </td>
                  </tr>
                  <tr>
                    <td>
                      • 이동전화 서비스∙단말대금 분할상환구매∙신용보험 계약의
                      가입∙유지∙관리(채권추심∙대위권 행사 포함), 금융사고 조사,
                      자산유동화, 당사 제휴카드 발급 여부 결정
                    </td>
                    <td>
                      <span class="highlight">고유식별정보</span>,
                      개인신용정보(연체정보 등 신용도 판단정보, 신용거래정보,
                      신용등급, 보증보험 가입정보 및 보험 가입∙유지∙관리
                      목적으로서의 조회 정보)<br />※ 신용정보는 나이스평가정보,
                      한국정보통신진흥협회 또는 서울보증보험 등 신용조회회사,
                      신용정보집중기관 또는 보증보험회사(보증보험회사의
                      신용조회회사, 신용정보집중기관, 금융결제원,
                      한국기업데이터에 조회 및 제공하는 것 포함)로부터
                      제공받습니다
                    </td>
                    <td>
                      <span class="highlight"
                        >동의서 제출시점부터 관련 계약∙정산∙대위권이 종료하는
                        시점까지, <br />단, 가입이나 거래가 거절되는 경우 상담
                        종료시점까지</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>• 이용요금 미납으로 인한 해지 시 알림</td>
                    <td>
                      이름, 직권해지예정일, 중복확인정보(DI), 연체금액,
                      납부기한, 미성년자의 경우 법정대리인 중복확인정보(DI),
                      이동전화번호 또는 서비스관리번호
                    </td>
                    <td>
                      <span class="highlight">해당업무 처리 완료시까지</span>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      • 이용자가 웹사이트 또는 앱에 입력한 본인확인정보의
                      정확성여부확인 (본인확인 서비스 제공) <br />
                      • 해당 웹사이트 또는 앱에 연계정보(CI) /
                      중복가입확인정보(DI) 전송 <br />
                      • 서비스 관련 상담 및 고객 의견 응대
                    </td>
                    <td>
                      이용자의 성명, 이동전화번호, 가입한 이동전화 회사,
                      생년월일, 성별, 연계정보(CI), 중복가입확인정보(DI),
                      이용자가 이용하는 웹사이트 또는 앱 정보, 이용일시
                    </td>
                    <td>
                      <span class="highlight"
                        >서비스 이용ᆞ제공 기간(단, 법령에서 따로 정한 경우 해당
                        기간)</span
                      >
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>sk7mobile.com 홈페이지</h2>
              <table class="table_wid01 three">
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집·이용 항목</th>
                    <th style="width: 150px">보유 및 이용 기간</th>
                  </tr>
                  <tr>
                    <td>본인인증 (휴대폰인증, 아이핀인증)</td>
                    <td>
                      이름, 이동전화번호 (PASS 인증시)<br />
                      이름, 이동전화번호, 생년월일, 성별 (SMS 인증시)<br />
                      아이핀ID, 비밀번호 (아이핀 인증시)
                    </td>
                    <td>
                      <span class="highlight">3개월까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>회원가입(후불서비스 조회, 변경, 상담)</td>
                    <td>
                      이름, 생년월일, 성별, 아이디, 패스워드, 휴대폰번호,
                      통신사, e-mail 주소, 연계정보(CI), 단말기모델명,
                      단말기OS버전, 만 14세 미만 아동일 경우 (법정대리인
                      휴대폰인증 또는 아이핀인증 결과 정보)
                    </td>
                    <td>
                      <span class="highlight"
                        >서비스 해지(회원 탈퇴/동의 철회) 시 즉시 파기</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>유심 구매</td>
                    <td>
                      이름, 휴대폰번호, 주소, 생년월일, 성별, 결제정보, 카드사,
                      신용카드번호
                    </td>
                    <td>
                      <span class="highlight">5년까지</span> (전자상거래
                      등에서의 소비자보호에 관한 법률)
                    </td>
                  </tr>
                  <tr>
                    <td>가입신청서 작성, 휴대폰 구매</td>
                    <td>
                      이동전화서비스 제공시 수집.이용하는 모든 항목(표 상단 6개
                      항목), 배송을 위한 정보(수령인, 휴대폰번호, 다른 연락처,
                      배송주소)
                    </td>
                    <td>
                      <span class="highlight"
                        >가입신청 및 구매정보 작성 3개월 까지</span
                      >
                      (가입완료시 이동전화 서비스 보유기간까지)
                    </td>
                  </tr>
                  <tr>
                    <td>셀프 개통</td>
                    <td>
                      이름, 휴대폰번호, 인증번호, USIM번호, 번호이동인증,
                      연계정보(CI)
                    </td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>공기계 구매</td>
                    <td>이름, 휴대폰번호, 생년월일, 성별, 결제정보, 주소</td>
                    <td>
                      <span class="highlight"
                        >계약 및 대금결제 기록 5년 까지</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>비회원이벤트 (구매후기)</td>
                    <td>이름, 휴대폰번호, 생년월일, 성별</td>
                    <td>
                      <span class="highlight">이벤트 종료후 3개월까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>신청내역조회</td>
                    <td>이름, 휴대폰번호, 접수번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>휴대폰등록</td>
                    <td>이름, 휴대폰번호, 신용카드번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>개인정보 이용내역조회</td>
                    <td>이름, 휴대폰번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>통신자료제공사실 열람</td>
                    <td>이름, 휴대폰번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>미환급금 조회</td>
                    <td>
                      • 미환급액 조회 시 : 이름,
                      <span class="highlight">주민등록번호</span><br />
                      • 미환급액 환급 신청시 : 이름,
                      <span class="highlight">주민등록번호</span>, 입금은행명,
                      계좌번호
                    </td>
                    <td>
                      <span class="highlight">서비스 이용 후 즉시 폐기</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>국제전화 00700.com 홈페이지</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>본인인증 (휴대폰인증)</td>
                    <td>
                      이름, 이동전화번호 (PASS 인증시)<br />
                      이름, 이동전화번호, 생년월일, 성별 (SMS 인증시)
                    </td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>
                      로그인 (요금제 조회 및 신청, 이용 내역 조회, 제휴포인트
                      등록 및 적립내역 조회)
                    </td>
                    <td>휴대폰번호</td>
                    <td><span class="highlight">3개월까지</span></td>
                  </tr>
                  <tr>
                    <td>로그인 후 이벤트 신청 및 결과조회</td>
                    <td>휴대폰번호</td>
                    <td><span class="highlight">24개월까지</span></td>
                  </tr>
                  <tr>
                    <td>요금제 신청</td>
                    <td>휴대폰번호</td>
                    <td>
                      <span class="highlight">요금제 종료 후 5년까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>이용내역</td>
                    <td>
                      휴대폰번호, 통화내역 조회 시 상대방 착신번호 끝 4자리
                    </td>
                    <td>
                      <span class="highlight">3년까지</span>(전자상거래 등에서의
                      소비자보호에 관한 법률)
                    </td>
                  </tr>
                  <tr>
                    <td>제휴포인트</td>
                    <td>휴대폰번호, OK캐쉬백 번호, 아시아나항공 번호</td>
                    <td>
                      <span class="highlight">요금제 종료 후 5년까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>이벤트</td>
                    <td>휴대폰번호</td>
                    <td>
                      <span class="highlight">이벤트 종료 후 3개월까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>무료통화 신청</td>
                    <td>휴대폰번호</td>
                    <td><span class="highlight">6개월까지</span></td>
                  </tr>
                  <tr>
                    <td>앱 다운로드 URL 전송, 고객 불만상담</td>
                    <td>휴대폰번호</td>
                    <td>
                      <span class="highlight">3년까지</span>
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>SK국제전화 00700 앱</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>
                      회원가입(휴대폰인증, 국제전화서비스 조회, 변경, 이벤트,
                      상담)
                    </td>
                    <td>휴대폰번호, 생년월일, DI, 회원등급</td>
                    <td>
                      <span class="highlight">서비스 이용 기간</span><br />※
                      회원 탈퇴 시, 고객민원 처리 등의 사유로 최대 3개월까지
                      보유(법령에 따로 정한 경우에는 해당 기간까지)
                    </td>
                  </tr>
                  <tr>
                    <td>제휴포인트</td>
                    <td>휴대폰번호, OK캐쉬백 번호, 아시아나항공 번호</td>
                    <td>
                      <span class="highlight">요금제 종료후 5년까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>이벤트</td>
                    <td>휴대폰번호</td>
                    <td>
                      <span class="highlight">6개월까지</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- end // to개발 / popup01 개인정보의 이용목적, 수집항목 및 보유기간(필수) -->

      <!-- to개발 / popup02 개인정보의 이용목적, 수집항목 및 보유기간(선택) -->
      <section
        v-if="currentModal === '2'"
        id="modalLayerPopup"
        key="20210120-02"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              개인정보의 이용목적, 수집항목 및 보유기간(선택)
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>
                      이동전화서비스와 SK텔링크가 제공하는 다른 상품∙서비스간,
                      개인정보의 결합∙분석 및 이를 통한 개인맞춤∙연계서비스
                      혜택제공 및 고객센터 상담
                    </td>
                    <td>
                      이동전화서비스 이용 시 개인정보 수집∙이용에 동의한 모든
                      항목(서비스 이용기록 포함)
                    </td>
                    <td>
                      <span class="highlight">서비스 이용 기간</span> <br />
                      ※ 영리목적의 광고성 정보 전송을 철회하고자 하는 경우 080-
                      897-0999 을 통하여 가능
                    </td>
                  </tr>
                  <tr>
                    <td>사은품 제공</td>
                    <td>이름, 이동전화번호, 주소</td>
                    <td>
                      <span class="highlight">신청서 작성 완료일부터 90일</span>
                    </td>
                  </tr>
                  <tr>
                    <td>명의도용 조사 및 수사기관을 통한 수사의뢰</td>
                    <td>
                      이름, 생년월일, 성별, 이동전화번호, 주소, 전화번호,
                      가입신청서, 서비스이용내역, 상담내역, 미납 등
                      요금납부정보, 단말계약내역, 분할상환내역
                    </td>
                    <td rowspan="2">
                      <span class="highlight"
                        >명의도용 조사 완료일로부터 5년</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>명의자, 가입대리점, 실사용자에 의한 법적분쟁 대응</td>
                    <td>
                      명의도용 접수신청서, 명의도용 조사내역, 판정결과, 변상내역
                    </td>
                  </tr>
                </tbody>
              </table>
              <h2>sk7mobile.com 홈페이지</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>

                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>
                      유심 선물, 7쿠폰, 기타 신규 상품 및 프로모션 혜택 안내
                    </td>
                    <td>이름, 이동전화번호, 이메일</td>
                    <td>
                      <span class="highlight"
                        >서비스 해지(회원 탈퇴/동의 철회) 시 즉시 파기 </span
                      ><br />
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>SK국제전화 00700 앱</h2>
              <table>
                <colgroup>
                  <col style="width: 40%" />
                  <col style="width: 40%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>수집·이용 목적</th>
                    <th>수집 항목</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>국제전화 혜택/이벤트 안내</td>
                    <td>
                      이동전화번호, 이용목적, 설치경로, 선호요금제, 선호혜택
                    </td>
                    <td>
                      <span class="highlight">서비스 이용 기간</span><br />※
                      회원 탈퇴 시, 고객민원 처리 등의 사유로 최대 3개월까지
                      보유
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- end // to개발 / popup02 개인정보의 이용목적, 수집항목 및 보유기간(선택) -->

      <!-- 기본적인 서비스 제공을 위해 타 기관으로부터 제공받는 정보  -->
      <section
        v-if="currentModal === '3'"
        id="modalLayerPopup"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              기본적인 서비스 제공을 위해 타 기관으로부터 제공받는 정보
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <table>
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>서비스명</th>
                    <th>제공자</th>
                    <th>제공받는 항목</th>
                    <th>수집 및 이용목적</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td rowspan="2">sk7mobile.com 홈페이지</td>
                    <td>나이스평가정보, 코리아크레딧뷰로</td>
                    <td>연계정보(CI) &amp; 중복확인정보(DI)</td>
                    <td>서비스 이용에 따른 본인식별∙인증절차에 이용</td>
                    <td>
                      <span class="highlight">본인식별∙인증완료시까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>SK브로드밴드</td>
                    <td>
                      연계정보(CI), 이름, 이동전화번호, SK브로드밴드
                      서비스관리번호, SK 브로드밴드 서비스가입내역(가입, 정지,
                      해지)
                    </td>
                    <td>
                      SK브로드밴드가 제공하는 통신 서비스(인터넷, 전화, B tv,
                      케이블 인터넷, B tv 케이블, 케이블 인터넷 전화 등)
                      이용(가입/변경/해지 등 포함) 및 요금 등 통신 서비스 관련
                      정보 제공
                    </td>
                    <td>
                      <span class="highlight">서비스 해지</span>(회원 탈퇴/동의
                      철회) 시 즉시 파기(단, 법령에서 따로 정한 경우 해당 기간)
                    </td>
                  </tr>
                  <tr>
                    <td>국제전화 00700.com 홈페이지</td>
                    <td>코리아크레딧뷰로</td>
                    <td>연계정보(CI) &amp; 중복확인정보(DI)</td>
                    <td>서비스 이용에 따른 본인식별∙인증절차에 이용</td>
                    <td>
                      <span class="highlight">본인식별∙인증완료시까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>SK 국제전화 00700 앱</td>
                    <td>코리아크레딧뷰로</td>
                    <td>연계정보(CI) &amp; 중복확인정보(DI) &amp; 생년월일</td>
                    <td>서비스 이용에 따른 본인식별∙인증절차에 이용</td>
                    <td>
                      <span class="highlight">본인식별∙인증완료시까지</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!--end 기본적인 서비스 제공을 위해 타 기관으로부터 제공받는 정보  -->

      <!-- to개발 / popup03 개인정보처리 업무의 위탁 -->
      <section
        v-if="currentModal === '4'"
        id="modalLayerPopup"
        key="20210120-03"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              개인정보처리 업무의 위탁
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width: 30%" />
                  <col style="width: 30%" />
                  <col style="width: 40%" />
                </colgroup>
                <caption>
                  이동전화 개인정보처리 - 위탁한 업체, 위탁 업무 정보입니다.
                </caption>
                <tbody>
                  <tr>
                    <th>위탁한 업체</th>
                    <th>다시 위탁한 업체</th>
                    <th>위탁 및 다시 위탁한 업무</th>
                  </tr>

                  <tr>
                    <td rowspan="3">SK텔레콤</td>
                    <td>SK주식회사C&C</td>
                    <td>고객관리시스템 운영</td>
                  </tr>
                  <tr>
                    <td style="border-left: 1px solid #e2e2e4">
                      에프앤유신용정보
                    </td>
                    <td>
                      · 이동전화 관련 각종 조회, 변경 등 각종 고객센터 업무<br />
                      . 요금청구서 <br />
                      . 미납요금에 대한 채권추심 및 추심업무 관련 고객 상담
                    </td>
                  </tr>
                  <tr>
                    <td style="border-left: 1px solid #e2e2e4">SK플래닛</td>
                    <td>AI 고객상담 업무</td>
                  </tr>
                  <tr>
                    <td>SK주식회사C&C</td>
                    <td>씨앤토트플러스</td>
                    <td>IT 인프라 운영</td>
                  </tr>
                  <tr>
                    <td>SK쉴더스</td>
                    <td></td>
                    <td>보안 운영</td>
                  </tr>
                  <tr>
                    <td>그린피플</td>
                    <td></td>
                    <td>요금조정, 청구서검증, 금액대사, 재고관리</td>
                  </tr>
                  <tr>
                    <td>서비스에이스</td>
                    <td></td>
                    <td>가입정보확인</td>
                  </tr>
                  <tr>
                    <td>코니퍼</td>
                    <td></td>
                    <td>고객관리시스템 운영</td>
                  </tr>
                  <tr>
                    <td>아이브랩</td>
                    <td></td>
                    <td>온라인시스템 운영</td>
                  </tr>
                  <tr>
                    <td>자몽컴퍼니</td>
                    <td></td>
                    <td>전자문서시스템 운영</td>
                  </tr>
                  <tr>
                    <td>나이스평가정보, 코리아크레딧뷰로</td>
                    <td></td>
                    <td>
                      • 실명인증서비스<br />
                      • 본인확인서비스 (휴대폰인증, 범용공인인증, 신용카드인증,
                      PASS인증)<br />
                      • 아이핀서비스
                    </td>
                  </tr>
                  <tr>
                    <td>네이버</td>
                    <td></td>
                    <td>본인확인서비스 (네이버인증)</td>
                  </tr>
                  <tr>
                    <td>케이에스넷</td>
                    <td></td>
                    <td>은행계좌 자동이체 유효성인증</td>
                  </tr>
                  <tr>
                    <td>케이에스넷, 페이레터</td>
                    <td></td>
                    <td>신용카드 결제</td>
                  </tr>
                  <tr>
                    <td>버프리프</td>
                    <td></td>
                    <td>이동전화 가입, 상담, 배송, 이벤트</td>
                  </tr>
                  <tr>
                    <td>대리점 8개 (※대리점명 표하단)</td>
                    <td>판매점 4,355개 (※판매점명 표하단)</td>
                    <td>가입, 변경, 해지, 번호이동, 요금수납, 기타 상담</td>
                  </tr>
                  <tr>
                    <td>체인로지스</td>
                    <td></td>
                    <td>유심∙단말기 배송</td>
                  </tr>
                  <tr>
                    <td>만나코퍼레이션</td>
                    <td>만나플래닛 외 배송업체 69개 (※배송업체명 표하단)</td>
                    <td>유심∙단말기 배송</td>
                  </tr>
                  <tr>
                    <td>바로고</td>
                    <td>배송업체 179개 (※배송업체명 표하단)</td>
                    <td>유심∙단말기 배송</td>
                  </tr>
                  <tr>
                    <td>GS엠비즈, SK엠앤서비스</td>
                    <td></td>
                    <td>사은품제공</td>
                  </tr>
                  <tr>
                    <td>한국정보통신진흥협회</td>
                    <td></td>
                    <td>
                      • 개인신용정보 전송 요구에 따른 (거점)중계기관 이용<br />
                      • 알뜰폰허브 가입 접수
                    </td>
                  </tr>
                  <tr>
                    <td>인포뱅크</td>
                    <td></td>
                    <td>카카오톡 알림</td>
                  </tr>
                </tbody>
              </table>

              <h2>국제전화(00700.com 홈페이지, 앱)</h2>
              <table>
                <colgroup>
                  <col style="width: 30%" />
                  <col style="width: 30%" />
                  <col style="width: 40%" />
                </colgroup>
                <caption>
                  국제전화 개인정보처리 - 위탁 업체, 위탁 업무 정보입니다.
                </caption>
                <tbody>
                  <tr>
                    <th>위탁한 업체</th>
                    <th>다시 위탁한 업체</th>
                    <th>위탁 및 다시 위탁한 업무</th>
                  </tr>
                  <tr>
                    <td>코니퍼</td>
                    <td></td>
                    <td>고객관리시스템 운영</td>
                  </tr>
                  <tr>
                    <td>나이스신용정보</td>
                    <td></td>
                    <td>
                      서비스 고객상담, 국제전화 서비스 이용요금제 안내,
                      전자문서시스템 운영
                    </td>
                  </tr>
                  <tr>
                    <td>그린피플</td>
                    <td></td>
                    <td>요금조정, 청구서검증, 금액대사, 재고관리</td>
                  </tr>
                  <tr>
                    <td>서비스에이스</td>
                    <td></td>
                    <td>가입정보확인</td>
                  </tr>
                  <tr>
                    <td>SK엠앤서비스</td>
                    <td></td>
                    <td>국제전화 서비스 이용요금제 안내</td>
                  </tr>
                  <tr>
                    <td>GS엠비즈</td>
                    <td></td>
                    <td>상품권 발송</td>
                  </tr>
                  <tr>
                    <td>코리아크레딧뷰로</td>
                    <td></td>
                    <td>본인확인서비스 (휴대폰인증)</td>
                  </tr>
                </tbody>
              </table>
              <div style="font-size: 12px; font-weight: 400">
                ※ 대리점 : 태우통신, 엠씨씨네트월드, 비전모바일,
                에스제이네트웍스, 에셀트리, 글로벌네트웍스, 인스코리아,
                고고팩토리
                <br />
                ※ 판매점 : (강남동)휴대폰아울렛, 010네트웍, 010모바일, 010통신,
                1004(천사), 1345모바일, 162텔, 1986모바일, 1백점만점,
                21세기텔레콤, 21세기폰통령, 25시텔레콤, 265-토르폰, 2백점만점,
                2호광장아이통신, 2호선통신, 300텔레콤, 322-신부산모바일,
                365모바일, 365폰, 365폰마트, 365휴대폰, 3남매아빠폰가게,
                3백점만점, 403-아재통신, 441-청춘개금, 444-금샘,
                477-빌리언모바일, 486통신, 777휴대폰내수점, 777휴대폰대소점,
                777휴대폰안성점, 777휴대폰오성점, 777휴대폰청당점,
                777휴대폰통정점, 789휴대폰할인마트, 88컴퍼니, 88통신,
                933-탑스텔레콤, 99텔레콤(보라점), 99통신, A1모바일, A1정보통신,
                A1제일통신, abcsmartkorea, ABC폰마트, AH네트웍스, AM모바일,
                AM통신, ASIAMOBILE, ASUS김해, B.KTELECOM, band통신, BD통신,
                BF연합통신, BK통신, C&T프라자SK, CJ텔레콤, ck통신, CL모바일,
                CS통신, D&G통신, DB유통, DC마트(LABAKADE), DC텔레콤, DHPL,
                DH통신, DK모바일, DM모바일, DM프리넷, DN통신, e-2통신, ECOPHON,
                EU정보통신, E통신, e편한통신, FM통신, GF모바일, GnB, G텔레콤,
                HENGSANGSHOP, HM텔레콤, Hnetworks, H모바일, H통신,
                IDM(아이디엠), IMC모바일, IMT코리아, INS대곶점, IT정보통신,
                i이동통신, J.S휴대폰아울렛, JH모바일, JH통신, JK모바일,
                JK텔레콤, JK통신, JM정보통신, JM텔레콤, JN모바일, JS정보통신,
                Js텔레콤, JS통신, JUNG텔레콤, JW모바일, j원mobile, J텔레콤,
                K.L정보통신, K-1정보통신, KB통신3호점, KTM통신, KT스마트통신,
                K-플러스, LH모바일, LOL모바일, LT.Mart, LTE통신, LUCKY텔레콤,
                LV통신, MAGNET, Main, MAMA통신, MC텔레콤, MJ모바일, MK,
                MK모바일, MK정보, M-play, MSK, MS통신, MVP텔레콤, M-ZONE, m스타,
                M통신, NEW우주상사, N샵운서, N-통신, O2통신, OJMART, OK모바일,
                OK텔레콤, OK통신, P.S모바일, PMJ캄보디아통신, pop정보통신,
                ROO정보통신, S.H정보통신, S.S텔레콤, S.T유통, S2정보통신,
                SC모바일, Seven텔레콤, SHAPLA(샤플라), SH모바일, sj다솜은행나무,
                SJ모바일, sj텔레콤, sks통신, sk동일텔레콤, SK미라클대리점,
                sk숭인점, SK유림, SK작전방화, SK정보통신, SK중앙통신, SM텔레콤,
                SM통신, spf무역, SR모바일, SSD헤렌텔레콤, SS모바일, SUN모바일,
                sun정보통신, SUN텔레콤, S모바일, THE조아통신, THTelecom,
                TI모바일, TnKmobile, TOP정보통신, TOP텔레콤, TOP통신,
                TRY(트라이모바일), T명동, T정보통신, VIKOFMOBILE, VIPMobile,
                VTMOBILE, WELLCOMM, YB컴퍼니, YD컴퍼니운정점, YG이동통신,
                YL통신, YT통신, Y텔레콤, 가나모바일, 가람, 가람모바일, 가람통신,
                가람폰마트, 가로수텔레콤, 가야모바일, 가야통신,
                가온누리정보통신, 가온모바일, 가온이동통신, 가온정보통신,
                가온컴퍼니, 가온컴퍼니장유지점, 가온통신, 가은이네, 가은통신,
                가이드, 가이드상사, 가이드텔레폰, 가인정보통신,
                가장싼핸드폰가게, 가재울휴대폰, 가족통신, 가치컴퍼니,
                가호통신사천점, 갈렙(Caleb)종합상사, 감동모바일, 감동텔레콤,
                감동통신, 감만정보통신, 감성모바일, 감자통신, 감전텔레콤,
                강남모바일, 강남엔젤폰, 강남중고폰매입판매에코폰강남역,
                강동텔레콤, 강명통신, 강북구수유중고폰코리아모바일, 개미통신,
                갤럭시, 갤럭시SM, 갤럭시에스엠, 갬성카, 거북텔레콤, 거상텔레콤,
                거성정보통신, 거성통신, 거제맘통신_아주점, 거제액정나라,
                거창쿡누리, 거창통신, 건강모바일, 건강한휴대폰, 건민정보통신,
                건우모바일, 건우모바일둔산수정점, 건우모바일원신흥점,
                겨루스마트통신, 경&정보통신, 경기광주휴대폰성지픽업폰,
                경기도매폰센터상현폰, 경남통신, 경남통신사천점, 경동정보통신,
                경북정보통신, 경산중고폰, 경산중고폰거래소, 경성대중고유투폰,
                경원통신, 고고통신, 고고팩토리, 고구려모바일, 고래모바일,
                고래통신, 고래폰덕계점, 고래폰범천점, 고려, 고려모바일,
                고려이동통신, 고려정보통신공사, 고봉국제무역, 고양폰,
                곡성텔레콤, 곤지암통신, 골드맥시스템, 골드모바일, 골드무역,
                골드문통신, 골드텔레콤, 골든드래곤, 골든모바일, 골든벨,
                곰텔레콤, 곰텔레콤(경산점), 공간모바일, 공간정보통신,
                공감모바일, 공룡모바일, 공룡통신, 공설통신, 공성모바일샵,
                공일공텔레콤중앙점, 공일공통신, 공주그린서비스센터, 공짜나라,
                공짜텔레콤, 공짜파티, 관악천보, 관우통신장현점, 관훈,
                광교휴대폰성지모두의폰, 광장텔레콤, 광장통신, 광주애니콜,
                광주전자, 광주통신GG상사, 광천핸드폰가게, 광통신,
                광희통신(고속터미널점), 구구텔레콤, 구구폰, 구대도통신,
                구로원정보통신, 구름, 구름통신2(신도림), 구리스카이정보통신,
                구리중고폰에코폰, 구리통신, 구미민주정보통신2, 구미아이폰수리,
                구미중고폰, 구미휴대폰성지, 구미휴대폰성지(황상점), 구백억,
                구영모바일, 구찌통신, 구파발점YL통신, 구평준프리, 국가대표통신,
                국대폰, 국보통신, 국성텔레콤, 국제통신, 국화통신,
                군산통신프라자, 군산휴대폰성지, 군산휴대폰성지조촌디오션점,
                군포, 군포텔레콤, 굿뉴스모바일, 굿대디, 굿데이텔레콤,
                굿모닝당산, 굿모닝모바일2, 굿모닝외대점, 굿모닝정보통신,
                굿모닝텔레콤, 굿모닝통신, 굿모바일, 굿타임통신, 굿텔레콤,
                규봉모바일(구로), 그냥저냥, 그린맘통신, 그린모바일,
                그린모바일2호점, 그린이동통신, 그린정보통신, 그린코리아,
                그린텔레콤, 그린통신, 글로벌, 글로벌네트웍스,
                글로벌머니익스프레스00, 글로벌머니익스프레스01,
                글로벌머니익스프레스02, 글로벌머니익스프레스03,
                글로벌머니익스프레스04, 글로벌머니익스프레스05,
                글로벌머니익스프레스06, 글로벌머니익스프레스08,
                글로벌머니익스프레스09, 글로벌모바일홍은프라자, 글로벌시스템,
                글로벌엘투, 글로벌종합상회, 글로벌케이엔씨, 글로벌텔레콤,
                글로벌통신, 글컴정보통신, 금강telecom, 금강정보통신, 금강텔레콤,
                금모래, 금복통신, 금산통신, 금솔씨앤엘아산용화지점,
                금영미래통신, 금오정보통신, 금오텔레콤, 금용통신, 금이텔레콤,
                금정모바일, 금천모바일_서울8913, 금천천보통신, 금촌폰,
                금호한빛통신, 기가(GIGA)모바일, 기가텔레콤, 기분좋은대리점,
                기분좋은모바일, 기원, 기장판다모바일, 길동텔레콤, 길벗정보통신,
                길상모바일, 길상휴대폰, 길정보통신, 길통신, 김군네폰가게,
                김밥모바일, 김서방텔레콤, 김응언모바일, 김자매통신, 김천영통신,
                김천텔레콤, 김포아저씨, 김해휴대폰성지따봉폰, 까래통신,
                까미통신, 까츄모바일, 까치산모바일, 깐부통신, 깨모바일,
                꽁돈버는머니폰, 꽃남통신, 꿀꿀스마트통신, 꿀단지텔레콤, 꿀통신,
                꿀폰마켓, 끼우칭모바일, 나나힐통신, 나누리통신, 나눔모바일9호,
                나눔통신, 나눔통신아이폰수리, 나는나텔레콤, 나라텔레콤,
                나라통신, 나래이동통신(본점), 나래정보통신, 나래텔레콤,
                나래통신, 나로통신, 나르샤, 나리정보(NARI), 나리통신,
                나마스떼모바일, 나무커머스, 나무텔레콤, 나바인터내셔널,
                나비모바일, 나우모바일, 나우이공이, 나우통신, 나은, 나이스통신,
                나인모바일, 나통신, 낙원통신, 남동통신, 남매통신, 남문텔레콤,
                남부정보통신, 남산천사텔레콤, 남서울정보통신, 남양모바일,
                남양본가, 남양통신, 남통드림통신, 낫띵이즈임파서블, 내담통신,
                내손모바일, 내폰을부탁해, 너구리C, 넘버원, 넘버원모바일,
                넘버원모바일서부점, 넘버원텔레콤, 네모모바일, 네오정보통신,
                네오통신, 네이버정보통신, 네일카롱, 네잎크로바통신, 네팔모바일,
                넥스테라, 넥스트제너레이션모션, 노란마켓강릉점,
                노란마켓남포역점, 노란마켓대구점, 노란마켓대전시청역점,
                노란마켓부산대점, 노란마켓부산사상, 노란마켓부산서면,
                노란마켓부산해운대센텀점, 노란마켓울산삼산, 노란마켓의정부점,
                노란마켓중고폰광주점, 노란마켓파주점, 노랑폰, 노마상회,
                노마진모바일(산곡), 노마진정보통신, 노마진폰마트, 노바통신,
                노블무역&환전, 노원(NO.ONE, 녹색통신, 논공규통신, 논노모바일,
                논산탑통신, 누리텔레콤, 누리통신, 누림모바일, 눈비,
                뉴(new)야탑통신, 뉴(New)폰, 뉴디지털정보통신, 뉴보스,
                뉴서울정보통신, 뉴스카이암사점, 뉴스타2호점, 뉴스타통신,
                뉴아마존, 뉴엔탑통신, 뉴정보통신, 뉴코아텔레콤2, 뉴텔레콤,
                뉴텔레콤밀레, 뉴텔레콤석전점, 뉴폰, 뉴폰박사, 늑대와여우컴퓨터,
                늘푸른통신, 니즈플러스광주지점dc마, 닉카컴퍼니, 닌자통신,
                다다모바일, 다다텔레콤, 다다통신, 다다폰, 다둥이통신, 다드림,
                다마꼬데스, 다모아정보통신, 다모아통신, 다문화(DMH)통신,
                다비인터내셔널, 다빈텔레콤, 다산JS모바일, 다산텔레콤, 다산통신,
                다산폰마트, 다솔, 다솔모바일, 다연정보통신, 다연통신, 다온,
                다온모바일, 다온정보, 다온정보통신, 다온정보통신월곡점,
                다온텔레콤, 다온통신, 다올, 다올정보통신, 다올텔레콤,
                다옴텔레콤, 다와정보통신, 다우통신, 다움, 다원TC장안,
                다원텔레콤, 다원텔레콤2, 다원통신, 다윗통신, 다은텔레콤,
                다음모바일, 다음통신, 다이렉트PC, 다이아, 다이아모바일,
                다이아통신, 다인정보통신, 다인텔레콤, 다인통신, 다자바통신,
                다정모바일, 다진하이킥, 다판다모바일, 다판다새폰중고폰,
                다함께폰폰폰, 닥터폰인하텔레콤, 단골정보통신, 단대오거리통신,
                단무지폰, 단비2호점, 단유통신, 단해텔레콤, 달리컴퍼니, 달빛,
                달인텔레콤3호점, 달콤모바일, 당근모바일, 당근통신, 당근폰,
                당근폰마켓, 당신을위한휴대폰비서, 당진휴대폰, 당하한빛이동통신,
                대감텔레콤, 대경텔레콤, 대구아이폰수리바른수리,
                대구아이폰수리이지픽스, 대구아이폰수리휴고남,
                대구칠곡중고폰에코폰, 대구텔레콤, 대도모바일2호점, 대도통신,
                대동애니콜, 대림차이홍4호점, 대림통신, 대명정보통신, 대명텔레콤,
                대명통신, 대명폰, 대명폰2호점, 대명폰3호점, 대박, 대박모바일,
                대박엠케이통신, 대박착한폰2호점, 대박텔레컴, 대박텔레콤,
                대박통신, 대박통신(행정점), 대박통신감일점, 대박통신도촌점,
                대박통신세류점, 대박통신위례점, 대박통신응암점, 대박폰,
                대박폰요기요, 대박플러스통신, 대불정보통신, 대상인의상점,
                대상정보통신, 대상텔레콤, 대성모바일, 대성모바일(플레너),
                대성텔레콤, 대성통신, 대신무역, 대신정보, 대연1호점JK,
                대연3호점(JK), 대영모바일, 대영정보통신, 대영텔레콤2호점,
                대영텔레콤4호점, 대우정보통신, 대우통신, 대원TELECOM,
                대원모바일, 대원텔레콤, 대원통신, 대인배모바일, 대일통신,
                대전상회, 대전선불폰, 대정씨앤씨서울지점, 대진17호, 대진텔레콤,
                대찬통신, 대통령,대박마트, 대풍수통신, 대한상사, 대한정보통신,
                대한텔레콤, 대한통신, 대현정보통신, 대호정보통신, 대화동양,
                대화통신, 더끌린통신, 더나은, 더더컴퍼니, 더라온, 더리치,
                더모스트모바일, 더베스트폰, 더본모바일, 더블앤코리아, 더블에스,
                더블유모바일, 더블유에스텔레콤, 더블제로모바일, 더블조이,
                더블케이대박통신, 더산컴퍼니, 더싼모바일, 더원네트웍스,
                더원모바일, 더원텔레콤, 더조은폰, 더좋은휴대폰, 더주누(TheJUNU),
                더차칸모바일1호점, 더차칸모바일2호점, 더컴퍼니, 더컴퍼니행신점,
                더케이, 더티진, 더폰, 더폰？, 더폰샵, 더폰샵하단점, 더폰코리아,
                더플레이, 덕계리통신, 덕산텔레콤, 덕신휴대폰, 덕암큰소리,
                덕후모바일, 데오통신, 데이타통신, 데일리, 도경글로벌, 도담인천,
                도담통신, 도량sk, 도량행복통신, 도리통신, 도마혁,
                도매통신산척점, 도매특판우주폰, 도매폰, 도매폰2010,
                도매폰광주점, 도매폰구운점, 도매폰마트(호두텔레콤), 도매폰센터,
                도매폰의정부, 도연, 도연(평동), 도연통신, 도연통신신가점,
                독도폰, 돌고래통신, 돌다리텔레콤, 동광전자, 동남텔레콤,
                동네방네폰가게, 동네통신, 동네폰, 동대구유림, 동대문텔레텍,
                동방텔레콤, 동방통신, 동보텔레콤, 동보텔레콤이마트점,
                동부나이스드림, 동부통신, 동북통신, 동산텔레콤, 동산통신,
                동성텔레콤, 동신정보통신, 동아레코드, 동아모바일(지점),
                동아모바일만수점, 동아이동통신, 동양상사, 동원무역, 동이,
                동인텔레콤, 동일통신, 동진정보통신, 동촌모바일,
                동탄휴대폰성지일등폰, 동해아이폰수리센터아이픽스굿,
                동해정보통신, 돼지통신, 돼지휴대폰당진점, 두두모바일,
                두리서창현점, 두리텔레콤, 두용모바일, 두원통신, 두청년, 두청년2,
                두청년3, 두폰, 둥이종합조경, 둥이통신, 드래곤통신, 드루와통신,
                드림, 드림네트웍스, 드림모바일, 드림온정보통신, 드림정보통신,
                드림텔레콤, 드림통신, 드림티에스, 드림폰마트, 드림프리덤가평점,
                드림프리덤길동2점, 드림프리덤덕소점, 드림프리덤마천사거리점,
                드림프리덤목동1점, 드림프리덤사가정1점, 드림프리덤사가정3점,
                드림프리덤역삼점, 드림프리덤의정부가능점, 드림프리덤잠실1점,
                드림프리덤잠실2점, 드림프리덤종암2점, 드림프리덤죽전점,
                드림프리덤청평점, 득롱모바일, 디디모바일, 디디엠미즈존,
                디디컴퍼니, 디디컴퍼니좌동점, 디딤모바일, 디모바일(D모바일),
                디비통신, 디씨즈, 디앤에스, 디앤티부강점, 디에스(DS)모바일,
                디에스솔루션, 디에스정보통신, 디에스컴퍼니, 디에스텔레콤,
                디에스통신, 디에스통신을지로점, 디에이치(DH)모바일, 디엔시티,
                디엘무역, 디엠디한중여행사, 디오정보통신, 디오젠커머스,
                디와이(DY)통신, 디와이엠통신, 디와이텔레콤, 디제이모바일,
                디지싸본점(DGSSA), 디지털정보, 디지털텔레콤, 디컴퍼니, 디피알,
                디핑케이스세종점, 딩동댕이동통신, 딩딩모바일, 따르릉텔레콤,
                따베르나, 따봉통신, 딸기텔레콤, 딸기폰, 딸랑이통신, 딸부자통신,
                땅파서폰파는집, 땅파서폰파는집(내외동점),
                땅파서폰파는집(사직점), 땅파서폰파는집(아랫반송점),
                땅파서폰파는집덕천점, 땅파서폰파는집부전점,
                땅파서폰파는집창원지점, 땅파서폰파는집충무점, 땅폰광안점,
                땅폰동래점, 땅폰반여, 땅폰수영점, 땅폰집장유점, 또바기모바일,
                똥개텔레콤, 뚜뚜통신, 뚜비모바일(충주), 라나컴퓨터, 라니통신,
                라라네트웍스, 라라모바일, 라라정보통신, 라라텔레콤, 라라통신,
                라미야전자담배화정점, 라베니체폰, 라비커뮤니케이션, 라온,
                라온글로벌동탄점, 라온컴퍼니, 라온텔레콤, 라이브폰,
                라이브폰1호점, 라이브폰2호점, 라이언텔레콤, 라이온모바일,
                라이프정보통신, 라이프텔레콤, 라이프통신, 라인모바일,
                라인텔레콤흥덕점, 라임텔레콤, 라임통신, 라함텔레콤, 람보통신,
                래미안2호점, 랜퉁텔레콤, 러브모바일, 러브모바일(갈마점),
                러화국제, 럭키, 럭키모바일, 럭키무역, 럭키정보통신천천중앙지점,
                럭키텔레콤, 럭키통신, 럭키홀, 레드비통신, 레드정보통신(본오점),
                레드텔레콤, 레드텔레콤(신암), 레드통신, 레드통신초록점,
                레아모바일, 레오통신, 레이통신, 레인보우모바일,
                레인보우텔레콤(연무점), 레전드모바일2, 레전드모바일3호점,
                레전드텔레콤, 렐레, 렝다완폰샵, 로건컴퍼니, 로그인,
                로덱스화장품&정통신, 로또모바일, 로또텔레콤, 로아정보통신,
                로얄통신쌍문동(부성), 로얄트레이딩, 로원텔레콤, 로이, 로하스,
                롸켱롸르노, 루시텔레콤, 루아모바일, 루이통신, 루키텔레콤,
                루프리텔레콤, 룸비니, 륀뽀, 류컴퍼니, 륜경통신시스템,
                르호봇시스템, 리더스, 리더스오브이노베이션,
                리더스오브이노베이션남양, 리더스텔레콤교대점, 리더정보통신,
                리본모바일, 리빙텔레콤함양점, 리사모바일, 리아,
                리안외국인종합서비스센터, 리안외국인지원센터대전,
                리안외국인지원센터인천, 리안텔레콤, 리오모바일, 리우컴,
                리즈모바일, 리치모바일, 리치컴퍼니, 리치텔레콤, 리치통신,
                리카모바일, 리틀정보통신, 리호모바일, 리호컴퍼니, 린헤어, 링링,
                링치그룹, 마루모바일, 마루텔레콤, 마루통신, 마르가드, 마마통신,
                마모텔레콤, 마산탑모바일, 마산하나로, 마석본가, 마스터통신,
                마이모바일, 마이텔레콤, 마이통신, 마징가몰로즈,
                마징가제2의김연아김하린, 마징가텔레콤사송점, 마카롱통신,
                마켓제이앤제이, 막내통신, 막탄폰, 만개통신, 만기통신, 만나폰,
                만두모바일, 만두정보통신, 만복텔레콤, 만선, 만세통신W스퀘어점,
                만세통신대연혁신점, 만세통신덕계점, 만세통신서창점, 만세폰,
                만월, 만촌통신, 만평통신, 망고케이스, 망내, 매니아89본점,
                매니아M&C, 매니아텔레콤, 매직모바일간석점, 매직정보통신,
                맥스모바일, 맥스컴퍼니, 맥스플러스, 맨앤텔, 맹호모바일,
                머니통신, 머천드코리아, 멀티샵, 멀티스, 멀티정보통신,
                멀티존텔레콤, 멀티폰샵, 멋쟁이통신, 메가스토어, 메가정보통신,
                메가텔레콤, 메가통신, 메가폰, 메리텔레콤, 메이트텔레콤(광주),
                메이플라이통신, 메인모바일, 메인텔레콤, 메인통신, 메타체이서,
                메트로통신, 메트로폰, 멤버스, 명기텔레콤, 명문정보통신,
                명문통신, 명산모바일, 명선통신, 명성대박통신, 명성정보능평점,
                명성정보통신, 명성텔레콤, 명신텔레콤, 명음텔레콤, 명지통신,
                명진정보통신, 명진텔레콤, 명촌여행사, 명텔레콤, 명품모바일,
                명품정보통신, 명품텔레콤, 모닝텔레콤, 모두다, 모두통신,
                모든스마트폰, 모든이동통신, 모든텔레콤, 모든폰, 모듬통신,
                모라텔레콤, 모람통신, 모마, 모바시폰짱, 모바일119, 모바일89,
                모바일GSM, 모바일RS, 모바일SC, 모바일라운지언남, 모바일랜드,
                모바일레시피, 모바일면세점, 모바일박스, 모바일뱅크,
                모바일베스트샵, 모바일불리스, 모바일샵, 모바일스퀘어,
                모바일스토어, 모바일스토어성정점, 모바일앤크로스스티치,
                모바일온, 모바일월드, 모바일정보통신, 모바일제이,
                모바일제이능곡점, 모바일존, 모바일컴퍼니, 모바일컴퍼니(장기점),
                모바일컴퍼니장기점, 모바일텔레콤, 모바일통신, 모바일트리,
                모바일파크, 모바일패밀리샵, 모바일팩토리, 모바일폰컴퍼니,
                모바일프렌즈, 모비(MOBI), 모비맨(MOBIMAN), 모비코텔레콤,
                모비피아, 모빌랜드(MOBILAND), 모빌샵, 모아telecom, 모아정보통신,
                모아텔레콤, 모이자, 모전텔레콤, 모토로라정보통신, 모퉁이,
                모현통신, 목감모바일, 목동폰마트365, 목행동휴대폰판매,
                몬스터통신, 몬스터폰야탑점, 몬텔, 몽, 몽키중고폰, 몽키텔레콤,
                몽텔레콤, 무안고추통신, 무열텔레콤, 무적통신, 무지개컴퍼니,
                무지개컴퍼니3호점, 무지개통신, 무한통신, 문막본가, 문모바일,
                문산텔레콤, 문산통신, 문텔레콤, 뭉치모바일, 미나통신, 미남통신,
                미녀텔레콤, 미니텔레콤, 미니통신, 미다스통신, 미달통신,
                미더스통신, 미도텔레콤, 미래무역, 미래정보통신, 미래텔레콤,
                미래통신, 미래플러스통신, 미르, 미르텔레콤, 미르통신,
                미리통신서울한양점, 미림텔레콤, 미사역휴대폰, 미소모바일,
                미소야당점, 미소정보통신, 미소텔레콤, 미소통신, 미소통신강북,
                미소통신건영점, 미솔모바일, 미스미스터렌탈, 미스터모바일,
                미앤상모바일, 미원정보통신, 미추홀통신2호점, 미추홀통신3호점,
                미카엘통신, 미키포토, 민Telecom, 민국통신, 민권모바일,
                민우모바일, 민이동통신, 민재통신, 민정보통신, 민정통신,
                민주모바일, 민지정보통신, 민캉, 민텔, 민텔레콤, 민트모바일,
                민트통신, 민트폰, 민호통신, 민후통신, 믿음모바일, 믿음텔레콤,
                밀레니엄, 밀레니엄C&P, 밀레니엄이동통신, 밀레니엄컴퓨터,
                밀양홀릭통신, 밍탕무역, 바나나모바일, 바나나통신, 바다모바일,
                바다텔레콤, 바로배터리, 바로정보통신, 바르다모바일내동점, 바른,
                바른드림, 바른모바일, 바른모바일봉동점, 바른오피스, 바른컴퍼니,
                바른텔레콤, 바른텔레콤신대점, 바른통신, 바른통신지금점,
                바를정보통신, 바바르트레이딩, 바바모바일, 바비통신, 바양CG,
                바울이동통신, 바이셀폰, 바이셀폰거창점, 바이셀폰경산점,
                바이셀폰김천점, 바이셀폰서구점, 바이폰, 바인, 박가통신,
                박강컴퍼니, 박군통신, 박리다매울산휴대폰특판, 박상욱모바일,
                박카수통신, 반구.콩텔레콤, 반달통신, 반도모바일, 반석정보통신,
                반흐헝, 반히에우모바일, 발아현미휴대폰, 밤가시텔레콤, 방교폰,
                방통모바일, 배민폰, 배산JK, 배재통신, 배티용원점, 백곰모바일,
                백두산통신, 백두통신변동점, 백마폰, 백억통신, 백제통신,
                백호모바일, 백호정보통신, 백호텔레콤, 버디텔레콤,
                버스모바일배곧점, 버스폰, 버팔로, 벅수통신, 벌집, 벌집전남대점,
                벌집통신, 범경, 범스통신, 범이통신, 범준통신, 범텔레콤, 범통신,
                베베폰, 베스트, 베스트모바일, 베스트모바일백석2호점, 베스트바이,
                베스트인코리아, 베스트컴, 베스트케이, 베스트코리안모바일,
                베스트텔레콤, 베스트통신, 베스트통신진평점, 베스트폰,
                베이비통신, 베테랑, 베토벤통신, 벳폰, 벳폰(안산), 벼리텔레콤,
                별고을통신, 별꼴모바일, 별모바일, 별별휴대폰, 별빛온도,
                별빛통신, 별자리통신, 별텔레콤, 별통신, 병점모바일, 보경텔레콤,
                보그텔레콤, 보담모바일, 보라폰, 보람모바일, 보람텔레콤,
                보물선통신, 보물섬통신, 보배드림, 보배통신, 보성정보통신,
                보성텔레콤, 보스그룹, 보스그룹가천대학점, 보스그룹경산점,
                보스그룹계명대점, 보스그룹광주점, 보스그룹군산대점,
                보스그룹대구대점, 보스그룹대구점, 보스그룹대전우송대점,
                보스그룹동서대점, 보스그룹부산대학점, 보스그룹부산점,
                보스그룹사무실, 보스그룹상명대점, 보스그룹서울건대점,
                보스그룹서울경희대점, 보스그룹서울고대점,
                보스그룹서울성균관대점, 보스그룹서울신촌점,
                보스그룹서울중앙대점, 보스그룹수원경희대점, 보스그룹신창점,
                보스그룹아산점, 보스그룹안산한양대점, 보스그룹안성중앙대점,
                보스그룹완주우석대점, 보스그룹용인단국대점, 보스그룹인하대점,
                보스그룹전남대점, 보스그룹전북대점, 보스그룹전주2호점,
                보스그룹청주대점, 보스그룹청주점, 보은폰나라, 보은하나이동통신,
                복산텔레콤, 복산텔레콤남외점, 본앤코리아, 봄봄, 봄정보통신,
                봉봉모바일, 봉봉텔레콤, 봉양통신, 봉텔레콤, 부건모바일,
                부경텔레콤, 부산사상모바일, 부산알뜰폰&선불폰서면개통점,
                부산정보연산점, 부산정보통신, 부산중고폰,
                부산중고폰유투폰해운대점, 부산휴대폰성지옆커폰범일,
                부산휴대폰성지옆커폰사직점, 부산휴대폰성지옆커폰시청점,
                부산휴대폰성지옆커폰화명점, 부산휴대폰성지폰甲(갑)알람폰, 부일,
                부일텔레콤, 부자컴퍼니, 부자텔레콤, 부자통신, 부흥통신,
                북경전화국, 북부모바일, 북삼프라자, 불꽃모바일, 불티나통신,
                붐(BOOM)통신, 붐빔아시아마트, 붕붕콜, 뷔통신, 브더욱글로리,
                브라더컴퍼니, 브라더통신, 브라보정보통신, 브랜드노드,
                브랜드모바일, 브로통신, 브이아이피(VIP)텔레콤, 브이아이피텔레콤,
                브이케이그룹, 브이텔레콤, 블랙퀸, 블루1호점, 블루갤럭시,
                블루나인모바일, 블루나인모바일(3호점), 블루나인모바일(4호점),
                블루명가, 블루모바일, 블루모바일경서점, 블루오션텔레콤,
                블루텔레콤, 블루텔전곡점, 비디모바일, 비발디통신,
                비비(BB)모바일, 비상통신, 비아이티컴, 비앤에프컨설팅,
                비에스(BS)모바일, 비에프(BF)연합통신, 비에프텔레콤, 비엔모바일,
                비엔통신, 비엠모바일, 비엠컴퍼니이진, 비전모바일,
                비제이(BJ)텔레콤, 비제이모바일, 비제트컴퍼니, 비젼텔레콤,
                비젼통신, 비쥬통신, 비케이모바일, 비케이통신, 비타민텔레콤,
                비투씨컴퍼니, 비트통신, 비티에프, 비티에프(대전지점),
                비티에프(여주지점), 비티에프(제천지점), 비티에프(하동지점),
                비피엠통신, 빅게임텔레콤, 빅게임텔레콤미사점, 빅모바일,
                빅뱅정보통신(도화점), 빅뱅정보통신(마전점),
                빅뱅정보통신(법원점), 빅뱅정보통신(삼산점),
                빅뱅정보통신(용현점), 빅뱅정보통신(학익점), 빅뱅정보통신부개점,
                빈모바일, 빈빈통신, 빈스(BINS), 빈텔레콤, 빛고을정보통신,
                빛나통신, 빛으로텔레콤, 빠세텔레콤, 빨간모자, 빵다통신,
                빵빵통신, 빵텔레콤, 빼꼼모바일, 뽀너스통신, 뽀로로모바일2호점,
                뽀로로텔레콤, 쀼통신, 삐삐통신, 삐삐폰대구경산점, 사계절통신,
                사구통신, 사랑Telecom, 사랑모바일, 사랑방비디오.핸드폰,
                사랑이넘치는통신, 사랑텔레콤, 사자폰판다폰, 사하밧트셀룰러,
                산성모바일, 산성텔레콤, 산하정보통신본점, 삼경통신, 삼계텔레콤,
                삼국지모바일, 삼낭모바일, 삼랑진대일통신, 삼미폰케이스,
                삼백통신, 삼부통신, 삼산특판, 삼성SB통신, 삼성스마트모바일,
                삼성스마트폰디에스, 삼성이동통신, 삼성텔레콤, 삼성통신,
                삼성플러스모바일, 삼양프라자, 삼영통신, 삼일텔레콤, 삼일통신,
                삼청통신, 삼총사텔레콤, 삼팔모바일, 상대원정보통신, 상동멀티샵,
                상모정보통신, 상봉모바일, 상상모바일, 상상텔레콤, 상상통신,
                상상트레이드, 상승모바일, 상승모바일1호점, 상승모바일3호점,
                상승모바일5호점, 상승모바일7호점, 상승모바일8호점,
                상승모바일9호, 상승텔레콤, 상어통신, 상주착한통신, 상지컴퍼니,
                상해중국슈퍼, 새내모바일, 새누리통신, 새드텔레콤, 새로나,
                새록통신, 새롬이동통신, 새롬정보통신산곡본점, 새롬텔레콤,
                새롬통신, 새마을정보통신, 새마을통신대신점, 새벽정보통신,
                새별통신, 새봄텔레콤, 새봄통신, 새솔11, 새폰줄께헌폰다오,
                새하텔레콤, 새한정보통신, 새한텔레콤, 색깔텔레콤, 샛별정보통신,
                생생통신, 샤넬텔레콤, 샤샤마트, 샤인, 샤인모바일, 샤크텔레콤,
                샴푸폰나라, 샴푸폰나라송정점, 서강컴퍼니본점, 서광텔레콤,
                서광하트, 서대구통신, 서린글로벌광교점, 서린글로벌봉담점,
                서린글로벌향남점, 서모바일, 서문통신, 서브넷, 서산핸드폰,
                서신텔레콤, 서안모바일, 서연모바일, 서연이동통신, 서연정보통신,
                서연통신, 서영모바일, 서영통신, 서우정보통신, 서울,
                서울사과모바일, 서울이동통신, 서울재팬코머스, 서울전자통신,
                서울휴대폰성지옆커폰강동점, 서울휴대폰성지옆커폰답십리점,
                서울휴대폰성지옆커폰왕십리점, 서울휴대폰성지옆커폰중곡점,
                서울휴대폰성지옆커폰청파점, 서원텔레콤, 서윤정보통신, 서이,
                서진통신, 서하네폰가게, 서하통신, 서한통신, 서현통신,
                서호글로벌, 석사정보통신, 석적깐부통신, 석정보통신,
                선(SUN)모바일, 선단중앙통신, 선단통신, 선도림, 선봉모바일,
                선봉모바일2호점, 선봉모바일5호점, 선봉모바일6호점,
                선봉모바일7호점, 선봉모바일9호점, 선불로알뜰폰선불폰앤텔레콤,
                선산정보통신, 선셋, 선암텔레콤, 선우네트웍스, 선인장통신,
                선인정보통신, 선정보통신노룬산점, 선중텔레콤, 선진통상,
                선한모바일, 설렘모바일(와동점), 설탕텔레콤, 섬비서모바일, 성공,
                성공2호점, 성공모바일, 성공통신, 성남아이폰수리, 성남이동통신,
                성남통신, 성동모바일, 성림텔레콤, 성민통신, 성산하나로통신,
                성성텔레콤, 성신모바일, 성신유통, 성신직업소개소,
                성신텔레콤야음점, 성실모바일, 성예사, 성원텔레콤, 성일정보,
                성재텔레콤, 성주스타통신, 성지, 성지모바일, 성지모바일도림점,
                성지요, 성지요동백점, 성지요옥수점, 성지요인계점, 성지통신,
                성지폰, 성지폰다산점, 성지폰미사점, 성창텔레콤, 성호이동통신,
                성훈대박텔레콤, 세경통신(안산), 세계로통신, 세계통신,
                세계통신송우, 세라세라5, 세린텔레콤, 세림이동통신, 세림텔레콤,
                세민상회, 세복모바일, 세븐모바일, 세븐모바일(장안점),
                세븐삼천폰스토리, 세븐텔레콤, 세븐텔레콤(면목), 세븐통신,
                세상모든폰, 세상모든폰명일역, 세아통신, 세연통신, 세영통신,
                세운텔레콤, 세움정보통신, 세은통신, 세이브통신, 세인텔레콤,
                세일모바일, 세종, 세종텔레콤산격점, 세종통신,
                세종휴대폰성지오빠텔레콤, 세진, 세진2호점, 세진텔레콤, 세진통신,
                세화통신, 세화통신(동명점), 세희통신도매, 센스텔레콤,
                센터모바일, 센텀모바일, 센트럴통신, 셀타운, 셀텍, 셀폰,
                셀폰모바일, 셀프메이드, 셈틀정보통신, 셋터, 소나무텔레콤,
                소녀폰공덕역점, 소녀폰구미김천점, 소녀폰군산점, 소녀폰부천역점,
                소녀폰신사점, 소녀폰용인기흥점, 소녀폰주안점,
                소녀폰중고폰구미인동점, 소녀폰중고폰대구수성경산점,
                소녀폰중고폰세종시점, 소담, 소리모바일, 소리새통신, 소리샘,
                소리샘모바일, 소리샘텔레콤, 소리이동통신, 소리정보통신,
                소리통신, 소망, 소망모바일, 소망텔레콤, 소명모바일, 소미스토리,
                소미통신, 소세아통신, 소울, 소원텔레콤, 소중한텔레콤,
                솔체정보통신, 솔티텔리컴, 솔티휴대폰할인마트, 솜모바일,
                송우리샤샤, 송이통신, 송현텔레콤, 쇼스토어, 쇼앤티통신,
                쇼킹텔레콤, 수(水)글로벌, 수글로벌발안점, 수모바일, 수민텔레콤,
                수박통신, 수산SK, 수서텔레콤, 수성모바일, 수스마트프라자,
                수승통신, 수아모바일, 수아통신, 수엔사라스마트폰, 수엠비,
                수연정보통신, 수연텔레콤, 수원경기대, 수원센트럴,
                수원알뜰폰선불폰, 수원연무, 수원조원, 수원조원시장,
                수원중고폰매입선불폰폰나라, 수원폰-FM텔레콤,
                수원휴대폰성지시원폰, 수정모바일, 수정보통신, 수정통신,
                수진정보통신, 수진텔레콤, 수청대우폰, 수컴퍼니,
                수택모바일팩토리, 수텔레콤, 수통신, 수현정보통신, 수호정보통신,
                수호텔레콤, 숙지정보통신, 순돌이모바일, 순돌이모바일강원점,
                순백스토어, 순이통신, 순진폰, 숨은휴대폰맛집, 숲텔레콤,
                쉐어드림병점점, 슈텔레콤, 슈퍼맨텔레콤, 슈퍼폰, 스레이럿모바일,
                스마일, 스마일보부상, 스마일이동통신, 스마일커뮤니티,
                스마일텔레콤, 스마일통신, 스마일티, 스마일폰, 스마일화장품,
                스마텍코리아글로벌, 스마트, 스마트k, 스마트달인, 스마트모바일,
                스마트모바일방촌점, 스마트모바일효목점, 스마트스토어,
                스마트월드2, 스마트정보, 스마트정보통신, 스마트정석, 스마트지호,
                스마트컴퍼니, 스마트텔레콤, 스마트통신, 스마트폰1번가,
                스마트폰구조대, 스마트폰면세점, 스마트폰면세점(은계점),
                스마트폰면세점(한양대점), 스마트폰면세점고잔점,
                스마트폰면세점덕암점, 스마트폰면세점동탄점,
                스마트폰면세점사동본점, 스마트폰면세점성포점,
                스마트폰면세점송림점, 스마트폰면세점신탄점,
                스마트폰면세점트리지움점, 스마트플래닛, 스마트플러스, 스마트한,
                스마트한폰세상, 스위치모바일에이엔티, 스위트베이마트, 스카이,
                스카이(SKY)텔레콤, 스카이(sky)통신, 스카이(SKYTELECOM),
                스카이K1, 스카이모바일, 스카이블루, 스카이정보통신,
                스카이텔레콤, 스카이텔레콤2호점, 스카이통신, 스타, 스타모바일,
                스타정보, 스타컴퍼니, 스타컴퍼니2호점, 스타텔레콤, 스타통신,
                스타트업, 스타플러스, 스타필휴대폰, 스페이스, 스펙트럼, 스피드,
                스피드모바일, 스피드영통, 스피드원, 스피드작전점,
                스피드정보통신, 스피드텔레콤, 스피드텔레콤매탄,
                스피드텔레콤병점역본점, 스피드텔레콤병점점, 스피드텔레콤안녕점,
                스피드통신, 슬기로운폰가게, 승승장구, 승승장구10호점,
                승승장구12호점, 승승장구14호점, 승승장구16호점, 승승장구22호점,
                승승장구3호점, 승승장구6호점, 승승장구7호점, 승승장구8호점,
                승승장구9호점, 승승장구텔레콤13호점, 승승장구텔레콤20호점,
                승승장구텔레콤21호점, 승승장구텔레콤23호점,
                승승장구텔레콤25호점, 승승장구텔레콤26호점, 승승장구텔레콤2호점,
                승승장구텔레콤4호점, 승승장구텔레콤5호점, 승신텔레콤, 승원통신,
                승재네전화기, 승지화이팅, 승천모바일원당, 시나브로, 시내모바일,
                시네마통신2호점, 시대여행사, 시대정보통신, 시민정보, 시아통신,
                시온, 시온네트워크, 시온모바일, 시원텔레콤, 시원통신,
                시원한정보통신, 시은모바일, 시티텔레콤, 시현통신, 시환텔레콤,
                신광통신, 신기통신, 신길정보통신부곡점, 신길정보통신하상점,
                신나라통신읍내점, 신도림도매폰진접점, 신도림휴대폰성환점,
                신도림휴대폰신창점, 신도림휴대폰아산점, 신동백프라자,
                신동텔레콤, 신명318, 신방동청년휴대폰, 신방이텔레콤, 신사모바일,
                신사임당, 신삽교대리점, 신성난곡점, 신성남정보통신, 신성모바일,
                신세계, 신세계모바일, 신세계이동통신, 신세계전화, 신세계전화국,
                신세계텔레콤, 신세계통신, 신세계통신(월평점), 신세계프로젝트,
                신세기정보통신, 신세기통신, 신세대정보통신, 신안텔레콤,
                신애통신, 신영정보통신, 신영통신, 신우정보, 신우정보통신,
                신월정보, 신창세계, 신천휴대폰, 신터미널이동통신, 신텔레콤,
                신통신, 신폰II, 신한정보통신, 신홍명통신, 신화, 신화그룹,
                신화그룹개운점, 신화이동통신, 신화정보통신, 신화텔레콤,
                신화통신, 신후커뮤니케이션, 신흥가구, 신흥텔레콤, 심곡텔레콤,
                심심코리아, 심코리아, 심패스, 심형, 싸다구통신, 싸다폰, 싸닭폰,
                싸닭폰검단점, 싸대기중고폰, 싸이렉스텔레콤, 싸이버통신,
                싼통신(갈마점), 싼폰마트, 쌍문텔레콤, 써니, 써니(Sunny)프라자,
                써니모바일, 써니텔레콤, 써니통신, 썬(SUN), 썬다롱텔레콤,
                썬모바일(공릉점), 썬엔텔레콤, 썬정보통신, 썬텔레콤, 썬통신,
                썬통신면목점, 썬플러스, 썬플러스중계, 썸2호점, 쎄븐모바일,
                쎄븐텔레콤5호점, 쎄븐텔레콤주안7호, 쏠텔레콤, 쏭쏭모바일,
                씨스타모바일, 씨씨클라쓰, 씨씨클라쓰2호점, 씨씨클라쓰3호점,
                씨씨텔레콤범일점, 씨앤씨모바일, 씨에스CS, 씨에스모바일,
                씨에스텔레콤, 씨에이치모바일, 씨에이치모바일2, 씨엔케이네트웍스,
                씨엔케이정보, 씨엘, 씨엘모바일, 씨엠알모바일, 씨케이모바일,
                씨티정보통신상대원점, 씨티텔레콤(가산점), 씨피(cp)통신,
                씨피모바일, 씽씽모바일, 씽씽텔레콤, 아낌없이주는폰가게(본점),
                아남정보통신, 아딸아모바일, 아라라트, 아란모바일, 아람정보통신,
                아로파정보, 아름텔레콤, 아산텔레콤, 아시아마트, 아시아모바일,
                아시아텔레콤, 아시아폰, 아시아폰마트, 아애롱딴모바일샵,
                아우라모바일, 아우름정보통신, 아워스(OURS), 아이넷통신,
                아이디오, 아이디오(2호점), 아이랜드, 아이모바일, 아이박스24,
                아이에스컴퍼니, 아이엔씨잠실새내, 아이엠모바일,
                아이엠씨모바일완주점, 아이엠티, 아이엠피, 아이원통신,
                아이정보통신, 아이제이텔레콤, 아이텔레콤, 아이티코리아,
                아이티통신, 아이폰닥터수원아이폰수리, 아이폰수리바이셀폰숙대점,
                아이폰수리센터바나나모바일진접, 아이플러스폰울산,
                아이픽스안양아이폰수리센터, 아인모바일, 아인통신2, 아임폰중앙점,
                아재통신, 아재폰, 아정통신, 아토모바일, 아트정보통신선운점,
                아트통신, 아포일등통신, 아프로모바일, 아프리디트레이딩,
                아프리카중고폰, 아프리카중고폰안산중앙지사, 안군모바일,
                안나폰마트, 안드로통신, 안득휴대폰, 안면종합전자대리점,
                안면행복텔레콤, 안산에코폰, 안성네트워크, 안성모바일,
                안양중고폰매입아프리카폰, 안평정보통신, 알뜰닷컴,
                알뜰닷컴정관점, 알뜰모바일, 알뜰온통신, 알뜰통신, 알뜰폰가게,
                알뜰폰문의센터, 알뜰폰선불폰공육삼모바일, 알뜰폰선불폰앤텔레콤,
                알뜰폰천국, 알뜰폰할인점, 알뜰한통신(사천2호점), 알뜰한폰박사,
                알라딘마트, 알로통신, 알로하모바일, 알앤알텔레콤, 알에스통신,
                알에프쓰리월드코리아, 알찬모바일, 알케이유통, 알토할랄마켓,
                알통모바일, 알티통신(RT통신), 알파정보통신, 알파통신, 압정통신,
                앙코르모바일, 애니원정보, 애니콜통신, 애니콜프라자, 애니텔레콤,
                애니통신, 애니통신앤언니, 애드앤샵, 애플, 애플정보통신,
                애플텔레콤, 애플통신, 애플통신모바일, 애플트리, 애플플러스,
                액세스모바일하랄푸드, 액정이랑폰이랑, 앤모바일, 앤스마트,
                앤텔레콤, 앤통신, 앤트신사점, 앤트피디, 앤트혜원점,
                야무진모바일, 야후정보통신, 야후텔레콤, 약목일등통신,
                양가모바일4호점(부개), 양가스토어, 양곡이동통신, 양덕원통신,
                양반텔레콤, 양산서창텔레콤, 양산성지폰, 양산아저씨,
                양산휴대폰성지옆커폰중부점, 양주아이폰수리아이폰브라더스,
                어니언, 어라운드비, 어메이징액정태준모바일, 어썸면목, 억성무역,
                억조정보통신, 언니네아이폰수리점, 언니텔레콤, 언니통신,
                엄지텔레콤, 엄지통신, 엄청텔레콤, 업밸류, 업앤업, 에덴모바일,
                에덴비젼, 에덴통신, 에린통신, 에스디텔레콤.알뜰폰, 에스모바일,
                에스모바일영등점, 에스모바일정발점, 에스비(sb)산업,
                에스비모바일, 에스비정보통신(부천), 에스비통신, 에스씨엘통신,
                에스씨컴퍼니, 에스아이, 에스앤(sn), 에스앤에이치,
                에스에스(SS)모바일, 에스에스모바일, 에스에스모바일(SS모바일),
                에스에이치(S.H)정보통신, 에스에이치모바일,
                에스에이치커뮤니케이션, 에스에이컴퍼니, 에스엔모바일,
                에스엔에스컴퍼니, 에스엠(SM), 에스엠(SM)통신,
                에스엠(SM)통신(부산영도구), 에스엠(SM)통신사, 에스엠디컴퍼니,
                에스엠모바일, 에스엠스토어, 에스엠씨(SMC)정보통신,
                에스엠정보통신, 에스엠컴퍼니, 에스엠통신, 에스와이,
                에스와이통신, 에스원자파, 에스제이(SJ)모바일, 에스제이네트웍스,
                에스제이모바일, 에스제이씨, 에스제이통신, 에스지(SG)텔레콤,
                에스지(SG)프렌즈, 에스지피플면목점, 에스케이모바일,
                에스케이엠비, 에스케이와이텔레콤, 에스케이텔레콤한백경원점,
                에스통신, 에스티제이, 에스티제이광명지점, 에스티제이수지구청점,
                에스티통신, 에스피, 에스피모바일2호점, 에스피모바일3호점,
                에스피커뮤니티, 에어테크, 에이(A)모바일, 에이비, 에이비씨넷,
                에이스, 에이스네트웍스, 에이스네트웍스온천점, 에이스모바일,
                에이스모바일김해점, 에이스텔레콤, 에이스통신, 에이에스통신,
                에이원, 에이치(H)모바일, 에이치HMOBILE, 에이치네트웍스,
                에이치더블유모바일, 에이치모바일, 에이치모바일(H모바일),
                에이치무역, 에이치비(HB)텔레콤, 에이치비텔레콤,
                에이치스토어영남대점, 에이치시스템, 에이치씨텍,
                에이치에스모바일, 에이치에스통신컴퍼니, 에이치에이치씨,
                에이치에이치씨(HHC)묵현2, 에이치엔엘, 에이치엘모바일,
                에이치엠오, 에이치와이모바일, 에이치와이이노베이션,
                에이치와이컴퍼니(무역), 에이치케이티샵, 에이치텔레콤,
                에이치통신, 에이치투오정보통신, 에이케이, 에이투제트컴퍼니,
                에이투젯휴대폰, 에이티에이(ATA)유통, 에이플러스2호점,
                에이플러스원, 에이피, 에코모바일대구점, 에코모바일이동점,
                에코양덕점, 에코티앤엘, 에코페이, 에코폰, 에코폰미아점,
                에코폰아이엔씨, 에코폰종로점, 에코폰중고폰시흥배곧점,
                에코폰천호점, 에프엠정보통신, 에프원(고척), 엑스포정보통신,
                엑스포통신, 엑시트휴대폰, 엔네트웍스, 엔네트웍스신현점,
                엔모바일, 엔비컴퍼니, 엔비텔레콤, 엔스피드, 엔씨정보통신,
                엔씨통신, 엔씨통신마북점, 엔에스, 엔에스모바일, 엔에스범박직영,
                엔에스텔레콤, 엔제이통신, 엔젤OA통신, 엔케이모바일, 엔케이통신,
                엔터컴명성I, 엔터컴씨엘, 엔티모바일, 엘군텔레콤, 엘림,
                엘림스튜디오, 엘림정보통신, 엘비통신, 엘샤다이텔레콤,
                엘샤다이텔레콤구포점, 엘씨모바일, 엘에스티, 엘에이치모바일,
                엘에이치모바일초전점, 엘엠글로벌, 엘제이모바일, 엘지디지탈통신,
                엘케이모바일, 엘케이정보통신, 엘케이컴퍼니, 엘케이텔레콤,
                엘티이(LTE), 엠.T.KMOBILE, 엠넷세종점, 엠디(MD), 엠마모바일,
                엠비션, 엠비에스포스원, 엠빌드온천점, 엠스타쉬, 엠앤에이모바일,
                엠에스모바일, 엠에스모바일수내점, 엠에스정보통신,
                엠에스지제이모바일, 엠에스팜, 엠에스플러스, 엠오엠, 엠인엠,
                엠제이(MJ), 엠제이(MJ)모바일, 엠제이모바일, 엠제이스토어,
                엠제이통신, 엠지모바일, 엠케이, 엠케이그룹, 엠케이네트웍스,
                엠케이네트웍스김해삼정점, 엠케이모바일, 엠케이스타,
                엠케이아이엔씨, 엠케이텔레콤, 엠케이통신, 엠텔레콤,
                엠텔레콤증산1호점, 엠통신(신성점), 엠통신전민동점, 엠트랜스퍼,
                여기로, 여능통신, 여로, 여름, 여보세요, 여수, 여우통신,
                여주통신, 역곡17호점, 역말이동통신, 연결통신, 연두프라자,
                연모바일, 연세모바일, 연아모바일, 연우모바일성서, 연우통신,
                연일텔레콤, 연지IMT, 연텔레콤, 연통신, 연필정보통신,
                연합정보통신, 연합통신, 열린정보통신, 열린정보통신(2호점),
                영광대리점, 영구통신, 영남정보통신, 영남통신, 영등포, 영미전자,
                영보통신, 영보통신부평, 영사운드, 영석정보통신, 영스피드,
                영신텔레콤, 영심텔레콤, 영앤리치모바일, 영우정보통신, 영우통신,
                영웅텔레콤, 영원oa통신, 영원이동통신, 영원텔레콤, 영원통신,
                영자텔레콤, 영종국제통신, 영천아시아폰마트, 영텔레콤, 영통신,
                옆커폰사천점, 옆커폰성지울산전하점, 옆커폰통영점, 옆커폰화곡,
                예린통신, 예림통신, 예림통신_대구7980, 예쁜중고폰, 예쁜폰아트,
                예사랑, 예스, 예스메이저, 예스모바일, 예스모바일사수점,
                예스정보통신, 예스컴퍼니경남지점, 예스컴퍼니김해지점, 예스통신,
                예쓰통신, 예안텔레콤, 예울투, 예원준텔레콤, 예원텔레콤,
                예은정보통신, 예인모바일, 예일정보통신, 예진텔레콤,
                오가다,폰가게, 오거리텔레콤, 오거리통신, 오늘의스팟, 오대양통신,
                오동통신, 오라컴퍼니, 오랜知, 오랜지텔레콤, 오렌지모바일,
                오렌지에프엔씨, 오렌지컴퓨터병점본점, 오렌지텔레콤,
                오렌지텔레콤(신창), 오롯이모바일, 오룡역3번출구빽스텔레콤,
                오류정보통신, 오르나핸드폰, 오메가정보통신, 오복통신,
                오빠네휴대폰가게, 오빠텔레콤2, 오빠통신, 오산버스폰궐동점,
                오산아프리카폰, 오산중고폰, 오산프로도, 오상통신, 오색시장폰,
                오성텔레콤, 오션텔레콤(서울강남), 오송통신, 오수정보통신,
                오알지일동점, 오오오(555)TELECOM, 오웨이통신, 오이통신,
                오조호조, 오칠, 오케이(OK)통신, 오케이모바일, 오케이샵,
                오케이에스케이맨2, 오케이정보통신, 오케이중고폰강서구점,
                오케이중고폰원당점, 오케이통신, 오케이통신혁신점, 오투모바일,
                오픈모바일, 오학통신, 옥구통신, 온누리통신, 온세계통신,
                온천텔레콤, 온컴퍼니, 올뉴모바일부개점, 올레마켓, 올림픽텔레콤,
                올모바일, 올빛통신, 올폰컴퍼니, 올헬스, 와룡, 와룡원주2지점,
                와룡원주3지점, 와룡컴퍼니강대점, 와룡컴퍼니거두점,
                와룡컴퍼니신장대리점, 와룡컴퍼니팔호광장점, 와룡컴퍼니홍천점,
                와룡컴퍼니화천점, 와싸, 와우테크, 와우통신, 와이모바일,
                와이모바일5호점, 와이비컴퍼니상대점, 와이에스, 와이에스모바일,
                와이에이부평점, 와이엠통신, 와이제이(YJ)모바일, 와이제이모바일,
                와이제이컴퍼니, 와이쥐모바일논공점, 와이쥐모바일창녕점, 와이즈,
                와이즈모바일, 와이컴퍼니, 와이통신, 완도컴퓨터, 완도통신,
                왕건성지, 왕대박, 왕대박텔레콤, 왕산정보통신, 왕창텔레콤,
                외국인마트, 외대통신, 외도휴대폰할인매장, 요한텔레콤,
                용마정보통신, 용인텔레콤, 용인휴대폰성지픽업폰, 용텔레콤,
                우광통신, 우기통신, 우들통신, 우디모바일, 우리3통신, 우리대리점,
                우리동네1등매장군포역점, 우리동네1등매장당동점,
                우리동네1등매장산본점, 우리동네1등매장인계점, 우리동네도매폰,
                우리동네텔레콤, 우리동네폰마트, 우리모바일, 우리모바일검단점,
                우리모바일도림점, 우리모바일병방점, 우리모바일임학점,
                우리아빠핸드폰, 우리이노베이션, 우리정보, 우리정보통신,
                우리텔레콤, 우리통신, 우리형스마트폰, 우림정보통신, 우림텔레콤,
                우림통신, 우성텔레컴, 우송이동통신, 우수통신, 우신텔레콤,
                우씨통신, 우아미컴퍼니, 우야통신, 우연통신, 우원모바일, 우윈스,
                우유통신, 우의통신, 우정텔레콤, 우정통신, 우주정보통신,
                우주텔레콤, 우주통신, 우주통신롯데마트점, 우주폰통신, 우진,
                우진정보통신, 우진통신, 우텔레콤, 운남텔레콤, 운성상사,
                운정아저씨, 울란바타르2, 울산선불폰메가폰강남교회점,
                울산월드마트SHALIMAR, 울산텔레콤, 울산폰마트,
                울산휴대폰성지강동특판, 울산휴대폰성지동구특판,
                울산휴대폰성지송정특판, 울산휴대폰성지울산특판, 웃딩통신마트,
                웅모바일, 웅비통신, 웅이정보통신, 웅이텔레콤, 웅이통신,
                웅쭈노리퍼블릭, 웅텔레콤, 워터웨이브, 원(One)텔레콤,
                원광이동통신, 원다리텔레콤, 원모바일, 원스타모바일, 원스텝,
                원정보통신, 원주텔레콤, 원주휴대폰성지, 원카무역, 원큐통신,
                원탑, 원탑모바일, 원탑통신, 원탑프로, 원텔레콤, 원텔정보통신,
                원통신, 원투모바일, 원플러스원, 월드, 월드MOBILEMART,
                월드모바일, 월드스타텔레콤, 월드아시아마켓, 월드전자랜드,
                월드정보통신, 월드텔레콤, 월드통신, 월드폰, 월드폰2, 월드폰3,
                월드폰마트, 월드폰아울렛, 월드푸드, 월드푸드마트통신,
                웨스텔레콤, 웨이다, 웨일모바일, 웨일모바일오남점, 웰메이드,
                웰빙, 웹컴통신, 위너, 위너모바일, 위너스텔레콤, 위너스통신,
                위너텔레콤, 위너통신, 위드모바일, 위즈모바일, 위즈엠통신,
                위코리아, 윈모바일, 윈스토어, 윈윈커뮤니티, 윈텔레콤, 유광통신,
                유나통신, 유노텔레콤, 유니모바일, 유니스우정일지점,
                유니온모바일, 유니온정보통신, 유니온텔레콤, 유니온통신, 유니원,
                유니콘모바일고덕점, 유니콘모바일합정점, 유니콘통신, 유니통신,
                유락통신, 유로통신, 유리정보, 유림통신, 유명전자,
                유민이네텔레콤, 유성정보통신, 유성텔레콤, 유성통신,
                유시티부동산공인중개사사무소, 유신사, 유신정보통신가좌2점,
                유안통신, 유어컴, 유에스모바일, 유에이블모바일, 유엔플러스,
                유이텔레콤, 유이통신, 유일이동통신, 유정텔레콤, 유주모바일,
                유진위너, 유진정보통신, 유진텔레콤, 유진통신, 유케이씨컴퍼니,
                유코아달성화원점, 유코아팔달, 유텔레콤, 유토피아정보통신,
                유한회사에스엠통신, 유한회사카인드, 유한회사플러스통신,
                유행통신, 유현통신, 육일통신, 윤모바일, 윤샵, 윤성, 윤쓰텔레콤,
                윤이나텔레콤, 윤텔레콤, 윤현, 율모바일, 율텔레콤, 율통신,
                으뜸모바일, 으뜸정보통신, 으뜸통신, 은성정보통신, 은성통신,
                은아정보통신, 은진텔레콤, 은컴퍼니, 은텔레콤, 은하통신,
                은현텔레콤, 은혜정보통신, 음파통신(휴대폰싸게파는집), 응답하라,
                응안응안모바일, 의정부고릴라폰, 의정부휴대폰성지,
                의정부휴대폰성지옆커폰민락점, 의형제, 이EA코리아, 이군모바일,
                이글스AURORA, 이김텔레콤, 이노모바일, 이노텔레콤, 이노통신,
                이니코(INICO), 이다글로벌송산점, 이담통신, 이동통신프라자,
                이든통신, 이랑통신, 이레텔레콤, 이레통상, 이레행복통신,
                이련통신, 이루다모바일, 이루다컴퍼니, 이루다태유,
                이루다태유-세교점, 이루다태유오색시장점, 이루다통신,
                이루다홀딩스(원동폰, 이루다홀딩스-지점서정폰, 이룸,
                이룸네트웍스분성로점, 이룸정보통신, 이룸텔레콤, 이룸통신,
                이벨무역, 이비모바일, 이쁜핸드폰가게, 이삭컴퍼니,
                이삭토스트텔레콤, 이수네트워크, 이수통신, 이슬통신,
                이시스헬프ONE, 이씨통신, 이안모바일, 이안정보통신, 이안통신,
                이앤박, 이앤에프가경점, 이에스텔레콤7, 이에스통신,
                이에이모바일EAMOBILE, 이엔(EN)컴퍼니, 이엔(EN)통신, 이엘코리아,
                이엠홀딩스, 이오모바일, 이오통신, 이원텔레콤, 이윤통신, 이음새,
                이일정보통신, 이제이모바일안양점, 이조방텔레콤, 이조통신,
                이지컴퍼니, 이지코퍼레이션, 이지텔레콤, 이지폰, 이천본가,
                이천시통신, 이천테크노본가, 이천휴대폰성지픽업폰,
                이츠텔답십리점, 이테르, 이테아하드인터내셔날, 이투통신,
                이펑휴대폰가게, 이혜리핸드폰, 이화정보통신, 이환텔레콤,
                인기텔레콤, 인디, 인베스트, 인사랑, 인사랑시흥점, 인생네폰,
                인생모바일, 인스토어, 인아정보통신, 인천논현중고폰매입소녀폰,
                인천비승십정, 인천큰마을, 인탭(INTAB), 인탭(INTAB)동대문지점,
                인피니티텔레콤, 인하텔레콤, 일등모바일, 일등텔레콤, 일등통신,
                일등통신가야, 일등통신초전, 일루TC, 일루텔레콤, 일류통신,
                일모바일, 일산이앤티, 일신통신, 일우, 일이삼정보통신, 일이통신,
                일촌통신, 일풍국제, 임란모바일, 임별통신, 임실이동통신,
                임실정보통신, 임실휴대폰할인매장, 잇다컴퍼니, 잇츠폰통신, 잎슬,
                자매폰, 자몰마켓, 자바상대통신, 자유, 자유통신, 자이텔레콤,
                자이폰마트, 잘판다, 잘풀리는집, 잡스폰마트, 장가모바일,
                장군텔레콤, 장모바일, 장미텔레콤, 장성통신, 장승정보통신,
                장유텔리콤, 장은영, 장흥통신, 재기통신, 재스민트레드, 재우통신,
                재인통신, 재일통신, 재홍텔레콤(더블류점), 전국텔레콤,
                전기통신LED, 전샵, 전주알뜰모바일, 전하폰성지, 점보텔레콤,
                정림밀레니엄이동통신, 정명모바일, 정모바일, 정민모바일,
                정보통신마을, 정보통신피앤에스, 정상텔레컴, 정연텔레콤, 정우,
                정우텔레콤, 정우통신, 정원텔레콤, 정이동통신, 정인네트웍스,
                정있는모바일, 정있는모바일석남점, 정직한모바일, 정직한스토리,
                정직한통신, 정직한폰가게, 정직한휴대폰, 정진모바일, 정텔레콤,
                정통신, 정후디지털, 제니스, 제로, 제로모바일, 제로텔레콤,
                제로통신, 제미니그룹, 제은통신, 제이(J)라스트모바일,
                제이네트웍스, 제이니모바일, 제이대리점, 제이디모바일, 제이디엠,
                제이디텔레콤안중점, 제이디휴대폰아울렛, 제이모바일,
                제이비모바일, 제이샵통신, 제이씨통신, 제이아띠, 제이알컴퍼니,
                제이알통신, 제이앤제이, 제이앤제이지지, 제이에스,
                제이에스모바일, 제이에스정보통신, 제이에스코퍼레이션,
                제이에스텔레콤, 제이에스통신, 제이에이치모바일,
                제이에이치컴퍼니, 제이에이치통신, 제이엔5호점, 제이엔비모바일,
                제이엔에스텔레콤, 제이엘정보통신, 제이엠, 제이엠(JM)통신,
                제이엠넷(JMNET), 제이엠모바일, 제이엠컴퍼니, 제이엠통신,
                제이와이(JY)텔레콤, 제이와이컴퍼니, 제이와이텔레콤, 제이와이폰,
                제이원통신, 제이윈통신, 제이유니버스, 제이유통신, 제이이동통신,
                제이정보통신, 제이제이, 제이제이(JJ)중고폰, 제이제이(JJ)컴퍼니,
                제이제이네트웍스, 제이제이모바일, 제이제이모바일(신화),
                제이제이모바일광교점, 제이제이모바일동탄, 제이제이모바일병점점,
                제이제이스모바일, 제이제이컴퍼니, 제이제이컴퍼니다사,
                제이제이텔레콤, 제이제이폰, 제이컴퍼니, 제이케이머천트글로벌,
                제이케이컴퍼니, 제이케이통신, 제이텔레콤, 제이텔레콤영통점,
                제이텔정보, 제이통신, 제이통신익산점, 제이투모바일,
                제이투케이정보통신, 제이폰샵(뉴텔레콤), 제이플러스,
                제이피모바일, 제일모바일, 제일정보통신, 제일텔레콤, 제일통신,
                제천공일공, 제천휴대폰성지, 제트모바일, 제희텔레콤, 조모바일,
                조아텔레콤, 조은, 조은정보통신, 조은텔레콤, 조은통신,
                조은휴대폰, 조이모바일, 조이텔레콤, 조프(JOF.), 종로프라자,
                종암이동통신, 종합텔레콤, 좋은생각텔레콤,
                좋은생각텔레콤특판본점, 좋은예감, 좋은정보, 죠이통신, 주)석영,
                주)스마트419, 주니통신, 주덕텔레콤, 주명정보통신, 주모바일,
                주민텔레콤, 주씨모바일, 주얼리텔레콤, ㈜에스피모바일,
                ㈜엠씨씨네트월드, 주연모바일(만수), 주연정보(인천/만수),
                주연정보통신, 주연정보통신논현점, 주연통신, 주영텔레콤,
                주왕모바일, 주울모바일, 주원이동통신, 주원통신, 주은텔레콤,
                ㈜태우통신, 주현모바일, 주호텔레콤, 죽전모바일, 준닷컴,
                준모바일, 준이동통신, 준정보통신, 준케이모바일, 준텔레콤,
                준텔레콤정산점, 준통신, 중계텔레콤, 중고폰1번가,
                중고폰1번가삼례점, 중고폰가게투앤디, 중고폰매입판매센터슈가폰,
                중고폰매입판매소녀폰김포사우점, 중고폰매입판매소녀폰동대구역점,
                중고폰매입판매소녀폰분당서현, 중고폰매입판매소녀폰상점,
                중고폰매입판매소녀폰수성범어점, 중고폰매입판매소녀폰포항점,
                중고폰매입판매에코폰, 중고폰매입판매에코폰동,
                중고폰매입판매에코폰인천구월, 중고폰매입판매에코폰일산화,
                중고폰몬스터, 중고폰뱅크수원점, 중고폰뱅크오산점,
                중고폰선불폰알뜰폰유안텔, 중고폰세상주안점, 중고폰알뜰샵,
                중고폰에코폰강서등촌역점, 중고폰에코폰광주점,
                중고폰에코폰대구광장코아점, 중고폰에코폰부산대점,
                중고폰에코폰일산점, 중고폰에코폰제주점, 중고폰을부탁해,
                중고폰인생, 중고폰창고, 중국백화연쇄점, 중국식품상점,
                중부비즈네트웍스, 중앙로모바일(풍기점), 중앙로정보통신한빛점,
                중앙이동통신, 중앙정보통신, 중앙텔레콤, 중앙통신, 중원텔레콤,
                중파텔레콤, 쥐에프모바일, 쥬네브프라자, 즐겨찾기, 즐펀텔레콤,
                지교, 지교대호, 지교사당점, 지교정릉점, 지구별, 지노텔레콤,
                지니모바일, 지니모바일(동오텔레콤), 지니텔레콤기흥구청점,
                지니통신, 지니폰앤케이스, 지니폰케이스, 지마트몰, 지명,
                지비모바일, 지상통신, 지성정보통신, 지성통신, 지아모바일,
                지앤비4, 지에스엠, 지오모바일, 지오텔레샵, 지오텔레콤,
                지우모바일(땅폰영도점), 지우통신, 지운통신, 지원모바일,
                지유플러스, 지은이동통신, 지은정보통신, 지은통신, 지인텔레콤,
                지중해텔레콤, 지케에스, 지텔레콤옥계2호점, 지티, 지티모바일,
                지티통신, 지한통신, 지혜정보2, 지호정보통신, 지호통신,
                진동TOP통신, 진명스마트폰, 진명텔레콤, 진사랑통신, 진성,
                진솔텔레콤, 진실텔레콤, 진심모바일, 진아통신라온점,
                진양텔레콤(발안점), 진영일등통신, 진영정보통신, 진영텔레콤,
                진영현이통신, 진우통신, 진이통신, 진정보통신, 진주1등텔레콤,
                진주중고폰사고팔고, 진주중고품매입폰사닷컴진주점, 진주통신,
                진천폰월드, 진천화랑휴대폰, 진텔레콤, 진텔레콤(고척점), 진통신,
                집앞휴대폰, 짱구모바일, 짱구텔레콤, 짱구통신, 짱구폰, 짱모바일,
                짱텔레콤, 짱통신, 쭈텔레콤, 쭌텔레콤, 쭌텔레콤도화점,
                쭌텔레콤프로점, 차세대통신, 차이나통신, 착한가게,
                착한총각네폰가게, 착한텔레콤, 착한통신, 착한통신향남점, 착한폰,
                착한폰마트, 착한핸드폰가게, 착한휴대폰, 착한휴대폰샵,
                착한휴대폰특판, 찬비텔레콤, 찬성통신, 찰s텔레콤, 참빛통신,
                참조은모바일, 참텔레콤, 참통신, 참통신1호점, 참통신2호점,
                창고형핸드폰수원아주대점, 창고형핸드폰최고통신용인수지점,
                창녕아시아마트, 창대통신, 창신텔레콤, 창업과투자,
                창원중고폰폰사요몰, 창원휴대폰성지DT, 창천항로옥동점, 찾폰샵,
                채봉통신, 채연시원텔금곡점, 채연시원텔반여점, 채연시원텔본점,
                채연시원텔토곡점, 채영, 채영정보통신, 채정텔레콤, 챌린저모바일,
                챔피언모바일, 챔피언텔레콤, 챔피언텔레콤(구포점),
                챔피언텔레콤(김해점), 천보통신강서점, 천안모바일,
                천안휴대폰성지영모바일성정점, 천일정보통신, 천일텔레콤,
                천지종합이동통신, 천지통신, 천하모바일, 천하텔레콤, 천하통신,
                첨단이동통신, 청구top, 청군통신하성점, 청년K휴대폰, 청년모바일,
                청년통신, 청년폰, 청년휴대폰, 청담통신, 청룡텔레콤, 청심네트웍,
                청아, 청주텔레콤, 청주폰, 청춘대길, 청춘대길삼양점,
                청춘대길지축지점, 청춘모바일, 청춘모바일(일산), 청춘텔레콤,
                청춘통신, 청하정보통신, 체라정보통신, 체인지텔레콤, 체화모바일,
                첼시통신, 초록정보통신, 초목통신, 초이스커뮤니티, 초코모바일,
                총각네텔레콤, 총각네폰가게, 총각네폰가게문덕, 총각네휴대폰가게,
                총각네휴대폰가게후곡점, 총각통신, 최강모바일, 최강케이스,
                최강텔레콤, 최강통신, 최고네트웍스, 최고도화점, 최고통신,
                최신정보통신, 쵸파통신, 축복모바일, 춘천아이폰수리아이케어샵,
                춘향이휴대폰, 충남전자, 충성대림점, 충주휴대폰성지,
                충청종합유통(통신), 친구통신, 친절텔레콤, 친절한모바일,
                친절한컴퍼니, 친절한텔레콤본점, 친절한텔레콤악세사리,
                친절한폰가게, 칠곡아이폰수리, 칠구텔레콤, 칠삼통신,
                칠칠칠휴대폰직산점, 침산통신, 카이(KAI)모바일,
                카토대구경북지사.준통신, 카페통신, 칼라통신, 캄보디아모바일,
                캄보디아종합서비스, 캄보디아통신, 캄코텍, 캠핑통신, 캡틴,
                캡틴모바일, 컬러모바일, 컴사요, 컴앤폰, 컴앤휴, 컴포니통신,
                컴퓨터&소모품이야기, 케빈모바일, 케이(K)모바일, 케이모바일,
                케이모바일(목동사거리점), 케이모바일(부천역점),
                케이모바일(현대점), 케이모바일부천자유시장점, 케이모바일원당점,
                케이모바일원종점, 케이브이엔, 케이비, 케이비모바일,
                케이비에이코리아, 케이비통신, 케이샤모바일, 케이스타옥포점,
                케이씨텔레콤, 케이씨통신, 케이알(KR)모바일2호점,
                케이알(KR)모바일4호점, 케이알(KR)모바일8호점, 케이알모바일3호점,
                케이알모바일5호점, 케이알모바일6호점, 케이알모바일7호점,
                케이알모바일본점, 케이에스모바일, 케이에스스토어, 케이에스시흥,
                케이에스엠모바일, 케이에스텔레콤, 케이에스텔레콤2호점,
                케이에이씨, 케이엘모바일, 케이엠모바일, 케이엠컴퍼니,
                케이엠텔레콤, 케이원네트웍스, 케이원정보통신, 케이원텔,
                케이원텔10호점, 케이원텔22호점, 케이원텔24호점, 케이원텔5호점,
                케이원텔8호점, 케이정보통신, 케이제이, 케이제이모바일,
                케이제이통신, 케이케이엠유한회사, 케이투(K2)정보통신,
                케이투(K2)통신, 케이투모바일, 케이티(KT)한백텔레콤,
                케이티정보통신, 켈리즈(KELIZ), 코레모바일, 코리아모바일,
                코리아텔레콤, 코리아통신, 코아루통신, 코아텔레콤, 코코로모바일,
                코코통신, 콩콩굿몰, 쾌남정보통신, 쿠모바일, 쿠키모바일,
                쿠키정보(남문직영점), 쿠팡모바일, 쿨정보통신, 퀸모바일, 큐브,
                큐큐아시아, 큐큐통신, 큐큐트레이드, 큐텔레콤, 큐통신,
                큐피드텔레콤, 크로스, 크리스텔은행동점, 크리스텔통신중앙점,
                크리에이티브네트웍스, 큰말통신, 큰별통신, 큰세계통신, 큰소리,
                큰소리통신구암점, 큰텔레콤, 큰통신, 클래식모바일, 클릭,
                클릭모바일, 클릭이동통신, 클릭폰, 키다리삼촌휴대폰가게,
                키움텔레콤, 키움통신, 키위모바일, 킴스모바일, 킹모바일,
                킹모바일2, 킹스모바일, 킹콩폰, 킹텔, 킹텔레콤, 타이거,
                타이거모바일, 타이틀케이스, 타임텔레콤, 탄탄대로폰마켓, 탄현폰,
                탄후엔Mobile, 탐탐(TAMTAM), 탑(TOP)통신, 탑모바일, 탑정보통신,
                탑컴퍼니, 탑텔레콤, 탑통신, 탕정모바일, 탕정정보통신,
                태광정보통신, 태권V모바일, 태극통신, 태디TEDY, 태리텔레콤,
                태마2, 태민정보통신, 태산네트웍스, 태산네트웍스가오점,
                태산네트웍스동남점, 태산네트웍스모충점, 태산네트웍스사직점,
                태산네트웍스사천점, 태산네트웍스운천점, 태산네트웍스이마트점,
                태산네트웍스주중점, 태산네트웍스천안점, 태산네트웍스청전점,
                태산정보, 태성여행사, 태승모바일본점, 태승모바일신천점,
                태승모바일초지점, 태양모바일, 태양정보통신, 태양텔레콤,
                태양통신, 태연, 태영정보통신, 태영텔레콤, 태영통신, 태왕통신,
                태원, 태인텔레콤, 태태통신, 태한통신, 태현정보통신, 태형정보,
                태화텔레콤, 태흥모바일, 태희통신, 탱통신, 테두리통신, 테라월드,
                테라통신, 테마모바일, 테마신흥점, 테마이동통신(인천/가좌동),
                테마정보통신, 테마학익, 테크노정보통신, 테크노통신, 테크타운,
                텔레붐(Teleboom), 토당동휴대폰매장능곡점, 토리모바일, 토리통신,
                토리폰, 토마토모바일, 토스폰, 토탈통신, 통신공화국, 통신나라,
                통신매니아, 통신본가, 통신일번지, 통신쟁이, 통신천국,
                통영아이폰수리애플픽스, 통일텔레콤, 통일핸드폰, 통큰휴대폰,
                통통텔레콤, 통통텔레콤방어진점, 투게더, 투게더통신, 투노,
                투데이모바일, 투민, 투어텔레콤, 투에이치, 투에이치모바일, 투엘,
                투헝통신, 튀블리, 트리플에스, 트윈통신, 티디씨(TDC)환전,
                티비모바일, 티샵(T-SHOP), 티앤에스마케팅신내, 티앤티,
                티앤티정보통신, 티에스(TS)통신백화점, 티에이치모바일,
                티에이치텔레콤2호점, 티엔샵, 티엔에스텔레콤,
                티엘에스(TLS)텔레콤, 티엠텔레콤, 티월드꽃동산점,
                티유모바일장기점, 티정보, 티제이(TJ)텔레콤(호계점), 티지모바일,
                티케이씨모바일, 티텔레콤휴천점, 티통신, 티티모바일, 티티텔레콤,
                티플러스, 파라다이스텔레콤, 파라통신계산, 파란(PARAN),
                파란모바일, 파란모바일도화점, 파란텔레콤, 파랑텔레콤(송내점),
                파랑통신, 파발마정보통신, 파워스테이션, 파워엘정보통신,
                파워텔레컴, 파워통신, 파워할인유통, 파워해미, 파인모바일,
                파인애플통신, 파주중고폰폰당포파주점, 판다모바일, 판다텔레콤,
                판다텔레콤,위너피플, 판다통신, 판다통신고려대점, 판다폰수미통신,
                판매왕, 판타스틱통신, 팔팔모바일, 팔평통신, 팔평통신효목점,
                팝콘정보통신, 팡팡모바일, 팡팡텔레콤, 팡팡통신, 패밀리데이,
                패밀리모바일, 패밀리서창, 패밀리정보통신, 패밀리텔레콤,
                패밀리통신, 패밀리통신까치산점, 패밀리통신화곡점,
                퍼스트네트웍스, 퍼스트모바일, 퍼스트모바일강화점, 퍼스트상사,
                퍼스트상사광희점, 퍼스트텔레콤2호점, 퍼스트통신,
                퍼스트통신초곡점, 퍼펙트모바일, 펀모바일, 펀통신,
                페리도트&휴대폰면세점, 펜타씨에이성남단대점,
                펜타씨에이은평구청점, 펠릭스, 펭귄폰본점, 평택모바일,
                평택중고에코폰, 평택중고폰폰당포평택점, 평택휴대폰백억,
                평화커뮤니케이션, 평화통신, 포도, 포맨텔레콤, 포스,
                포에이스컴퓨터, 포유모바일, 포유컴퍼니, 포유텔레콤, 포인트샵,
                포인트정보통신, 포항중고폰폰당포포항점, 폰&서비스, 폰&컴,
                폰&케이스, 폰&폰, 폰(Phone)+82, 폰119, 폰119본점, 폰가게,
                폰값껌값, 폰공짜세상, 폰나라, 폰나라이동통신, 폰다둥이네,
                폰다방, 폰닥터, 폰달인, 폰마루통신, 폰마켓, 폰마켓동탄점,
                폰마트, 폰마트(J.H컴퍼니), 폰마트NO.1, 폰마트도화, 폰마트본점,
                폰마트양동점, 폰마트통신, 폰매니아, 폰매직, 폰매직신포점,
                폰맥스, 폰메이드, 폰메이드4호점, 폰모아, 폰무원, 폰박텔레콤,
                폰뱅크, 폰뱅크후포점, 폰부스, 폰부스직영점, 폰사, 폰사닷컴,
                폰사랑, 폰사랑김도령, 폰사랑수리센터, 폰사랑이동통신, 폰사무소,
                폰사무소배방점, 폰사요몰(수원인계점), 폰사요몰울산성남점,
                폰사요몰중고폰광주점, 폰사요몰중고폰대전점, 폰사자,
                폰상점(댜니모바일), 폰생폰사, 폰샵, 폰샵(Phone#), 폰샵영주점,
                폰세상, 폰쇼핑, 폰슐랭&설탕텔레콤부산연제점, 폰슐랭N설탕텔레콤,
                폰스타, 폰스타2호점, 폰스타산본점, 폰스타평촌점, 폰스토리,
                폰스토어, 폰스홀릭, 폰시유범어점, 폰싼집2, 폰싼집5, 폰싼집6,
                폰아울렛, 폰아울렛본점, 폰아저씨, 폰아찌, 폰앤드림, 폰앤아트,
                폰앤커피, 폰앤컴, 폰앤톡, 폰앤톡라페도매폰, 폰앤톡운정점,
                폰앤폰, 폰에스, 폰요기요, 폰월드, 폰이로다, 폰이야기, 폰이팡,
                폰이팡중고폰선불폰수원시청역점, 폰이팡중고폰선불폰일산화정점,
                폰이팡중고폰선불폰청량리, 폰이팡중고폰알뜰폰군포점, 폰인2호점,
                폰즈포유텔레콤, 폰지유안성점, 폰친구, 폰카페,
                폰카페(엠제이통신), 폰케이스신드롬, 폰코리아, 폰탑,
                폰탑(신다대점), 폰탑(신평점), 폰탑(영도점), 폰탑(장림점),
                폰탑경주점, 폰탑진영점, 폰택김해어방점, 폰택김해율하점,
                폰택김해진영점, 폰톡, 폰통령, 폰통신, 폰파는녀석들,
                폰파는진주아저씨, 폰파는총각, 폰판다, 폰팔이김봉준,
                폰팔이김봉준(3호점), 폰팔이김봉준(진평점), 폰팜, 폰팡팡공짜팡팡,
                폰포유2(phone4u2), 폰폰폰, 폰프라자, 폰프라자(역전논스톱텔레콤),
                폰프라자5호점, 폰프라자7호점, 폰프라자8, 폰프라자P2, 폰프라자P3,
                폰프라자S, 폰프라자X, 폰프라자봉곡점, 폰프렌즈, 폰플러스,
                폰플러스(신흥점), 폰플러스1호, 폰필모바일, 폰하우스,
                폰하우스당진점, 폰할인마트(신암점), 폰할인마트2, 폰할인마트3,
                폰할인마트4, 폰할인연구소, 폴스타통신, 폼텔레콤, 푸른나라,
                푸른정보통신, 풀통신, 품크메르모바일, 풍무통신, 프라임모바일,
                프라임정보통신, 프라임통신, 프라임호평점, 프랜드모바일, 프랜즈,
                프렌들리모바일, 프렌즈모바일, 프렌즈텔레콤, 프렌즈통신,
                프로모바일화곡점, 프로통신, 프로통신전농, 프론티어홀딩스,
                프리넷, 프리마텔레콤, 프리모바일, 프리통신, 프리패어드,
                프리페이폰, 프린스텔레콤, 플러스모바일, 플러스모바일(양천),
                플러스텔레콤, 플러스통신, 플레이모바일, 플렉스모바일,
                피(P)텔레콤, 피시사구팔구닷컴, 피씨가이, 피아이텔레콤, 피앤피,
                피앤피모바일, 피앤피텔레콤, 피에스모바일, 피에스텔레콤,
                피엔모바일, 피엔씨정보통신, 피엠씨, 피엠에스텔레콤, 피치모바일,
                피카소, 피케이모바일, 피크통신, 픽스굿, 픽스마일, 픽스모바일,
                픽스퀵, 필모바일, 필이동통신, 필정보통신, 필텔레콤, 핏불텔레콤,
                핑크텔레콤, 하나mobile, 하나로네이트, 하나로네트웍스태화점,
                하나로블루이동통신, 하나로양정, 하나로정보통신, 하나로텔레콤,
                하나로통신, 하나로플러스, 하나모바일, 하나미치, 하나이동통신,
                하나정보, 하나정보통신, 하나텔레콤, 하나통신,
                하남중고폰에코폰미사점, 하냥모바일, 하녀텔레콤, 하늘모바일,
                하늘정보, 하늘정보통신, 하늘텔레콤, 하늘통신, 하니모바일500,
                하니텔레콤, 하람, 하랑골목통신, 하랑모바일, 하랑에이스모바일,
                하랑조아모바일, 하랑텔레콤, 하랑플러스, 하랑해드림텔레콤,
                하루모바일, 하루통신, 하모니, 하모바일, 하빈통신, 하소나모바일,
                하얀세상, 하연, 하영통신, 하우텔레콤, 하원모바일, 하은모바일,
                하음통신, 하이엔드스토어, 하이정보통신, 하이텔레콤, 하이통신,
                하이퍼아이앤씨, 하이포레스트, 하트코리아, 하트텔레콤,
                하하모바일, 하하통신, 학하통신, 한mobile, 한강, 한결컴퍼니,
                한결텔레콤, 한결통신, 한국정보통신, 한국통신비연구소, 한길정보,
                한길텔레콤, 한길통신, 한누리통신, 한라인통신, 한라통신,
                한마음네트웍스, 한마음아이앤씨, 한마음정보통신, 한마음컴퍼니,
                한마정보통신, 한미통신, 한바로통신, 한밭정보통신, 한배모바일,
                한백이동통신, 한백이동통신보령점, 한백이동통신완주점,
                한백텔레콤, 한별텔레콤, 한별통신, 한빛, 한빛모바일,
                한빛정보통신, 한빛종합통신, 한빛통신, 한사랑텔레콤,
                한사랑텔레콤(SK), 한성텔레콤, 한성통신, 한손스마트,
                한손스마트5호포승점, 한솔통신(반석점), 한스컴퍼니,
                한신기전통신별내점, 한씨네텔레콤, 한양, 한얼정보통신,
                한영텔레콤, 한우물통신, 한울텔레콤다대점, 한울텔레콤용원점,
                한음정보통신, 한일텔레콤, 한진텔레콤, 한창, 한창텔레콤,
                한컴정보, 한희통신, 한희통신마천점, 할랄마트, 함양공짜나라,
                합덕통신, 핫통신, 해나루통신, 해남, 해동정보통신, 해랑모바일,
                해리상회, 해맞이통신, 해모로, 해미핸드폰, 해밀정보통신,
                해밀통신, 해바라기통신(가고파), 해성기업, 해수텔레콤, 해양마트,
                해오름통신, 해주통신, 해태통신, 해피모바일, 해피밸리통신,
                해피텔레콤, 해피통신, 해피하우스, 핸드폰가게EJ, 핸드폰강선생,
                핸드폰나라, 핸드폰마켓, 핸드폰마트, 핸드폰아울렛동아모바일8호점,
                핸드폰전문매장, 핸즈모바일, 핸폰즈, 핸프모바일중랑점,
                행복모바일, 행복이동통신, 행복정보통신, 행복텔레콤, 행복통신,
                행복통신2, 행복한남매통신, 행복휴대폰마트, 행운, 행운모바일,
                행운정보통신, 행운텔레콤, 행운통신, 향기텔레콤, 향남버스폰,
                향통신, 허니모바일, 허니비모바일, 허니통신, 허브정보통신,
                허브텔레콤, 허브통신, 헌주통신, 헬로벨, 헬로벨계양지사,
                헬로벨디에치통신, 헬로우지에스엠, 헬리오통신, 혁신모바일,
                혁신토탈샵, 현경텔레콤, 현대IMT, 현대모바일, 현대정보, 현대통신,
                현동모바일, 현빈텔레콤, 현성, 현엘티이(LTE)죽전점, 현우정보통신,
                현이네폰가게, 현이통신, 현정보통신, 현주텔레콤,
                현지모바일하늘점, 현컴퍼니, 현텔레콤, 형제2, 형제모바일,
                형제모바일3매장, 형제모바일4, 형제텔레콤, 형제통신,
                형제티브이사, 형제폰가게, 형진통신, 혜람통신, 혜성모바일,
                혜성인터네셔널, 혜성정보통신, 혜자텔레콤, 혜천이동통신, 혜통신,
                호경플러스, 호경플러스2정보통신, 호구통신, 호금통신, 호두모바일,
                호매실꽁폰, 호야네폰가게, 호야정보, 호야통신, 호우,
                호원에이치모바일, 호키포키, 호평모바일팩토리, 호현텔레콤,
                홈모바일플러스, 홍규통신, 홍대모바일, 홍모바일, 홍익,
                홍익정보통신본점, 홍천폰마트, 화곡직영점, 화니모바일, 화성반송,
                화성반월, 화성본가, 화성텔샵, 화성통신, 화이트(퇴촌),
                화이트텔레콤, 화이트통신, 화평정보통신, 화평텔레콤, 환,
                환희이동통신, 황금돼지통신, 황금텔레콤, 황금텔레콤남문점,
                황금통신, 황금폰, 황대장통신, 황모바일, 황민글로벌, 황산Telecom,
                황소폰, 황제모바일, 황제텔레콤, 황제통신, 회룡통신, 효원텔레콤,
                효자통신, 효정정보통신, 효진통신, 후니모바일, 후니텔레콤,
                훈남모바일, 훈모바일, 훈민정음, 훈민정음텔레콤, 훈스마트텔레콤,
                훈이통신, 훈텔레콤, 훈통신, 훈훈통신, 휘텔레콤, 휘황부산,
                휴대FUN, 휴대폰, 휴대폰11번가, 휴대폰1번가, 휴대폰1번지,
                휴대폰365아울렛진접점, 휴대폰365일할인매장, 휴대폰DC마트,
                휴대폰가게, 휴대폰가게양주점, 휴대폰가격이알고싶다울산점,
                휴대폰갤러리, 휴대폰갤럭시아울렛, 휴대폰거래소,
                휴대폰거래소(동탄점), 휴대폰공장(김해점), 휴대폰국제시장,
                휴대폰꽁짜마트, 휴대폰나라, 휴대폰나라교현점,
                휴대폰나라서충주점, 휴대폰놀이터, 휴대폰다모아, 휴대폰다이렉트,
                휴대폰달인(새마을통신), 휴대폰대박싼집(북문로짱), 휴대폰대통령,
                휴대폰도매가할인마트, 휴대폰도매샵, 휴대폰도매할인,
                휴대폰디지털프라자, 휴대폰랜드, 휴대폰마켓, 휴대폰마트,
                휴대폰마트병천1호점, 휴대폰맛집, 휴대폰멀티샵, 휴대폰멀티점,
                휴대폰면세점, 휴대폰면세점(은평점), 휴대폰면세점호매실직영점,
                휴대폰명가, 휴대폰바로고, 휴대폰박사, 휴대폰백화점,
                휴대폰빅마트, 휴대폰상설마트, 휴대폰상점, 휴대폰샵, 휴대폰성지,
                휴대폰성지119모바일, 휴대폰성지가호통신,
                휴대폰성지가호통신평거점, 휴대폰성지골든모바일,
                휴대폰성지구리점, 휴대폰성지나라, 휴대폰성지다폰,
                휴대폰성지다폰구영, 휴대폰성지다폰온산, 휴대폰성지다폰호계,
                휴대폰성지대한민국폰가게, 휴대폰성지도매폰,
                휴대폰성지도매폰목동점, 휴대폰성지도매폰미아점,
                휴대폰성지도매폰반월점, 휴대폰성지도매폰패밀리,
                휴대폰성지모두의폰, 휴대폰성지믿고사진영점, 휴대폰성지선부점,
                휴대폰성지성지폰, 휴대폰성지수정모바일, 휴대폰성지스마트통신,
                휴대폰성지아일랜드통신, 휴대폰성지알람폰금동통신,
                휴대폰성지알람폰로나통신, 휴대폰성지알람폰루피통신,
                휴대폰성지알람폰짱구통신, 휴대폰성지옆커폰공업탑점,
                휴대폰성지옆커폰내발산점, 휴대폰성지옆커폰반고개점,
                휴대폰성지옆커폰시지광장점, 휴대폰성지옆커폰완정점,
                휴대폰성지옆커폰화성새솔점, 휴대폰성지원곡점,
                휴대폰성지이노명학점, 휴대폰성지제이정보통신,
                휴대폰성지중랑신내점, 휴대폰성지중앙점, 휴대폰성지코끼리폰,
                휴대폰성지코끼리폰동래, 휴대폰성지코끼리폰수정,
                휴대폰성지크크모바일심곡점, 휴대폰성지패밀리모바일가정점,
                휴대폰성지패밀리모바일당하점, 휴대폰성지패밀리모바일연희점,
                휴대폰성지패밀리모바일주안점, 휴대폰성지폰여사,
                휴대폰성지폰팔이윤상혁청주점, 휴대폰성지형곡점,
                휴대폰성지휴대폰파는총각월곡점, 휴대폰세상, 휴대폰쇼핑몰,
                휴대폰스토어, 휴대폰싸게파는집, 휴대폰싸게파는집(조치원짱),
                휴대폰싸다구, 휴대폰싼집, 휴대폰아울렛, 휴대폰아울렛괴정점,
                휴대폰아울렛신부점, 휴대폰아울렛이수역점, 휴대폰에반하다,
                휴대폰월드, 휴대폰일번가두정점, 휴대폰일번지진해점, 휴대폰점빵,
                휴대폰종합마트, 휴대폰종합프라자, 휴대폰주민센터괴정점,
                휴대폰주민센터남산점, 휴대폰주민센터두실점,
                휴대폰주민센터망미시장점, 휴대폰주민센터시청점,
                휴대폰주민센터신만덕점, 휴대폰주민센터우동점,
                휴대폰주민센터주례점, 휴대폰주민센터하단본점, 휴대폰창고,
                휴대폰챔피언, 휴대폰천국, 휴대폰천국(황금동), 휴대폰천하통일,
                휴대폰천하통일구일점, 휴대폰청년들, 휴대폰청년들누읍점,
                휴대폰청년들테라스스퀘어점, 휴대폰클라쓰, 휴대폰테크노마트,
                휴대폰특판산척점, 휴대폰파는총각상인점,
                휴대폰파는총각신매광장점, 휴대폰파는총각이곡점,
                휴대폰파는총각이시아폴리스점, 휴대폰파는총각중산점,
                휴대폰파는총각진천점, 휴대폰파는총각칠곡3지구점,
                휴대폰파는총각현풍테크노점, 휴대폰팜(평택), 휴대폰팩토리,
                휴대폰프라자, 휴대폰프라자이주단지점, 휴대폰프린스1호점,
                휴대폰하우스, 휴대폰하우스지웰시티점, 휴대폰할인마트,
                휴대폰할인매장, 휴대폰할인매장(가산점),
                휴대폰할인매장도매폰FM텔레콤, 휴마트, 휴먼(휴먼모바일(안양역)),
                휴먼네트웍스명촌지점, 휴모바일, 휴케이스, 휴통신, 흥,
                흥부텔레콤, 흥해라모바일, 흥화모바일, 흥화통신2, 희망정보통신,
                희망텔레콤, 희망통신, 희상통신, 희성이동통신, 희수통신,
                희야텔레콤, 히어로즈, 히어로폰, 히즈텔레콤, 히트모바일,
                히트정보, 히트텔레콤, 히트통신, 힐링
                <br />
                ※ 만나코퍼레이션 배송업체 : 공유다[부평배달캠프],
                FSD[강남서초]강남라이더스지사, MNP[A대구달서구]라이더스두류지사,
                MNP[강원원주]단계지사, MNP[강원철원]철원총판,
                MNP[경기고양일산]프리라이프1, MNP[경기구리시]구리총판,
                MNP[경기남양주시]베스트콜, MNP[경기동두천]리더스콜,
                MNP[경기수원시]세류권선1지사, MNP[경기수원시]영통1지사,
                MNP[경기파주]월롱지사, MNP[경기화성시]1동탄1지사,
                MNP[경남창원마산]합포구총판, MNP[경북포항]남구효자점,
                MNP[광주광산]광산지사, MNP[대구남구]허브지사,
                MNP[대구달성군]현풍점, MNP[대전]만나 둔산지사M,
                MNP[방배동]에스비 배달대행, MNP[보령시]청년배달,
                MNP[부산1본부]부전총판, MNP[부산남구]메트로총판,
                MNP[서울구로]개봉,광명만나플러스,
                MNP[서울구로]개봉,광명만나플러스, MNP[서울동작구]사당지사,
                MNP[서울성북구]안암지사, MNP[인천계양]챔피언지사,
                MNP[인천영종도]만나, MNP[제주]공유다 다연퀵,
                MNP[제주시]삼도총판, MNP[조치원]총판8(모2),
                MNP[천안직산]직산탑콜, MNP[충주]RP지부, MNP[포항남구]오천지사,
                OK[경기안산]선부, RP[대구북구칠곡]카카오콜,
                RP[부산해운대]좌동총판, RP[세종]예스콜2, RP[전남순천시]
                배고파딜리버리, RP[천안]썬배달대행, RUN.[부산동래]수안지점,
                RUN.[부산동래]수안지점, RUN.부산기장_총판4,
                RUN.서울은평_서대문지점, RUN[경기안산]월피점, RUN[대구성서]TOP,
                RUN[대전]관저지부, SH부산반송TNB, SH슈퍼히어로 서귀포시,
                UN[광주광산첨단]총판, WIN.[부산수영구]바다콜1호점,
                WIN.[인천서구]가정점, WIN[대구달서구]DG상인점,
                YSR[서울관악동작영등포]UK동작영등포, 공유다[강원속초공유다],
                공유다[경기광주리드콜총판], 공유다[김포풍무사우],
                공유다[안산상록중앙총판], 공유다[안산상록총판],
                공유다[은평유어파트너], 공유다[인천서구검단], 공유다[하남지사],
                날라가[서울관악구]연담, 날라가[시흥정왕4지사]NEW,
                이어드림[대구동구지부], 이어드림[부산금정],
                제트콜[서울노원구]노원총판, 제트콜[전남광양]광양1지사
                <br />
                ※ 바로고 배송업체 : [강동하남]강동하남허브,
                [강원강릉]강원강릉허브, [강원속초]강원속초1허브,
                [강원양구]강원양구허브, [강원원주]원주바로고1지사,
                [강원원주지정]원주기업도시점, [강원원주흥업]원주바로고흥업점,
                [강원인제]강원인제2허브, [강원주문진]강원주문진허브,
                [강원춘천]춘천지사, [강원태백]강원태백허브1,
                [강원평창]강원대관령허브, [강원화천]강원화천허브,
                [고양내유]고양B2B허브, [고양덕양]고양덕양프로허브,
                [고양삼송]고양삼송B2B, [고양화정]고양덕양프로허브,
                [공덕오거리]마포공덕오거리허브, [공주신관]충남공주허브,
                [과천문원]과천비투비, [관악신림]신림허브, [광명철산]광명5팀,
                [광주경안]경기광주허브, [광주광산]광주월계B2B,
                [광주금호]B광주서구운천허브, [광주봉선]NN광주남구허브,
                [광주수완][H]광주광산센터, [광주용봉]광주북부삼각허브,
                [광주월계]광주월계B2B, [광주충장로]광주동구충장허브,
                [광주태전]경기광주태전허브, [광진성수]DS성수광진센터,
                [광진화양]DS성수광진센터, [구리인창]구리허브,
                [군포당동][H]안양총판, [김포운양]김포구래2허브,
                [남양주다산]다산허브, [남양주덕소]덕소허브,
                [남양주별내]별내지사, [남양주호평]평내호평허브,
                [당진읍내시]바다코리아메인수행, [대구고성]북대구침산허브,
                [대구교동]대구중구교동허브, [대구수성]대구수성수성동허브,
                [대구용산]성서용산허브, [대구율하]대구동구율하동허브,
                [대구읍내]북대구칠곡허브, [대구창리]대구현풍구지허브,
                [대구평리]서대구평리허브, [대전궁동]대전유성중앙허브,
                [대전산성]대전중부연합허브, [대전선화]대전동구중앙허브,
                [동대문장안]동대문DMC, [동대문회기]회기중앙지사,
                [동두천송내]동두천B2B, [마곡등촌]강서등촌가양A허브,
                [부산사직]부산사직종합운동장점, [부천원미][H]경기부천총판,
                [분당미금]분당미금허브, [분당서현]성남동판교허브,
                [분당야탑]분당야탑2허브, [분당여수]분당야탑2허브,
                [서대문홍제]서대문홍제허브, [서울가락]송파가락시장몰허브,
                [서울강일]강동상일허브, [서울개포]강남대치1허브,
                [서울공릉]노원다올허브, [서울구로]구로엘리트허브,
                [서울길음]DS세무관리1센터, [서울논현]강남논현허브,
                [서울대치]강남대치1허브, [서울도봉]도봉구허브,
                [서울돈암]서울성북허브, [서울동소문]서울성북허브,
                [서울동작]노량진허브, [서울마천]송파가락2허브,
                [서울마포]서대문신촌지사, [서울목동]양천지원A허브,
                [서울문정]송파문정허브, [서울미아]DS세무관리1센터,
                [서울반포]서초교대허브, [서울방배]서초방배1허브,
                [서울삼성]강남논청허브, [서울삼전]송파잠실허브,
                [서울상계]노원가온허브, [서울상암]마포북부센터,
                [서울서초]서초교대허브, [서울성내]강동성내허브,
                [서울성북]서울성북허브, [서울송파]송파장군허브,
                [서울수서]강남수서허브, [서울수유]DS세무관리1센터,
                [서울시흥]금천3지사, [서울신정]바로고신정지사,
                [서울신천]송파가락허브, [서울안암]서울성북허브,
                [서울양재]강남양재2허브, [서울역삼]강남역삼1허브,
                [서울역촌]은평중앙센터, [서울연희]서대문신촌지사,
                [서울영등포]영등포중앙허브, [서울월계]성북다온허브,
                [서울잠실]송파잠실허브, [서울잠원]강남논현허브,
                [서울장위]성북다온허브, [서울장지]송파문정허브,
                [서울정릉]DS세무관리1센터, [서울중계]노원가온허브,
                [서울청담]강남논청허브, [서울충정로]중구명동허브,
                [서울흑석]흑석허브, [성남중앙]성남중원A허브,
                [성동도선]중구황학허브, [수원금곡]수원서수원허브,
                [수원영통]수원영통중앙허브, [수원인계]수원동수원허브,
                [수원정자]수원장안허브, [시흥군자]바로고안산단원허브,
                [시흥대야]시흥대야허브, [시흥목감]시흥목감허브,
                [시흥배곧][H]경기시흥총판, [시흥월곶][H]경기시흥총판,
                [시흥장곡]바로고시흥연성 &lt;총판&gt; ,
                [시흥정왕][H]경기시흥총판, [안산고잔]바로고신고잔허브,
                [안산사동]안산상록하늘본점, [안산상록]안산부곡허브,
                [안성공도]경기안성허브, [안성명륜]안성명륜점,
                [안양동안]동안비투비, [안양만안][H]M경기안양만안총판,
                [양주고읍]양주고읍허브, [양평양근]경기양평허브,
                [여주가남]경기여주가남허브경기여주하동허브(이탈),
                [여주홍문]경기여주홍문허브, [오산궐동]수원오산중앙허브,
                [용인김량장]처인구지사, [용인남사]용인남사허브,
                [용인동백]수원동백1허브, [용인백암]용인백암허브,
                [용인신갈]수원신구갈허브, [용인죽전]굿프랜즈,
                [울산호계]울산북구호계허브, [의왕고천]동안비투비,
                [의왕월암]안산건건동허브, [의정부]경기의정부허브,
                [이천마장]경기이천마장허브, [이천장호원]경기이천장호원허브,
                [이천창전][H]경기이천관제센터, [인천가좌]바로고서구동부허브,
                [인천검암]인천루원가정허브, [인천구월]바로고간석동부허브,
                [인천부평][H]인천부평총판, [인천서창][H]인천연수2총판,
                [인천송도][H]인천송도총판지사, [인천연수]인천연수허브,
                [인천연희]인천서구승학허브, [인천작전][H]계양구지사,
                [인천중산]영종하늘바로고, [인천중앙]바로고동인천허브,
                [인천청라]인천서구청라허브, [인천학익][H]M인천미추홀총판,
                [정읍수성]전북정읍허브, [중구충무로]중구명동허브,
                [중랑면목]중랑B2B허브, [파주금촌]경기파주금촌1허브,
                [파주문산]경기파주문산허브, [평택고덕]본사배차지사,
                [평택비전]경기평택허브, [평택안중]경기평택안중허브,
                [포천소흘]포천소흘허브, [하남교산]하남거북이,
                [화성남양]화성남양1허브, [화성능동]화성병점허브,
                [화성봉담]화성봉담허브, [화성장지]수원동탄중앙허브,
                [화성진안]화성병점허브, [화성청계]수원동탄역허브,
                [화성향남]화성향남지구
              </div>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- end // to개발 / popup03 개인정보처리 업무의 위탁 -->

      <!-- to개발 / popup04 수집한 개인정보의 제3자 제공 -->
      <section
        v-if="currentModal === '5'"
        id="modalLayerPopup"
        key="20210120-04"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              수집한 개인정보의 제3자 제공
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <caption>
                  제공받는 업체, 제공하는 항목, 제공받는 자의 이용목적, 보유 및
                  이용기간 에 따른 이동전화 개인정보 제 3자 제공에 관한
                  정보입니다.
                </caption>
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 30%" />
                  <col style="width: 30%" />
                  <col style="width: 20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <th>제공항목</th>
                    <th>제공목적</th>
                    <th>보유 및 이용기간</th>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">서울보증보험</span>
                      (서울보증보험이 분리보관 정보 이용 및
                      신용정보회사(나이스평가정보, 코리아크레딧뷰로,
                      한국평가데이터), 신용정보집중기관(한국신용정보원),
                      금융결제원에 제공ㆍ조회하는 것 포함)
                    </td>
                    <td>
                      <span class="highlight">고유식별정보</span>(서울보증보험에
                      단순 전달), 이름, 이동전화번호, 서비스정보,
                      분할상환계약정보, 청구정보, 신용거래정보(신용보험가입정보
                      등), 신용도 판단정보(연체, 대금지급정보 등),
                      신용등급(평점)
                    </td>
                    <td>
                      <span class="highlight"
                        >서비스 이용요금 및 분할상환 채권보전을 위한
                        신용보험가입 인수심사, 운영관리, 채권추심, 신용정보의
                        집중관리ㆍ활용 및 교환</span
                      >
                    </td>
                    <td rowspan="2">
                      <span class="highlight"
                        >상거래관계가 종료된 날부터 5년까지</span
                      ><br />(단, 법령에 따로 정한 경우에는 해당 기간까지,
                      신용보험 미가입시에는 제공 및 보유하지 않음)
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >자산유동화법인 및 관련수탁자(삼일회계법인 등
                        자산실사법인, 추심기관 등), 공공기관(금융감독원
                        등)</span
                      >
                    </td>
                    <td>
                      이름, 생년월일, 주소, 서비스 가입 및 분할 상환 계약정보,
                      납부정보 신용능력 정보, 보험가입정보
                    </td>
                    <td><span class="highlight">자산유동화 관련 업무</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">한국인터넷진흥원</span></td>
                    <td>
                      이동전화번호, 스팸발신번호, 스팸메시지 내용, 스팸수신시간
                    </td>
                    <td>
                      <span class="highlight"
                        >스팸신고 및 차단, 스팸발송으로 인한 이용 정지 혹은
                        해지사실의 타통신사에 의한 확인 등</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >한국정보통신진흥협회, 한국통신사업자연합회, 행정안전부,
                        타 통신사</span
                      >
                    </td>
                    <td>
                      이름,<span class="highlight"
                        >주민등록번호(외국인등록번호)</span
                      >, 서비스관리번호, 사업자등록번호, 법인등록번호 및 법인명,
                      이동전화번호, 요금정보
                    </td>
                    <td>
                      <span class="highlight"
                        >미환급금 안내 (주소 확인, 우편물 발송, 문자 발송)</span
                      >
                    </td>
                    <td>
                      <span class="highlight">해당 업무 처리 완료 시까지 </span>
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="4">
                      <span class="highlight"
                        >한국정보통신진흥협회, 타 통신사,
                        한국통신사업자연합회</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td rowspan="3">
                      <span class="highlight">고유식별정보</span>, 이름(법인명),
                      이동전화번호, 신분증 기재사항, 주소, 생년월일, 국적,
                      미납요금 금액, 가입정보(통신사, 가입일, 해지일)
                    </td>
                    <td>
                      <span class="highlight"
                        >방송통신신용정보공동관리(식별, 체납, 복지,
                        외국인정보인증), 번호이동, 명의도용방지, 외국인 출국
                        정보, 우편안내, 분실단말기 공동관리, 스팸발송자, 서비스
                        가입제한, 이동전화 단기간 가입 회선 관리</span
                      >
                    </td>
                    <td>
                      <span class="highlight">서비스제공기간</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >분실신고된 단말기의 국외 사용차단 (단말기 분실 신고자에
                        한함)</span
                      >
                    </td>
                    <td rowspan="2">
                      <span class="highlight"
                        >해당 업무 처리 완료 시까지(거절의 경우 상담 종료
                        시까지)</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">
                        스팸발송자서비스 가입제한, 보이스피싱, 스미싱,
                        불법대부광고, 불법금융행위광고, 사기, 성매매 등에 사용된
                        대포폰, 발신번호변작자의 서비스 가입제한
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >한국정보통신진흥협회, 이동통신사</span
                      >
                    </td>
                    <td>
                      이름, 연체정보등록예정일, 신용정보사 위임예정일, 통신권
                      중복가입방지정보(DI), 직권해지예정일, 연체금액, 납부기한,
                      미성년자의 경우 법정대리인 중복가입방지정보(DI),
                      이동전화번호 또는 서비스관리번호
                    </td>
                    <td>
                      <span class="highlight">
                        통신연체사실통지, 이용요금 미납으로 인한 해지시 알림
                      </span>
                    </td>
                    <td>
                      <span class="highlight">
                        해당 업무 처리 완료 시까지
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">
                        행정안전부, 과학기술정보통신부,
                        한국정보통신진흥협회</span
                      >
                    </td>
                    <td>이름,<span class="highlight">주민등록번호 </span></td>
                    <td>
                      <span class="highlight">주민등록전산 정보 확인</span>
                    </td>
                    <td>
                      <span class="highlight">서비스 해지 시까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">
                        법무부, 한국정보통신진흥협회
                      </span>
                    </td>
                    <td>
                      이름, 국적, <span class="highlight">여권번호 </span> ,
                      등록번호, 생년월일, 회선번호
                    </td>
                    <td>
                      <span class="highlight">외국인 출국 사실 정보 확인</span>
                    </td>
                    <td>
                      <span class="highlight">체류기간까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="highlight">한국정보통신진흥협회</span></td>
                    <td>단말기 정보(IMEI)</td>
                    <td><span class="highlight">IMEI 사전등록 서비스</span></td>
                    <td>
                      <span class="highlight">서비스 계약 해지 시까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="highlight">한국정보통신진흥협회</span></td>
                    <td>
                      <span class="highlight">고유식별정보</span>,개통일자,
                      단말기정보(모델명, 일련번호, IMEI)
                    </td>
                    <td><span class="highlight">동일명의 확인 서비스</span></td>
                    <td>
                      <span class="highlight">서비스 해지 시까지</span>
                    </td>
                  </tr>

                  <tr>
                    <td><span class="highlight">나이스평가정보</span></td>
                    <td>
                      <span class="highlight">주민등록번호</span>, 이름,
                      중복가입확인정보(DI), 생년월일, 성별
                    </td>
                    <td>
                      <span class="highlight"
                        >내국인 실명 인증, 신용정보조회</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >신분증 발급기관(행정안전부, 법무부, 외교부,
                        경찰청)</span
                      >
                    </td>
                    <td>
                      <span class="highlight">고유식별정보</span>, 이름, 신분증
                      기재사항
                    </td>
                    <td><span class="highlight">부정가입 방지</span></td>
                    <td>
                      <span class="highlight"
                        >서비스 가입 처리 완료 시까지</span
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >행정안전부, 과학기술정보통신부,
                        한국정보통신진흥협회</span
                      >
                    </td>
                    <td><span class="highlight">주민등록번호</span>, 이름</td>
                    <td>
                      <span class="highlight">주민등록전산 정보 확인</span>
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >법무부, 한국정보통신진흥협회</span
                      >
                    </td>
                    <td>
                      <span class="highlight">여권번호</span>, 이름, 국적,
                      등록번호, 생년월일, 이동전화번호
                    </td>
                    <td>
                      <span class="highlight">외국인 출국사실 정보 확인</span>
                    </td>
                    <td><span class="highlight">체류기간까지</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >KT, SK브로드밴드, LGU+, 온세텔레콤, SK텔링크(국제전화
                        및 콜렉트콜 사업자), 삼성SDS, LG헬로, 한국케이블텔레콤,
                        드림라인, SB인터렉티브(국제전화 사업자)</span
                      >
                    </td>
                    <td>
                      이름, 이동전화번호, 생년월일, 청구일자, 청구금액,
                      과금자료(착신번호, 통화일시, 발신번호, 통화시간), 미납금액
                      등
                    </td>
                    <td>
                      <span class="highlight"
                        >국제전화 및 콜랙트콜 이용에 따른 요금 정산</span
                      >
                    </td>
                    <td><span class="highlight">요금 징수 후 5년 간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">국세청</span></td>
                    <td><span class="highlight">주민등록번호</span></td>
                    <td>
                      <span class="highlight"
                        >소득공제 대상 항목에 대한 소득공제 반영 신청</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">즉시납부/자동납부 카드사</span>
                    </td>
                    <td>카드번호</td>
                    <td>
                      <span class="highlight"
                        >소득공제 대상 항목에 대한 소득공제 반영 신청</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>

                  <tr>
                    <td>
                      <span class="highlight"
                        >다날, KG모빌리언스, 페이레터, 갤럭시아머니트리,
                        NHN한국사이버결제</span
                      >
                    </td>
                    <td>
                      이름, 이동전화번호, DI, 이동전화 이용금액,
                      인증실패사유(한도초과 등), 통신과금서비스 이용가능고객
                      여부, 잔여한도 및 미납여부
                    </td>
                    <td>
                      <span class="highlight"
                        >통신과금서비스(휴대폰결제) 인증 및 이용금액 정산</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">케이에스넷, SK주식회사</span>
                    </td>
                    <td>예금주 생년월일, 계좌번호</td>
                    <td>
                      <span class="highlight"
                        >은행계좌 자동이체 신청 시 계좌 인증</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">금융결제원, SK주식회사</span>
                    </td>
                    <td>이름, 이동전화번호, 예금주 생년월일, 계좌번호</td>
                    <td>
                      <span class="highlight"
                        >은행계좌 자동이체 신청 및 인출 요청</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">금융결제원, 나이스평가정보</span>
                    </td>
                    <td>카드주 생년월일, 계좌∙카드번호</td>
                    <td>
                      <span class="highlight"
                        >카드 자동결제 신청, 카드결제 요청, 은행계좌
                        송금처리</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">자동인출을 신청하신 은행</span>
                    </td>
                    <td>이동전화번호, 생년월일, 계좌번호</td>
                    <td>
                      <span class="highlight">은행계좌 자동이체 처리</span>
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight">금융결제원, 케이에스넷</span>
                    </td>
                    <td>카드번호</td>
                    <td><span class="highlight">카드 자동결제 요청</span></td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">자동납부 신청 카드사</span></td>
                    <td>카드번호</td>
                    <td><span class="highlight">카드 자동결제 처리</span></td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td>
                      <span class="highlight"
                        >케이에스넷, 하나은행, 우리은행, 국민은행, 신한은행,
                        농협</span
                      >
                    </td>
                    <td>이름</td>
                    <td>
                      <span class="highlight">입금전용계좌 수취인조회</span>
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>

                  <tr>
                    <td>
                      <span class="highlight"
                        >한국정보통신산업협회, 나이스평가정보</span
                      >
                    </td>
                    <td><span class="highlight">주민등록번호</span></td>
                    <td>
                      <span class="highlight"
                        >실명인증 및 연계정보(CI)발급</span
                      >
                    </td>
                    <td>
                      <span class="highlight">실명인증 후 즉시 파기</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="highlight">번개장터</span></td>
                    <td>
                      이동전화번호, 요금제, 가입일, 해지일, 정지일, 요금제변경일
                    </td>
                    <td>
                      <span class="highlight"
                        >번개장터 포인트적립, 수수료정산</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">중고나라</span></td>
                    <td>이름, 이동전화번호, 요금제명, 가입일, 해지일</td>
                    <td>
                      <span class="highlight">제휴요금제를 통한 쿠폰 제공</span>
                    </td>
                    <td>
                      <span class="highlight">쿠폰발송 후 6개월까지</span>
                    </td>
                  </tr>
                  <tr>
                    <td><span class="highlight">SK브로드밴드</span></td>
                    <td>이동전화번호, 서비스가입상태(가입, 정지, 해지)</td>
                    <td>
                      <span class="highlight"
                        >SK브로드밴드 서비스와 SK7mobile 알뜰폰과의 결합상태
                        확인</span
                      >
                    </td>
                    <td>
                      <span class="highlight">서비스제공기간</span>(SK브로드밴드
                      서비스 이용과 무관)
                    </td>
                  </tr>
                </tbody>
              </table>

              <h2>국제전화(00700.com 홈페이지, 앱)</h2>
              <table>
                <caption>
                  제공받는 업체, 제공하는 항목, 제공받는 자의 이용목적, 보유 및
                  이용기간 에 따른 이동전화 개인정보 제 3자 제공에 관한
                  정보입니다.
                </caption>
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 30%" />
                  <col style="width: 30%" />
                  <col style="width: 20%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>제공받는 자</th>
                    <th>제공항목</th>
                    <th>제공목적</th>
                    <th>보유기간</th>
                  </tr>
                  <tr>
                    <td><span class="highlight">SK플래닛</span></td>
                    <td>OK캐쉬백번호, 전화번호, 적립포인트</td>
                    <td>
                      <span class="highlight"
                        >OK캐쉬백포인트 적립, 이벤트/혜택 기프티콘 발송</span
                      >
                    </td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                  <tr>
                    <td><span class="highlight">아시아나항공</span></td>
                    <td>아시아나항공회원번호, 전화번호, 적립마일리지</td>
                    <td><span class="highlight">마일리지 적립</span></td>
                    <td><span class="highlight">서비스제공기간</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->

            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>
      <!-- end // to개발 / popup04 수집한 개인정보의 제3자 제공 -->

      <!-- to개발 / popup05 고객 개인정보의 국외 이전-->
      <section
        v-if="currentModal === '6'"
        id="modalLayerPopup"
        key="20210120-05"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              고객 개인정보의 국외 이전
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화</h2>
              <table>
                <colgroup>
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                  <col style="width: 20%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>관련 서비스</th>
                    <th>이전되는 개인정보 항목</th>
                    <th>이전되는 국가 및 이전 일시, 방법</th>
                    <th>개인정보를 이전받는 자</th>
                    <th>개인정보를 이전받는 자의 이용목적 및 보유기간</th>
                  </tr>
                  <tr>
                    <td>eSIM 개통 서비스</td>
                    <td>가입자 고유식별번호(IMSI), MSISDN, EID, ICCID</td>
                    <td>
                      <span class="highlight">이전국가 : 프랑스</span><br />
                      일시 : eSIM 개통 요청 발생시<br />
                      방법 : TLS(상호인증서기반 통신)을 이용한 원격지 전송
                    </td>
                    <td>
                      <span class="highlight"
                        >IDEMIA (RSP 운영사, 02-3446-6277)</span
                      >
                    </td>
                    <td>
                      <span class="highlight">목적 : eSIM Profile Download</span
                      ><br />
                      <span class="highlight"
                        >보유기간 : eSIM 서비스 이용 종료 또는 위탁 계약 종료
                        시점까지</span
                      ><br />
                    </td>
                  </tr>
                  <tr>
                    <td>eSIM 개통 서비스 (Notification 기능)</td>
                    <td>EID (IDEMIA 사 RSP 서버를 통하여 제공)</td>
                    <td>
                      <span class="highlight">이전국가 : 미국</span> <br />
                      일시 : eSIM 개통 요청 발생시 <br />
                      방법 : TLS(상호인증서기반 통신)을 이용한 원격지 전송
                    </td>
                    <td>
                      <span class="highlight"
                        >삼성(02-2255-0114), <br />
                        애플(02-6712-6700)
                      </span>
                    </td>
                    <td>
                      <span class="highlight"
                        >목적 : QR촬영없이 단말 Notification 을 통한 eSIM
                        Profile Download <br />
                        보유기간 : eSIM 서비스 이용 종료 또는 위탁 계약 종료
                        시점까지
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->
            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <!-- to개발 / popup06 의견 수렴 및 불만 처리 -->
      <section
        v-if="currentModal === '7'"
        id="modalLayerPopup"
        key="20210120-06"
        class="ui-modal"
        role="dialog"
        aria-hidden="false"
        aria-labelledby="modalLayerPopup-tit"
        aria-modal="true"
        style="display: block"
      >
        <div class="ui-modal-wrap modal-black-wrap">
          <header class="ui-modal-tit">
            <h1 id="modalLayerPopup-tit" holds="true" tabindex="0">
              의견 수렴 및 불만 처리
            </h1>
            <button type="button" class="btn-close" @click="hide">
              <i>닫기</i>
            </button>
          </header>
          <div class="ui-modal-cont" style="height: auto">
            <!-- 약관 내용 -->
            <div class="term-box">
              <h2>이동전화, 국제전화(00700.com 홈페이지, 앱)</h2>
              <table>
                <colgroup>
                  <col style="width: 50%" />
                  <col style="width: 50%" />
                </colgroup>
                <caption>
                  필수 이용 목적에 따른 필수 수집항목, 보유기간(필수 이용 목적,
                  필수 수집 항목, 보유기간정보제공)
                </caption>
                <tbody>
                  <tr>
                    <th>개인정보보호 책임자</th>
                    <th>개인정보보호 담당자</th>
                  </tr>
                  <tr>
                    <td>
                      성명, 직책 : 이정열 Infra Tech 담당임원<br />
                      전화번호 : 이동전화 114, (국번없이) 1599-0999<br />
                      이메일 : sktelinkprivacy@sk.com
                    </td>
                    <td>
                      성명, 직책 : 이원희 Infra 보안팀 부장<br />
                      전화번호 : 이동전화 114, (국번없이) 1599-0999<br />
                      이메일 : sktelinkprivacy@sk.com
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- // 약관내용 -->
            <div class="btn-wrap">
              <button type="button" class="btn-line" holde="true" @click="hide">
                <i>확인</i>
              </button>
            </div>
          </div>
        </div>
      </section>

      <div class="modal-backdrop" style="display: block" />

      <!-- to개발 / 팝업 오픈 시 ui-modal 안에 팝업이 생기는 구조였음 -->
      <!-- end // to개발 / 2021.01.20 ~ 현재까지 적용 -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentModal: "",
      isVisible: false,
    };
  },
  methods: {
    show(id) {
      (this.currentModal = id), (this.isVisible = true);
    },
    hide() {
      (this.currentModal = ""), (this.isVisible = false);
    },
  },
};
</script>

<style></style>
