<template>
  <a
    class="btn btn_graylineW fr icon_l_btn"
    :href="downloadLink"
    target="_blank"
    role="button"
  ><i class="icon_download" />다운로드</a>
</template>

<script>
export default {
  name: 'DownloadLink',
  components: {
  },
  props: {
    downloadLink: {
      type: String,
      required: true
    }
  },
  emits: [
  ],
  data () {
    return {
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style>

</style>