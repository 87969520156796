<template>
  <div id="app">
    <!-- <Meta /> -->
    <Loader v-if="isLoader > 0" />
    <KcbCert
      v-if="kcbCertPopup && kcbToken.length"
      :token="kcbToken"
      :cpcd="kcbCpCd"
    />
    <CertMobile
      :is-visible.sync="certPopup.visible"
      @cert-mobile="openKcbCert"
      @close="closeCertMobile"
    />
    <!--타이틀 제거 :service-name="certPopup.title" -->

    <!-- 공통 알림 팝업 -->
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
      @close="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>

    <!-- 시스템 에러 팝업 -->
    <Popup
      :is-visible.sync="errorVisible"
      :pop-title="'시스템 에러'"
      @click="errorPopupHandler"
      @close="errorPopupHandler"
    >
      <p>
        요청하신 작업의 처리 중 오류가 발생하였습니다.
        <br><br>
        서비스 이용에 불편을 드려 죄송합니다.<br>빠른 조치하도록 하겠습니다.
      </p>
    </Popup>

    <!-- 웹 최적화 이용 안내 -->
    <PopupBrowserSupport
      :is-visible.sync="popup.browserSupport.isVisible"
      :pop-title="'웹 최적화 이용 안내'"
      @change="changeBrowserSuppotPopup"
    >
      <p>
        SK텔링크 홈페이지 서비스는 Chrome 및 Internet Explorer 11 이상 버전에 최적화 되어있습니다.
        Internet Explorer 10 이하 버전을 사용하시는 고객님께서는 홈페이지의 일부 사용이 제한되거나, 일부 페이지가 정상적인 동작을 하지 않을 수 있습니다.
        <br><br>
        안정적인 서비스 이용을 위해 가급적 최적화된 브라우저로 이용해 주시기 바랍니다.
      </p>
      <p class="txt_point01 tal">
        * Internet Explorer 버전을 확인하세요. <br>
        Internet Explorer 실행 > 도구(Alt + x) > Internet Explorer 정보 > 확인
      </p>
    </PopupBrowserSupport>

    <popup-big
      :pop-heading="'책임의 한계와 법적고지'"
      :btn-show="false"
      :is-visible="legalIsVisible"
      :full-popup="'pop-full'"
      @click="handlePopupBig"
      @close="handlePopupBig"
    >
      <h4 class="title">
        SK텔링크 고객님 <br class="m_br"><span class="txt_point01">책임의 한계와 법적고지를 확인</span>해주세요.
      </h4>
      <div class="cont_term">
        <p>본 책임의 한계와 법적 고지는 <span class="txt_point01">www.sk7mobile.com 웹 회원을 포함한 SK텔링크주식회사(이하 “회사”)가 제공하는 인터넷 관련 서비스(이하 "사이트")를 이용하는 이용자가 유념해야 할 사항들을 고지</span>하는 것으로서 회원에게만  적용되는 약관과는 별도로 모든 이용자에게 적용됩니다. 따라서 이용자께서는 본 고지내용을 반드시 읽고 이용 여부를 결정하시기 바랍니다.</p>
        <p>회사는 사이트에 포함되어 있거나, 사이트를 통해 배포되거나, 사이트에 포함되어 있는 서비스로부터 얻게 되는 모든 정보(이하 "자료") 중 이용자가 게시한 자료(이하 "이용자 간 전달 자료 포함")의 정확성이나 신뢰성에 대해 어떠한 보증도 하지 않으며, 자료의 정확성, 저작권 준수 여부, 적법성 또는 도덕성에 대해 아무런 책임을 지지 않습니다. 본 사이트에서 이용자가 게시하는 자료 중에는 이용자의 개인적인 판단에 따라 불쾌감 또는 피해를 주는 내용이 있을 수도 있습니다.</p>
        <p>이용자가 게시하는 자료 가운데 불법 내지는 선량한 풍속 기타 사회질서에 반하는 내용에 대하여 회사는 어떠한 책임도 지지 않으며, 사이트 내에서 이용자가 게시하는 여하한 정보 및 이와 관련된 광고, 기타 정보 또는 제안의 결과로서 취득하게 되는 어떠한 재화와 용역에 대하여도 그것의 가치, 용도, 광고 및 기타 관계법령 준수 등에 대한 일체의 보증을 하지 않습니다.</p>
        <p>이용자는 자료에 대한 신뢰 여부가 전적으로 이용자의 책임임을 인정합니다. 회사는 필요한 경우 타인의 권리를 침해하거나 의무를 위반하는 이용자에 대하여 사전 통지 없이 서비스 이용 제한 조치 또는 이용계약 해지를 할 수 있습니다. 또한, 회사는 이용자가 게시하는 자료가 타인의 저작권 등을 침해하였음이 확인된 경우 등에는 게시물 삭제 등의 조치를 취할 수 있습니다. 한편, 회사는 이용자가 게시하는 자료와 관련하여 저작권법 등 지적재산권에 관한 법률, 전자거래기본법 및 기타 관계법령의 준수 여부에 대하여 어떠한 책임도 지지 않으며, 오직 이용자 본인에게 책임이 있음을 알려드립니다.</p>
        <p>어떠한 경우에도 회사는 이용자가 게시한 자료와 관련하여 직접, 간접, 부수적, 징벌적, 파생적인 손해 등 명목 여부를 불문하고 여하한 손해에 대해서도 책임을 지지 않습니다. 이용자가 사이트 내에 게시한 자료의 저작권은 이용자 본인에게 있으며, 회사가 게시한 자료의 저작권은 회사에 있습니다. 상기의 자료들은 저작권법에 의해 보호를 받고 있으며, 일부 혹은 전체의 내용을 회사나 이용자의 동의 없이 무단으로 복제, 배포, 게시를 하는 행위는 저작권법에 위배됩니다.</p>
        <p>회사는 필요하다고 인정되는 경우 본 고지내용을 변경할 수 있으며, 변경된 내용은 사이트 화면에 게시하거나 기타의 방법으로 이용자에게 공시합니다. 이용자는 변경된 고지내용에 동의하지 않으면 서비스 이용을 중단할 수 있습니다.</p>
        <p class="txt_point01">
          이상의 고지 내용은 2013년 2월 20일부터 시행합니다.
        </p>
        <p class="txt_point01">
          최종 갱신일 : 2013년 2월 20일
        </p>
      </div>
    </popup-big>
    <div id="wrap">
      <router-view />
    </div>
  </div>
</template>
<script>
import api from '@/api'
import util from '@/utilities'
import KcbCert from '@/pages/Cert.vue'
import Loader from '@/components/Loader'
import CertMobile from '@/components/popup/CertMobile'
import Popup from '@/components/popup/Popup'
import PopupBrowserSupport from '@/components/popup/PopupBrowserSupport'
import PopupBig from '@/components/popup/PopupBig'

export default {
  name: 'App',
  metaInfo: {
    title: 'SK텔링크',
    titleTemplate: '%s | SK텔링크'
  },
  components: {
    Loader,
    KcbCert,
    CertMobile,
    Popup,
    PopupBrowserSupport,
    PopupBig
  },
  data() {
    return {
      popup: {
        certMobile: {
          isVisible: false,
        },
        browserSupport: {
          isVisible: false,
        },
        applyPlan: {
          isVisible: false
        },
      },
      legalIsVisible: false,
      kcbToken: '',
      kcbCpCd: '',
    }
  },
  computed: {
    isLoader() {
      return this.$store.getters.LOADER
    },
    kcbCertPopup() {
      return this.$store.getters.KCB_CERT_POPUP
    },
    certPopup() {
      return this.$store.getters.CERT_POPUP
    },
    kcbCertKey() {
      return this.$store.getters.KCB_CERT_KEY
    },
    error() {
      return this.$store.getters.ERROR
    },
    errorVisible() {
      return this.error ? true : false
    },
    alertPopup() {
      return this.$store.getters.ALERT_POPUP
    },
    legalPopupVisible() {
      return this.$store.getters.LEGAL_POPUP
    },
  },
  watch: {
    legalPopupVisible: {
      handler() {
        this.legalIsVisible = this.legalPopupVisible
      }
    }
  },
  created() {
    if (util.getCookie('browserSupport') !== 'Y') {
      // 브라우저 버전 체크
      if (util.getBrowserIEVersion() !== -1 && util.getBrowserIEVersion() <= 10) {
        this.popup.browserSupport.isVisible = true
      }
    }
  },
  mounted() {
  },
  methods: {
    handlePopupBig() {
      this.$store.commit('OFF_LEGAL_POPUP')
    },
    openKcbCert() {
      /**
       * 휴대폰 본인 티켓요청
       */
      const returnUrl = `${window.location.origin}/cert-result`
      // const returnUrl = process.env.VUE_APP_REDIRECT_API + '/common/redirect-kcb-auth'
      api.CommonGetReqKcbAuthTicket({ return_url: returnUrl})
        .then(response => {
          if (response.data.result_cd === '0000') {
            this.kcbToken = response.data.mdl_tkn
            this.kcbCpCd = response.data.cp_cd
            this.$store.commit('ON_KCB_CERT_POPUP')
            this.popup.certMobile.isVisible = false
          }
        })
      // this.$store.commit('OFF_CERT_POPUP')
    },
    errorPopupHandler() {
      this.$store.commit('SET_ERROR', false)
    },
    alertPopupHandler() {
      if (this.alertPopup.code) {
        // 본인인증 관련 에러시 인증 팝업 띄움
        if (this.alertPopup.type === 'cert') {
          this.$store.commit('ON_CERT_POPUP')
          if (this.alertPopup.code === 'E019') {
            this.$store.commit('KCB_CERT_KEY', '')
          }
        }
        this.$store.commit('SET_CALLBACK_ALERT', util.copyDifferentlyData(this.alertPopup))
        this.$store.commit('OFF_ALERT_POPUP')
      } else {
        this.$store.commit('OFF_ALERT_POPUP')
      }
    },
    closeCertMobile() {
      // this.$router.go(-1)
      // this.$router.push({ path: '/'})
      this.certPopup.visible = false
    },
    changeBrowserSuppotPopup(value) {
      if(value) {
        util.setCookie('browserSupport', 'Y', 7)
        this.popup.browserSupport.isVisible = false
      }
    }
  }
}
</script>

<style lang="scss">
#app {
  position: relative;
}
</style>