<template>
  <div id="ENG_STOCK_HOLDERS" class="eng">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents en">
        <div class="sub_header bg05">
          <h3 class="title">Finance information</h3>
          <p class="txt">
            We promote our corporate values, leaping to the global carrier<br />with
            our transparent management
          </p>
          <img src="/content/images/sub_header_cp05.jpg" />
        </div>

        <!-- tab -->
        <div class="tab_type01 tab2">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li class="on" title="현재메뉴 선택됨">
              <router-link to="/eng/stockholders">
                Stockholders’ status
              </router-link>
            </li>
            <li>
              <router-link to="/eng/credit_rating"> Credit rating </router-link>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <div class="fs_table">
            <h4 class="blind">주주현황</h4>
            <div class="img_box">
              <img
                src="/content/images/img_cp_equity_2018.png"
                alt="SK텔레콤(주)지분율 100%"
                class="w_hide"
              />
              <img
                src="/content/images/img_cp_equity_m_2018.png"
                alt="SK텔레콤(주)지분율 100%"
                class="m_hide"
              />
            </div>

            <div class="table_title">
              <span class="fr txt_point01"
                ><span class="icon_star">*</span>(unit: stock, %)</span
              >
            </div>
            <div class="table_type04">
              <table>
                <caption>
                  구분, 주식수㈜, 지분율(%) 나타내는 표입니다.
                </caption>
                <colgroup>
                  <col style="width: 33%" />
                  <col style="width: 34%" />
                  <col style="width: 33%" />
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">Classification</th>
                    <th scope="col">Number of Stocks</th>
                    <th scope="col">Share ratio</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>SK telecom</td>
                    <td class="txt_right">1,432,627</td>
                    <td class="txt_right">100.00%</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th scope="row">Total</th>
                    <td class="txt_right">1,432,627</td>
                    <td class="txt_right">100.00%</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/EngHeader";
import BreadCrumb from "@/components/EngBreadCrumb";
import Footer from "@/components/EngFooter";

export default {
  name: "Stockholders",
  metaInfo: {
    title: "Stockholders’ status | Finance information | Company | SK telink",
    titleTemplate: null,
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
