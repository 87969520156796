<template>
  <div id="ENG_INTRO_CEO" class="eng">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents en">
        <div class="sub_header bg01 en">
          <h3 class="title">CEO</h3>
          <p class="txt">
            We highly appreciate your keen interest and warm attention.
          </p>
          <img src="/content/images/sub_header_cp01.jpg" alt="Visual image" />
        </div>

        <!-- company -->
        <div class="company">
          <div class="ceo_area2">
            <h3>
              "We will create a growth story for a new leap forward with a new
              vision and culture that will be responsible for SK telink's Next."
            </h3>
            <span class="w_hide">
              <img
                class="img_box"
                src="/content/images/img_ceo4.jpg"
                alt="SK텔링크 대표이사 하성호"
              />
              <p>
                Welcome and thank you for visiting SK telink website.<br /><br />
                With your keen interest and warm attention, SK telink has
                achieved solid performance in our current business areas despite
                the global pandemic and rapid market changes.<br /><br />
                International Direct Dialing service ‘00700’ won the top 3
                Korean customer satisfaction surveys, namely National Customer
                Satisfaction Index (NCSI), Korean Standard-Service Quality Index
                (KS-SQI) and Korean Customer Satisfaction Index (KCSI) for 8
                consecutive years and is undeniably a representative brand of
                Korea. In addition, MVNO service ‘SK 7mobile’ won the major
                brand indices, namely 2022 Korea Brand Recommendation Index,
                2022 Premium Brand Index and 2022 National Brand Competitiveness
                Index and is renowned for the best MVNO brand in Korea.
                <br /><br />
                We are not sitting on its laurels. We will not only develop
                various growth businesses and establish implementation
                strategies that will be responsible for SK telink’s Next, but
                also create a new growth story for SK telink’s new leap forward
                by realizing the strategic roles and value increase, as a member
                of SK ICT Family.
                <br /><br />
                Moreover, we will faithfully fulfill responsibilities and
                obligations stipulated in relevant laws and regulations and
                strengthen the self-compliance program for the user protection.
                We will actively promote ESG-based 'happiness management' and
                grow into a company that makes its members happy, gives trust to
                its customers, exchanges good influence with the society where
                we belong , and eventually everyone can be proud of.
                <br /><br />
                We ask for your steadfast support and interest.
                <br /><br />
                Thank you<br /><br />
                SK telink CEO<br />
                HA SEONG HO
              </p>
            </span>
            <span class="m_hide">
              <img
                class="img_box"
                src="/content/images/img_ceo4.jpg"
                alt="SK텔링크 대표이사 하성호"
              />
              <p>
                Welcome and thank you for visiting SK telink website.<br /><br />
                With your keen interest and warm attention, SK telink has
                achieved solid performance in our current business areas despite
                the global pandemic and rapid market changes.<br /><br />
                International Direct Dialing service ‘00700’ won the top 3
                Korean customer satisfaction surveys, namely National Customer
                Satisfaction Index (NCSI), Korean Standard-Service Quality Index
                (KS-SQI) and Korean Customer Satisfaction Index (KCSI) for 8
                consecutive years and is undeniably a representative brand of
                Korea. In addition, MVNO service ‘SK 7mobile’ won the major
                brand indices, namely 2022 Korea Brand Recommendation Index,
                2022 Premium Brand Index and 2022 National Brand Competitiveness
                Index and is renowned for the best MVNO brand in Korea.
                <br /><br />
                We are not sitting on its laurels. We will not only develop
                various growth businesses and establish implementation
                strategies that will be responsible for SK telink’s Next, but
                also create a new growth story for SK telink’s new leap forward
                by realizing the strategic roles and value increase, as a member
                of SK ICT Family.
                <br /><br />
                Moreover, we will faithfully fulfill responsibilities and
                obligations stipulated in relevant laws and regulations and
                strengthen the self-compliance program for the user protection.
                We will actively promote ESG-based 'happiness management' and
                grow into a company that makes its members happy, gives trust to
                its customers, exchanges good influence with the society where
                we belong , and eventually everyone can be proud of.
                <br /><br />
                We ask for your steadfast support and interest.
                <br /><br />
                Thank you<br /><br />
                SK telink CEO<br />
                HA SEONG HO
              </p>
            </span>
          </div>
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/EngHeader";
import BreadCrumb from "@/components/EngBreadCrumb";
import Footer from "@/components/EngFooter";

export default {
  name: "IntroCEO",
  metaInfo: {
    title: "CEO | Company | SK telink",
    titleTemplate: null,
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
