<template>
  <div id="TMEMBERSHIP_NEW">
    <!-- 컨텐츠 -->
    <div class="tmemberWrap">
      <div class="tmHeader">
        <div class="tmHeaderB">
          <img
            src="/content/images/tm_new_header_20211122.png"
            alt="국제전화 00700 사용하면 매월 T멤버십 제휴 혜택 제공!"
          >
          <div class="tmPopLink">
            <a
              class="new-button"
              @click="popup.tmCheck.isVisible = true"
            >신청</a>
            <a
              class="terminate-button"
              @click="popup.tmCancel.isVisible = true"
            >해지</a>
          </div>
        </div>
      </div>

      <div class="tmContent">
        <div class="tmTab">
          <ul>
            <li
              :class="tabIndex == 1 ? 'on': ''"
              @click="tabActive(1)"
            >
              제휴 혜택
            </li>
            <li
              :class="tabIndex == 2 ? 'on': ''"
              @click="tabActive(2)"
            >
              신청 안내
            </li>
            <li
              :class="tabIndex == 3 ? 'on': ''"
              @click="tabActive(3)"
            >
              이용 안내
            </li>
          </ul>
        </div>

        <!-- 제휴 혜택 -->
        <div
          v-if="tabIndex == 1"
          class="tmContBox"
        >
          <div class="tmContTxt">
            <div class="tmMsg">
              고객님의 T멤버십 종류에 따라 신청 단계에서 제휴 혜택은 자동 선택됩니다.
            </div>
            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_tmn_11_20211122.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3><span class="highLight">T멤버십 적립형 요금제</span></h3>
                <ul>
                  <li>요금제 신청 후 가입시점부터 국제전화 00700 사용 시 매월 사용금액의 10%가 T플러스 포인트로 익월 적립됩니다.</li>
                  <li>신청 후 T멤버십 적립형 요금제가 적용되며 기존에 이용 중인 요금제는 자동해지됩니다.<br>타 00700 요금제로 변경 시 제휴 혜택은 자동 종료되며 변경 전까지 사용금액에 대해서만 적립해 드립니다.</li>
                </ul>
              </div>
            </div>

            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_tmn_12_20211122.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3><span class="highLight">T멤버십 할인형 요금제</span></h3>
                <ul>
                  <li>요금제 신청 후 가입시점부터 국제전화 00700 사용 시 매월 사용금액의 10%가 공제됩니다.</li>
                  <li>신청 후 T멤버십 할인형 요금제가 적용되며 기존에 이용 중인 요금제는 자동해지됩니다.<br>타 00700 요금제로 변경 시 제휴 혜택은 자동 종료되며 변경 전까지 사용금액에 대해서만 10% 할인 혜택이 적용됩니다.</li>
                </ul>
              </div>
            </div>

            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_tmn_13_20211122.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3><span class="highLight">공통 안내</span></h3>
                <ul>
                  <li>자세한 국가별 요금은 ‘이용안내’ 참고 부탁 드립니다.</li>
                  <li>이용과 관련해 문의 사항은 고객센터로 연락 부탁 드립니다.<br>고객센터: 1599-0070 (평일 오전 9시~오후 6시/주말, 공휴일: 휴무)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- // 제휴 혜택 -->

        <!-- 신청 안내 -->
        <div
          v-if="tabIndex == 2"
          class="tmContBox"
        >
          <div class="tmContTxt">
            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_tmn_21_20211122.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3>T멤버십 제휴 요금제 신청 방법</h3>
                <ul>
                  <li>T멤버십 웹사이트 혹은 모바일 앱에서 00700 검색 후 신청 가능하십니다.</li>
                  <li>휴대폰 번호 입력 후 SMS 인증이 필요하며, OTB번호를 입력하시면 고객님의 T멤버십 종류에 따라 자동으로 제휴 요금제가 선택됩니다.<br>이후 요금제 가입 버튼을 누르시면 신청이 최종 완료됩니다.</li>
                  <li>개인정보 수집 및 이용과  제3자 제공 동의 후 요금제 신청이 가능하시며 동의하지 않으실 경우 제휴 요금제 가입이 불가합니다. </li>
                  <li>본 제휴 혜택 이용 도중 T멤버십을 해지할 경우 해당 시점부터 혜택은 자동 지급 종료됩니다.</li>
                  <li>본 요금제 해지 시 해당 년도 재가입이 불가합니다. </li>
                </ul>
              </div>
            </div>
            <div class="tmC">
              <!-- <div class="tmIcon">
                <img
                  src="/content/images/icon_tmn_22_20211013.png"
                  alt=""
                >
              </div> -->
              <div class="tmCt">
                <h3>고객센터</h3>
                <ul>
                  <li>☎ 제휴 혜택 신청 및 해지 관련 문의 : <strong>1599-0070</strong></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- // 신청 안내 -->


        <!-- 이용 안내 -->
        <div
          v-if="tabIndex == 3"
          class="tmContBox"
        >
          <div class="tmContTxt">
            <a
              id="opTmNation"
              @click="popup.price.isVisible = true"
            >국가별 요금</a>
            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_tmn_31_20211122.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3>T멤버십 제휴요금제 혜택 이용 안내</h3>
                <h3><span class="highLight">T멤버십 적립형 요금제</span></h3>
                <ul>
                  <li>요금제 신청 후 전월 21일~당월 20일(국제전화 과금주기)까지 00700 사용 시 익월 초에 T플러스 포인트로 적립됩니다.<br>(ex. 12월 국제전화 사용 시 1월 초 T플러스 포인트 적립)</li>
                  <li>화상, 위성 등 특수대역대 통화는 적립 대상에서 제외됩니다.</li>
                </ul>

                <h3><span class="highLight">T멤버십 할인형 요금제</span></h3>
                <ul>
                  <li>요금제 신청 후 전월 21일~당월 20일(국제전화 과금주기)까지 00700 사용 시 사용금액의 10%가 자동 공제되어 청구됩니다.<br>(ex. 12월 국제전화 1만원 사용 시 9천원 청구/부가세 별도)</li>
                  <li>화상, 위성 등 특수대역대 통화는 할인 대상에서 제외됩니다.</li>
                </ul>
              </div>
            </div>

            <div class="tmC">
              <div class="tmIcon">
                <img
                  src="/content/images/icon_tmn_32.png"
                  alt=""
                >
              </div>
              <div class="tmCt">
                <h3>이용 유의사항</h3>
                <ul>
                  <li>본 제휴 혜택은 SKT T멤버십 제휴 상품이므로 통신사가 변경된 경우 제휴 요금제 이용이 불가합니다.</li>
                  <li>휴대폰번호 변경 시 제휴 요금제 혜택 적용이 불가하므로, 변경된 번호에 대해서 T멤버십에서 제휴요금제 재신청 부탁 드리겠습니다.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- // 이용 안내 -->
      </div>
    </div>

    <!-- 국가별 요금 안내 팝업-->
    <div
      v-if="popup.price.isVisible" 
      id="tmNationBg"
    />
    <div
      v-if="popup.price.isVisible"
      id="tmNation"
    >
      <!-- ro개발 / 국가별 요금 팝업 style="display:block" -->
      <h1>국가별 요금 안내</h1>
      <div class="tmNCont">
        <h2>· 주요국가<span>(부가세 포함)</span></h2>
        <div class="tmTblW">
          <div class="tmTbl">
            <table>
              <caption />
              <colgroup>
                <col style="width:60%">
                <col style="width:40%">
              </colgroup>
              <thead>
                <tr><th>국가</th><th>요금</th></tr>
              </thead>
              <tbody>
                <tr><td>미국</td><td>990원/분</td></tr>
                <tr><td>중국</td><td>1,458.6원/분</td></tr>
                <tr><td>호주</td><td>1,229.58원/분</td></tr>
              </tbody>
            </table>
          </div>
          <div class="tmTbl">
            <table>
              <caption />
              <colgroup>
                <col style="width:60%">
                <col style="width:40%">
              </colgroup>
              <thead>
                <tr><th>국가</th><th>요금</th></tr>
              </thead>
              <tbody>
                <tr><td>일본</td><td>990원/분</td></tr>
                <tr><td>캐나다</td><td>990원/분</td></tr>
              </tbody>
            </table>
          </div>
        </div>

        <h2>· 기타국가<span>(부가세 포함)</span></h2>
        <div class="tmTblW">
          <div class="tmTbl">
            <table>
              <caption />
              <colgroup>
                <col style="width:60%">
                <col style="width:40%">
              </colgroup>
              <thead>
                <tr>
                  <th>국가</th>
                  <th>요금</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>가나</td><td>1,229.58원/분</td></tr>
                <tr><td>가봉</td><td>1,229.58원/분</td></tr>
                <tr><td>가이아나</td><td>1,229.58원/분</td></tr>
                <tr><td>감비아</td><td>1,229.58원/분</td></tr>
                <tr><td>과달로프</td><td>1,229.58원/분</td></tr>
                <tr><td>과테말라</td><td>1,229.58원/분</td></tr>
                <tr><td>괌</td><td>1,229.58원/분</td></tr>
                <tr><td>그레나다</td><td>1,229.58원/분</td></tr>
                <tr><td>그리스</td><td>1,229.58원/분</td></tr>
                <tr><td>그린랜드</td><td>1,229.58원/분</td></tr>
                <tr><td>기니</td><td>1,229.58원/분</td></tr>
                <tr><td>기니비사우</td><td>1,229.58원/분</td></tr>
                <tr><td>나미비아</td><td>1,229.58원/분</td></tr>
                <tr><td>나우루</td><td>1,229.58원/분</td></tr>
                <tr><td>나이지리아</td><td>1,229.58원/분</td></tr>
                <tr><td>남수단</td><td>1,229.58원/분</td></tr>
                <tr><td>남아프리카공화국</td><td>1,229.58원/분</td></tr>
                <tr><td>네덜란드</td><td>1,229.58원/분</td></tr>
                <tr><td>네덜란드령 안틸레스</td><td>1,229.58원/분</td></tr>
                <tr><td>네팔</td><td>1,458.6원/분</td></tr>
                <tr><td>노르웨이</td><td>1,229.58원/분</td></tr>
                <tr><td>노퍽섬</td><td>1,229.58원/분</td></tr>
                <tr><td>뉴질랜드</td><td>1,229.58원/분</td></tr>
                <tr><td>뉴칼레도니아</td><td>1,229.58원/분</td></tr>
                <tr><td>니우에</td><td>1,229.58원/분</td></tr>
                <tr><td>니제르</td><td>1,229.58원/분</td></tr>
                <tr><td>니카라과</td><td>1,229.58원/분</td></tr>
                <tr><td>대만</td><td>990원/분</td></tr>
                <tr><td>덴마크</td><td>1,229.58원/분</td></tr>
                <tr><td>도미니카공화국</td><td>1,229.58원/분</td></tr>
                <tr><td>도미니카연방</td><td>1,229.58원/분</td></tr>
                <tr><td>독일</td><td>1,229.58원/분</td></tr>
                <tr><td>동티모르</td><td>1,229.58원/분</td></tr>
                <tr><td>디에고 가르시아섬</td><td>1,229.58원/분</td></tr>
                <tr><td>라오스</td><td>1,229.58원/분</td></tr>
                <tr><td>라이베리아</td><td>1,229.58원/분</td></tr>
                <tr><td>라트비아</td><td>1,229.58원/분</td></tr>
                <tr><td>러시아</td><td>1,229.58원/분</td></tr>
                <tr><td>레바논</td><td>1,229.58원/분</td></tr>
                <tr><td>레소토</td><td>1,229.58원/분</td></tr>
                <tr><td>레위니옹섬</td><td>1,229.58원/분</td></tr>
                <tr><td>루마니아</td><td>1,229.58원/분</td></tr>
                <tr><td>룩셈부르크</td><td>1,229.58원/분</td></tr>
                <tr><td>르완다</td><td>1,229.58원/분</td></tr>
                <tr><td>리비아</td><td>1,229.58원/분</td></tr>
                <tr><td>리투아니아</td><td>1,229.58원/분</td></tr>
                <tr><td>리히텐슈타인</td><td>1,229.58원/분</td></tr>
                <tr><td>마다가스카르</td><td>1,229.58원/분</td></tr>
                <tr><td>마데이라</td><td>1,229.58원/분</td></tr>
                <tr><td>마르티니크</td><td>1,229.58원/분</td></tr>
                <tr><td>마셜제도</td><td>1,229.58원/분</td></tr>
                <tr><td>마요트</td><td>1,229.58원/분</td></tr>
                <tr><td>마카오</td><td>1,229.58원/분</td></tr>
                <tr><td>마케도니아</td><td>1,229.58원/분</td></tr>
                <tr><td>말라위</td><td>1,229.58원/분</td></tr>
                <tr><td>말레이시아</td><td>1,458.6원/분</td></tr>
                <tr><td>말리</td><td>1,229.58원/분</td></tr>
                <tr><td>멕시코</td><td>1,229.58원/분</td></tr>
                <tr><td>모나코</td><td>1,229.58원/분</td></tr>
                <tr><td>모로코</td><td>1,229.58원/분</td></tr>
                <tr><td>모리셔스</td><td>1,229.58원/분</td></tr>
                <tr><td>모리타니아</td><td>1,229.58원/분</td></tr>
                <tr><td>모잠비크</td><td>1,229.58원/분</td></tr>
                <tr><td>몬테네그로</td><td>1,229.58원/분</td></tr>
                <tr><td>몬트세라트</td><td>1,229.58원/분</td></tr>
                <tr><td>몰도바</td><td>1,229.58원/분</td></tr>
                <tr><td>몰디브</td><td>1,229.58원/분</td></tr>
                <tr><td>몰타</td><td>1,229.58원/분</td></tr>
                <tr><td>몽골</td><td>1,458.6원/분</td></tr>
                <tr><td>미얀마</td><td>1,458.6원/분</td></tr>
                <tr><td>미크로네시아</td><td>1,229.58원/분</td></tr>
                <tr><td>바누아투</td><td>1,229.58원/분</td></tr>
                <tr><td>바레인</td><td>1,229.58원/분</td></tr>
                <tr><td>바베이도스</td><td>1,229.58원/분</td></tr>
                <tr><td>바티칸시국</td><td>1,229.58원/분</td></tr>
                <tr><td>바하마</td><td>1,229.58원/분</td></tr>
                <tr><td>발레아릭제도</td><td>1,229.58원/분</td></tr>
                <tr><td>방글라데시</td><td>1,458.6원/분</td></tr>
                <tr><td>버뮤다제도</td><td>1,229.58원/분</td></tr>
                <tr><td>버진군도(미국령)</td><td>1,229.58원/분</td></tr>
                <tr><td>버진군도(영국령)</td><td>1,229.58원/분</td></tr>
                <tr><td>베냉</td><td>1,229.58원/분</td></tr>
                <tr><td>베네수엘라</td><td>1,229.58원/분</td></tr>
                <tr><td>베트남</td><td>1,458.6원/분</td></tr>
                <tr><td>벨기에</td><td>1,229.58원/분</td></tr>
                <tr><td>벨라루스</td><td>1,229.58원/분</td></tr>
                <tr><td>벨리즈</td><td>1,229.58원/분</td></tr>
                <tr><td>보스니아 헤르체코비나</td><td>1,229.58원/분</td></tr>
                <tr><td>보츠와나</td><td>1,229.58원/분</td></tr>
                <tr><td>볼리비아</td><td>1,229.58원/분</td></tr>
                <tr><td>부룬디</td><td>1,229.58원/분</td></tr>
                <tr><td>부르키나파소</td><td>1,229.58원/분</td></tr>
                <tr><td>부탄</td><td>1,229.58원/분</td></tr>
                <tr><td>불가리아</td><td>1,229.58원/분</td></tr>
                <tr><td>브라질</td><td>1,229.58원/분</td></tr>
                <tr><td>브루나이</td><td>1,229.58원/분</td></tr>
                <tr><td>사모아(미국령)</td><td>1,229.58원/분</td></tr>
                <tr><td>사우디아라비아</td><td>1,458.6원/분</td></tr>
                <tr><td>사이판</td><td>1,229.58원/분</td></tr>
                <tr><td>산마리노</td><td>1,229.58원/분</td></tr>
                <tr><td>상투메 프린시페</td><td>1,229.58원/분</td></tr>
                <tr><td>생피에르 미클롱</td><td>1,229.58원/분</td></tr>
                <tr><td>서사모아</td><td>1,229.58원/분</td></tr>
                <tr><td>서사하라</td><td>1,229.58원/분</td></tr>
                <tr><td>세네갈</td><td>1,229.58원/분</td></tr>
                <tr><td>세르비아</td><td>1,229.58원/분</td></tr>
                <tr><td>세우타</td><td>1,229.58원/분</td></tr>
                <tr><td>세이셸</td><td>1,229.58원/분</td></tr>
                <tr><td>세인트루시아</td><td>1,229.58원/분</td></tr>
                <tr><td>세인트빈센트 그레나딘</td><td>1,229.58원/분</td></tr>
                <tr><td>세인트키츠 네비스</td><td>1,229.58원/분</td></tr>
                <tr><td>세인트헬레나</td><td>1,229.58원/분</td></tr>
                <tr><td>소말리아</td><td>1,229.58원/분</td></tr>
                <tr><td>솔로몬제도</td><td>1,229.58원/분</td></tr>
                <tr><td>수단</td><td>1,229.58원/분</td></tr>
                <tr><td>수리남</td><td>1,229.58원/분</td></tr>
                <tr><td>스리랑카</td><td>1,458.6원/분</td></tr>
              </tbody>
            </table>
          </div>
          <div class="tmTbl">
            <table>
              <caption />
              <colgroup>
                <col style="width:60%">
                <col style="width:40%">
              </colgroup>
              <thead>
                <tr>
                  <th>국가</th>
                  <th>요금</th>
                </tr>
              </thead>
              <tbody>
                <tr><td>스와질랜드</td><td>1,229.58원/분</td></tr>
                <tr><td>스웨덴</td><td>1,229.58원/분</td></tr>
                <tr><td>스위스</td><td>1,229.58원/분</td></tr>
                <tr><td>스페인</td><td>1,229.58원/분</td></tr>
                <tr><td>슬로바키아</td><td>1,229.58원/분</td></tr>
                <tr><td>슬로베니아</td><td>1,229.58원/분</td></tr>
                <tr><td>시리아</td><td>1,229.58원/분</td></tr>
                <tr><td>시에라리온</td><td>1,229.58원/분</td></tr>
                <tr><td>싱가포르</td><td>990원/분</td></tr>
                <tr><td>아랍에미리트</td><td>1,229.58원/분</td></tr>
                <tr><td>아루바</td><td>1,229.58원/분</td></tr>
                <tr><td>아르메니아</td><td>1,229.58원/분</td></tr>
                <tr><td>아르헨티나</td><td>1,229.58원/분</td></tr>
                <tr><td>아이슬란드</td><td>1,229.58원/분</td></tr>
                <tr><td>아이티</td><td>1,229.58원/분</td></tr>
                <tr><td>아일랜드</td><td>1,229.58원/분</td></tr>
                <tr><td>아제르바이잔</td><td>1,229.58원/분</td></tr>
                <tr><td>아프가니스탄</td><td>1,229.58원/분</td></tr>
                <tr><td>안도라</td><td>1,229.58원/분</td></tr>
                <tr><td>안타티카</td><td>1,229.58원/분</td></tr>
                <tr><td>알래스카</td><td>990원/분</td></tr>
                <tr><td>알바니아</td><td>1,229.58원/분</td></tr>
                <tr><td>알제리</td><td>1,229.58원/분</td></tr>
                <tr><td>앙골라</td><td>1,229.58원/분</td></tr>
                <tr><td>앤티가 바부다</td><td>1,229.58원/분</td></tr>
                <tr><td>앵귈라</td><td>1,229.58원/분</td></tr>
                <tr><td>어센션섬</td><td>1,229.58원/분</td></tr>
                <tr><td>에리트레아</td><td>1,229.58원/분</td></tr>
                <tr><td>에스토니아</td><td>1,229.58원/분</td></tr>
                <tr><td>에콰도르</td><td>1,229.58원/분</td></tr>
                <tr><td>에티오피아</td><td>1,229.58원/분</td></tr>
                <tr><td>엘살바도르</td><td>1,229.58원/분</td></tr>
                <tr><td>영국</td><td>1,229.58원/분</td></tr>
                <tr><td>예멘</td><td>1,229.58원/분</td></tr>
                <tr><td>오만</td><td>1,229.58원/분</td></tr>
                <tr><td>오스트리아</td><td>1,229.58원/분</td></tr>
                <tr><td>온두라스</td><td>1,229.58원/분</td></tr>
                <tr><td>왈리스 퓌튀나</td><td>1,229.58원/분</td></tr>
                <tr><td>요르단</td><td>1,229.58원/분</td></tr>
                <tr><td>우간다</td><td>1,229.58원/분</td></tr>
                <tr><td>우루과이</td><td>1,229.58원/분</td></tr>
                <tr><td>우즈베키스탄</td><td>1,458.6원/분</td></tr>
                <tr><td>우크라이나</td><td>1,229.58원/분</td></tr>
                <tr><td>이라크</td><td>1,229.58원/분</td></tr>
                <tr><td>이란</td><td>1,229.58원/분</td></tr>
                <tr><td>이스라엘</td><td>1,229.58원/분</td></tr>
                <tr><td>이집트</td><td>1,229.58원/분</td></tr>
                <tr><td>이탈리아</td><td>1,229.58원/분</td></tr>
                <tr><td>인도</td><td>1,458.6원/분</td></tr>
                <tr><td>인도네시아</td><td>1,458.6원/분</td></tr>
                <tr><td>자메이카</td><td>1,229.58원/분</td></tr>
                <tr><td>잔지바르</td><td>1,229.58원/분</td></tr>
                <tr><td>잠비아</td><td>1,229.58원/분</td></tr>
                <tr><td>적도기니</td><td>1,229.58원/분</td></tr>
                <tr><td>조지아</td><td>1,229.58원/분</td></tr>
                <tr><td>중앙아프리카</td><td>1,229.58원/분</td></tr>
                <tr><td>지부티</td><td>1,229.58원/분</td></tr>
                <tr><td>지브롤터</td><td>1,229.58원/분</td></tr>
                <tr><td>짐바브웨</td><td>1,229.58원/분</td></tr>
                <tr><td>차드</td><td>1,229.58원/분</td></tr>
                <tr><td>체코</td><td>1,229.58원/분</td></tr>
                <tr><td>칠레</td><td>1,229.58원/분</td></tr>
                <tr><td>카나리아제도</td><td>1,229.58원/분</td></tr>
                <tr><td>카메룬</td><td>1,229.58원/분</td></tr>
                <tr><td>카보베르데</td><td>1,229.58원/분</td></tr>
                <tr><td>카자흐스탄</td><td>1,458.6원/분</td></tr>
                <tr><td>카타르</td><td>1,229.58원/분</td></tr>
                <tr><td>캄보디아</td><td>1,458.6원/분</td></tr>
                <tr><td>케냐</td><td>1,229.58원/분</td></tr>
                <tr><td>케이맨제도</td><td>1,229.58원/분</td></tr>
                <tr><td>코모로</td><td>1,229.58원/분</td></tr>
                <tr><td>코소보</td><td>1,229.58원/분</td></tr>
                <tr><td>코스타리카</td><td>1,229.58원/분</td></tr>
                <tr><td>코트디부아르</td><td>1,229.58원/분</td></tr>
                <tr><td>콜롬비아</td><td>1,229.58원/분</td></tr>
                <tr><td>콩고</td><td>1,229.58원/분</td></tr>
                <tr><td>쿠바</td><td>1,229.58원/분</td></tr>
                <tr><td>쿠웨이트</td><td>1,229.58원/분</td></tr>
                <tr><td>쿡제도</td><td>1,229.58원/분</td></tr>
                <tr><td>크로아티아</td><td>1,229.58원/분</td></tr>
                <tr><td>크리스마스섬</td><td>1,229.58원/분</td></tr>
                <tr><td>키르기스스탄</td><td>1,229.58원/분</td></tr>
                <tr><td>키리바시</td><td>1,229.58원/분</td></tr>
                <tr><td>키프로스</td><td>1,229.58원/분</td></tr>
                <tr><td>타지키스탄</td><td>1,229.58원/분</td></tr>
                <tr><td>타히티</td><td>1,229.58원/분</td></tr>
                <tr><td>탄자니아</td><td>1,229.58원/분</td></tr>
                <tr><td>태국</td><td>1,458.6원/분</td></tr>
                <tr><td>터크스 케이커스제도</td><td>1,229.58원/분</td></tr>
                <tr><td>터키</td><td>1,229.58원/분</td></tr>
                <tr><td>토고</td><td>1,229.58원/분</td></tr>
                <tr><td>토켈라우제도</td><td>1,229.58원/분</td></tr>
                <tr><td>통가</td><td>1,229.58원/분</td></tr>
                <tr><td>투르크메니스탄</td><td>1,229.58원/분</td></tr>
                <tr><td>투발루</td><td>1,229.58원/분</td></tr>
                <tr><td>튀니지</td><td>1,229.58원/분</td></tr>
                <tr><td>트리니다드 토바고</td><td>1,229.58원/분</td></tr>
                <tr><td>파나마</td><td>1,229.58원/분</td></tr>
                <tr><td>파라과이</td><td>1,229.58원/분</td></tr>
                <tr><td>파키스탄</td><td>1,458.6원/분</td></tr>
                <tr><td>파푸아뉴기니</td><td>1,229.58원/분</td></tr>
                <tr><td>팔라우</td><td>1,229.58원/분</td></tr>
                <tr><td>팔레스타인</td><td>1,229.58원/분</td></tr>
                <tr><td>페로 제도</td><td>1,229.58원/분</td></tr>
                <tr><td>페루</td><td>1,229.58원/분</td></tr>
                <tr><td>포르투갈</td><td>1,229.58원/분</td></tr>
                <tr><td>포클랜드제도</td><td>1,229.58원/분</td></tr>
                <tr><td>폴란드</td><td>1,229.58원/분</td></tr>
                <tr><td>푸에르토리코</td><td>1,229.58원/분</td></tr>
                <tr><td>프랑스</td><td>1,229.58원/분</td></tr>
                <tr><td>프랑스령 기아나</td><td>1,229.58원/분</td></tr>
                <tr><td>피지</td><td>1,229.58원/분</td></tr>
                <tr><td>핀란드</td><td>1,229.58원/분</td></tr>
                <tr><td>필리핀</td><td>1,458.6원/분</td></tr>
                <tr><td>하와이</td><td>990원/분</td></tr>
                <tr><td>헝가리</td><td>1,229.58원/분</td></tr>
                <tr><td>홍콩</td><td>990원/분</td></tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <a
        class="tmNClose"
        href="#none"
        @click="popup.price.isVisible = false"
      >
        <img
          src="/content/images/btn_tm_n_close.png"
          alt="닫기"
        >
      </a>
    </div>

    <!-- 신규가입 -->
    <tmCheck 
      v-if="popup.tmCheck.isVisible"
      @click="popup.tmCheck.isVisible = false"
    />
    <!-- 해지 -->
    <tmCancel
      v-if="popup.tmCancel.isVisible"
      @click="popup.tmCancel.isVisible = false"
    />
  </div>
</template>

<script>
import tmCheck from '../partnership/TmembershipNewCheck'
import tmCancel from '../partnership/TmembershipNewCancel'

export default {
  name: 'TmembershipNew',
  components: {
    tmCheck,
    tmCancel
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:'1',
      popup: {
        price: {
          isVisible: false
        },
        tmCheck: {
          isVisible: false
        },
        tmCancel: {
          isVisible: false
        }
      }
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    tabActive(num){
      this.tabIndex = num
    }
  }
}
</script>

<style lang="scss" scoped>
#tmNation, #tmNationBg {
  display: block;
}
.tmemberWrap .tmHeader .tmPopLink {
  bottom: 16px;
}
@media (max-width: 1023px) {
  .tmemberWrap .tmHeader {
    padding: 30px 10px 20px;
  }
}
.tmemberWrap .tmContent .tmTab ul li:first-child { line-height: 76px; }
.tmemberWrap .tmContent .tmContBox{
  display: block;
}
.tmemberWrap .tmContent .tmContBox .tmContTxt a#opTmNation {
  display: block;
  width: 100%;
  height: 84px;
  margin: 0 0 40px;
  line-height: 84px;
  background: #222;
  font-size: 40px;
  color: #f7f7f7;
  letter-spacing: -.06em;
  text-align: center;
  border-radius: 14px;
}
@media (max-width: 1023px) {
  .tmemberWrap .tmContent .tmTab ul li:first-child { line-height: 38px; }
  .tmemberWrap .tmContent .tmContBox .tmContTxt a#opTmNation {
    height: 42px;
    margin: 0 0 20px;
    font-size: 20px;
    line-height: 42px;
    border-radius: 7px;
  }
}


.tmemberWrap .tmContent .tmContBox .tmContTxt .tmMsg {
  margin-bottom: 30px;
  color: #000;
  font-size: 36px;
  font-weight: 600;
  line-height: 1.35;
}
.tmemberWrap .tmContent .tmContBox .tmContTxt .tmC .tmCt h3 > span.highLight {
  position: relative;
  margin-left: 5px;
  padding: 0 10px;
  font-size: 32px;
  font-weight: inherit;
  &:before {
    content: '';
    position: absolute;
    bottom: 0; z-index: -1;
    width: 100%; height: 22px;
    margin-left: -5px;
    background-color: #adfaff;
  }
}
@media (max-width:1023px) {
  .tmemberWrap .tmContent .tmContBox .tmContTxt .tmMsg {
    margin-bottom: 15px;
    font-size: 18px;
  }
  .tmemberWrap .tmContent .tmContBox .tmContTxt .tmC .tmCt h3 > span.highLight {
    margin-left: 5px;
    padding: 0 4px;
    font-size: 16px;
    &:before {
      height: 11px;
      margin-left: -2.5px;
    }
  }
}
</style>
