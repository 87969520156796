
<template>
  <div id="TMEMBERSHIP_BASKIN">
    <div class="event_wrap tbr">
      <div class="event_view">
        <div>
          <span class="#ff4552">
            <img
              src="/content/images/partnership/220315_tmembership_baskin_1.png"
              alt="T Membership x SK국제전화 00700 배스킨라빈스 이벤트"
              @load="onLoad"  
            ><img
              src="/content/images/partnership/220316_tmembership_baskin_2.png"
              alt="이용방법 STEP 1 OO700 앱 다운로드, STEP 2 00700 국제전화 사용, STEP 3 기프티콘 받기"
            >
          </span>
          <div class="event_view_field">
            <div class="event_content">
              <div class="event_box">
                <div class="event_header">
                  <h1>이벤트 참여하기</h1>
                </div>
                <div
                  class="agree_box"
                >
                  <div
                    class="agree_wrap"
                  > 
                    <div>
                      <input
                        id="yes01"
                        v-model="agreePrivacy01"
                        class="myClass YNchk"
                        type="checkbox"
                        value="yes01"
                      ><label for="yes01">
                        <span>
                          혜택 제공을 위한 개인정보 수집, 활용 및 취급 위탁에 동의합니다.    
                        </span>
                      </label>
                    </div>
                    <div>
                      <input
                        id="yes02"
                        v-model="agreePrivacy02"
                        class="myClass YNchk"
                        type="checkbox"
                        value="yes02"
                      ><label for="yes02">
                        <span>
                          혜택 제공을 위하여 SK텔레콤과 SK텔링크간 고객 정보 확인에 동의합니다. 
                        </span>
                      </label>
                    </div>
                  </div>
                  <div class="event_more_btn btn_st">
                    <a
                      @click="isVisiblePrivacyPopup = true"
                    >
                      자세히 보기
                    </a>
                  </div>
                </div>
                <div class="simple_type">
                  <dl>
                    <dt>휴대폰번호</dt>
                    <dd>
                      <div class="insert_phone">
                        <select
                          id="call_select01"
                          ref="telNumberFirst"
                          v-model="telNumber.first"
                          name="i_sHpNo1"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                        <span class="txt_hyphen" />
                        <input
                          ref="telNumberMiddle"
                          v-model="telNumber.middle"
                          name="i_sHpNo2"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen" />
                        <input
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <button
                        type="button"
                        class="btn btn_cert"
                        @click="requestSMSCert"
                      >
                        SMS 인증
                      </button>
                    </dd>
                  </dl>
                  <dl
                    v-if="authNumberInputVisible"
                    id="numberCheck"
                    class="C030 duplPhono"
                  >
                    <dt>인증번호 입력</dt>
                    <dd>
                      <div class="insert_certNum">
                        <input
                          ref="certNumber"
                          v-model="auth_no"
                          name="i_sCertificationNo"
                          title="인증번호"
                          type="text"
                          maxlength="6"
                          pattern="[0-9]*"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <button
                        class="btn btn_cert"
                        @click="requestCert"
                      >
                        인증번호확인
                      </button>
                    </dd>
                  </dl>
                </div>        
                <div class="download_btn btn_st">
                  <a
                    id="btnApply"
                    onclick="gtag('event','응모완료',{'event_category':'이벤트'});"
                    @click="registrationFinal"
                  >
                    앱 다운로드 URL 문자로 받기
                  </a>
                </div>
              </div>
            </div>
          </div>
          <span class="img_wrap">
            <img
              src="/content/images/partnership/220315_tmembership_baskin_3.png"
              alt="T Membership x SK국제전화 00700 배스킨라빈스 이벤트 안내"
            >
          </span>
          <div class="event_caution">
            <h1>유의사항</h1>
            <ul>
              <li>이 이벤트는 1회만 참여하실 수 있습니다.<br>(중복 참여 안 됨)</li>
              <li>이벤트 참여 시 00700 앱을 다운로드하실 때 등록한<br class="br">휴대폰 번호로 모바일 쿠폰을 보내 드립니다.</li>
              <li class="lt">
                경품으로 받은 모바일 쿠폰은 기간을 연장하실 수 없습니다.
              </li>
              <li>비정상적이거나 불법적인 방법으로 이벤트에 참여하신<br class="br"> 경우 이벤트 대상이 아니며, 혜택이 제공되지 않습니다.</li>
              <li>이벤트 참여 완료 시 개인정보 수집, 활용 및 취급·위탁에<br class="br"> 대해 동의하신 것으로 간주됩니다.</li>
              <li>이 이벤트 경품은 제휴사 사정에 따라 안내 없이 상품이<br class="br"> 변경되거나 대체될 수 있습니다.</li>
              <li>문의 : SK국제전화 00700 고객센터<br>(1599-0070, 평일 오전 9시 ~ 오후 6시)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757 w650"
      style="display:block"
    >
      <div class="medium">
        <h3>개인 정보 수집, 이용 동의</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인 정보 수집, 이용 동의
          </h4>
          <div class="cont_term">
            <ul class="txt_point01">
              <li>1. 수집/이용 목적 : T멤버십 고객 대상 기프티콘 제공</li>
              <li>2. 수집/이용 항목 : 이동전화번호</li>
              <li>3. 보유/이용기간 : 이벤트 종료 60일까지</li>
              <li>4. 제공받는 자 : SK텔레콤</li>
              <li>5. 제공받는 목적 : T멤버십 가입여부 확인</li>
              <li>6. 제공받는 항목 : 이동전화번호</li>
              <li>7. 제공받는 자의 보유/이용기간 : 이벤트 종료 10일까지</li>
            </ul>
            <p class="bullet">
              위 개인정보 수집/이용에 동의하지 않으실 경우,<br> 해당서비스를 이용하실 수 없습니다.
            </p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    <!-- //팝업 -->
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  name: 'TmembershipBaskin',
  components: {
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    isVisiblePrivacyPopup: false,
    agreePrivacy01: false,
    agreePrivacy02: false,
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    telNumber:{
      first:'010',
      middle: '',
      last:''
    },
    authNumberInputVisible: false,
    ticket: '',
    event_no:'EV000000000000000005',
    auth_no: '',
  }
 },
 computed: {
  svc_tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  callbackAlert(){
    return this.$store.getters.CALLBACK_ALERT
  }
 },
 watch: {
  callbackAlert: {
    deep: true,
    handler() {
      if (this.callbackAlert.code === '0002' || this.callbackAlert.code === '0006' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E0027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E021' || this.callbackAlert.code === 'E022' || this.callbackAlert.code === 'E037') {
        this.resetPage()
        window.scrollTo(0, 0)
        document.getElementById('wrap').scrollTop = 0
      }
      if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
        this.authNumberInputVisible = false,
        this.telNumber = {
          first:'010',
          middle: '',
          last:''
        },
        this.auth_no = ''
        this.ticket = ''
      }
      if (this.callbackAlert.code === 'E001') { // 인증시간 초과
        this.auth_no = ''
        this.ticket = ''
      }
    }
  }
 },
 created () {},
 mounted () {},
 methods: {
  alertPopupHandler() {
    this.alertPopup.visible = false
    this.alertPopup.message = ''
    if (this.alertPopup.target) {
      this.$refs[this.alertPopup.target].focus()
    }
    this.alertPopup.target = ''
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
      return false
    } else {
      return true
    }
  },
  checkAuthNumber() {
    if (!this.auth_no) {
      this.alertPopup.target = 'certNumber'
      this.showAlertPopup('인증번호를 입력해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkSMSCert() {
    if(!this.isSmsCert) {
      this.showAlertPopup('SMS인증을 완료해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkselect01(){
    if(!this.agreePrivacy01){
      this.showAlertPopup('개인정보 수집에 동의하셔야 신청이 가능합니다.')
      return false
    }else {
      return true
    }
  },
  checkselect02(){
    if(!this.agreePrivacy02){
      this.showAlertPopup('개인정보 수집에 동의하셔야 신청이 가능합니다.')
      return false
    }else {
      return true
    }
  },
  // SMS 인증 요청
  requestSMSCert() {
    if (this.checkPhoneNumber()) {
      api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
      .then(response => {
        if (response.data.sms_ticket) {
          this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
          this.ticket = response.data.sms_ticket
          this.authNumberInputVisible = true
        }
      })
    }
  },
  // 인증 번호로 SMS 인증 확인
  requestCert() {
    if (this.checkAuthNumber()) {
      api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
      .then(response => {
        if (response.data.result_cd === '0001') {
          this.isSmsCert = true
          this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
        }
      })
    }
  },
  //신청완료
  registrationFinal() {
    if (this.checkselect01() && this.checkselect02() && this.checkPhoneNumber() && this.checkSMSCert()) {
      api.EventPostReqEntry({
        sms_ticket: this.ticket,
        event_no: this.event_no,
        svc_tel_no: this.svc_tel_no
      })
  //     // api.EventPostWelGift({ svc_tel_no: this.svc_tel_no })
  //     // .then(response => {
  //     //   if (response.data.result_cd === 'E037') {
  //     //     this.$store.commit('ON_ALERT_POPUP', { message: '죄송합니다. 고객님은 이벤트 참여 대상이 아니십니다.', type: 'alert', code: 'E037'})
  //     //   }
  //     //   else if(response.data.result_cd === '0002') {
  //     //     api.EventPostReqEntry({
  //     //         sms_ticket: this.ticket,
  //     //         event_no: this.event_no,
  //     //         svc_tel_no: this.svc_tel_no
  //     //       })
  //     //   }
  //     // })
      }
  },
  resetPage(){
    this.agreePrivacy = false,
    this.telNumber= {
      first:'010',
      middle: '',
      last:''
    },
    this.authNumberInputVisible = false,
    this.auth_no = ''
    this.ticket = ''
    this.isSmsCert = false
  },
  //20210917 이벤트 종료
  onLoad() {
    var popitem = document.getElementById("TMEMBERSHIP_BASKIN");

     if (new Date() >= new Date('04/15/2022 11:59:59')) {   //반영 시간 지정
        this.showAlertPopup('이벤트 기간이 종료되었습니다.<br>감사합니다.')
         popitem.classList.add("closeEvent");
    }
  }
 }
}
</script>

<style lang="scss" scoped>
.tbr .event_view {
    width: 720px;
    font-family: 'Noto Sans CJK KR';
}

.tbr {
  & > .event_view > div { text-align: left; }
  .event_view_field {
    margin-top: 0;
    padding: 50px;
    background-color: #ff4552;
    .event_box {
      width: 100%;
      padding: 50px 70px 80px;
      background: #fff;
      border-radius: 20px;
    }
    .event_header {
        h1 {
        color: #333;
        font-size: 42px;
        font-weight: 700;
        text-align: center;
        }
    }
    .event_content {
      .simple_type {
        dl {
          margin-top: 50px;
          dt {
            margin-bottom: 24px;
            color: #333;
            font-size: 30px;
            font-weight: 700;
            line-height: 1;
          }
          dd {
            &:after {
              content: ''; display: block; clear: both; overflow: hidden;
            }
          }
        }
        .insert_phone {
          float: left;
          width: 73%;
          height: 70px;
          & > * { vertical-align: middle; }
          select {
            width: 28%;
            height: 100%;
            padding-left: 12px;
            color: #333;
            font-family: 'Roboto';
            font-size: 26px;
            font-weight: 500;
            background: #fff url('/content/images/btn_arrow_selelct.png') no-repeat calc(100% - 12px) 50%/16px 9px;
            border: 2px solid #d2d2d2;
            border-radius: 12px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
          input[type=number], input[type=text] {
            width: 28%;
            height: 100%;
            padding: 0;
            color: #333;
            font-size: 26px;
            font-family: 'Roboto';
            font-weight: 500;
            line-height: 70px;
            text-align: center;
            background-color: #fff;
            border: 2px solid #d2d2d2;
            border-radius: 12px;
          }
          .txt_hyphen {
            display: inline-block;
            position: relative;
            width: 6%;
            height: 100%;
            text-align: center;
            line-height: 100%;
            &:before {
              content: '';
              position: absolute;
              top: 50%;
              left: 50%;
              width: 6px; height: 2px; 
              background-color: #333;
              margin: -1px 0 0 -4px;
            }
          }
        }
        .insert_certNum {
          float: left;
          width: 22.5%;
          height: 70px;
          margin-right: 3.75%;
          input[type=number], input[type=text] {
            width: 100%;
            height: 100%;
            padding: 0;
            color: #333;
            font-family: 'Roboto';
            font-size: 26px;
            font-weight: 500;
            line-height: 70px;
            text-align: center;
            background-color: #fff;
            border: 2px solid #d2d2d2;
            border-radius: 12px;
          }
          & + .btn.btn_cert {
            float: left;
            width: auto;
            height: 70px;
            padding: 0 20px;
            font-family: 'Noto Sans CJK KR';
          }
        }
        .btn.btn_cert {
          display: block;
          float: right;
          width: 27%;
          height: 70px;
          color: #fff;
          font-size: 26px;
          font-weight: 500;
          background: #cc1a28;
          border-radius: 12px;
          letter-spacing: -0.05em;
        }
      }

      .agree_box {
        display: block;
        margin-top: 40px;
        .agree_wrap {
          div {
            position: relative;
            + div {
              margin-top: 20px;
            }
          }
          input[type=checkbox] {
            position: absolute;
            left: 9px;
            top: 18px;
            z-index: -1;
            opacity: 0;
            &:checked+label:before {
              background-image: url('/content/images/partnership/icon_checkbox_tbr_checked.png');
            }
          }
          label {
            color: #333;
            font-size: 26px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: -0.05em;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            &:before {
              content: '';
              position: absolute;
              left: 0;
              top: 5px;
              width: 35px;
              height: 35px;
              background: url('/content/images/partnership/icon_checkbox_tbr.png') 0 0 /35px no-repeat;
              display: inline-block;
            }
          }
          span {
            display: inline-block;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            padding-left: 45px;
          }
          p {
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            text-align: left;
          }
        }
      }
      .btn_st {
        margin-top: 30px;
        a {
          display: block;
          width: 100%;
          padding: 34px 0;
          color: #fff;
          font-size: 30px;
          font-weight: 500;
          text-align: center;
          background-color: #0d0d0d;
          border-radius: 20px;
        }
      }
    }
  }
  .event_caution {
    background: #ff4552;
    padding: 50px 50px 100px;
    color: #fff;
    h1 {
      margin-bottom: 20px;
      font-size: 32px;
      font-weight: 700;
    }
    li {
      position: relative;
      padding-left: 30px;
      font-size: 26px;
      line-height: 1.4;
      &:before {
        position: absolute;
        content: "※";
        top: 0;
        left: 0;
      }
      + li {
        margin-top: 10px;
      }
      &.lt {
        letter-spacing: -0.05em;
      }
    }
  }
  .img_wrap {
    background: #ff4552;
  }
}

@media (max-width: 719px) {
  .tbr {
    .event_view {
      width: 100%;
      padding: 0;
      .event_box {
        padding: 25px 25px 40px;
      }
    }
    .event_view_field {
      padding: 25px;
      .event_header {
        margin-bottom: 20px;
        h1 {
          font-size: 20px;
        }
      }
      .event_content {
        .simple_type {
          dl {
            margin-top: 25px;
            dt {
              font-size: 15px;
              margin-bottom: 12px;
            }
          }
          .insert_phone {
            height: 40px;
            select, input[type=number], input[type=text] { font-size: 13px; font-family: 'Roboto';font-weight: 500;}
            select {
              background: #fff;
              padding: 0;
              text-align: center;
            }
            .txt_hyphen:before { width: 4px; height: 1px; margin-left: -2px; }
          }

          .insert_certNum {
            height: 40px;
            input[type=number], input[type=text] { font-size: 13px; line-height: 40px; font-family: 'Roboto';font-weight: 500;}
            & + .btn.btn_cert {
              height: 40px;
              padding: 0 10px;
            }
          }

          .btn.btn_cert {
            height: 40px;
            font-size: 13px;
            font-family: 'Noto Sans CJK KR';
          }
        }
        .agree_box {
          margin-top: 20px;
          
          .agree_wrap {
            div + div {
              margin-top: 10px;
            }
            input[type=checkbox] {
              left: 4px;
              top: 5px
            }
            input[type=checkbox]:checked+label:before {
              background-size: 19px
            }
            label {
              font-size: 13px;
              span {
                padding-left: 22px;
              }
              &:before {
                top: 3px;
                background-size: 19px;
              }
            }
          }
        }
        .btn_st  {
          height: auto;
          margin-top: 15px;
          a {
            height: auto;
            padding: 15px 30px;
            line-height: 1;
            font-size: 15px;
          }
        }
      }
    }
    .event_caution {
      padding: 30px;
      h1 {
        margin-bottom: 10px;
        font-size: 16px;
      }
      li {
        font-size: 15px;
        font-weight: inherit;
        padding: 0 0 0 20px;
        &:before {
          top: 0;
        }
        + li {
          margin-top: 5px;
        }
      }
      .br {display: none;}
    }
  }
}

// popup
.pop_wrap02.w650{
  width: 650px;
    .bullet{ 
      position: relative;
      padding-left: 17px;
      line-height: 20px;
      &:before {
        content: '※';
        display: inline-block;
        position: absolute;
        top: 1px;
        left: 0;
      }
    }
    br {
      display: none;
    }
}

@media (max-width: 719px) {
  .pop_wrap02.w650 { 
    width: 100%;
    .medium {
      padding-bottom: 20px;
    }
    .cont_term ul li {
      line-height: 20px;
    }
  }
}
</style>
