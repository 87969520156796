<template>
  <div id="footer" class="en">
    <div class="last">
      <div class="inner">
        <div class="footer_info">
          <div class="info">
            <h2>
              <img src="/content/images/footer_logo.png" alt="SK telink" />
            </h2>
            <div class="family_site">
              <!-- Start EngFamily Site 컴포넌트 -->
              <EngFamilySite
                :anchor-text="FamilySiteAnchor"
                :family-site-list="FamilySiteList"
              />
              <!-- End EngFamily Site 컴포넌트 -->
            </div>
            <div>
              <div class="contU">
                Contact Us (Business Proposal) :
                <a href="mailto:biz_proposal@sk.com">biz_proposal@sk.com</a>
              </div>
              <div>
                Mapo T-town Bldg, Mapo-daero 144,<br class="m_hide" />
                Mapo-gu, Seoul
                <dl>
                  <dt>CEO</dt>
                  <dd>Ha Seong-Ho</dd>
                </dl>
              </div>
              <div class="family_site_link_wrap">
                <a
                  href="https://ethics.sk.co.kr/eng"
                  class="family_site_link"
                  target="_blank"
                  >Ethics Counsel / Report your Concern</a
                >
              </div>
              <dl class="w_hide">
                <dt>Business registration number</dt>
                <dd>104-81-43391</dd>
              </dl>
            </div>
          </div>
        </div>
        <div class="banners">
          <img
            src="/content/images/footer_banner.png"
            alt="NCSI 국가고객만족도 1위, KS-SQI 한국서비스품질 지수 1위, KCSI 한국산업의 고객만족도1위, NBCI 국가브랜드 경쟁력지수 1위, KS-PBI 프리미엄 브랜드지수 1위 KNPS 고객이 가장 추천하는 기업지수 1위"
          />
        </div>
        <p>Copyright ⓒ <span>SK telink CO,LTD.</span> All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import EngFamilySite from "@/components/EngFamilySite";

export default {
  name: "EngFooter",
  components: {
    EngFamilySite,
  },
  data() {
    return {
      FamilySiteAnchor: "FAMILYSITE",
      FamilySiteList: [
        {
          text: "SK telecom",
          link: "http://www.sktelecom.com/",
          title: "SK telecom 새창열림",
        },
        {
          text: "SK innovation",
          link: "http://www.skinnovation.com",
          title: "SK innovation 새창열림",
        },
        {
          text: "SK networks",
          link: "https://www.sknetworks.co.kr/",
          title: "SK networks 새창열림",
        },
        {
          text: "SK hynix",
          link: "http://www.skhynix.com/",
          title: "SK hynix 새창열림",
        },
        {
          text: "SK chemicals",
          link: "https://www.skchemicals.com",
          title: "SK chemicals 새창열림",
        },
        { text: "SKC", link: "http://www.skc.kr/", title: "SKC 새창열림" },
        {
          text: "SK E&C",
          link: "http://www.skec.co.kr/",
          title: "SK E&C 새창열림",
        },
        {
          text: "SK shipping",
          link: "http://www.skshipping.com/",
          title: "SK shipping 새창열림",
        },
        {
          text: "SK securities",
          link: "https://www.sks.co.kr/main/index.cmd",
          title: "SK securities 새창열림",
        },
        {
          text: "SK E&S",
          link: "http://www.skens.com/",
          title: "SK E&S 새창열림",
        },
        {
          text: "SK gas",
          link: "https://www.skgas.co.kr/",
          title: "SK gas 새창열림",
        },
        {
          text: "SK energy",
          link: "http://www.skenergy.com/",
          title: "SK energy 새창열림",
        },
        {
          text: "SK global chemical",
          link: "http://www.skchem.com/",
          title: "SK global chemical 새창열림",
        },
        {
          text: "SK lubricants",
          link: "http://www.sklubricants.com/main.asp",
          title: "SK lubricants 새창열림",
        },
        {
          text: "SK planet",
          link: "http://www.skplanet.com/",
          title: "SK planet 새창열림",
        },
        {
          text: "SK broadband",
          link: "http://www.skbroadband.com/",
          title: "SK broadband 새창열림",
        },
        {
          text: "SK telesys",
          link: "http://www.sktelesys.com/",
          title: "SK telesys 새창열림",
        },
        {
          text: "SK communications",
          link: "http://www.skcomms.co.kr/",
          title: "SK communications 새창열림",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
