
<template>
  <div id="BAROCLUB_CHECK">
    <div class="event_wrap baroclub">
      <div class="event_view">
        <div>
          <img
            src="/content/images/partnership/220511_baroclub_img1.jpg"
            alt="00700 30분 무료통화 신청"
          >
          <div class="event_view_inner">
            <div class="ui-box">
              <div class="ui-box-inner">
                <dl class="ui-insert-form">
                  <dt>휴대폰 번호</dt>
                  <dd>
                    <div class="ui-row">
                      <div class="ui-col sel-box">
                        <select
                          ref="telNumberFirst"
                          v-model="telNumber.first"
                          name="i_sHpNo1"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                      </div>
                      <span class="ui-dash" />
                      <div class="ui-col">
                        <input 
                          ref="telNumberMiddle" 
                          v-model="telNumber.middle" 
                          name="i_sHpNo2" 
                          title="전화번호" 
                          type="text" 
                          maxlength="4" 
                          pattern="[0-9]*"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <span class="ui-dash" />
                      <div class="ui-col">
                        <input 
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3" 
                          title="전화번호" 
                          type="text" 
                          maxlength="4" 
                          pattern="[0-9]*" 
                          inputmode="numeric" 
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div> 
                    </div>
                    <div class="ui-btn-wrap">
                      <a
                        class="ui-btn"
                        @click="requestSMSCert"
                      ><img
                        src="/content/images/partnership/220511_baroclub_text1.png"
                        alt="SMS인증하기"
                      ></a>
                    </div>
                  </dd>
                </dl>
              </div>
            </div>

            <div class="ui-box">
              <div class="ui-box-inner">
                <dl class="ui-insert-form">
                  <dt>인증번호 입력</dt>
                  <dd>
                    <div class="ui-row">
                      <div class="ui-col w100p">
                        <input
                          id="AsianaMemberNo" 
                          ref="certNumber"
                          v-model="certNumber"
                          type="text"  
                          name="i_sAsianaMemberNo" 
                          title="인증번호 입력" 
                          maxlength="9" 
                          pattern="[0-9]*"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                    </div>
                    <div class="ui-btn-wrap">
                      <a
                        class="ui-btn"
                        @click="requestCert"
                      ><img
                        src="/content/images/partnership/220511_baroclub_text2.png"
                        alt="인증번호 확인"
                      ></a>
                    </div>
                  </dd>
                </dl>

                <!-- 개인정보 동의 -->
                <div class="ui-agree">
                  <div>
                    <input
                      id="c_a01"
                      v-model="checkbox.one"
                      type="checkbox"
                      @click="privacy1Popup"
                    > 
                    <label for="c_a01">개인정보 수집 및 이용에 동의합니다.</label>
                  </div>
                  <div>
                    <input
                      id="c_a02"
                      v-model="checkbox.two"
                      type="checkbox"
                      @click="privacy2Popup"
                    >
                    <label for="c_a02">개인정보 제3자 제공에 동의합니다.</label>
                  </div>
                </div>
                <!-- // 개인정보 동의 -->
              </div>
            </div>

            <div class="ui-text">
              00700 무료통화 신청 관련 자세한 문의사항은<br>
              SK국제전화 00700 고객센터(1599-0070)를 이용해주시길 바랍니다.
            </div>

            <div class="ui-btn-wrap">
              <a
                class="ui-img"
                @click="requestFinal"
              >
                <img
                  src="/content/images/partnership/220511_baroclubl_btn1.png"
                  alt="확인"
                >
              </a>
              <router-link
                class="ui-img"
                to="/partnership/BaroClub"
              >
                <img
                  src="/content/images/partnership/220511_baroclub_btn2.png"
                  alt="취소"
                >
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 팝업 -->
    <article
      v-if="isVisiblePrivacy1Popup"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-header">
          <h1>개인정보 수집 및 이용 동의(내용)</h1>
        </div>
        <div class="ui-popup-container">
          <ul>
            <li class="ui-dash">
              수집하는 개인정보의 항목: 전화번호
            </li>
            <li class="ui-dash">
              개인정보의 이용목적: 00700 무료통화 등록
            </li>
            <li class="ui-dash">
              보유 및 이용기간: <strong>무료통화 이용기간 후 6개월까지 보유</strong> (법령에 따로 정한 경우에는 해당 기간까지)
            </li>
            <li>※ 미동의 시 혜택 신청이 불가합니다.</li>
          </ul>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="isVisiblePrivacy1Popup = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </article>

    <!-- 팝업 -->
    <article
      v-if="isVisiblePrivacy2Popup"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-header">
          <h1>개인정보 제3자 제공 동의(내용)</h1>
        </div>
        <div class="ui-popup-container">
          <ul>
            <li class="ui-dash">
              제공받는자: <strong>SK텔레콤㈜</strong>
            </li>
            <li class="ui-dash">
              제공목적: <strong>SK텔레콤 T로밍 baro 요금제 혜택 대상 확인</strong>
            </li>
            <li class="ui-dash">
              제공항목: 전화번호
            </li>
            <li class="ui-dash">
              보유 및 이용기간: <strong>무료통화 이용기간 후 6개월까지 보유</strong> (법령에 따로 정한 경우에는 해당 기간까지)
            </li>
            <li>※ 미동의 시 혜택 신청이 불가합니다.</li>
          </ul>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="isVisiblePrivacy2Popup = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </article>
    
    <!-- 신청 완료 알럿 팝업 -->
    <div
      v-if="alertPopup1.visible"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <p class="ui-msg">
            신청이 완료되었습니다.<br>
            {{ applyNumber.end }}까지 00700 30분 무료통화<br> 이용이 가능합니다.<br>
            국제전화 00700 많은 이용 부탁 드립니다.
          </p>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="alertPopup1.visible = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 신청 불가 알럿 팝업 -->
    <div
      v-if="alertPopup2.visible"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <p class="ui-msg">
            사용 가능한 쿠폰이 존재하지 않습니다.<br>
            신청 대상 여부는 T로밍 나의 혜택 매뉴에서<br> 확인 가능합니다.
          </p>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="alertPopup2.visible = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </div>
    
    <!-- 중복 신청 알럿 팝업 -->
    <div
      v-if="alertPopup3.visible"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <p class="ui-msg">
            {{ applyNumber.start }}에 신청이 완료된 번호입니다.<br>
            {{ applyNumber.end }}까지 00700 30분 무료통화<br> 이용이 가능합니다.
          </p>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="alertPopup3.visible = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </div>

    <!-- 연동 오류 알럿 팝업 -->
    <div
      v-if="alertPopup4.visible"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <p class="ui-msg">
            신청에 오류가 있습니다.<br>
            고객센터로 문의 바랍니다.
          </p>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="alertPopup4.visible = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'

export default {
  name: 'BaroClubCheck',
  components: {
  },
  props: {},
  emits: [],
  data () {
    return {
      alertPopup1: {
        visible: false,
      },
      alertPopup2: {
        visible: false
      },
      alertPopup3: {
        visible: false
      },
      alertPopup4: {
        visible: false
      },
      telNumber: {
        first: '010'
      },
      certNumber: '',
      isVisiblePrivacy1Popup: false,
      isVisiblePrivacy2Popup: false,
      checkbox: {
        one: '',
        two: ''
      },
      ticket: '',
      applyNumber: {
        number: '',
        start: '',
        end: ''
      },
    }
  },
  computed: {
    tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if (this.callbackAlert.code === 'E027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code === 'E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E032' || this.callbackAlert.code === 'E033' || this.callbackAlert.code === 'E035' || this.callbackAlert.code === 'E036' || this.callbackAlert.code === 'E039' || this.callbackAlert.code === 'E040') { 
          this.resetPage()
        }
        if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
          this.telNumber = {
            first: '010',
            middle: '',
            last: ''
          },
          this.certNumber = '',
          this.ticket = ''
        }
        if (this.callbackAlert.code === 'E001') { // 인증시간 초과
          this.certNumber = ''
          this.ticket = ''
        }
      }
    }  
  },
  created () {},
  mounted () {
  },
  methods: {
      applyFreeAlready(data) {
      this.applyNumber.number = data.svc_tel_no
      this.applyNumber.start = this.$moment(data.use_strt_dt).format('YYYY년 MM월 DD일')
      this.applyNumber.end = this.$moment(data.use_end_dt).format('YYYY년 MM월 DD일')
      this.alertPopup3.visible = true
    },
      applyFreeSuccess(data) {
      this.applyNumber.number = data.svc_tel_no
      this.applyNumber.start = this.$moment(data.use_strt_dt).format('YYYY년 MM월 DD일')
      this.applyNumber.end = this.$moment(data.use_end_dt).format('YYYY년 MM월 DD일')
      this.alertPopup1.visible = true
    },
    privacy1Popup() {
      if(!this.checkbox.one) {
        this.isVisiblePrivacy1Popup = true
      }
    },
    privacy2Popup() {
      if(!this.checkbox.two) {
        this.isVisiblePrivacy2Popup = true
      }
    },
    close(){
      this.$emit('click')
    },
    checkPhoneNumber() {
      if (!this.telNumber.middle) {
        this.$refs.telNumberMiddle.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.last) {
        this.$refs.telNumberLast.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        alert('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else {
        return true
      }
    },
    checkAuthNumber() {
      if (!this.certNumber) {
        this.$refs.certNumber.focus()
        alert('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkCheckbox() {
      if(!this.checkbox.one) {
        alert('개인정보 수집 이용에 동의해주세요.')
        return false
      } else if(!this.checkbox.two) {
        alert('개인정보 제3자 제공에 동의해주세요.')
        return false
      } else {
        return true
      }
    },
    // SMS인증하기
    requestSMSCert() {
      if (this.checkPhoneNumber()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.tel_no })
          .then(response => {
            if (response.data.sms_ticket) {
              alert('인증번호가 정상적으로 발송되었습니다.')
              this.ticket = response.data.sms_ticket
            }
          })
      }
    },
    // 인증번호 확인
    requestCert() {
      if (this.checkAuthNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.certNumber, sms_ticket: this.ticket })
          .then(response => {
            if (response.data.result_cd === '0001') {
              alert('인증번호가 확인되었습니다. 감사합니다.')
            }
          })
      }
    },
    // 확인 버튼
    requestFinal() {
      if (this.checkPhoneNumber() && this.checkAuthNumber() && this.checkCheckbox()) {
          api.ItsvcPostApplyBaroclubFreeDedt({ sms_ticket: this.ticket })
            .then(response => {
              if (response.data.result_cd === '0004') {
                this.applyFreeSuccess(response.data)
              } else if (response.data.result_cd === 'E025') {
                this.applyFreeAlready(response.data)
              } else if (response.data.result_cd === 'E041') {
                  this.alertPopup4.visible = true
              } else if (response.data.result_cd === 'E042') {
                  this.alertPopup2.visible = true
              } else if (response.data.result_cd === 'E043') {
                  this.alertPopup4.visible = true
              } else if (response.data.result_cd === 'E044') {
                  this.alertPopup4.visible = true
              }
              this.resetPage()
            })
      }
    },
    resetPage(){
      this.telNumber = {
        first: '010',
        middle: '',
        last: ''
      },
      this.certNumber = '',
      this.checkbox = {
        one: false,
        two: false
      },
      this.ticket = ''
    },
  }
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px #fff inset;
}
.event_view {
  & > div {
    padding-bottom: 120px;
    background-color: #2255c8;
  }
  .event_view_inner {
    padding: 0 80px;
    text-align: left;
  }
}
.ui-box {
  margin-top: 80px;
  & + .ui-box { margin-top: 50px; }
  .ui-box-inner {
    position: relative;
    z-index: 1;
    padding: 70px;
    background-color: #fff;
    border: 3px solid #222222;
    border-radius: 50px;
  }
  &:after {
    content: '';
    display: block;
    position: relative;
    height: 60px;
    margin-top: -37px;
    background-color: #16a7e6;
    border: 3px solid #222222;
    border-radius: 0 0 50px 50px;
  }
}
.ui-insert-form {
  & > dt {
    color: #222;
    font-family: 'NanumSquareRound',sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: normal;
  }
  & > dd {
    margin-top: 25px;
    .ui-row {
      height: 112px;
    }
    .ui-col {
      display: inline-block;
      width: calc((100% - 108px) / 3);
      padding: 0 20px;
      border: 3px solid #222;
      border-radius: 20px;
      input[type=text] {
        width: 100%;
        height: 106px;
        color: #222;
        font-family: 'NanumSquareRound',sans-serif;
        font-size: 42px;
        font-weight: 700;
        line-height: normal;
        background-color: transparent;
      }
    }
    .w100p { width: 100%; }
    .sel-box {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 25px;
        width: 33px;
        height: 19px;
        margin-top: -10px;
        background: url('/content/images/partnership/220511_baroclub_select.png') no-repeat;
        background-size: 100% auto;
      }
      select {
        width: 100%;
        height: 106px;
        color: #222;
        font-family: 'NanumSquareRound',sans-serif;
        font-size: 42px;
        font-weight: 700;
        line-height: normal;
        background-color: transparent;
        -webkit-appearance: none;
        border-radius: 0;
      }
    }
    .ui-dash {
      display: inline-block;
      position: relative;
      width: 54px;
      height: 100%;
      font-size: 0;
      line-height: 0;
      vertical-align: top;
      &:after {
        content: '';
        position: absolute;
        left: 50%; top: 50%;
        width: 15px;
        height: 3px;
        margin: -1px 0 0 -7px;
        background-color: #222;
      }
    }
  }
  .ui-btn-wrap { margin-top: 50px; }
}
.ui-btn-wrap {
  display: flex;
  justify-content: space-between;
  margin-top: 99px;
  .ui-btn {
    display: block;
    width: 100%;
    padding: 36px 0;
    text-align: center;
    background-color: #ffc821;
    border-radius: 20px;
    img { width: auto !important; }
  }
}
.ui-agree {
  margin-top: 33px;
  & > div {
    padding: 17px 0;
  }
  input[type=checkbox] {
    width: 52px; height: 52px;
    margin-right: 20px;
    background: #d3d3d3 url('/content/images/partnership/220511_baroclub_check.png') no-repeat;
    background-size: 100% auto;
    border-radius: 10px;
    vertical-align: top;
    &:checked {
      background-color: #2255c8;
    }
  }
  label {
    color: #222;
    font-family: 'NanumSquareRound',sans-serif;
    font-size: 40px;
    font-weight: 400;
    line-height: 52px;
  }
}
.ui-text {
  padding: 52px 0 0 10px;
  color: #fff;
  font-family: 'NanumSquareRound',sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 52px;
}
.ui-popup {
  position: fixed;
  left: 0; top: 0;
  z-index: 9999;
  width: 100%; height: 100vh;
  background-color: rgba(0,0,0,.8);

  .ui-popup-wrap {
    position: fixed;
    width: 320px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 24px 25px;
    background-color: #fff;
    border-radius: 15px;
    .ui-popup-header {
      padding: 20px 0 18px;
      color: #222;
      font-family: 'NanumSquareRound',sans-serif;
      font-size: 15px;
      font-weight: 700;
      border-bottom: 1px solid #dbdbdb;
    }
    .ui-popup-container {
      padding-top: 15px;
      li {
        position: relative;
        color: #222;
        font-family: 'NanumSquareRound',sans-serif;
        font-size: 14px;
        font-weight: 400;
        &.ui-dash {
          padding-left: 8px;
          &:before {
            content: '-';
            position: absolute; left: 0;
          }
        }
        strong {
          color: #ff0101;
          font-size: 16px;
          font-family: inherit;
          font-weight: 700;
          text-decoration: underline;
        }
      }
      .ui-msg {
        padding: 35px 0 20px;
        color: #222;
        font-family: 'NanumSquareRound',sans-serif;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        line-height: 1.5;
      }
      .ui-btn-wrap {
        margin-top: 17px;
        .ui-btn {
          padding: 12px 0;
          line-height: 0;
          background-color: #2255c8;
          border-radius: 7px;
          img { width: calc(83px / 3) !important; }
        }
      }
    }
  }
}

@media (max-width: 1023px) {
.event_view {
  & > div {
    padding-bottom: 40px;
  }
  .event_view_inner {
    padding: 0 26px;
  }
}
.ui-box {
  margin-top: 28px;
  & + .ui-box { margin-top: 18px; }
  .ui-box-inner {
    padding: 23px;
    border-width: 1px;
    border-radius: 16px;
  }
  &:after {
    content: '';
    display: block;
    position: relative;
    height: 20px;
    margin-top: -12px;
    border-width: 1px;
    border-radius: 0 0 16px 16px;
  }
}
.ui-insert-form {
  & > dt {
    font-size: 14px;
  }
  & > dd {
    margin-top: 8px;
    .ui-row {
      height: 37px;
    }
    .ui-col {
      width: calc((100% - 36px) / 3);
      padding: 0 6px;
      border-width: 1px;
      border-radius: 7px;
      input[type=text] {
        height: 35px;
        padding: 0;
        font-size: 14px;
        text-align: center;
      }
    }
    .w100p { width: 100%; }
    .sel-box {
      &:after {
        right: 8px;
        width: 11px;
        height: 9px;
        margin-top: -4px;
      }
      select {
        height: 35px;
        font-size: 14px;
      }
    }
    .ui-dash {
      width: 18px;
      &:after {
        width: 6px;
        height: 1px;
        margin: -1px 0 0 -3px;
      }
    }
  }
  .ui-btn-wrap { margin-top: 17px; }
}
.ui-btn-wrap {
  margin-top: 33px;
  .ui-btn {
    padding: 12px 0;
    border-radius: 7px;
    line-height: 0;
    img { height: calc(47px / 3) }
  }
  .ui-img {
    width: 48.5%;
  }
}
.ui-agree {
  margin-top: 11px;
  & > div {
    padding: 6px 0;
    line-height: 0;
  }
  input[type=checkbox] {
    width: 14px; height: 14px;
    margin-right: 7px;
    border-radius: 3px;
  }
  label {
    font-size: 13px;
    line-height: 14px;
  }
}
.ui-text {
  padding: 17px 0 0 3px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
}
</style>
