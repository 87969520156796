<template>
  <div
    class="family_site_wrap"
    :class="{ on : isOpen }"
  >
    <button 
      type="button" 
      class="family_site_button"
      @click="isOpen = !isOpen"
    >
      {{ anchorText }}
      <span>
        <img
          src="/content/images/btn_family_site.png" 
          alt="펼치기"
        >
      </span>
    </button>
    <transition name="slide">
      <ul
        v-if="isOpen"
      >
        <li
          v-for="(item, index) in familySiteList"
          :key="index"
        >
          <a
            target="_blank" 
            :title="item.title"
            :href="item.link"
          >{{ item.text }}</a>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'FamilySite',
  components: {
  },
  props: {
    anchorText: {
      type: String,
      required: true
    },
    familySiteList: {
      type: Array,
      required: true
    },
  },
  emits: [
  ],
  data () {
    return {
      isOpen: false,
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.family_site_wrap ul {
  display: block !important;
  li:last-child {
    border-bottom: 1px dotted #565656;
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s linear;
}
.slide-enter-to, .slide-leave {
  max-height: 50px;
  overflow: hidden;
}
.slide-enter, .slide-leave-to {
  max-height: 0;
  overflow: hidden;
}
</style>
