
<template>
  <div id="SK00701">
    <div class="event_wrap sk00701">
      <div class="event_header">
        <div class="logo">
          <div>
            <img
              src="/content/images/partnership/220523_sk00701_logo1.png"
              alt="SK텔링크"
            >
          </div>
          <!-- <div>
            <img
              src="/content/images/partnership/220523_sk00701_logo2.png"
              alt="00701"
            >
          </div> -->
        </div>
        <h1 class="blind">
          국제전화 00701 이용 내역 조회 서비스
        </h1>
      </div>

      <div class="detail_content">
        <h2 class="title">
          <img
            src="/content/images/partnership/220523_sk00701_text2.png"
            alt="전월 잔여 내역"
          >
          ({{ startDt.substr(0,4) }}/{{ String(startDt.substr(4,2) - 1).padStart(2, '0') }}/{{ startDt.substr(6,2) }} ~ {{ endDt.substr(0,4) }}/{{ String(endDt.substr(4,2) - 1).padStart(2, '0') }}/{{ endDt.substr(6,2) }})
        </h2>
        <dl 
          v-if="beforeFreeAmt != 0" 
          class="col_data"
        >
          <div>
            <dt>사용 유효 기간</dt>
            <dd>{{ beforeFreeEndDt.substr(0,4) }}/{{ beforeFreeEndDt.substr(4,2) }}/{{ beforeFreeEndDt.substr(6,2) }}</dd>
          </div>
          <div>
            <dt>전월 잔여 분수</dt>
            <dd>{{ parseInt(beforeFreeAmt/60) }}분 {{ beforeFreeAmt%60 }}초</dd>
          </div>
        </dl>
        <dl 
          v-if="beforeFreeAmt === 0"
          class="col_data"
        >
          <div>
            <dt>사용 유효 기간</dt>
            <dd>없음</dd>
          </div>
          <div>
            <dt>전월 잔여 분수</dt>
            <dd>없음</dd>
          </div>
        </dl>

        <h2 class="title">
          <img
            src="/content/images/partnership/220523_sk00701_text3.png"
            alt="당월 이용 내역"
          >
          ({{ startDt.substr(0,4) }}/{{ startDt.substr(4,2) }}/{{ startDt.substr(6,2) }} ~ {{ endDt.substr(0,4) }}/{{ endDt.substr(4,2) }}/{{ endDt.substr(6,2) }})
        </h2>

        <div class="row_data_table">
          <table>
            <thead>
              <tr>
                <th>이용 일자</th>
                <th>이용 시간</th>
                <th>기본료</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(history, historyIdex) in usedInfoResult.use_list"
                :key="`history${historyIdex}`"
              >
                <td>{{ `${history.call_dt.substr(0,4)}/${history.call_dt.substr(4,2)}/${history.call_dt.substr(6,2)}` }}</td>
                <td>{{ `${parseInt(history.use_tm/60)}분 ${history.use_tm%60}초` }}</td>
                <td
                  class="useTime"
                >
                  {{ `${history.base_amount}` | comma }}원
                </td>
              </tr>
              <!-- <tr>
                <td>YYY/MM/DD</td>
                <td>100분 40초</td>
                <td
                  class="useTime"
                >
                  110,000원
                </td>
              </tr> -->
            </tbody>
            <tfoot>
              <tr>
                <th>이용 합계</th>
                <td>{{ parseInt(totalUse/60) }}분  {{ totalUse%60 }}초</td>
                <td>
                  {{ totalBase | comma }}원
                  <span>(부가세 포함)</span>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        
        <hr class="gap">

        <h2 class="title">
          <img
            src="/content/images/partnership/220523_sk00701_text4.png"
            alt="전일 기준 잔여 내역"
          >
        </h2>
        <dl 
          v-if="afterFreeEndAmt != 0"
          class="col_data sizeup"
        >
          <div>
            <dt>사용 유효 기간</dt>
            <dd>{{ afterFreeEndDt.substr(0,4) }}/{{ afterFreeEndDt.substr(4,2) }}/{{ afterFreeEndDt.substr(6,2) }}</dd>
          </div>
          <div>
            <dt>잔여 분수</dt>
            <dd>{{ parseInt(afterFreeEndAmt/60) }}분 {{ afterFreeEndAmt%60 }}초</dd>
          </div>
        </dl>
        <dl 
          v-if="afterFreeEndAmt === 0"
          class="col_data sizeup"
        >
          <div>
            <dt>사용 유효 기간</dt>
            <dd>{{ afterFreeEndDt.substr(0,4) }}/{{ afterFreeEndDt.substr(4,2) }}/{{ afterFreeEndDt.substr(6,2) }}</dd>
          </div>
          <div>
            <dt>잔여 분수</dt>
            <dd>없음</dd>
          </div>
        </dl>

        <div class="caution">
          <ul>
            <li>전일 기준 잔여 내역은 전일까지의 사용 내역 기준으로, 당일 사용내역은 미반영되었습니다.</li>
            <li>상기 내역은 청구 작업 이전의 내역으로, 실제의 값과 오차가 있을 수 있습니다.</li>
            <li>해당 페이지는 후불 무선 회선에서 00701을 사용한 내역만 조회됩니다. 위성, 해상 등의 특수대역의 통화는 조회되지 않습니다. </li>
            <li>고객센터 : 1599-0070(운영시간 : 평일 오전 9시~오후 6시)</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  name: 'Sk00701Detail',
  components: {},
 filters: {
    comma (val) {
      return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    }
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    ticket: this.$route.query.sms_ticket,
    usedInfoResult: {},  // 사용 내역 조회 결과    beforeFreeEndDt: '',
    beforeFreeEndDt: '',
    beforeFreeAmt: 0,
    afterFreeEndDt: '',
    afterFreeEndAmt: 0,
    totalUse: 0,
    totalBase: 0,     
    startDt: '',
    endDt: ''
  }
 },
 computed: {
 },
 watch: {
},
 created () {
  this.getUseList()   
},
 mounted () {
 },
 methods: {
  getUseList() {
    api.ItsvcPostUsed701Info({sms_ticket: this.ticket, svc_st_dt: '20220521'})
        .then(response => {
          this.usedInfoResult = response.data
          if (this.usedInfoResult.result_cd === '0001') {
            this.usedInfoResult.use_list.forEach(item => {
              this.totalUse = this.totalUse + item.use_tm
              this.totalBase = this.totalBase + item.base_amount
            })
            this.beforeFreeEndDt = response.data.bef_free_end_dt
            this.beforeFreeAmt = response.data.bef_free_amt
            this.afterFreeEndDt = response.data.aft_free_end_dt
            this.afterFreeEndAmt = response.data.aft_free_amt
            this.startDt = response.data.start_dt
            this.endDt = response.data.end_dt
          }
        })
   }
  }
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');

.sk00701 {
  width: 1120px;
  margin: 0 auto;
  margin-top: 40px;
  .event_header {
    padding: 35px 40px 0;
    img { width: auto; }
    .logo {
      display: flex;
      justify-content: space-between;
    }
  }
  .detail_content {
    padding: 70px 70px 100px;
    img { width: auto; }
    h2.title {
      margin-top: 100px;
      color: #222;
      font-family: "NanumSquareRound";
      font-size: 42px;
      font-weight: 800;
      text-align: center;
      &:first-child { margin-top: 0; }
    }
    .col_data {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
      padding: 34px 50px;
      background-color: #fff2ee;
      border-radius: 18px;
      & > div {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 80px;
        &:first-child { padding-left: 0; padding-right: 80px; }
      }
      dt {
        color: #505050;
        font-family: "NanumSquareRound";
        font-size: 28px;
        font-weight: 400;
        line-height: normal;
      }
      dd {
        color: #222;
        font-family: "NanumSquareRound";
        font-size: 28px;
        font-weight: 700;
        line-height: normal;
      }
      &.sizeup {
        background-color: #ff6432;
        dt {
          color: #fff2ee;
          font-size: 30px;
          font-weight: 400;
        }
        dd {
          color: #fff;
          font-size: 30px;
          font-weight: 800;
        }
      }
    }
    .row_data_table {
      margin: 45px 30px 0;
      thead {
        tr {
          background-color: #f4f4f4;
          border-bottom: 2px solid #ccc;
          th {
            padding: 23px 20px;
            color: #505005;
            font-family: "NanumSquareRound";
            font-size: 28px;
            font-weight: 400;
            line-height: normal;
            &:nth-child(1) { text-align: left; }
            &:nth-child(3) { text-align: right; }
          }
        }
      }
      tbody {
        border-bottom: 2px solid #505050;
        td {
          padding: 12px 20px 11px;
          color: #505050;
          font-family: "NanumSquareRound";
          font-size: 28px;
          font-weight: 400;
          line-height: normal;
          text-align: center;
          &:nth-child(1) { text-align: left; }
          &.useTime {
            color: #222;
            font-weight: 700;
            text-align: right;
          }
        }
        & > tr:first-child {
          td {
            padding-top: 35px;
          }
        }
        & > tr:last-child {
          td {
            padding-bottom: 37px;
          }
        }
      }
      tfoot {
        th {
          padding: 40px 20px 0;
          color: #2a2a2a;
          font-family: "NanumSquareRound";
          font-size: 28px;
          font-weight: 800;
          line-height: normal;
          text-align: left;
        }
        td {
          padding: 40px 20px 0;
          color: #222;
          font-family: "NanumSquareRound";
          font-size: 28px;
          font-weight: 800;
          line-height: normal;
          text-align: center;
          &:nth-child(3) { text-align: right; }
          & > span {
            display: block;
            font-family: "NanumSquareRound";
            font-size: 22px;
            font-weight: 800;
            line-height: normal;
          }
        }
      }
    }
    .gap {
      margin-top: 57px;
      border-top: 2px solid #ccc;
    }
  }
  .caution {
    margin-top: 52px;
    padding: 0 28px;
    li {
      position: relative;
      margin-top: 10px;
      padding-left: 30px;
      color: #505050;
      font-family: 'NanumSquareRound',sans-serif;
      font-size: 28px;
      font-weight: 400;
      line-height: 44px;
      text-align: left;
      letter-spacing: -0.04em;
      word-break: keep-all;
      &:before {
        position: absolute;
        content: "※";
        left: 0;
      }
      &:first-child { margin-top: 0; }
    }
  }
}

@media (max-width: 1023px) {
.sk00701 {
  width: 100%;
  .event_header {
    padding: 12px 15px 0;
    .logo {
      display: flex;
      justify-content: space-between;
      img { height: 19px; }
    }
  }
  .detail_content {
    padding: 23px 23px 33px;
    h2.title {
      margin-top: 50px;
      font-size: 15px;
      line-height: normal;
      img { height: 20px; vertical-align: text-bottom; }
    }
    .col_data {
      display: block;
      margin-top: 20px;
      padding: 17px 25px;
      border-radius: 9px;
      & > div {
        padding: 3px 0;
        &:first-child { padding-right: 0; }
      }
      dt {
        font-size: 16px;
      }
      dd {
        font-size: 16px;
      }
      &.sizeup {
        dt {
          font-size: 18px;
        }
        dd {
          color: #fff;
          font-size: 18px;
        }
      }
    }
    .row_data_table {
      margin: 18px 10px 0;
      thead {
        tr {
          border-bottom-width: 1px;
          th {
            padding: 12px 10px;
            font-size: 15px;
          }
        }
      }
      tbody {
        border-bottom-width: 1px;
        td {
          padding: 6px 10px 5px;
          font-size: 15px;
        }
        & > tr:first-child {
          td {
            padding-top: 17px;
          }
        }
        & > tr:last-child {
          td {
            padding-bottom: 18px;
          }
        }
      }
      tfoot {
        th {
          padding: 20px 10px 0;
          font-size: 15px;
        }
        td {
          padding: 20px 10px 0;
          font-size: 15px;
          & > span {
            font-size: 13px;
          }
        }
      }
    }
    .gap {
      margin-top: 30px;
      border-top-width: 1px;
    }
  }
  .caution {
    margin-top: 17px;
    padding: 0 8px;
    li {
      margin-top: 5px;
      padding: 0 0 0 15px;
      font-size: 15px;
      line-height: 20px;
      word-break: normal;
      &:before {
        top: 0;
      }
    }
  }
}
}
</style>
