<template>
  <div id="INTRODUCE_VISION">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg01">
          <h3 class="title">
            비전
          </h3>
          <p class="txt">
            SK텔링크는 고객에게 가치 있는 서비스를 제공하고<br>새로운 기술을
            활용하여 더 나은 세상을 만들기 위해 노력합니다.
          </p>
          <img
            src="/content/images/sub_header_cp01.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- company -->
        <div class="company mb0">
          <div class="cp_area">
            <div class="cp_box">
              <div class="img_sk w_hide">
                <img
                  src="/content/images/img_cp_vision01_1.png"
                  alt="Link the World, SK telink SK텔링크의 Vision은 모든 고객과 세상을 연결하고 새로운 미래를 이끌어 고객의 삶을 행복하게 만드는 것입니다"
                >
              </div>
              <div class="img_sk m_hide">
                <img
                  src="/content/images/img_cp_vision02_1.png"
                  alt="Link the World, SK telink SK텔링크의 Vision은 모든 고객과 세상을 연결하고 새로운 미래를 이끌어 고객의 삶을 행복하게 만드는 것입니다"
                >
              </div>
            </div>
          </div>
          <div class="cp_area gray">
            <div class="cp_box">
              <h4>
                미래상
              </h4>
              <p>
                SK텔링크는 Digital Transformation기반의 차별화된<br class="m_br">
                서비스 발굴을 통해<br>
                고객이 언제 어디서나 세상과 편하고 안전하게 소통할 수 있도록<br class="m_br">
                혁신적인 가치를 창출해 내는<br class="m_br">
                Total ICT 사업자로 진화할 것입니다.
              </p>
              <div class="img_box">
                <img
                  src="/content/images/img_cp_vision03.png"
                  alt=""
                >
              </div>
            </div>
          </div>
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl.vue'

export default {
  name: 'Vision',
  metaInfo: {
    title: '비전 | SK텔링크 소개 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
