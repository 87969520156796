<template>
  <div id="PRIVACY_GUIDE_ILLEGAL_TM">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            이용자 피해 예방 가이드
          </h3>
          <p class="txt">
            고객님의 개인정보보호를 위해<br>SK 텔링크에서 제공하는 피해 예방 정보를 확인하세요.
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- to.개발 on 되어있는 li가 가운데로 와야함. -->
        <div class="tab_scroll01 tab7">
          <!-- tab갯수에따른 중복클래스 : 4개일때: 클래스 없음, 5개일때: tab5, 6개일때: tab6, 7개일때: tab7 -->
          <ul>
            <li>
              <router-link to="/view/customer/privacy_guide">
                동영상&amp; 웹툰<br>피해예방교육
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/customer/privacy_guide_illegal_TM">
                불법 TM신고
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_Msafer">
                명의도용방지
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_smishing">
                스미싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_phishing">
                피싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_pharming">
                파밍
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_spam_message">
                스팸메시지
              </router-link>
            </li>
          </ul>
        </div>

        <!-- guide_wrap -->
        <div class="guide_wrap">
          <div class="guide_box01">
            <div class="sub_title">
              <h4 class="title">
                불법TM 신고안내
              </h4>
              <p class="txt">
                이동통신서비스 불법TM(Telemarketing : 전화권유판매)이란 수신자의 수신거부나 수신동의 철회를 방해하는 조치를 포함하여,<br>
                <span class="txt_point01">사전에 신고된 전화권유판매업자가 육성으로 TM하는 경우 이외의 모든 TM 행위</span>
              </p>
            </div>
          </div>

          <div class="guide_box02 gray">
            <div class="sub_title">
              <h4 class="title">
                이동통신서비스 불법TM 유형
              </h4>
              <p class="txt">
                <span class="hashtag"># ARS TM</span><span class="hashtag"># 허위광고</span><span class="hashtag"># 소속미확인업체</span>
              </p>
            </div>

            <div class="guide_slide">
              <div class="swiper-wrapper">
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm06.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    ARS 등 녹음된 음성의<br>기술적 조치를 통한 TM
                  </div>
                </div>
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm07.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    내용이 허위/과장 광고인 경우<br>(SKT 우수고객행사, 단말무료 제공, 최저가 요금제 등)
                  </div>
                </div>
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm08.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    광고성 정보 전송자의 명칭/연락처를 밝히지 않는 행위<br>(발신전용 전화사용, TM업체 소속 미확인)
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="guide_box03 white">
            <div class="sub_title">
              <h4 class="title">
                신고 절차 / 보상안내
              </h4>
              <p class="txt">
                ㆍSK텔링크를 사칭하는 불법TM 영업 건 <span class="txt_point01 br">(단, 각 통신사 별 공식 기변센터가 자사의 가입자를 대상으로 기변 안내하는 영업행위는 제외)</span>
              </p>
              <h5 class="title02">
                신고절차
              </h5>
            </div>
            <div class="guide_list02">
              <ul>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm18.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    불법TM가입 관련 영업콜
                  </div>
                </li>
                <li class="child2">
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm19.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    단말기 수령 또는 개통 후<br>단말일련번호 확인
                  </div>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm15.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    불법TM영업신고<br><span class="txt_point01">당사 고객센터 1599-0999(유료)<br>녹취자료, 단말일련번호 제출</span>
                  </div>
                </li>
              </ul>
            </div>
            <div class="sub_title">
              <h5 class="title02">
                고객보상
              </h5>
              <ul class="txt">
                <li>· SK텔링크 가입자로 확인된 경우 보상 및 가입철회 가능</li>
                <li>
                  · 가입 철회 및 수령 단말기 무상지급<br>
                  <span class="txt_point01 br"><span class="icon_star">*</span>고객 요청 시 가입철회, 가입 유지 시 단말할부금 면제 처리</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- //guide_wrap -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl";

export default {
  name: 'PrivacyGuideIllegalTM',
  metaInfo: {
    title: '불법 TM신고 | 이용자 피해 예방가이드 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
