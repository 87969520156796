<template>
  <div class="scroll">
    <div class="swiper-wrapper">
      <!-- to 개발 / 슬라이드 추가 -->
      <button
        type="button"
        data-role="none"
        class="slick-prev slick-arrow"
        aria-label="Previous"
        role="button"
        @click="previous"
      >
        Previous
      </button>
      <div
        class="slick-slide carousel"
      >
        <ul
          ref="carouselList"
          class="carousel__list"
        >
          <li
            v-for="(item, index) in itemArray"
            :key="index"
            ref="carouselChild"
            :class="slideActive"
          >
            <router-link :to="item.link">
              <div class="carousel__image">
                <img
                  :src="item.image.src"
                  :alt="item.image.alt"
                >
              </div>
              <dl>
                <dt>{{ item.title }}</dt>
                <dd v-html="item.description" />
              </dl>
            </router-link>
          </li>
        </ul>
      </div>
      <button
        type="button"
        data-role="none"
        class="slick-next slick-arrow"
        aria-label="Next"
        role="button"
        @click="next"
      >
        Next
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Carousel',
  props: {
    items: {
      type: Array,
      required: true
    },
    options:{
      type: Object,
      required: true
    }
  },
  data(){
    return{
      itemArray:[],
      slideSpeed:300,
      isActive: false,
      clickAble: true
    }
  },
  computed:{
    slideActive(){
      return this.isActive ? 'slide-active' : null;
    },
    slideOption(){
      return this.options;
    }
  },
  watch: {
    items: {
      deep: true,
      handler() {
        this.items.forEach(item => {
          this.itemArray.push(item)
        })
      }
    }
  },
  mounted () {
  },
  methods: {
    next(){
      if(this.clickAble) {
        this.clickAble = false
        let slideList = this.$refs.carouselList; // slide 부모
        this.itemArray.forEach((item, index) => {
          if (index < this.slideOption.slideToShow) {
            this.itemArray.push(item)
          }
        })
        slideList.style.transition = this.slideSpeed + "ms";
        slideList.style.transform = "translate3d(-" + (this.slideOption.slideWidth * 2) + "px, 0px, 0px)";
        setTimeout(() => {
          this.itemArray.splice(0, this.slideOption.slideToShow)
          slideList.style.transition = 0 + "ms";
          slideList.style.transform = "translate3d(-" + (this.slideOption.slideWidth * 1) + "px, 0px, 0px)";
          this.clickAble = true
        }, this.slideSpeed)
      }
    },
    previous() {
      if(this.clickAble) {
        this.clickAble = false
        let slideList = this.$refs.carouselList;
        let newArray = [];
        this.itemArray.forEach((item, index) => {
          if (index > (slideList.childNodes.length-1) - this.slideOption.slideToShow) {
            newArray.push(item)
          }
        })
        newArray.reverse()
        slideList.style.transition = this.slideSpeed + "ms";
        slideList.style.transform = "translate3d(0px, 0px, 0px)";
        setTimeout(() => {
          newArray.forEach(item => {
            this.itemArray.unshift(item)
          })
          this.itemArray.splice(slideList.childNodes.length, this.slideOption.slideToShow)
          slideList.style.transition = 0 + "ms";
          slideList.style.transform = "translate3d(-" + (this.slideOption.slideWidth * 1) + "px, 0px, 0px)";
          this.clickAble = true;
        }, this.slideSpeed)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel{
  $root:&;
  overflow: hidden !important;
  position: relative;
  &__list {
    display: flex;
    overflow: visible !important;
    transition: transform .5s;
    li{
      float: none;
    }
  }
}
</style>