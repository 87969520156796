<template>
  <div
    id="CIBI"
    class="eng"
  >
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents en"
      >
        <div class="sub_header bg01">
          <h3 class="title">
            CI/BI
          </h3>
          <p class="txt">
            SK telink’s corporate identity
          </p>
          <img
            src="/content/images/sub_header_cp01.jpg"
            alt="Visual image"
          >
        </div>

        <!-- tab -->
        <!-- to.개발 / 기능 추가 필요 아래 .company 두개 블럭과 연결 -->
        <div class="tab_type01">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li
              id="taba"
              :class="tabIndex == 1 ? 'on': ''"
              title="현재메뉴 선택됨"
            >
              <div
                @click="tabActive(1)"
              >
                CI
              </div>
            </li>
            <li
              id="tabb"
              :class="tabIndex == 2 ? 'on': ''"
            >
              <div
                @click="tabActive(2)"
              >
                BI
              </div>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company tab1-->
        <div
          v-show="tabIndex == 1"
          id="tab1"
          class="company"
        >
          <div class="ci_list">
            <h4>Logo mark</h4>
            <ul>
              <li>
                <div>
                  <img
                    src="/content/images/img_logo01.png"
                    alt="SK telink"
                  >
                </div>
                <a
                  href="/content/download/cibi/ci_Sktelink.ai"
                  class="btn_down"
                  download
                >Download the AI file</a>
                <a
                  href="/content/download/cibi/ci_Sktelink.jpg"
                  class="btn_down"
                  download
                >Download the JPG file</a>
              </li>
              <li>
                <div>
                  <img
                    src="/content/images/img_logo02.png"
                    alt="SK telink"
                  >
                </div>
                <a
                  href="/content/download/cibi/ci-eng_SKtelink.ai"
                  download
                  class="btn_down"
                >Download the AI file</a>
                <a
                  href="/content/download/cibi/ci-eng_SKtelink.jpg"
                  class="btn_down"
                  download
                >Download the JPG file</a>
              </li>
            </ul>
            <div class="txt_box">
              <p>
                The logo mark is the official symbol of SK telink. It should not be changed in any case, and it is used in various media,<br>
                such as printed matters and promotional materials, and plays an important role in delivering the corporate and brand image of SK telink.
              </p>
              <p class="txt_point01">
                <span class="icon_star">*</span>To prevent the deformation of the logo mark, please download the file on the web.
              </p>
            </div>
          </div>

          <div class="ci_list bg_rule">
            <div>
              <h4>CI Space Regulation</h4>
              <ul>
                <li>
                  <div>
                    <img
                      src="/content/images/img_logo03.png"
                      alt="Assuming that the height of the logo mark ‘ K ’ is X, the height of the ‘Happy Wings’ is 1.1X. The size of the Korean company name is 0.8X, and the size of the English company name is 0.55X."
                    >
                  </div>
                </li>
                <li>
                  <div>
                    <img
                      src="/content/images/img_logo04.png"
                      alt="The space between the SK logo and the company name is 0.8X in Korean, and 0.55X in English. The logo mark is SK Red, and the company name is SK Orange, and if it is black and white, the logo mark is Black 60%."
                    >
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="color_list">
            <h4>Main colors</h4>
            <div class="color_list_inner">
              <div class="red">
                <h5>SK Red</h5>
                <ul>
                  <li>Pantone : 186C</li>
                  <li>CMYK : 0.100.8.4</li>
                  <li>RGB : 234.0.44</li>
                </ul>
              </div>
              <div class="orange">
                <h5>SK Orange</h5>
                <ul>
                  <li>Pantone : 158C</li>
                  <li>CMYK : 0.66.97.0</li>
                  <li>RGB : 255.122.0</li>
                </ul>
              </div>
            </div>
            <div class="txt_box">
              <p>
                <span class="txt_point02">The SK Red of the logo mark and the symbol mark is dynamic and vibrant red,</span><br>
                and <span class="txt_point01">SK orange is a color that symbolizes ‘Happiness,’ ‘Friendliness’ and ‘Welcome.’</span><br class="w_hide">
                In principle, the specified color must be used for the logo mark against a white background.<br>
                In particular, as the continuous use of the same colors plays an important role in building the image of SK telink,<br>
                you must comply with the color utilization standards. In principle, if you want to use dedicated colors, spot colors must be used.
              </p>

              <p class="txt_point01">
                <span class="icon_star">*</span>If you use colors not described here, you must consider the chroma and brightness of the ground color,<br>and use them after consultation with the administrative department.
              </p>
            </div>
          </div>

          <div class="ci_list color_rule">
            <h4>Color Regulation</h4>
            <ul>
              <li>
                <div>
                  <img
                    src="/content/images/img_logo05.png"
                    alt="SK telink logo(black)"
                  >
                </div>
                <dl>
                  <dt>Black color</dt>
                  <dd>When you use the logo for black-and-white printing,<br>the Happy Wings must be Black 100% and 60%<br>so that the difference in brightness can be distinguished.</dd>
                </dl>
              </li>
              <li class="bg_print">
                <div>
                  <img
                    src="/content/images/img_logo06.png"
                    alt="SK telink logo(Negative Print)"
                  >
                </div>
                <dl>
                  <dt>Negative Print</dt>
                  <dd>For negative print,<br>if the brightness of the background color is 30%, use white.</dd>
                </dl>
              </li>
            </ul>
          </div>
        </div>
        <!-- //customer -->

        <!-- company tab2-->
        <div
          v-show="tabIndex == 2"
          id="tab2"
          class="company"
        >
          <div class="bi_area">
            <h4>international call</h4>
            <ul class="sk_inter">
              <li class="child1">
                <div>
                  <img
                    src="/content/images/img_bi01.png"
                    alt="SK International Call 00700"
                  >
                </div>
                <a
                  href="/content/download/cibi/ci_00700.ai"
                  class="btn_down"
                  download
                >Download the AI file</a>
                <a
                  href="/content/download/cibi/ci_00700.jpg"
                  class="btn_down"
                  download
                >Download the JPG file</a>
              </li>
              <li class="child2">
                <div>
                  <img
                    src="/content/images/img_bi02.png"
                    alt="SK International Call 00700"
                  >
                </div>
                <a
                  href="/content/download/cibi/ci-eng_00700.ai"
                  class="btn_down"
                  download
                >Download the AI file</a>
                <a
                  href="/content/download/cibi/ci-eng_00700.jpg"
                  class="btn_down"
                  download
                >Download the JPG file</a>
              </li>
            </ul>
          </div>

          <div class="bi_area">
            <h4>MVNO SK 7mobile</h4>
            <ul class="sk_7m">
              <li class="child1 single">
                <div>
                  <img
                    src="/content/images/img_bi7m02.png"
                    alt="General horizontal SK MVNO 7mobile"
                  >
                </div>
                <a
                  href="/content/download/cibi/7mobile height.ai"
                  class="btn_down"
                  download
                >AI file</a>
                <a
                  href="/content/download/cibi/7mobile height.jpg"
                  class="btn_down"
                  download
                >JPG file</a>
              </li>
              <!-- <li class="child2">
                <div>
                  <img
                    src="/content/images/img_bi7m01.png"
                    alt="General vertical SK MVNO 7mobile"
                  >
                </div>
                <a
                  href="/content/download/cibi/7mobile height.ai"
                  class="btn_down"
                  download
                >AI file</a>
                <a
                  href="/content/download/cibi/7mobile height.jpg"
                  class="btn_down"
                  download
                >JPG file</a>
              </li> -->
            </ul>
          </div>

          <!-- 9월 15일이후 적용예정 -->
          <!-- <div class="bi_area">
                    <h4>SK 7mobile</h4>
                    <ul class="sk_7mobile">
                        <li class="child1">
                            <h5>General horizontal</h5>
                            <div><img src="/content/images/img_bi03.png" alt="SK 7mobile" /></div>
                            <a href="" class="btn_down">AI file</a>
                            <a href="" class="btn_down">JPG file</a>
                        </li>
                        <li class="child2">
                            <h5>General vertical</h5>
                            <div><img src="/content/images/img_bi04.png" alt="SK 7mobile" /></div>
                            <a href="" class="btn_down">AI file</a>
                            <a href="" class="btn_down">JPG file</a>
                        </li>
                        <li class="child3">
                            <h5>With the Happy Wings</h5>
                            <div><img src="/content/images/img_bi05.png" alt="SK 7mobile" /></div>
                            <a href="" class="btn_down">AI file</a>
                            <a href="" class="btn_down">JPG file</a>
                        </li>
                    </ul>
                </div> -->
          <!-- //9월 15일이후 적용예정 -->
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/EngHeader";
import BreadCrumb from "@/components/EngBreadCrumb";
import Footer from "@/components/EngFooter";

function MS_bindDownload(el) {
    if (el === undefined) {
        throw Error('I need element parameter.');
    }
    if (el.href === '') {
        throw Error('The element has no href value.');
    }
    var filename = el.getAttribute('download');
    if (filename === null){
        throw Error('I need download property.');
    }
    if (filename === '') {
        var tmp = el.href.split('/');
        filename = tmp[tmp.length - 1];
    }
    el.addEventListener('click', function (evt) {
        evt.preventDefault();
        var xhr = new XMLHttpRequest();
        xhr.onloadstart = function () {
            xhr.responseType = 'blob';
        };
        xhr.onload = function () {
            navigator.msSaveOrOpenBlob(xhr.response, filename);
        };
        xhr.open("GET", el.href, true);
        xhr.send();
    })
}

export default {
  name: 'Cibi',
  metaInfo: {
    title: 'CI/BI | Company | SK telink',
    titleTemplate: null
  },
  components: {
    Header,
    BreadCrumb,
    Footer
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:'1'
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
    if (util.getBrowserIEVersion() !== -1) {
      setTimeout(() => {
        let atags = document.querySelectorAll('a[download]')
          for (let i = 0; i < atags.length; i++) {
            const element = atags[i];
            MS_bindDownload(element);
          }
      }, 100)
    }
  },
  methods: {
    tabActive(num){
      this.tabIndex = num
    }
  }
}
</script>

<style lang="scss" scoped>
.single {
  display: inline-block !important;
  float: none !important;
}
</style>
