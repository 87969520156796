const messageList = [
  { code: '0002', type: 'alert', message: '신청이 완료되었습니다.', messageEng: ''},
  { code: '0003', type: 'alert', message: '신청이 완료되었습니다.', messageEng: ''},
  { code: '0004', type: 'alert', message: '쿠폰 등록이 완료되었습니다.<br>Completed!', messageEng: 'Completed!'},
  { code: '0005', type: 'alert', message: '해지가 완료되었습니다.', messageEng: ''},
  { code: '0006', type: 'alert', message: '이벤트 응모에 감사드리며,<br>안내 문자 확인 부탁 드립니다.', messageEng: ''},
  { code: '0009', type: 'alert', message: '응모가 완료되었습니다.', messageEng: ''},
  { code: 'E001', type: 'alert', message: '인증시간이 초과되었습니다.<br>인증번호를 다시 발송해 주세요.' , messageEng: ''},
  { code: 'E002', type: 'alert', message: '인증에 5회 실패하였습니다.<br>인증번호를 다시 발송해 주세요.' , messageEng: ''},
  { code: 'E003', type: 'alert', message: '이미 인증이 완료되었습니다.' , messageEng: ''},
  { code: 'E004', type: 'alert', message: '인증번호가 일치하지 않습니다.<br>5회 실패 시 인증번호 재발송이 필요합니다.', messageEng: ''},
  { code: 'E005', type: 'alert', message: '인증에 오류가 있습니다.<br>인증을 다시 진행해 주세요.', messageEng: ''},
  { code: 'E006', type: 'alert', message: '인증에 실패하였습니다.', messageEng: ''},
  { code: 'E007', type: 'cert', message: '인증 유효시간이 초과되었습니다.<br>인증을 다시 진행해 주세요.' , messageEng: ''},
  { code: 'E008', type: 'cert', message: '인증에 오류가 있습니다.<br>인증을 다시 진행해 주세요.' , messageEng: ''},
  { code: 'E009', type: 'cert', message: '인증에 오류가 있습니다.<br>인증을 다시 진행해 주세요.' , messageEng: ''},
  { code: 'E010', type: 'cert',	message: '신청에 실패하였습니다.<br>인증된 번호와 가입전화번호가 일치하지 않습니다.<br>인증을 다시 진행해 주세요.', messageEng: ''},
  { code: 'E011', type: 'alert', message: '이미 신청한 번호입니다.<br>이용해주셔서 감사합니다.', messageEng: 'You have already applied for a Call Plan.'},
  { code: 'E012', type: 'alert', message: '신청에 실패하였습니다. 다시 시도해 주세요.', messageEng: ''},
  { code: 'E016', type: 'alert', message: '신청에 오류가 있습니다. <br>다시 진행해 주세요.', messageEng: ''},
  { code: 'E017', type: 'alert', message: '선불 이동전화번호는 신청이 불가합니다.<br>다른 번호를 이용해 주세요.', messageEng: ''},
  { code: 'E018', type: 'alert', message: '신청에 오류가 있습니다.<br>다시 진행해 주세요.', messageEng: ''},
  { code: 'E019', type: 'cert',	message: '인증에 5회 실패하였습니다.<br>휴대폰 본인인증을 다시 진행해 주세요.', messageEng: ''},
  { code: 'E021', type: 'alert', message: 'All Pass 요금제 이용고객이 아닙니다.<br>요금제 혜택 변경은 이용고객 대상 서비스입니다.<br>자세한 확인이 필요하실 경우 고객센터에 문의 부탁 드립니다. (1599-0070)', messageEng: ''},
  { code: 'E022', type: 'alert', message: '선택하신 혜택은 이용 중인 All Pass 요금제에서 변경이 불가능합니다.<br>자세한 확인이 필요하실 경우 고객센터에 문의 부탁 드립니다. (1599-0070)', messageEng: ''},
  { code: 'E023', type: 'alert', message: '고객님은 요금제 신청을 이미 하셨습니다.', messageEng: ''},
  { code: 'E024', type: 'alert', message: '요금제 변경 불가번호!<br>1599-00700(SK텔링크 고객센터) 문의 안내', messageEng: ''},
  { code: 'E027', type: 'alert', message: '인증 유효시간이 초과되었습니다.<br>인증을 다시 진행해 주세요.', messageEng: ''},
  { code: 'E028', type: 'alert', message: '인증에 오류가 있습니다.<br>인증을 다시 진행해 주세요.', messageEng: ''},
  { code: 'E029', type: 'alert', message: '인증에 오류가 있습니다.<br>인증을 다시 진행해 주세요.', messageEng: ''},
  { code: 'E030', type: 'alert', message: '요금제 가입 대상이 아닙니다.', messageEng: ''},
  { code: 'E031', type: 'alert', message: '프로모션 기간이 아닙니다.', messageEng: ''},
  { code: 'E032', type: 'alert', message: 'T멤버십 인증에 실패 했습니다. 멤버십 번호를 확인해 주세요.', messageEng: ''},
  { code: 'E033', type: 'alert', message: 'T멤버십 혜택유형 조회에 실패 했습니다.', messageEng: ''},
  { code: 'E035', type: 'alert', message: '기존 요금제 가입자입니다.', messageEng: ''},
  { code: 'E036', type: 'alert', message: 'T추천요금제 가입 가능', messageEng: ''},
  { code: 'E037', type: 'alert', message: '죄송합니다. 고객님은 이벤트 참여 대상이 아니십니다.', messageEng: ''},
  { code: 'E038', type: 'alert', message: '이벤트 대상 확인 에러', messageEng: ''},
  { code: 'E039', type: 'alert', message: '동일 년도 재가입 불가 요금제입니다.', messageEng: ''},
  { code: 'E040', type: 'alert', message: '제휴 요금제를 사용하고 있지 않습니다.', messageEng: ''},
  // { code: 'E041', type: 'alert', message: '신청에 오류가 있습니다. 고객센터로 문의 바랍니다.', messageEng: ''},
  // { code: 'E042', type: 'alert', message: '이용 가능한 쿠폰이 존재하지 않습니다.', messageEng: ''},
  // { code: 'E043', type: 'alert', message: '쿠폰 이용 등록이 불가합니다..', messageEng: ''},
  // { code: 'E044', type: 'alert', message: '신청에 오류가 있습니다. 고객센터로 문의 바랍니다.', messageEng: ''},
  { code: 'E045', type: 'alert', message: '가입 신청하신 번호는 홀릭요금제 가입이 어렵습니다.<br>자세한 사항은 고객센터로 문의해 주시기 바랍니다.<br>이용해 주셔서 감사합니다.<br><br># 고객센터 1599-0070(유료) / 9시~18시 운영', messageEng: ''},
  { code: 'E046', type: 'alert', message: '죄송합니다. 홀릭 체험 대상이 아닙니다.<br>이용해주셔서 감사합니다.', messageEng: ''},
  { code: 'E047', type: 'alert', message: '이미 쿠폰을 사용한 번호입니다.<br>이용해주셔서 감사합니다.<br>Sorry, this number was already got the promotion benefit.', messageEng: ''}
]

export {
  messageList
}
