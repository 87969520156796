<template>
  <div class="error">
    <div class="qna__logo">
      <img
        src="/content/images/logo_qna.png"
        alt="에스케이 텔링크 로고"
      >
    </div>

    <div class="text_align__center">
      <p class="error-title">
        시스템 에러
      </p>
      <p class="error-text">
        페이지를 찾을 수 없습니다. <br>요청하신 페이지가 사라졌거나, 올바른 접속 경로가 아닙니다. <br>항상 더 나은 서비스를 제공하기 위하여 최선을 다하겠습니다.
      </p>
      <router-link
        to="/"
        class="btn-underline"
      >
        SK텔링크 홈페이지 바로가기
      </router-link>
    </div>
  </div>
</template>


<script>
export default {

}
</script>

<style>

</style>