<template>
  <transition name="fade">
    <button
      v-show="isVisible"
      id="SROLL_TOP_BUTTON"
      type="button"
      class="btn_top"
      :class="isMobile"
      @click="toTop"
    >
      <img
        src="/content/images/btn_top.png"
        alt="상단바로가기"
      >
    </button>
  </transition>
</template>

<script>
export default {
  name:'ScrollTop',
  props:{},
  data(){
    return{
      isVisible:false,
      isMobile:''
    }
  },
  computed:{},
  created(){
    window.addEventListener('load', this.buttonResize);
    window.addEventListener('resize', this.buttonResize);
    window.addEventListener('scroll', this.scrollTop);
    window.addEventListener('wheel', this.scrollTop);
  },
  mounted(){
  },
  methods: {
    scrollTop() {
      if (this.isMobile) {
        if (document.querySelector('#wrap').scrollTop > 100) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
      } else {
        // this.isVisible = false
        if (window.scrollY > 100) {
          this.isVisible = true;
        } else {
          this.isVisible = false;
        }
      }
    },
    toTop() {
      if(this.isMobile){
        document.querySelector('#wrap').scroll({
          top:0,
          behavior: 'smooth'
        });
      }else{
        window.scroll({
          top:0,
          behavior:'smooth'
        })
      }
      setTimeout(() => {
        this.isVisible = false;
      }, 600);
    },
    //모바일화면시 사이즈
    buttonResize() {
      const mobileMatch = window.matchMedia("screen and (max-width: 1023px)");
      if (mobileMatch.matches) {
        this.isMobile = 'mobileButton'
      } else {
        this.isMobile = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobileButton{
  .btn_top{/* display: none; */right: 20px;bottom: 30px;width: 35px; height: 35px; z-index: 899;}
  .btn_top img{ width: 100%;}
}
//scroll fade animation
.fade-enter-active, .fade-leave-active {
  transition: opacity .7s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>