<template>
  <div v-if="isVisible">
    <div
      class="pop_wrap m-size"
      style="display:block"
    >
      <div class="alert">
        <h3> {{ popTitle }}</h3>
        <div class="popup-inner">
          <slot> 팝업 컨텐츠 </slot>
          <div
            class="btn_center"
            :class="btnDivide"
          >
            <a
              v-if="btnDivide"
              :class="cType"
              @click="buttonConfirm"
            >{{ buttonTextType2 }}</a>
            <a
              :class="cType2"
              @click="buttonHandler"
            >{{ buttonText }}</a>
          </div>
        </div>
        <a
          href="javascript:;"
          class="pop-close"
          @click="popupClose"
        ><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <div
      id="msgDimd"
      class="dimd"
      style="display: block;"
    />
  </div>
</template>

<script>
export default {
  name: 'DefaultPopup',
  props: {
    popTitle: {
      type: String,
      required: true
    },
    buttonText:{
      type: String,
      required: false,
      default: '확인'
    },
    buttonTextType2:{
      type: String,
      required: false,
      default: '확인'
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    cType: {
      type: String,
      required: false,
      default: 'btn_type01'
    },
    cType2: {
      type: String,
      required: false,
      default: 'btn_type02'
    },
    btnDivide: {
      type:String,
      required:false,
      default:''
    }
  },
  methods:{
    popupClose(){
      this.$emit('close')
    },
    buttonHandler() {
      this.$emit('click')
    },
    buttonConfirm(){
      this.$emit('confirm')
    }
  }
}
</script>

<style>

</style>