<template>
  <div
    v-if="menuItems.length"
    class="location"
  >
    <ul class="location_inner">
      <li class="location_item">
        <router-link
          to="/"
          class="location_link"
        >
          <img
            src="/content/images/location_ico_home.png"
            alt="홈"
          >
        </router-link>
      </li>

      <!-- 1depth -->
      <li
        class="location_item"
      >
        <a
          href="#"
          class="location_link"
        >
          {{ oneDepthItem.label }}
        </a>
        <button
          type="button"
          @mouseenter="mouseOver"
        >
          <img
            src="/content/images/location_btn_more.png"
            alt="더보기"
          >
        </button>
        <div
          class="sub_location"
          @mouseleave="mouseLeave"
        >
          <ul>
            <template v-for="(two, twoIndex) in menuItems[oneDepthIndex].children">
              <li
                v-if="two.isVisible === true"
                :key="`two_${twoIndex}`"
              >
                <router-link :to="two.link">
                  {{ two.label }}
                </router-link>
              </li>
            </template>
          </ul>
        </div>
      </li>
      <!-- //1depth -->

      <!-- 2depth -->
      <li
        class="location_item"
        :class="{ current: twoDepthItem.children.length === 0}"
      >
        <router-link
          :to="twoDepthItem.link"
          class="location_link"
        >
          {{ twoDepthItem.label }}
        </router-link>
        <button
          v-if="twoDepthItem.children.length"
          type="button"
          @mouseenter="mouseOver"
        >
          <img
            src="/content/images/location_btn_more.png"
            alt="더보기"
          >
        </button>
        <div
          v-if="twoDepthItem.children.length"
          class="sub_location"
          @mouseleave="mouseLeave"
        >
          <ul>
            <template v-for="(three, threeIndex) in menuItems[oneDepthIndex].children[twoDepthIndex].children">
              <li
                v-if="three.isVisible === true && !three.outLink"
                :key="`three_${threeIndex}`"
              >
                <router-link
                  :to="three.link"
                >
                  {{ three.label }}
                </router-link>
              </li>
              <li
                v-else-if="three.isVisible === true && three.outLink"
                :key="`three_${threeIndex}`"
              >
                <a
                  :href="three.outLink"
                  target="_blank"
                >
                  {{ three.label }}
                </a>
              </li>
            </template>
          </ul>
        </div>
      </li>
      <!-- //2depth -->

      <!-- active menu -->
      <li
        v-if="twoDepthItem.children.length"
        class="location_item"
        :class="{'current' : 'isActive'}"
      >
        <router-link
          class="location_link"
          :to="threeDepthItem.link"
        >
          {{ threeDepthItem.label }}
        </router-link>
      </li>
      <!-- //active menu -->
    </ul>
  </div>
</template>

<script>
import api from '@/api/index.js'

export default {
  name: 'BreadCrumb',
  props: {
    parms: {
      type: Number,
      required: false,
      default: null
    }
  },
  data(){
    return {
      menuItems:[],
      oneDepthItem: {},
      twoDepthItem: {},
      threeDepthItem: {},
      oneDepthIndex:null,
      twoDepthIndex:null,
      threeDepthIndex:null,
    }
  },
  computed: {
    parmsIdGet(){
      return this.parms
    }
  },
  created () {
    api.Menu()
      .then(response => {
        this.menuItems = response.data
        this.updateArray()
      })
  },
  methods:{
    updateArray(){
      this.menuItems.forEach((oneDepth, index) => {
        oneDepth.children.forEach((twoDepth, index2) =>{
          if (twoDepth.link === this.$route.path || twoDepth.name === this.$route.name) {
            if (twoDepth.children.length === 0) {
              this.oneDepthItem = oneDepth;
              this.twoDepthItem = twoDepth;
              this.oneDepthIndex = index;
              this.twoDepthIndex = index2;
            }
          }
          twoDepth.children.forEach((threeDepth, index3) => {
            if (threeDepth.link === this.$route.path || threeDepth.name === this.$route.name) {
              this.oneDepthItem = oneDepth;
              this.twoDepthItem = twoDepth;
              this.threeDepthItem = threeDepth;
              this.oneDepthIndex = index;
              this.twoDepthIndex = index2;
              this.threeDepthIndex = index3;
            }
          })
        })
      })
    },
    mouseOver(event){
      let subList = event.currentTarget.nextElementSibling
      let subItems = document.querySelectorAll('.sub_location');
      for(let i=0; i<subItems.length; i++){
        subItems[i].classList.remove('on');
        subItems[i].style.height = '0px'
        subItems[i].style.overflow = 'hidden'
      }
      subList.classList.add('on');
      subList.style.height= subList.childNodes[0].clientHeight + 'px'
      setTimeout(() => {
        subList.style.overflow = 'visible'
      }, 200);
    },
    mouseLeave(event){
      let subList = event.target;
      subList.classList.remove('on');
      subList.style.height= '0px'
      subList.style.overflow = 'hidden'
    }
  },
}
</script>

<style lang="scss">
.location ul li .sub_location{
  display: block;
  overflow: hidden;
  height: 0;
  transition: height 0.2s;
  padding: 0;
}
.location ul li .sub_location ul {
  position: relative;
  padding: 11px 30px 20px;
}
.location ul li .sub_location.on{
  transition: height 0.2s;
  color: #666;
}
</style>
