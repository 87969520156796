<template>
  <div id="KOREANAAIR_SKYPASS">
    <!-- 컨텐츠 -->
    <div id="container">
      <div id="contents">
        <div class="event_wrap event_wrap-pc">
          <div class="event_view">
            <div style="margin-top:0;">
              <div
                id="boardWEB"
                class="event_wrap"
              >
                <div class="event_view event_view-mobile ">
                  <div>
                    <span class="w_hide">
                      <img
                        alt="대한항공 SKYPASS고객이면 00700 국제전화 대박할인!"
                        src="/content/images/img_event_skypass_detail_pc.jpg"
                      >
                    </span>
                    <span class="m_hide">
                      <img
                        alt="대한항공 SKYPASS고객이면 00700 국제전화 대박할인!"
                        src="/content/images/img_event_skypass_detail_m.jpg"
                      >
                    </span>
                    <div class="blind">
                      <h5>대한항공 SKYPASS고객이면 00700 국제전화 대박할인!</h5>
                      <p>대한항공 &amp; SK텔링크 제휴 스카이패스 전용요금제 출시!</p>
                      <h6>스카이패스 전용요금제란?</h6>
                      <p>SK텔링크 국제전화 00700이 대한항공 스카이패스 회원들에게 드리는 타사대비 평균 58%저렴한 요금제입니다.</p>
                      <p>국제전화 요금비교</p>
                      <table>
                        <thead>
                          <tr>
                            <td scope="col">
                              국가
                            </td>
                            <td>SKYPASS 제휴 요금제</td>
                            <td>00700표준요금제</td>
                            <td>표준대비 할인율</td>
                          </tr>
                          <tr />
                        </thead>
                        <tbody>
                          <tr>
                            <td scope="col">
                              미국
                            </td>
                            <td>110원</td>
                            <td>178.2원</td>
                            <td>38%</td>
                          </tr>
                          <tr>
                            <td scope="col">
                              일본
                            </td>
                            <td>198원</td>
                            <td>660.0원</td>
                            <td>70%</td>
                          </tr>
                          <tr>
                            <td scope="col">
                              중국
                            </td>
                            <td>110원</td>
                            <td>1221.0원</td>
                            <td>90%</td>
                          </tr>
                        </tbody>
                      </table>
                      <p>기타 국가도 타사대비 저렴하게 사용하실 수 있습니다.(기타 국가 상세요금 조회는 SK텔링크 고객센터(1599-00700)를 통해 확인하실 수 있습니다)</p>
                      <p>가입 시 별도의 기본료가 부과되지 않습니다.</p>
                      <h6>SKYPASS제휴요금제 이용방법</h6>
                      <ol>
                        <li>STEP1. 사전에 대한항공 SKYPASS 회원가입 필수!</li>
                        <li>STEP2. 제휴요금제 이용을 위해 정확한 성명, 회원번호 12자리 입력</li>
                        <li>STEP3. 요금제 신청 후 국제전화 00700 이용하기</li>
                      </ol>
                      <h6>유의사항</h6>
                      <ul>
                        <li>00700 SKYPASS w제휴 요금제를 가입하는 경우, SKYPASS회원을 확인하기 위해 개인정보 수집/이용/제공에 반드시 동의하셔야만 정상 가입이 완료됩니다.</li>
                        <li>개인정보 수집/이용/제공 동의를 거부하실 경우 요금제 가입에 제한이 있을 수 있습니다.</li>
                        <li>SKYPASS 회원번호는 숫자 12자리이며, 구 회원번호인 BM00000000는 사용할 수 없습니다.</li>
                        <li>00700 SKYPASS 제휴 요금제는 6개월간 미 사용시 자동 취소될 수 있습니다.</li>
                        <li>국제전화 이용하는 번호 변경 시 고객센터를 통해 변경하셔야 지속적으로 이용이 가능합니다.</li>
                        <li>SK국제전화 앱(애플리케이션)을 다운받은 경우 기존 요금제가 자동 취소됩니다(앱 설치 시 안내 확인).</li>
                        <li>국제전화 요금제 변경 또는 재가입 신청은 홈페이지 내 마이페이지 또는 고객센터 1599-00700(평일 09시~18시)에서 변경/수정 가능합니다.</li>
                        <li>비정상적이거나 불법적인 방법으로 국제전화를 이용할 경우 사전 안내 없이 국제전화 요금제 취소 및 이용에 제한이 있을 수 있습니다.</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="notice event_view_field notice-mobile">
                  <div class="table_type01">
                    <dl>
                      <dt>성명</dt>
                      <dd>
                        <input
                          ref="userName"
                          v-model="name"
                          name="i_sUserNm"
                          title="성명"
                          class="wid452"
                          type="text"
                        >
                      </dd>
                    </dl>
                    <dl class="double">
                      <dt>전화번호</dt>
                      <dd>
                        <div class="border-none">
                          <div class="radio_box">
                            <input
                              id="i_sPhoneType_C030"
                              v-model="phoneType"
                              name="i_sPhoneType"
                              title="무선번호"
                              class="myClass"
                              type="radio"
                              value="C030"
                            >
                            <label
                              for="i_sPhoneType_C030"
                              @click="telNumber.C020.middle = '', telNumber.C020.last = ''"
                            >
                              무선번호
                            </label>
                          </div>
                          <div class="radio_box">
                            <input
                              id="i_sPhoneType_C020"
                              v-model="phoneType"
                              name="i_sPhoneType"
                              title="유선번호"
                              class="myClass"
                              type="radio"
                              value="C020"
                            >
                            <label
                              for="i_sPhoneType_C020"
                              @click="telNumber.C030.middle = '', telNumber.C030.last = '', auth_no = ''"
                            >
                              유선번호
                            </label>
                          </div>
                        </div>
                        <div class="border-none">
                          <div
                            v-if="phoneType === 'C020'"
                            class="C020 duplPhono"
                          >
                            <!-- 유선 전화번호 타입 -->
                            <div
                              class="select_box"
                              style="vertical-align: middle;"
                            >
                              <label
                                aria-hidden="true"
                                for="call_select01"
                              >선택</label>
                              <select
                                id="call_select01"
                                ref="telNumberC020First"
                                v-model="telNumber.C020.first"
                                name="i_sPhone1"
                                title="유선전화 앞자리 선택"
                              >
                                <option value="선택">
                                  선택
                                </option>
                                <option value="02">
                                  02
                                </option>
                                <option value="031">
                                  031
                                </option>
                                <option value="032">
                                  032
                                </option>
                                <option value="033">
                                  033
                                </option>
                                <option value="041">
                                  041
                                </option>
                                <option value="042">
                                  042
                                </option>
                                <option value="043">
                                  043
                                </option>
                                <option value="044">
                                  044
                                </option>
                                <option value="051">
                                  051
                                </option>
                                <option value="052">
                                  052
                                </option>
                                <option value="053">
                                  053
                                </option>
                                <option value="054">
                                  054
                                </option>
                                <option value="055">
                                  055
                                </option>
                                <option value="061">
                                  061
                                </option>
                                <option value="062">
                                  062
                                </option>
                                <option value="063">
                                  063
                                </option>
                                <option value="064">
                                  064
                                </option>
                                <option value="070">
                                  070
                                </option>
                              </select>
                            </div>
                            <span class="txt_hyphen">-</span>
                            <input
                              ref="telNumberC020Middle"
                              v-model="telNumber.C020.middle"
                              name="i_sPhone2"
                              title="전화번호"
                              type="text"
                              maxlength="4"
                              oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                            >
                            <span class="txt_hyphen">-</span>
                            <input
                              ref="telNumberC020Last"
                              v-model="telNumber.C020.last"
                              name="i_sPhone3"
                              title="전화번호"
                              type="text"
                              maxlength="4"
                              oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                            >
                          </div>
                          <div
                            v-if="phoneType === 'C030'"
                            class="C030 duplPhono"
                          >
                            <!-- 무선 전화번호 타입 -->
                            <div
                              class="select_box"
                              style="vertical-align: middle;"
                            >
                              <label
                                aria-hidden="true"
                                for="call_select01"
                              >선택</label>
                              <select
                                id="call_select01"
                                ref="telNumberC030First"
                                v-model="telNumber.C030.first"
                                name="i_sHpNo1"
                                title="무선전화 앞자리 선택"
                              >
                                <option value="선택">
                                  선택
                                </option>
                                <option value="010">
                                  010
                                </option>
                                <option value="011">
                                  011
                                </option>
                                <option value="016">
                                  016
                                </option>
                                <option value="017">
                                  017
                                </option>
                                <option value="018">
                                  018
                                </option>
                                <option value="019">
                                  019
                                </option>
                              </select>
                            </div>
                            <span class="txt_hyphen">-</span>
                            <input
                              ref="telNumberC030Middle"
                              v-model="telNumber.C030.middle"
                              name="i_sHpNo2"
                              title="전화번호"
                              type="text"
                              maxlength="4"
                              oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                            >
                            <span class="txt_hyphen">-</span>
                            <input
                              ref="telNumberC030Last"
                              v-model="telNumber.C030.last"
                              name="i_sHpNo3"
                              title="전화번호"
                              type="text"
                              maxlength="4"
                              oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                            >
                            <div class="btn-parent">
                              <a
                                class="btn btn_sendnum"
                                @click="requestSMSCert"
                              >SMS인증하기</a>
                            </div>
                          </div>
                        </div>
                      </dd>
                    </dl>
                    <dl
                      v-if="phoneType === 'C030'"
                      class="C030 duplPhono w100"
                    >
                      <dt>인증번호 입력</dt>
                      <dd>
                        <input
                          ref="auth_no"
                          v-model="auth_no"
                          name="i_sCertificationNo"
                          title="인증번호"
                          class="wid452"
                          type="text"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <div class="btn-parent">
                          <a
                            class="btn btn_checknum"
                            @click="requestCert"
                          >인증번호확인</a>
                        </div>
                      </dd>
                    </dl>
                    <dl>
                      <dt>SKYPASS 회원번호</dt>
                      <dd>
                        <input
                          ref="memberNumber"
                          v-model="member_no"
                          name="i_sHanatour"
                          title="SKYPASS 회원번호"
                          class="onlyNumber wid452"
                          type="text"
                          maxlength="12"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <div class="btn-parent">
                          <a
                            href="https://kr.koreanair.com/content/koreanair/korea/ko/skypass/benefits.html#skypass-elite"
                            target="_blank"
                          >SKYPASS 확인하기</a>
                        </div>
                      </dd>
                    </dl>
                    <table id="event_info_input_table">
                      <caption class="blind">
                        이벤트 참여 입력
                      </caption>
                      <colgroup>
                        <col width="190">
                        <col width="500">
                      </colgroup>
                    </table>
                  </div>
                </div>
                <br>
                <div class="check_box check_box-bolck">
                  <input
                    id="yes"
                    v-model="privacyAgree"
                    name="yesno"
                    class="myClass YNchk"
                    type="checkbox"
                    value="yes01"
                  >
                  <label for="yes"><span>개인정보 제3자 제공안내 / 광고성 정보수신에 관한 동의</span></label>
                </div>
                <div>
                  <a
                    class="btn_view"
                    @click="privacyPopup = true"
                  >개인정보 제3자 제공안내 / 광고성 정보수신에 관한 동의 안내 보기</a>
                </div>
                <div
                  class="btn_center"
                  style="margin-top: 0;padding-bottom: 70px;"
                >
                  <a
                    class="btn_type01 btnApply round-type"
                    @click="requestFinal"
                  >요금제 신청하기</a>
                </div>
              </div>
            </div>
          </div>
        <!-- EVENT_SITE_DISPLAY ne 'R' -->
        </div>
      </div>
    </div>

    <!-- 개인정보 수집 동의 안내 팝업 -->
    <div
      v-if="privacyPopup"
      id="popMedium01"
      class="pop_wrap02"
      style="display: block"
    >
      <div class="medium">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term mt0">
            <p class="txt-m">
              SK텔링크 주식회사는 고객감사 특별이벤트 진행을 위하여 아래와 같이 고객정보를 수집하며,<br>고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보의 항목 : 이름, 가입 서비스번호, 연락처 (SMS인증 포함), SKYPASS 회원번호</li>
              <li>개인정보의 수집 및 이용목적: 요금제 신청, 고객 상담 등 요금제 관련 상담 진행</li>
              <li>개인정보의 보유 및 이용기간: <strong>요금제 취소신청으로부터 1개월</strong></li>
            </ul>
            <p>개인정보 수집에 대해서 동의/거부할 권리가 있으나 거부 시 요금제 신청이 제한 될 수 있습니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="privacyPopup = false"
          >확인</a>
        </div>
        <a
          @click="privacyPopup = false"
        >
          <img
            alt="닫기"
            src="http://www.sktelink.com/images/btn/btn_pop_close.png"
          >
        </a>
      </div>
    </div>

    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'

export default {
  name: 'KoreanairSkypass',
  components: {
    Popup
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      privacyPopup: false,
      privacyAgree: false,
      phoneType: 'C030',
      name: '',
      auth_no: '',
      ticket: '',
      member_no: '',
      telNumber: {
        C030: {
          first: '선택',
          middle: '',
          last: ''
        },
        C020: {
          first: '선택',
          middle: '',
          last: ''
        }
      },
      alertPopup: {
        visible: false,
        message: '',
        target: ''
      },
    }
  },
  computed: {
    tel_C030_no() {
      return `${this.telNumber.C030.first}${this.telNumber.C030.middle}${this.telNumber.C030.last}`
    },
    tel_C020_no() {
      return `${this.telNumber.C020.first}${this.telNumber.C020.middle}${this.telNumber.C020.last}`
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if(this.callbackAlert.code === '0002' || this.callbackAlert.code ==='E011' || this.callbackAlert.code ==='E027' || this.callbackAlert.code ==='E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code ==='E017' || this.callbackAlert.code ==='E018'){
          this.resetPage()
          window.scrollTo(0, 0)
          document.getElementById('wrap').scrollTop = 0
        }
        if (this.callbackAlert.code === 'E002') {  // 인증5화 오류
          this.phoneType = 'C030',
          this.telNumber= {
            C030: {
              first: '선택',
              middle: '',
              last: ''
            },
            C020: {
              first: '선택',
              middle: '',
              last: ''
            }
          },
          this.auth_no = ''
          this.ticket = ''
        }
        if (this.callbackAlert.code === 'E001') { // 인증시간 초과
          this.auth_no = ''
          this.ticket = ''
        }
      }
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    alertPopupHandler() {
      this.alertPopup.visible = false
      this.alertPopup.message = ''
      if (this.alertPopup.target) {
        this.$refs[this.alertPopup.target].focus()
      }
      this.alertPopup.target = ''
    },
    showAlertPopup(message) {
      this.alertPopup.message = message
      this.alertPopup.visible = true
    },
    checkPrivacy() {
      if(!this.privacyAgree) {
        this.showAlertPopup('개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 요금제 가입이 가능합니다.')
        return false
      } else {
        return true
      }
    },
    checkInputName() {
      if(!this.name) {
        this.alertPopup.target = 'userName'
        this.showAlertPopup('성명을 입력하여 주세요.')
        return false
      } else {
        return true
      }
    },
    checkPhoneNumberC030() {
      if (this.telNumber.C030.first === '선택') {
        this.alertPopup.target = 'telNumberC030First'
        this.showAlertPopup('전화번호를 입력하여 주세요.')
        return false
      } else if (!this.telNumber.C030.middle) {
        this.alertPopup.target = 'telNumberC030Middle'
        this.showAlertPopup('전화번호를 입력하여 주세요.')
        return false
      } else if (!this.telNumber.C030.last) {
        this.alertPopup.target = 'telNumberC030Last'
        this.showAlertPopup('전화번호를 입력하여 주세요.')
        return false
      } else if(this.telNumber.C030.middle.length < 3 || this.telNumber.C030.last.length < 4) {
        this.showAlertPopup('전화번호가 유효하지 않습니다.')
        return false
      } else if (!this.name) {
        this.checkInputName()
      } else {
        return true
      }
    },
    checkPhoneNumberC020() {
      if (this.telNumber.C020.first === '선택') {
        this.alertPopup.target = 'telNumberC020First'
        this.showAlertPopup('전화번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.C020.middle) {
        this.alertPopup.target = 'telNumberC020Middle'
        this.showAlertPopup('전화번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.C020.last) {
        this.alertPopup.target = 'telNumberC020Last'
        this.showAlertPopup('전화번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.C020.middle.length < 3 || this.telNumber.C020.last.length < 4) {
        this.showAlertPopup('전화번호가 유효하지 않습니다.')
        return false
      } else if (!this.name) {
        this.checkInputName()
      } else {
        return true
      }
    },
    checkAuthNumber() {
      if (!this.auth_no) {
        this.alertPopup.target = 'auth_no'
        this.showAlertPopup('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkMemberNumber() {
      if(!this.member_no) {
        this.alertPopup.target = 'memberNumber'
        this.showAlertPopup('SKYPASS 회원번호를 입력하여 주세요.')
        return false
      } else if(this.member_no.length < 12) {
        this.alertPopup.target = 'memberNumber'
        this.showAlertPopup('SKYPASS 회원번호는 12자리입니다.<br>확인하시고 입력해 주세요.')
      } else {
        return true
      }
    },
    // SMS인증하기
    requestSMSCert() {
      if (this.checkPhoneNumberC030()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.tel_C030_no })
        .then(response => {
          if (response.data.sms_ticket) {
            this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
            this.ticket = response.data.sms_ticket
          }
        })
      }
    },
     // 인증번호확인
    requestCert() {
      if(!this.ticket) {
        this.showAlertPopup('휴대폰 인증을 해주세요.')
      } else if (this.checkAuthNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
        .then(response => {
          if (response.data.result_cd === '0001') {
            this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
          }
        })
      }
    },
    // 요금제 신청하기
    requestFinal() {
      if(this.phoneType === 'C030') {
        // 무선번호 경우
        if (this.checkPrivacy() && this.checkInputName() && this.checkPhoneNumberC030() && this.checkAuthNumber() && this.checkMemberNumber()) {
          api.EventPostReqPartnerEvent({
            sms_ticket: this.ticket,
            event_no: 'ZZ000000000000005051',
            member_no: this.member_no
          })
        }
      } else {
        // 유선번호 경우
        if (this.checkPrivacy() && this.checkInputName() && this.checkPhoneNumberC020() && this.checkMemberNumber()) {
          api.EventPostReqPartnerEvent({
            tel_no: this.tel_C020_no,
            event_no: 'ZZ000000000000005051',
            member_no: this.member_no
          })
        }
      }
    },
    resetPage(){
      this.name = '',
      this.phoneType = 'C030',
      this.telNumber= {
        C030: {
          first: '선택',
          middle: '',
          last: ''
        },
        C020: {
          first: '선택',
          middle: '',
          last: ''
        }
      },
      this.auth_no = ''
      this.member_no = ''
      this.privacyAgree = false,
      this.ticket = ''
    }
  }
}
</script>

<style lang="scss" scoped>
#KOREANAAIR_SKYPASS {
  .event_wrap {
    padding-top: 0;
    .event_view {
      padding-top: 0;
      & > div {
        margin-top: 0;
      }
    }
  }
}
</style>

