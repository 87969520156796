
<template>
  <div id="BAROCLUB">
    <div class="event_wrap baroclub">
      <div class="event_view">
        <div>
          <img
            src="/content/images/partnership/220510_baroclub_img1.jpg"
            alt="T로밍baro요금제 고객을 위한 SK국제전화 30분 무료통화!"
          ><img
            src="/content/images/partnership/220510_baroclub_img2.png"
            alt="T로밍baro요금제 고객님을 위한 특별한 혜택!"
          >
          <div class="event_active_btn">
            <router-link
              to="/partnership/BaroClubCheck"
            >
              <img
                src="/content/images/partnership/220510_baroclub_text1.png"
                alt="무료통화 신청하기"
              >
            </router-link>
          </div>
          <img
            src="/content/images/partnership/220510_baroclub_img3.png"
            alt="국제전화 00700 이용방법"
          >
          <div class="event_caution">
            <h2>
              <img
                src="/content/images/partnership/220510_baroclub_text2.png"
                alt="무료통화 신청 시 꼭 읽어보세요!"
              >
            </h2>
            <ul>
              <li>본 이벤트에 참여하기 위해서는 개인정보 수집, 활용 및 취급위탁에 대한 동의가 필요합니다.</li>
              <li>T로밍 baro 요금제 가입 후, 혜택 대상 고객님에 한해 제공 가능합니다. <br>(T로밍 baro 요금제 혜택 대상 여부는 T월드 앱 또는 홈페이지 > T로밍 > 나의 혜택에서 확인 가능)</li>
              <li>무료통화 이용을 위해 무선번호(휴대폰번호)만 참여가 가능하며, 00700을 이용하실 번호를 정확히 입력하셔야 합니다.</li>
              <li>일정 통화분수를 제공하는 정액요금제 등 이용고객님은 해당 통화분수 소진 후 30분 무료가 적용됩니다.</li>
              <li>위성/화상전화, 국제SMS 이용 시에는 무료통화가 적용되지 않습니다.</li>
              <li>본 이벤트 이외 참여를 통한 무료통화권 등록 시 해당 무료통화 소진 후 적용이 가능합니다.</li>
              <li>선불형 이동전화는 무료통화 적용이 불가합니다.</li>
              <li>비정상적이거나 불법적인 방법으로 이벤트에 참여하신 경우 혜택 대상에서 제외되실 수 있습니다.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaroClub',
  components: {},
  props: {},
  emits: [],
  data () {
    return {}
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');
.baroclub {
  .event_active_btn {
    padding: 47px 0 88px;
    background-color: #2255c8;
    img { width: auto; }
  }
  .event_caution {
    padding: 70px 70px;
    background: #2869df;
    color: #fff;
    h2 {
      margin-bottom: 65px;
      text-align: center;
      img { width: auto; }
    }
    li {
      position: relative;
      padding-left: 25px;
      color: #fff;
      font-family: 'NanumSquareRound',sans-serif;
      font-size: 33px;
      font-weight: 400;
      line-height: 1.67;
      text-align: left;
      letter-spacing: -0.04em;
      &:before {
        position: absolute;
        content: "-";
        top: -1px;
        left: 0;
      }
    }
  }
}

.closeEvent {max-height: 100vh; overflow: hidden;}

@media (max-width: 1023px) {
  .baroclub {
    .event_active_btn {
      padding: 30px 0 45px;
      img { width: 55%; }
    }
    .event_caution {
      padding: 45px 25px;
      h2 {
        margin-bottom: 20px;
        img { width: 38%; }
      }
      li {
        font-size: 15px;
        padding: 0 0 0 10px;
        &:before {
          top: 0;
        }
        br { display: none; }
      }
    }
  }
  .pop_wrap02 .medium { padding-bottom: 20px; }
}
</style>

