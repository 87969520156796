<template>
  <div v-if="isVisible">
    <div
      class="pop_wrap m-size"
      style="display:block"
    >
      <div class="alert">
        <h3> {{ popTitle }}</h3>
        <div class="popup-inner">
          <slot> 팝업 컨텐츠 </slot>
        </div>
        <div class="not-week">
          <div class="check_box">
            <input
              id="week"
              v-model="agreeClose"
              type="checkbox"
              @change="changeAgreeClose"
            >
            <label for="week"><span>일주일간 이 창을 열지 않음</span></label>
          </div>
        </div>
        <a
          class="pop-close"
          @click="$emit('update:is-visible', false)"
        ><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <div
      id="msgDimd"
      class="dimd"
      style="display: block;"
    />
  </div>
</template>

<script>
export default {
  name: 'DefaultPopup',
  props: {
    popTitle: {
      type: String,
      required: true
    },
    buttonText:{
      type: String,
      required: false,
      default: '확인'
    },
    isVisible: {
      type: Boolean,
      required: true
    },
    cType: {
      type: String,
      required: false,
      default: 'btn_type01'
    }
  },
  data() {
    return {
      agreeClose: false
    }
  },
  methods:{
    popupClose(){
      this.$emit('click')
    },
    buttonHandler() {
      this.$emit('click')
    },
    changeAgreeClose() {
      this.$emit('change', this.agreeClose)
    }
  }
}
</script>

<style>

</style>