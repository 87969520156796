<template>
  <div v-if="isVisible">
    <div
      id="popAlertSim"
      class="pop_wrap m-size"
      style="display: block;"
    >
      <div class="alert">
        <h3>본인인증확인</h3>
        <div class="popup-inner">
          <p>
            <span class="txt_point01">조회 및 신청</span> 서비스를 <br>
            이용하기 위해서는 <br>휴대폰 본인인증을 진행해주세요.
          </p>
          <div class="btn_center">
            <a
              class="btn_type01"
              @click="handlerClickCert"
            >휴대폰 본인인증</a>
          </div>
        </div>
        <a
          class="pop-close"
          @click="handlerClick"
        ><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <div
      id="msgDimd"
      class="dimd"
      style="display: block;"
    />
  </div>
</template>

<script>
export default {
  name: 'Popup',
  props: {
    // serviceName: {
    //   type: String,
    //   required: true
    // },
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    handlerClick() {
      this.$emit('close', false)
    },
    handlerClickCert() {
      this.$emit('cert-mobile')
    }
  }
}
</script>

<style>

</style>