<template>
  <div id="00700_APP">
    <Header ref="header" />
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb ref="breadcrumb" />
      <div id="contents">
        <div class="sub_header bg11">
          <h3 class="title">
            국제전화 00700 앱
          </h3>
          <p class="txt">
            끊김 없이 들린다 끊임없이 누른다<br>국제전화 사용할 땐, 국제전화 00700 앱으로 더 편하게! 더 쉽게!
          </p>
          <img
            src="/content/images/sub_header_inter11-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>
        <!-- inter -->
        <div class="inter">
          <div
            ref="app_download"
            class="app_download"
            :class="{ 'is-sticky' : isSticky }"
          >
            <div
              ref="sticky"
              class="sticky w_hide"
              :class="{ 'sticky-bottom': stickyBottom }"
            >
              <div class="wrap">
                <h4><em>국제전화 00700 앱</em>을<br> 설치하세요!</h4>
                <div class="method">
                  <div class="qrcode">
                    <h5>QR코드 스캔하여 스토어에서 설치</h5>
                    <ul>
                      <li>
                        <div>
                          <img
                            src="/content/images/img_inter32.png"
                            alt="Google Play QR코드"
                          >
                        </div>
                        <p>Google Play<br>다운로드</p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/img_inter33.png"
                            alt="APP Store QR코드"
                          >
                        </div>
                        <p>App Store<br>다운로드</p>
                      </li>
                    </ul>
                  </div>
                  <div class="sms">
                    <h5>SMS로 다운로드 링크 보내기</h5>
                    <div>
                      <input
                        id="i_sHpNo"
                        ref="telNo"
                        v-model="telNo"
                        type="text"
                        title="휴대폰번호 입력"
                        placeholder="휴대폰번호 입력"
                        name="i_sHpNo"
                        maxlength="11"
                        class="ui-autocomplete-input"
                        autocomplete="off"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        @keyup.enter="request"
                      >
                      <button
                        id="btnSend"
                        type="button"
                        class="btn_type01"
                        @click="request"
                      >
                        전송하기
                      </button>
                      <div class="check_box">
                        <input
                          id="sms_agree"
                          v-model="smsCheckbox"
                          type="checkbox"
                          name="sms_agree"
                        >
                        <label for="sms_agree"><span>SMS 전송을 위한 개인정보이용동의</span></label>
                        <a
                          id="footerResponsibility"
                          @click="popup.privacy.isVisible = true"
                        >(<span>내용보기</span>)</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="m_hide">
              <h4>국제전화 00700 앱을<br>설치하세요!</h4>
              <p>끊김 없이 들린다 끊임없이 누른다<br>국제전화 사용할 땐,<br>국제전화 00700 앱으로 더 편하게! 더 쉽게!</p>
              <div class="btn_box">
                <a
                  href="https://play.google.com/store/apps/details?id=com.sktelink.sk00700"
                  target="_blank"
                ><img
                  src="/content/images/btn_google_play_download.png"
                  alt="GET IT ON Google Play"
                ></a>
                <a
                  href="https://apple.co/2zTND4b "
                  target="_blank"
                ><img
                  src="/content/images/btn_app_store_download.png"
                  alt="Download on the App Store"
                ></a>
                <!-- <a href="https://itunes.apple.com/kr/app/sk%EA%B5%AD%EC%A0%9C%EC%A0%84%ED%99%94-00700/id557484330?mt=8" target="_blank"><img src="/images/mobile/btn/btn_app_store_download.png" alt="Download on the App Store"></a> -->
              </div>
            </div>
          </div>
          <!-- <script>
            // app_download sticky script
            $(window).on('scroll.appDownload, resize.appDownload, load.appDownload', function () {
            var $appDownload = $('.app_download');
            var $appDownloadSticky = $appDownload.find('.sticky');
            var scrollTop = $(window).scrollTop();
            var headerHeight = $('#header').height() + $('.location').outerHeight(true);
            var stickyPosition = $appDownload.offset().top + $appDownload.outerHeight(true);
            var hasSticky = $appDownload.hasClass('is-sticky');
            var footerOffsetTop;
            var positionTop;

            if (scrollTop + headerHeight > stickyPosition && hasSticky === false) {
            $appDownload.addClass('is-sticky');
            $appDownloadSticky.css('opacity', '0').stop().animate({'opacity': '1'}, 700);
            } else if (scrollTop + headerHeight < stickyPosition && hasSticky) {
            $appDownload.removeClass('is-sticky');
            return false;
            }

            footerOffsetTop = $('#footer').offset().top;

            if ($(window).height() + scrollTop > footerOffsetTop) {
            positionTop = footerOffsetTop - $appDownloadSticky.outerHeight(true);
            $appDownloadSticky.css({'position':'absolute', 'top': positionTop, 'bottom': 'auto'});
            } else if ($appDownloadSticky.css('position') === 'absolute') {
            $appDownloadSticky.removeAttr('style');
            }
            });
          </script> -->
          <div class="app_service_list">
            <ul>
              <li>
                <h4><span>1</span>전세계 200여개 국가를 연결하는<br><em>최고의 통화품질</em></h4>
                <div>
                  <img
                    src="/content/images/img_inter34.png"
                    alt=""
                    class="w_auto"
                  >
                  <p>1분 1초가 아까운 가족, 지인, 비즈니스 파트너들과의 통화시간.<br class="w_hide">00700 앱만 있다면 끊기지 않는 국제전화를<br class="w_hide">더욱 간편하게 사용하실 수 있습니다.</p>
                </div>
              </li>
              <li>
                <h4><span>2</span>복잡한 절차 없이 버튼 하나로<br><em>다양한 요금제 가입 가능</em></h4>
                <div>
                  <img
                    src="/content/images/img_inter35.png"
                    alt=""
                    class="w_auto"
                  >
                  <p>00700 앱에서는 국제전화 요금제 가입을 위한<br class="w_hide">복잡한 인증 절차가 필요 없습니다.<br class="w_hide">고객님께 맞는 국제전화 요금제를 직접 선택해 사용해보세요!</p>
                </div>
              </li>
              <li>
                <h4><span>3</span>00700 앱에서만 열리는<br class="m_hide"><em>다채로운 이벤트</em></h4>
                <div>
                  <img
                    src="/content/images/img_inter36.png"
                    alt=""
                    class="w_auto"
                  >
                  <p>국제전화 00700 앱 이용 고객이라면 제한없이 자유롭게<br class="w_hide">이벤트에 참여하실 수 있습니다.<br class="w_hide">기프티콘부터 상품권, 항공 마일리지, 전자제품까지<br class="w_hide">빵빵한 경품 놓치지 마세요!</p>
                </div>
              </li>
              <li>
                <h4><span>4</span>국가별요금부터<br class="m_hide">국제전화 사용량까지<br><em>알고 싶었던 정보를 한눈에 확인</em></h4>
                <div>
                  <img
                    src="/content/images/img_inter37.png"
                    alt=""
                    class="w_auto"
                  >
                  <p>국제전화를 사용할 때마다 궁금했던 부분을 00700 앱이<br class="w_hide">속 시원하게 풀어드립니다.<br class="w_hide">가입한 요금제에 따른 국가별요금, 현지 시간 등의 해외정보와<br class="w_hide">국제전화 사용량(당월/전월) 등을 쉽게 확인하실 수 있습니다.</p>
                </div>
              </li>
              <li>
                <h4><span>5</span>1:1 문의를 통해 언제 어디서든<br><em>궁금증 해결</em></h4>
                <div>
                  <img
                    src="/content/images/img_inter38.png"
                    alt=""
                    class="w_auto"
                  >
                  <p>국제전화 00700 사용에 대한 모든 궁금증은<br class="w_hide">1:1 문의로 남겨주시면 고객센터로 전화하지 않아도<br class="w_hide">원하는 답변을 얻으실 수 있습니다.</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- //inter -->
      </div>
    </div>
    <div ref="footer">
      <Footer />
    </div>
    <SummitControl />
    <popup-big
      :pop-heading="'개인정보 수집 동의 안내'"
      :btn-show="true"
      :is-visible.sync="popup.privacy.isVisible"
      @click="popup.privacy.isVisible = false"
      @close="popup.privacy.isVisible = false"
    >
      <h4 class="title">
        개인정보 수집 동의 안내
      </h4>

      <div class="cont_term">
        <p>
          SK텔링크 주식회사는 국제전화 이용에 대한 혜택제공을 위해 아래와 같이 고객정보를 수집하며,<br>
          고객정보 취급에 관한 상세한 사항은 <a
            href="http://www.sktelink.com"
            class="txt_point01"
          >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
        </p>
        <ul class="txt_point01">
          <li>수집하는 개인정보의 항목 : 휴대폰번호(연락처)</li>
          <li>개인정보의 수집 및 이용목적 : SK국제전화 앱 다운로드 URL 전송</li>
          <li>개인정보의 보유 및 이용기간 : <strong>SK국제전화 앱 다운로드 URL 전송 후 파기</strong></li>
        </ul>
        <p>SK국제전화 앱 다운로드 URL을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</p>
      </div>
    </popup-big>
    <PopupCaptchar
      ref="popupCatchar"
      :is-visible.sync="popup.captchar.isVisible"
      @click="connectLink"
      @close="popup.captchar.isVisible = false"
    />
  </div>
</template>

<script>
import api from '@/api'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'
import PopupBig from '@/components/popup/PopupBig'
import PopupCaptchar from '@/components/popup/PopupCaptchar'

export default {
  name: 'App00700',
  metaInfo: {
    title: '국제전화 00700앱 | 국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
    PopupBig,
    PopupCaptchar
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      telNo: '',
      smsCheckbox: '',
      popup: {
        privacy: {
          isVisible: false,
        },
        captchar:{
          isVisible: false,
        }
      },
      isSticky: false,
      stickyBottom: false,
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted () {
    setTimeout(() => {
      window.addEventListener('scroll', e => {
        const scrollTop = window.scrollY || window.pageYOffset
        const footerOffsetTop = this.$refs.footer.getBoundingClientRect().top + scrollTop

        if (scrollTop > 700) {
          this.isSticky = true

          if((window.innerHeight + scrollTop) > footerOffsetTop) {
            this.stickyBottom = true
          } else {
            this.stickyBottom = false
          }
        } else {
          this.isSticky = false
          this.stickyBottom = false
        }
      })
    }, 500)
  },
  methods: {
    request() {
      if(!this.telNo) {
        this.$store.commit('ON_ALERT_POPUP', { message: '휴대폰번호를 입력하세요.'})
        this.$refs.telNo.focus()
      } else if(this.telNo.length < 10) {
        this.$store.commit('ON_ALERT_POPUP', { message: '잘못된 휴대폰번호 입니다.'})
        this.$refs.telNo.focus()
      } else if (!this.smsCheckbox) {
        this.$store.commit('ON_ALERT_POPUP', { message: '개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 전송이 가능합니다.' })
      } else if(this.telNo[0] !== "0" || this.telNo[1] !== "1") {
        this.$store.commit('ON_ALERT_POPUP', { message: '잘못된 휴대폰번호 입니다.'})
        this.$refs.telNo.focus()
      }else {
        this.popup.captchar.isVisible = true
      }
    },
    connectLink(){
      if(this.$refs.popupCatchar.isPassCaptchar === false){
        this.$store.commit('ON_ALERT_POPUP', { message: '보안문자를 바르게 입력해 주세요.' })
      }else {
        let data = {
          "tel_no": this.telNo,
          "req_tp": "00700APP"
        }
        api.ItsvcGetReqAppLink(data)
        .then(response => {
          this.popup.captchar.isVisible = false
          if(response.data.result_cd === '0002') {
            this.$store.commit('ON_ALERT_POPUP', { message: '다운로드 링크를 SMS로 전송하였습니다.' })
            this.telNo = ''
            this.smsCheckbox = ''
            this.$refs.popupCatchar.isPassCaptchar = false
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sticky {
  // opacity: 0;
  transition:opacity 0.7s;
  &.sticky-bottom{
    position:absolute;
    bottom: 644px;
  }
}
.is-sticky{
  .sticky {
    animation-duration: 0.7s;
    animation-name: fadein;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
