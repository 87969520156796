
<template>
  <div id="FREE_EVENT">
    <div class="event_wrap type2 type_05">
      <div class="event_view">
        <div>
          <span>
            <img
              alt="International Call 00701, 쿠폰 등록하시고 국제전화 이용하세요!"
              src="/content/images/partnership/220907_free10_img01.jpg"
              @load="onLoad"
            >
          </span>
          <div class="event_view_field bg">
            <div class="insWrap">
              <div class="msg">
                쿠폰을 등록할 핸드폰 번호를 알려주세요.<br>
                Share your cellphone number for Coupon.
              </div>
              <div class="check_box">
                <input
                  id="yes1"
                  v-model="agreePrivacy1"
                  name="yesno1"
                  type="checkbox"
                  value="yes01"
                >
                <label for="yes1"> <span>개인정보 수집 및 이용에 동의<br>Consent to collect and use personal information.</span></label>
                <a
                  v-if="!isVisiblePrivacy1"
                  class="btn_view"
                  @click="isVisiblePrivacy1 = true"
                >자세히 보기 Learn more</a>
                <a
                  v-if="isVisiblePrivacy1"
                  class="btn_view close"
                  @click="isVisiblePrivacy1 = false"
                >자세히 보기 Learn more</a>
              </div>
              <div
                v-if="isVisiblePrivacy1"
                class="privacyCnt"
              >
                <p>
                  SK텔링크 주식회사는 국제전화 이용 및 프로모션에 대한 혜택 제공을 위해 아래와 같이 고객정보를 수집하며,<br class="w_hide">
                  고객정보 취급에 관한 상세한 사항은 <a
                    href="http://www.sktelink.com/privacy/intro"
                    target="blank"
                  >http://www.sktelink.com/privacy/intro</a>에 게시된<br class="w_hide">
                  개인정보 처리방침을 참고하시기 바랍니다.
                </p>
                <ul>
                  <li>· 수집하는 개인정보의 항목 : 휴대폰번호(연락처)</li>
                  <li>· 개인정보 수집 및 이용목적 : SK국제전화 이벤트 응모 및 경품 추첨</li>
                  <li>· 개인정보 보유 및 이용기간 : <strong>SK국제전화 요금제 가입 시점부터 해지일자까지</strong></li>
                </ul>
                <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 요금제/프로모션 혜택 제공에 제한이 될 수 있습니다.</p>
                <p>SK국제전화 요금제/프로모션 혜택을 받기 위해 위와 같이 본인의 개인정보 수집 및 이용하는 것에 동의합니다.</p>
              
                <hr>
              
                <p>
                  SK Telink Co., Ltd. collects customer information as follows to provide benefits for<br class="w_hide">
                  international call use and promotion. For more information on handling customer information,<br class="w_hide">
                  please refer to the Privacy Policy posted on <a
                    href="http://www.sktelink.com/privacy/intro"
                    target="blank"
                  >http://www.sktelink.com/privacy/intro</a>
                </p>
                <ul>
                  <li>· Items of personal information to be collected : Mobile phone number (contact)</li>
                  <li>
                    · Purpose of collecting and using personal information : Join our Event and Send Gift Draw phone plan Personal
                  </li>
                  <li>
                    · Information retention and use period : <strong>From the time you subscribe to SK International Phone Plans to
                      the termination date</strong>
                  </li>
                </ul>
                <p>
                  You have the right to agree or reject to the collection of personal information, but if you refuse,
                  you may be restricted from receiving plan or promotion benefits.
                </p>
                <p>
                  I agree to collect and use my personal information as above to receive SK International Phone Plan or Promotion
                  benefits.
                </p>
              </div>
              <div class="table_type01">
                <dl>
                  <dt>본인인증</dt>
                  <dd>
                    <div class="phone-box_wrap">
                      <div class="phone-box">
                        <div class="select_box">
                          <select
                            id="call_select01"
                            ref="telNumberFirst"
                            v-model="telNumber.first"
                            name="i_sHpNo1"
                            title="무선전화 앞자리 선택"
                          >
                            <option value="010">
                              010
                            </option>
                            <option value="011">
                              011
                            </option>
                            <option value="016">
                              016
                            </option>
                            <option value="017">
                              017
                            </option>
                            <option value="018">
                              018
                            </option>
                            <option value="019">
                              019
                            </option>
                          </select>
                        </div>
                        <span class="txt_hyphen" />
                        <input
                          ref="telNumberMiddle"
                          v-model="telNumber.middle"
                          name="i_sHpNo2"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen" />
                        <input
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <a
                        class="btn"
                        @click="requestSMSCert"
                      >
                        <img
                          alt="인증요청"
                          src="/content/images/partnership/220907_free50_text01.png"
                        ><span>Send Verification Number</span></a>
                    </div>
                  </dd>
                </dl>
                <dl
                  v-if="authNumberInputVisible"
                  id="numberCheck"
                  class="cert"
                >
                  <dt>인증번호 입력</dt>
                  <dd>
                    <input
                      ref="certNumber"
                      v-model="auth_no"
                      name="i_sCertificationNo"
                      title="인증번호"
                      type="text"
                      maxlength="6"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      placeholder="인증번호를 입력해주세요. Enter a Verification Number."
                    ><a
                      class="btn"
                      @click="requestCert"
                    >
                      <img
                        alt="확인"
                        src="/content/images/partnership/220907_free50_text02.png"
                      ><span>Confirm</span></a>
                  </dd>
                </dl>
                <dl
                  v-if="couponCodeVisible"
                  class="code"
                >
                  <dt>쿠폰코드</dt>
                  <dd>
                    <div class="select_box">
                      <select
                        id="i_sUserNm"
                        ref="select1"
                        v-model="selectCouponCode"
                        name="i_sUserNm"
                        title="쿠폰코드 선택하기"
                      >
                        <option value="FR02">
                          FR02
                        </option>
                      </select>
                    </div><a
                      id="btnApply"
                      class="btn_type01"
                      @click="registrationFinal"
                    >
                      <img
                        alt="등록하기"
                        src="/content/images/partnership/220907_free50_text03.png"
                      ><span>Get it!</span></a>
                  </dd>
                </dl>
              </div>
            </div>
            
            <img
              alt="이용안내"
              src="/content/images/partnership/220907_free10_img02.png"
              @load="onLoad"
            >
          </div>
          <div class="event_view_field">
            <div class="event_caution">
              <h1>※ 유의사항</h1>
              <ul>
                <li>할인쿠폰은 사용 유효기간에(등록 후 3개월 이내) 한해 사용하실 수 있습니다.</li>
                <li>쿠폰 이용은 핸드폰번호 당 1회에 한해 제공됩니다.</li>
                <li>10분 무료 통화 초과 사용 시, 00701 서비스이용료 11,000원(부가세포함)이 부과됩니다.</li>
                <li>00701은 전 세계 어디든 11,000원(부가세포함)으로 100분(유효기간:첫 통화 후 30일) 통화 가능한 국제전화 서비스입니다.</li>
                <li>00701은 11,000원(VAT포함)은 00701 첫 통화 시 부과되며, 잔여 분수 및 유효기간은 sktelink.com/partnership/sk00701 에서 확인 가능합니다. </li>
                <li>단, 할인쿠폰은 청구할인으로 제공되어 이용안내문자 및 잔여분수조회 시, 미반영 되어 조회됩니다.</li>
                <li>00701 휴대폰 이용요금은 청구서에 11,000원(부가세포함)이 함께 청구됩니다. (국제전화 이용료 또는 국제전화기본료로 표기됨)</li>
                <li>국제전화 00701 문의 : 고객센터 1599-0999 (월~금 오전9시~18시)</li>
              </ul>

              <hr>

              <h1>※  NOTE</h1>
              <ul>
                <li>The promotion code is valid for 3 months after registering.</li>
                <li>Only one time get 50% discount benefit. (The promotion couldn’t be applied repeatedly.)</li>
                <li>After you used up the Free 10 minutes, it charges ₩11,000 (including VAT) for 00701 International call fee.</li>
                <li>00701 is the worldwide coverage International Call service at ₩11,000(including VAT) for 100minutes for 30days.</li>
                <li>00701 charges ₩11,000(including VAT) at your first call with 00701, and provide your available call minutes and valid date on your pre-charged budget on www.sktelink.com/partnership/sk00701.</li>
                <li>But, the promotion(50% discount) doesn’t apply above the website.</li>
                <li>00701 bill, ₩11,000(including VAT) for 100minutes for 30days, is included on the monthly cell phone bill remarked as International Call service.</li>
                <li>00701 Customer Center phone number is 1599-0070 (paid, Monday through Friday, 9pm–18pm) / in Korea only Mail Inquiry: sk007.cs@sk.com</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @close="alertPopupHandler"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    <!-- //팝업 -->

    
    <!-- 팝업 - 전화번호만 입력 + 등록하기 -->
    <article
      v-if="isVisibleError1Popup"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <div class="msg">
            쿠폰코드를 입력하고 등록해주세요.<br>
            Please enter the promotion code.
          </div>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="isVisibleError1Popup = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </article>

    <!-- 팝업 - 쿠폰번호만 입력+등록하기 -->
    <article
      v-if="isVisibleError2Popup"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <div class="msg">
            쿠폰 받을 핸드폰번호를 입력하고 등록해주세요.<br>
            Please enter your cellphone number for promotion.
          </div>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="isVisibleError2Popup = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </article>

    <!-- 팝업 - 전화번호+쿠폰번호 정상입력+등록하기 -->
    <article
      v-if="isVisibleError3Popup"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <div class="msg">
            쿠폰등록이 완료되었습니다.<br>
            Completed! 
          </div>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="isVisibleError3Popup = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </article>

    <!-- 팝업 - 기등록된 전화번호인 경우 -->
    <article
      v-if="isVisibleError4Popup"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <div class="msg">
            이미 쿠폰 사용을 한 핸드폰번호입니다. 이용해주셔서 감사합니다.<br>
            Sorry, this number was already got the promotion benefit.
          </div>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="isVisibleError4Popup = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </article>

    <!-- 팝업 - 쿠폰코드 오류 시 -->
    <article
      v-if="isVisibleError5Popup"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <div class="msg">
            쿠폰번호를 확인하고 입력해주세요.<br>
            Please check your promotion code again.
          </div>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="isVisibleError5Popup = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </article>

    <!-- 팝업 - 쿠폰 유효기간 지난 경우 -->
    <article
      v-if="isVisibleError6Popup"
      class="ui-popup"
    >
      <div class="ui-popup-wrap">
        <div class="ui-popup-container">
          <div class="msg">
            쿠폰 유효기간이 종료되었습니다.<br>
            The valid date is expired.
          </div>
          <div class="ui-btn-wrap">
            <a
              class="ui-btn"
              @click="isVisibleError6Popup = false"
            >
              <img
                src="/content/images/partnership/220511_baroclub_text3.png"
                alt="확인"
              ></a>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  name: 'FREEEVENT',
  components: {
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    isVisiblePrivacy1: false,
    agreePrivacy1:false,
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    telNumber:{
      first:'010',
      middle: '',
      last:''
    },
    authNumberInputVisible: true,
    couponCodeVisible: true,
    isVisibleError1Popup: false,
    isVisibleError2Popup: false,
    isVisibleError3Popup: false,
    isVisibleError4Popup: false,
    isVisibleError5Popup: false,
    isVisibleError6Popup: false,
    ticket: '',
    event_no:'EV000000000000000011',
    auth_no: '',
    selectCouponCode:'FR02',
    add_info: '10분무료'

  }
 },
 computed: {
  svc_tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  callbackAlert(){
    return this.$store.getters.CALLBACK_ALERT
  }
 },
 watch: {
  callbackAlert: {
    deep: true,
    handler() {
      if (this.callbackAlert.code === '0002' || this.callbackAlert.code === '0004' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E0027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code === 'E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E021' || this.callbackAlert.code === 'E022' || this.callbackAlert.code === 'E045' || this.callbackAlert.code === 'E046' || this.callbackAlert.code === 'E047') {
        this.resetPage()
        window.scrollTo(0, 0)
        document.getElementById('wrap').scrollTop = 0
      }
      if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
        this.authNumberInputVisible = false,
        this.telNumber = {
          first:'010',
          middle: '',
          last:''
        },
        this.auth_no = ''
        this.ticket = ''
      }
      if (this.callbackAlert.code === 'E001') { // 인증시간 초과
        this.auth_no = ''
        this.ticket = ''
      }
    }
  }
},
 created () {},
 mounted () {
 },
 methods: {
  alertPopupHandler() {
    this.alertPopup.visible = false
    this.alertPopup.message = ''
    if (this.alertPopup.target) {
      this.$refs[this.alertPopup.target].focus()
    }
    this.alertPopup.target = ''
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.<br>Please enter your cellphone number for promotion.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.<br>Please enter your cellphone number for promotion.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.<br>Please enter your cellphone number for promotion.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.<br>Please enter your cellphone number for promotion.')
      return false
    } else {
      return true
    }
  },
  checkAuthNumber() {
    if (!this.auth_no) {
      this.alertPopup.target = 'certNumber'
      this.showAlertPopup('인증번호를 입력해 주세요.<br>Please enter a verification number.')
      return false
    } else {
      return true
    }
  },
  checkSMSCert() {
    if(!this.isSmsCert) {
      this.showAlertPopup('SMS인증을 완료해 주세요.<br>Please complete the verification.')
      return false
    } else {
      return true
    }
  },
  checkselect(){
    if(!this.agreePrivacy1){
      this.showAlertPopup('개인정보 수집에 동의하셔야 신청이 가능합니다.<br>Please consent to collect and use personal information.')
      return false
    }else if(this.selectCouponCode ==='쿠폰코드 선택'){
      this.showAlertPopup('쿠폰코드를 선택해주세요.<br>Please check your promotion code again.')
      return false
    }else {
      return true
    }
  },
  // SMS 인증 요청
  requestSMSCert() {
    if (this.checkPhoneNumber()) {
      api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
      .then(response => {
        if (response.data.sms_ticket) {
          this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
          this.ticket = response.data.sms_ticket
          this.authNumberInputVisible = true
        }
      })
    }
  },
  // 인증 번호로 SMS 인증 확인
  requestCert() {
    if (this.checkAuthNumber()) {
      api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
      .then(response => {
        if (response.data.result_cd === '0001') {
          this.isSmsCert = true
          this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
          this.couponCodeVisible = true
        }
      })
    }
  },
  //신청완료
  registrationFinal() {
    if (this.checkselect() && this.checkPhoneNumber() && this.checkSMSCert()) {
      api.EventPostReqEntry({
        sms_ticket: this.ticket,
        event_no: this.event_no,
        req_ctgr: this.selectCouponCode,
        req_ct: this.add_info
      })
    }
  },
  resetPage(){
    this.agreePrivacy1 = false,
    this.telNumber= {
      first:'010',
      middle: '',
      last:''
    },
    this.authNumberInputVisible = false,
    this.selectCouponCode = 'FR02',
    this.auth_no = ''
    this.ticket = ''
    this.isSmsCert = false
  },
  //20210917 이벤트 종료
  onLoad() {
    var popitem = document.getElementById("FREE_EVENT");

    // if (new Date() >= new Date('08/19/2022 16:00:00')) {   //반영 시간 지정
    //   this.showAlertPopup('고객님들의 성원에 힘입어 조기 종료되었습니다.<br>감사합니다.')
    //   popitem.classList.add("closeEvent");
    // }
  }
 }
}
</script>

<style lang="scss" scoped>

.check_box {
  display: block;
  position: relative;
  margin-top: 45px;
  line-height: normal;
  & > input:checked {
    & + label {
      background-image: url('/content/images/partnership/220907_free_checkbox_on.png');
      background-size: 28px auto;
    }
  }
  label {
    & > * {
      font-family: 'GmarketSans';
    }
    padding-left: 43px;
    line-height: normal;
    background-image: url('/content/images/partnership/220907_free_checkbox.png');
    background-position: 0 0;
    background-size: 28px auto;
    span {
      color: #222;
      font-size: 22px;
      font-family: 'GmarketSans';
      font-weight: 500;
      line-height: 30px;
    }
  }
  .btn_view {
    position: absolute;
    bottom: 4px;
    right: 0;
    padding: 0 10px 2px;
    color: #222;
    font-size: 22px;
    font-family: 'GmarketSans';
    font-weight: 500;
    background: none;
    &:before {
      content: '';
      position: absolute;
      left: 0; bottom: 0;
      z-index: -1;
      width: 100%; height: 17px;
      background-color: #ffece7;
    }
  }
}

.event_view_field {
  position: relative;
  background-color: #fef1ed;
  &.bg:before {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 144px;
    background: #f55d44 url('/content/images/partnership/220907_free50_img01-1.jpg') no-repeat;
  }
}
.insWrap {
  position: relative;
  z-index: 1;
  margin: 0 57px 70px;
  padding: 70px 48px 60px;
  background-color: #fff;
  border-radius: 40px;
  box-shadow: 0 0 20px rgba(0,0,0,0.08);

  .msg {
    padding-bottom: 65px;
    color: #020202;
    font-size: 40px; line-height: 48px;
    font-family: 'GmarketSans';
    font-weight: 500;
    letter-spacing: -0.06em;
    text-align: center;
    border-bottom: 1px solid #ebebeb;
  }

  .table_type01 {
    width: 100%;
    margin-top: 35px;
    border: 0 none;
    dl {
      border-top: 0;
      &.cert {
        input { width: 625px; }
      }
      &.code {
        .select_box { width: 625px; }
      }
      dt {
        display: none;
      }
      dd {
        padding: 15px 0 15px;
        border: 0;
        .phone-box {
          display: inline-flex;
          justify-content: space-between;
          width: 625px !important;
        }
        a {
          width: 260px;
          height: auto;
          margin-left: 25px;
          padding: 13px 0 8px;
          color: #fff;
          font-size: 25px;
          font-family: 'GmarketSans';
          font-weight: 500;
          line-height: 0;
          background-color: #ff603a;
          border-radius: 10px;
          vertical-align: top;
          img { width: auto; }
          span {
            display: block;
            margin-top: 3px;
            font-size: 15px;
            font-family: 'GmarketSans';
            font-weight: 500;
            letter-spacing: -0.06em;
          }
        }
        .select_box {
          width: 100%;
          height: auto;
          vertical-align: top;
          select {
            position: static;
            min-width: 150px;
            height: auto;
            padding: 21px 0 14px 15px;
            color: #505050;
            font-size: 30px;
            font-family: 'GmarketSans';
            font-weight: 500;
            line-height: normal;
            vertical-align: top;
            background: #fff url('/content/images/partnership/220613_holic_arrow.png') no-repeat right 15px center;
            border: 1px solid #484848;
            border-radius: 10px;
          }
        }
        input {
          width: 100%;
          height: auto;
          padding: 20px 0 15px 15px;
          color: #505050;
          font-size: 30px;
          font-family: 'GmarketSans';
          font-weight: 500;
          line-height: normal;
          background-color: #fff;
          border: 1px solid #484848;
          border-radius: 10px;
          &::placeholder {
            color: #c2c2c2;
            font-size: 20px;
          }
        }
        .txt_hyphen {
          display: inline-block;
          margin: 36px 6px 0;
          padding: 0 7px;
          vertical-align: top;
          border-top: 1px solid #505050;
        }
      }
    }
  }
  .table_msg {
    margin-top: 18px;
    padding-left: 87px;
    color: #303c43;
    font-size: 19px;
    font-family: 'GmarketSans';
    font-weight: 500;
    line-height: 1.5
    // text-align: center;
  }
}
.privacyCnt {
  margin: 40px 0 0;
  padding: 30px 35px 26px;
  background-color: #f4f4f4;
  border-radius: 20px;
  hr { margin: 28px 0 34px; border-top: 2px solid #fff; }
  a {
    color: #ff603a;
    font-family: 'GmarketSans';
    font-weight: 500;
  }
  p {
    margin-top: 15px;
    padding: 0 10px;
    color: #222;
    font-size: 17px;
    font-family: 'GmarketSans';
    font-weight: 500;
    text-align: left;
  }
  ul {
    margin: 30px 0;
    padding: 0 10px;
    li {
      color: #222;
      font-size: 17px;
      font-family: 'GmarketSans';
      font-weight: 500;
      line-height: 27px;
      strong {
        color: #ff6432;
        font-size: 19px;
        font-weight: 700;
      }
    }
  }
}
.event_caution {
  padding: 80px 60px 90px;
  color: #fff;
  background: #2b2b2b;
  hr { margin: 45px 0 57px; border-top: 1px solid #7b7b7b; }
  h1 {
    margin-bottom: 40px;
    color: #ff603a;
    font-size: 35px;
    font-family: 'GmarketSans';
    font-weight:700
  }
  li, dd {
    position: relative;
    color: #ccc;
    font-size: 27px;
    font-family: 'GmarketSans';
    font-weight: 500;
    padding: 10px 0 10px 17px;
    margin-bottom: -7px;
    letter-spacing: -2px;
    line-height: 37px;
    &:before {
      position: absolute;
      content: "";
      top: 24px;
      left: 0;
      width: 8px; height: 8px; background-color: #fff; border-radius: 50%;
    }
  }
  dl {
    margin-top: 40px;
    dt {
      margin-bottom: 10px;
      color: #fea5ea;
      font-size: 24px;
      font-weight: 300;
      line-height: 1.5;
    }
  }
}

.ui-popup {
  position: fixed;
  left: 0; top: 0;
  z-index: 9999;
  width: 100%; height: 100vh;
  background-color: rgba(0,0,0,.8);

  .ui-popup-wrap {
    position: fixed;
    width: 400px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 0 24px 25px;
    background-color: #fff;
    border-radius: 15px;
    .ui-popup-header {
      padding: 20px 0 18px;
      color: #222;
      font-family: 'NanumSquareRound',sans-serif;
      font-size: 15px;
      font-weight: 700;
      border-bottom: 1px solid #dbdbdb;
    }
    .ui-popup-container {
      padding-top: 30px;
      .msg {
        padding: 20px 15px 17px;
        color: #222;
        font-size: 16px;
        font-family: 'GmarketSans';
        font-weight: 500;
        line-height: 1.5;
        background-color: #f7f7f7;
        border-radius: 10px;
      }
      .ui-btn-wrap {
        margin-top: 25px;
        .ui-btn {
          display: block;
          padding: 20px 0;
          line-height: 0;
          text-align: center;
          background-color: #ff603a;
          border-radius: 30px;
          img { width: auto; height: 18px; }
        }
      }
    }
  }
}

@media (max-width: 1023px) {  
.check_box {
  margin-top: 7px;
  padding-left: 0;
  & > input:checked {
    & + label {
      min-height: auto;
      background-repeat: no-repeat;
      background-size: 20px;
      border: none;
    }
  }
  label {
    display: block;
    min-height: auto;
    background-repeat: no-repeat;
    background-position: 0 3px;
    background-size: 20px;
    border: none;
    span {
      font-size: 16px;
      line-height: 1.3;
      vertical-align: middle !important;
      background: none !important;
    }
  }
  .btn_view {
    position: relative;
    top: auto; right: auto; left: 27px;
    font-size: 14px;
    &:before {
      height: 13px;
    }
  }
}
.event_view_field {
  &.bg:before {
    width: 100%; height: 72px;
    background-size: 100% 100%
  }
}
.insWrap {
  margin: 0 20px 40px;
  padding: 30px 15px 15px;
  border-radius: 20px;
  .msg { padding-bottom: 25px; font-size: 16px; line-height: 1.5; }
  .table_type01 {
    margin-top: 20px;
    dl {
      dd {
        padding: 5px 0 10px !important;
        a {
          width: 100%;
          margin-left: 0;
          padding: 9px 0 1px;
          border-radius: 5px;
          img { height: 15px; }
          span {
            margin-top: 1px;
            font-size: 13px;
          }
        }
        .select_box {
          width: 100%;
          min-width: auto;
          background: none;
          select {
            width: 100%;
            min-width: auto;
            height: 44px;
            padding: 2px 0 0 10px;
            font-size: 16px;
            line-height: 44px;
            background-color: #fff;
            opacity: 1;
            border-radius: 5px
          }
        }
        input {
          width: 30%;
          height: 44px;
          font-size: 16px;
          line-height: 44px;
          border-radius: 5px;
          &::placeholder {
            font-size: 14px;
          }
        }
        .phone-box {
          width: 100% !important;
          .select_box {
            width: 30%;
          }
        }
        .txt_hyphen {
          margin: 23px 3px 0;
          padding: 0 3px
        }
      }
      &.cert {
        dd {
          input { width: 100%; }
        }
      }
      &.code {
        .select_box { width: 100%; }
      }
    }
  }
  .table_msg {
    padding-left: 0;
    font-size: 15px;
  }
}
.privacyCnt {
  margin: 10px 0 15px;
  padding: 15px 15px 13px;
  border-radius: 10px;
  hr { margin: 14px 0 17px; border-top-width: 1px }
  p {
    font-size: 13px;
    line-height: 1.4;
  }
  ul {
    margin: 10px 0;
    li {
      font-size: 13px;
      line-height: 1.4;
      strong {
        font-size: 15px;
      }
    }
  }
}
.event_caution {
  padding: 30px 25px;
  hr { margin: 20px 0 25px; }
  h1 {
      margin-bottom: 10px;
      font-size: 19px;
  }
  li, dd {
    font-size: 15px;
    line-height: 1.5;
    padding: 7px 0 7px 10px;
    letter-spacing: -1px;
    &:before {
      top: 14px;
      width: 4px; height: 4px;
    }
  }
  dl {
    margin-top: 20px;
    dt {
      margin-bottom: 5px;
      font-size: 15px;
    }
  }
}
.ui-popup {
  .ui-popup-wrap {
    width: 100%;
    max-width: 90%;
  }
}
}
</style>