
<template>
  <div id="ASIANA_MILEAGE">
    <!-- 컨텐츠 -->
    <div class="event_wrap">
      <div class="event_view">
        <img src="/content/images/mms_bg_asia2.jpg">
        <div class="agree-info">
          <strong>개인정보 수집 동의 안내</strong>
          <div class="btn-box">
            <a
              class="btn_view"
              @click="privacyPopup = true"
            >개인정보 수집 동의 안내 자세히보기</a>
          </div>
          <div class="agree-chk">
            <div class="check_box2">
              <div class="check-box-inner">
                <input
                  id="yes"
                  ref="privacyAgree"
                  v-model="privacyAgree"
                  type="radio"
                  name="yesno"
                  class="myClass YNchk normal_radio"
                  value="yes01"
                  checked="checked"
                >
                <label for="yes"><span>동의합니다</span></label>
              </div>
              <div class="check-box-inner">
                <input
                  id="no"
                  v-model="privacyAgree"
                  type="radio"
                  name="yesno"
                  class="myClass YNchk normal_radio"
                  value="no01"
                >
                <label for="no"><span>동의하지 않습니다</span></label>
              </div>
            </div>
          </div>
        </div>

        <div class="notice event_view_field">
          <div class="table_type01">
            <dl class="double">
              <dt>전화번호</dt>
              <dd>
                <div class="border-top-n">
                  <div class="radio_box">
                    <input
                      id="i_sPhoneType_C030"
                      type="radio"
                      name="i_sPhoneType"
                      class="myClass"
                      value="C030"
                      title="무선번호"
                      checked="checked"
                    >
                    <label for="i_sPhoneType_C030"> 무선번호</label>
                  </div>
                </div>
                <div class="border-top-n">
                  <div class="phone-box table_type">
                    <div class="sel-box table-cell">
                      <label
                        for="call_select01"
                        aria-hidden="true"
                      >010</label>
                      <select
                        id="call_select01"
                        ref="telNumberFirst"
                        v-model="telNumber.first"
                        name="i_sHpNo1"
                        title="무선전화 앞자리 선택"
                      >
                        <option value="010">
                          010
                        </option>
                        <option value="011">
                          011
                        </option>
                        <option value="016">
                          016
                        </option>
                        <option value="017">
                          017
                        </option>
                        <option value="018">
                          018
                        </option>
                        <option value="019">
                          019
                        </option>
                      </select>
                    </div>
                    <div class="table-cell">
                      <input
                        ref="telNumberMiddle"
                        v-model="telNumber.middle"
                        autocomplete="off"
                        name="i_sHpNo2"
                        title="전화번호"
                        type="text"
                        maxlength="4"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                    <div class="table-cell">
                      <input
                        ref="telNumberLast"
                        v-model="telNumber.last"
                        autocomplete="off"
                        name="i_sHpNo3"
                        title="전화번호"
                        type="text"
                        maxlength="4"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                  </div>
                  <div class="btn-parent">
                    <a
                      class="btn btn_sendnum"
                      @click="requestSMSCert"
                    >SMS인증하기</a>
                  </div>
                </div>
              </dd>
            </dl><dl
              v-if="certNumberInputVisible"
              id="numberCheck"
              class="C030 duplPhono w100"
            >
              <dt>인증번호 입력</dt>
              <dd>
                <input
                  ref="certNumber"
                  v-model="certNumber"
                  autocomplete="off"
                  type="text"
                  name="i_sCertificationNo"
                  title="인증번호"
                  class=""
                  maxlength="6"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                > <a
                  class="btn btn_checknum"
                  @click="requestCert"
                >인증번호확인</a>
              </dd>
            </dl><dl>
              <dt>아시아나 회원번호 등록</dt>
              <dd>
                <input
                  id="AsianaMemberNo"
                  ref="memberNumber"
                  v-model="memberNumber"
                  autocomplete="off"
                  type="text"
                  name="i_sAsianaMemberNo"
                  title="아시아나 회원번호"
                  class="onlyNumber wid452"
                  maxlength="9"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                ><br>
              </dd>
            </dl><table id="event_info_input_table">
              <caption class="blind">
                이벤트 참여 입력
              </caption>
              <colgroup>
                <col width="190">
                <col width="500">
              </colgroup>
            </table>
          </div>
        </div>
        <div class="add-info">
          <ul>
            <li>아시아나 회원번호 9자리 숫자를 정확히 입력해 주세요.<br>예시)회원번호 OZ123456789->123456789 숫자만 등록해 주세요.</li>
            <li>
              마일리지 확인방법 <a
                href="https://flyasiana.com/I/KR/KO/viewLogin.do?callType=IBE&menuId=CM201802220000728453"
                target="_blank"
                title="새창열림"
              >바로가기</a>
            </li>
            <li>마일리지 조회는 아시아나클럽 로그인 후 가능합니다. </li>
          </ul>
        </div>
        <div class="btn_center double">
          <a
            class="btn_type01 btnApply"
            @click="registrationFinal"
          >등록하기</a>
        </div>
      </div>
    </div>

    <!-- 개인정보 수집 동의 안내 팝업 -->
    <div
      v-if="privacyPopup"
      id="popMedium01"
      class="pop_wrap02"
      style="display: block"
    >
      <!-- to개발 / 스타일 임시 보여질땐 display: block; -->
      <div class="medium">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>
          <div
            class="cont_term"
            style="height:200px; overflow-y:auto;"
          >
            <p>
              SK텔링크 주식회사는 국제전화 이용에 대한 혜택제공을 위해 아래와 같이 고객정보를 수집하며, 고객정보 취급에 관한 상세한 사항은
              <router-link
                to="/main"
                class="txt_point01"
              >
                www.sktelink.com
              </router-link>
              에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>개인정보를 제공받는 자 : <strong>아시아나항공</strong></li>
              <li>수집하는 개인정보의 항목 : 핸드폰번호(무선번호)/ 아시아나 마일리지 회원번호</li>
              <li>개인정보의 수집 및 이용목적 : <strong>SK국제전화 요금제에 대한 아시아나 마일리지 적립 진행 및 고객상담 진행</strong></li>
              <li>개인정보의 보유 및 이용기간 : <strong>SK국제전화 요금제 혜택을 위한 고객정보 등록 시점부터 표준요금제 전환 이후 5년까지 보관 후 삭제</strong></li>
            </ul>
            <p>
              개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 요금제 혜택 제공에 제한이 될 수 있습니다.<br>
              SK국제전화 요금제 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.
            </p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="privacyPopup = false"
          >확인</a>
        </div>
        <a
          class="pop-close"
          @click="privacyPopup = false"
        ><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>

    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'

export default {
  name: 'AsianaMileage',
  components: {
    Popup
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      privacyPopup: false,
      alertPopup: {
        visible: false,
        message: '',
        target: ''
      },
      telNumber: {
        first: '010'
      },
      certNumber: '',
      memberNumber: '',
      ticket: '',
      svc_tp: '',
      privacyAgree: '',
      certNumberInputVisible: false,
      isSmsCert: false
    }
  },
  computed: {
    tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if (this.callbackAlert.code === '0002' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code === 'E029') {
          this.resetPage()
          window.scrollTo(0, 0)
          document.getElementById('wrap').scrollTop = 0
        }
        if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
          this.telNumber= {
            first: '010',
            middle: '',
            last:''
          },
          this.certNumberInputVisible = false,
          this.certNumber = ''
          this.ticket = ''
        }
        if (this.callbackAlert.code === 'E001') { // 인증시간 초과
          this.certNumber = ''
          this.ticket = ''
        }
      }
    }
  },
  created () {},
  mounted () {
  },
  methods: {
    alertPopupHandler() {
      this.alertPopup.visible = false
      this.alertPopup.message = ''
      if (this.alertPopup.target) {
        this.$refs[this.alertPopup.target].focus()
      }
      this.alertPopup.target = ''
    },
    showAlertPopup(message) {
      this.alertPopup.message = message
      this.alertPopup.visible = true
    },
    checkPrivacy() {
      if(this.privacyAgree !== 'yes01') {
        this.alertPopup.target = 'privacyAgree'
        this.showAlertPopup('개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 신청이 가능합니다.')
        return false
      } else {
        return true
      }
    },
    checkPhoneNumber() {
      if (!this.telNumber.middle) {
        this.alertPopup.target = 'telNumberMiddle'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if (!this.telNumber.last) {
        this.alertPopup.target = 'telNumberLast'
        this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else if (this.privacyAgree !== 'yes01') {
        this.alertPopup.target = 'privacyAgree'
        this.showAlertPopup('개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 신청이 가능합니다.')
        return false
      } else {
        return true
      }
    },
    checkCertNumber() {
      if (!this.certNumber) {
        this.alertPopup.target = 'certNumber'
        this.showAlertPopup('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkSMSCert() {
      if(!this.isSmsCert) {
        this.showAlertPopup('SMS인증을 완료해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkMemberNumber() {
      if(!this.memberNumber || this.memberNumber.length < 9) {
        this.alertPopup.target = 'memberNumber'
        this.showAlertPopup('아시아나 회원번호를 확인하여 주세요.')
        return false
      } else {
        return true
      }
    },
    // SMS 인증 요청
    requestSMSCert() {
      if (this.checkPhoneNumber()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.tel_no })
          .then(response => {
            if (response.data.sms_ticket) {
              this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
              this.ticket = response.data.sms_ticket
              this.certNumberInputVisible = true
            }
          })
      }
    },
    // 인증 번호로 SMS 인증 확인
    requestCert() {
      if (this.checkCertNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.certNumber, sms_ticket: this.ticket })
          .then(response => {
            if (response.data.result_cd === '0001') {
              this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
              this.isSmsCert = true
            }
          })
      }
    },
    // 등록하기
    registrationFinal() {
      if (this.checkPrivacy() && this.checkPhoneNumber() && this.checkSMSCert() && this.checkMemberNumber()) {
        api.EventPostSavePartnerEvent({
          sms_ticket: this.ticket,
          req_tp: 'AN',
          member_no: this.memberNumber
        })
      }
    },
    resetPage(){
      this.privacyAgree= '',
      this.telNumber= {
        first: '010',
        middle: '',
        last:''
      },
      this.certNumberInputVisible = false,
      this.memberNumber = '',
      this.certNumber = ''
      this.ticket = '',
      this.isSmsCert = false
    }
  }
}
</script>

<style lang="scss">
.event_wrap {background-color: #fff;}
</style>