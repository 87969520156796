
<template>
  <div id="NCSI_NO1_22">
    <div class="event_wrap ncsi">
      <div class="event_view">
        <div>
          <span>
            <img
              alt="9년 연속 국가고객만족도(NCSI) 1위 기념, 00700에 바라는 점을 남겨주시면, 괌 여행 항공권을 드립니다."
              src="/content/images/partnership/220429_ncsino1_img1.jpg"
              @load="onLoad"              
            ><img
              alt=""
              src="/content/images/partnership/220429_ncsino1_img2.png"
              @load="onLoad"              
            ><img
              alt=""
              src="/content/images/partnership/220429_ncsino1_img3.png"
              @load="onLoad"              
            >
          </span>
          <div class="event_view_field">
            <div class="event_header">
              <img
                src="/content/images/partnership/220429_ncsino1_img1_text1.png"
                alt="응모하기"
              >
            </div>

            <div class="event_content">
              <div class="simple_type">
                <dl>
                  <dt>휴대폰번호</dt>
                  <dd>
                    <div class="insert_phone">
                      <select
                        id="call_select01"
                        ref="telNumberFirst"
                        v-model="telNumber.first"
                        name="i_sHpNo1"
                        title="무선전화 앞자리 선택"
                      >
                        <option
                          value="010"
                        >
                          010
                        </option>
                        <option value="011">
                          011
                        </option>
                        <option value="016">
                          016
                        </option>
                        <option value="017">
                          017
                        </option>
                        <option value="018">
                          018
                        </option>
                        <option value="019">
                          019
                        </option>
                      </select>
                      <span class="txt_hyphen" />
                      <input
                        ref="telNumberMiddle"
                        v-model="telNumber.middle"
                        name="i_sHpNo2"
                        title="전화번호"
                        type="text"
                        maxlength="4"
                        pattern="[0-9]*"
                        class="numbersOnly"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                      <span class="txt_hyphen" />
                      <input
                        ref="telNumberLast"
                        v-model="telNumber.last"
                        name="i_sHpNo3"
                        title="전화번호"
                        type="text"
                        maxlength="4"
                        pattern="[0-9]*"
                        class="numbersOnly"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                    <button
                      type="button"
                      class="btn btn_cert"
                      @click="requestSMSCert"
                    >
                      개인(SMS)인증
                    </button>
                  </dd>
                </dl>
                <dl
                  v-if="authNumberInputVisible"
                  id="numberCheck"
                  class="C030 duplPhono"
                >
                  <dt>인증번호 입력</dt>
                  <dd>
                    <div class="insert_certNum">
                      <input
                        ref="certNumber"
                        v-model="auth_no"
                        name="i_sCertificationNo"
                        title="인증번호"
                        type="text"
                        maxlength="6"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                    <button
                      class="btn btn_cert"
                      @click="requestCert"
                    >
                      인증번호확인
                    </button>
                  </dd>
                </dl>
              </div>
              <div class="division_type">
                <dl>
                  <dt class="blind">
                    카테고리 선택
                  </dt>
                  <dd>
                    <div class="insert_phone">
                      <select
                        id="i_sCategory"
                        v-model="selectCategory"
                        name="i_sCategory"
                        title="카테고리 선택하기"
                        @change="selectedCategory"
                      >
                        <option value="카테고리를 선택해주세요">
                          카테고리
                        </option>
                        <option value="통화품질">
                          통화품질
                        </option>
                        <option value="요금&요금제">
                          요금&요금제
                        </option>
                        <option value="이벤트">
                          이벤트
                        </option>
                        <option value="00700앱&홈페이지">
                          00700앱&홈페이지
                        </option>
                        <!-- <option value="고객센터">
                          고객센터
                        </option>
                        <option value="기타의견">
                          기타의견
                        </option> -->
                        <option value="기타내용">
                          기타내용
                        </option>
                      </select>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt class="blind">
                    의견
                  </dt>
                  <dd>
                    <div class="insert_comment">
                      <textarea
                        v-model="wishText"
                        placeholder="의견을 남겨주세요.(최대 300자)"
                        class="textarea"
                      />
                    </div>
                  </dd>
                </dl>
              </div>
            
              <div
                class="agree_box"
              >
                <input
                  id="yes"
                  v-model="agreePrivacy"
                  name="yesno"
                  class="myClass YNchk"
                  type="checkbox"
                  value="yes01"
                >
                <label for="yes" />
                <a
                  @click="isVisiblePrivacyPopup = true"
                >
                  이벤트 응모 및 혜택 제공을 위한위한 개인정보 수집, 활용 및 취급 위탁에 동의합니다.
                </a>
              </div>
              <div class="event_active_btn">
                <a
                  id="btnApply"
                  @click="registrationFinal"
                ><img
                  src="/content/images/partnership/220429_ncsino1_img1_text2.png"
                  alt="이벤트 응모완료"
                ></a>
              </div>
            </div>
          </div>
          <div class="event_caution">
            <h2>
              <img
                src="/content/images/partnership/220429_ncsino1_img1_text3.png"
                alt="이벤트 유의 사항"
              >
            </h2>
            <ul>
              <li>본 이벤트 경품은 제주항공의 마일리지(1등 총 200만 마일리지, 2등 총 20만 마일리지)로 제공됩니다.</li>
              <li>제주항공 마일리지 제공을 위해 당첨 고객님께 문자를 통한 제주항공 회원 가입 및 회원 번호 전달 취합 안내를 드립니다.</li>
              <li>1, 2등 경품의 경우 5만원 초과의 경품 제공 건으로, 경품 수취 관련 각종 세금은 SK텔링크에서 전액 부담하며, 처리 목적으로 당첨자 개별 연락을 통해 성함과 국적, 주민등록번호를 수집합니다.<br>이를 거부하시거나 3회 이상 부재 시 이벤트 당첨이 취소될 수 있습니다.</li>
              <li>3, 4등 경품의 경우 응모하신 휴대폰번호로 모바일 쿠폰이 발송될 예정이며, 실물 상품권으로 교환하시거나 상품 구매 시 사용 가능합니다.</li>
              <li>본 이벤트 경품은 제휴사 및 상품 수급 업체에 따라 별도 공지 없이 상품이 변경/대체될 수 있습니다.</li>
              <li>단순 욕설 혹은 비방을 기재할 경우, 추후 이벤트 참여가 제한됩니다.</li>
              <li>비정상적이거나 불법적인 방법으로 이벤트에 참여하신 경우, 이벤트 대상에서 제외될 수 있습니다.</li>
              <li>이벤트 관련 문의 : SK국제전화 00700 고객센터: 1599-0070 <br>(월~금, 9시~18시)</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 국제전화 이용에 대한 혜택제공을 위해 아래와 같이 고객정보를 수집하며,<br>고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보의 항목 : 이동전화번호</li>
              <li>개인정보의 수집 및 이용목적 : 이벤트 참여</li>
              <li>개인정보의 보유 및 이용기간 : <span style="font-weight: bold; font-size: 1.2em;"><strong>당첨자 발표 후 3개월까지</strong></span></li>
              <li>취급 위탁기관 : 나이스신용정보 (고객센터)</li>
            </ul>
            <p>※ 미동의 시 이벤트 응모가 불가합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    <!-- //팝업 -->
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  name: 'KSSQI',
  components: {
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    isVisiblePrivacyPopup: false,
    agreePrivacy:false,
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    telNumber:{
      first:'010',
      middle: '',
      last:''
    },
    authNumberInputVisible: false,
    ticket: '',
    event_no:'EV000000000000000007',
    auth_no: '',
    selectCategory:'카테고리를 선택해주세요',
    wishText: '',
  }
 },
 computed: {
  svc_tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  add_ctgr(){
    return this.selectCategory
  },
  add_txt(){
    return this.wishText
  },
  callbackAlert(){
    return this.$store.getters.CALLBACK_ALERT
  }
 },
 watch: {
  callbackAlert: {
    deep: true,
    handler() {
      if (this.callbackAlert.code === '0009' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E0027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E021' || this.callbackAlert.code === 'E022') {
        this.resetPage()
        window.scrollTo(0, 0)
        document.getElementById('wrap').scrollTop = 0
      }
      if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
        this.authNumberInputVisible = false,
        this.telNumber = {
          first:'010',
          middle: '',
          last:''
        },
        this.auth_no = ''
        this.ticket = ''
      }
      if (this.callbackAlert.code === 'E001') { // 인증시간 초과
        this.auth_no = ''
        this.ticket = ''
      }
    }
  }
},
 created () {},
 mounted () {
    
 },
 methods: {
  selectedCategory() {
    this.add_ctgr
  },
  addText() {
    this.add_txt
  },
  alertPopupHandler() {
    this.alertPopup.visible = false
    this.alertPopup.message = ''
    if (this.alertPopup.target) {
      this.$refs[this.alertPopup.target].focus()
    }
    this.alertPopup.target = ''
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
      return false
    } else {
      return true
    }
  },
  checkAuthNumber() {
    if (!this.auth_no) {
      this.alertPopup.target = 'certNumber'
      this.showAlertPopup('인증번호를 입력해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkSMSCert() {
    if(!this.isSmsCert) {
      this.showAlertPopup('SMS인증을 완료해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkselect(){
    if(!this.agreePrivacy){
      this.showAlertPopup('개인정보 수집에 동의하셔야 이벤트 응모가 가능합니다.')
      return false
    }else if((this.selectCategory ==='카테고리를 선택해주세요')){
      this.showAlertPopup('카테고리를 선택해주세요.')
      return false
    }else if((this.wishText ==='')){
      this.showAlertPopup('의견을 입력해주세요.')
      return false
    }else {
      return true
    }
  },
  onChange() {
    this.selectCategory = '카테고리를 선택해주세요'
  },
  // SMS 인증 요청
  requestSMSCert() {
    if (this.checkPhoneNumber()) {
      api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
      .then(response => {
        if (response.data.sms_ticket) {
          this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
          this.ticket = response.data.sms_ticket
          this.authNumberInputVisible = true
        }
      })
    }
  },
  // 인증 번호로 SMS 인증 확인
  requestCert() {
    if (this.checkAuthNumber()) {
      api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
      .then(response => {
        if (response.data.result_cd === '0001') {
          this.isSmsCert = true
          this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
        }
      })
    }
  },
  //신청완료
  registrationFinal() {
    if (this.checkselect() && this.checkPhoneNumber() && this.checkSMSCert()) {
      api.EventPostReqEntry({
        event_no: this.event_no,
        sms_ticket: this.ticket,
        req_ctgr: this.add_ctgr,
        req_ct: this.add_txt
      })
    }
  },
  resetPage(){
    this.agreePrivacy = false,
    this.telNumber= {
      first:'010',
      middle: '',
      last:''
    },
    this.selectCategory = '카테고리를 선택해주세요',
    this.wishText = '',
    this.authNumberInputVisible = false,
    this.auth_no = ''
    this.ticket = ''
    this.isSmsCert = false
  },
    //20220520 이벤트 종료
    onLoad() {
      var popitem = document.getElementById("NCSI_NO1_22");

      if (new Date() >= new Date('05/20/2022 23:59:59')) {   //종료 시간 지정
          this.showAlertPopup('종료된 이벤트입니다.')
          popitem.classList.add("closeEvent");
      // }else if (new Date() < new Date('05/04/2022 00:00:00')) {   //시작 시간 지정
      //     this.showAlertPopup('본 이벤트는 7/2부터 참여 가능합니다.')
      //     popitem.classList.add("closeEvent");
      }
  },
 }
}
</script>

<style lang="scss" scoped>
@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');

.ncsi {
  & > .event_view > div { text-align: left; }
  .event_view_field {
    margin-top: 0;
    padding: 130px 100px;
    background-color: #5f5aec;
    .event_header {
      text-align: center;
      img { width: auto; }
    }
    .event_content {
      .simple_type {
        padding-bottom: 58px;
        dl {
          margin-top: 70px;
          dt {
            margin-bottom: 28px;
            color: #fff;
            font-size: 36px;
            line-height: 1;
          }
          dd {
            &:after {
              content: ''; display: block; clear: both; overflow: hidden;
            }
          }
        }
      }
      .division_type {
        display: flex;
        padding-bottom: 53px;
        border-bottom: 1px solid #7a76ef;
        &>dl { width: 100%; }
        &>dl:first-child { width: 35%; padding-right: 20px; }
        .insert_phone {
          float: none;
          width: 100%;
          select {
            width: 100%;
          }
        }
      }
      .insert_phone {
        float: left;
        width: 75%;
        height: 90px;
        & > * { vertical-align: middle; }
        select {
          width: 30%;
          height: 100%;
          color: #000;
          font-size: 32px;
          font-family: 'arial', Sans-serif;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: #fff url('/content/images/btn_arrow_selelct.png') no-repeat calc(100% - 12px) 50%/16px 9px;
          border-radius: 10px;
        }
        input[type=number], input[type=text] {
          width: 30%;
          height: 100%;
          padding: 0;
          color: #000;
          font-size: 32px;
          font-family: 'arial', Sans-serif;
          line-height: 90px;
          text-align: center;
          background-color: #fff;
          border-radius: 10px;
        }
        .txt_hyphen {
          display: inline-block;
          position: relative;
          width: 5%;
          height: 100%;
          text-align: center;
          line-height: 100%;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 8px; height: 2px; background-color: #fff;
            margin: -1px 0 0 -4px;
          }
        }
      }
      .insert_certNum {
        float: left;
        width: 22.5%;
        height: 90px;
        margin-right: 3.75%;
        input[type=number], input[type=text] {
          width: 100%;
          height: 100%;
          padding: 0;
          color: #000;
          font-size: 32px;
          font-family: 'arial', Sans-serif;
          line-height: 90px;
          text-align: center;
          background-color: #fff;
          border-radius: 10px;
        }
        & + .btn.btn_cert {
          float: left;
          width: auto;
          height: 90px;
          padding: 0 25px;
        }
      }
      .insert_comment {
        .textarea { width: 100%; min-height: 235px; padding: 20px; color: #666; border-radius: 10px; font-family: notosans, Sans-serif, Dotum, 돋음; font-size: 30px; font-weight: 400; background: #fff; box-sizing: border-box; }
        .textarea::placeholder { color: #aaa; font-size: 30px; font-weight: 400; letter-spacing: -0.5px; }
      }
      .btn.btn_cert {
        display: block;
        float: right;
        width: 22%;
        height: 90px;
        color: #fff;
        font-size: 26px;
        background: #1a1d63;
        border-radius: 10px;
      }
      .agree_box {
        display: block;
        position: relative;
        margin-top: 40px;
        padding-left: 60px;
        color: #fff;
        font-size: 32px;
        line-height: normal;
        letter-spacing: -0.05em;
        input[type=checkbox] {
          position: absolute;
          left: 5px;
          top: 3px;
          z-index: -1;
          opacity: 0;
          &:checked+label {
            background: url('/content/images/partnership/icon_checkbox_welcome_checked.png') no-repeat;
          }
        }
        label {
          position: absolute;
          left: 0;
          width: 49px;
          height: 49px;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          background: url('/content/images/partnership/icon_checkbox_welcome.png') no-repeat;
        }
        a {
          color: inherit;
          font-size: inherit;
          line-height: inherit;
        }
        p {
          color: inherit;
          font-size: inherit;
          line-height: inherit;
          text-align: left;
        }
      }
      .event_active_btn {
        margin-top: 90px;
        text-align:  center;
        a {
          display: inline-block;
          padding: 30px 120px;
          border-radius: 55px;
          background-color: #1a1d63;
          img { width: auto; }
        }
      }
    }
  }
  .event_caution {
    padding: 128px 70px;
    background: #656565;
    color: #fff;
    h2 {
      margin-bottom: 65px;
      text-align: center;
      img { width: auto; }
    }
    li {
      position: relative;
      padding-left: 25px;
      color: #fff;
      font-family: 'NanumSquareRound',sans-serif;
      font-size: 33px;
      font-weight: 400;
      line-height: 1.67;
      text-align: left;
      letter-spacing: -0.04em;
      &:before {
        position: absolute;
        content: "-";
        top: -1px;
        left: 0;
      }
    }
  }
}

.closeEvent {max-height: 100vh; overflow: hidden;}


@media (max-width: 1023px) {
  .ncsi {
    .event_view_field {
      padding: 40px 25px;
      .event_header {
        img { height: 46px; }
      }
      .event_content {
        .simple_type {
          padding-bottom: 20px;
          dl {
            margin-top: 30px;
            dt {
              font-size: 15px;
              margin-bottom: 10px;
            }
          }
        }
        .division_type {
          display: block;
          padding-bottom: 20px;
          &>dl { width: 100%; }
          &>dl:first-child { width: 100%; margin-bottom: 20px; padding-right: 0px; }
          .insert_phone {
            float: none;
            width: 100%;
            select {
              width: 100%;
            }
          }
        }
        .insert_phone {
          height: 40px;
          select, input[type=number], input[type=text] { font-size: 16px; line-height: 40px; border-radius: 5px; }
          .txt_hyphen:before { width: 4px; height: 1px; margin-left: -2px; }
        }
        .insert_certNum {
          height: 40px;
          input[type=number], input[type=text] { font-size: 16px; line-height: 40px; border-radius: 5px; }
          & + .btn.btn_cert {
            height: 40px;
            padding: 0 10px;
          }
        }
        .insert_comment {
          .textarea { font-size: 15px; }
          .textarea::placeholder { font-size: 15px; }
        }
        .btn.btn_cert {
          height: 40px;
          font-size: 12px;
          border-radius: 5px;
        }
        .agree_box {
          margin-top: 15px;
          padding-left: 30px;
          font-size: 15px;
          input[type=checkbox]:checked+label {
            background-size: 100% auto;
          }
          label {
            width: 25px;
            height: 25px;
            background-size: 100% auto;
          }
        }
        .event_active_btn {
          margin-top: 20px;
          a {
            padding: 10px 40px;
            line-height: 0;
            img { height: 18px; }
          }
        }
      }
    }
    .event_caution {
      padding: 45px 25px;
      h2 {
        margin-bottom: 20px;
        img { height: 21px; }
      }
      li {
        font-size: 15px;
        padding: 0 0 0 10px;
        &:before {
          top: 0;
        }
        br { display: none; }
      }
    }
  }
  .pop_wrap02 .medium { padding-bottom: 20px; }
}
</style>
