<template>
  <div id="COSTOMER_SERVICE_INFO">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            고객상담 센터안내
          </h3>
          <p class="txt">
            SK텔링크 고객센터에서는 다양한 경로로<br> 고객님께서 궁금하신 사항을 알려드립니다
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- customer -->
        <div class="center">
          <div class="center_company mobile">
            <dl>
              <dt>국제전화/위성통신서비스</dt>
              <dd>
                <a
                  href="tel:1599-00700"
                  class="mobile-only"
                >1599-00700</a>
                <span class="pc-only">1599-00700</span>
                <em>(유료)</em>
              </dd>
              <dd class="right">
                <a
                  href="tel:080-89-00700"
                  class="mobile-only"
                >080-89-00700</a>
                <span class="pc-only">080-89-00700</span>
                <em>(무료)</em>
              </dd>
            </dl>
            <dl>
              <dt>고객센터 운영시간</dt>
              <dd>
                평일 09:00~18:00(토요일, 일요일, 공휴일 휴무)
              </dd>
            </dl>
          </div>
        </div>
        <div class="center gray">
          <div class="center_company mobile">
            <dl>
              <dt>SK 7mobile</dt>
              <dd>
                <a
                  href="tel:1599-0999"
                  class="mobile-only"
                >1599-0999</a>
                <span class="pc-only">1599-0999</span>
                <em>(유료)</em>
              </dd>
              <dd class="right">
                <a
                  href="tel:114"
                  class="mobile-only"
                >114</a>
                <span class="pc-only">114</span>
                <em>(가입자휴대폰 무료)</em>
              </dd>
            </dl>
            <dl>
              <dt>고객센터 운영시간</dt>
              <dd>평일 09:00~18:00 (토요일, 일요일, 공휴일 휴무)</dd>
              <dd class="point">
                <span class="icon_star">*</span>
                단, 분실/통화품질 업무는 연중 휴무 없이 24시간 운영
              </dd>
            </dl>
            <a
              href="https://www.sk7mobile.com"
              target="_blank"
              title="SK 7mobile 홈페이지 새창열림"
              class="center_company_a"
            >SK 7mobile 홈페이지 바로가기</a>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'

export default {
  name: 'CostomerServiceInfo',
  metaInfo: {
    title: '고객상담 센터 안내 | 고객상담 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .center .center_company .mobile-only{
    display: none;
    @media (max-width: 1023px){
      display: block;
    }
  }
  .center .center_company .pc-only{
    @media (max-width: 1023px){
      display: none;
    }
  }

</style>
