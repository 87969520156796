<template>
  <div id="ADVERTISEMENT_PAPER">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div id="contents" class="contents">
        <div class="sub_header bg06">
          <h3 class="title">광고</h3>
          <p class="txt">
            미디어속 SK텔링크의<br />광고 이야기를 전해드립니다.
          </p>
          <img src="/content/images/sub_header_cp06.jpg" alt="비쥬얼 이미지" />
        </div>

        <!-- tab -->
        <div class="tab_type01 tab3">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li>
              <router-link to="/view/media/advertisement_video">
                영상 광고
              </router-link>
            </li>
            <li>
              <router-link to="/view/media/advertisement_audio">
                오디오 광고
              </router-link>
            </li>
            <li class="on" title="현재메뉴 선택됨">
              <router-link to="/view/media/advertisement_paper">
                지면 광고
              </router-link>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <div class="paper_list">
            <ul>
              <li>
                <img
                  src="/content/images/img_paper02_20181022.jpg"
                  style="width: 100%"
                  alt="한국서비스품질지수(KS-SQI)10년연속1위 1년은 우연이지만 10년은 믿음입니다."
                />
                <h4>[SK 국제전화 00700]</h4>
                <p>
                  2018 한국서비스 품질지수<br class="m_hide" />10년 연속 1위
                </p>
              </li>
              <li>
                <img
                  src="/content/images/img_paper01_20181022.jpg"
                  style="width: 100%"
                  alt="항상곁에있고싶은마음 효도의신"
                />
                <h4>[SK 7mobile]</h4>
                <p>효도의 신</p>
              </li>
            </ul>
          </div>

          <div class="paper_list">
            <ul>
              <li>
                <img
                  src="/content/images/img_paper01_2018.jpg"
                  style="width: 100%"
                  alt="대한민국 1등 통화품질을 더 합리적으로 누릴 수 있도록 SK 7mobile이 새롭게 시작합니다. 모바일 가치소비의 시작! SK 7mobile!"
                />
                <h4>[SK 7mobile]</h4>
                <p>SK 7mobile <br class="m_hide" />브랜드 리뉴얼</p>
              </li>
              <li>
                <img
                  src="/content/images/img_paper02_2018.jpg"
                  style="width: 100%"
                  alt="대치동 학생들의 공부필수폰 #공신폰!! 학원끊기전에 데이터부터 끊으세요! GALAXY WIDE2"
                />
                <h4>[SK 7mobile]</h4>
                <p>공부의신2</p>
              </li>
            </ul>
          </div>
          <div class="paper_list">
            <ul>
              <li>
                <img
                  src="/content/images/img_paper01.jpg"
                  style="width: 100%"
                  alt="sk텔링크 한국서비스품질질수(KS-SQI) 9년 연속 1위 끊김없는 통화 품질로 끊임없이 9년 연속 1위 당신의 대화, 당신의 안부, 당신의 사랑이 끊김없이 연결되도록 국가대표답게 끊임없이 노력하겠습니다. 국가대표 국제전화 sk국제전화00700"
                />
                <h4>[SK 국제전화 00700]</h4>
                <p>
                  2017 한국서비스 품질지수<br class="m_hide" />
                  10년 연속 1위
                </p>
              </li>
              <li>
                <img
                  src="/content/images/img_paper02.jpg"
                  style="width: 100%"
                  alt="sk텔링크 SK 7mobile 쟤야! 스마트폰 쓰면서 전교1등! 강성태가 추천하는 전교1등폰 와이파이, 데이터는 없다! 영어사전과 스타일은 있다! #전교1등폰 #강성태추천폰 공부의神"
                />
                <h4>[SK 7mobile]</h4>
                <p>공부의신</p>
              </li>
            </ul>
          </div>
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl";

export default {
  name: "AdvertisementPaper",
  metaInfo: {
    title: "지면 광고 | 광고 | 미디어센터",
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
  },
  props: {},
  emits: [],
  data() {
    return {};
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
