<template>
  <div id="CALLING_PLAN_RECOMMANDED">
    <Header />
    <!-- 컨텐츠 -->
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />

      <div id="contents">
        <div class="sub_header bg06-02">
          <h3 class="title">
            추천요금제
          </h3>
          <p class="txt">
            고객님의 국제전화 사용패턴에 꼭 맞는<br>00700 요금제를 추천 받으시고 예상 금액을 확인해보세요!
          </p>
          <img
            src="/content/images/sub_header_inter06-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- inter -->
        <div class="inter">
          <div class="advice_list white">
            <h4>추천 요금상품 보기</h4>
            <div class="recommand_cont">
              <ul class="no-scroll">
                <li class="child1">
                  <h5>이용국가</h5>
                  <p>이용 국가를 입력해주세요.</p>
                  <div>
                    <img
                      src="/content/images/img_inter_sub12.png"
                      alt=""
                    >
                  </div>
                  <div class="time_box">
                    <Autocomplete
                      v-model="formData.natl_cd"
                      :source="country"
                      :results-display="'natl_nm'"
                      :results-value="'natl_cd'"
                      :clear-button-icon="''"
                      :placeholder="'국가명 입력'"
                      @selected="selectCountry"
                      @keyupEnter="inquire"
                    />
                  </div>
                </li>
                <li class="child2">
                  <h5>이용시간</h5>
                  <p>국제전화 이용 시 한통화 당<br>평균시간을 입력해주세요.</p>
                  <div>
                    <img
                      src="/content/images/img_inter_sub13.png"
                      alt=""
                    >
                  </div>
                  <div class="time_box">
                    <strong>이용시간</strong>
                    <input
                      id="i_sMin"
                      ref="timeBox"
                      v-model.number="formData.use_min"
                      type="text"
                      title="분 입력"
                      class="onlyNumber simul"
                      maxlength="8"
                      autocomplete="off"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      style="text-align: right"
                    >
                    <span>분</span>
                  </div>
                </li>
                <li class="child3">
                  <h5>추가 혜택</h5>
                  <p>받고 싶은 혜택을 선택해주세요.</p>
                  <div>
                    <img
                      src="/content/images/img_inter_sub14.png"
                      alt=""
                    >
                  </div>
                  <ul>
                    <li>
                      <div class="check_box">
                        <input
                          id="discount"
                          v-model="checkBox.discount"
                          type="checkbox"
                          name="chkDiscount"
                          checked="checked"
                          value="Y"
                        >
                        <label for="discount"><span>요금할인</span></label>
                      </div>
                    </li>
                    <li>
                      <div class="check_box">
                        <input
                          id="free"
                          v-model="checkBox.freeCall"
                          type="checkbox"
                          name="chkFree"
                          checked="checked"
                          value="Y"
                        >
                        <label for="free"><span>무료통화</span></label>
                      </div>
                    </li>
                    <li
                      v-if="sktShow"
                      id="sktFeeArea"
                    >
                      <div class="check_box">
                        <input
                          id="skt"
                          v-model="checkBox.skt"
                          type="checkbox"
                          name="chkSkt"
                          checked="checked"
                          value="Y"
                        >
                        <label for="skt"><span>SKT 전용</span></label>
                      </div>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div class="btn_center single line_top">
              <button
                type="button"
                class="btn_type01"
                @click="inquire"
              >
                추천요금제 조회하기
              </button>
            </div>
          </div>

          <div
            v-if="resultAreaOpen"
            id="dv_table_area"
            class="use_table_area"
          >
            <h4>나에게 딱 맞는 국제전화 요금제</h4>

            <div class="table_type04">
              <table id="charge_table">
                <caption>00700 표준요금, 001 표준요금, 002 표준요금, 수도, 국가번호, 시차 및 현지시간 나타내는 표입니다.</caption>
                <colgroup />
                <tbody>
                  <tr>
                    <th scope="col">
                      국가명
                    </th>
                    <th scope="col">
                      국가번호
                    </th>
                    <th scope="col">
                      00700<br class="m_br"> 표준요금
                    </th>
                    <th scope="col">
                      시차 및<br class="m_br"> 현지시간
                    </th>
                  </tr>
                  <tr>
                    <td>{{ resultStandard.natl_info.natl_nm }}</td>
                    <td>{{ resultStandard.natl_info.natl_no }}</td>
                    <!-- <td>{{ resultStandard.price_info.price_00700 * formData.use_min | comma }}원/{{ formData.use_min }}분</td>                     -->
                    <td>{{ resultStandard.price_info.price_00700 * enterMin | comma }}원/{{ enterMin }}분</td>
                    <td class="txt_point01">
                      <span class="icon_img"><img
                        src="/content/images/icon_time.png"
                        alt="시차 및 현지시간"
                      ></span>
                      {{ resultStandard.natl_info.time_gap }}시간({{ resultStandard.natl_info.fomatedDate }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br>

            <div class="table_type04">
              <table>
                <caption>추가 혜택 구분, 추천 요금제, 신청하기 나타내는 표입니다.</caption>
                <colgroup />
                <thead>
                  <tr>
                    <th scope="col">
                      추가혜택
                    </th>
                    <th scope="col">
                      추천요금제
                    </th>
                    <th scope="col">
                      예상 사용요금
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="checkBox.discount">
                    <td>요금할인</td>
                    <td>
                      <router-link
                        to="/view/business/calling_plan_specified"
                        target="_blank"
                      >
                        지정국가 요금제
                      </router-link>
                    </td>
                    <td>
                      <template v-if="resultSpecified">
                        {{ resultSpecified.price * enterMin | comma }}원
                      </template>
                    </td>
                  </tr>
                  <tr v-if="checkBox.freeCall">
                    <td>무료통화</td>
                    <td>
                      <router-link
                        to="/view/business/calling_plan_free3000"
                        target="_blank"
                      >
                        안심 3000요금제
                      </router-link>
                    </td>
                    <td>{{ resultAnsim.price | comma }}원</td>
                  </tr>
                  <tr v-if="checkBox.skt && sktShow">
                    <td>SKT전용</td>
                    <td>
                      <a
                        href="/os/it/ci/it_ci_fee_okcash.do"
                        target="_blank"
                      >00700프리요금제</a>
                    </td>
                    <td>부가통화분수 소진</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <ul class="txt_em">
              <li><span class="icon_star">*</span>해당 요금은 VAT가 포함된 금액입니다.</li>
              <li><span class="icon_star">*</span>00700 요금제에 가입할 시 분당으로 과금되며, 표준요금의 경우 초당 과금됩니다.</li>
              <li>
                <span class="icon_star">*</span>00700 프리 요금제는 월정액에 따라 대상 대상국가에서 제외될 수 있으므로 요금제 상세 안내를 자세히 읽어보시길 바라겠습니다. <br> &nbsp;요금제 안내 <a
                  href="https://www.sktelink.com/view/business/skt_special"
                  target="_blank"
                  style="color:#333;"
                >: www.sktelink.com/view/business/skt_special</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!--// 컨텐츠 -->
    </div>
    <!-- Container -->
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import util from '@/utilities'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl.vue'
import Autocomplete from '@/components/Autocomplete'

export default {
  name: 'CallingPlanRecommanded',
  metaInfo: {
    title: '추천요금제 | 국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
    Autocomplete
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      formData: {},
      selectedCountry: {},
      country: [],
      resultAreaOpen: false,
      resultStandard: {
        natl_info: {},
        price_info: {}
      },
      resultSpecified: [],
      resultAnsim: {},
      enterMin: '',
      sktShow: true,
      sktCountryList: [
        '중국',
        '미국',
        '캐나다',
        '홍콩',
        '싱가포르',
        '태국',
        '말레이시아',
        '방글라데시',
        '몽골',
        '인도',
        '괌',
        '사이판',
        '포르투갈',
        '우즈베키스탄',
        '인도네시아',
        '베트남',
        '캄보디아',
        '파키스탄',
        '독일',
        '오스트리아',
        '호주',
        '불가리아',
        '이스라엘',
        '콜롬비아',
        '마카오',
        '라오스',
      ],
      checkBox: {
        discount: true,
        freeCall: true,
        skt: true
      },
    }
  },
  computed: {
  },
  watch: {},
  created () {
    api.ItsvcGetPreStandardPricePlanInfo()
    .then(response => {
      this.country = response.data
    })
  },
  mounted () {
  },
  methods: {
    selectCountry(item) {
      this.selectedCountry = item.selectedObject
      this.sktShow = this.sktCountryList.includes(this.selectedCountry.natl_nm)
    },
    inquire() {
      if (!this.formData.natl_cd) {
        this.$store.commit('ON_ALERT_POPUP', { message: '국가명을 입력하세요.' })
      } else if(!this.formData.use_min) {
        this.$store.commit('ON_ALERT_POPUP', { message: '이용시간을 입력하세요.' })
      } else {
        // 00700 표준정보
        api.ItsvcGetStandardPricePlanInfo(this.formData)
        .then(response => {
          this.resultStandard = response.data
          this.resultAreaOpen = true
          this.resultStandard.natl_info.fomatedDate = util.convDateToSimpleDateFormat(this.resultStandard.natl_info.local_time)
          this.enterMin = this.formData.use_min
        })
        if(this.checkBox.discount) {
          // 지정국가 요금제
          api.ItsvcGetPricePlanInfo({
            req_tp: 'NATL',
            natl_cd: this.formData.natl_cd
          })
          .then(response => {
            this.resultSpecified = response.data
          })
        }
        if(this.checkBox.freeCall) {
          // 안심 3000요금제
          let data = {
            "chrg_cd": "COSFA",
            "natl_cd": this.formData.natl_cd,
            "use_time": this.formData.use_min
          }
          api.ItsvcGetFreeDedtPricePlanInfo(data)
          .then(response => {
            this.resultAnsim = response.data
          })
        }
      }
    },
  }
}
</script>

<style lang="scss">
.advice_list .recommand_cont ul {
  overflow: visible;
  &:after {
    content:'';
    display:block;
    clear:both;
  }
  li .time_box {
    overflow: visible;
    @media (max-width: 1023px) {
      padding: 0 29px 0 80px;
    }
    .autocomplete {
      overflow: visible;
      width: 200px;
      height: 42px;
      margin: 0 3px 0 15px;
      &__box {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 0;
        padding: 0;
      }
      &__inputs {
        width: 100%;
        height: 100%;
        padding: 0;
        input {
          width: 100%;
          height: 100%;
          margin: 0;
        }
      }
      &__icon {
        display: none;
      }
      &__results {
        overflow-y: auto;
        overflow-x: hidden;
        height: 150px;
        border: 1px solid #ddd;
        background-color: #eee;
        color: #000;
        padding: 2px;
        @media (max-width: 1023px) {
          margin-top: 0;
        }
        &__item {
          float: none;
          width: 100%;
          height: 28px;
          padding: 0;
          margin: 0;
          font-size: 17px;
          color: #333;
          line-height: 28px;
          &:hover {
            border: 1px solid #fbd850;
            background-color: #fff;
            font-weight: bold;
            color: #eb8f00;
          }
        }
      }
      &__selected {
        border: 1px solid #fbd850;
        background-color: #fff;
        font-weight: bold;
        color: #eb8f00;
      }
    }
  }
}
</style>
