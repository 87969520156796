<template>
  <div id="FAQ">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            자주하는 질문
          </h3>
          <p class="txt">
            고객들께서 궁금해 하시는 질문 중<br>자주하시는 질문을 모아놓았습니다
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <div class="tab_type01 tab4">
          <ul>
            <li
              v-for="(one, oneIndex) in categories.svc_tp_list"
              :key="`one${oneIndex}`"
              :class="{ on: formData.svc_tp == one.svc_tp}"
            >
              <a
                id="C010"
                title="홈페이지"
                class="btn_tab"
                @click="select(one)"
              >{{ one.svc_tp_nm }}</a>
            </li>
          </ul>
        </div>
        <template v-for="(oneSub, oneSubIndex) in categories.svc_tp_list">
          <div
            v-if="formData.svc_tp == oneSub.svc_tp"
            id="scrollC010"
            :key="`one${oneSubIndex}`"
            class="tab_scroll02"
          >
            <ul>
              <li :class="{ on: !formData.bulletin_tp}">
                <a
                  id="ALL"
                  class="btn_detail_tab"
                  @click="selectSub({ bulletin_tp: ''})"
                >전체</a>
              </li>
              <template v-for="(two, twoIndex) in categories.bulletin_tp_list">
                <li
                  v-show="oneSub.svc_tp === two.svc_tp"
                  :key="`two${twoIndex}`"
                  :class="{ on: formData.bulletin_tp === two.bulletin_tp }"
                >
                  <a
                    id="C010"
                    class="btn_detail_tab"
                    @click="selectSub(two)"
                  >{{ two.bulletin_tp_nm }}</a>
                </li>
              </template>
            </ul>
          </div>
        </template>

        <fieldset class="search_box">
          <legend>질문 검색</legend>
          <div class="search_box_inner">
            <div class="select_box">
              <label for="type_select01">질문내용</label>
              <select
                id="type_select01"
                v-model="formData.search_tp"
                name="i_sSearchKind"
                title="질문 또는 답변으로 검색 선택"
              >
                <option value="ALL">
                  전체
                </option>
                <option value="TITLE">
                  질문내용
                </option>
                <option value="DESC">
                  답변
                </option>
              </select>
            </div>
            <input
              id="i_sKeyword"
              v-model="formData.search_word"
              type="text"
              name="i_sKeyword"
              value=""
              placeholder="검색어를 입력하세요"
              title="검색어를 입력하세요"
              @keyup.enter="search"
            >
            <button
              type="button"
              class="btn_type02 btn_search"
              @click="search"
            >
              검색
            </button>
          </div>
        </fieldset>

        <!-- faq 리스트 -->
        <!-- customer -->
        <div class="customer">
          <div class="faq_list">
            <ul class="faq_wid01">
              <li
                v-for="(faq, faqIndex) in freqQustionList"
                :key="`${faqIndex}`"
                :class="{ on: currentFaq === faqIndex }"
                @click="selectFaq(faqIndex)"
              >
                <!-- to.개발 / 클릭하면 추가되는 클래스 .on  -->
                <div class="tit_box">
                  <dl class="child1">
                    <dt>No</dt>
                    <dd>{{ faq.row_num }}</dd>
                  </dl>
                  <dl class="child2">
                    <dt>구분</dt>
                    <dd>{{ faq.bulletin_tp_nm }}</dd>
                  </dl>
                  <dl class="child3">
                    <dt>상세카테고리</dt>
                    <dd>{{ faq.bulletin_tp_dtl_nm }}</dd>
                  </dl>
                  <dl class="child4">
                    <dt>제목</dt>
                    <dd class="txt_left">
                      <span>{{ faq.title }}</span>
                    </dd>
                  </dl>
                </div>
                <div
                  class="txt_open"
                  :style="currentFaq === faqIndex ? 'display:block' : 'display:none'"
                >
                  {{ faq.bulletin_desc }}
                </div>
              </li>
              <li
                v-if="totalCount === 0"
                class="no-data"
              >
                내용이 없습니다.
              </li>
            </ul>
          </div>

          <!-- paging -->
          <Pagination
            v-if="totalCount > 0"
            :total-count="totalCount"
            :page-per-count="formData.page_per_cnt"
            :current-page="formData.page_num"
            @update="updatePage"
          />
          <!-- <div class="btn_more">
            <button type="button">
              더보기
              <span title="총 페이지">(<em title="현재 페이지">1</em>/4)</span>
            </button>
          </div> -->
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from "@/components/Header"
import BreadCrumb from "@/components/BreadCrumb"
import Pagination from "@/components/Pagination"
import Footer from "@/components/Footer"
import SummitControl from '@/components/SummitControl'

export default {
  name: "Faq",
  metaInfo: {
    title: '자주하는 질문 | 고객상담 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Pagination,
    Footer,
    SummitControl,
  },
  emits: [],
  data() {
    return {
      freqQustionList:{},
      categories: {},
      totalCount: 0,
      formData: {
        svc_tp: '',
        bulletin_tp: '',
        page_per_cnt: 10,
        page_num: 1,
        search_tp: 'ALL',
        search_word: ''
      },
      currentFaq: null
    };
  },
  computed: {},
  watch: {},
  created() {
    api.BulletinGetCommonCode()
    .then(response => {
      this.categories = response.data
      this.formData.svc_tp = this.categories.svc_tp_list[0].svc_tp
      this.search()
    })
  },
  mounted() {},
  methods: {
    select(item) {
      this.formData.svc_tp = item.svc_tp
      this.formData.bulletin_tp = ''
      this.formData.page_num = 1
      this.search()
    },
    selectSub(item) {
      this.formData.bulletin_tp = item.bulletin_tp
      this.formData.page_num = 1
      this.search()
    },
    search() {
      api.BulletinGetFreqQuestionList(this.formData)
        .then(response => {
          this.freqQustionList = response.data.bulletin_list
          this.totalCount = response.data.bulletin_total_cnt
          this.currentFaq = null
        })
    },
    updatePage(num) {
      this.$router.replace({ query: {...this.$route.query, page_num: num }}).catch(()=>{})
      this.formData.page_num = num
      this.search()
    },
    selectFaq(index) {
      this.currentFaq = index
    }
  }
};
</script>

<style lang="scss" scoped>
  .search_box{
    .search_box_inner{
      .select_box{
        border: none;
        label{
          display: none !important;
        }
        select{
          opacity: 1;
          padding: 0 33px 0 15px;
          background-size: 35px auto;
          background-position: right;
          border: 1px solid #e2e2e2;
          border-radius: 0;
          // background-color: transparent;
        }
        &:before{
            height: 53px;
            top: 1px;
        }
      }
    }
  }
  .faq_list ul li.no-data{
    padding: 30px 0;
    text-align: center;
    cursor: initial;
  }
</style>
