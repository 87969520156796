import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

const DEFAULT_STATE = {
  eventCSS: '/content/css/event.css',
  loader: 0,
  certPopup: {
    visible: false,
  },
  kcbCertPopup: false,
  kcbCertKey: '',
  error: false,
  popup: {
    alert: {
      visible: false,
      message: '',
      code: '',
      type: ''
    },
    legal: {
      visible: false
    }
  },
  notice: [],
  alert: {},
  telMask: '',
  ticket: '',
  btnImage: '',
}

const state = JSON.parse(JSON.stringify(DEFAULT_STATE))

const mutations = {
  SET_CALLBACK_ALERT(state, payload) {
    state.alert = payload
  },
  START_LOADER(state) {
    state.loader++
  },
  FINISH_LOADER(state) {
    setTimeout(() => {
      state.loader--
    }, 300)
  },
  SET_NOTICE_ITEMS(state, payload) {
    state.notice = payload
  },
  ON_CERT_POPUP(state) {
    state.certPopup.visible = true
  },
  OFF_CERT_POPUP(state) {
    state.certPopup = JSON.parse(JSON.stringify(DEFAULT_STATE.certPopup))
  },
  ON_KCB_CERT_POPUP(state) {
    state.kcbCertPopup = true
  },
  OFF_KCB_CERT_POPUP(state) {
    state.kcbCertPopup = false
  },
  KCB_CERT_KEY(state, payload) {
    state.kcbCertKey = payload
  },
  SET_ERROR(state, payload) {
    state.error = payload
  },
  ON_ALERT_POPUP(state, payload) {
    state.popup.alert.visible = true
    state.popup.alert.message = payload.message
    if (payload.code) {
      state.popup.alert.code = payload.code
    }
    if (payload.type) {
      state.popup.alert.type = payload.type
    }
  },
  OFF_ALERT_POPUP(state) {
    state.popup.alert.visible = false
    state.popup.alert.message = ''
    state.popup.alert.code = ''
    state.popup.alert.type = ''
  },
  ON_LEGAL_POPUP(state) {
    state.popup.legal.visible = true
  },
  OFF_LEGAL_POPUP(state) {
    state.popup.legal.visible = false
  },
  SET_TEL_MASK(state, payload) {
    state.telMask = payload
  },
  SET_TICKET_NO(state, payload) {
    state.ticket = payload
  },
  SET_BTN_IMAGE(state, payload) {
    state.btnImage = payload
  }
}

const getters = {
  CALLBACK_ALERT: state => {
    return state.alert
  },
  EVENT_CSS: state => {
    return state.eventCSS
  },
  LOADER: state => {
    return state.loader
  },
  CERT_POPUP: state => {
    return state.certPopup
  },
  LEGAL_POPUP: state => {
    return state.popup.legal.visible
  },
  KCB_CERT_POPUP: state => {
    return state.kcbCertPopup
  },
  KCB_CERT_KEY: state => {
    return state.kcbCertKey
  },
  ERROR: state => {
    return state.error
  },
  ALERT_POPUP: state => {
    return state.popup.alert
  },
  NOTICE_ITEMS: state => {
    return state.notice
  },
  TELMASK: state => {
    return state.telMask
  },
  TICKET: state => {
    return state.ticket
  },
  BTNIMAGE: state => {
    return state.btnImage
  }
}

const actions = {}

export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  plugins: [createPersistedState({
    paths: [
      'eventCSS',
      'kcbCertKey',
      'notice',
      'telMask',
      'ticket',
      'btnImage'
    ]
  })]
})

