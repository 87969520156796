<template>
  <div id="header">	
    <!-- begin skipNav 2021-02-19 -->
    <div class="skipNavi">
      <a href="#container">본문 바로가기</a>
    </div>
    <!-- end skipNav 2021-02-19 -->
    <div class="inner">
      <h1>
        <router-link 
          to="/eng/main"
          class="point_color"
        >
          <img
            src="/content/images/h1_logo.png"
            alt="SK telink"
          >
        </router-link>
        <a href="/os_main.do" />
      </h1>
      <div class="gnb-area">
        <div class="utill">
          <!-- <a href="#" class="btn-none"><img src="/content/images/header_btn_user.png" alt="마이페이지"></a> -->
          <div class="web_list">
            <!-- <a href="a">로그인</a>
          <a href="#a">회원가입</a>
          <a href="#a" class="quick"><img src="/content/images/header_btn_quick.png" alt="퀵메뉴"></a> -->
            <router-link 
              to="/main"
              class="point_color"
            >
              KOR
            </router-link>
          </div>
        </div>
      
        <div
          id="gnb"
          :class="{ 'activateGnb': activateGnb }"
        >
          <a
            href="#"
            class="mobile-gnb-btn"
            @click="toggleGnb"
          ><img
            src="/content/images/header_btn_nav.png"
            alt="메뉴"
          ></a>
          <div class="gnb-inner">
            <!-- to개발 / 모바일 메뉴 오픈 시 gnb-inner > style="display: block;" 처리 -->
            <div class="gnb-inner-wrap">
              <!-- to개발 / 모바일 메뉴 오픈 시 gnb-inner-wrap > style="right: 0;" 처리 -->
              <div class="gnb-eng">
                <router-link 
                  to="/main"
                >
                  KOR
                </router-link>
              </div>
              <ul
                class="gnb_list"
              >
                <li
                  v-for="(oneDepth, index) in menuItems"
                  :key="index"
                  class="gnb-list-wrap"
                  :class="{ 'has-sub': oneDepth.children.length }"
                  @mouseleave="gnbClose"
                >
                  <router-link
                    class="gnb-focus"
                    :to="oneDepth.link"
                    @mouseover.native="gnbOpen"
                  >
                    {{ oneDepth.label }}
                  </router-link>
                  <span
                    v-if="oneDepth.children.length > 0"
                    @click="gnbOpenMo"
                  >
                    {{ oneDepth.label }}
                  </span>
                  <span v-else>
                    <router-link
                      :to="oneDepth.link"
                    >
                      {{ oneDepth.label }}
                    </router-link>
                  </span>
                  <div
                    v-if="oneDepth.children.length"
                    ref="gnbListArea"
                    class="gnb-list-area"
                    :class="{ open: isOpen }"
                  >
                    <ul class="gnb-list-inner gnb-list--first">
                      <template
                        v-for="(twoDepth, index2) in oneDepth.children"
                      >
                        <li
                          v-if="twoDepth.isVisible === true"
                          :key="index2"
                        >
                          <router-link
                            :to="twoDepth.link"
                          >
                            {{ twoDepth.label }}
                          </router-link>
                          <span>
                            <router-link
                              :to="twoDepth.link"
                            >
                              {{ twoDepth.label }}
                            </router-link>
                          </span>
                        </li>
                      </template>
                    </ul>
                  </div>
                </li>
              </ul>
              <a
                href="#"
                class="mobile-close-btn"
                @click.prevent="closeGnb"
              ><img
                src="/content/images/gnb_btn_close.png"
                alt="메뉴닫기"
                data-pin-nopin="true"
              ></a>
            </div>
          </div>
        </div>
      <!-- gnb -->
      </div>
    <!-- 메인만 링크 노출 -->
    </div>
  </div>
</template>

<script>
import api from '@/api'
export default {
  name: 'EngHeader',
  data() {
    return {
      activateGnb: false,
      menuItems: [],
      currentIndex: null,
      isOpen: false
    }
  },
  created () {
    window.addEventListener('resize', this.gnbClose);
    api.EngMenu()
      .then(response => {
        this.menuItems = response.data
      })
      
  },
  methods: {
    toggleGnb() {
      this.activateGnb = this.activateGnb ? false : true
    },
    closeGnb() {
      this.activateGnb = false
    },
    gnbOpen(event) {
      const pcGnbList = event.target.nextElementSibling.nextElementSibling
      if(pcGnbList) {
        if(this.isOpen === false) {
          const pcMenuHeight = pcGnbList.childNodes[0].clientHeight;
          pcGnbList.style.height = pcMenuHeight + 'px'
          this.isOpen = true
        } else {
          pcGnbList.style.height = 0 + 'px'
          this.isOpen = false
        }
      }
    },
    gnbClose() {
      this.$refs.gnbListArea.forEach(item => {
        item.style.height = 0 + 'px'
      })
      this.isOpen = false
    },
    gnbOpenMo(event) {
      const moGnbList = event.target.nextElementSibling
      if(moGnbList) {
        if(this.isOpen === false) {
          const subMenuHeight = moGnbList.childNodes[0].clientHeight;
          moGnbList.style.height = subMenuHeight + 'px'
          this.isOpen = true
        } else {
          moGnbList.style.height = 0 + 'px'
          this.isOpen = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#header {
  z-index: 11;
}
.activateGnb {
  @media (max-width: 1023px) {
    .gnb-inner {
      display: block!important;
    }
    .gnb-inner-wrap{
      right: 0!important;
    }
  }
}
#gnb .gnb_list {
  .gnb-list-wrap {
    &:first-child {
      padding-left: 500px;
      margin-left: 0;
      @media (max-width: 1023px) {
        padding-left: 0;
      }
    }
    span a {
      display: inline-block;
      width: 100%;
      line-height: 40px;
    }
    .gnb-list-inner li span a {
      display: block;
      width: 100%;
      margin-bottom: 0;
      font-size: 16px;
      color: #fff;
    }
  }
  .gnb-list-area {
    display: block !important;
    height: 0;
    overflow: hidden;
    transition: all 0.3s linear;
  }
}
</style>