<template>
  <div id="ASIANA_MILEAGE_EVENT">
    <div>
      <!-- 컨텐츠 -->
      <div
        id="AsianaSavingMallHeader"
        class="saving_header"
      >
        <div
          id="AsianaSavingmallPc"
          class="saving_header_pc"
        >	
          <div class="inner">
            <h1>			
              <a
                href="#"
                title="ASIANA AIRLINES 마일리지 적립몰 페이지 이동"
              >
                <img
                  src="https://flyasiana.com/C/pc/image/club/new_img_logo.png"
                  alt="ASIANA AIRLINES"
                >			
              </a>		
            </h1>		
            <div class="item">			
              <div class="cell">				
                <i />			
                <span>
                  아시아나항공은 통신판매중개자로 하단 상품에 대해 보증하거나 별도의 책임을 지지 않으며, 상품 판매와 관련한 책임은 “SK텔링크”에 있습니다.</span>			
              </div>		
            </div>		
            <button
              type="button"
              @click="guidePopup"
            >
              이용안내
            </button>
          </div>
        </div>
        <div
          id="AsianaSavingmallMo"
          class="saving_header_mo"
          style=""
        >	
          <div class="inner">		
            <div class="item">
              아시아나항공은 통신판매중개자로 하단 상품에 대해 보증하거나 별도의 책임을 지지 않으며, 상품 판매와 관련한 책임은 “SK텔링크”에 있습니다.
            </div>		
            <button
              type="button"
              @click="guidePopup"
            >
              이용안내
            </button>	
          </div>	
          <div class="bg" />
        </div>
      </div>
      <div id="container">
        <div id="contents">
          <div class="sub_header bg08">
            <h3 class="title">
              포인트혜택형
            </h3>
            <p class="txt">
              국제전화 이용하고, 추가적인 혜택도 원하는 고객님을 위해<br>SK국제전화 00700이 준비한 HIT 요금제!
            </p>
            <img
              src="/content/images/sub_header_inter08-m.jpg"
              alt="비쥬얼 이미지"
            >
          </div>

          <!-- inter -->
          <div class="inter mb0">
            <div class="sub_title">
              <h4 class="title">
                쓰는 만큼<br class="m_br"> 항공 마일리지도 쌓이는<br>
                <span class="txt_point01">00700 아시아나<br class="m_br"> 마일리지 요금제</span>
              </h4>
              <p class="txt">
                <span class="txt_point01">00700 쓰면 항공 마일리지를 주는<br class="m_br"> 00700 아시아나 마일리지 요금제.</span><br class="m_br">
                국제전화 이용금액 1,000원당<br class="m_br"> 아시아나 3 마일리지 파격 제공!
              </p>
            </div>
            <div class="advice_list">
              <h4>이런 고객님이 사용하면 좋아요!</h4>
              <p><span class="hashtag"># 법인고객</span><span class="hashtag"># 마일리지적립</span><span class="hashtag"># 해외직구</span></p>
              <div class="advice_scroll">
                <div>
                  <ul class="list-scroll-small">
                    <li>
                      <div>
                        <img
                          src="/content/images/img_inter07.png"
                          alt=""
                        >
                      </div>
                      <p>해외 출장이 잦은 비즈니스 고객</p>
                    </li>
                    <li>
                      <div>
                        <img
                          src="/content/images/img_inter08.png"
                          alt=""
                        >
                      </div>
                      <p>항공 마일리지를 적립하는<br class="m_br"> 국제전화 이용고객</p>
                    </li>
                    <li>
                      <div>
                        <img
                          src="/content/images/img_inter03.png"
                          alt=""
                        >
                      </div>
                      <p>1년에 2회 이상 해외여행을 즐기는 <br>YOLO족</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="advice_list white">
              <h4>거부할 수 없는 매력은<br class="m_hide"> 무엇인가요?</h4>
              <div class="advice_scroll">
                <div>
                  <ul class="list-scroll-small">
                    <li>
                      <div>
                        <img
                          src="/content/images/img_inter_sub06.png"
                          alt=""
                        >
                      </div>
                      <dl>
                        <dt>아시아나 항공 마일리지 제공</dt>
                        <dd>
                          국제전화 00700을 사용하면 쌓이는<br>
                          아시아나 마일리지!<br>
                          1천원당 아시아나 3 마일리지가 제공되는<br>
                          오직 00700만의 혜택을 누려보세요.
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <div>
                        <img
                          src="/content/images/img_inter_sub07.png"
                          alt=""
                        >
                      </div>
                      <dl>
                        <dt>할인까지 부족함 없이 제공</dt>
                        <dd>
                          전세계 200여 개국에 대해<br>
                          최대 70% 할인요금 제공!<br>
                          국제전화 이용할인에 마일리지 적립까지<br>
                          1석 2조의 혜택을 누려보세요.
                        </dd>
                      </dl>
                    </li>
                    <li>
                      <div>
                        <img
                          src="/content/images/img_inter_sub08.png"
                          alt=""
                        >
                      </div>
                      <dl>
                        <dt>가입비 100% 무료</dt>
                        <dd>
                          무료통화를 받기 위해 필요한 요금제 가입비 0원!<br>
                          남녀노소, 국내거주 외국인까지<br>
                          부담 없이 가입하여 이용할 수 있습니다.<br>
                          (단, 아시아나 마일리지 번호는 필수)
                        </dd>
                      </dl>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="inter_seach">
              <h4><strong>아시아나 마일리지 요금제</strong>국가별 요금을 확인해보세요</h4>
              <div class="type01">
                <Autocomplete
                  v-model="formData.natl_cd"
                  :source="country"
                  :results-display="'natl_nm'"
                  :results-value="'natl_cd'"
                  :clear-button-icon="''"
                  :placeholder="'국가명을 입력해주세요'"
                  @selected="selectCountry"
                  @keyupEnter="search"
                />
                <button
                  type="button"
                  class="btn_type01 btn_search"
                  @click="search"
                >
                  검색
                </button>
              </div>
            </div>
          
            <div
              v-if="searchResultArea"
              id="amountArea"
              class="result_area"
            >
              <div class="table_type04 amount">
                <table>
                  <caption>아시아나마일리지 요금제, 표준요금, 할인율 나타내는 표입니다.</caption>
                  <colgroup>
                    <col style="width:34%;">
                    <col style="width:33%;">
                    <col style="width:33%;">
                  </colgroup>
                  <tbody>
                    <tr>
                      <th scope="col">
                        아시아나<br class="m_br">마일리지<br class="m_br"> 요금제
                      </th>
                      <th scope="col">
                        표준요금
                      </th>
                      <th scope="col">
                        할인율
                      </th>
                    </tr>
                    <tr>
                      <td>{{ result.price | comma }}원/분</td>
                      <td>{{ result.price_00700 | comma }}원/분</td>
                      <td>{{ result.discount_rate }}%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
  
              <ul class="txt_em">
                <li><span class="icon_star">*</span>평균 28%, 최대 70% 할인 제공(일부 국가에 대해서는 표준요금보다 요금이 높을 수 있습니다.)</li>
                <li><span class="icon_star">*</span>과금 기준 : 아시아나마일리지 요금제(원/분), 표준요금(원/초)</li>
                <li><span class="icon_star">*</span>해당 요금 적용은 요금제 가입일시부터 적용됩니다.</li>
                <li><span class="icon_star">*</span>표기된 요금은 VAT가 포함된 요금이며, 상기 표준요금은 비교를 위해 분으로 환산된 금액으로 표기되었습니다</li>
              </ul>
            </div>

            <div class="inter_seach02">
              <h4><strong>아시아나 마일리지</strong> 예상적립을 알아보세요</h4>
              <div class="type01">
                <input
                  id="exptAmt"
                  v-model="inputAmount"
                  type="text"
                  class="type02"
                  title="마일리지 예상사용금액 입력"
                  placeholder="예상사용금액 (1달)"
                  autocomplete="off"
                  oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  @keyup.enter="inquire"
                >
                <button
                  type="button"
                  class="btn_type02 btn_search2"
                  @click="inquire"
                >
                  조회
                </button>
              </div>
            </div>

            <!-- 검색결과박스 -->
            <div
              v-if="inquireResultArea"
              id="expArea"
              class="result_area"
            >
              <div class="table_type04">
                <table>
                  <caption>예상 사용금액(1달), 예상 적립 마일리지 나타내는 표입니다.</caption>
                  <colgroup>
                    <col style="width:50%;">
                    <col style="width:50%;">
                  </colgroup>
                  <thead>
                    <tr>
                      <th scope="col">
                        예상 사용금액(1달)
                      </th>
                      <th scope="col">
                        예상 적립 마일리지
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td id="amount">
                        {{ outputAmount | comma }}원
                      </td>
                      <td id="mileage">
                        {{ mileage | comma }}마일리지
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <ul class="txt_em">
                <li><span class="icon_star">*</span>아시아나 마일리지는 사용 후 다음 달 말일까지 자동 적립됩니다.</li>
                <li><span class="icon_star">*</span>해당 시뮬레이션은 적립 예상 마일리지며, 실 적립 마일리지와 다를 수 있습니다.</li>
                <li><span class="icon_star">*</span>아시아나 마일리지 적립은 VAT가 제외된 금액 기준으로 계산됩니다.</li>
                <li><span class="icon_star">*</span>시스템에 등록된 마일리지 번호가 변경된 경우 고객센터(1599-00700)를 통해 변경하실 수 있습니다. </li>
              </ul>
            </div>
            <div class="btn_center single line_top">
              <a
                class="btn_type01 req_asiana"
                @click="applyPopup = true"
              >아시아나 마일리지 요금제 신청하기</a>
            </div>
            <!-- //검색결과박스 -->

            <div class="use_list_area">
              <div class="use_list">
                <h4>쉽게 따라 하는 이용방법</h4>
                <p>한국에서 태국 방콕 지역의 <span>휴대폰으로 전화를 걸 경우</span></p>
                <ul>
                  <li>
                    <div>
                      <img
                        src="/content/images/icon_inter01.png"
                        alt=""
                      >
                    </div>
                    <p>00700 국제전화 식별번호</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/icon_inter06.png"
                        alt=""
                      >
                    </div>
                    <p>국가번호(태국 : 66)</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/icon_inter33.png"
                        alt=""
                      >
                    </div>
                    <p>상대방 휴대폰 번호(65-123-4567)<br><span><span class="icon_star">*</span>휴대폰 번호는 0번 제외</span></p>
                  </li>
                </ul>
              </div>

              <div class="use_list">
                <p>한국에서 태국 방콕 지역의 <span>일반전화로 전화를 걸 경우</span></p>
                <ul class="four">
                  <li>
                    <div>
                      <img
                        src="/content/images/icon_inter01.png"
                        alt=""
                      >
                    </div>
                    <p>00700 국제전화 식별번호</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/icon_inter06.png"
                        alt=""
                      >
                    </div>
                    <p>국가번호(태국 : 66)</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/icon_inter25.png"
                        alt=""
                      >
                    </div>
                    <p>지역번호(방콕 : 2)<br><span><span class="icon_star">*</span>지역번호 번호는<br class="m_hide"> 0번 제외</span></p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/icon_inter40.png"
                        alt=""
                      >
                    </div>
                    <p>상대방  번호(123-4567)</p>
                  </li>
                </ul>
              </div>
            </div>

            <div class="notice_area gray">
              <h4 class="title">
                <span class="icon_mark"><img
                  src="/content/images/icon_mark01.png"
                  alt="주의사항"
                ></span>요금제 가입 시 꼭 읽어보세요
              </h4>
              <ul>
                <li><span class="icon_star">*</span>00700 아시아나 마일리지 요금제를 가입하는 경우, 아시아나 마일리지 적립을 위하여 가입 시 제공되는 개인정보 수집/이용/제공에 반드시 동의하셔야 정상 가입이 완료됩니다.</li>
                <li><span class="icon_star">*</span>개인정보 수집/이용/제공 동의를 거부하실 경우 요금제 가입에 제한이 있을 수 있습니다.</li>
                <li><span class="icon_star">*</span>아시아나 마일리지 번호는 OZ를 제외한 숫자 9자리이며, 번호 변경 시 고객센터를 통해 변경하셔야 지속적으로 적립됩니다.</li>
                <li><span class="icon_star">*</span>아시아나 마일리지 적립은 다음  달 말까지 자동적으로 일괄 적립됩니다.</li>
                <li><span class="icon_star">*</span>국제전화 00700 국내통화요금제는 6개월간  미 사용 시 자동 취소될 수 있습니다.</li>
                <li><span class="icon_star">*</span>번호 변경 시, 요금제 가입을 다시 신청해야만 기존 혜택이 유지됩니다. </li>
                <li><span class="icon_star">*</span>국제전화 요금제 변경 또는 재가입 신청은 홈페이지 내 마이페이지 또는 고객센터 1599-00700(평일 09시~18시)에서 변경/수정 가능합니다.</li>
                <li><span class="icon_star">*</span>비정상적이거나 불법적인 방법으로 국제전화를 이용할 경우 사전 안내 없이 국제전화 요금제 취소 및 이용에 제한이 있을 수 있습니다.</li>
              </ul>
            </div>
          </div>	
          <!-- //inter -->
        </div>
      </div>
      <!--// 컨텐츠 -->
      <!-- // container -->

      <!-- 팝업 -->
      <div
        v-if="applyPopup"
        id="popAsiana"
        class="pop_wrap02 pop_asiana pop-scroll"
        style="display: block;"
      >
        <div class="medium">
          <h3>요금제 신청</h3>
          <div class="pop_cont02">
            <div class="cont_term">
              <h4 class="txt_point01">
                개인정보 수집/이용/제공 동의 안내
              </h4>
              <p>
                SK텔링크 주식회사는 제휴상품 가입 진행을 위하여 아래와 같이 고객정보를 수집하며, <br>
                고객정보  취급에 관한 상세한 사항은 
                <router-link 
                  to="/main"
                  class="txt_point01"
                >
                  www.sktelink.com
                </router-link>
                에 게시된 개인정보처리방침을 참조하시기 바랍니다.
                <button
                  type="button"
                  class="jk_toggle_btn"
                  :class="{ on: privacyShow }"
                  @click="privacyShow = !privacyShow"
                >
                  더보기
                </button>
              </p>
              <transition name="slide">
                <div
                  v-if="privacyShow"
                  class="jk_toggle_hide"
                  style="display:block"
                >
                  <h5 class="icon_sqare">
                    개인정보 수집항목/이용목적/이용기간
                  </h5>
                  <ul>
                    <li>수집항목 : 이름, 전화번호, 아시아나 회원번호</li>
                    <li>이용목적 : 요금제가입, 고객상담진행, 마일리지 적립</li>
                    <li>보유 및 이용기간 : <strong>서비스이용기간</strong></li>
                  </ul>
                
                  <h5 class="icon_sqare">
                    개인정보의 제3자 제공
                  </h5>
                  <ul>
                    <li>제공받는 자 : 아시아나항공</li>
                    <li>제공하는 항목 : 전화번호, 아시아나 회원번호, 적립마일리지</li>
                    <li>제공받는 자의 이용목적 : 회원여부 확인, 마일리지 적립</li>
                    <li>보유 및  이용기간 : <strong>고객정보 등록 시점부터 표준요금제 전환 이후 5년까지 보관 후 삭제</strong></li>
                  </ul>
                
                  <p>개인정보 수집/이용/제공에 대해서 동의/거부할 권리가 있으나 거부 시 요금제 가입이 제할 될 수 있습니다.</p>
                </div>
              </transition>
              <p class="txt_point01">
                본인은 요금제 가입을 위하여 위와 같이 본인의 고객정보 제3자 제공하는 것에
              </p>
              <div class="radio_area">
                <div class="radio_box">
                  <input
                    id="agree_term_yes1"
                    ref="agreeTerm"
                    v-model="agreeTerm"
                    type="radio"
                    name="agree_term1"
                    value="Y"
                  >
                  <label for="agree_term_yes1">동의합니다</label>
                </div>
                <div class="radio_box">
                  <input
                    id="agree_term_no1"
                    v-model="agreeTerm"
                    type="radio"
                    name="agree_term1"
                    value="N"
                  >
                  <label for="agree_term_no1">동의하지 않습니다(참여불가)</label>
                </div>
              </div>
              <ul class="txt_em">
                <li><span class="icon_star">*</span>요금제 가입을 위해 고객님이 사용하시는 휴대폰 및 집전화 번호를 하단 입력창에 입력해주세요.</li>
                <li><span class="icon_star">*</span>아시아나 회원번호 입력은 OZ제외 숫자 9자리만 입력해주세요.</li>
              </ul>
            </div>
            <div class="table_type01">
              <dl>
                <dt>성명</dt>
                <dd>
                  <input
                    id="asianaName"
                    ref="name"
                    v-model="name"
                    type="text"
                    title="성명 입력"
                    class="wid299"
                    name="i_sMemberName"
                  >
                </dd>
              </dl>
              <dl class="double">
                <dt>전화번호</dt>
                <dd>
                  <div>
                    <div class="radio_box">
                      <input
                        id="applynum01"
                        v-model="telType"
                        type="radio"
                        name="applynum"
                        checked="checked"
                        value="M"
                      >
                      <label 
                        for="applynum01"
                        @click="telNumber.lfirst = '', telNumber.middle = '', telNumber.last = ''"
                      >무선번호</label>
                    </div>

                    <div class="radio_box">
                      <input
                        id="applynum02"
                        v-model="telType"
                        type="radio"
                        name="applynum"
                        value="L"
                      >
                      <label 
                        for="applynum02"
                        @click="telNumber.middle = '', telNumber.last = ''"
                      >유선번호</label>
                    </div>
                  </div>
                  <div>
                    <div class="space_box">
                      <div 
                        v-if="telType === 'M'"
                        class="select_box wid112"
                      >
                        <select
                          id="phone_select01"
                          ref="telNumberMFirst"
                          v-model="telNumber.mfirst"
                          title="전화번호 앞자리 선택"
                          name="i_sPhoneFirst"
                        >
                          <option value="010">
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                        </select>
                      </div>
                      <input
                        v-if="telType === 'L'"
                        id="phone1"
                        ref="telNumberLFirst"
                        v-model="telNumber.lfirst"
                        type="text"
                        class="wid112"
                        placeholder=""
                        title="전화번호 지역번호 입력"
                        maxlength="3"
                        size="4"
                        name="i_sPhoneLast"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                      <span class="txt_hyphen">-</span>
                      <input
                        id="phone2"
                        ref="telNumberMiddle"
                        v-model="telNumber.middle"
                        type="text"
                        class="wid112"
                        placeholder=""
                        title="전화번호 가운데자리 입력"
                        maxlength="4"
                        size="4"
                        name="i_sPhoneMiddle"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                      <span class="txt_hyphen">-</span>
                      <input
                        id="phone3"
                        ref="telNumberLast"
                        v-model="telNumber.last"
                        type="text"
                        class="wid112"
                        placeholder=""
                        title="전화번호 마지막자리 입력"
                        maxlength="4"
                        size="4"
                        name="i_sPhoneLast"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                    <a
                      v-if="telType === 'M'"
                      class="btn_sendnum obj_cert"
                      @click="requestSMSCert"
                    >인증하기</a>
                  </div>
                </dd>
              </dl>
              <!-- 유선번호 선택 시, 인증번호 입력 영역 비노출 -->
              <dl
                v-if="telType === 'M'"
                id="numberCheck"
                class="C030 duplPhono w100"
              >
                <dt>인증번호 입력</dt>
                <dd>
                  <input
                    ref="certNumber"
                    v-model="certNumber"
                    type="text"
                    name="i_sCertificationNo"
                    title="인증번호"
                    class=""
                    maxlength="6"
                    pattern="[0-9]*"
                    inputmode="numeric"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  > <a
                    class="btn btn_checknum"
                    @click="requestCert"
                  >인증번호확인</a>
                </dd>
              </dl>
              <!-- //유선번호 선택 시, 인증번호 입력 영역 비노출 -->
              <dl>
                <dt>아시아나 회원번호</dt>
                <dd>
                  <!-- 모바일이 아닐 경우 -->
                  <input
                    id="asianaNo"
                    ref="memberNumber"
                    v-model="member_no"
                    type="text"
                    title="아시아나 회원번호 입력"
                    placeholder="OZ 제외한 숫자 9자리 입력해주세요."
                    class="wid299 onlyNumber"
                    maxlength="9"
                    size="9"
                    numberonly="true"
                    oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                  >
                
                  <br>
                  <p class="p_cont">
                    마일리지 확인방법 <a
                      class="a_cont"
                      target="_blank"
                      href="https://flyasiana.com/I/KR/KO/viewLogin.do?callType=IBE&menuId=CM201802220000728453"
                    ><b>[바로가기]</b></a><br>
                    ※ 마일리지 조회는 아시아나클럽 회원 로그인 후 가능합니다.
                  </p>
                </dd>
              </dl>
            </div>
          </div>
          <!-- 2017-07-15 팝업위치변경 -->
          <div class="btn_center double">
            <input
              type="button"
              class="btn_type01"
              value="신청하기"
              style="cursor: pointer;"
              @click="requestFinal"
            >
          </div>
          <!-- //2017-07-15 팝업위치변경 -->
          <a 
            @click="applyPopup = false"
          >
            <img
              src="/content/images/btn_pop_close.png"
              alt="닫기"
            >
          </a>
        </div>
      </div>
      <!-- //팝업 -->
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Autocomplete from '@/components/Autocomplete'

export default {
  name: 'AsianaMileageEvent',
  components: {
    Autocomplete
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      formData: {},
      selectedCountry: {},
      country: [],
      result: {
        natl_info: {},
        price_info: {}
      },
      searchResultArea: false,
      inquireResultArea: false,
      applyPopup: false,
      privacyShow: false,
      inputAmount: '',
      outputAmount: '',
      mileage: '',
      agreeTerm: '',
      name: '',
      member_no: '',
      telType: 'M',
      telNumber: {
        mfirst: '010'
      },
      certNumber: '',
      ticket: ''
    }
  },
  computed: {
    tel_m_no() {
      return `${this.telNumber.mfirst}${this.telNumber.middle}${this.telNumber.last}`
    },
    tel_l_no() {
      return `${this.telNumber.lfirst}${this.telNumber.middle}${this.telNumber.last}`
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if(this.callbackAlert.code === '0002' || this.callbackAlert.code ==='E027' || this.callbackAlert.code ==='E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code ==='E011' || this.callbackAlert.code ==='E017' || this.callbackAlert.code ==='E018'){
          this.resetPage()
        }
        if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
          this.telNumber = {
            mfirst: '010',
            middle: '',
            last: ''
          },
          this.certNumber = '',
          this.member_no = '',
          this.ticket = ''
        }
        if (this.callbackAlert.code === 'E001') { // 인증시간 초과
          this.certNumber = ''
          this.ticket = ''
        }
      }
    }
  },
  created () {
    // this.guidePopup()
    api.ItsvcGetPrePricePlanInfo()
      .then(response => {
        this.country = response.data
      })
  },
  mounted () {
    this.$root.$el.children[0].classList.add('only_cont')
  },
  methods: {
    // 국가별요금 조회 
    selectCountry(item) {
      this.selectedCountry = item.selectedObject
    },
    search() {
      if (!this.formData.natl_cd) {
        this.$store.commit('ON_ALERT_POPUP', { message: '국가명을 입력하세요.' })
      } else {
        this.searchResultArea = true
        const data = {
          "req_tp": "ASIANA",
          "natl_cd": this.formData.natl_cd
        }
        api.ItsvcGetPricePlanInfo(data)
        .then(response => {
          this.result = response.data
        })
      } 
    },
    // 예상적립 조회
    inquire() {
      this.inquireResultArea = true
      this.outputAmount = this.inputAmount
      let remainder = this.inputAmount / 1000
      this.mileage = Math.floor(remainder) * 3
    },
    // 이용안내 팝업
    guidePopup() {
      window.open("https://flyasiana.com/C/KR/KO/club/header/guide-popup.do?mallCode=SKTELINK&mallName=SK%ED%85%94%EB%A7%81%ED%81%AC","","width=800,height=700,top=200")
    },
    // 요금제 신청하기
    checkPrivacy() {
      if(!this.agreeTerm || this.agreeTerm === 'N') {
        alert('개인정보 수집/이용/제공 동의 안내 동의하지 않으시면 아시아나 마일리지 요금제에 가입할 수 없습니다.')
        this.$refs.agreeTerm.focus()
        return false
      } else {
        return true
      }
    },
    checkName() {
      if(!this.name) {
        alert('이름을 입력하세요.')
        this.$refs.name.focus()
        return false
      } else {
        return true
      }
    },
    checkMPhoneNumber() {
      if(!this.telNumber.middle) {
        this.$refs.telNumberMiddle.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(!this.telNumber.last) {
        this.$refs.telNumberLast.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        alert('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else {
        return true
      }
    },
    checkLPhoneNumber() {
      if(!this.telNumber.lfirst) {
        this.$refs.telNumberLFirst.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(!this.telNumber.middle) {
        this.$refs.telNumberMiddle.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(!this.telNumber.last) {
        this.$refs.telNumberLast.focus()
        alert('휴대폰 번호를 입력해 주세요.')
        return false
      } else if(this.telNumber.lfirst.length < 3 || this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        alert('휴대폰 번호가 유효하지 않습니다.')
        return false
      } else {
        return true
      }
    },
    checkCertNumber() {
      if (!this.certNumber) {
        this.$refs.certNumber.focus()
        alert('인증번호를 입력해 주세요.')
        return false
      } else {
        return true
      }
    },
    checkMemberNumber() {
      if(!this.member_no) {
        this.$refs.memberNumber.focus()
        alert('아시아나 회원번호를 입력해주세요. (OZ제외한 숫자 9자리)')
        return false
      } else if(this.member_no.length < 9) {
        this.$refs.memberNumber.focus()
        alert('올바른 아시아나회원번호를 입력하세요.')
      } else {
        return true
      }
    },
    // SMS 인증하기
    requestSMSCert() {
      if (this.checkMPhoneNumber()) {
        api.CommonPostReqSmsAuth({ svc_tel_no: this.tel_m_no })
          .then(response => {
            if (response.data.sms_ticket) {
              alert('인증번호가 정상적으로 발송되었습니다.')
              this.ticket = response.data.sms_ticket
            }
          })
      }
    },
    // 인증 번호로 SMS 인증 확인
    requestCert() {
      if (this.checkCertNumber()) {
        api.CommonGetCheckSmsAuth({ auth_no: this.certNumber, sms_ticket: this.ticket })
          .then(response => {
            if (response.data.result_cd === '0001') {
              alert('인증번호가 확인되었습니다. 감사합니다.')
            }
          })
      }
    },
    requestFinal() {
      if(this.telType === 'M') {
        // 무선번호 경우
        if (this.checkPrivacy() && this.checkName() && this.checkMPhoneNumber() && this.checkCertNumber() && this.checkMemberNumber()) {
          api.EventPostReqPartnerEvent({
            sms_ticket: this.ticket,
            event_no: 'SUBS_ASIAN',
            member_no: this.member_no
          })
          .then(response => {
            if (response.data.result_cd === '0002') {
              this.applyPopup = false
            }
          })
        }
      } else {
        // 유선번호 경우
        if (this.checkPrivacy() && this.checkName() && this.checkLPhoneNumber() && this.checkMemberNumber()) {
          api.EventPostReqPartnerEvent({
            tel_no: this.tel_l_no,
            event_no: 'SUBS_ASIAN',
            member_no: this.member_no
          })
          .then(response => {
            if (response.data.result_cd === '0002') {
              this.applyPopup = false
            }
          })
        }
      } 
    },
    resetPage(){
      this.agreePrivacy = false,
      this.name = '',
      this.telNumber = {
        mfirst: '010',
        middle: '',
        last: ''
      },
      this.certNumber = '',
      this.member_no = '',
      this.ticket = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.sub_title .title .txt_point01 {
  font-size: 44px;
  line-height: 1.2em;
  @media (max-width: 1023px) {
    font-size: 29px;
    line-height: 18px;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease-in;
}
.slide-enter-to, .slide-leave {
   max-height: 300px;
   overflow: hidden;
}
.slide-enter, .slide-leave-to {
   overflow: hidden;
   max-height: 0;
}

#ASIANA_MILEAGE_EVENT {
  .pop_wrap02 {
    z-index: 10;
  }
}
</style>
