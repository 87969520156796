<template>
  <div id="FREE_TRIAL">
    <Header />
    <!-- 컨텐츠 -->
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />

      <div id="contents">
        <div class="sub_header bg10">
          <h3 class="title">
            국제전화 무료체험
          </h3>
          <p class="txt">
            국제전화 00700의 우수한 통화품질과 서비스를 경험하고 싶은<br>고객님을 위해 무료통화 5분의 체험 기회를 드립니다!
          </p>
          <img
            src="/content/images/sub_header_inter10-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- inter -->
        <div class="inter mb0">
          <!-- 하단 여백없을시 class mb0 추가 -->
          <div class="advice_list">
            <h4>이런 고객님이 사용하면 좋아요!</h4>
            <p class="advice_list_text">
              <span class="hashtag"># 서비스 품질</span><span class="hashtag"># 국제전화</span><span class="hashtag"># 짧은통화</span>
            </p>
            <div class="advice_scroll">
              <div class="advice_scroll_inner">
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter24.png"
                        alt=""
                      >
                    </div>
                    <p>국제전화 서비스 품질을<br class="m_br"> 비교하고 싶은 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter25.png"
                        alt=""
                      >
                    </div>
                    <p>전 세계 어디든지 잘 터지는<br> 국제전화가 필요한 고객</p>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter26.png"
                        alt=""
                      >
                    </div>
                    <p>짧은 시간 국제전화를<br class="m_br"> 사용하고 싶은 고객</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="advice_list white">
            <h4>거부할 수 없는 매력은<br class="m_hide"> 무엇인가요?</h4>
            <div class="advice_scroll">
              <div class="advice_scroll_inner">
                <ul class="list-scroll-small">
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub32-1.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>해외 직통회선으로<br> 고품질 통화 제공</dt>
                      <dd>국제전화 통화량의 95%를 차지하는<br> 상위 30개국과의 직통회선 연결로<br> 더욱 생생하고 깨끗한 통화품질을 제공해,<br> 고객의 마음까지 전달합니다.</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub23.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>가입비 없이<br> 무료통화 혜택 누리기</dt>
                      <dd>국제전화 이용 시 가입비/기본료 100% 무료!<br> 사용하고 싶을 때 언제든지 쓸 수 있는<br> 자유로운 이용으로 무료통화 혜택을 누려보세요.</dd>
                    </dl>
                  </li>
                  <li>
                    <div>
                      <img
                        src="/content/images/img_inter_sub33-1.png"
                        alt=""
                      >
                    </div>
                    <dl>
                      <dt>최고의 고객만족 서비스</dt>
                      <dd>국가고객만족도 1위 기업답게!<br> 00700을 많이 사용하거나<br> 오랜 기간 이용해주신 고객님께<br> 우수고객 사은행사, 정기 문화행사, 무료통화권 등<br> 다양한 혜택을 제공합니다.</dd>
                    </dl>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="inter_seach">
            <h4><strong> 국제전화 00700 표준요금</strong>과 현지정보를 확인해보세요</h4>
            <div class="type01">
              <Autocomplete
                v-model="formData.natl_cd"
                :source="country"
                :results-display="'natl_nm'"
                :results-value="'natl_cd'"
                :clear-button-icon="''"
                :placeholder="'국가명을 입력해주세요'"
                @selected="selectCountry"
                @keyupEnter="inquire"
              />
              <button
                type="button"
                class="btn_type01 btn_search"
                @click="inquire"
              >
                검색
              </button>
            </div>
          </div>

          <!-- 검색결과박스 -->
          <div
            v-if="resultAreaOpen"
            class="result_area"
          >
            <div class="table_type04">
              <table>
                <caption>00700 표준요금, 001 표준요금, 002 표준요금, 수도, 국가번호, 시차 및 현지시간 나타내는 표입니다.</caption>
                <colgroup>
                  <col style="width:34%;">
                  <col style="width:33%;">
                  <col style="width:33%;">
                </colgroup>
                <tbody>
                  <tr>
                    <th scope="col">
                      00700<br class="m_br"> 표준요금
                    </th>
                    <th scope="col">
                      001<br class="m_br"> 표준요금
                    </th>
                    <th scope="col">
                      002<br class="m_br"> 표준요금
                    </th>
                  </tr>
                  <tr>
                    <td>{{ result.price_info.price_00700 }}원/분</td>
                    <td>{{ result.price_info.price_001 }}원/분</td>
                    <td>{{ result.price_info.price_002 }}원/분</td>
                  </tr>
                  <tr>
                    <th scope="col">
                      수도
                    </th>
                    <th scope="col">
                      국가번호
                    </th>
                    <th scope="col">
                      시차 및<br class="m_br"> 현지시간
                    </th>
                  </tr>
                  <tr>
                    <td>{{ result.natl_info.natl_nm }}/{{ result.natl_info.capital }}</td>
                    <td>{{ result.natl_info.natl_no }}</td>
                    <td class="txt_point01">
                      <span class="icon_img"><img
                        src="/content/images/icon_time.png"
                        alt="시차 및 현지시간"
                      ></span>
                      {{ result.natl_info.time_gap }}시간({{ result.natl_info.fomatedDate }})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <ul class="txt_em">
              <li><span class="icon_star">*</span>해당 요금은 VAT가 포함된 금액입니다.</li>
              <li><span class="icon_star">*</span>해당 요금은 표준요금 기준으로 작성되었습니다.</li>
              <li><span class="icon_star">*</span>실제 과금 기준은 1초당 과금됩니다.</li>
            </ul>
            <div class="btn_center line_top">
              <router-link
                to="/view/business/calling_plan_domestic"
                class="btn_type01"
              >
                할인요금 알아보러 가기
              </router-link>
            </div>
          </div>
          <!-- //검색결과박스 -->

          <div class="use_list_area">
            <div class="use_list">
              <div class="login_use">
                <h4>00700 무료통화 5분 신청</h4>
                <div class="btn_center single">
                  <!-- 00700 무료통화 신청 레이어 팝업 -->
                  <a
                    class="btn_type01"
                    @click="certKcbToken"
                  >신청하기</a>
                  <!--// 00700 무료통화 신청 레이어 팝업 -->
                </div>
              </div>
            </div>
          </div>

          <div class="notice_area gray">
            <h4 class="title">
              <span class="icon_mark"><img
                src="/content/images/icon_mark01.png"
                alt="주의사항"
              ></span>무료체험 신청시 꼭 읽어보세요
            </h4>
            <ul>
              <li><span class="icon_star">*</span>해당 무료통화 신청은 최근 2개월 이내 국제전화 00700을 이용하지 않은 고객만 신청할 수 있습니다.</li>
              <li><span class="icon_star">*</span>국제전화 무료통화 5분은 신청일로부터 다음달 말 일까지 적용됩니다.</li>
              <li><span class="icon_star">*</span>기한 내 사용하지 않거나 남은 무료통화 분수는 이월되지 않습니다.</li>
              <li><span class="icon_star">*</span>국제전화 00700 요금제 중 정액형, 구독형 요금제 이용 고객은 요금제에 포함된 기본 무료 제공 분수를 초과한 분수에 대해 본 무료 체험이 적용되는 점 유의하시길 바라겠습니다.</li>
              <li><span class="icon_star">*</span>불법적인 방법으로 국제전화 00700을 이용한 경우 국제전화 무료체험 이 적용되지 않을 수 있습니다.</li>
              <li><span class="icon_star">*</span>해당 무료체험 서비스는 SK텔링크의 사정에 의해 통보 없이 종료될 수 있습니다.</li>
              <li><span class="icon_star">*</span>선불폰 이용고객의 경우 해당 무료체험 서비스를 이용할 수 없습니다. </li>
              <li><span class="icon_star">*</span>신청 고객 정보는 민원 대응 등을 위해 신청일로부터 6개월까지 보관되며, 이후 즉시 파기됩니다. </li>
            </ul>
          </div>
        </div>
        <!-- //inter -->
      </div>
      <!--// 컨텐츠 -->
    </div>
    <!-- // container -->
    <Footer />
    <PopupApplyFree
      v-if="certResult.svc_tel_no"
      :is-visible.sync="popup.applyFree.isVisible"
      :used-phone-number="certResult.svc_tel_no"
      @already="applyFreeAlready"
      @success="applyFreeSuccess"
    />
    <!-- 이미 신청한 번호 -->
    <Popup
      :is-visible.sync="applyNumber.pastPopupVisible"
      :pop-title="'무료통화'"
      :c-type2="'btn_type02'"
      @close="applyNumber.pastPopupVisible = false"
      @click="applyNumber.pastPopupVisible = false"
      @confirm="applyNumber.pastPopupVisible = false"
    >
      <div class="pop_row">
        <span class="txt_point02">{{ applyNumber.number }}</span>는 <span class="txt_point01">{{ applyNumber.start }}</span>에<br>
        무료통화 서비스를 신청하신 번호 입니다.
      </div>
      <div class="pop_row">
        <span class="txt_point01">{{ applyNumber.end }}</span> 이후에 재신청이 가능합니다.
      </div>
    </Popup>

    <Popup
      :is-visible.sync="applyNumber.successPopupVisible"
      :pop-title="'무료통화'"
      :c-type2="'btn_type02'"
      @click="applyNumber.successPopupVisible = false"
      @close="applyNumber.successPopupVisible = false"
      @confirm="applyNumber.successPopupVisible = false"
    >
      <div class="pop_row">
        <span class="txt_point02">{{ applyNumber.number }}</span>번호로<br>
        신청 완료 되었습니다.
      </div>
      <div class="pop_row">
        국제전화 무료통화 서비스는<br>
        <span class="txt_point01">{{ applyNumber.start }}</span> 에 신청하셨으며,<br>
        <span class="txt_point01">{{ applyNumber.end }}</span> 이후에 재신청이 가능합니다.
      </div>
    </Popup>
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import util from '@/utilities'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'
import Autocomplete from '@/components/Autocomplete'
import PopupApplyFree from '@/components/popup/PopupApplyFree'
import Popup from '@/components/popup/Popup'

export default {
  name: 'FreeTrial',
  metaInfo: {
    title: '국제전화무료체험 | 국제전화 00700 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl,
    Autocomplete,
    PopupApplyFree,
    Popup
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      formData: {},
      selectedCountry: {},
      country: [],
      resultAreaOpen: false,
      result: {
        natl_info: {},
        price_info: {}
      },
      certResult: {},
      popup: {
        applyFree: {
          isVisible: false
        }
      },
      applyNumber: {
        pastPopupVisible: false,
        successPopupVisible: false,
        number: '',
        start: '',
        end: ''
      },
    }
  },
  computed: {
    token() {
      return this.$store.getters.KCB_CERT_KEY
    }
  },
  created () {
    api.ItsvcGetPreStandardPricePlanInfo()
      .then(response => {
        this.country = response.data
      })
  },
  methods: {
    applyFreeAlready(data) {
      this.applyNumber.number = data.svc_tel_no
      this.applyNumber.start = this.$moment(data.use_strt_dt).format('YYYY년 MM월 DD일')
      this.applyNumber.end = this.$moment(data.use_end_dt).format('YYYY년 MM월 DD일')
      this.applyNumber.pastPopupVisible = true
    },
    applyFreeSuccess(data) {
      this.applyNumber.number = data.svc_tel_no
      this.applyNumber.start = this.$moment(data.use_strt_dt).format('YYYY년 MM월 DD일')
      this.applyNumber.end = this.$moment(data.use_end_dt).format('YYYY년 MM월 DD일')
      this.applyNumber.successPopupVisible = true
    },
    selectCountry(item) {
      this.selectedCountry = item.selectedObject
    },
    inquire() {
      if (!this.formData.natl_cd) {
        this.$store.commit('ON_ALERT_POPUP', { message: '국가명을 입력하세요.' })
      } else {
        api.ItsvcGetStandardPricePlanInfo(this.formData)
        .then(response => {
          this.result = response.data
          if(this.result.natl_info) {
            this.resultAreaOpen = true
            this.result.natl_info.fomatedDate = util.convDateToSimpleDateFormat(this.result.natl_info.local_time)
          }
        })
      }
    },
    certKcbToken() {
      this.applyNumber.pastPopupVisible = false
      this.applyNumber.successPopupVisible = false
      if (this.token) { // 토큰 있으면
      api.CommonGetReqKcbAuth({ mdl_tkn: this.token })
        .then(response => {
          this.certResult = response.data
          if (this.certResult.result_cd === '0001') {
            this.popup.applyFree.isVisible = true
          }
        })
      } else {  // 토큰 없으면 : 인증 레이어 팝업 띄우기
        this.$store.commit('ON_CERT_POPUP')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.inter_seach {
  overflow: visible;
  div.type01 {
    position: relative;
    overflow: visible;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}
.natl-list{
  position: absolute;
  left: 0;
  top: 57px;
  z-index: 999;
  width: 384px;
  height: 150px;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #ddd;
  background: #eee;
  color: #333;
  padding: 2px;
  margin: 0;
  outline: none;
  li {
    line-height: 28px;
    text-align: left;
    &:hover {
      border: 1px solid #fbd850;
      background: #fff;
      font-weight: bold;
      color: #eb8f00;
    }
    a {
      display: block;
      cursor: pointer;
    }
  }
}

.inter .result_area {
  display: block;
}
</style>
