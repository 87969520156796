<template>
  <div
    v-if="isVisible"
    class="pop_wrap02 pop-full no-fixed"
    style="display: block;"
  >
    <div class="medium auto">
      <h3>공지사항</h3>
      <!--      <div class="tab_type01">-->
      <!--        &lt;!&ndash; 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 &ndash;&gt;-->
      <!--        <ul class="tab_mini">-->
      <!--          <li-->
      <!--            :class="tabIndex == 1 ? 'on': ''"-->
      <!--          >-->
      <!--            <div-->
      <!--              @click="tabActive(1)"-->
      <!--            >-->
      <!--              영업 양도에 따른 계약 및<br>개인정보 이전 안내-->
      <!--            </div>-->
      <!--          </li>-->
      <!--          <li-->
      <!--            :class="tabIndex == 2 ? 'on': ''"-->
      <!--          >-->
      <!--            <div-->
      <!--              @click="tabActive(2)"-->
      <!--            >-->
      <!--              기업서비스 및<br>회원가입 제도 종료 안내-->
      <!--            </div>-->
      <!--          </li>-->
      <!--        </ul>-->
      <!--      </div>-->
      <!--      <div-->
      <!--        v-if="tabIndex == 1"-->
      <!--        class="pop_inner"-->
      <!--      >-->
      <div
        class="pop_inner"
      >
        <!-- to개발 / 탭 on 순서대로 display: block; 처리-->
        <h4 class="title text_align__center">
          SK텔링크 홈페이지 이용제한 안내
        </h4>
        <p class="text__big">
          안녕하세요. SK텔링크입니다.<br><br>
          고객님께 더욱 안정적인 서비스를 제공하고자 아래와 같이 시스템 점검이 진행됩니다.<br>
          점검 작업 시간 내 일시적으로 홈페이지 이용 제한이 발생될 수 있습니다.<br><br>
          <strong>■ 이용 제한 서비스 : 홈페이지 내 조회 및 신청 관련 기능</strong><br>
          - 작업 일시 : <strong>2022년 3월 29일(화) 00:00 ~ 05:00 (5시간)</strong><br>
          - 회사소개 : IR자료실, 채용공고 등<br>
          - 국제전화 서비스 : 추천요금제, 요금제 요율 조회/신청, 요금제 조회, 사용내역 조회 등<br>
          - 고객센터 : 1:1 문의, 공지사항 등 <br><br>
          앞으로도 더나은 서비스를 제공하고자 노력하는 SK텔링크가 되겠습니다.<br><br>
          감사합니다.
        </p>
        <!-- <p class="text__small">
          안녕하세요. SK텔링크입니다.<br><br>
          일시적으로 시스템 일부 기능에 오류가 있어 긴급 점검 중에 있으며, 아래와 같이 서비스 이용에 제한이 발생됩니다.<br>
          이용에 불편을 끼쳐드려 죄송하며, 조속히 시스템 점검 완료하여 정상 운영될 수 있도록 하겠습니다.<br><br>
          ■ 이용 제한 서비스<br>
          - 국제전화 서비스 : 요금제 조회, 사용내역 조회<br>
          - 고객센터 : 1:1 문의<br><br>
          다른 메뉴는 정상적으로 이용 가능합니다.<br>
          앞으로도 더나은 서비스를 제공하고자 노력하는 SK텔링크가 되겠습니다.<br><br>
          감사합니다.
        </p> -->
      </div><!-- end / pop_inner -->

      <div class="not-week">
        <div class="check_box">
          <input
            id="week"
            v-model="agreeClose"
            type="checkbox"
            @change="changeAgreeClose"
          >
          <label for="week"><span>1일간 이 창을 열지 않음</span></label>
        </div>
      </div>
      <a
        class="pop-close"
        @click="$emit('update:is-visible', false)"
      ><img
        src="/content/images/btn_pop_close.png"
        alt="닫기"
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullPopup',
  props: {
    isVisible: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      agreeClose: false,
      tabIndex:'1'
    }
  },
  methods:{
    changeAgreeClose() {
      this.$emit('change', this.agreeClose)
    },
    tabActive(num){
      this.tabIndex = num
    }
  }
}
</script>

<style lang="scss" scoped>
.pop_wrap02 {
  z-index: 99;
  .tab_type01 ul.tab_mini li {
    &.on {
      div {
        color: #fff;
        background-color: #ff630a;
      }
    }
    div {
      height: 60px;
      font-size: 16px;
      line-height: 1.4;
      font-weight: 400;
      letter-spacing: -2px;
      vertical-align: middle;
      cursor: pointer;
      color: #444;
    }
  }
}
</style>
