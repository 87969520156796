
<template>
  <div id="NCSI_NO1">
    <div class="event_wrap type2 type_05">
      <div class="event_view">
        <div>
          <span>
            <img
              alt="국가고객만족도 8년 연속 1위 기념 국제전화 00700 구독형 요금제 매월 300분 무료 또는 상품권 지급"
              src="/content/images/partnership/210419_ncsino1_top.jpg"
              width="640"
              style="height: auto;"
              @load="onLoad"              
            >
          </span>
          <div class="notice event_view_field ncsi_no1">
            <div class="align-center">
              <div class="table_type01 w100">
                <dl>
                  <dt>상품권 택1</dt>
                  <dd>
                    <div class="select_box seb2">
                      <select
                        id="i_sBenefit"
                        v-model="selectBenefit"
                        name="i_sBenefit"
                        title="혜택 선택하기"
                        @change="selectedBenefit"
                      >
                        <option value="혜택을 선택해주세요">
                          혜택을 선택해주세요
                        </option>
                        <option value="COZ22">
                          해피콘
                        </option>
                        <option value="COZ14">
                          신세계상품권
                        </option>
                        <option value="COZ10">
                          SK주유권
                        </option>
                        <option value="COZ05">
                          GS25편의점상품권
                        </option>
                        <option value="COZ18">
                          롯데마트상품권
                        </option>
                      </select>
                    </div>
                  </dd>
                </dl>
                <dl>
                  <dt>SMS 인증</dt>
                  <dd>
                    <div class="phone-box_wrap">
                      <div class="phone-box">
                        <div
                          class="select_box type"
                          style="vertical-align: middle;"
                        >
                          <select
                            id="call_select01"
                            ref="telNumberFirst"
                            v-model="telNumber.first"
                            name="i_sHpNo1"
                            title="무선전화 앞자리 선택"
                          >
                            <option
                              value="010"
                            >
                              010
                            </option>
                            <option value="011">
                              011
                            </option>
                            <option value="016">
                              016
                            </option>
                            <option value="017">
                              017
                            </option>
                            <option value="018">
                              018
                            </option>
                            <option value="019">
                              019
                            </option>
                          </select>
                        </div>
                        <span class="txt_hyphen">-</span>
                        <input
                          ref="telNumberMiddle"
                          v-model="telNumber.middle"
                          name="i_sHpNo2"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                        <span class="txt_hyphen">-</span>
                        <input
                          ref="telNumberLast"
                          v-model="telNumber.last"
                          name="i_sHpNo3"
                          title="전화번호"
                          type="text"
                          maxlength="4"
                          pattern="[0-9]*"
                          class="numbersOnly"
                          inputmode="numeric"
                          oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                        >
                      </div>
                      <a
                        class="btn btn_sendnum"
                        @click="requestSMSCert"
                      >SMS인증하기</a>
                    </div>
                  </dd>
                </dl>
                <dl
                  v-if="authNumberInputVisible"
                  id="numberCheck"
                  class="C030 duplPhono"
                >
                  <dt>인증번호 입력</dt>
                  <dd>
                    <input
                      ref="certNumber"
                      v-model="auth_no"
                      name="i_sCertificationNo"
                      title="인증번호"
                      type="text"
                      maxlength="6"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                    ><a
                      class="btn btn_checknum"
                      @click="requestCert"
                    >인증번호확인</a>
                  </dd>
                </dl>
              </div>

              <div class="check_box mt20">
                <input
                  id="yes"
                  v-model="agreePrivacy"
                  name="yesno"
                  class="myClass YNchk"
                  type="checkbox"
                  value="yes01"
                >
                <label for="yes"><span>SK국제전화 요금제 혜택을 받기 위해 개인정보 수집하는 것에 동의합니다.</span></label>
                <a
                  class="btn_view pop_focus"
                  @click="isVisiblePrivacyPopup = true"
                >자세히 보기</a>
              </div>
              <div class="align-center">
                <div class="btn_center type_02">
                  <a
                    id="btnApply"
                    class="btn_type01 round-type"
                    @click="registrationFinal"
                  >요금제 가입하기</a>
                </div>
              </div>
            </div>
          </div>
          <div class="event_view_field">
            <span>
              <img
                alt="유의사항"
                src="/content/images/partnership/210419_ncsino1_bottom.png"
              >
            </span>
            <div
              style="display:none"
              class="event_view"
            >
              <div class="blind">
                <h6>유의사항</h6>
                <ul>
                  <li>본 요금제 가입을 위해선 개인정보 수집 및 활용, 취급 위탁에 대한 동의가 필요합니다.</li>
                  <li>본 요금제에 대한 가입 혜택은 국제전화 00700 사용에 대해서만 적용됩니다.</li>
                  <li>국제전화 00700 사용 기간은 전월 21일~당월 20일이며, 익월 15일 이내 상품권이 지급됩니다. 따라서 본 이벤트를 통해 요금제 가입 시 고객님이 선택하신 상품권은 6월부터 발송될 예정입니다.</li>
                  <li>SK국제전화에서 판매 중인 타 국제전화 요금제와 중복가입이 불가합니다.</li>
                  <li>불법 스팸, 상업적 용도 등 불법적인 국제전화 이용 시 즉시 서비스 제공이 중단됩니다.</li>
                  <li>월 제공분수 초과 이용 시, 초과분에 대해서는 00700 표준요금이 적용됩니다.</li>
                  <li>국제전화 00700 구독형 요금제(All pass 10,000요금제) 가입 시 이벤트 기간 동안 무료로 요금제 혜택을 받으실 수 있으며, 5월 21일부터는 정상적으로 기본료가 청구됩니다. </li>
                  <li>이벤트 기간 종료 후 요금월(무선주기: 전월 21일~당월 20일) 중도에 해당 요금제를 가입 또는 해지하는 경우 기본료는 월할 계산으로 적용됩니다.</li>
                  <li>화상/위성전화 등 특수대역대 이용시 00700 300분 통화 혜택은 적용되지 않으며, 선불폰 이용은 불가합니다. </li>
                  <li>번호 또는 명의 변경 시, 혜택은 소멸되며 고객센터로 사전 연락 부탁 드립니다.</li>
                  <li>요금제 관련 자세한 문의는 SK국제전화 고객센터로 연락 부탁 드립니다.</li>
                  <li>고객센터 전화번호: 1599-0070</li>
                  <li>운영시간: 평일 오전 9시~오후 6시(주말/공휴일 휴무)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 국제전화 이용에 대한 혜택제공을 위해 아래와 같이 고객정보를 수집하며,<br>고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보의 항목 : 휴대폰번호(연락처)</li>
              <li>개인정보의 수집 및 이용목적 : SK국제전화 요금제에 대한 고객상담 진행</li>
              <li>개인정보의 보유 및 이용기간 : <strong>SK국제전화 요금제 가입 시점부터 해지일자까지</strong></li>
            </ul>
            <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 요금제 혜택 제공에 제한이 될 수 있습니다.</p>
            <p>SK국제전화 요금제 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <Popup
      :is-visible.sync="alertPopup.visible"
      :pop-title="'확인'"
      :c-type="'btn_type02'"
      @click="alertPopupHandler"
    >
      <p v-html="alertPopup.message" />
    </Popup>
    <!-- //팝업 -->
  </div>
</template>

<script>
import api from '@/api'
import Popup from '@/components/popup/Popup'
export default {
  name: 'NCSI',
  components: {
    Popup
  },
 props: {
 },
 emits: [
 ],
 data () {
  return {
    isVisiblePrivacyPopup: false,
    agreePrivacy:false,
    alertPopup: {
      visible: false,
      message: '',
      target: ''
    },
    telNumber:{
      first:'010',
      middle: '',
      last:''
    },
    authNumberInputVisible: false,
    ticket: '',
    event_no:'EV000000000000000001',
    auth_no: '',
    selectBenefit:'혜택을 선택해주세요',
  }
 },
 computed: {
  svc_tel_no() {
    return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
  },
  add_info(){
    return this.selectBenefit
  },
  callbackAlert(){
    return this.$store.getters.CALLBACK_ALERT
  }
 },
 watch: {
  callbackAlert: {
    deep: true,
    handler() {
      if (this.callbackAlert.code === '0002' || this.callbackAlert.code === 'E011' || this.callbackAlert.code === 'E018' || this.callbackAlert.code === 'E0027' || this.callbackAlert.code === 'E028' || this.callbackAlert.code ==='E029' || this.callbackAlert.code === 'E016' || this.callbackAlert.code === 'E017' || this.callbackAlert.code === 'E021' || this.callbackAlert.code === 'E022') {
        this.resetPage()
        window.scrollTo(0, 0)
        document.getElementById('wrap').scrollTop = 0
      }
      if (this.callbackAlert.code === 'E002') {  // 인증5회 오류
        this.authNumberInputVisible = false,
        this.telNumber = {
          first:'010',
          middle: '',
          last:''
        },
        this.auth_no = ''
        this.ticket = ''
      }
      if (this.callbackAlert.code === 'E001') { // 인증시간 초과
        this.auth_no = ''
        this.ticket = ''
      }
    }
  }
},
 created () {},
 mounted () {
    
 },
 methods: {
  selectedBenefit() {
    this.add_info
  },
  alertPopupHandler() {
    this.alertPopup.visible = false
    this.alertPopup.message = ''
    if (this.alertPopup.target) {
      this.$refs[this.alertPopup.target].focus()
    }
    this.alertPopup.target = ''
  },
  showAlertPopup(message) {
    this.alertPopup.message = message
    this.alertPopup.visible = true
  },
  checkPhoneNumber() {
    if (!this.telNumber.first) {
      this.alertPopup.target = 'telNumberFirst'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.middle) {
      this.alertPopup.target = 'telNumberMiddle'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if (!this.telNumber.last) {
      this.alertPopup.target = 'telNumberLast'
      this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
      return false
    } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
      this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
      return false
    } else {
      return true
    }
  },
  checkAuthNumber() {
    if (!this.auth_no) {
      this.alertPopup.target = 'certNumber'
      this.showAlertPopup('인증번호를 입력해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkSMSCert() {
    if(!this.isSmsCert) {
      this.showAlertPopup('SMS인증을 완료해 주세요.')
      return false
    } else {
      return true
    }
  },
  checkselect(){
    if(!this.agreePrivacy){
      this.showAlertPopup('개인정보 수집에 동의하셔야 신청이 가능합니다.')
      return false
    }else if((this.selectBenefit ==='혜택을 선택해주세요')){
      this.showAlertPopup('혜택을 선택해주세요.')
      return false
    }else {
      return true
    }
  },
  onChange() {
    this.selectBenefit = '혜택을 선택해주세요'
  },
  // SMS 인증 요청
  requestSMSCert() {
    if (this.checkPhoneNumber()) {
      api.CommonPostReqSmsAuth({ svc_tel_no: this.svc_tel_no })
      .then(response => {
        if (response.data.sms_ticket) {
          this.showAlertPopup('인증번호가 정상적으로 발송되었습니다.')
          this.ticket = response.data.sms_ticket
          this.authNumberInputVisible = true
        }
      })
    }
  },
  // 인증 번호로 SMS 인증 확인
  requestCert() {
    if (this.checkAuthNumber()) {
      api.CommonGetCheckSmsAuth({ auth_no: this.auth_no, sms_ticket: this.ticket })
      .then(response => {
        if (response.data.result_cd === '0001') {
          this.isSmsCert = true
          this.showAlertPopup('인증번호가 확인되었습니다.<br>감사합니다.')
        }
      })
    }
  },
  //신청완료
  registrationFinal() {
    if (this.checkselect() && this.checkPhoneNumber() && this.checkSMSCert()) {
      api.EventPostReqEvent({
        sms_ticket: this.ticket,
        event_no: this.event_no,
        add_info: this.add_info,
      })
    }
  },
  resetPage(){
    this.agreePrivacy = false,
    this.telNumber= {
      first:'010',
      middle: '',
      last:''
    },
    this.selectBenefit = '혜택을 선택해주세요',
    this.authNumberInputVisible = false,
    this.auth_no = ''
    this.ticket = ''
    this.isSmsCert = false
  },
  //20210517 이벤트 종료
    onLoad() {
      var popitem = document.getElementById("NCSI_NO1");

      if (new Date() >= new Date('05/20/2021 23:59:59')) {   //반영 시간 지정
          this.showAlertPopup('종료된 이벤트입니다.')
          popitem.classList.add("closeEvent");
      }
  },
 }
}
</script>

<style lang="scss" scoped>
.event_wrap.type2.type_05 .select_box select{
  opacity: 1 !important;
  color: #6c6c6c !important;
  font-weight: 400;
}
.ncsi_no1 {
  padding: 0 50px 30px;
  background-color: #fde8ed;
}
//20210517 이벤트 종료
.closeEvent {
  max-height: 100vh;
  overflow: hidden;
}
@media (max-width: 1023px) {
  .ncsi_no1 {
    padding: 0 12px 30px;
  }
  .check_box label span {
    font-size: 13px;
  }
}
</style>
