<template>
  <div id="PRIVACY_GUIDE_MSAFER">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            이용자 피해 예방 가이드
          </h3>
          <p class="txt">
            고객님의 개인정보보호를 위해<br>SK 텔링크에서 제공하는 피해 예방 정보를 확인하세요.
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- to.개발 on 되어있는 li가 가운데로 와야함. -->
        <div class="tab_scroll01 tab7">
          <!-- tab갯수에따른 중복클래스 : 4개일때: 클래스 없음, 5개일때: tab5, 6개일때: tab6, 7개일때: tab7 -->
          <ul>
            <li>
              <router-link to="/view/customer/privacy_guide">
                동영상&amp; 웹툰<br>피해예방교육
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_illegal_TM">
                불법 TM신고
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/customer/privacy_guide_Msafer">
                명의도용방지
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_smishing">
                스미싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_phishing">
                피싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_pharming">
                파밍
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_spam_message">
                스팸메시지
              </router-link>
            </li>
          </ul>
        </div>

        <!-- guide_wrap -->
        <div class="guide_wrap">
          <div class="guide_box01">
            <div class="sub_title">
              <h4 class="title">
                명의도용 방지 서비스(Msafer)란?
              </h4>
              <p class="txt">
                신규로 각종 통신서비스(이동전화, 무선인터넷[WiBro], 유선전화, 초고속인터넷, 인터넷전화[VoIP], 유료방송 등)에<br>
                가입하거나 명의변경을 통해 양도받을 경우 그 사실을
                <span class="txt_point01">본인 명의로 사용하고 있는 이동전화 회선을 통해 SMS로 알려주고, <br>홈페이지를 통해 실시간 개통현황을 확인 할 수 있는 서비스</span> 입니다.
              </p>
            </div>

            <div class="btn_center">
              <a
                href="https://www.msafer.or.kr/index.do"
                target="_blank"
                title="Msafer 홈페이지 새창열림"
                class="btn_type02"
              >Msafer 홈페이지 바로가기</a>
            </div>
          </div>

          <div class="guide_box02 gray">
            <h4 class="title">
              Msafer 서비스 구성도
            </h4>

            <div class="msafer_box">
              <img
                src="/content/images/img_msafer.png"
                alt="Msafer 서비스 구성도 이미지"
              >
              <div class="blind">
                <ul>
                  <li>대리점,인터넷 신규가입</li>
                  <li>유선전화, 이동전화, 무선인터넷, 초고속인터넷, 인터넷전화, 방송서비스 개통사실통보</li>
                  <li>
                    명의도용 방지 서비스(Msafer)
                    <ul>
                      <li>개통사실안내 SMS 발송요청 &gt; 이동통신사업자 &gt; 개통사실 안내SMS &gt; 고객</li>
                      <li>고객</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- //guide_wrap -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl";

export default {
  name: 'PrivacyGuideMsafer',
  metaInfo: {
    title: '명의도용방지 | 이용자 피해 예방가이드 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
