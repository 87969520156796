<template>
  <div id="ADVERTISEMENT_VIDEO">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg06">
          <h3 class="title">
            광고
          </h3>
          <p class="txt">
            미디어속 SK텔링크의<br>광고 이야기를 전해드립니다.
          </p>
          <img
            src="/content/images/sub_header_cp06.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- tab -->
        <div class="tab_type01 tab3">
          <!-- 기본탭 : 메뉴 3개일때 : tab3 메뉴 4개일때 tab4 -->
          <ul>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/media/advertisement_video">
                영상 광고
              </router-link>
            </li>
            <li>
              <router-link to="/view/media/advertisement_audio">
                오디오 광고
              </router-link>
            </li>
            <li>
              <router-link to="/view/media/advertisement_paper">
                지면 광고
              </router-link>
            </li>
          </ul>
        </div>
        <!-- //tab -->

        <!-- company -->
        <div class="company">
          <div class="movie_list">
            <ul>
              <li>
                <div class="movie">
                  <iframe
                    width="100%"
                    height="100%"
                    title="YouTube 동영상 플레이어"
                    src="https://www.youtube.com/embed/oA0xknt8LJo"
                    frameborder="0"
                    allowfullscreen=""
                  /><!-- 예시 영상 -->
                </div>
                <div class="info">
                  <dl>
                    <dt>[SK텔링크]국제전화 <br> 정상훈의 00700 품질 체험기 </dt>
                    <dd>
                      <!-- 이번 이벤트는 4월 28일(금)일부터 5월 3일(수)까지 ‘SK국제전화 00700’ 홈페이지(www.00700.com)를 통해 응모한 유？무선 번호로 참여할 수 있으며, 5월 5일(금)부터 8일(월)까지사전 등록한 번호로 ‘SK국제전화 00700’을 이용한 고객 중 77명을 추첨해 갤럭시S8+ 128G(1명), 신세계상품권 5만원(6명), CGV 주말 영화예매권 2매 (70명)등 다양한 경품을 증정한다.당첨 발표는 5월 10일(수)이며, 당첨자에게 개별적으로 연락할 예정이다. 또한 이벤트 기간 내 다량 사용 고객70명을 선정해 도미노피자 또는  JTN라이브콘서트 티켓 (1인 2매)도 함께... -->
                    </dd>
                  </dl>
                </div>
              </li>
              <li>
                <div class="movie">
                  <iframe
                    width="100%"
                    height="100%"
                    title="YouTube 동영상 플레이어"
                    src="https://www.youtube.com/embed/cvhvKYYBEpA"
                    frameborder="0"
                    allowfullscreen=""
                  /><!-- 예시 영상 -->
                </div>
                <div class="info">
                  <dl>
                    <dt>[SK텔링크]국제전화  00700 <br>정상훈의 00700 품질 체험기 (Full ver.) </dt>
                    <dd>
                      <!-- 이번 이벤트는 4월 28일(금)일부터 5월 3일(수)까지 ‘SK국제전화 00700’ 홈페이지(www.00700.com)를 통해 응모한 유？무선 번호로 참여할 수 있으며, 5월 5일(금)부터 8일(월)까지사전 등록한 번호로 ‘SK국제전화 00700’을 이용한 고객 중 77명을 추첨해 갤럭시S8+ 128G(1명), 신세계상품권 5만원(6명), CGV 주말 영화예매권 2매 (70명)등 다양한 경품을 증정한다.당첨 발표는 5월 10일(수)이며, 당첨자에게 개별적으로 연락할 예정이다. 또한 이벤트 기간 내 다량 사용 고객70명을 선정해 도미노피자 또는  JTN라이브콘서트 티켓 (1인 2매)도 함께... -->
                    </dd>
                  </dl>
                </div>
              </li>
              <li>
                <div class="movie">
                  <iframe
                    width="100%"
                    height="100%"
                    title="YouTube 동영상 플레이어"
                    src="https://www.youtube.com/embed/J62fi05s1Wk"
                    frameborder="0"
                    allowfullscreen=""
                  /><!-- 예시 영상 -->
                </div>
                <div class="info">
                  <dl>
                    <dt>[SK텔링크]국제전화 00700<br>정상훈의 00700 품질 체험기(메이킹필름)</dt>
                    <dd>
                      <!-- 이번 이벤트는 4월 28일(금)일부터 5월 3일(수)까지 ‘SK국제전화 00700’ 홈페이지(www.00700.com)를 통해 응모한 유？무선 번호로 참여할 수 있으며, 5월 5일(금)부터 8일(월)까지사전 등록한 번호로 ‘SK국제전화 00700’을 이용한 고객 중 77명을 추첨해 갤럭시S8+ 128G(1명), 신세계상품권 5만원(6명), CGV 주말 영화예매권 2매 (70명)등 다양한 경품을 증정한다.당첨 발표는 5월 10일(수)이며, 당첨자에게 개별적으로 연락할 예정이다. 또한 이벤트 기간 내 다량 사용 고객70명을 선정해 도미노피자 또는  JTN라이브콘서트 티켓 (1인 2매)도 함께... -->
                    </dd>
                  </dl>
                </div>
              </li>
              <li>
                <div class="movie">
                  <iframe
                    width="100%"
                    height="100%"
                    title="YouTube 동영상 플레이어"
                    src="https://www.youtube.com/embed/3YiSKbpbWGo"
                    frameborder="0"
                    allowfullscreen=""
                  /><!-- 예시 영상 -->
                </div>
                <div class="info">
                  <dl>
                    <dt>[SK텔링크]국제전화 00700<br>용건만 간단히(재혼,이민)편</dt>
                    <dd>
                      <!-- 이번 이벤트는 4월 28일(금)일부터 5월 3일(수)까지 ‘SK국제전화 00700’ 홈페이지(www.00700.com)를 통해 응모한 유？무선 번호로 참여할 수 있으며, 5월 5일(금)부터 8일(월)까지사전 등록한 번호로 ‘SK국제전화 00700’을 이용한 고객 중 77명을 추첨해 갤럭시S8+ 128G(1명), 신세계상품권 5만원(6명), CGV 주말 영화예매권 2매 (70명)등 다양한 경품을 증정한다.당첨 발표는 5월 10일(수)이며, 당첨자에게 개별적으로 연락할 예정이다. 또한 이벤트 기간 내 다량 사용 고객70명을 선정해 도미노피자 또는  JTN라이브콘서트 티켓 (1인 2매)도 함께... -->
                    </dd>
                  </dl>
                </div>
              </li>
              <li>
                <div class="movie">
                  <iframe
                    width="100%"
                    height="100%"
                    title="YouTube 동영상 플레이어"
                    src="https://www.youtube.com/embed/vjzWC6K9qzk"
                    frameborder="0"
                    allowfullscreen=""
                  /><!-- 예시 영상 -->
                </div>
                <div class="info">
                  <dl>
                    <dt>[SK텔링크]국제전화 00700<br>용건만 간단히(회춘,기부)편</dt>
                    <dd>
                      <!-- 이번 이벤트는 4월 28일(금)일부터 5월 3일(수)까지 ‘SK국제전화 00700’ 홈페이지(www.00700.com)를 통해 응모한 유？무선 번호로 참여할 수 있으며, 5월 5일(금)부터 8일(월)까지사전 등록한 번호로 ‘SK국제전화 00700’을 이용한 고객 중 77명을 추첨해 갤럭시S8+ 128G(1명), 신세계상품권 5만원(6명), CGV 주말 영화예매권 2매 (70명)등 다양한 경품을 증정한다.당첨 발표는 5월 10일(수)이며, 당첨자에게 개별적으로 연락할 예정이다. 또한 이벤트 기간 내 다량 사용 고객70명을 선정해 도미노피자 또는  JTN라이브콘서트 티켓 (1인 2매)도 함께... -->
                    </dd>
                  </dl>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl'

export default {
  name: 'AdvertisementVideo',
  metaInfo: {
    title: '영상 광고 | 광고 | 미디어센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
