<template>
  <div id="PRIVACY_GUIDE_PHARMING">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            이용자 피해 예방 가이드
          </h3>
          <p class="txt">
            고객님의 개인정보보호를 위해<br>SK 텔링크에서 제공하는 피해 예방 정보를 확인하세요.
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- to.개발 on 되어있는 li가 가운데로 와야함. -->
        <div class="tab_scroll01 tab7">
          <!-- tab갯수에따른 중복클래스 : 4개일때: 클래스 없음, 5개일때: tab5, 6개일때: tab6, 7개일때: tab7 -->
          <ul>
            <li>
              <router-link to="/view/customer/privacy_guide">
                동영상&amp; 웹툰<br>피해예방교육
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_illegal_TM">
                불법 TM신고
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_Msafer">
                명의도용방지
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_smishing">
                스미싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_phishing">
                피싱
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/customer/privacy_guide_pharming">
                파밍
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_spam_message">
                스팸메시지
              </router-link>
            </li>
          </ul>
        </div>

        <!-- guide_wrap -->
        <div class="guide_wrap">
          <div class="guide_box01">
            <div class="sub_title">
              <h4 class="title">
                파밍(Pharming)이란?
              </h4>
              <p class="txt">
                <span class="txt_point01">악성코드에 감염된 사용자 PC를 조작(Pharming)하여 금융정보를 빼내는 것</span>으로<br>
                사용자 PC가 악성코드에 감염되면 정상 홈페이지에 접속하여도 피싱(가짜)사이트로 유도하여 금융정보 탈취해 범행계좌로 이체하는 수법<br>
                <span class="txt_point01 br"><span class="icon_star">*</span>(피싱사이트) ‘http://*Kb*bank.com’ 등 정상 홈페이지로 가장하여 금융정보(보안카드번호 전부) 입력을 요구, 신종금융사기의 주요 범행수단</span>
              </p>
            </div>

            <div class="guide_list01 list4">
              <ul>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm01.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    1. 사용자PC가<br>악성코드에 감염됨
                  </p>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm02.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    2. 정상 홈페이지에 접속하여도<br>피싱(가짜)사이트로 유도
                  </p>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm03.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    3. 금융정보 탈취
                  </p>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm04.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    4. 범행계좌로 이체
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="sub_title gray">
            <h4 class="title">
              피해유형
            </h4>
            <p class="txt">
              피해유형피해자 184명이 동일한 파밍 수법으로 금융정보가 탈취되고<br>공인인증서가 재발급되어 불시에 13억원이 무단 이체됨
            </p>
          </div>

          <div class="guide_box02">
            <div class="sub_title">
              <h4 class="title">
                피해 예방 방법
              </h4>
              <p class="txt">
                <span class="hashtag"># OTP</span><span class="hashtag"># 보안토큰</span><span class="hashtag"># 출처불명 파일</span>
              </p>
            </div>

            <div class="guide_slide">
              <div class="swiper-wrapper">
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm01.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    <strong>개인정보 보안 강화</strong>
                    <ul>
                      <li>· OTP(일회성 비밀번호생성기),<br> 보안토큰 (비밀정보 복사방지) 사용</li>
                      <li>· 컴퓨터, 이메일 등에 공인인증서, 보안카드 사진,<br> 비밀번호 저장 금지</li>
                    </ul>
                  </div>
                </div>
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm02.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    <strong>PC사용 주의</strong>
                    <ul>
                      <li>· 사이트 주소의 정상 여부 확인</li>
                      <li>· 윈도우, 백신프로그램을 최신 상태로 업데이트</li>
                      <li>· 출처불명 파일이나 이메일은 즉시 삭제<br> 및 무료 다운로드 사이트 이용 자제</li>
                    </ul>
                  </div>
                </div>
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm03.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    <strong>전자금융사기 예방</strong>
                    <ul>
                      <li>· 전자금융사기 예방서비스(공인인증서 PC지정 등)<br> 적극 가입(9. 26 전면 시행 예정)</li>
                      <li>· 보안카드번호 전부를 절대 입력하지 말 것</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="guide_box03">
            <div class="sub_title">
              <h4 class="title">
                대처요령
              </h4>
              <h5 class="title02">
                금전피해가 발생한 경우
              </h5>
              <p class="txt">
                신속히 112센터나 금융기관 콜센터를 통해 지급정지 요청 후 해당 은행에 경찰이 발급한 ‘사건사고 사실확인원’을 제출하여 피해금 환급 신청<br>
                <span class="txt_point01 br"><span class="icon_star">*</span>가짜사이트 신고 : 한국인터넷진흥원 보호나라(<a
                  href="http://www.boho.or.kr"
                  target="_blank"
                >www.boho.or.kr</a>)에 신고</span>
              </p>
            </div>
            <div class="guide_list02">
              <ul>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm05.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    <strong>[지급정지신청]</strong>
                    <ul>
                      <li>1. 피해자가 112 또는 금융기관 콜센터에 전화, 지급정지 신청</li>
                      <li>
                        2. 해당 은행에서 지급정지 조치
                        <span class="txt_point01"><span class="icon_star">*</span>경찰에서 발급한 사건 사고사실 확인원 제출</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li class="child2">
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm06.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    <strong>[명의자 채권소멸]</strong>
                    <em>주관 : 금융감독원</em>
                    <ul>
                      <li>1. 명의자 채권소멸 개시 공고</li>
                      <li>
                        2. 공고 2개월 후 채권소멸
                        <span class="txt_point01"><span class="icon_star">*</span>2개월 경과 전까지 명의인 이의제기 가능</span>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm07.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    <strong>[피해자 환급]</strong>
                    <em>주관 : 금감원은행</em>
                    <ul>
                      <li>1. 금감원은 명의자 채권 소멸 후 14일 내<br> 환급금액 결정하여 금융기관 피해자 통지</li>
                      <li>2. 금융기관은 피해금 지급</li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
            <div class="sub_title">
              <h5 class="title02">
                금전피해가 발생하지 않은 경우
              </h5>
              <p class="txt">
                악성코드 삭제 / 입력했던 금융정보는 해당 은행을 통해 변경 / OTP 사용
              </p>
            </div>
          </div>
        </div>
        <!-- //guide_wrap -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '../../components/Header'
import BreadCrumb from '../../components/BreadCrumb'
import Footer from '../../components/Footer'
import SummitControl from '../../components/SummitControl'

export default {
  name: 'PrivacyGuidePharming',
  metaInfo: {
    title: '파밍 | 이용자 피해 예방가이드 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
