<script>
export default {
  name: 'Meta',
  props: {
    title: {
      type: String,
      required: true,
    }
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = this.title
      }
    }
  },
  created() {
    document.title = process.env.VUE_APP_HTML_TITLE
  }
}
</script>
