<template>
  <div id="PRIVACY_GUIDE_SMISHING">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            이용자 피해  예방 가이드
          </h3>
          <p class="txt">
            고객님의 개인정보보호를 위해<br>SK 텔링크에서 제공하는 피해 예방 정보를 확인하세요.
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- to.개발 on 되어있는 li가 가운데로 와야함. -->
        <div class="tab_scroll01 tab7">
          <!-- tab갯수에따른 중복클래스 : 4개일때: 클래스 없음, 5개일때: tab5, 6개일때: tab6, 7개일때: tab7 -->
          <ul>
            <li>
              <router-link to="/view/customer/privacy_guide">
                동영상&amp; 웹툰<br>피해예방교육
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_illegal_TM">
                불법 TM신고
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_Msafer">
                명의도용방지
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/customer/privacy_guide_smishing">
                스미싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_phishing">
                피싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_pharming">
                파밍
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_spam_message">
                스팸메시지
              </router-link>
            </li>
          </ul>
        </div>

        <!-- guide_wrap -->
        <div class="guide_wrap">
          <div class="guide_box01">
            <div class="sub_title">
              <h4 class="title">
                스미싱(Smishing)이란?
              </h4>
              <p class="txt">
                문자메시지(SMS)와 피싱(Phishing)의 합성어로, ‘무료쿠폰 제공’,’돌잔치 초대장’,’모바일 청첩장’등을 내용으로 하는<br>
                <span class="txt_point01">문자메시지 내 인터넷주소 클릭하면 악성코드가 스마트폰에 설치되어 피해자가 모르는 사이에 소액결제 피해 발생 또는 개인/금융정보 탈취하는 수법</span>
              </p>
            </div>

            <div class="guide_list01 list3 m_list3">
              <!-- m_list3 모바일에서 세로형 리스트로 변경됨 -->
              <ul>
                <li class="child1">
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm12.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    문자메시지내 인터넷주소 클릭
                  </p>
                </li>
                <li class="child2">
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm13.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    악성코드가 스마트폰에 설치
                  </p>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm14.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    소액결제 피해발생<br>또는 개인/금융정보 탈취
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="sub_title gray">
            <h4 class="title">
              피해유형
            </h4>
            <p class="txt">
              스미싱에 이용된 변종 악성코드는 소액결제 인증번호를 가로채는 것에 그치지 않음 최근에는 피해자 스마트폰에 저장된 주소록<br>
              연락처, 사진(주민등록증·보안카드 사본), 공인인증서, 개인정보 등까지 탈취하므로 더 큰 금융범죄로 이어질 수 있습니다.
            </p>
          </div>

          <div class="guide_box02">
            <div class="sub_title">
              <h4 class="title">
                피해 예방 방법
              </h4>
              <p class="txt">
                <span class="hashtag"># 미확인 앱</span><span class="hashtag"># 출처불명</span><span class="hashtag"># 백신 어플</span>
              </p>
            </div>

            <div class="guide_slide">
              <div class="swiper-wrapper">
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm01.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    <strong>개인정보 보안 강화</strong>
                    <ul>
                      <li>· 출처가 확인되지 않은 인터넷주소를 클릭 금지</li>
                      <li>· 보안강화·업데이트 명목으로 금융정보를<br> 요구하는 경우 절대 입력 금지 </li>
                    </ul>
                  </div>
                </div>
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm04.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    <strong>스마트폰 보안 강화</strong>
                    <ul>
                      <li>· 미확인 앱이 함부로 설치되지 않도록 스마트폰 설정</li>
                      <li>· 백신프로그램을 어플 설치하고 주기적으로 업데이트</li>
                      <li>· 공인된 오픈마켓을 통해 앱 설치</li>
                    </ul>
                  </div>
                </div>
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm05.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    <strong>시스템을 활용한 금융정보 보호</strong>
                    <ul>
                      <li>· 이동사 고객센터에 전화하거나<br> 이통사 인터넷 홈페이지를 이용하여<br> 소액결제를 원천적으로 차단하거나 결제금액 제한</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="guide_box03">
            <div class="sub_title">
              <h4 class="title">
                대처요령
              </h4>
              <div class="aling_box">
                <div>
                  <h5 class="title02">
                    금전피해가 발생한 경우
                  </h5>
                  <p class="txt">
                    금융기관 콜센터 전화 / 악성파일 삭제 / 악성코드 삭제
                  </p>
                </div>
                <div>
                  <h5 class="title02">
                    금전피해가 발생하지 않은 경우
                  </h5>
                  <p class="txt">
                    스마트폰에 설치된 악성파일 삭제 / 해당 이통사 제공 예방서비스 이용
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- //guide_wrap -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl";

export default {
  name: 'PrivacyGuideSmishing',
  metaInfo: {
    title: '스미싱 | 이용자 피해 예방가이드 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
