<template>
  <div id="JOB_NOTICE">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header bg04">
          <h3 class="title">
            채용공고
          </h3>
          <p class="txt">
            패기와 열정이 넘치는 당신!<br>SK텔링크에서 당신을 기다립니다.
          </p>
          <img
            src="/content/images/sub_header_cp04.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- company -->
        <div class="company">
          <div class="table_type02">
            <ul class="table_wid06">
              <li
                v-for="(item, index) in jobNoticeList.bulletin_list"
                :key="index"
              >
                <dl class="child1">
                  <dt>No</dt>
                  <dd>
                    {{ item.row_num }}
                    <input
                      type="hidden"
                      name="i_arrInputCd"
                      value="1521"
                    >
                  </dd>
                </dl>
                <dl class="child2">
                  <dt>채용공고</dt>
                  <dd class="txt_left">
                    <router-link
                      :to="{ name: 'job_view', params: { id: item.bulletin_no }, query: { page_num: formData.page_num }}"
                      title="[SK 텔링크] 위성통신 및 유선영업 포지션 경력채용 공고"
                      class="btn_detail"
                    >
                      {{ item.title }}
                    </router-link>
                  </dd>
                </dl>
                <dl class="child3">
                  <dt>유형</dt>
                  <dd v-if="item.bulletin_tp==='CAREER'">
                    {{ item.bulletin_tp.replace('CAREER', '경력') }}
                  </dd>
                  <dd v-if="item.bulletin_tp==='NEW'">
                    {{ item.bulletin_tp.replace('NEW', '신입') }}
                  </dd>
                </dl>
                <dl class="child4">
                  <dt>접수마감</dt>
                  <dd>{{ item.endDate }}</dd>
                </dl>
                <dl class="child5">
                  <dt>상태</dt>
                  <dd v-if="item.state<=0">
                    접수가능
                  </dd>
                  <dd v-if="item.state>0">
                    접수마감
                  </dd>
                </dl>
              </li>
            </ul>
          </div>

          <!-- paging -->
          <Pagination
            v-if="totalCount > 0"
            :total-count="totalCount"
            :page-per-count="formData.page_per_cnt"
            :current-page="formData.page_num"
            @update="updatePage"
            @loadmo="updatePageMobile"
          />
        </div>
        <!-- //company -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Pagination from "@/components/Pagination"
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl.vue'

export default {
  name: 'JobNotice',
  metaInfo: {
    title: '채용공고 목록 | 채용안내 | 회사소개',
  },
  components: {
    Header,
    BreadCrumb,
    Pagination,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      jobNoticeList:{},
      totalCount: 0,
      formData: {
        bulletin_tp: "RECRUIT",
        page_per_cnt: 10,
        page_num: 1,
      },
      currentPage:null,
      endDate: null,
    }
  },
  computed: {
  },
  watch: {},
  created() {
    if(this.$route.query.page_num) {
      this.formData.page_num = parseInt(this.$route.query.page_num, 10)
    }
    api.BulletinGetBullutinList(this.formData)
    .then(response => {
      this.jobNoticeList = response.data
      this.totalCount = response.data.bulletin_total_cnt
      this.jobNoticeList.bulletin_list.forEach(item => {
        item.endDate = this.$moment(item.end_dt).format('YYYY.MM.DD')
        item.state = this.$moment().diff(item.endDate)
      })
    })
  },
  mounted () {
  },
  methods: {
    apiCall(){
      api.BulletinGetBullutinList(this.formData)
      .then(response => {
        this.bullutinList = response.data
        this.totalCount = response.data.bulletin_total_cnt
        this.currentPage = null
      })

    },
    moreList(){
      api.BulletinGetBullutinList(this.formData)
      .then(response => {
        response.data.bulletin_list.forEach(item => {
          this.bullutinList.bulletin_list.push(item)
        })
      })
    },
    updatePage(num) {
      this.$router.replace({ query: {...this.$route.query, page_num: num }}).catch(()=>{})
      this.formData.page_num = num
      this.apiCall()
    },
    updatePageMobile(num) {
      this.formData.page_num = num
      this.moreList()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>
