<template>
  <div id="VSAT">
    <Header />
    <!-- 컨텐츠 -->
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />
      <div id="contents">
        <div class="sub_header bg12">
          <h3 class="title">
            위성통신서비스
          </h3>
          <p class="txt">
            선박, 격오지 등 지상통신이 되지 않는 곳에 Inmarsat, VSAT 등<br>글로벌 위성을 이용하여 통신서비스를 제공합니다.
          </p>
          <img
            src="/content/images/sub_header_biz12-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- biz -->
        <div class="biz tab_area">
          <div class="tab_type01">
            <ul>
              <li

                title="현재메뉴 선택됨"
              >
                <router-link
                  to="/view/business/inmarsat"
                >
                  Inmarsat
                </router-link>
              </li>
              <li class="on">
                <router-link
                  to="/view/business/vsat"
                >
                  VSAT
                </router-link>
              </li>
            </ul>
          </div>

          <!-- tab_cont -->
          <div class="tab_cont">
            <!-- VSAT -->
            <div class="on tab2">
              <span class="blind">VSAT</span>
              <div class="sub_box">
                <h4>육상 통신 인프라가 닿지 않는 선박, 해외플랜트, 항공기에<br><span class="txt_point01">위성망을 이용하여 데이터<br class="m_hide"> 서비스를 제공합니다.</span></h4>
                <p>
                  <span class="txt_point01">SK만의 앞선 우수한 기술력을 통한 안정적인 VSAT(Very Small Aperture Terminal) 서비스</span><br>
                  사용량 제한이 없는 무제한 정액제 서비스를 통해<br class="m_hide"> 경제적인 비용부담을 줄여, 효과적인<br class="m_hide"> 예산관리가 가능합니다.<br>전세계 400여개 기술지원센터 통해 <br class="m_hide"> 글로벌 A/S 서비스를 경험하세요.
                </p>
                <div class="btn_center">
                  <div class="btn double">
                    <a
                      href="tel:1599-00700"
                      class="btn_cs mobile-only"
                    ><span>고객상담 1599-00700</span></a>
                    <span class="btn_cs pc-only">고객상담 1599-00700</span>
                    <!-- <router-link
                      to="/view/customer/online_consult"
                      class="btn_inq"
                    >
                      <span>
                        1:1 문의
                      </span>
                    </router-link> -->
                  </div>
                  <ul>
                    <li>
                      <a
                        href="/content/download/위성통신서비스 식지_v3.pdf"
                        onclick=""
                        class="btn_ico ico2"
                        download
                      ><span>가입신청서</span></a>
                    </li>
                    <li>
                      <a
                        href="/content/download/위성통신서비스 이용약관 전문(부가세표시).pdf"
                        onclick=""
                        class="btn_ico ico3"
                        download
                      ><span>이용약관다운로드</span></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="advice_list gray">
                <h4>이런 기업이 사용하면 좋아요!</h4>
                <p><span class="hashtag"># 선박</span><span class="hashtag"># 상선</span><span class="hashtag"># 어선</span><span class="hashtag">#  크루즈</span><span class="hashtag"># 플랜트 사업장</span><span class="hashtag"># 항공기</span></p>

                <div class="advice_scroll">
                  <div>
                    <ul class="list-scroll-small">
                      <li>
                        <div>
                          <img
                            src="/content/images/biz_phone_vsat_01.png"
                            alt=""
                          >
                        </div>
                        <p>승조원 복지증진, 연료절감솔루션 또는<br class="w_hide">선박ERP를<br class="m_hide"> 도입하고자 하는<br>해상 운항 선박 기업</p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/biz_phone_vsat_02.png"
                            alt=""
                          >
                        </div>
                        <p>국내외 격오지에 플랜트를 구축하거나,<br class="w_hide">극지 취재, 위성 M2M , 방송 중계를<br>하고자 하는 기업</p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/biz_phone_vsat_03.png"
                            alt=""
                          >
                        </div>
                        <p>기내 인터넷을 제공하고자 하는<br class="w_hide"> 항공서비스 제공 기업 또는<br class="w_hide"> 항공기 보유 기업</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="advice_list white">
                <h4>특징을 확인해보세요</h4>
                <div class="advice_scroll">
                  <div>
                    <ul class="list-scroll-small">
                      <li>
                        <div>
                          <img
                            src="/content/images/img_distinct15.png"
                            alt=""
                          >
                        </div>
                        <dl>
                          <dt>서비스 신뢰성 및 안정성</dt>
                          <dd>SK만의 앞선 통신 기술력으로<br class="m_hide"> 이미 검증된<br class="w_hide"> 각 분야별 1위 사업자들과의 협업을 통해<br>안정적인 서비스를 제공합니다. </dd>
                        </dl>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/img_distinct16.png"
                            alt=""
                          >
                        </div>
                        <dl>
                          <dt>기술 플랫폼 확장성</dt>
                          <dd>SK텔레콤, SK C&C의<br class="m_hide"> 차별화된 IoT 및 통신 기반<br class="w_hide"> 각종 <br class="m_hide">솔루션과 ADT캡스 보안 솔루션 등을<br class="m_hide"> 적용하여<br class="w_hide"> 기술의 확장성이<br class="m_hide"> 뛰어납니다.</dd>
                        </dl>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/img_distinct14.png"
                            alt=""
                          >
                        </div>
                        <dl>
                          <dt>고객지원 및 유지보수</dt>
                          <dd>365일 24시간 기술 지원 센터를<br class="m_hide"> 운영하며,<br class="w_hide"> 전세계 400여 개 이상의<br class="m_hide"> 글로벌 A/S망을<br class="w_hide"> 구축하고 있습니다.</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="advice_list">
                <h4>기업의 기대효과는 무엇일까요?</h4>
                <div class="advice_scroll">
                  <div>
                    <ul class="list-scroll-small">
                      <li>
                        <dl>
                          <dt class="txt_point01">
                            선박, 격오지에서<br>육상과의 정보 격차 해소
                          </dt>
                          <dd>육상과의 통신이 불가한 곳에서<br>유일한 통신 수단인<br class="m_hide"> 위성통신서비스로<br class="w_hide"> 실시간으로<br class="m_hide"> 정보 교류가 가능합니다.</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt class="txt_point01">
                            Smartship, IoT를 가능케 하는<br class="w_hide"> ICT 융합 환경 조성
                          </dt>
                          <dd>기존 저속 고비용의 FBB서비스에서는<br>불가능 했던 ERP, 솔루션 등을<br>가능케 함으로써 스마트한<br class="m_hide"> 환경을 구축합니다.</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt class="txt_point01">
                            무제한 정액제로 예산,<br>비용 관리에 효과적
                          </dt>
                          <dd>사용량에 제한이 없는 정액제로<br class="m_hide"> 예산 편성이<br class="w_hide"> 원활하고 효과적인<br class="m_hide"> 관리가 가능합니다.</dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="consist_box">
                <div>
                  <h4>서비스 구성도</h4>
                  <div class="img_box">
                    <span class="w_hide"><img
                      src="/content/images/biz_phone_vsat_07.png"
                      alt="통신위성을 통하여 선박 및 격오지에 음성 및 데이터를 전달합니다."
                    ></span>
                    <span class="m_hide"><img
                      src="/content/images/biz_phone_vsat_07-m.png"
                      alt="통신위성을 통하여 선박 및 격오지에 음성 및 데이터를 전달합니다."
                    ></span>
                  </div>
                </div>
              </div>

              <div class="consist_box">
                <div>
                  <h4>서비스 범위</h4>
                  <div class="img_box">
                    <span><img
                      src="/content/images/biz_phone_vsat_08.png"
                      alt="위성망을 통해 제공하는 Ku대역 해상 전용 위성통신 서비스 입니다."
                    ></span>
                  </div>
                </div>
              </div>

              <div class="pay_wrap">
                <h4>부가서비스</h4>
                <div class="tab_scroll03">
                  <div>
                    <ul>
                      <li
                        :class="tabIndex == 1 ? 'on': ''"
                      >
                        <div
                          @click="tabActive(1)"
                        >
                          해상 선박 CCTV 솔루션
                        </div>
                      </li>
                      <li
                        :class="tabIndex == 2 ? 'on': ''"
                      >
                        <div
                          @click="tabActive(2)"
                        >
                          서비스 구성도
                        </div>
                      </li>
                      <li
                        :class="tabIndex == 3 ? 'on': ''"
                      >
                        <div
                          @click="tabActive(3)"
                        >
                          CCTV솔루션특징
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- 해상 선박 CCTV 솔루션 -->
                <div
                  v-if="tabIndex == 1"
                  class="cont_box"
                >
                  <div class="img_box1">
                    <p>해상안전을 육상에서 PC 뿐만 아니라 모바일에서도 확인이 가능한 선박 특화형 CCTV 솔루션</p>
                    <ul class="vsat_list">
                      <li>
                        <div>
                          <img
                            src="/content/images/icon_biz_vsat01.png"
                            alt=""
                          >
                        </div>
                        <p>영상촬영</p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/icon_biz_vsat02.png"
                            alt=""
                          >
                        </div>
                        <p>영상저장</p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/icon_biz_vsat03.png"
                            alt=""
                          >
                        </div>
                        <p>모니터링</p>
                      </li>
                      <li class="last">
                        <div>
                          <img
                            src="/content/images/icon_biz_vsat04.png"
                            alt=""
                          >
                        </div>
                        <p>선박환경 최적화</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- //해상 선박 CCTV 솔루션 -->
                <!-- 서비스 구성도 -->
                <div
                  v-if="tabIndex == 2"
                  class="cont_box"
                >
                  <div class="img_box">
                    <span class="w_hide"><img
                      src="/content/images/biz_phone_vsat_10.png"
                      alt="네트워크 카메라롤 촬영된 영상을 VSAT위성을 통하여 PC 및 모바일폰으로 제공합니다."
                    ></span>
                    <span class="m_hide"><img
                      src="/content/images/biz_phone_vsat_10-m.png"
                      alt="네트워크 카메라롤 촬영된 영상을 VSAT위성을 통하여 PC 및 모바일폰으로 제공합니다."
                    ></span>
                  </div>
                </div>
                <!-- //서비스 구성도 -->
                <!-- CCTV솔루션특징 -->
                <div
                  v-if="tabIndex == 3"
                  class="cont_box"
                >
                  <div class="img_box1">
                    <ul class="vsat_list02">
                      <li>
                        <div>
                          <img
                            src="/content/images/icon_biz_vsat05.png"
                            alt=""
                          >
                        </div>
                        <p>VSAT 서비스에 최적화된<br>전송 프레임 및 영상 화질 제공</p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/icon_biz_vsat06.png"
                            alt=""
                          >
                        </div>
                        <p> 방수방진에 강하고<br class="m_hide"> 적외선 확인 가능<br>선박 전용 카메라 및<br class="m_hide"> DVR로 구성</p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/icon_biz_vsat07.png"
                            alt=""
                          >
                        </div>
                        <p>선박 전문가들로<br class="m_hide"> 구성된 설치 시공</p>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- //CCTV솔루션특징 -->
              </div>

              <div class="biz_footer">
                <p>지금 보고 계신<br class="m_hide"> 기업상품에 대해<br>더 궁금한 점이 있으시다면<br class="m_hide"> 상담이나 문의를 해보세요</p>
                <div class="btn_center">
                  <div class="btn double">
                    <a
                      href="tel:1599-00700"
                      class="btn_cs mobile-only"
                    ><span>고객상담 1599-00700</span></a>
                    <span class="btn_cs pc-only">고객상담 1599-00700</span>
                    <!-- <router-link
                      to="/view/customer/online_consult"
                      class="btn_inq"
                    >
                      <span>
                        1:1 문의
                      </span>
                    </router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- //VSAT -->
          </div>
          <!-- //tab_cont -->
        </div>
        <!-- //biz -->
      </div>
    </div>
    <!--// 컨텐츠 -->
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'


export default {
  name: 'Vsat',
  metaInfo: {
    title: 'VSAT | 위성통신서비스 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {
      tabIndex:'1'
    }
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
    tabActive(num) {
      this.tabIndex = num
    }
  }
}
</script>

<style lang="scss" scoped>
.biz .pay_wrap {
  .tab_scroll03 ul li {
    &.on div {
      color: #ff630a;
      border-bottom: 1px solid #ff630a;
    }
    div {
      display: inline-block;
      padding-bottom: 3px;
      color: #666;
      font-size: 16px;
      font-weight: 300;
      border-bottom: 1px solid #fff;
      cursor: pointer;
      &:hover {
        color: #ff630a;
        border-bottom: 1px solid #ff630a;
      }
    }
  }
  .cont_box {
    display: block;
  }
}
</style>
