<template>
  <div id="GLOBAL_B">
    <div
      id="boardWEB"
      class="event_wrap type type2 type_05"
    >
      <div
        class="top"
        style="z-index:9998;"
      >
        <div class="global_area">
          <div class="select_box">
            <label
              aria-hidden="true"
              for="language"
            >국가 선택</label>
            <select
              id="language"
              v-model="slectlang"
              name="language"
              title="국가 선택"
              @change="changeRoute"
            >
              <!-- 				<option value="">국가 선택</option>  -->

              <option value="eng">
                English
              </option> <!-- to.개발 / 선택시 영문 페이지로 이동 -->

              <option
                value="kor"
                selected
              >
                한국어
              </option>
            </select>
          </div>
        </div>
      </div>

      <!-- KOR Content -->
      <div
        v-if="slectlang==='kor'"
        class="event_view type_02 mgt40"
      >
        <div>
          <span class="w_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp00700_pc_01.jpg"
              width="1120"
              usemap="#Map"
              style="height: auto;"
            >

            <a
              class="btn_charge"
              @click="isVisibleCountryPopupKor = true"
            ><img
              src="/content/images/btn_charge_pc.jpg"
              alt="국가별 요금확인"
              style="cursor: pointer;"
            ></a>

          </span>
          <span class="m_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp00700_mo_01.jpg"
              width="640"
              usemap="#Map"
              style="height: auto;"
            >

            <a
              class="btn_charge_m"
              @click="isVisibleCountryPopupKor = true"
            ><img
              src="/content/images/btn_charge_mo.jpg"
              alt="국가별 요금확인"
              style="cursor: pointer;"
            ></a>

          </span>
          <input
            name="i_sPostscript"
            type="hidden"
            value=""
          >
          <input
            name="cType"
            type="hidden"
            value="10000"
          >
          <div class="notice event_view_field">
            <div class="table_type01">
              <dl>
                <dt>요금제</dt>
                <dd>
                  <div class="select_box seb2">
                    <select
                      id="i_sComment"
                      v-model="selectRatePlan"
                      name="i_sComment"
                      title="요금제 선택하기"
                      @change="categoryChange"
                    >
                      <option value="요금제 선택">
                        요금제 선택
                      </option>
                      <option value="COGP3">
                        00700 Global Plus 300
                      </option>
                      <option value="COGP5">
                        00700 Global Plus 500
                      </option>
                      <option value="GCSVM">
                        국내통화요금제
                      </option>
                    </select>
                  </div>
                </dd>
              </dl>

              <dl>
                <dt>전화번호</dt>
                <dd>
                  <div>
                    <div class="C030 duplPhono">
                      <!-- 무선 전화번호 타입 -->
                      <div
                        class="select_box"
                        style="vertical-align: middle;"
                      >
                        <select
                          ref="telNumberFirst"
                          v-model="telNumber.first"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="">
                            선택
                          </option>
                          <option
                            value="010"
                            selected
                          >
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                          <option value="02">
                            02
                          </option>
                          <option value="031">
                            031
                          </option>
                          <option value="032">
                            032
                          </option>
                          <option value="033">
                            033
                          </option>
                          <option value="041">
                            041
                          </option>
                          <option value="042">
                            042
                          </option>
                          <option value="043">
                            043
                          </option>
                          <option value="044">
                            044
                          </option>
                          <option value="051">
                            051
                          </option>
                          <option value="052">
                            052
                          </option>
                          <option value="053">
                            053
                          </option>
                          <option value="054">
                            054
                          </option>
                          <option value="055">
                            055
                          </option>
                          <option value="061">
                            061
                          </option>
                          <option value="062">
                            062
                          </option>
                          <option value="063">
                            063
                          </option>
                          <option value="064">
                            064
                          </option>
                          <option value="070">
                            070
                          </option>
                        </select>
                      </div>
                      <span class="txt_hyphen">-</span>
                      <input
                        ref="telNumberMiddle"
                        v-model="telNumber.middle"
                        title="휴대폰번호"
                        type="text"
                        maxlength="4"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                      <span class="txt_hyphen">-</span>
                      <input
                        ref="telNumberLast"
                        v-model="telNumber.last"
                        title="휴대폰번호"
                        type="text"
                        maxlength="4"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                  </div>
                </dd>
              </dl>

              <dl>
                <dt>보안문자</dt>
                <dd class="security">
                  <Captchar
                    ref="captchar"
                    :width="135"
                    :height="42"
                    @result="updateCaptcharState"
                  />
                  <!-- <div class="captcha">
                    <img
                      id="formCaptchaImage"
                      src="/content/images/captcha.png"
                    >
                  </div>
                  <a
                    href="javascript:void(0)"
                    onclick="fnPreventRefresh($('#formCaptchaImage'));"
                    class="btn_refresh"
                  ><img
                    src="/content/images/btn_refresh.png"
                    alt="새로고침"
                  ></a>
                  <input
                    id="formCaptchaText"
                    type="text"
                    placeholder="보안문자를 입력하세요."
                    title="보안문자를 입력하세요."
                    class="wid225 captchaText"
                    name="formCaptchaText"
                    maxlength="6"
                  >
                </dd> -->
                </dd>
              </dl>
            </div>
            <div class="align-center">
              <div class="check_box global">
                <input
                  id="yes"
                  ref="agreePrivacy"
                  v-model="agreePrivacy"
                  name="yesno"
                  class="myClass YNchk"
                  type="checkbox"
                  value="yes01"
                >
                <label for="yes"><span style="font-size: 14px;">SK국제전화 요금제 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</span></label>
              </div>
              <div>
                <a
                  class="btn_view"
                  @click="isVisiblePrivacyPopup = true"
                >개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 신청이 가능합니다.</a>
              </div>
            </div>
          </div>
        </div>
        <div class="event_view_field">
          <div class="btn_center type_02">
            <a
              id="btnApply"
              class="btn_type01 round-type"
              @click="registrationFinal"
            >요금제 신청하기</a>
          </div>

          <span class="w_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp00700_pc_02.jpg"
            >
          </span>
          <span class="m_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp00700_mo_02.jpg"
            >
          </span>

          <div
            style="display:none"
            class="event_view"
          >
            <div class="blind">
              <h6>이벤트 참여시 유의사항</h6>
              <ul>
                <li>본 이벤트에 참여하기 위해서는 개인정보 수집, 활용 및 취급위탁에 대한 동의가 필요합니다.</li>
                <li>본 이벤트 경품은 사정에 따라 별도 공지 없이 상품이 변경/대체될 수 있습니다.</li>
                <li>본 이벤트는 기프티콘 발송을 위해 휴대전화(핸드폰)만 참여가 가능합니다.</li>
                <li>이벤트 응모, 경품 기프티콘 발송을 위해 휴대전화번호를 정확히 입력하셔야 합니다.</li>
                <li>
                  본 이벤트는 위성/화상전화, 국제SMS이용 내역과 마이플랜 요금제, 더길게요금제, SKT00700프리
                  부가서비스 등 정액제 요금제 가입자는 참여가 제한됩니다.
                </li>
                <li>비정상적이거나 불법적인 방법으로 이벤트에 참여하신 경우 혜택 대상에서 제외되실 수 있습니다.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--// KOR Content -->

      <!-- ENG Content -->
      <div
        v-if="slectlang==='eng'"
        class="event_view type_02 mgt40"
      >
        <div>
          <span class="w_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp00700_pc_01_en.jpg"
              width="1120"
              usemap="#Map"
              style="height: auto;"
            >

            <a
              class="btn_charge"
              @click="isVisibleCountryPopupEng = true"
            ><img
              src="/content/images/btn_charge_pc_en.jpg"
              alt="국가별 요금확인"
              style="cursor: pointer;"
            ></a>

          </span>
          <span class="m_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp00700_mo_01_en.jpg"
              width="640"
              usemap="#Map"
              style="height: auto;"
            >

            <a
              class="btn_charge_m"
              @click="isVisibleCountryPopupEng = true"
            ><img
              src="/content/images/btn_charge_mo_en.jpg"
              alt="국가별 요금확인"
              style="cursor: pointer;"
            ></a>

          </span>
          <input
            name="i_sPostscript"
            type="hidden"
            value=""
          >
          <input
            name="cType"
            type="hidden"
            value="10000"
          >
          <div class="notice event_view_field">
            <div class="table_type01">
              <dl>
                <dt>Call Plan</dt>
                <dd>
                  <div class="select_box seb2">
                    <select
                      id="i_sComment"
                      ref="selectRatePlan"
                      v-model="selectRatePlan"
                      name="i_sComment"
                      title="요금제 선택하기"
                      @change="categoryChange"
                    >
                      <option value="요금제 선택">
                        Choose your Call Plan
                      </option>
                      <option value="COGP3">
                        00700 Global Plus 300
                      </option>
                      <option value="COGP5">
                        00700 Global Plus 500
                      </option>
                      <option value="GCSVM">
                        Domestic Call Plan
                      </option>
                    </select>
                  </div>
                </dd>
              </dl>

              <dl>
                <dt>Telephone Number(Mobile/Fixed)</dt>
                <dd>
                  <div>
                    <div class="C030 duplPhono">
                      <!-- 무선 전화번호 타입 -->
                      <div
                        class="select_box"
                        style="vertical-align: middle;"
                      >
                        <select
                          ref="telNumberFirst"
                          v-model="telNumber.first"
                          title="무선전화 앞자리 선택"
                        >
                          <option value="선택">
                            선택
                          </option>
                          <option
                            value="010"
                            selected
                          >
                            010
                          </option>
                          <option value="011">
                            011
                          </option>
                          <option value="016">
                            016
                          </option>
                          <option value="017">
                            017
                          </option>
                          <option value="018">
                            018
                          </option>
                          <option value="019">
                            019
                          </option>
                          <option value="02">
                            02
                          </option>
                          <option value="031">
                            031
                          </option>
                          <option value="032">
                            032
                          </option>
                          <option value="033">
                            033
                          </option>
                          <option value="041">
                            041
                          </option>
                          <option value="042">
                            042
                          </option>
                          <option value="043">
                            043
                          </option>
                          <option value="044">
                            044
                          </option>
                          <option value="051">
                            051
                          </option>
                          <option value="052">
                            052
                          </option>
                          <option value="053">
                            053
                          </option>
                          <option value="054">
                            054
                          </option>
                          <option value="055">
                            055
                          </option>
                          <option value="061">
                            061
                          </option>
                          <option value="062">
                            062
                          </option>
                          <option value="063">
                            063
                          </option>
                          <option value="064">
                            064
                          </option>
                          <option value="070">
                            070
                          </option>
                        </select>
                      </div>
                      <span class="txt_hyphen">-</span>
                      <input
                        ref="telNumberMiddle"
                        v-model="telNumber.middle"
                        title="휴대폰번호"
                        type="text"
                        maxlength="4"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                      <span class="txt_hyphen">-</span>
                      <input
                        ref="telNumberLast"
                        v-model="telNumber.last"
                        title="휴대폰번호"
                        type="text"
                        maxlength="4"
                        oninput="javascript: this.value = this.value.replace(/[^0-9]/g, '');"
                      >
                    </div>
                  </div>
                </dd>
              </dl>

              <dl>
                <dt>Security Code</dt>
                <dd class="security">
                  <Captchar
                    ref="captchar"
                    :width="135"
                    :height="42"
                    :placeholder="'Key in Security Code'"
                    @result="updateCaptcharState"
                  />
                  <!-- <div class="captcha">
                    <img
                      id="formCaptchaImage"
                      src="/content/images/captcha.png"
                    >
                  </div>
                  <a
                    href="javascript:void(0)"
                    onclick="fnPreventRefresh($('#formCaptchaImage'));"
                    class="btn_refresh"
                  ><img
                    src="/content/images/btn_refresh.png"
                    alt="새로고침"
                  ></a>

                  <input
                    id="formCaptchaText"
                    type="text"
                    placeholder="Key in Security Code"
                    title="Key in Security Code"
                    class="wid225 captchaText"
                    name="formCaptchaText"
                    maxlength="6"
                  > -->
                </dd>
              </dl>
            </div>

            <div class="align-center">
              <div class="check_box global">
                <input
                  id="yes"
                  v-model="agreePrivacy"
                  name="yesno"
                  class="myClass YNchk"
                  type="checkbox"
                  value="yes01"
                >
                <label for="yes"><span style="font-size: 14px;">Agree personal information provided to third parties/Agree to receive advertising information</span></label>
              </div>
              <div>
                <a
                  class="btn_view"
                  @click="isVisiblePrivacyPopup = true"
                >View guide for Agree personal information provided to third parties /Agree to receive advertising information</a>
              </div>
            </div>
          </div>
        </div>
        <div class="event_view_field">
          <div class="btn_center type_02">
            <a
              id="btnApply"
              class="btn_type01 round-type"
              @click="registrationFinal"
            >Apply for a Call Plan</a>
          </div>

          <span class="w_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp00700_pc_02_en.jpg"
            >
          </span>
          <span class="m_hide">
            <img
              alt="Wow 요금제"
              src="/content/images/gp00700_mo_02_en.jpg"
            >
          </span>

          <div
            style="display:none"
            class="event_view"
          >
            <div class="blind">
              <h6>이벤트 참여시 유의사항</h6>
              <ul>
                <li>본 이벤트에 참여하기 위해서는 개인정보 수집, 활용 및 취급위탁에 대한 동의가 필요합니다.</li>
                <li>본 이벤트 경품은 사정에 따라 별도 공지 없이 상품이 변경/대체될 수 있습니다.</li>
                <li>본 이벤트는 기프티콘 발송을 위해 휴대전화(핸드폰)만 참여가 가능합니다.</li>
                <li>이벤트 응모, 경품 기프티콘 발송을 위해 휴대전화번호를 정확히 입력하셔야 합니다.</li>
                <li>
                  본 이벤트는 위성/화상전화, 국제SMS이용 내역과 마이플랜 요금제, 더길게요금제, SKT00700프리
                  부가서비스 등 정액제 요금제 가입자는 참여가 제한됩니다.
                </li>
                <li>비정상적이거나 불법적인 방법으로 이벤트에 참여하신 경우 혜택 대상에서 제외되실 수 있습니다.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <!--// ENG Content -->
    </div>

    <!-- 공통 개인정보 수집 동의안내 팝업 -->
    <div
      v-if="isVisiblePrivacyPopup"
      class="pop_wrap02 w757"
      style="display:block"
    >
      <div class="medium ko">
        <h3>개인정보 수집 동의 안내</h3>
        <div class="pop_cont02">
          <h4 class="title">
            개인정보 수집 동의 안내
          </h4>

          <div class="cont_term">
            <p>
              SK텔링크 주식회사는 국제전화 이용에 대한 혜택제공을 위해 아래와 같이 고객정보를 수집하며, <br>고객정보 취급에 관한 상세한 사항은 <a
                class="txt_point01"
                href="http://www.sktelink.com"
              >www.sktelink.com</a>에 게시된 개인정보처리방침을 참조하시기 바랍니다.
            </p>
            <ul class="txt_point01">
              <li>수집하는 개인정보의 항목 : 휴대폰번호(연락처)</li>
              <li>개인정보의 수집 및 이용목적 : SK국제전화 요금제에 대한 고객상담 진행</li>
              <li>개인정보의 보유 및 이용기간 : <strong>SK국제전화 요금제 가입 시점부터 해지일자까지</strong></li>
            </ul>
            <p>개인정보 수집에 대한 동의/거부할 권리가 있으나, 거부 시 요금제 혜택 제공에 제한이 될 수 있습니다.</p>
            <p>SK국제전화 요금제 혜택을 받기 위해 위와 같이 본인의 개인정보 수집하는 것에 동의합니다.</p>
          </div>
        </div>
        <div class="btn_center">
          <a
            class="btn_type01"
            @click="isVisiblePrivacyPopup = false"
          >확인</a>
        </div>
        <a @click="isVisiblePrivacyPopup = false"><img
          alt="닫기"
          src="/content/images/btn_pop_close.png"
        ></a>
      </div>
    </div>
    <!--// 공통 개인정보 수집 동의안내 팝업 -->

    <!-- KOR Popup -->
    <div
      v-if="isVisibleCountryPopupKor"
      class="pop_wrap02 w757 h-100"
      style="display:block"
    >
      <!-- to개발 / 2021-02-25 퍼블수정 | 클래스 추가-->
      <div class="medium">
        <div
          id="in_l"
          class="company"
        >
          <div class="fs_table">
            <div class="table_title_in_l">
              <h4>국내통화요금제</h4>
              <span class="fr txt_point">단위 : 원/분, 부가가치세 포함</span>
            </div>
            <div class="table_type04">
              <table>
                <caption>국내통화요금제</caption>
                <colgroup>
                  <col style="width:33.3%;">
                  <col style="width:33.3%;">
                  <col style="width:33.3%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      대역
                    </th>
                    <th scope="col">
                      지역
                    </th>
                    <th scope="col">
                      요금
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr> <td>1</td> <td>일본</td> <td>280.5</td> </tr>
                  <tr> <td>2</td> <td>중국</td> <td>118.8</td> </tr>
                  <tr> <td>3</td> <td>베트남</td> <td>607.2</td> </tr>
                  <tr> <td>4</td> <td>미얀마</td> <td>805.2</td> </tr>
                  <tr> <td>5</td> <td>홍콩</td> <td>118.8</td> </tr>
                  <tr> <td>6</td> <td>싱가폴</td> <td>118.8</td> </tr>
                  <tr> <td>7</td> <td>대만</td> <td>541.2</td> </tr>
                  <tr> <td>8</td> <td>인도네시아</td> <td>541.2</td> </tr>
                  <tr> <td>9</td> <td>필리핀</td> <td>448.8</td> </tr>
                  <tr> <td>10</td> <td>말레이지아</td> <td>541.2</td> </tr>
                  <tr> <td>11</td> <td>태국</td> <td>118.8</td> </tr>
                  <tr> <td>12</td> <td>몽골</td> <td>805.2</td> </tr>
                  <tr> <td>13</td> <td>라오스 등 4개국</td> <td>825</td> </tr>
                  <tr> <td>14</td> <td>미국(본토)</td> <td>118.8</td> </tr>
                  <tr> <td>15</td> <td>캐나다</td> <td>118.8</td> </tr>
                  <tr> <td>16</td> <td>알래스카, 하와이</td> <td>118.8</td> </tr>
                  <tr> <td>17</td> <td>북미, 대양주 23국</td> <td>792</td> </tr>
                  <tr> <td>18</td> <td>호주</td> <td>352</td> </tr>
                  <tr> <td>19</td> <td>뉴질랜드</td> <td>357.5</td> </tr>
                  <tr> <td>20</td> <td>괌, 사이판</td> <td>357.5</td> </tr>
                  <tr> <td>21</td> <td>영국</td> <td>335.5</td> </tr>
                  <tr> <td>22</td> <td>프랑스</td> <td>335.5</td> </tr>
                  <tr> <td>23</td> <td>독일</td> <td>335.5</td> </tr>
                  <tr> <td>24</td> <td>이탈리아</td> <td>335.5</td> </tr>
                  <tr> <td>25</td> <td>네델란드 등 4개국</td> <td>528</td> </tr>
                  <tr> <td>26</td> <td>그리스 등 6개국</td> <td>653.4</td> </tr>
                  <tr> <td>27</td> <td>벨기에 등 5개국</td> <td>653.4</td> </tr>
                  <tr> <td>28</td> <td>룩셈부르크 등 5개국</td> <td>653.4</td> </tr>
                  <tr> <td>29</td> <td>리히텐슈타인 등 6개국</td> <td>653.4</td> </tr>
                  <tr> <td>30</td> <td>불가리아 등 3개국</td> <td>904.2</td> </tr>
                  <tr> <td>31</td> <td>슬로바키아 등 4개국</td> <td>904.2</td> </tr>
                  <tr> <td>32</td> <td>그루지아 등 10개국</td> <td>904.2</td> </tr>
                  <tr> <td>33</td> <td>루마니아 등 10개국</td> <td>904.2</td> </tr>
                  <tr> <td>34</td> <td>러시아</td> <td>851.4</td> </tr>
                  <tr> <td>35</td> <td>파키스탄</td> <td>877.8</td> </tr>
                  <tr> <td>36</td> <td>방글라데시</td> <td>877.8</td> </tr>
                  <tr> <td>37</td> <td>인도</td> <td>349.8</td> </tr>
                  <tr> <td>38</td> <td>네팔 등 4개국</td> <td>1,188.00</td> </tr>
                  <tr> <td>39</td> <td>이란</td> <td>930.6</td> </tr>
                  <tr> <td>40</td> <td>사우디아라비아</td> <td>877.8</td> </tr>
                  <tr> <td>41</td> <td>아랍에미레이트</td> <td>877.8</td> </tr>
                  <tr> <td>42</td> <td>레바논 등 11개국</td> <td>1,009.80</td> </tr>
                  <tr> <td>43</td> <td>브라질</td> <td>884.4</td> </tr>
                  <tr> <td>44</td> <td>아르헨티나</td> <td>884.4</td> </tr>
                  <tr> <td>45</td> <td>멕시코</td> <td>884.4</td> </tr>
                  <tr> <td>46</td> <td>중남미 15개국</td> <td>996.6</td> </tr>
                  <tr> <td>47</td> <td>중남미 28개국</td> <td>996.6</td> </tr>
                  <tr> <td>48</td> <td>아프리카 12개국</td> <td>1,056.00</td> </tr>
                  <tr> <td>49</td> <td>아프리카 24개국</td> <td>1,056.00</td> </tr>
                  <tr> <td>50</td> <td>아프리카 25개국</td> <td>1,056.00</td> </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="fs_table">
            <div class="table_title_in_l">
              <h4 class="sc">
                대역표
              </h4>
            </div>
            <div class="table_type04">
              <table>
                <caption>대역표</caption>
                <colgroup>
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      대역
                    </th>
                    <th scope="col">
                      지역
                    </th>
                    <th scope="col">
                      국가 및<br class="m_br">지역 수
                    </th>
                    <th scope="col">
                      국가 및 <br class="m_br">지역명
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td> <td rowspan="13">
                      아시아
                    </td> <td>1</td> <td>일본</td>
                  </tr>
                  <tr> <td>2</td> <td>1</td> <td>중국</td> </tr>
                  <tr> <td>3</td> <td>1</td> <td>베트남</td> </tr>
                  <tr> <td>4</td> <td>1</td> <td>미얀마</td> </tr>
                  <tr> <td>5</td> <td>1</td> <td>홍콩</td> </tr>
                  <tr> <td>6</td> <td>1</td> <td>싱가폴</td> </tr>
                  <tr> <td>7</td> <td>1</td> <td>대만</td> </tr>
                  <tr> <td>8</td> <td>1</td> <td>인도네시아</td> </tr>
                  <tr> <td>9</td> <td>1</td> <td>필리핀</td> </tr>
                  <tr> <td>10</td> <td>1</td> <td>말레이지아</td> </tr>
                  <tr> <td>11</td> <td>1</td> <td>태국</td> </tr>
                  <tr> <td>12</td> <td>1</td> <td>몽골</td> </tr>
                  <tr> <td>13</td> <td>4</td> <td>라오스, 마카오, 부르나이, 캄보디아</td> </tr>
                  <tr>
                    <td>14</td> <td rowspan="3">
                      북미
                    </td> <td>1</td> <td>미국(본토)</td>
                  </tr>
                  <tr> <td>15</td> <td>1</td> <td>캐나다</td> </tr>
                  <tr> <td>16</td> <td>2</td> <td>알래스카, 하와이</td> </tr>
                  <tr>
                    <td>17</td> <td rowspan="4">
                      오세아니아/대양주
                    </td> <td>23</td> <td>나우르, 노르폭섬, 뉴칼래도니아, 니우에섬, 동티모르, 마셀아일랜드, 마이크로네시아, 바누아투, 사모아(미국령), 서사모아, 세인트피에르, 솔로몬제도, 안타티카, 왈리스엔포튜나섬, 쿡아일랜드, 크리스마스섬, 키리바티, 타이티, 토켈라우, 통가, 투바루, 파퓨아뉴기니아, 팔라우, 피지</td>
                  </tr>
                  <tr> <td>18</td> <td>1</td> <td>호주</td> </tr>
                  <tr> <td>19</td> <td>1</td> <td>뉴질랜드</td> </tr>
                  <tr> <td>20</td> <td>2</td> <td>괌, 사이판</td> </tr>
                  <tr>
                    <td>21</td> <td rowspan="14">
                      유럽
                    </td> <td>1</td> <td>영국</td>
                  </tr>
                  <tr> <td>22</td> <td>1</td> <td>프랑스</td> </tr>
                  <tr> <td>23</td> <td>1</td> <td>독일</td> </tr>
                  <tr> <td>24</td> <td>1</td> <td>이탈리아</td> </tr>
                  <tr> <td>25</td> <td>4</td> <td>네델란드, 스웨덴, 스위스, 스페인</td> </tr>
                  <tr> <td>26</td> <td>7</td> <td>그리스, 노르웨이, 덴마크, 마데이라, 사이프러스, 오스트리아, 포르투갈</td> </tr>
                  <tr> <td>27</td> <td>5</td> <td>그린란드, 벨기에, 아이슬랜드, 아일랜드, 페로이 제도</td> </tr>
                  <tr> <td>28</td> <td>5</td> <td>룩셈부르크, 모나코, 바티칸시티, 산마리노, 핀란드</td> </tr>
                  <tr> <td>29</td> <td>6</td> <td>리히텐슈타인, 말타, 발레아릭, 안도라, 지브랄타, 터키</td> </tr>
                  <tr> <td>30</td> <td>3</td> <td>불가리아, 체코, 헝가리</td> </tr>
                  <tr> <td>31</td> <td>4</td> <td>슬로바키아, 에스토니아, 카자흐스탄, 폴란드</td> </tr>
                  <tr> <td>32</td> <td>10</td> <td>그루지아, 라트비아, 리투아니아, 보스니아, 세르비아, 세우타, 슬로베니아, 알바니아, 우즈베키스탄, 크로아티아, 코소보</td> </tr>
                  <tr> <td>33</td> <td>10</td> <td>루마니아, 마케도니아, 몰도바, 벨라루스, 아르메니아, 아제르바이젠, 우크라이나, 키리기즈스탄, 타지키스탄, 터크메니스탄</td> </tr>
                  <tr> <td>34</td> <td>1</td> <td>러시아</td> </tr>
                  <tr>
                    <td>35</td> <td rowspan="4">
                      서남아
                    </td> <td>1</td> <td>파키스탄</td>
                  </tr>
                  <tr> <td>36</td> <td>1</td> <td>방글라데시</td> </tr>
                  <tr> <td>37</td> <td>1</td> <td>인도</td> </tr>
                  <tr> <td>38</td> <td>4</td> <td>네팔, 몰디브, 스리랑카, 부탄</td> </tr>
                  <tr>
                    <td>39</td> <td rowspan="4">
                      중동
                    </td> <td>1</td> <td>이란</td>
                  </tr>
                  <tr> <td>40</td> <td>1</td> <td>사우디아라비아</td> </tr>
                  <tr> <td>41</td> <td>1</td> <td>아랍에미레이트</td> </tr>
                  <tr> <td>42</td> <td>11</td> <td>레바논, 바레인, 시리아, 아프가니스탄, 예멘, 오만, 요르단, 이라크, 이스라엘, 카타르, 쿠웨이트, 팔레스타인</td> </tr>
                  <tr>
                    <td>43</td> <td rowspan="5">
                      중‧남미
                    </td> <td>1</td> <td>브라질</td>
                  </tr>
                  <tr> <td>44</td> <td>1</td> <td>아르헨티나</td> </tr>
                  <tr> <td>45</td> <td>1</td> <td>멕시코</td> </tr>
                  <tr> <td>46</td> <td>15</td> <td>구아델로프, 네덜란드령안틸레스, 마트니크, 버뮤다, 버진섬(미국), 버진섬(영국), 볼리비아, 아루바, 엘살바도르, 우루구아이, 칠레, 케이만섬, 코스타리카, 콜롬비아, 하이티, 푸에토리코</td> </tr>
                  <tr> <td>47</td> <td>28</td> <td>가이아나, 구아테말라, 그레나다, 니카라구아, 도미니카, 몬세라트, 바베이도스, 바하마, 베네주엘라, 벨리제, 부룬디, 세인트루시아, 세인트킷츠, 세인트빈센트, 수리남, 안귈라, 안티구아, 에쿠아도르, 온두라스, 자마이카, 쿠바, 터크스엔카이코스섬, 트리나다드토바코, 파나마, 파라구아이, 팔크랜드섬, 페루, 프랑스령가이아나</td> </tr>
                  <tr>
                    <td>48</td> <td rowspan="3">
                      아프리카
                    </td> <td>12</td> <td>가나, 가봉, 남아프리카공화국, 레위니옹섬, 리비아, 말라위, 보츠와나, 스와질랜드, 알제리아, 앙골라, 중앙아프리카, 짐바브웨</td>
                  </tr>
                  <tr> <td>49</td> <td>23</td> <td>감비아, 기니아, 기니아비소, 나미비아, 니제르, 라이베리아, 레소토, 르완다, 모로코, 모리타니아, 모리티어스, 부르키나파소, 세네갈, 세이칠레스, 수단, 남수단, 아리보리코스트, 자이레, 잔지바, 잠비아, 카메룬, 카프버데섬, 콩고, 튜니지아</td> </tr>
                  <tr> <td>50</td> <td>25</td> <td>나이지리아, 디에고가르시아, 마다카스카르, 말리, 메이요트, 모잠비크, 베넹, 사오톰프린시페, 서사하라, 세이트헬레나, 소말리아, 시에라레온, 에리트리아, 에센션섬, 에집트, 에티오피아, 우간다, 적도기니아, 지부티, 차드공화국, 카나리제도, 케냐, 코모로스, 탄자니아, 토고</td> </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <a @click="isVisibleCountryPopupKor = false"><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <!--// KOR Popup -->

    <!-- ENG Popup -->
    <div
      v-if="isVisibleCountryPopupEng"
      class="pop_wrap02 w757 h-100"
      style="display:block"
    >
      <!-- to개발 / 2021-02-25 퍼블수정 | 클래스 추가-->
      <div class="medium">
        <div
          id="in_l"
          class="company"
        >
          <div class="fs_table">
            <div class="table_title_in_l">
              <h4>Domestic Call Plan</h4>
              <span class="fr txt_point">(Unit : Won/Min., V.A.T Included)</span>
            </div>
            <div class="table_type04">
              <table>
                <caption>Domestic Call Plan</caption>
                <colgroup>
                  <col style="width:33.3%;">
                  <col style="width:33.3%;">
                  <col style="width:33.3%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      Zone
                    </th>
                    <th scope="col">
                      Country
                    </th>
                    <th scope="col">
                      Fee
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr> <td>1</td> <td>JAPAN</td> <td>280.5</td> </tr>
                  <tr> <td>2</td> <td>CHINA</td> <td>118.8</td> </tr>
                  <tr> <td>3</td> <td>VIETNAM</td> <td>607.2</td> </tr>
                  <tr> <td>4</td> <td>MYANMAR</td> <td>805.2</td> </tr>
                  <tr> <td>5</td> <td>HONG KONG</td> <td>118.8</td> </tr>
                  <tr> <td>6</td> <td>SINGAPORE</td> <td>118.8</td> </tr>
                  <tr> <td>7</td> <td>TAIWAN</td> <td>541.2</td> </tr>
                  <tr> <td>8</td> <td>INDONESIA</td> <td>541.2</td> </tr>
                  <tr> <td>9</td> <td>PHILIPPINES</td> <td>448.8</td> </tr>
                  <tr> <td>10</td> <td>MALAYSIA</td> <td>541.2</td> </tr>
                  <tr> <td>11</td> <td>THAILAND</td> <td>118.8</td> </tr>
                  <tr> <td>12</td> <td>MONGOLIAN</td> <td>805.2</td> </tr>
                  <tr> <td>13</td> <td>LAOS etc.</td> <td>825</td> </tr>
                  <tr> <td>14</td> <td>UNITED STATES</td> <td>118.8</td> </tr>
                  <tr> <td>15</td> <td>CANADA</td> <td>118.8</td> </tr>
                  <tr> <td>16</td> <td>ALASKA, HAWAII</td> <td>118.8</td> </tr>
                  <tr> <td>17</td> <td>AMERICAN SAMOA etc.</td> <td>792</td> </tr>
                  <tr> <td>18</td> <td>AUSTRALIA</td> <td>352</td> </tr>
                  <tr> <td>19</td> <td>NEW ZEALAND</td> <td>357.5</td> </tr>
                  <tr> <td>20</td> <td>GUAM, SAIPAN</td> <td>357.5</td> </tr>
                  <tr> <td>21</td> <td>UNITED KINGDOM</td> <td>335.5</td> </tr>
                  <tr> <td>22</td> <td>FRANCE</td> <td>335.5</td> </tr>
                  <tr> <td>23</td> <td>GERMANY</td> <td>335.5</td> </tr>
                  <tr> <td>24</td> <td>ITALY</td> <td>335.5</td> </tr>
                  <tr> <td>25</td> <td>NETHERLANDS etc.</td> <td>528</td> </tr>
                  <tr> <td>26</td> <td>Europe 1</td> <td>653.4</td> </tr>
                  <tr> <td>27</td> <td>Europe 2</td> <td>653.4</td> </tr>
                  <tr> <td>28</td> <td>Europe 3</td> <td>653.4</td> </tr>
                  <tr> <td>29</td> <td>Europe 4</td> <td>653.4</td> </tr>
                  <tr> <td>30</td> <td>Europe 5</td> <td>904.2</td> </tr>
                  <tr> <td>31</td> <td>Europe 6</td> <td>904.2</td> </tr>
                  <tr> <td>32</td> <td>Europe 7</td> <td>904.2</td> </tr>
                  <tr> <td>33</td> <td>Europe 8</td> <td>904.2</td> </tr>
                  <tr> <td>34</td> <td>RUSSIA</td> <td>851.4</td> </tr>
                  <tr> <td>35</td> <td>PAKISTAN</td> <td>877.8</td> </tr>
                  <tr> <td>36</td> <td>BANGLADESH</td> <td>877.8</td> </tr>
                  <tr> <td>37</td> <td>INDIA</td> <td>349.8</td> </tr>
                  <tr> <td>38</td> <td>NEPAL etc.</td> <td>1,188.00</td> </tr>
                  <tr> <td>39</td> <td>IRAN</td> <td>930.6</td> </tr>
                  <tr> <td>40</td> <td>SAUDI ARABIA</td> <td>877.8</td> </tr>
                  <tr> <td>41</td> <td>UNITED ARAB EMIRATES</td> <td>877.8</td> </tr>
                  <tr> <td>42</td> <td>Middle East</td> <td>1,009.80</td> </tr>
                  <tr> <td>43</td> <td>BRAZIL</td> <td>884.4</td> </tr>
                  <tr> <td>44</td> <td>ARGENTINA</td> <td>884.4</td> </tr>
                  <tr> <td>45</td> <td>MEXICO</td> <td>884.4</td> </tr>
                  <tr> <td>46</td> <td>Central South America 1</td> <td>996.6</td> </tr>
                  <tr> <td>47</td> <td>Central South America 2</td> <td>996.6</td> </tr>
                  <tr> <td>48</td> <td>Africa 1</td> <td>1,056.00</td> </tr>
                  <tr> <td>49</td> <td>Africa 2</td> <td>1,056.00</td> </tr>
                  <tr> <td>50</td> <td>Africa 3</td> <td>1,056.00</td> </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="fs_table">
            <div class="table_title_in_l">
              <h4 class="sc">
                Zone table
              </h4>
            </div>
            <div class="table_type04">
              <table>
                <caption>Zone table</caption>
                <colgroup>
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                  <col style="width:25%;">
                </colgroup>
                <thead>
                  <tr>
                    <th scope="col">
                      Zone
                    </th>
                    <th scope="col">
                      Area
                    </th>
                    <th scope="col">
                      국가 및<br class="m_br">지역 수
                    </th>
                    <th scope="col">
                      Country
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1</td> <td rowspan="13">
                      Asia
                    </td> <td>1</td> <td>JAPAN</td>
                  </tr>
                  <tr> <td>2</td> <td>1</td> <td>CHINA</td> </tr>
                  <tr> <td>3</td> <td>1</td> <td>VIETNAM</td> </tr>
                  <tr> <td>4</td> <td>1</td> <td>MYANMAR</td> </tr>
                  <tr> <td>5</td> <td>1</td> <td>HONG KONG</td> </tr>
                  <tr> <td>6</td> <td>1</td> <td>SINGAPORE</td> </tr>
                  <tr> <td>7</td> <td>1</td> <td>TAIWAN</td> </tr>
                  <tr> <td>8</td> <td>1</td> <td>INDONESIA</td> </tr>
                  <tr> <td>9</td> <td>1</td> <td>PHILIPPINES</td> </tr>
                  <tr> <td>10</td> <td>1</td> <td>MALAYSIA</td> </tr>
                  <tr> <td>11</td> <td>1</td> <td>THAILAND</td> </tr>
                  <tr> <td>12</td> <td>1</td> <td>MONGOLIAN</td> </tr>
                  <tr> <td>13</td> <td>4</td> <td>BRUNEI, CAMBODIA, LAOS, MACAU</td> </tr>
                  <tr>
                    <td>14</td> <td rowspan="3">
                      North America
                    </td> <td>1</td> <td>UNITED STATES</td>
                  </tr>
                  <tr> <td>15</td> <td>1</td> <td>CANADA</td> </tr>
                  <tr> <td>16</td> <td>2</td> <td>ALASKA, HAWAII</td> </tr>
                  <tr>
                    <td>17</td> <td rowspan="4">
                      Oceania
                    </td> <td>23</td> <td>AMERICAN SAMOA, ANTARTICA, CHRISTMAS IS., COOK ISLANDS, EAST TIMOR, FIJI ISLANDS, KIRIBATI, MARSHALL ISLAND, MICRONESIA, NAURU, NEW CALEDONIA, NIUE, NORFOLK IS., PALAU, PAPUA NEW GUINEA, SOLOMON ISLANDS, ST. PIERRE, TAHITI, TOKELAU, TONGA ISLANDS, TUVALU, VANUATU, WALLIS & FORTUNA ISLANDS, WESTERN SAMOA</td>
                  </tr>
                  <tr> <td>18</td> <td>1</td> <td>AUSTRALIA</td> </tr>
                  <tr> <td>19</td> <td>1</td> <td>NEW ZEALAND</td> </tr>
                  <tr> <td>20</td> <td>2</td> <td>GUAM, SAIPAN</td> </tr>
                  <tr>
                    <td>21</td> <td rowspan="14">
                      Europe
                    </td> <td>1</td> <td>UNITED KINGDOM</td>
                  </tr>
                  <tr> <td>22</td> <td>1</td> <td>FRANCE</td> </tr>
                  <tr> <td>23</td> <td>1</td> <td>GERMANY</td> </tr>
                  <tr> <td>24</td> <td>1</td> <td>ITALY</td> </tr>
                  <tr> <td>25</td> <td>4</td> <td>NETHERLANDS, SPAIN, SWEDEN, SWITZERLAND</td> </tr>
                  <tr> <td>26</td> <td>7</td> <td>AUSTRIA, CYPRUS, DENMARK, GREECE, MADEIRA, NORWAY, PORTUGAL</td> </tr>
                  <tr> <td>27</td> <td>5</td> <td>BELGIUM, FAEROE ISLANDS, GREENLAND, ICELAND, IRELAND</td> </tr>
                  <tr> <td>28</td> <td>5</td> <td>FINLAND, LUXEMBOURG, MONACO, SAN MARINO, VATICAN CITY</td> </tr>
                  <tr> <td>29</td> <td>6</td> <td>ANDORRA, BALEARIC, GIBRALTAR, LIECHTENSTEIN, MALTA, TURKEY</td> </tr>
                  <tr> <td>30</td> <td>3</td> <td>BULGARIA, CZECH REPUBLIC, HUNGARY</td> </tr>
                  <tr> <td>31</td> <td>4</td> <td>ESTONIA, KAZAKHSTAN, POLAND, SLOVAKIA</td> </tr>
                  <tr> <td>32</td> <td>10</td> <td>ALBANIA, BOSNIA-HERCEGOVINA, CROATIA, GEORGIA, LATVIA, LITHUANIA, SERBIA, SLOVENIA, UZBEKISTAN</td> </tr>
                  <tr> <td>33</td> <td>10</td> <td>ARMENIA, AZERBAIJAN, BELARUS, KYRGYZSTAN, MACEDONIA, MOLDOVA, ROMANIA, TAJISKISTAN, TURKMENISTAN, UKRAINE</td> </tr>
                  <tr> <td>34</td> <td>1</td> <td>RUSSIA</td> </tr>
                  <tr>
                    <td>35</td> <td rowspan="4">
                      West South Asia
                    </td> <td>1</td> <td>PAKISTAN</td>
                  </tr>
                  <tr> <td>36</td> <td>1</td> <td>BANGLADESH</td> </tr>
                  <tr> <td>37</td> <td>1</td> <td>INDIA</td> </tr>
                  <tr> <td>38</td> <td>4</td> <td>BHUTAN, MALDIVES, NEPAL, SRI LANKA</td> </tr>
                  <tr>
                    <td>39</td> <td rowspan="4">
                      Middle East
                    </td> <td>1</td> <td>IRAN</td>
                  </tr>
                  <tr> <td>40</td> <td>1</td> <td>SAUDI ARABIA</td> </tr>
                  <tr> <td>41</td> <td>1</td> <td>UNITED ARAB EMIRATES</td> </tr>
                  <tr> <td>42</td> <td>11</td> <td>AFGHANISTAN, BAHRAIN, IRAQ, ISRAEL, JORDAN, KUWAIT, LEBANON, OMAN, PALESTINE, QATAR, SYRIA, YEMEN</td> </tr>
                  <tr>
                    <td>43</td> <td rowspan="5">
                      Central South America
                    </td> <td>1</td> <td>BRAZIL</td>
                  </tr>
                  <tr> <td>44</td> <td>1</td> <td>ARGENTINA</td> </tr>
                  <tr> <td>45</td> <td>1</td> <td>MEXICO</td> </tr>
                  <tr> <td>46</td> <td>15</td> <td>AMERICAN VIRGIN IS., ARUBA, BERMUDA, BOLIVIA,BRITISH VIRGIN IS., CAYMAN ISLANDS, CHILE, COLOMBIA, COSTA RICA, EL SALVADOR, GUADELOUPE, HAITI, MARTINIQUE, NETHERLANDS ANTILLES, URUGUAY</td> </tr>
                  <tr> <td>47</td> <td>28</td> <td>ANGUILLA, ANTIGUA, BAHAMAS, BARBADOS, BELIZE, CUBA, DJIBOUTI, DOMINICA, ECUADOR, FALKLAND ISLANDS, FRENCH GUIANA, GRENADA, GUATEMALA, GUYANA, HONDURAS,  JAMAICA, MONTSERRAT, NICARAGUA, PANAMA, PARAGUAY, PERU, ST. KITTS, ST. LUCIA, ST. VINCENT, SURINAME, TRINIDAD & TOBAGO, TURKS & CAICOS ISLANDS, VENEZUELA</td> </tr>
                  <tr>
                    <td>48</td> <td rowspan="3">
                      Africa
                    </td> <td>12</td> <td>ALGERIA, ANGOLA, BOTSWANA, BURUNDI, CENTRAL AFRICAN REPUBLIC, GABON REPUBLIC, GHANA, LIBYAN A P S J, MALAWI, REUNION ISLAND, SOUTH AFRICA, SWAZILAND, ZIMBABWE</td>
                  </tr>
                  <tr> <td>49</td> <td>23</td> <td>BURKINA FASO, CAMEROON, CAPE VERDE ISLANDS, CONGO, GAMBIA, GUINEA, GUINEA BISSAU, IVORY COAST, LESOTHO, LIBERIA, MAURITANIA, MAURITIUS, MOROCCO, NAMIBIA, NIGER REPUBLIC, RWANDA, SENEGAL, SEYCHELLES ISLAND, SOUTH SUDAN, SUDAN, TUNISIA, ZAIRE, ZAMBIA, ZANZIBAR</td> </tr>
                  <tr> <td>50</td> <td>25</td> <td>ASCENSION ISLAND, BENIN, CANARY IS., CHAD, COMOROS, DIEGO GARCIA, DJIBOUTI, EGYPT, EQUATORIAL GUINEA, ERITREA, ETHIOPIA, KENYA, MADAGASCAR, MALI REPUBLIC, MAYOTTE ISLAND, NIGERIA, SAO TOME, SIERRA LEONE, SOMALIA, ST. HELENA, TANZANIA, TOGO, UGANDA, WEST SAHARA</td> </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <a @click="isVisibleCountryPopupEng = false"><img
          src="/content/images/btn_pop_close.png"
          alt="닫기"
        ></a>
      </div>
    </div>
    <!--// ENG Popup -->

    <div
      v-if="alertPopup.visible"
      class="pop_wrap m-size"
      style="display: block;"
    >
      <div class="alert">
        <h3> INFO</h3>
        <div class="popup-inner">
          <p v-html="alertPopup.message" />
          <div class="btn_center">
            <a
              v-if="slectlang === 'kor'"
              class="btn_type02"
              @click="alertPopupHandler"
            >확인</a>
            <a
              v-if="slectlang === 'eng'"
              class="btn_type02"
              @click="alertPopupHandler"
            >Confirm</a>
          </div>
        </div>
        <a
          href="javascript:;"
          class="pop-close"
          @click="alertPopupHandler"
        >
          <img
            src="/content/images/btn_pop_close.png"
            alt="닫기"
          >
        </a>
      </div>
    </div>

    <div
      v-if="registerPopup.visible"
      class="pop_wrap m-size"
      style="display: block;"
    >
      <div class="alert">
        <h3> INFO</h3>
        <div class="popup-inner">
          <p v-html="registerPopup.message" />
          <div
            v-if="slectlang === 'kor'"
            class="btn_center double"
          >
            <a
              class="btn_type02"
              @click="registrationConfirm"
            >확인</a>
            <a
              class="btn_type02"
              @click="alertPopupHandler"
            >취소</a>
          </div>
          <div
            v-if="slectlang === 'eng'"
            class="btn_center double"
          >
            <a
              class="btn_type02"
              @click="registrationConfirm"
            >Confirm</a>
            <a
              class="btn_type02"
              @click="alertPopupHandler"
            >Cancel</a>
          </div>
        </div>
        <a
          href="javascript:;"
          class="pop-close"
          @click="alertPopupHandler"
        >
          <img
            src="/content/images/btn_pop_close.png"
            alt="닫기"
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import Captchar from '@/components/Captchar'
export default {
  name: 'GlobalB',
  components: {
    Captchar,
  },
  data(){
    return{
      isPassCaptchar: false,
      isVisiblePrivacyPopup:false,
      isVisibleCountryPopupKor:false,
      isVisibleCountryPopupEng:false,
      slectlang: this.$route.query.lang,
      alertPopup: {
        visible: false,
        message: '',
        target: ''
      },
      registerPopup: {
        visible: false,
        message: '',
        target: ''
      },
      telNumber: {
        first:'010',
        middle:'',
        last:''
      },
      selectRatePlan:'요금제 선택',
      agreePrivacy:false,
      event_no:'ZZ000000000000005701',
    }
  },
  computed: {
    oper_br_cd(){
      if(this.slectlang == 'kor'){
        return 'D15259'
      }else{
        return 'D15258'
      }
    },
    tel_no() {
      return `${this.telNumber.first}${this.telNumber.middle}${this.telNumber.last}`
    },
    add_info(){
      return this.selectRatePlan
    },
    callbackAlert(){
      return this.$store.getters.CALLBACK_ALERT
    }
  },
  watch: {
    callbackAlert: {
      deep: true,
      handler() {
        if (this.callbackAlert.type === 'alert') { // 신청완료
          this.resetPage()
          window.scrollTo(0, 0)
          document.getElementById('wrap').scrollTop = 0
        }
      }
    }
  },
  created () {

  },
  mounted () {
  },
  methods: {
    updateCaptcharState(state) {
      this.isPassCaptchar = state
    },
    changeRoute() {
      this.$router.push({ name: 'global_b', query: { lang: this.slectlang}})
      location.reload()
    },
    alertPopupHandler() {
      if(this.alertPopup){
        this.alertPopup.visible = false
        this.alertPopup.message = ''
        if (this.alertPopup.target) {
          this.$refs[this.alertPopup.target].focus()
        }
        this.alertPopup.target = ''
      }
      if(this.registerPopup){
        this.registerPopup.visible = false
        this.registerPopup.message = ''
      }
      return true
    },
    showAlertPopup(message) {
      this.alertPopup.message = message
      this.alertPopup.visible = true
    },
    showRegisterPopup(message){
      this.registerPopup.message = message
      this.registerPopup.visible = true
    },
    checkselect(){
      if(!this.agreePrivacy){
        if(this.slectlang === 'kor'){
          this.showAlertPopup('개인정보활용 수집 및 취급 위탁 동의에 체크하셔야 신청이 가능합니다.')
        }else{
          this.showAlertPopup('You can apply if you check the consignment agreement to use and handle personal information.')
        }
      }else if(this.selectRatePlan === '요금제 선택' ){
        if(this.slectlang === 'kor'){
          this.showAlertPopup('요금제를 선택해주세요.')
        }else {
          this.showAlertPopup('Choose your Call Plan.')
        }
      }else {
        return true
      }
    },
    checkPhoneNumber() {
      if (!this.telNumber.first) {
        this.alertPopup.target = 'telNumberFirst'
        if(this.slectlang === 'kor'){
          this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        }else{
          this.showAlertPopup('Enter your phone number.')
        }
      } else if (!this.telNumber.middle) {
        this.alertPopup.target = 'telNumberMiddle'
        if(this.slectlang === 'kor'){
          this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        }else{
          this.showAlertPopup('Enter your phone number.')
        }
      } else if (!this.telNumber.last) {
        this.alertPopup.target = 'telNumberLast'
        if(this.slectlang === 'kor'){
          this.showAlertPopup('휴대폰 번호를 입력해 주세요.')
        }else{
          this.showAlertPopup('Enter your phone number.')
        }
      } else if(this.telNumber.middle.length < 3 || this.telNumber.last.length < 4) {
        if(this.slectlang === 'kor'){
          this.showAlertPopup('휴대폰 번호가 유효하지 않습니다.')
        }else{
          this.showAlertPopup('Please enter your cell phone number correctly.')
        }
        return false
      } else {
        return true
      }
    },
    checkCaptchar(){
      if(this.isPassCaptchar === false){
        if(this.slectlang === 'kor'){
          this.showAlertPopup('보안문자를 바르게 입력해 주세요.')
        }else{
          this.showAlertPopup('Please enter the security code correctly.')
        }
      } else {
        return true
      }
    },
    categoryChange(){
      this.add_info
    },
    registrationFinal(){
      if (this.checkselect() && this.checkPhoneNumber() && this.checkCaptchar()) {
        if(this.slectlang === 'kor'){
          this.showRegisterPopup('신청하시겠습니까?')
        }else{
          this.showRegisterPopup('Would you like to apply?')
        }
      }
      return true
    },
    registrationConfirm(){
      this.registerPopup.visible = false
      api.EventPostReqEvent({
        tel_no: this.tel_no,
        event_no: this.event_no,
        add_info: this.add_info,
        oper_br_cd: this.oper_br_cd,
      })
    },
    resetPage(){
      this.isVisiblePrivacyPopup = false,
      this.agreePrivacy = false,
      this.selectRatePlan = '요금제 선택',
      this.telNumber = {
        first:'010',
        middle:'',
        last:''
      },
      this.isPassCaptchar = false,
      this.$refs.captchar.resetInput(),
      this.$refs.captchar.generate()
    }
  }
}
</script>

<style lang="scss" scoped>
.event_wrap.type2.type_05 .global_area .select_box select{
  z-index: 1;
}
.event_wrap.type2.type_05 .notice .select_box select{
  opacity: 1 !important;
  font-weight: 400;
}
</style>

