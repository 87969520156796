var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"VSAT"}},[_c('Header'),_c('div',{staticClass:"contents",attrs:{"id":"container"}},[_c('BreadCrumb'),_c('div',{attrs:{"id":"contents"}},[_vm._m(0),_c('div',{staticClass:"biz tab_area"},[_c('div',{staticClass:"tab_type01"},[_c('ul',[_c('li',{attrs:{"title":"현재메뉴 선택됨"}},[_c('router-link',{attrs:{"to":"/view/business/inmarsat"}},[_vm._v(" Inmarsat ")])],1),_c('li',{staticClass:"on"},[_c('router-link',{attrs:{"to":"/view/business/vsat"}},[_vm._v(" VSAT ")])],1)])]),_c('div',{staticClass:"tab_cont"},[_c('div',{staticClass:"on tab2"},[_c('span',{staticClass:"blind"},[_vm._v("VSAT")]),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"pay_wrap"},[_c('h4',[_vm._v("부가서비스")]),_c('div',{staticClass:"tab_scroll03"},[_c('div',[_c('ul',[_c('li',{class:_vm.tabIndex == 1 ? 'on': ''},[_c('div',{on:{"click":function($event){return _vm.tabActive(1)}}},[_vm._v(" 해상 선박 CCTV 솔루션 ")])]),_c('li',{class:_vm.tabIndex == 2 ? 'on': ''},[_c('div',{on:{"click":function($event){return _vm.tabActive(2)}}},[_vm._v(" 서비스 구성도 ")])]),_c('li',{class:_vm.tabIndex == 3 ? 'on': ''},[_c('div',{on:{"click":function($event){return _vm.tabActive(3)}}},[_vm._v(" CCTV솔루션특징 ")])])])])]),(_vm.tabIndex == 1)?_c('div',{staticClass:"cont_box"},[_vm._m(7)]):_vm._e(),(_vm.tabIndex == 2)?_c('div',{staticClass:"cont_box"},[_vm._m(8)]):_vm._e(),(_vm.tabIndex == 3)?_c('div',{staticClass:"cont_box"},[_vm._m(9)]):_vm._e()]),_vm._m(10)])])])])],1),_c('Footer'),_c('SummitControl')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_header bg12"},[_c('h3',{staticClass:"title"},[_vm._v(" 위성통신서비스 ")]),_c('p',{staticClass:"txt"},[_vm._v(" 선박, 격오지 등 지상통신이 되지 않는 곳에 Inmarsat, VSAT 등"),_c('br'),_vm._v("글로벌 위성을 이용하여 통신서비스를 제공합니다. ")]),_c('img',{attrs:{"src":"/content/images/sub_header_biz12-m.jpg","alt":"비쥬얼 이미지"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub_box"},[_c('h4',[_vm._v("육상 통신 인프라가 닿지 않는 선박, 해외플랜트, 항공기에"),_c('br'),_c('span',{staticClass:"txt_point01"},[_vm._v("위성망을 이용하여 데이터"),_c('br',{staticClass:"m_hide"}),_vm._v(" 서비스를 제공합니다.")])]),_c('p',[_c('span',{staticClass:"txt_point01"},[_vm._v("SK만의 앞선 우수한 기술력을 통한 안정적인 VSAT(Very Small Aperture Terminal) 서비스")]),_c('br'),_vm._v(" 사용량 제한이 없는 무제한 정액제 서비스를 통해"),_c('br',{staticClass:"m_hide"}),_vm._v(" 경제적인 비용부담을 줄여, 효과적인"),_c('br',{staticClass:"m_hide"}),_vm._v(" 예산관리가 가능합니다."),_c('br'),_vm._v("전세계 400여개 기술지원센터 통해 "),_c('br',{staticClass:"m_hide"}),_vm._v(" 글로벌 A/S 서비스를 경험하세요. ")]),_c('div',{staticClass:"btn_center"},[_c('div',{staticClass:"btn double"},[_c('a',{staticClass:"btn_cs mobile-only",attrs:{"href":"tel:1599-00700"}},[_c('span',[_vm._v("고객상담 1599-00700")])]),_c('span',{staticClass:"btn_cs pc-only"},[_vm._v("고객상담 1599-00700")])]),_c('ul',[_c('li',[_c('a',{staticClass:"btn_ico ico2",attrs:{"href":"/content/download/위성통신서비스 식지_v3.pdf","onclick":"","download":""}},[_c('span',[_vm._v("가입신청서")])])]),_c('li',[_c('a',{staticClass:"btn_ico ico3",attrs:{"href":"/content/download/위성통신서비스 이용약관 전문(부가세표시).pdf","onclick":"","download":""}},[_c('span',[_vm._v("이용약관다운로드")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advice_list gray"},[_c('h4',[_vm._v("이런 기업이 사용하면 좋아요!")]),_c('p',[_c('span',{staticClass:"hashtag"},[_vm._v("# 선박")]),_c('span',{staticClass:"hashtag"},[_vm._v("# 상선")]),_c('span',{staticClass:"hashtag"},[_vm._v("# 어선")]),_c('span',{staticClass:"hashtag"},[_vm._v("# 크루즈")]),_c('span',{staticClass:"hashtag"},[_vm._v("# 플랜트 사업장")]),_c('span',{staticClass:"hashtag"},[_vm._v("# 항공기")])]),_c('div',{staticClass:"advice_scroll"},[_c('div',[_c('ul',{staticClass:"list-scroll-small"},[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/biz_phone_vsat_01.png","alt":""}})]),_c('p',[_vm._v("승조원 복지증진, 연료절감솔루션 또는"),_c('br',{staticClass:"w_hide"}),_vm._v("선박ERP를"),_c('br',{staticClass:"m_hide"}),_vm._v(" 도입하고자 하는"),_c('br'),_vm._v("해상 운항 선박 기업")])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/biz_phone_vsat_02.png","alt":""}})]),_c('p',[_vm._v("국내외 격오지에 플랜트를 구축하거나,"),_c('br',{staticClass:"w_hide"}),_vm._v("극지 취재, 위성 M2M , 방송 중계를"),_c('br'),_vm._v("하고자 하는 기업")])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/biz_phone_vsat_03.png","alt":""}})]),_c('p',[_vm._v("기내 인터넷을 제공하고자 하는"),_c('br',{staticClass:"w_hide"}),_vm._v(" 항공서비스 제공 기업 또는"),_c('br',{staticClass:"w_hide"}),_vm._v(" 항공기 보유 기업")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advice_list white"},[_c('h4',[_vm._v("특징을 확인해보세요")]),_c('div',{staticClass:"advice_scroll"},[_c('div',[_c('ul',{staticClass:"list-scroll-small"},[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_distinct15.png","alt":""}})]),_c('dl',[_c('dt',[_vm._v("서비스 신뢰성 및 안정성")]),_c('dd',[_vm._v("SK만의 앞선 통신 기술력으로"),_c('br',{staticClass:"m_hide"}),_vm._v(" 이미 검증된"),_c('br',{staticClass:"w_hide"}),_vm._v(" 각 분야별 1위 사업자들과의 협업을 통해"),_c('br'),_vm._v("안정적인 서비스를 제공합니다. ")])])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_distinct16.png","alt":""}})]),_c('dl',[_c('dt',[_vm._v("기술 플랫폼 확장성")]),_c('dd',[_vm._v("SK텔레콤, SK C&C의"),_c('br',{staticClass:"m_hide"}),_vm._v(" 차별화된 IoT 및 통신 기반"),_c('br',{staticClass:"w_hide"}),_vm._v(" 각종 "),_c('br',{staticClass:"m_hide"}),_vm._v("솔루션과 ADT캡스 보안 솔루션 등을"),_c('br',{staticClass:"m_hide"}),_vm._v(" 적용하여"),_c('br',{staticClass:"w_hide"}),_vm._v(" 기술의 확장성이"),_c('br',{staticClass:"m_hide"}),_vm._v(" 뛰어납니다.")])])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/img_distinct14.png","alt":""}})]),_c('dl',[_c('dt',[_vm._v("고객지원 및 유지보수")]),_c('dd',[_vm._v("365일 24시간 기술 지원 센터를"),_c('br',{staticClass:"m_hide"}),_vm._v(" 운영하며,"),_c('br',{staticClass:"w_hide"}),_vm._v(" 전세계 400여 개 이상의"),_c('br',{staticClass:"m_hide"}),_vm._v(" 글로벌 A/S망을"),_c('br',{staticClass:"w_hide"}),_vm._v(" 구축하고 있습니다.")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"advice_list"},[_c('h4',[_vm._v("기업의 기대효과는 무엇일까요?")]),_c('div',{staticClass:"advice_scroll"},[_c('div',[_c('ul',{staticClass:"list-scroll-small"},[_c('li',[_c('dl',[_c('dt',{staticClass:"txt_point01"},[_vm._v(" 선박, 격오지에서"),_c('br'),_vm._v("육상과의 정보 격차 해소 ")]),_c('dd',[_vm._v("육상과의 통신이 불가한 곳에서"),_c('br'),_vm._v("유일한 통신 수단인"),_c('br',{staticClass:"m_hide"}),_vm._v(" 위성통신서비스로"),_c('br',{staticClass:"w_hide"}),_vm._v(" 실시간으로"),_c('br',{staticClass:"m_hide"}),_vm._v(" 정보 교류가 가능합니다.")])])]),_c('li',[_c('dl',[_c('dt',{staticClass:"txt_point01"},[_vm._v(" Smartship, IoT를 가능케 하는"),_c('br',{staticClass:"w_hide"}),_vm._v(" ICT 융합 환경 조성 ")]),_c('dd',[_vm._v("기존 저속 고비용의 FBB서비스에서는"),_c('br'),_vm._v("불가능 했던 ERP, 솔루션 등을"),_c('br'),_vm._v("가능케 함으로써 스마트한"),_c('br',{staticClass:"m_hide"}),_vm._v(" 환경을 구축합니다.")])])]),_c('li',[_c('dl',[_c('dt',{staticClass:"txt_point01"},[_vm._v(" 무제한 정액제로 예산,"),_c('br'),_vm._v("비용 관리에 효과적 ")]),_c('dd',[_vm._v("사용량에 제한이 없는 정액제로"),_c('br',{staticClass:"m_hide"}),_vm._v(" 예산 편성이"),_c('br',{staticClass:"w_hide"}),_vm._v(" 원활하고 효과적인"),_c('br',{staticClass:"m_hide"}),_vm._v(" 관리가 가능합니다.")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consist_box"},[_c('div',[_c('h4',[_vm._v("서비스 구성도")]),_c('div',{staticClass:"img_box"},[_c('span',{staticClass:"w_hide"},[_c('img',{attrs:{"src":"/content/images/biz_phone_vsat_07.png","alt":"통신위성을 통하여 선박 및 격오지에 음성 및 데이터를 전달합니다."}})]),_c('span',{staticClass:"m_hide"},[_c('img',{attrs:{"src":"/content/images/biz_phone_vsat_07-m.png","alt":"통신위성을 통하여 선박 및 격오지에 음성 및 데이터를 전달합니다."}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"consist_box"},[_c('div',[_c('h4',[_vm._v("서비스 범위")]),_c('div',{staticClass:"img_box"},[_c('span',[_c('img',{attrs:{"src":"/content/images/biz_phone_vsat_08.png","alt":"위성망을 통해 제공하는 Ku대역 해상 전용 위성통신 서비스 입니다."}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box1"},[_c('p',[_vm._v("해상안전을 육상에서 PC 뿐만 아니라 모바일에서도 확인이 가능한 선박 특화형 CCTV 솔루션")]),_c('ul',{staticClass:"vsat_list"},[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/icon_biz_vsat01.png","alt":""}})]),_c('p',[_vm._v("영상촬영")])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/icon_biz_vsat02.png","alt":""}})]),_c('p',[_vm._v("영상저장")])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/icon_biz_vsat03.png","alt":""}})]),_c('p',[_vm._v("모니터링")])]),_c('li',{staticClass:"last"},[_c('div',[_c('img',{attrs:{"src":"/content/images/icon_biz_vsat04.png","alt":""}})]),_c('p',[_vm._v("선박환경 최적화")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box"},[_c('span',{staticClass:"w_hide"},[_c('img',{attrs:{"src":"/content/images/biz_phone_vsat_10.png","alt":"네트워크 카메라롤 촬영된 영상을 VSAT위성을 통하여 PC 및 모바일폰으로 제공합니다."}})]),_c('span',{staticClass:"m_hide"},[_c('img',{attrs:{"src":"/content/images/biz_phone_vsat_10-m.png","alt":"네트워크 카메라롤 촬영된 영상을 VSAT위성을 통하여 PC 및 모바일폰으로 제공합니다."}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img_box1"},[_c('ul',{staticClass:"vsat_list02"},[_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/icon_biz_vsat05.png","alt":""}})]),_c('p',[_vm._v("VSAT 서비스에 최적화된"),_c('br'),_vm._v("전송 프레임 및 영상 화질 제공")])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/icon_biz_vsat06.png","alt":""}})]),_c('p',[_vm._v(" 방수방진에 강하고"),_c('br',{staticClass:"m_hide"}),_vm._v(" 적외선 확인 가능"),_c('br'),_vm._v("선박 전용 카메라 및"),_c('br',{staticClass:"m_hide"}),_vm._v(" DVR로 구성")])]),_c('li',[_c('div',[_c('img',{attrs:{"src":"/content/images/icon_biz_vsat07.png","alt":""}})]),_c('p',[_vm._v("선박 전문가들로"),_c('br',{staticClass:"m_hide"}),_vm._v(" 구성된 설치 시공")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"biz_footer"},[_c('p',[_vm._v("지금 보고 계신"),_c('br',{staticClass:"m_hide"}),_vm._v(" 기업상품에 대해"),_c('br'),_vm._v("더 궁금한 점이 있으시다면"),_c('br',{staticClass:"m_hide"}),_vm._v(" 상담이나 문의를 해보세요")]),_c('div',{staticClass:"btn_center"},[_c('div',{staticClass:"btn double"},[_c('a',{staticClass:"btn_cs mobile-only",attrs:{"href":"tel:1599-00700"}},[_c('span',[_vm._v("고객상담 1599-00700")])]),_c('span',{staticClass:"btn_cs pc-only"},[_vm._v("고객상담 1599-00700")])])])])
}]

export { render, staticRenderFns }