<template>
  <div
    v-if="detail.result_cd === '0000'"
    id="OnlineConsultReply"
  >
    <Header />
    <div id="container">
      <div class="qna">
        <div class="qna__top">
          <div class="qna__logo">
            <img
              src="/content/images/logo_qna.png"
              alt="에스케이 텔링크 로고"
            >
          </div>
          <div class="qna__title">
            1:1 문의 답변
            <span>문의하신 내용에 답변드립니다.</span>
          </div>
        </div>
        <div class="qna__area">
          <div class="qna__box">
            <div class="qna__subject">
              문의내용
            </div>
            <div class="qna__contents">
              {{ detail.rcpt_desc }}
            </div>
          </div>
          <div class="qna__box">
            <div class="qna__subject">
              답변내용
            </div>
            <div class="qna__contents qna__contents--answer">
              {{ detail.res_desc }}
            </div>
          </div>
        </div>
        <div class="qna__info">
          <div class="qna__info-title">
            온라인상담 관련 개인정보 수집/이용 동의 내역
          </div>
          <ul class="qna__info-list">
            <li>1. 수집항목 : 휴대폰번호</li>
            <li>2. 이용목적 : SK텔링크 전반적인 상담</li>
            <li>3. 개인정보 보유기간 및 이용기간 : <strong>상담 등록후 2개월까지</strong></li>
          </ul>
        </div>
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from "@/components/Header";
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'

export default {
  name: 'OnlineConsultReply',
  metaInfo: {
    title: '1:1 상담 답변',
  },
  components: {
    Header,
    Footer,
    SummitControl
  },
  props: {

  },
  emits: [
  ],
  data () {
    return {
      detail:{},
    }
  },
  computed: {
  },
  watch: {},
  created () {
    api.ItsvcGetQuestion({ rcpt_key: this.$route.query.rcpt_key })
    .then(response => {
      this.detail = response.data
    })
  },
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
