<template>
  <div id="INMARSAT">
    <Header />
    <!-- 컨텐츠 -->
    <div
      id="container"
      class="contents"
    >
      <BreadCrumb />
      <div id="contents">
        <div class="sub_header bg12">
          <h3 class="title">
            위성통신서비스
          </h3>
          <p class="txt">
            선박, 격오지 등 지상통신이 되지 않는 곳에 Inmarsat, VSAT 등<br>글로벌 위성을 이용하여 통신서비스를 제공합니다.
          </p>
          <img
            src="/content/images/sub_header_biz12-m.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- biz -->
        <div class="biz tab_area">
          <div class="tab_type01">
            <ul>
              <li
                class="on"
                title="현재메뉴 선택됨"
              >
                <router-link
                  to="/view/business/inmarsat"
                >
                  Inmarsat
                </router-link>
              </li>
              <li>
                <router-link
                  to="/view/business/vsat"
                >
                  VSAT
                </router-link>
              </li>
            </ul>
          </div>

          <!-- tab_cont -->
          <div class="tab_cont">
            <!-- Inmarsat -->
            <div class="on tab1">
              <span class="blind">Inmarsat</span>
              <div class="sub_box">
                <h4>4세대 Inmarsat 위성을 통해<br class="m_hide"> 해양과 해상에서도<br><span class="txt_point01">원활한 통신을 제공하는<br class="m_hide"> 가장 보편적인<br class="m_hide"> 국제 위성통신 서비스</span></h4>
                <p>
                  <span class="txt_point01">해양과 해상까지 원활한 위성통신 제공하는 <br class="m_hide">글로벌  통신서비스 Inmarsat(Fleet Broadband)</span><br>
                  국내 공중 전화망을 경유하지 않고<br class="m_hide"> 본사의 교환기에 접속하여, 국제, 시외  및 이동전화로의 통화 서비스를 제공하고 있습니다. <br>음성 통화와 이메일, 팩스, 문자서비스를<br class="m_hide"> 하나의 서비스로  경험해보세요.
                </p>
                <div class="btn_center">
                  <div class="btn double">
                    <a
                      href="tel:1599-00700"
                      class="btn_cs mobile-only"
                    ><span>고객상담 1599-00700</span></a>
                    <span class="btn_cs pc-only">고객상담 1599-00700</span>
                    <!-- <router-link
                      to="/view/customer/online_consult"
                      class="btn_inq"
                    >
                      <span>
                        1:1 문의
                      </span>
                    </router-link> -->
                  </div>
                  <ul>
                    <li>
                      <a
                        href="/content/download/위성통신서비스 식지_v3.pdf"
                        onclick=""
                        class="btn_ico ico2"
                        download
                      ><span>가입신청서</span></a>
                    </li>
                    <li>
                      <a
                        href="/content/download/위성통신서비스 이용약관 전문(부가세표시).pdf"
                        onclick=""
                        class="btn_ico ico3"
                        download
                      ><span>이용약관다운로드</span></a>
                    </li>
                  </ul>
                </div>
              </div>

              <div class="advice_list gray">
                <h4>이런 기업이 사용하면 좋아요!</h4>
                <p><span class="hashtag"># 선박</span><span class="hashtag"># 상선</span><span class="hashtag"># 어선</span><span class="hashtag">#  격오지 취재</span><span class="hashtag"># 플랜트 사업장</span></p>

                <div class="advice_scroll">
                  <div>
                    <ul class="list-scroll-small">
                      <li>
                        <div>
                          <img
                            src="/content/images/biz_phone_inmarsat_01.png"
                            alt=""
                          >
                        </div>
                        <p>하나의 서비스로 전화, <br class="m_hide">이메일, 팩스 등을<br>이용하고자 하는 고객</p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/biz_phone_inmarsat_02.png"
                            alt=""
                          >
                        </div>
                        <p>사용량이 많지 않아<br class="m_hide"> 사용한 만큼만<br>비용을 내고자 하는 고객</p>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/biz_phone_inmarsat_03.png"
                            alt=""
                          >
                        </div>
                        <p>가장 안정적인 서비스를<br>제공받고자 하는 고객</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="advice_list white">
                <h4>특징을 확인해보세요</h4>
                <div class="advice_scroll">
                  <div>
                    <ul class="list-scroll-small">
                      <li>
                        <div>
                          <img
                            src="/content/images/img_distinct12.png"
                            alt=""
                          >
                        </div>
                        <dl>
                          <dt>편리한 운용</dt>
                          <dd>조작이 간편한 하나의 안테나 장비에<br> 전화, 이메일, 팩스 등을 연결하<br>여 편리하게 이용할 수 있습니다.</dd>
                        </dl>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/img_distinct13.png"
                            alt=""
                          >
                        </div>
                        <dl>
                          <dt>경제적 혜택</dt>
                          <dd>다양한 요금제를 기반으로<br> 고객에게 맞춤형<br class="m_hide"> 컨설팅을 제공합니다. </dd>
                        </dl>
                      </li>
                      <li>
                        <div>
                          <img
                            src="/content/images/img_distinct14.png"
                            alt=""
                          >
                        </div>
                        <dl>
                          <dt>고객지원 및 유지보수</dt>
                          <dd>365일 24시간 기술 지원 센터를 운영하며,<br class="w_hide"> 전세계 400여 개 이상의 글로벌 A/S망을<br class="w_hide"> 구축하고 있습니다. </dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="advice_list">
                <h4>기업의 기대효과는 무엇일까요?</h4>
                <div class="advice_scroll">
                  <div>
                    <ul class="list-scroll-small">
                      <li>
                        <dl>
                          <dt class="txt_point01">
                            국제위성으로 해상<br>내 전화 및 팩스 제공
                          </dt>
                          <dd>국제위성으로 어느 해양에서도<br> 해운, 원양어선 등 해상 선박과 원활한<br class="w_hide"> 전화통화 및 팩스가 가능합니다.</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt class="txt_point01">
                            지상에서도 원활한<br>위성통신 서비스
                          </dt>
                          <dd>지구 적도상공에 위치한 통신위성을<br> 통해 항공기와 지상간의 원활한<br> 통신 서비스를 제공합니다.</dd>
                        </dl>
                      </li>
                      <li>
                        <dl>
                          <dt class="txt_point01">
                            국내 최저 요금의<br>위성통신 서비스
                          </dt>
                          <dd>국내 Inmartsat 서비스 중 최저요금과<br> 고품질의 위성통신 서비스를 통해<br class="m_hide"> 비용의<br class="w_hide"> 부담을 줄여주고, <br class="m_hide">고품질의 통화를 제공합니다. </dd>
                        </dl>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="pay_wrap">
                <h4>서비스 종류</h4>
                <!-- 서비스 종류 -->
                <div class="pay_box">
                  <!-- class 변경 cont_box on -->
                  <h5 class="blind">
                    서비스 종류
                  </h5>
                  <!-- 웹 -->
                  <div class="table_type04 w_hide">
                    <table>
                      <caption>VOICE, STANDARD IP, STREAMING IP, ISDN, TEXT를 나타내는 표입니다.</caption>
                      <colgroup>
                        <col style="width:20%;">
                        <col style="width:20%;">
                        <col style="width:20%;">
                        <col style="width:20%;">
                        <col style="width:20%;">
                      </colgroup>
                      <thead>
                        <tr>
                          <th scope="col">
                            VOICE
                          </th>
                          <th scope="col">
                            STANDARD IP
                          </th>
                          <th scope="col">
                            STREAMING IP
                          </th>
                          <th scope="col">
                            ISDN
                          </th>
                          <th scope="col">
                            TEXT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <img
                              src="/content/images/icon_biz_inmarsat01.png"
                              alt="VOICE"
                            >
                          </td>
                          <td>
                            <img
                              src="/content/images/icon_biz_inmarsat02.png"
                              alt="432 kbps"
                            >
                          </td>
                          <td>
                            <img
                              src="/content/images/icon_biz_inmarsat03.png"
                              alt="256 kbps"
                            >
                          </td>
                          <td>
                            <img
                              src="/content/images/icon_biz_inmarsat04.png"
                              alt="64K"
                            >
                          </td>
                          <td>
                            <img
                              src="/content/images/icon_biz_inmarsat05.png"
                              alt="TEXT"
                            >
                          </td>
                        </tr>
                        <tr>
                          <td>4Kbps circuit-switched service</td>
                          <td>최대 432Kbps의 IP 데이터</td>
                          <td>QoS보장형 서비스</td>
                          <td>64K ISDN 서비스</td>
                          <td>SMS (160 Characters)</td>
                        </tr>
                        <tr>
                          <td>착신전환,통화중대기 등<br>부가서비스</td>
                          <td>이용 패킷용량별 과금</td>
                          <td>32, 64, 128, 256kbps<br>(선택형)</td>
                          <td>32, 64, 128, 256kbps<br>(선택형)</td>
                          <td />
                        </tr>
                        <tr>
                          <td>Broadcast quality voice</td>
                          <td />
                          <td>이용 시간별 과금</td>
                          <td>31.Khz Aduio를 통한<br>G2 Fax지원</td>
                          <td />
                        </tr>
                        <tr>
                          <td>Voicemail</td>
                          <td />
                          <td />
                          <td />
                          <td />
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- //웹 -->
                  <!-- 모바일 -->
                  <div class="table_slide m_hide">
                    <ul>
                      <li class="accordionItem">
                        <!-- open 될때 on 클래스 추가 -->
                        <div class="tit_box">
                          <h5 class="title">
                            VOICE
                          </h5>
                          <span class="btn"><img
                            src="/content/images/btn_table_slide.png"
                            alt="펼쳐짐"
                          ></span>
                        </div>

                        <div
                          class="txt_open"
                          style="display:block"
                        >
                          <!-- open 될때 display:block 추가 -->
                          <ul class="table_list02">
                            <!-- table_list -->
                            <li>
                              <p>&middot; 4Kbps circuit-switched service</p>
                              <p>&middot; 착신전환,통화중대기 등 부가서비스</p>
                              <p>&middot; Broadcast quality voice</p>
                              <p>&middot; Voicemail</p>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="accordionItem">
                        <div class="tit_box">
                          <h5 class="title">
                            STANDARD IP
                          </h5>
                          <span class="btn"><img
                            src="/content/images/btn_table_slide.png"
                            alt="펼치기"
                          ></span>
                        </div>

                        <div class="txt_open">
                          <ul class="table_list02">
                            <!-- table_list -->
                            <li>
                              <p>&middot; 최대 432Kbps의 IP 데이터</p>
                              <p>&middot; 이용 패킷용량별 과금</p>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="accordionItem">
                        <div class="tit_box">
                          <h5 class="title">
                            STREAMING IP
                          </h5>
                          <span class="btn"><img
                            src="/content/images/btn_table_slide.png"
                            alt="펼치기"
                          ></span>
                        </div>

                        <div class="txt_open">
                          <ul class="table_list02">
                            <!-- table_list -->
                            <li>
                              <p>&middot; QoS보장형 서비스</p>
                              <p>&middot; 32, 64, 128, 256kbps (선택형)</p>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="accordionItem">
                        <div class="tit_box">
                          <h5 class="title">
                            ISDN
                          </h5>
                          <span class="btn"><img
                            src="/content/images/btn_table_slide.png"
                            alt="펼치기"
                          ></span>
                        </div>

                        <div class="txt_open">
                          <ul class="table_list02">
                            <!-- table_list -->
                            <li>
                              <p>&middot; 64K ISDN 서비스</p>
                              <p>&middot; 32, 64, 128, 256kbps(선택형)</p>
                              <p>&middot; 31.Khz Aduio를 통한 G2 Fax지원</p>
                            </li>
                          </ul>
                        </div>
                      </li>
                      <li class="accordionItem">
                        <div class="tit_box">
                          <h5 class="title">
                            TEXT
                          </h5>
                          <span class="btn"><img
                            src="/content/images/btn_table_slide.png"
                            alt="펼치기"
                          ></span>
                        </div>

                        <div class="txt_open">
                          <ul class="table_list02">
                            <!-- table_list -->
                            <li>
                              <p>&middot; SMS (160 Characters)</p>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <!-- //모바일 -->
                </div>
                <!-- //서비스 종류 -->
              </div>

              <div class="consist_box">
                <div>
                  <h4>서비스 범위</h4>
                  <div class="img_box">
                    <span><img
                      src="/content/images/biz_phone_inmarsat_07.png"
                      alt="1-4 Americas, 1-4 EMEA, 1-4 Asia-Pacific 위성통신이 가능한 범위 입니다."
                    ></span>
                  </div>
                </div>
              </div>

              <div class="biz_footer">
                <p>지금 보고 계신<br class="m_hide"> 기업상품에 대해<br>더 궁금한 점이 있으시다면<br class="m_hide"> 상담이나 문의를 해보세요</p>
                <div class="btn_center">
                  <div class="btn double">
                    <a
                      href="tel:1599-00700"
                      class="btn_cs mobile-only"
                    ><span>고객상담 1599-00700</span></a>
                    <span class="btn_cs pc-only">고객상담 1599-00700</span>
                    <!-- <router-link
                      to="/view/customer/online_consult"
                      class="btn_inq"
                    >
                      <span>
                        1:1 문의
                      </span>
                    </router-link> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- //Inmarsat -->
          </div>
          <!-- //tab_cont -->
        </div>
        <!-- //biz -->
      </div>
    </div>
    <!--// 컨텐츠 -->
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from '@/components/Header'
import BreadCrumb from '@/components/BreadCrumb'
import Footer from '@/components/Footer'
import SummitControl from '@/components/SummitControl'

export default {
  name: 'Inmarsat',
  metaInfo: {
    title: 'Inmarsat | 위성통신서비스 | 사업소개',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
    this.accordion()
  },
  methods: {
    accordion() {
      const accordionItem = document.querySelectorAll('.accordionItem')
      const accordionTxt = document.querySelectorAll('.txt_open')
      const closeAll = function() {
        accordionItem.forEach(item => {
          item.classList.remove('on')
        })
        accordionTxt.forEach(item2 => {
          item2.style.height = null
          item2.classList.remove('on')
        })
      }
      for(let i=0; i<accordionItem.length; i++) {
        accordionItem[i].addEventListener('click', function() {
          let currentTxt = this.children[1]
          if(this.classList.contains('on')) {
            closeAll()
            this.classList.remove('on')
            currentTxt.style.height = null
            currentTxt.classList.remove('on')
          } else {
            closeAll()
            this.classList.add('on')
            currentTxt.style.height = currentTxt.scrollHeight + 'px'
            currentTxt.classList.add('on')
          }
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1023px) {
  .table_slide .txt_open {
    display: block !important;
    padding: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.4s;
    border-top: none;
    &.on {
      border-top: 1px dotted #8d8d8d;
    }
    .table_list02 {
      margin: 15px;
    }
  }
  .btn_cs.mobile-only {
    display: block;
  }
}
</style>
