<template>
  <div id="PRIVACY_GUIDE_SPAN_MESSAGE">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            이용자 피해 예방 가이드
          </h3>
          <p class="txt">
            고객님의 개인정보보호를 위해<br>SK 텔링크에서 제공하는 피해 예방 정보를 확인하세요.
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <!-- to.개발 on 되어있는 li가 가운데로 와야함. -->
        <div class="tab_scroll01 tab7">
          <!-- tab갯수에따른 중복클래스 : 4개일때: 클래스 없음, 5개일때: tab5, 6개일때: tab6, 7개일때: tab7 -->
          <ul>
            <li>
              <router-link to="/view/customer/privacy_guide">
                동영상&amp; 웹툰<br>피해예방교육
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_illegal_TM">
                불법 TM신고
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_Msafer">
                명의도용방지
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_smishing">
                스미싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_phishing">
                피싱
              </router-link>
            </li>
            <li>
              <router-link to="/view/customer/privacy_guide_pharming">
                파밍
              </router-link>
            </li>
            <li
              class="on"
              title="현재메뉴 선택됨"
            >
              <router-link to="/view/customer/privacy_guide_spam_message">
                스팸메시지
              </router-link>
            </li>
          </ul>
        </div>

        <!-- guide_wrap -->
        <div class="guide_wrap">
          <div class="guide_box01">
            <div class="sub_title">
              <h4 class="title">
                스팸메시지(Spam  Message)이란?
              </h4>
              <p class="txt">
                스팸(Spam)은 전자우편, 게시판, 문자 메시지, 전화, 등을 통해 <span class="txt_point01">불특정 다수의 사람들에게<br> 무차별적으로 보내는 광고성 편지 또는 메시지</span>입니다. 사전에 미리 알고 예방하세요.
              </p>
            </div>

            <div class="guide_list01 list4">
              <ul>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm20.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    불특정 다수를 대상으로
                  </p>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm21.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    문자메세지 or 음성전화
                  </p>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm22.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    광고, 대출, 사기 등
                  </p>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm23.png"
                      alt="이미지"
                    >
                  </div>
                  <p class="txt">
                    무차별적인 <br>정보제공
                  </p>
                </li>
              </ul>
            </div>
          </div>

          <div class="sub_title gray">
            <h4 class="title">
              피해유형
            </h4>
            <p class="txt">
              2008년 3월에는 악성코드로 도용한 다른 사람의 개인정보로 도박사이트를 광고하는 스팸메일과 스팸성 게시물을 유포하던<br> 일당이 경찰청 사이버테러대응센터에 의해 적발된 사례가 있다.
            </p>
          </div>

          <div class="guide_box02">
            <div class="sub_title">
              <h4 class="title">
                피해 예방 방법
              </h4>
              <p class="txt">
                <span class="hashtag"># 프로그램설치</span><span class="hashtag"># 모르는번호</span><span class="hashtag"># 스팸차단</span>
              </p>
            </div>

            <div class="guide_slide">
              <div class="swiper-wrapper">
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm09.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    스팸전화가 의심되는 <br>번호 받지 않기
                  </div>
                </div>
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm10.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    스팸메세지  반드시 <br>차단하기
                  </div>
                </div>
                <div class="slick-slide">
                  <div class="img_box">
                    <img
                      src="/content/images/img_ctm11.png"
                      alt="이미지"
                    >
                  </div>
                  <div class="txt_box">
                    동의 없는 프로그램 <br>설치, 다운로드 하지 않기
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="guide_box03">
            <div class="sub_title">
              <h4 class="title">
                스팸신고 안내
              </h4>
            </div>

            <div class="guide_list02">
              <ul>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm15.png"
                      alt="이미지"
                    > <!-- 2017-08-08 이미지변경 -->
                  </div>
                  <div class="txt_box">
                    <strong>[사용자 신고]</strong>
                    <p>홈페이지(spam.kisa.or.kr), <br class="m_hide">Spamcop프로그램<br> 118 콜센터, 휴대폰 단말기의<br class="m_hide"> 간편신고<br class="w_hide"> 서비스 이용</p>
                  </div>
                </li>
                <li class="child2">
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm16.png"
                      alt="이미지"
                    > <!-- 2017-08-08 이미지변경 -->
                  </div>
                  <div class="txt_box">
                    <strong>[신고접수 및 위법사실 확인]</strong>
                    <p>신고의 접수 후, 해당 스팸이<br>법을 위반하였는지에 대한 확인</p>
                  </div>
                </li>
                <li>
                  <div class="img_box">
                    <img
                      src="/content/images/icon_ctm17.png"
                      alt="이미지"
                    > <!-- 2017-08-08 이미지변경 -->
                  </div>
                  <div class="txt_box">
                    <strong>[신고처리]</strong>
                    <p>법 위반의 정도에 따른<br class="m_hide"> 과태료 또는 수사<br>(1천만 원의 벌금 또는 1년 이하의 징역) 의뢰</p>
                  </div>
                </li>
              </ul>
            </div>

            <div class="btn_center">
              <a
                href="http://spam.kisa.or.kr"
                target="_blank"
                title="불법스팸대응센터 새창열림"
                class="btn_type02"
              >불법스팸대응센터 바로가기</a>
            </div>
          </div>
        </div>
        <!-- //guide_wrap -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import Header from "../../components/Header";
import BreadCrumb from "../../components/BreadCrumb";
import Footer from "../../components/Footer";
import SummitControl from "../../components/SummitControl";

export default {
  name: 'PrivacyGuideSpamMessage',
  metaInfo: {
    title: '스팸메시지 | 이용자 피해 예방가이드 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Footer,
    SummitControl
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
