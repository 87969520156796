<template>
  <div id="cert">
    <form name="form1">
      <input
        type="hidden"
        name="tc"
        value="kcb.oknm.online.safehscert.popup.cmd.P931_CertChoiceCmd"
      >	<!-- 변경불가-->
      <input
        v-model="certCpCdL"
        type="hidden"
        name="cp_cd"
      >
      <input
        v-model="certToken"
        type="hidden"
        name="mdl_tkn"
      >	<!-- 토큰 -->
      <input
        type="hidden"
        name="target_id"
        value=""
      >	
    </form>
  </div>
</template>

<script>
export default {
  props: {
    token: {
      type: String,
      required: true
    },
    cpcd: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      certToken: this.token,
      certCpCdL: this.cpcd
    }
  },
  created() {
  },
  mounted() {
    window.open('about:blank', 'auth_popup', 'width=430,height=640,scrollbar=yes,left=300,top=200')
    document.form1.action = 'https://safe.ok-name.co.kr/CommonSvl';
		document.form1.method = 'post';
		document.form1.target = 'auth_popup';
		document.form1.submit();
    setTimeout(() => {
      this.$store.commit('OFF_KCB_CERT_POPUP')
    }, 500)
  }
}
</script>

<style>
</style>