<template>
  <div id="NOTICE_LIST">
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents"
      >
        <div class="sub_header cs">
          <h3 class="title">
            공지사항
          </h3>
          <p class="txt">
            SK 텔링크의 새로운 소식을 알려드립니다
          </p>
          <img
            src="/content/images/sub_header_ct.jpg"
            alt="비쥬얼 이미지"
          >
        </div>

        <fieldset class="search_box">
          <legend>공지사항 검색</legend>
          <div class="search_align col2">
            <div class="search_align_inner">
              <div class="select_box">
                <label
                  id="type_select02_label"
                  for="type_select02"
                >전체</label>

                <select
                  id="type_select02"
                  v-model="formData.search_tp"
                  name="i_sSearchKind"
                  title="공지사항 검색 제목"
                >
                  <option value="ALL">
                    전체
                  </option>
                  <option value="TITLE">
                    제목
                  </option>
                  <option value="DESC">
                    내용
                  </option>
                </select>
              </div>
            </div>
            <div class="search_align_inner">
              <input
                v-model="formData.search_word"
                type="text"
                placeholder="검색어를 입력하세요"
                name="i_sKeyword"
                value=""
                title="검색어를 입력하세요"
                @keyup.enter="apiCall"
              >
              <button
                type="button"
                class="btn_type02"
                @click="apiCall"
              >
                검색
              </button>
              <div class="clear" />
            </div>
          </div>
        </fieldset>

        <!-- customer -->
        <div class="customer">
          <div class="table_type02">
            <ul class="table_wid01">
              <li
                v-for="(item, index) in bullutinList.bulletin_list"
                :key="index"
                :class="{ bg_point01: item.bulletin_tp === 'PRIORITY' }"
              >
                <dl class="child1">
                  <dt>No</dt>
                  <dd v-if="item.bulletin_tp === 'PRIORITY'">
                    공지
                  </dd>
                  <dd v-else>
                    {{ item.row_num }}
                  </dd>
                </dl>
                <dl class="child2">
                  <dt>작성일</dt>
                  <dd>{{ $moment(item.start_dt).format('YYYY.MM.DD') }}</dd>
                </dl>
                <dl class="child3">
                  <dt>제목</dt>
                  <dd class="txt_left">
                    <router-link
                      :to="{ name: 'notice_contents', params: { id: item.bulletin_no }, query: { page_num: formData.page_num }}"
                      :title="item.title"
                      class="btn_detail"
                    >
                      {{ item.title }}
                    </router-link>
                  </dd>
                </dl>
              </li>
              <li
                v-if="bullutinList.bulletin_total_cnt === 0"
                class="no-data"
              >
                내용이 없습니다.
              </li>
            </ul>
          </div>

          <!-- paging -->
          <Pagination
            v-if="totalCount > 0"
            :total-count="totalCount"
            :page-per-count="formData.page_per_cnt"
            :current-page="formData.page_num"
            @update="updatePage"
            @loadmo="updatePageMobile"
          />
          <!-- //paging -->
        </div>
        <!-- //customer -->
      </div>
    </div>
    <Footer />
    <SummitControl />
  </div>
</template>

<script>
import api from '@/api'
import Header from "@/components/Header";
import BreadCrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination"
import Footer from "@/components/Footer";
import SummitControl from "@/components/SummitControl";

export default {
  name: "NoticeList",
  metaInfo: {
    title: '공지사항 목록 | 고객센터',
  },
  components: {
    Header,
    BreadCrumb,
    Pagination,
    Footer,
    SummitControl
  },
  props: {

  },
  emits: [],
  data() {
    return {
      bullutinList:{},
      totalCount: 0,
      formData: {
        bulletin_tp: "NOTICE",
        page_per_cnt: 10,
        page_num: 1,
        search_tp: 'ALL',
        search_word: ''
      },
      currentPage:null,
    };
  },
  computed: {},
  watch: {},
  created() {
    if(this.$route.query.page_num) {
      this.formData.page_num = parseInt(this.$route.query.page_num, 10)
    }
    api.BulletinGetBullutinList(this.formData)
    .then(response => {
      this.bullutinList = response.data
      this.$store.commit('SET_NOTICE_ITEMS', this.bullutinList.bulletin_list)
      this.totalCount = response.data.bulletin_total_cnt
    })
  },
  mounted() {},
  methods: {
    apiCall(){
      api.BulletinGetBullutinList(this.formData)
      .then(response => {
        this.bullutinList = response.data
        this.$store.commit('SET_NOTICE_ITEMS', this.bullutinList.bulletin_list)
        this.totalCount = response.data.bulletin_total_cnt
        this.currentPage = null
      })
    },
    moreList(){
      api.BulletinGetBullutinList(this.formData)
      .then(response => {
        response.data.bulletin_list.forEach(item => {
          this.bullutinList.bulletin_list.push(item)
        })
        this.$store.commit('SET_NOTICE_ITEMS', this.bullutinList.bulletin_list)
      })
    },
    updatePage(num) {
      this.$router.replace({ query: {...this.$route.query, page_num: num }}).catch(()=>{})
      this.formData.page_num = num
      this.apiCall()
    },
    updatePageMobile(num) {
      this.formData.page_num = num
      this.moreList()
    },
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 1023px){
  .search_align{
    &.col2{
      .search_align_inner{
        .select_box{
          border: none;
          label{
            display: none !important;
          }
          select{
            opacity: 1;
            padding: 0 33px 0 15px;
            background-size: 35px auto;
            background-position: right;
            border: 1px solid #e2e2e2;
            border-radius: 0;
            background-color: transparent;
          }
        }
      }
    }
  }
}
.table_type02 ul li.no-data{
  padding: 30px 0;
  text-align: center;
}
</style>
