<template>
  <div
    id="CONTACT_US"
    class="eng"
  >
    <Header />
    <div id="container">
      <BreadCrumb />
      <div
        id="contents"
        class="contents en"
      >
        <div class="sub_header bg20">
          <h3 class="title">
            Contact us
          </h3>
          <img
            src="/content/images/sub_header_cp20.png"
            alt="Visual image"
          >
        </div>

        <div class="contact">
          <div class="contact__inner">
            <div class="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3163.344381844876!2d126.95154421549876!3d37.54694933300685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c98a00f4f714b%3A0x97fc2665d5048062!2z7ISc7Jq47Yq567OE7IucIOuniO2PrOq1rCDqs7XrjZXrj5kg66eI7Y-s64yA66GcIDE0NA!5e0!3m2!1sko!2skr!4v1615449173898!5m2!1sko!2skr"
                width=""
                height=""
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
              />
            </div>
            <div class="contact__info">
              <div class="contact__logo">
                <img
                  src="/content/images/logo_contact.png"
                  alt="에스케이 텔링크 로고"
                >
              </div>
              <div class="contact__text">
                Mapo T-town Bldg, Mapo-daero 144, Mapo-gu,<br>
                Seoul, 04212, Rep. of Korea
              </div>
              <div class="contact__wrap">
                <div class="contact__item">
                  Business Proposal
                </div>
                <div class="contact__item">
                  <a href="mailto:biz_proposal@sk.com">biz_proposal@sk.com</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/EngHeader";
import BreadCrumb from "@/components/EngBreadCrumb";
import Footer from "@/components/EngFooter";

export default {
  name: 'ContactUs',
  metaInfo: {
    title: 'Contact us | SK telink',
    titleTemplate: null
  },
  components: {
    Header,
    BreadCrumb,
    Footer
  },
  props: {
  },
  emits: [
  ],
  data () {
    return {}
  },
  computed: {
  },
  watch: {},
  created () {},
  mounted () {
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
</style>
